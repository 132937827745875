<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO CLIENTE -->
        <Dialog :visible.sync="dialogoNuevoCliente" :closeOnEscape="false" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <Clientes @closeCliente="closeDialogCliente()"></Clientes>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOG PARA ELIMINAR CLIENTES -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDelete = false" />
                Eliminar
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                    @click="confirmDelete()" />
            </div>
            <hr size="7" color="#ED6421" />
            <br />
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="editedCliente">Esta seguro de borrar al cliente
                    <b>{{ editedCliente.nombre }} | </b>
                    <b>{{ editedCliente.rfc }}</b>?</span>
            </div>
        </Dialog>
        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Clientes
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoCliente()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getClientes()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosClientes" removableSort showGridlines ref="clientes"
                    :value="itemsClientes" responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'rfc',
                        'nombre',
                        'domicilioFiscal',
                        'regimenFiscal.clave',
                        'usoCfdi.clave',
                        'direccion',
                        'estatus',
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Clientes."
                    :loading="loadingItemsClientes">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosClientes['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column sortable field="rfc" header="RFC" :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.rfc }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.nombre }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="domicilioFiscal " header="Domicilio Fiscal"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.domicilioFiscal }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="regimenFiscal" header="Régimen Fiscal"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.regimenFiscal.regimenFiscal }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="usoCfdi" header="Uso del CFDI"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.usoCfdi.usoCfdi }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editCliente(slotProps.data)" v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="openDialogDelete(slotProps.data)" v-tooltip.bottom="'Eliminar'" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Clientes from "./Clientes.vue";
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            // itemsClientes: [],
            filtrosClientes: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                rfc: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                nombre: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                domicilioFiscal: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                "regimenFiscal.clave": {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                "usoCfdi.clave": {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                direccion: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                estatus: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            },
            dialogoNuevoCliente: false,
            loadingItemsClientes: false,
            dialogDelete: false,
            editedIndexCliente: -1,
            editedCliente: {},
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsClientes() {
            return this.$store.state.listClienteStore;
        },
    },
    created() {
        this.getClientes();
    },
    methods: {
        async getClientes() {
            this.loading = true;
            this.loadingMensaje = 'Consultanod clientes';
            try {
                let response = await axios.get("Clientes/GetClientes/" + this.empresa.empresaBase);
                this.$store.state.listClienteStore = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        nuevoCliente() {
            let nuevoCliente = {
                idCliente: 0,
                rfc: '',
                nombre: '',
                domicilioFiscal: '',
                regimenFiscal: {
                    regimenFiscal: '',
                },
                usoCfdi: {
                    usoCfdi: '',
                },
                direccion: '',
                usuarioCreador: '',
                residenciaFiscal: {
                    residenciaFiscal: '',
                },
                correos: [],
                numRegIdTrib: '',
            }
            if (this.$store.state.clienteStore.idCliente == -1) {
                this.$store.state.clienteStore = { ...nuevoCliente }
            }
            this.dialogoNuevoCliente = true;
        },

        editCliente(item) {
            // console.log(item)
            this.$store.state.clienteStore = { ...item };
            this.dialogoNuevoCliente = true;
        },

        openDialogDelete(item) {
            this.editedIndexCliente = this.itemsClientes.indexOf(item);
            this.editedCliente = { ...item };
            this.dialogDelete = true;
        },

        async confirmDelete() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            try {
                this.editedCliente.usuarioModificador = this.empresa.nombre;
                let response = await axios.put(
                    "Clientes/PutClienteEstatus/" + this.empresa.empresaBase,
                    this.editedCliente
                );
                this.itemsClientes.splice(this.editedIndexCliente, 1);
                this.dialogDelete = false;
                this.loading = false;
                this.$toast.add({
                    severity: "success",
                    detail: "Cliente eliminado con éxito.",
                    life: 3000,
                });
            } catch (error) {
                // console.log(error)
                this.$toast.add({
                    severity: "error",
                    detail: "Error al eliminar, intente nuevamente.",
                    life: 3000,
                });
                this.loading = false;
            }
        },

        closeDialogCliente() {
            this.dialogoNuevoCliente = false;
        },
    },
    components: { Clientes },
};
</script>
