<template>
    <div>
        <!-- NUEVO CLIENTE -->
        <Dialog :visible.sync="dialogoNuevoCliente" :closeOnEscape="true" :closable="false" :style="{ width: '90vw' }">
            <Clientes @closeCliente="dialogoNuevoCliente = false"></Clientes>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Proyecto
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- DESCRIPCION -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="proyecto.descripcion" style="text-align: left" type="text" />
                            <label>Descripción</label>
                        </span>
                    </div>
                    <!-- PRESUPUESTO -->
                    <div class="justify-right field col-6">
                        <span class="p-float-label">
                            <InputNumber v-model="proyecto.presupuesto" mode="decimal" locale="en-US"
                                :minFractionDigits="2" />
                            <label>Presupuesto</label>
                        </span>
                    </div>
                    <!-- FECHA ENTREGA -->
                    <div class="justify-right field col-12 md:col-6">
                        <span class="p-float-label">
                            <Calendar v-model="proyecto.fechaEntrega" dateFormat="dd-MM-yy" />
                            <label>Fecha de Entrega</label>
                        </span>
                    </div>
                    <!-- CLIENTE -->
                    <div class=" field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <AutoComplete style="text-align: left" v-model="proyecto.cliente"
                                    :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                                </AutoComplete>
                                <label>Cliente</label>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import Clientes from "../Clientes/Clientes.vue";

export default {
    components: { Clientes },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

        }
    },
    computed: {
        clientes() {
            return this.$store.state.listClienteStore;
        },
        proyecto() {
            return this.$store.state.proyectoStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getClientes();
    },
    methods: {
        guardarOactualizar() {
            this.proyecto.descripcion = this.proyecto.descripcion.trim()

            if (this.$store.state.proyectoStore.idProyecto == 0) {
                this.postProyecto();
            } else {
                this.updateProyecto();
            }
        },

        async postProyecto() {
            this.loading = true;
            this.loadingMensaje = 'Guardando proyecto, espere...'

            this.proyecto.usuarioCreador = this.empresa.nombre
            console.log(this.proyecto)

            try {
                let response = await axios.post('Proyectos/PostProyecto/' + this.empresa.empresaBase, this.proyecto)
                console.log(response.data)
                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Proyecto guardado con exito.', life: 3000 });
                this.proyecto.idProyecto = response.data
                this.$store.state.listProyectosStore.push(this.proyecto)
                this.cierraVentana();

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });

            }
        },

        async updateProyecto() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando proyecto, espere...'

            this.proyecto.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('Proyectos/PutProyecto/' + this.empresa.empresaBase, this.proyecto)
                console.log(response.data)
                this.loading = false;

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listProyectosStore.findIndex(x => x.idProyecto === this.proyecto.idProyecto);
                Object.assign(this.$store.state.listProyectosStore[indice], this.proyecto)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Proyecto actualizado con exito.', life: 3000 });

                this.cierraVentana();

            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        async getClientes() {
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.empresa.empresaBase);
                console.log(result.data);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },
        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        nuevoCliente() {
            this.dialogoNuevoCliente = true;
        },

        cierraVentana() {
            this.$emit('closeProyecto')
        },
    },
}
</script>