<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="cierraVentana()" />
            <h2>Proveedor</h2>
            <Button @click="guardarOactualizar()" icon="pi pi-save"
                class="p-button-rounded p-button-success p-button-outlined" />
        </div>
        <div class="p-fluid grid">
            <div class="field col-12">
                <hr size=7 color="#ED6421">
            </div>
            <!-- RFC -->
            <div class="field col-3">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="rfc" type="text" v-model="proveedor.rfc" maxlength="13" />
                        <label for="rfc">RFC</label>
                    </span>
                </span>
            </div>
            <!-- NOMBRE -->
            <div class="field col-9">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="nombre" type="text" v-model="proveedor.nombre" />
                        <label for="nombre">Nombre</label>
                    </span>
                </span>
            </div>
            <!-- NOMBRE COMERCIAL -->
            <div class="field col-12">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="nombreComercial" type="text" v-model="proveedor.nombreComercial" />
                        <label for="nombreComercial">Nombre Comercial</label>
                    </span>
                </span>
            </div>
            <!-- CORREO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="Correo" type="text" v-model="proveedor.correo" />
                        <label for="Correo">Correo</label>
                    </span>
                </span>
            </div>
            <!-- TELEFONO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="telefono" type="text" v-model="proveedor.telefono" />
                        <label for="telefono">Teléfono</label>
                    </span>
                </span>
            </div>
            <!-- DIRECCION -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="direccion" type="text" v-model="proveedor.direccion" />
                        <label for="direccion">Dirección</label>
                    </span>
                </span>
            </div>
            <!-- CONTACTO -->
            <div class="field col-6">
                <span class="p-float-label">
                    <span class="p-float-label">
                        <InputText id="contacto" type="text" v-model="proveedor.contacto" />
                        <label for="contacto">Contacto</label>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
export default {
    data() {
        return {
            loading: false,
            loadingMensaje: '',
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        proveedor() {
            return this.$store.state.proveedorStore
        },

    },
    created() {
    },
    methods: {
        guardarOactualizar() {
            if (this.$store.state.proveedorStore.idProveedor == 0) {
                this.postProveedor();
            } else {
                this.updateProveedor();
            }
        },
        async postProveedor() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Proveedor, espere...'

            this.proveedor.usuarioCreador = this.token.nombre
            try {
                let response = await axios.post('Proveedores/PostProveedor/' + this.token.empresaBase, this.proveedor)
                console.log(response.data)

                this.proveedor.idProveedor = response.data
                this.$store.state.listaProveedorStore.push(this.proveedor)
                this.loading = false;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Proveedor guardado con exito.', life: 3000 });
                this.cierraVentana();

            } catch (error) {
                console.log(error)

                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });

            }
        },

        async updateProveedor() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Proveedor, espere...'

            this.proveedor.usuarioModificador = this.token.nombre
            try {
                let response = await axios.put('Proveedores/PutProveedor/' + this.token.empresaBase, this.proveedor)
                console.log(response.data)
                this.loading = false;

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaProveedorStore.findIndex(x => x.idProveedor === this.proveedor.idProveedor);
                Object.assign(this.$store.state.listaProveedorStore[indice], this.proveedor)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Proveedor actualizado con exito.', life: 3000 });

                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },
        cierraVentana() {
            this.$emit('closeProveedor')
        },

    },
}
</script>