<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Nuevo Propietario
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="propietario.nombre" style="text-align: left" type="text" maxlength="40" />
                            <label>Nombre</label>
                        </span>
                    </div>
                    <!-- No. Identificacion -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="propietario.numRegIdTrib" style="text-align: left" type="text"
                                maxlength="40" />
                            <label>No. Identificación</label>
                        </span>
                        <small>En este campo se debe registrar el número de identificación o
                            registro
                            fiscal del país de residencia para efectos fiscales del o de
                            los propietarios de la mercancía trasladada y puede
                            conformarse desde 6 a 40 caracteres.</small>
                    </div>
                    <!-- RESIDENCIA FISCAL -->
                    <div class="justify-right field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <Dropdown v-model="propietario.residenciaFiscal" :options="itemsPais" optionLabel="pais">
                                </Dropdown>
                                <label>País</label>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            itemsPais: [],

        }
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        propietario() {
            return this.$store.state.cEPropietarioStore;
        },
    },
    created() {
        this.listarPaises();
    },
    methods: {

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        guardarOactualizar() {
            if (this.$store.state.cEPropietarioStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Propietario, espere...'
            this.propietario.usuarioCreador = this.empresa.nombre
            console.log(this.propietario)
            try {
                let response = await axios.post('ComercioExterior/PostPropietario/' + this.empresa.empresaBase, this.propietario)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Propietario guardado con exito.', life: 3000 });

                this.propietario.id = response.data
                this.$store.state.listCEPropietarioStore.push(this.propietario)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Emisor, espere...'
            this.propietario.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('ComercioExterior/UpdatePropietario/' + this.empresa.empresaBase, this.propietario)
                // console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listCEPropietarioStore.findIndex(x => x.id === this.propietario.id);
                Object.assign(this.$store.state.listCEPropietarioStore[indice], this.propietario)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Propietario actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        async listarPaises() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetPaises');
                this.itemsPais = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>