<template>
    <div>
        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.descripcion }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarProyecto()" />
            </template>
        </Dialog>

        <!-- DIALOGO PARA CERRAR PROYECTO -->
        <Dialog :visible.sync="dialogCerrarProyecto" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de cerrar el proyecto <b>{{ objetoEditado.descripcion }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogCerrarProyecto = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="cerrarPoyectoConfirmar()" />
            </template>
        </Dialog>

        <!-- DIALOGO PARA CERRAR PROYECTO -->
        <Dialog :visible.sync="dialogAbrirProyecto" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de abrir el proyecto <b>{{ objetoEditado.descripcion }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogAbrirProyecto = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="abrirPoyectoConfirmar()" />
            </template>
        </Dialog>

        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO PROYECTO -->
        <Dialog :autoZIndex="true" :visible.sync="dialogoNuevoProyecto" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '900px' }">
            <Proyecto @closeProyecto="dialogoNuevoProyecto = false"></Proyecto>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Proyectos
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" class="mr-3 p-button-rounded" @click="nuevoProyecto()" />
                        <Button icon="pi pi-undo" class="p-button-rounded" @click="getProyectos()" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="mt-1 p-datatable-sm" :filters.sync="filtrosProyectos" removableSort showGridlines
                    ref="proyectos" :value="itemsProyectos" responsiveLayout="scroll" :paginator="true" :rows="10"
                    :globalFilterFields="['descripcion', 'presupuesto', 'cliente.rfc', 'cliente.nombre', 'fechaEntrega',]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Proyectos."
                    :loading="loadingItemsProyectos">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosProyectos['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column sortable field="descripcion" header="Descripción"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="slotProps">
                            <span :class="'product-badge-proyecto proyecto-' + slotProps.data.estatus">{{
                                slotProps.data.descripcion
                            }}</span>
                        </template>
                    </Column>
                    <Column sortable field="cliente.rfc" header="RFC Cliente"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.cliente.rfc }}
                        </template>
                    </Column>
                    <Column sortable field="cliente.nombre" header="Nombre Cliente"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.cliente.nombre }}
                        </template>
                    </Column>
                    <Column sortable field="presupuesto" header="Presupuesto"
                        :bodyStyle="{ 'text-align': 'right', overflow: 'visible' }">
                        <template #body="slotProps">
                            {{ formatCurrency(slotProps.data.presupuesto) }}
                        </template>
                    </Column>
                    <Column sortable field="asignado" header="Asignado"
                        :bodyStyle="{ 'text-align': 'right', overflow: 'visible' }">
                        <template #body="slotProps">
                            {{ formatCurrency(slotProps.data.asignado) }}
                        </template>
                    </Column>
                    <Column field="fechaEntrega" header="Fecha de Entrega"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fechaEntrega) }}
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <template v-if="slotProps.data.estatus == 'Vigente'">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                    v-tooltip.bottom="'Editar'" @click="editarProyecto(slotProps.data)" />
                            </template>
                            <template v-if="slotProps.data.estatus == 'Vigente'">
                                <Button icon="pi pi-lock" class="p-button-rounded p-button-info mr-2"
                                    v-tooltip.bottom="'Cerrar Proyecto'" @click="cerrarProyecto(slotProps.data)" />
                            </template>
                            <template v-if="slotProps.data.estatus == 'Cerrado'">
                                <Button icon="pi pi-lock-open" class="p-button-rounded p-button-info mr-2"
                                    v-tooltip.bottom="'Abrir Proyecto'" @click="abrirProyecto(slotProps.data)" />
                            </template>

                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                v-tooltip.bottom="'Eliminar'" @click="confirmDeleteProduct(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Proyecto from "../Proyectos/Proyecto.vue"
import moment from "moment";

export default {
    components: { Proyecto },
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            loadingItemsProyectos: false,

            filtrosProyectos: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
                "descripcion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "presupuesto": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "cliente.rfc": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "cliente.nombre": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "fechaEntrega": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },

            dialogoNuevoProyecto: false,

            deleteProductDialog: false,
            editedIndex: -1,
            objetoEditado: {},

            dialogCerrarProyecto: false,
            dialogAbrirProyecto: false
        };
    },
    computed: {
        itemsProyectos() {
            return this.$store.state.listProyectosStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getProyectos();
    },
    methods: {
        nuevoProyecto() {
            this.dialogoNuevoProyecto = true

            if (this.$store.state.proyectoStore != 0) {
                let obj = {
                    idProyecto: 0,
                    descripcion: '',
                    estatus: 'Vigente',
                    presupuesto: 0,
                    asignado: 0,
                    usuarioCreador: '',
                    usuarioModificador: '',
                    cliente: {
                        rfc: '',
                        nombre: '',
                        cliente: '',
                        usoCfdi: {},
                    }
                }
                this.$store.state.proyectoStore = { ...obj }
            }
        },

        async getProyectos() {
            this.loadingItemsProyectos = true;
            try {
                let response = await axios.get('Proyectos/GetProyectos/' + this.empresa.empresaBase)
                this.$store.state.listProyectosStore = response.data
                console.log(response)
                this.loadingItemsProyectos = false;

            } catch (error) {
                console.log(error)
                this.loadingItemsProyectos = false;

            }
        },

        editarProyecto(data) {
            this.$store.state.proyectoStore = { ...data }
            this.dialogoNuevoProyecto = true
        },

        abrirProyecto(data) {
            this.objetoEditado = { ...data };
            this.objetoEditado.estatus = 'Vigente'
            this.dialogAbrirProyecto = true;
        },

        async abrirPoyectoConfirmar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre
                let response = await axios.put('Proyectos/PutProyectoEstatus/' + this.empresa.empresaBase, this.objetoEditado)

                let indice = this.$store.state.listProyectosStore.findIndex(x => x.idProyecto === this.objetoEditado.idProyecto);
                Object.assign(this.$store.state.listProyectosStore[indice], this.objetoEditado)
                this.dialogAbrirProyecto = false
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Proyecto abierto.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al abrir, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },

        cerrarProyecto(data) {
            this.objetoEditado = { ...data };
            this.objetoEditado.estatus = 'Cerrado'
            this.dialogCerrarProyecto = true;
        },

        async cerrarPoyectoConfirmar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre
                let response = await axios.put('Proyectos/PutProyectoEstatus/' + this.empresa.empresaBase, this.objetoEditado)

                let indice = this.$store.state.listProyectosStore.findIndex(x => x.idProyecto === this.objetoEditado.idProyecto);
                Object.assign(this.$store.state.listProyectosStore[indice], this.objetoEditado)
                this.dialogCerrarProyecto = false
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Proyecto cerrado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },

        async eliminarProyecto() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre

                let response = await axios.put('Proyectos/PutProyectoEstatus/' + this.empresa.empresaBase, this.objetoEditado)
                this.itemsProyectos.splice(this.editedIndex, 1)

                this.deleteProductDialog = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Proyecto eliminado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },

        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsProyectos.indexOf(data)
            data.estatus = 'Cancelado'
            this.objetoEditado = { ...data };
            this.deleteProductDialog = true;
        },

        formatCurrency(value) {
            return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
    },

};
</script>
<style >
.product-badge-proyecto.proyecto-Cerrado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge-proyecto.proyecto-Vigente {
    background: #c8e6c9;
    color: #256029;
}

.product-badge-proyecto {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .3px;
}
</style>