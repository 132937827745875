<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <!-- DIALOG QUE MUESTRA LOS COMPROBANTES PENDIENTES DE PAGO -->
        <Dialog :visible.sync="dialogDoctoRelacionado" :containerStyle="{ width: '100vw' }" :modal="true"
            :closeOnEscape="true" :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDoctoRelacionado = false" />
                <h2>Documento Relacionado</h2>
                <h1></h1>
            </div>
            <hr size=7 color="#ED6421">

            <DataTable :value="listaDoctoRelacionado" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                class="mt-3" :filters.sync="filters" :globalFilterFields="['idDocumento', 'folio']" :paginator="true"
                :rows="10" :rowsPerPageOptions="[10, 20, 50]">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="folio fiscal o folio" />
                        </span>
                    </div>
                </template>
                <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'min-width': '10rem' }" frozen>
                    <template #body="slotProps">
                        <Button icon="pi pi-dollar" class="p-button-rounded p-button-success mr-2"
                            @click="registrarPago(slotProps.data)" />
                    </template>
                </Column>
                <Column field="idDocumento" header="Id Documento" :styles="{ 'flex-grow': '1', 'flex-basis': '230px' }">
                </Column>
                <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="monedaDR.clave" header="Moneda DR" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="impSaldoAnt" header="Imp Saldo Anterior"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'right' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.impSaldoAnt) }}</span>
                    </template>
                </Column>
            </DataTable>
        </Dialog>
        <!-- DIALOG DONDE SE INDICA EL MONTO A PAGAR -->
        <Dialog :visible.sync="dialogPago" :containerStyle="{ width: '100vw' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogPago = false" />
                <h2>Pago</h2>
                <Button icon="pi pi-plus" class="p-button-rounded p-button-success p-button-outlined" @click="pushPago" />
            </div>
            <hr size=7 color="#ED6421">
            <div class="p-fluid grid formgrid">
                <div class="field col-12 md:col-3">
                    <label>Serie</label>
                    <InputText v-model="doctoRelacionado.serie" readonly />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Folio</label>
                    <InputText v-model="doctoRelacionado.folio" readonly />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Moneda DR</label>
                    <InputText v-model="doctoRelacionado.monedaDR.clave" readonly />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Equivalencia DR</label>
                    <InputNumber v-model="doctoRelacionado.equivalenciaDR" mode="decimal" locale="en-US"
                        :minFractionDigits="6" />
                </div>

                <div class="field col-12 md:col-3">
                    <label>Saldo Anterior</label>
                    <InputNumber v-model="doctoRelacionado.impSaldoAnt" mode="decimal" locale="en-US" :minFractionDigits="2"
                        readonly />

                </div>
                <div class="field col-12 md:col-3">
                    <label>Saldo Insoluto</label>
                    <InputNumber v-model="impSaldoInsoluto" mode="decimal" locale="en-US" :minFractionDigits="2" readonly />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Importe Pagado</label>
                    <InputNumber v-model="doctoRelacionado.impPagado" mode="decimal" locale="en-US"
                        :minFractionDigits="2" />
                </div>
            </div>
        </Dialog>
        <Card class="shadow-none">
            <template #content>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- TOTAL RETENCIONES IVA -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.totales.totalRetencionesIVA" mode="currency" currency="USD"
                                locale="en-US" readonly />
                            <label>Total Retenciones IVA</label>
                        </span>
                    </div>
                    <!-- TOTAL RETENCIONES ISR -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.totales.totalRetencionesISR" mode="currency" currency="USD"
                                locale="en-US" readonly />
                            <label>Total Retenciones ISR</label>
                        </span>
                    </div>
                    <!-- TOTAL RETENCIONES IEPS -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.totales.totalRetencionesIEPS" mode="currency" currency="USD"
                                locale="en-US" readonly />
                            <label>Total Retenciones IEPS</label>
                        </span>
                    </div>
                </div>
                <!-- IMPUESTO IVA 16 -->
                <template v-if="pago.totales.totalTrasladosBaseIVA16 != 0">
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <!-- TOTAL BASE IVA 16 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosBaseIVA16" mode="currency" currency="USD"
                                    locale="en-US" readonly />
                                <label>Total Base IVA 16</label>
                            </span>
                        </div>
                        <!-- TOTAL IVA 16 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosImpuestoIVA16" mode="currency"
                                    currency="USD" locale="en-US" readonly />
                                <label>Total IVA 16</label>
                            </span>
                        </div>
                    </div>
                </template>
                <!-- IMPUESTO IVA 8 -->
                <template v-if="pago.totales.totalTrasladosBaseIVA8 != 0">
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <!-- TOTAL BASE IVA 8 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosBaseIVA8" mode="currency" currency="USD"
                                    locale="en-US" readonly />
                                <label>Total Base IVA 8</label>
                            </span>
                        </div>
                        <!-- TOTAL IVA 8 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosImpuestoIVA8" mode="currency"
                                    currency="USD" locale="en-US" readonly />
                                <label>Total IVA 8</label>
                            </span>
                        </div>
                    </div>
                </template>
                <!-- IMPUESTO IVA 0 -->
                <template v-if="pago.totales.totalTrasladosBaseIVA0 != 0">
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <!-- TOTAL BASE IVA 0 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosBaseIVA0" mode="currency" currency="USD"
                                    locale="en-US" readonly />
                                <label>Total Base IVA 0</label>
                            </span>
                        </div>
                        <!-- TOTAL IVA 0 -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosImpuestoIVA0" mode="currency"
                                    currency="USD" locale="en-US" readonly />
                                <label>Total IVA 0</label>
                            </span>
                        </div>
                    </div>
                </template>
                <!-- IMPUESTO IVA EXENTO -->
                <template v-if="pago.totales.totalTrasladosBaseIVAExento != 0">
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <!-- TOTAL BASE IVA Exento  -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber v-model="pago.totales.totalTrasladosBaseIVAExento" mode="currency"
                                    currency="USD" locale="en-US" readonly />
                                <label>Total Base IVA Exento</label>
                            </span>
                        </div>
                    </div>
                </template>
                <!-- MONTO TOTAL DEL PAGO -->
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- MONTO TOTAL PAGOS -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.totales.montoTotalPagos" mode="currency" currency="USD"
                                locale="en-US" readonly />
                            <label>Monto Total Pagos</label>
                        </span>
                    </div>
                </div>
                <!-- DATOS GENERALES DEL PAGO -->
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- FECHA -->
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <Calendar v-model="pago.pagos[0].fechaPago" dateFormat="dd-MM-yy" :showTime="true"
                                hourFormat="12" :showSeconds="true" />
                            <label>Fecha de Pago</label>
                        </span>
                    </div>
                    <!-- FORMA DE PAGO -->
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="pago.pagos[0].formaDePagoP"
                                :options="itemsFormaPago" optionLabel="formaPago" />
                            <label>Forma de PagoP</label>
                        </span>
                    </div>
                </div>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- MONEDA -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="pago.pagos[0].monedaP" :options="itemsMoneda"
                                optionLabel="moneda" />
                            <label>MonedaP</label>
                        </span>
                    </div>
                    <!-- TIPO DE CAMBIO -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.pagos[0].tipoCambioP" mode="decimal" locale="en-US"
                                :minFractionDigits="6" />
                            <label>Tipo de CambioP</label>
                        </span>
                    </div>
                    <!-- MONTO -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="pago.pagos[0].monto" mode="currency" currency="USD" locale="en-US" />
                            <label>Monto Total Pagos</label>
                        </span>
                    </div>
                </div>
                <!-- TABAL DE PAGOS -->
                <DataTable :value="pago.pagos[0].doctoRelacionados" :scrollable="true" scrollHeight="500px"
                    scrollDirection="both" class="mt-3">
                    <template #header>
                        <div class="flex justify-content-between align-items-center">
                            <h2 class="m-0">Documentos Relacionados</h2>
                            <Button icon="pi pi-plus" class="p-button-rounded p-button-success p-button-outlined"
                                @click="getPendientes()" />
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }"
                        frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deletePago(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="idDocumento" header="Id Documento" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="monedaDR.clave" header="Moneda DR" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="equivalenciaDR" header="Equivalencia DR"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="numParcialidad" header="Num. Parcialidad"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="impSaldoAnt" header="Imp Saldo Anterior"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.impSaldoAnt) }}</span>
                        </template>
                    </Column>
                    <Column field="impPagado" header="Imp. Pagado" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.impPagado) }}</span>
                        </template>
                    </Column>
                    <Column field="impSaldoInsoluto" header="Imp. Saldo Insoluto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.impSaldoInsoluto) }}</span>
                        </template>
                    </Column>
                    <Column field="objetoImpDR.clave" header="Objeto de Impuestos DR"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
    components: {

    },
    data() {
        return {
            listaDoctoRelacionado: [],
            doctoRelacionado: {
                monedaDR: { clave: '' }
            },
            dialogDoctoRelacionado: false,
            dialogPago: false,
            loading: false,
            loadingMensaje: '',
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'idDocumento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
        }
    },
    computed: {
        impSaldoInsoluto() {
            let resultado = 0;
            resultado = this.doctoRelacionado.impSaldoAnt - this.doctoRelacionado.impPagado
            return resultado;
        },
        pago() {
            return this.$store.state.complementoPagoStore.pagos;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getFormaPago();
        this.getMoneda();
    },
    methods: {
        async getFormaPago() {
            if (this.itemsFormaPago.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetFormaPago/');
                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },

        async getMoneda() {
            if (this.itemsMoneda.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },

        async getPendientes() {
            this.loadingMensaje = 'Consultando comprobantes pendientes'
            this.loading = true;
            let cliente = this.$store.state.complementoPagoStore.cliente.rfc;

            var clienteRFC = cliente.replace(/&/g, '-');

            try {
                let result = await axios.get('ComplementoDePago/GetPendientes/' + this.empresa.empresaBase + '/' + clienteRFC);
                this.listaDoctoRelacionado = result.data
                console.log(result.data)

                //ELIMINAMOS LOS QUE YA TENGAN UN PAGO REGISTRADO
                for (let a of this.pago.pagos[0].doctoRelacionados) {
                    let indice = this.listaDoctoRelacionado.findIndex(x => x.idDocumento === a.idDocumento)
                    this.listaDoctoRelacionado.splice(indice, 1)
                }
                this.dialogDoctoRelacionado = true;
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        registrarPago(item) {
            // console.log(item)
            this.dialogPago = true;
            this.doctoRelacionado = { ...item }
        },

        pushPago() {
            this.doctoRelacionado.impSaldoInsoluto = this.impSaldoInsoluto;
            let default_ = { monedaDR: { clave: '' } }
            this.doctoRelacionado.numParcialidad++;
            this.pago.pagos[0].doctoRelacionados.push(this.doctoRelacionado);
            this.sumatoriaImpuestos();
            let indice = this.listaDoctoRelacionado.findIndex(x => x.idDocumento === this.doctoRelacionado.idDocumento)
            this.listaDoctoRelacionado.splice(indice, 1)
            this.doctoRelacionado = { ...default_ }
            this.dialogPago = false;
        },

        editPago() { },

        sumatoriaImpuestos() {
            //INICIALIZAMOS
            this.pago.totales.totalRetencionesIVA = 0;
            this.pago.totales.totalRetencionesISR = 0;
            this.pago.totales.totalRetencionesIEPS = 0;

            this.pago.totales.totalTrasladosBaseIVA16 = 0;
            this.pago.totales.totalTrasladosImpuestoIVA16 = 0;
            this.pago.totales.totalTrasladosBaseIVA8 = 0;
            this.pago.totales.totalTrasladosImpuestoIVA8 = 0;
            this.pago.totales.totalTrasladosBaseIVA0 = 0;
            this.pago.totales.totalTrasladosImpuestoIVA0 = 0;

            this.totalTrasladosBaseIVAExento = 0;
            this.pago.totales.montoTotalPagos = 0

            this.pago.pagos[0].monto = 0;

            let listTrasladados = [];
            let listRetenidos = [];

            for (let a of this.pago.pagos[0].doctoRelacionados) {
                //SUMAMOS LOS PAGOS
                this.pago.pagos[0].monto += a.impPagado;
                this.pago.totales.montoTotalPagos += a.impPagado;
                let porcentaje = Math.round((a.impPagado / a.total) * 1000000) / 1000000

                //RECORREMOS LOS TRASLADADOS
                for (let t of a.impuestosDr.traslados) {
                    if (t.tipoFactor === 'Tasa') {
                        if (t.tasaOCuota == .16) {
                            t.importe = Math.round((t.importe * porcentaje) * 100) / 100;
                            t.base_ = Math.round((t.base_ * porcentaje) * 100) / 100;
                            this.pago.totales.totalTrasladosBaseIVA16 += t.base_
                            this.pago.totales.totalTrasladosImpuestoIVA16 += t.importe
                        }
                        if (t.tasaOCuota == .08) {
                            t.importe = Math.round((t.importe * porcentaje) * 100) / 100;
                            t.base_ = Math.round((t.base_ * porcentaje) * 100) / 100;
                            this.pago.totales.totalTrasladosBaseIVA8 += t.base_
                            this.pago.totales.totalTrasladosImpuestoIVA8 += t.importe
                        }
                        if (t.tasaOCuota == 0) {
                            console.log('AL 0', porcentaje, t.importe, t.base_)
                            t.importe = Math.round((t.importe * porcentaje) * 100) / 100;
                            t.base_ = Math.round((t.base_ * porcentaje) * 100) / 100;
                            this.pago.totales.totalTrasladosBaseIVA0 += t.base_
                            this.pago.totales.totalTrasladosImpuestoIVA0 += t.importe
                        }
                    }
                    if (t.tipoFactor === 'Exento') {
                        t.base_ = Math.round((t.base_ * porcentaje) * 100) / 100;
                        this.pago.totales.totalTrasladosBaseIVAExento += t.base_
                    }
                    listTrasladados.push(t);
                }

                //RECORREMOS LAS RETENCIONES
                for (let r of a.impuestosDr.retenciones) {
                    r.base_ = Math.round((r.base_ * porcentaje) * 100) / 100;
                    if (r.impuesto === '001') {
                        r.importe = Math.round((r.importe * porcentaje) * 100) / 100;
                        this.pago.totales.totalRetencionesISR += r.importe;
                    }
                    if (r.impuesto === '002') {
                        console.log('impuesto', r.importe * porcentaje)
                        r.importe = Math.round((r.importe * porcentaje) * 100) / 100;
                        this.pago.totales.totalRetencionesIVA += r.importe;
                    }
                    if (r.impuesto === '003') {
                        r.importe = Math.round((r.importe * porcentaje) * 100) / 100;
                        this.pago.totales.totalRetencionesIEPS += r.importe;
                    }
                    listRetenidos.push(r);
                }
            }

            //AGRUPAMOS LOS IMPUESTOS
            this.$store.state.complementoPagoStore.pagos.pagos[0].impuestosP.traslados = [];
            var obj = this.agrupaImpuestos(listTrasladados, 'impuesto', 'tasaOCuota')
            for (const prop in obj) {
                const tra = Object.assign({}, obj[prop])
                this.$store.state.complementoPagoStore.pagos.pagos[0].impuestosP.traslados.push(tra)
            }
            this.$store.state.complementoPagoStore.pagos.pagos[0].impuestosP.retenciones = [];
            var objR = this.agrupaImpuestos(listRetenidos, 'impuesto', 'tasaOCuota')
            for (const prop in objR) {
                const ret = Object.assign({}, objR[prop])
                this.$store.state.complementoPagoStore.pagos.pagos[0].impuestosP.retenciones.push(ret)
            }

            console.log(this.pago.pagos[0])
        },

        deletePago(item) {
            let indice = this.pago.pagos[0].doctoRelacionados.indexOf(item)
            this.pago.pagos[0].doctoRelacionados.splice(indice, 1)
            console.log('lista', this.pago.pagos[0].doctoRelacionados)
            this.sumatoriaImpuestos();
        },

        agrupaImpuestos(miarray, prop, propa) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop, propa];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.tipo };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 100) / 100;
                return groups;
            }, {});
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>