<template>
  <div>
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <div class="
              scalein
              animation-duration-2000 animation-iteration-infinite
              flex
              align-items-center
              justify-content-center
              font-bold
              border-round
              m-2
              px-5
              py-3
            ">
        {{ loadingMensaje }}
      </div>
    </Dialog>

    <Card>
      <!-- TITULO CARD -->
      <template #title>
        <div class="p-d-flex p-fluid grid formgrid">
          <div class="text-left field col-4">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
              @click="cierraVentana()" />
          </div>
          <div class="text-center field col-4">Vehículo</div>
          <div class="text-right field col-4">
            <template>
              <Button icon="pi pi-save" @click="guardarOactualizar()"
                class="p-button-rounded p-button-success p-button-outlined" />
            </template>
          </div>
        </div>
        <hr size="7" color="#ED6421" />
      </template>

      <template #content>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
          <div class="justify-right field col-12">
            <span class="p-float-label">
              <Dropdown v-model="vehiculos.configAutotransporte" style="text-align: left" :options="itemsConfigVehicular"
                optionLabel="configAutotransporte" />
              <label>Configuración Vehicular</label>
            </span>
          </div>
          <!-- PLACA VEHÍCULO MOTOR -->
          <div class="justify-right field col-12">
            <span class="p-float-label">
              <InputText id="placaVehiculoM" v-model="vehiculos.placaVM" type="text" maxlength="7"
                v-tooltip.bottom="'En este campo se deberá registrar la placa del vehículo en el que se realiza el traslado de bienes y/o mercancías, se deben registrar solo los caracteres alfanuméricos, sin guiones y espacios.'" />
              <label>Placa Vehículo Motor</label>
            </span>
          </div>

          <div class="justify-right field col-12">
            <span class="p-float-label">
              <InputNumber id="anioModeloVM" v-model="vehiculos.anioModelo" :useGrouping="false" showButtons :min="0"
                :max="9999"
                v-tooltip.bottom="'En este campo se deberá registrar el año del vehículo que se está utilizando para trasladar los bienes y/o mercancías.'" />
              <label>Año Modelo del Vehículo</label>
            </span>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      loadingMensaje: "",
      itemsConfigVehicular: [],
      filteredPlaca: null,
      placaVM: "",
      anioModeloVM: 0,
    };
  },
  computed: {
    empresa() {
      return this.$store.state.usuario;
    },
    vehiculos() {
      return this.$store.state.vehiculoStore;
    },
  },
  created() {
    this.getEmpresa();
    this.GetConfigVehicular();
  },
  methods: {
    // CONFIGURACION VEHICULAR
    async GetConfigVehicular() {
      try {
        let response = await axios.get(
          "CatalogosSatCartaPorte/GetConfigAutotransporte"
        );
        console.log(response.data)
        this.itemsConfigVehicular = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getEmpresa() {
      try {
        let response = await axios.get(
          "Empresas/GetEmpresa/" + this.empresa.empresaBase
        );
        this.datosEmpresa = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    cierraVentana() {
      this.$emit("closeVehiculo");
      // this.$store.state.ventaCotizacionStore.idVentaPedido = -1;
    },
    vistaPrevia() {
      this.dialogPdf = true;
      // console.log(this.$store.state.ventaCotizacionStore)
    },

    guardarOactualizar() {
      if (this.$store.state.vehiculoStore.id == 0) {
        this.postVehiculo();
      } else {
        this.updateVehiculo();
      }
    },

    async postVehiculo() {
      this.loading = true;
      this.loadingMensaje = 'Guardando vehículo, espere...'
      this.vehiculos.usuarioCreador = this.empresa.nombre
      // console.log(this.vehiculos)
      try {
        console.log(this.vehiculos)
        let response = await axios.post('CatalogosCartaPorte/PostVehiculos/' + this.empresa.empresaBase, this.vehiculos)
        // console.log(response.data)
        this.loading = false
        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Vehículo guardado con exito.', life: 3000 });

        this.vehiculos.id = response.data
        this.$store.state.listVehiculoStore.push(this.vehiculos)
        this.cierraVentana()

      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
      }
    },
    async updateVehiculo() {
      this.loading = true;
      this.loadingMensaje = 'Actualizando vehículo, espere...'
      this.vehiculos.usuarioModificador = this.empresa.nombre
      try {
        let response = await axios.put('CatalogosCartaPorte/UpdateVehiculo/' + this.empresa.empresaBase, this.vehiculos)
        console.log(response.data)
        this.loading = false;

        // BUSCAMOS EL INDICE
        let indice = this.$store.state.listVehiculoStore.findIndex(x => x.id === this.vehiculos.id);
        Object.assign(this.$store.state.listVehiculoStore[indice], this.vehiculos)

        this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Vehículo actualizado con exito.', life: 3000 });
        this.cierraVentana()
      } catch (error) {
        console.log(error)
        this.loading = false;
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
      }
    },

  },
};
</script>