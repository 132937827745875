<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- CLAVE PROD SERV -->
            <template v-if="editedIndex === -1">
                <div class=" field col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoSelect" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" field="concepto" @item-select="asignaConcepto">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
            </template>
            <!-- CLAVE PROD SERV -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <AutoComplete style="text-align: left" v-model="concepto.claveProdServ"
                        :suggestions="filteredClaveProdServ" @complete="searchClaveProdServ($event)" field="claveProdServ"
                        ref="_claveProdServ"></AutoComplete>
                    <label>Clave(SAT)</label>
                </span>
            </div>
            <!-- NO DE INDENTIFICACION -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                    <label>No. Identificación</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.descripcion" ref="_descripcion" />
                    <label>Descripción</label>
                </span>
            </div>
            <!-- CANTIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3"
                        ref="_cantidad" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- UNIDAD SAT -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <AutoComplete v-model="concepto.claveUnidad" :suggestions="filteredClaveUnidad"
                        @complete="searchClaveUnidad($event)" field="claveUnidad"></AutoComplete>
                    <label>Unidad(SAT)</label>
                </span>
            </div>
            <!-- UNIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.unidad" />
                    <label>Unidad</label>
                </span>
            </div>
            <!-- VALOR UNITARIO -->
            <template v-if="modoImporte">
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber v-model="concepto.valorUnitario" mode="decimal" locale="en-US"
                                :minFractionDigits="6" />
                            <label>Valor unitario</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                    </div>
                </div>
            </template>
            <template v-else>
                <!-- PRECIO -->
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber v-model="concepto.precio" mode="decimal" locale="en-US" :minFractionDigits="2" />
                            <label>precio</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushConceptoTotal" />
                    </div>
                </div>
            </template>
            <!-- OBJETO DE IMPUESTOS -->
            <div class="justify-left field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp" optionLabel="objImp" />
                    <label>Objeto de impuestos(SAT)</label>
                </span>
            </div>
            <!-- IMPUESTOS -->
            <template v-if="concepto.objImp.clave === '02'">
                <!-- TRASLADADOS -->
                <div class="field md:col-6 sm:col-12">
                    <h1>Traslados</h1>
                    <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                        :selection.sync="impuestos.traslados">
                        <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                        <Column field="traslado" header="Impuesto"></Column>
                        <Column field="tipoFactor" header="Tipo Factor"></Column>
                        <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                    </DataTable>
                </div>
                <!-- RETENIDOS -->
                <div class="field md:col-6 sm:col-12">
                    <h1>Retenciones</h1>
                    <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                        :selection.sync="impuestos.retenciones">
                        <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                        <Column field="retencion" header="Impuesto"></Column>
                        <Column field="tipoFactor" header="Tipo Factor"></Column>
                        <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                    </DataTable>
                </div>
            </template>
        </div>
        <!-- TABLA DE CONCEPTOS -->
        <DataTable :value="factura.conceptos" :scrollable="true" scrollHeight="300px" scrollDirection="both" class="mt-3">
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="editConcepto(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="deleteConcepto(slotProps.data)" />
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="noIdentificacion" header="No. Indentificación"
                :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }">
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="valorUnitario" header="Valor Unitario" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                </template>
            </Column>
            <Column field="importe" header="Importe" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
            <Column field="claveProdServ.clave" header="Clave SAT" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <!-- <Column field="impuestos.retenciones" header="IMPUESTOS"
                :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column> -->
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
// import { Console } from 'console'

export default {
    data() {
        return {
            conceptoDefault: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            filteredConcepto: null,
            concepto: {
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },
            conceptoSelect: null,
            editedIndex: -1,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
            filteredClaveProdServ: null,
            filteredClaveUnidad: null,
            filteredObjImp: null,
        }
    },
    computed: {
        listaConceptos() {
            return this.$store.state.listaConceptosStore
        },
        claveProdServ() {
            return this.$store.state.claveProdServStore
        },
        claveUnidad() {
            return this.$store.state.claveUnidadStore
        },
        objImp() {
            return this.$store.state.objImpStore
        },
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        factura() {
            return this.$store.state.ingresoCartaPorteStore;
        },
        modoImporte() {
            return this.$store.state.modoImporteStore;
        }
    },
    created() {
        this.GetClaveProdServ();
        this.GetClaveUnidad();
        this.GetObjImp();
        this.GetTraslados();
        this.GetRetenciones();
        this.GetConceptos();
    },
    methods: {
        async GetClaveProdServ() {
            if (this.claveProdServ.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            if (this.claveUnidad.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            if (this.objImp.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTraslados() {
            if (this.traslados.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            if (this.retenciones.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async uploadImage(event) {
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.$store.state.conceptoStore.imagen.base64 = result;
            } catch (error) {
                console.error(error);
                return;
            }
        },

        async GetConceptos() {
            try {
                this.loadingItemsConceptos = true;
                let response = await axios.get('Conceptos/GetConceptos/' + this.empresa.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.concepto.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchClaveProdServ(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveProdServ = [...this.claveProdServ];
                }
                else {
                    this.filteredClaveProdServ = this.claveProdServ.filter((country) => {
                        return country.claveProdServ.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchClaveUnidad(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveUnidad = [...this.claveUnidad];
                }
                else {
                    this.filteredClaveUnidad = this.claveUnidad.filter((country) => {
                        return country.claveUnidad.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchObjImp(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredObjImp = [...this.objImp];
                }
                else {
                    this.filteredObjImp = this.objImp.filter((country) => {
                        return country.objImp.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        asignaConcepto() {
            this.concepto = { ...this.conceptoSelect }
            this.impuestos = { ...this.conceptoSelect.impuestos }
        },

        pushConcepto() {
            //VALIDAMOS
            if (this.concepto.claveProdServ.clave === '' || !this.concepto.claveProdServ.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Clave (SAT)"', life: 5000 });
                this.$refs._claveProdServ.$el.focus();
                return;
            }
            if (this.concepto.descripcion === '' || !this.concepto.descripcion) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Descripción"', life: 5000 });
                this.$refs._descripcion.$el.focus();
                return;
            }
            if (this.concepto.cantidad === '' || !this.concepto.cantidad || this.concepto.cantidad == 0) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Cantidad"', life: 5000 });
                this.$refs._cantidad.$el.focus();
                return;
            }
            if (this.concepto.claveUnidad.clave === '' || !this.concepto.claveUnidad.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Unidad SAT"', life: 5000 });
                return;
            }
            if (this.concepto.objImp.clave === '' || !this.concepto.objImp.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Objeto de impuestos (SAT)"', life: 5000 });
                return;
            }
            if (this.concepto.valorUnitario === '' || !this.concepto.valorUnitario || this.concepto.valorUnitario == 0) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Valor unitario"', life: 5000 });
                return;
            }

            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            if (this.editedIndex === -1) {
                this.factura.conceptos.push(objConcepto);

            } else {
                Object.assign(this.factura.conceptos[this.editedIndex], objConcepto)
            }

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.editedIndex = -1;
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
        },

        pushConceptoTotal() {
            let precio = this.concepto.precio;
            let sumT = this.sum(this.impuestos.traslados, 'tasaOCuota')
            let sumR = this.sum(this.impuestos.retenciones, 'tasaOCuota')
            let sumaImpuestos = ((sumT - sumR)) + 1
            let importeC = precio / sumaImpuestos;
            importeC = Math.trunc(importeC * 1000000) / 1000000;
            console.log(importeC)
            this.concepto.valorUnitario = importeC


            //VALIDAMOS
            if (this.concepto.claveProdServ.clave === '' || !this.concepto.claveProdServ.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Clave (SAT)"', life: 5000 });
                this.$refs._claveProdServ.$el.focus();
                return;
            }
            if (this.concepto.descripcion === '' || !this.concepto.descripcion) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Descripción"', life: 5000 });
                this.$refs._descripcion.$el.focus();
                return;
            }
            if (this.concepto.cantidad === '' || !this.concepto.cantidad || this.concepto.cantidad == 0) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Cantidad"', life: 5000 });
                this.$refs._cantidad.$el.focus();
                return;
            }
            if (this.concepto.claveUnidad.clave === '' || !this.concepto.claveUnidad.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Unidad SAT"', life: 5000 });
                return;
            }
            if (this.concepto.objImp.clave === '' || !this.concepto.objImp.clave) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Objeto de impuestos (SAT)"', life: 5000 });
                return;
            }
            if (this.concepto.valorUnitario === '' || !this.concepto.valorUnitario || this.concepto.valorUnitario == 0) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Valor unitario"', life: 5000 });
                return;
            }

            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            if (this.editedIndex === -1) {
                this.factura.conceptos.push(objConcepto);

            } else {
                Object.assign(this.factura.conceptos[this.editedIndex], objConcepto)
            }

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.editedIndex = -1;
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
        },

        deleteConcepto(item) {
            let indice = this.factura.conceptos.indexOf(item);
            this.factura.conceptos.splice(indice, 1);
            this.calcularImpuestos();
            this.sumatoria();

        },

        editConcepto(item) {
            this.conceptoSelect = null;
            this.impuestos = { ...item.impuestos }

            this.editedIndex = this.factura.conceptos.indexOf(item);
            this.concepto = { ...item }

        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        calcularImpuestos() {
            this.$store.state.ingresoCartaPorteStore.impuestos.traslados = []
            this.$store.state.ingresoCartaPorteStore.impuestos.retenciones = []
            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.factura.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {
                //VERIFICAMOS SI LLEVA IMPUESTOS
                if (a.objImp.clave === '02') {
                    for (let t of a.impuestos.traslados) {
                        // t.base_ = a.importe
                        // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                        sumaT = sumaT + t.importe
                        sumaT = Math.round(sumaT * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        let im = { ...t }
                        listTrasladados.push(im);
                        im = {}
                    }

                    for (let r of a.impuestos.retenciones) {
                        // r.base_ = a.impo  rte
                        // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                        let im = { ...r }
                        listRetenidos.push(im);
                        im = {}
                    }
                }


                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.ingresoCartaPorteStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.ingresoCartaPorteStore.impuestos.traslados.push(tra)
                }
                // console.log(this.$store.state.ingresoCartaPorteStore.impuestos.traslados)

                this.$store.state.ingresoCartaPorteStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.ingresoCartaPorteStore.impuestos.retenciones.push(ret)
                }
            }
        },

        sumatoria() {
            let indice = 1;
            for (let x of this.factura.conceptos) {
                x.item = indice;
                indice++;
            }

            this.factura.subTotal = Math.round(this.sum(this.factura.conceptos, 'importe') * 100) / 100;
            let tras = Math.round(this.sum(this.$store.state.ingresoCartaPorteStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.ingresoCartaPorteStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.factura.total = this.factura.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.idImpuesto };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },

    },
}
</script>