<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Seguro
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid ">
                    <!-- TIPO DE SEGURO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.tipo" :options="tipos" />
                            <label>Tipo de Seguro</label>
                        </span>
                    </div>
                    <!-- ASEGURADORA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="item.aseguradora" />
                            <label>Aseguradora</label>
                        </span>
                    </div>
                    <!-- NUMERO DE POLIZA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="item.numPoliza" />
                            <label>Número de Póliza</label>
                        </span>
                    </div>
                    <!-- PRIMA SEGURO -->
                    <div v-if="item.tipo == 'Carga Transportada'" class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputNumber v-model="item.primaSeguro" mode="decimal" locale="en-US" :minFractionDigits="2" />
                            <label>Pirma Seguro</label>
                        </span>
                    </div>
                    <!-- VIGENCIA -->
                    <div class="justify-right field col-12 ">
                        <span class="p-float-label">
                            <Calendar v-model="item.vigencia" dateFormat="dd-MM-yy" />
                            <label>Fecha de Vigencia</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

            tipos: ['Responsabilidad Civil', 'Daños al medio ambiente', 'Carga Transportada'],
        }
    },
    computed: {
        item() {
            this.$store.state.seguroStore.vigencia = new Date(this.$store.state.seguroStore.vigencia)
            return this.$store.state.seguroStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {

        guardarOactualizar() {
            if (this.$store.state.seguroStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando seguro, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostSeguros/' + this.empresa.empresaBase, this.item)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Seguro guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listSeguroStore.push(this.item)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando seguro, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateSeguros/' + this.empresa.empresaBase, this.item)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listSeguroStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listSeguroStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Seguro actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>