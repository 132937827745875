<template>
  <div >
    <img src="https://contago.com.mx/img/home/banner-footer.jpg">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

}
</script>

