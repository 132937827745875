import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    //VARIABLES
    empresaStore: {
      logo: {
        extencion: '',
        base64: '',
      },
      idEmpresa: 0,
      rfc: '',
      domicilioFiscal: '',
      nombre: '',
      regimenFiscal: {},
      notasVentaA: '',
      colores: {
        color: ''
      },
    },
    correoStore: {
      nombreCorreo: '',
      correo: '',
      password: '',
      puerto: '',
      host: '',
    },
    sellosStore: {
      rfc: '',
      csdContra: '',
      csdKey: {
        base64: '',
      },
      csdCer: {
        base64: '',
      },
    },

    // PARA CANCELAR

    cancelacionStore: {
    },

    //CONCEPTOS
    conceptoStore: {
      idDocumento: 0,
      idConcepto: 0,
      claveProdServ: {
        claveProdServ: '',
      },
      noIdentificacion: '',
      cantidad: 1,
      claveUnidad: {
        claveUnidad: '',
      },
      unidad: '',
      descripcion: '',
      valorUnitario: 0,
      importe: 0,
      descuento: 0,
      precio: 0,
      total: 0,
      objImp: {},
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      numeroCuentaPredial: '',
      material: '',
      imagen: {
        base64: '',
      },
      linea: '',
      marca: '',
      submarca: '',
      iepsGas: 0,
      almacenable: 'NO',
      estatus: '',
      usuarioCreador: '',
      usuarioModificador: '',
      concepto: '',
    },
    listaConceptosStore: [],
    listaLineaStore: [],
    listaMarcaStore: [],
    listaSubmarcaStore: [],

    //IMPUESTOS
    trasladosStore: [],
    retencionesStore: [],

    //CLAVES DEL SAT
    claveProdServStore: [],
    claveUnidadStore: [],
    objImpStore: [],
    formaPagoStore: [],
    monedaStore: [],
    metodoPagoStore: [],
    usoCfdiStore: [],

    //CLAVES DEL SAT COMERCIO EXTERIOR
    motivoTrasladoStore: [],
    tipoOperacionStore: [],
    claveDePedimentoStore: [],
    certificadoOrigenStore: [],
    incotermStore: [],

    // CLIENTES
    clienteStore: {
      idCliente: 0,
      rfc: '',
      nombre: '',
      domicilioFiscal: '',
      regimenFiscal: {
        regimenFiscal: '',
      },
      usoCfdi: {
        usoCfdi: '',
      },
      direccion: '',
      usuarioCreador: '',
      residenciaFiscal: {
        pais: '',
      },
      correos: [],
      numRegIdTrib: '',
    },

    //CATALOGOS DEL SAT
    regimenFiscalStore: [],
    listUsoCfdiStore: [],
    residenciaFiscalStore: [],
    listClienteStore: [],
    listTipoRelacion: [],
    listMotivosCancelacionStore: [],

    // ALMACENES
    listaAlmacenesStore: [],
    almacenStore: {
      idAlmacen: 0,
      clave: '',
      descripcion: '',
      direccion: '',
      tipo: '',
    },

    //USUARIOS
    listaUsuariosInternosStore: [],
    usuarioInternosStore: {
      idUsuario: 0,
      rfc: '',
      curp: '',
      nombre: '',
      usuario: '',
      pin: '',
      puesto: '',
      departamento: '',
      serie: '',
      permisos: [],
      telefonos: [],
      correos: [],
      foto: {
        base64: '',
      },
      almacen: {},
    },

    //VENTAS PEDIDOS
    listVentaPedidoStore: [],
    listVentaPedidoSerieStore: [],
    ventaPedidoStore: {
      idVenta: -1,
      serie: '',
      folio: '',
      fecha: null,
      vigencia: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'Pedido',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
    },

    //PROTOCOLOS
    listProtocoloRemisionesStore: [],
    listProtocolosStore: [],
    listVentaPedidoSerieStore: [],
    protocoloStore: {
      idVentaProtocolo: -1,
      idVentaRemision: 0,
      serie: '',
      folio: '',
      fecha: null,
      vigencia: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'Protocolo',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      proyecto: {
        descripcion: '',
        presupuesto: 0
      },
      estatusFactura: 'SinFacturar'
    },

    //LISTA  DE PROYECTOS
    listProyectosStore: [],
    proyectoStore: {
      idProyecto: 0,
      descripcion: '',
      presupuesto: 0,
      asignado: 0,
      usuarioCreador: '',
      usuarioModificador: '',
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
      }
    },

    //FACTURA
    modoImporteStore: true,
    timbresDisponiblesStore: 0,
    listFacturaStore: [],
    listFacturaSerieStore: [],
    facturaStore: {
      idFactura: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'Factura',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }
    },

    //NOTA DE CREDITO
    listNotaCreditoStore: [],
    listNotaCreditoSerieStore: [],
    notaCreditoStore: {
      idNotaCredito: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'E',
      tipoComprobanteInterno: 'NC',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }
    },

    //ARRENDAMIENTO
    listArrendamientoStore: [],
    listArrendamientoSerieStore: [],
    arrendamientoStore: {
      idFactura: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'Arrendamiento',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }
    },

    //COMPLEMENTO DE PAGO
    listComplementoPagoStore: [],
    listComplementoPagoSerieStore: [],
    complementoPagoStore: {
      idComplementoPago: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 174,
        clave: 'XXX',
        descripcion: 'Los códigos asignados para las transacciones en que intervenga ninguna moneda',
        moneda: 'XXX | Los códigos asignados para las transacciones en que intervenga ninguna moneda'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'P',
      tipoComprobanteInterno: 'Complemento Pago',
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [
        {
          item: 1,
          idConcepto: 0,
          claveProdServ: { 'id': 51586, 'clave': '84111506', 'descripcion': 'Servicios de facturación', 'palabrasSimilares': '', 'claveProdServ': '84111506 | Servicios de facturación' },
          noIdentificacion: '',
          cantidad: 1,
          claveUnidad: { 'id': 241, 'clave': 'ACT', 'descripcion': 'Actividad', 'claveUnidad': 'ACT | Actividad' },
          unidad: '',
          descripcion: 'Pago',
          valorUnitario: 0,
          importe: 0,
          descuento: 0,
          precio: 0,
          total: 0,
          objImp: { 'id': 3, 'clave': '01', 'descripcion': 'No objeto de impuesto.', 'objImp': '01 | No objeto de impuesto.' },
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          numeroCuentaPredial: '',
        }
      ],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      pagos: {
        version: '2.0',
        totales: {
          totalRetencionesIVA: 0,
          totalRetencionesISR: 0,
          totalRetencionesIEPS: 0,
          totalTrasladosBaseIVA16: 0,
          totalTrasladosImpuestoIVA16: 0,
          totalTrasladosBaseIVA8: 0,
          totalTrasladosImpuestoIVA8: 0,
          totalTrasladosBaseIVA0: 0,
          totalTrasladosImpuestoIVA0: 0,
          totalTrasladosBaseIVAExento: 0,
          montoTotalPagos: 0,
        },
        pagos: [{
          fechaPago: new Date(),
          formaDePagoP: {},
          monedaP: {
            id: 100,
            clave: 'MXN',
            descripcion: 'Peso Mexicano',
            moneda: 'MXN | Peso Mexicano'
          },
          tipoCambioP: 0,
          monto: 0,
          numOperacion: '',
          rfcEmisorCtaOrd: '',
          nomBancoOrdExt: '',
          ctaOrdenante: '',
          rfcEmisorCtaBen: '',
          ctaBeneficiario: '',
          tipoCadPago: {
            clave: '',
          },
          certPago: {
            base64: '',
          },
          cadPago: {
            base64: '',
          },
          selloPago: {
            base64: '',
          },
          doctoRelacionados: [
            { item: 1, }
          ],
          impuestosP: {
            traslados: [],
            retenciones: []
          },
        }],
      },
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }
    },

    //COMERCIO EXTERIOR
    listComercioExteriorStore: [],
    listComercioExteriorSerieStore: [],
    comercioExteriorStore: {
      idComprobante: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: new Date(),
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'CE',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      comercio: {
        version: '1.1',
        motivoTraslado: {},
        tipoOperacion: {},
        claveDePedimento: {},
        certificadoOrigen: {},
        numCertificadoOrigen: '',
        numeroExportadorConfiable: '',
        incoterm: {},
        subdivision: '',
        observaciones: '',
        tipoCambioUSD: 0,
        totalUSD: 0,
      },
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      },
    },

    //VENTAS COTIZACION
    listVentaCotizacionStore: [],
    listVentaCotizacionSerieStore: [],
    ventaCotizacionStore: {
      idVenta: -1,
      serie: '',
      folio: '',
      fecha: null,
      vigencia: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'Cotizacion',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      proyecto: {
        descripcion: '',
        presupuesto: 0
      },
      imagenGeneral: { base64: '' }
    },

    //VENTAS REMISION
    listVentaRemisionStore: [],
    listVentaRemisionSerieStore: [],
    ventaRemisionStore: {
      idVenta: -1,
      serie: '',
      folio: '',
      fecha: null,
      vigencia: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'Remision',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      proyecto: {
        descripcion: '',
        presupuesto: 0
      }
    },
    //CORREO
    correoStore: {
      asunto: '',
      mensaje: '',
      emisor: {},
      archivos: [],
      receptores: [],
    },

    //VEHICULO
    listVehiculoStore: [],
    vehiculoStore: {
      id: -1,
      placaVM: '',
      anioModelo: 0,
      configAutotransporte: {},
      usuarioCreador: '',
      usuarioModificador: '',
    },

    // REMOLQUES
    listRemolqueStore: [],
    remolqueStore: {
      id: -1,
      placa: '',
      subTipoRem: {},
      usuarioCreador: '',
      usuarioModificador: '',
    },

    listPermisoSCTStore: [],
    permisoSCTStore: {
      id: -1,
      numPermisoSCT: '',
      permSCT: {
        permSCT: ''
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },

    listSeguroStore: [],
    seguroStore: {
      id: -1,
      aseguradora: '',
      numPoliza: '',
      tipo: '',
      primaSeguro: 0,
      vigencia: null,
      usuarioCreador: '',
      usuarioModificador: ''
    },

    listMercanciaStore: [],
    mercanciaStore: {
      id: -1,
      materialPeligroso: '',
      cveMaterialPeligroso: {
        materialPeligroso: ''
      },
      bienesTransp: {
        claveProdServCP: ''
      },
      descripcion: '',
      claveUnidad: { claveUnidad: '' },
      unidad: '',
      embalaje: { tipoEmbalaje: '' },
      descripEmbalaje: '',
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },

    listOrigenStore: [],
    listDestinoStore: [],
    ubicacionStore: {
      id: -1,
      tipoUbicacion: '',
      iDUbicacion: '',
      rfcRemitenteDestinatario: '',
      nombreRemitenteDestinatario: '',
      numRegIdTrib: '',
      residenciaFiscal: {
        pais: '',
      },
      domicilio: {
        domicilio: ''
      },
      usuarioCreador: '',
      usuarioModificador: '',

    },
    listaPaisStore: [],
    listDireccionStore: [],
    direccionStore: {
      id: -1,
      codigoPostal: '',
      calle: '',
      numeroExterior: '',
      numeroInterior: '',
      colonia: {
        colonia: '',
      },
      localidad: {
        localidad: '',
      },
      municipio: {
        municipio: '',
      },
      estado: {
        estado: '',
      },
      pais: {
        pais: '',
      },
      referencia: '',
      usuarioCreador: '',
      usuarioModificador: ''
    },

    // FIGURAS DE TRANSPORTE
    listFigurasTransporteStore: [],
    figuraTransporteStore: {
      id: -1,
      tipoFigura: {
        clave: '',
        figuraTransporte: '',
      },
      rfcFigura: '',
      numLicencia: '',
      nombreFigura: '',
      numRegIdTribFigura: '',
      residenciaFiscalFigura: {
        pais: '',
      },
      partesDelTransporte: {
        parteTransporte: '',
      },
      domicilio: {
        domicilio: ''
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },

    // AUTOTRANSPORTE
    listAutotransporteStore: [],
    autotransporteStore: {
      id: -1,
      permSCT: {
        numPermisoSCT: '',
      },
      identificacionVehicular: {
        placaVM: ''
      },
      seguroCivil: {
        seguro: '',
      },
      seguroAmbiente: {
        seguro: '',
      },
      seguroCarga: {
        seguro: '',
      },
      remolqueA: {
        placa: ''
      },
      remolqueB: {
        placa: ''
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },

    //INGRESO CARTA PORTE
    listIngresoCPSerieStore: [],
    listIngresoCartaPorteStore: [],
    ingresoCartaPorteStore: {
      id: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: new Date(),
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'IngresoCP',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      estatusCancelacion: '',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      },
      cartaPorte: {
        version: 2,
        transpInternac: 'NO',
        entradaSalidaMerc: '',
        viaEntradaSalida: {
          transporte: ''
        },
        paisOrigenDestino: {
          pais: ''
        },
        totalDistRec: 0,
        ubicacionOrigen: {
          ubicacion: '',
          fecha: new Date(),
          domicilio: {
            domicilio: ''
          }
        },
        ubicacionesDestino: [],
        mercancia: {
          pesoBrutoTotal: 0,
          unidadPeso: {
            clave: "KGM",
            claveUnidadPeso: "KGM | Kilogramo",
            nombre: "Kilogramo",
          },
          numTotalMercancias: 0,
        },
        mercancias: [],
        autotransporte: {
          id: 0,
          folioFiscal: '',
          permSCT:
          {
            id: 2,
            permSCT:
            {
              clave: '',
              descripcion: '',
              claveTransporte: '',
              permSCT: ''
            },
            numPermisoSCT: '',
            usuarioCreador: '',
            usuarioModificador: '',
          },
          identificacionVehicular:
          {
            id: 0,
            placaVM: '',
            anioModelo: 0,
            configAutotransporte:
            {
              clave: '',
              descripcion: '',
              numeroDeEjes: '',
              numeroDeLlantas: '',
              remolque: '',
              configAutotransporte: ''
            },
            estatus: '',
            usuarioCreador: '',
            usuarioModificador: ''
          },
          seguroCivil:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            // fechaCreacion: 0001-01-01T00:00:00, 
            // fechaModificacion: 0001-01-01T00:00:00, 
            seguro: null
          },
          seguroAmbiente:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            seguro: null
          },
          seguroCarga:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            seguro: null
          },
          remolqueA:
          {
            id: 0,
            subTipoRem: {
              clave: '',
              remolqueOSemirremolque: '',
              subTipoRem: ''
            },
            placa: '',
            usuarioCreador: null,
            usuarioModificador: null,
          },
          remolqueB: {
            id: 0,
            subTipoRem:
            {
              clave: '',
              remolqueOSemirremolque: '',
              subTipoRem: ''
            },
            placa: '',
            usuarioCreador: null,
            usuarioModificador: null,
          },
          seguros: [],
          remolques: [],
          usuarioCreador: null,
          usuarioModificador: null,
          autotransporte: ''
        },
        figuraDelTransporte: []
      }
    },

    // COMERCIO EXTERIOR EMISOR
    listCEEmisorStore: [],
    cEEmisorStore: {
      id: -1,
      nombre: '',
      curp: '',
      domicilio: {
        domicilio: ''
      },
      usuarioCreador: '',
      usuarioModificador: '',
    },
    //COMERCIO EXTERIOR PROPIETARIO
    listCEPropietarioStore: [],
    cEPropietarioStore: {
      id: -1,
      nombre: '',
      numRegIdTrib: '',
      residenciaFiscal: {
        pais: '',
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },
    //COMERCIO EXTERIOR RECEPTOR
    listCEReceptorStore: [],
    cEReceptorStore: {
      id: -1,
      nombre: '',
      numRegIdTrib: '',
      domicilio: {
        domicilio: ''
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },
    //COMERCIO EXTERIOR DESTINATARIO
    listCEDestinatarioStore: [],
    cEDestinatarioStore: {
      id: -1,
      nombre: '',
      numRegIdTrib: '',
      domicilio: {
        domicilio: ''
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },
    //COMERCIO EXTERIOR MERCANCIAS
    listCEMercanciaStore: [],
    cEMercanciaStore: {
      id: -1,
      descripcion: '',
      noIdentificacion: '',
      fraccionArancelaria: {
        fraccion: ''
      },
      cantidadAduana: 0,
      unidadAduana: {
        unidadAduana: ''
      },
      valorUnitarioAduana: 0,
      valorDolares: 0,
      descripciones: {
        marca: '',
        modelo: '',
        subModelo: '',
        numeroSerie: '',
      },
      usuarioCreador: '',
      usuarioModificador: ''
    },
    // UNIDAD ADUANA
    listUnidadAduanaStore: [],
    // FRACCION ARANCELARIA
    listFraccionArancelariaStore: [],

    // TRASLADO CARTA PORTE
    listTrasladoCPSerieStore: [],
    listTrasladoCartaPorteStore: [],
    trasladoCartaPorteStore: {
      id: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: new Date(),
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 174,
        clave: "XXX",
        descripcion: "Los códigos asignados para las transacciones en que intervenga ninguna moneda",
        moneda: "XXX | Los códigos asignados para las transacciones en que intervenga ninguna moneda"
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'T',
      tipoComprobanteInterno: 'TrasladoCP',
      metodoPago: {},
      empresa: {},
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {
          clave: "S01",
          descripcion: "Sin efectos fiscales.",
          fisica: "Sí",
          moral: "Sí",
          usoCfdi: "S01 | Sin efectos fiscales."
        },
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      },
      cartaPorte: {
        version: 2,
        transpInternac: 'NO',
        entradaSalidaMerc: '',
        viaEntradaSalida: {
          transporte: ''
        },
        paisOrigenDestino: {
          pais: ''
        },
        totalDistRec: 0,
        ubicacionOrigen: {
          ubicacion: '',
          fecha: new Date(),
          domicilio: {
            domicilio: ''
          }
        },
        ubicacionesDestino: [],
        mercancia: {
          pesoBrutoTotal: 0,
          unidadPeso: {
            clave: "KGM",
            claveUnidadPeso: "KGM | Kilogramo",
            nombre: "Kilogramo",
          },
          numTotalMercancias: 0,
        },
        mercancias: [],
        autotransporte: {
          id: 0,
          folioFiscal: '',
          permSCT:
          {
            id: 2,
            permSCT:
            {
              clave: '',
              descripcion: '',
              claveTransporte: '',
              permSCT: ''
            },
            numPermisoSCT: '',
            usuarioCreador: '',
            usuarioModificador: '',
          },
          identificacionVehicular:
          {
            id: 0,
            placaVM: '',
            anioModelo: 0,
            configAutotransporte:
            {
              clave: '',
              descripcion: '',
              numeroDeEjes: '',
              numeroDeLlantas: '',
              remolque: '',
              configAutotransporte: ''
            },
            estatus: '',
            usuarioCreador: '',
            usuarioModificador: ''
          },
          seguroCivil:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            seguro: null
          },
          seguroAmbiente:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            seguro: null
          },
          seguroCarga:
          {
            id: 0,
            aseguradora: '',
            tipo: '',
            numPoliza: '',
            primaSeguro: 0,
            vigencia: new Date(),
            usuarioCreador: '',
            usuarioModificador: '',
            seguro: null
          },
          remolqueA:
          {
            id: 0,
            subTipoRem: {
              clave: '',
              remolqueOSemirremolque: '',
              subTipoRem: ''
            },
            placa: '',
            usuarioCreador: null,
            usuarioModificador: null,
          },
          remolqueB: {
            id: 0,
            subTipoRem:
            {
              clave: '',
              remolqueOSemirremolque: '',
              subTipoRem: ''
            },
            placa: '',
            usuarioCreador: null,
            usuarioModificador: null,
          },
          seguros: [],
          remolques: [],
          usuarioCreador: null,
          usuarioModificador: null,
          autotransporte: ''
        },
        figuraDelTransporte: []
      },

    },

    // PUNTO DE VENTA
    usuarioVentasStore: {
      idUsuario: -1,
      usuario: '',
      folio: 0
    },

    turnoStore: {
      idTurno: 0,
      idUsuario: 0,
      efectivoInicial: 0.00,
      caja: { nombreCajas: 'Caja 01', caja: 1 },
      efectivoFinal: 0.00
    },

    listVentasStore: [],
    ventaStore: {
      idComprobante: -1,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'PuntoVenta',
      metodoPago: { id: 1, clave: "PUE", metodoPago: "PUE | Pago en una sola exhibición", descripcion: "Pago en una sola exhibición" },
      empresa: {},
      cliente: {
        rfc: 'XAXX010101000',
        nombre: 'PUBLICO EN GENERAL',
        cliente: 'XAXX010101000 | PUBLICO EN GENERAL',
        domicilioFiscal: '',
        usoCfdi: {
          id: 22,
          clave: "S01",
          moral: "Sí",
          fisica: "Sí",
          usoCfdi: "S01 | Sin efectos fiscales.",
          descripcion: "Sin efectos fiscales."
        },
        regimenFiscal: {
          id: 11,
          clave: "616",
          moral: "Sí",
          fisica: "No",
          descripcion: "Sin obligaciones fiscales",
          regimenFiscal: "616 | Sin obligaciones fiscales"
        },
        correos: []
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      idTurno: 0,
      usuarioVentas: '',
      pagoCon: 0.00,
      cambioTotal: 0.00,
      formasPago: [],
    },
    validaUsuarioPuntoVenta: 0,
    //ADDENDAS 
    addendaVwPsvStore: {
      id: -1,
    },
    divisionVwPsvStore: [],
    tipoDocumentoFiscalVwPsvStore: [],
    tipoDocumentoVWMVwPsvStore: [{ clave: 'PSV' }],
    codigoImpuestoVwPsvStore: [],
    tipoMonedaVwPsvStore: [],
    origenVwPsvStore: [],
    proveedorVwPsvStore: [],
    solicitanteVwPsvStore: [],

    // PROVEEDOR VW
    listProveedorVwStore: [],
    proveedorVwStore: {
      idProveedor: 0,
      codigo: '',
      correoContacto: '',
      nombre: '',
      usuarioCreador: '',
    },
    // SOLICITANTE VW
    listSolicitanteVwStore: [],
    solicitanteVwStore: {
      idSolicitante: 0,
      correo: '',
      nombre: '',
      usuarioCreador: '',
    },
    //BANCOS DE EMPRESA
    listBancosEmpresa: [],
    bancosEmpresaStore: {
      idCatalogoBancos: 0,
      cuenta: '',
      jsonBancosSat: {
        banco: '',
        clave: '',
        descripcion: '',
      },
    },

    //DEPOSITOS/RETIROS
    listMovimientos: [],
    movimientosStore: {
      idMovimientosBanco: 0,
      referencia: '',
      fechaMovimiento: new Date(),
      monto: 0,
      notas: '',
      moneda: {},
      cliente: {},
      comprobantes: [],
      jsonBancosSat: {},
      tipoMovimiento: '',
      usuarioCreador: '',
    },

    // COMPROBANTES MOVIMIENTO
    listComprobantesMov: [],

    //PROVEEDORES 
    listaProveedorStore: [],
    proveedorStore: {
      idProveedor: 0,
      rfc: '',
      nombre: '',
      nombreComercial: '',
      correo: '',
      telefono: '',
      contacto: '',
      direccion: '',
      proveedor: ''
    },


    // COMPRAS
    listComprasPedidoSerieStore: [],
    listaComprasPedidoStore: [],
    comprasPedidoStore: {
      idCompra: 0,
      serie: '',
      folio: '',
      fecha: null,
      tiempoEntrega: '',
      tipoComprobante: 'Pedido',
      empresa: {},
      proveedor: {
        idProveedor: 0,
        rfc: '',
        nombre: '',
        nombreComercial: '',
        correo: '',
        telefono: '',
        contacto: '',
        direccion: '',
        proveedor: ''
      },
      rfc: '',
      nombre: '',
      proyecto: {
        descripcion: '',
        presupuesto: 0
      },
      solicitante: '',
      email: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      conceptos: [],
      condicionesEntrega: '',
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      idRelacion: 0,
      serieR: '',
      folioR: '',
      total: 0,
      subTotal: 0
    },

    // COMPRAS COTIZACIONES
    listComprasCotizacionSerieStore: [],
    listaComprasCotizacionStore: [],
    comprasCotizacionStore: {
      idCompra: -1,
      serie: '',
      folio: '',
      fecha: null,
      tiempoEntrega: '',
      tipoComprobante: 'Cotización',
      empresa: {},
      proveedor: {
        idProveedor: 0,
        rfc: '',
        nombre: '',
        nombreComercial: '',
        correo: '',
        telefono: '',
        contacto: '',
        direccion: '',
        proveedor: ''
      },
      rfc: '',
      nombre: '',
      proyecto: {
        descripcion: '',
        presupuesto: 0
      },
      solicitante: '',
      email: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      conceptos: [],
      condicionesEntrega: '',
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      idRelacion: 0,
      serieR: '',
      folioR: '',
      total: 0,
      subTotal: 0
    },

    listaComprasOrdenComprasStore: [],
    listComprasOrdenComprasSerieStore: [],
    comprasOrdenCompraStore: {
      idCompra: 0,
      serie: '',
      folio: '',
      fecha: null,
      tiempoEntrega: '',
      tipoComprobante: 'OrdenCompra',
      empresa: {},
      proveedor: {
        idProveedor: 0,
        rfc: '',
        nombre: '',
        nombreComercial: '',
        correo: '',
        telefono: '',
        contacto: '',
        direccion: '',
        proveedor: ''
      },
      rfc: '',
      nombre: '',
      proyecto: {
        descripcion: '',
        presupuesto: 0
      },
      solicitante: '',
      email: '',
      notas: '',
      estatus: 'Nuevo',
      usuarioCreador: '',
      usuarioModificador: '',
      conceptos: [],
      condicionesEntrega: '',
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      idRelacion: 0,
      serieR: '',
      folioR: '',
      total: 0,
      subTotal: 0,
      fechaEntrega: null
    },

    //INVENTARIOS
    listMovimientos: [],
    listaESAlmacenStore: [],

    //VENTA GAS
    listVentasGasStore: [],
    listVentasStore: [],
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    },

  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    },

  },
  modules: {
  }
})
