<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarArchivo" accept=".pdf, .zip, .xls"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
        </div>
        <DataTable :value="addenda.archivos" :scrollable="true" scrollHeight="500px" scrollDirection="both" class="mt-1">

            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '10px' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="deleteArchivo(slotProps.data)" />
                </template>
            </Column>
            <Column field="nombre" header="Nombre" class="flex" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
            </Column>
            <Column field="tipo" header="Tipo" class="flex" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
            </Column>
        </DataTable>
</div>
</template>
<script>

import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        addenda() {
            return this.$store.state.addendaVwPsvStore;
        },
    },
    created() {

    },
    methods: {
        deleteArchivo(item) {
            let indice = this.addenda.archivos.indexOf(item);
            this.addenda.archivos.splice(indice, 1);
        },

        async cargarArchivo(event) {
            if (this.addenda.archivos.length == 3) {
                this.$toast.add({ severity: 'error', detail: 'Sólo se permiter cargar 3 archivos', life: 5000 });
                return;
            }

            let selectArchivo = {
                nombre: '',
                datos: {
                    base64: '',
                },
                tipo: '',
            }
            const file = event.files[0];
            const fileName = file.name;
            let extension = fileName.split('.').pop();
            try {
                const result = await this.convertBase64(file);
                selectArchivo.datos.base64 = result.split(',')[1];
                selectArchivo.tipo = extension;
                selectArchivo.nombre = fileName;
                //CARGAMOS EL ARCHIVO
                this.addenda.archivos.push(selectArchivo);
                selectArchivo = {};
            } catch (error) {
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
    },
}
</script>