<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- ENLISTA LOS MOVIMIENTOS -->
        <Dialog :visible.sync="dialogMovimientos" :closeOnEscape="true" :closable="false" :style="{ width: '90vw' }">
            <Movimientos @closeMovimiento="closeDialogMovimientos()"></Movimientos>
        </Dialog>

        <DataTable :value="listAlmacen" :scrollable="true" scrollHeight="400px" scrollDirection="both">
            <template #header>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- ENCABEZADO -->
                    <div class="col-6 text-left">
                        <h1>Inventario por Almacén </h1>
                    </div>
                    <!-- BOTONES -->
                    <div class="field col-6 text-right">
                        <Button icon="pi pi-replay" class="p-button-rounded p-button-info p-button-outlined"
                            @click="GetAlmacenes" />
                    </div>
                </div>
            </template>
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-eye" class="p-button-rounded p-button-info" v-tooltip.bottom="'Consultar Almacén'"
                        @click="GetMovimientos(slotProps.data)" />
                </template>
            </Column>
            <Column field="clave" header="Clave" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }" sorteable>
            </Column>
            <Column field="descripcion" header="Descrpción" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
            <Column field="direccion" header="Dirección" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
            <Column field="tipo" header="Tipo" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import Movimientos from './MovimientosInventario.vue'
export default {
    components: {
        Movimientos,
    },
    data() {
        return {
            dialogMovimientos: false,
            loading: false,
            loadingMensaje: '',
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        listAlmacen() {
            return this.$store.state.listaAlmacenesStore
        },
    },
    created() {
        this.GetAlmacenes();
    },
    methods: {
        async GetAlmacenes() {
            this.loadingAlmacenes = true;
            try {
                let response = await axios.get('Almacenes/GetAlmacenes/' + this.token.empresaBase)
                this.$store.state.listaAlmacenesStore = response.data
                console.log(response)
                this.loadingAlmacenes = false;

            } catch (error) {
                console.log(error)
                this.loadingAlmacenes = false;

            }
        },

        closeDialogMovimientos() {
            this.dialogMovimientos = false;
        },
    },
}
</script>