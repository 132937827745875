<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- ENLISTA LOS MOVIMIENTOS -->
        <Dialog :visible.sync="dialogMovimientos" :closeOnEscape="true" :closable="false" :style="{ width: '90vw' }"
            :modal="true">
            <Movimientos @closeMovimiento="closeDialogMovimientos()"></Movimientos>
        </Dialog>
        <Dialog :visible.sync="vistaPrevia" :closeOnEscape="true" :closable="true">
            <ImagePreview :src="ImagenBase64" alt="Image" width="500" preview />
        </Dialog>

        <!-- DIALOGO PARA ASIGNAR COMPROBANTES -->
        <Dialog styleClass="modalResponsivo" :visible.sync="dialogoAsignarComprobante" :closable="true"
            :containerStyle="{ width: '90vw' }" :showHeader="false" :modal="true">

            <div class="text-left mt-3 mb-2 p-d-flex  p-fluid grid formgrid">
                <div class=" text-left col-2 md:col-2">
                    <Button icon="mdi mdi-close" class="p-button-rounded p-button-danger p-button-text"
                        @click="dialogoAsignarComprobante = false" />
                </div>
                <div class="col-8 md:col-8 text-center">
                    <h2>{{ heaederProveedor }}</h2>
                </div>
                <div class=" text-right col-2 md:col-2">
                    <Button icon="mdi mdi-content-save" class="p-button-rounded" @click="asignarComprobamtes()" />
                </div>
            </div>
            <hr size=7 color="#ED6421">

            <DataTable :value="listaComprobantes" :paginator="true" class="p-datatable-customers" :rows="10"
                dataKey="folioFiscal" :filters.sync="filtros" filterDisplay="row" :loading="loadingComprobantes"
                responsiveLayout="scroll" :globalFilterFields="['folioFiscal', 'serie', 'folio', 'fecha']"
                :selection.sync="selectComprobantes" scrollDirection="both" :scrollable="true">
                <Column selectionMode="multiple" :headerStyle="{ 'min-width': '3rem' }" frozen></Column>
                <Column field="folioFiscal" header="Folio Fiscal" :styles="{ 'min-width': '25rem' }">
                    <template #body="{ data }">
                        {{ data.folioFiscal }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                            class="p-column-filter" placeholder="Buscar por Folio Fiscal"
                            v-tooltip.top.focus="'Presiona Enter para filtrar'" />
                    </template>
                </Column>
                <Column header="Serie" filterField="serie" :styles="{ 'min-width': '20rem' }">
                    <template #body="{ data }">
                        <span>{{ data.serie }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"
                            placeholder="Buscar por Serie" v-tooltip.top.focus="'Presiona Enter para filtrar'" />
                    </template>
                </Column>
                <Column header="Folio" filterField="folio" :styles="{ 'min-width': '20rem' }">
                    <template #body="{ data }">
                        <span>{{ data.folio }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"
                            placeholder="Buscar Folio" v-tooltip.top.focus="'Presiona Enter para filtrar'" />
                    </template>
                </Column>
                <Column header="Fecha" filterField="fecha" :styles="{ 'min-width': '20rem' }">
                    <template #body="{ data }">
                        <span>{{ data.fecha }}</span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"
                            placeholder="Buscar fecha" v-tooltip.top.focus="'Presiona Enter para filtrar'" />
                    </template>
                </Column>
                <Column header="Forma de Pago" field="formaPago.formaPago" :styles="{ 'min-width': '22rem' }">
                    <template #body="{ data }">
                        <span>{{ data.formaPago.formaPago }}</span>
                    </template>
                </Column>
                <Column header="SubTotal" field="subTotal" :styles="{ 'min-width': '12rem', 'text-align': 'right' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.subTotal) }}</span>
                    </template>
                </Column>
                <Column header="Descuento" field="descuento" :styles="{ 'min-width': '12rem', 'text-align': 'right' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.descuento) }}</span>
                    </template>
                </Column>
                <Column header="Total" field="total" :styles="{ 'min-width': '12rem', 'text-align': 'right' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.total) }}</span>
                    </template>
                </Column>
                <Column header="Moneda" field="moneda.moneda" :styles="{ 'min-width': '18rem' }">
                    <template #body="{ data }">
                        <span>{{ data.moneda.moneda }}</span>
                    </template>
                </Column>
            </DataTable>
        </Dialog>

        <!-- DIALOG CARPETA DE ARCHIVOS -->
        <Dialog :visible.sync="dialogCarpetaArchivos" header="Carpeta de Documentos Asignados" :closeOnEscape="true"
            :closable="true" :containerStyle="{ width: '920px' }" position="center" :modal="true">
            <!-- <div class="field col-12 pb-0 mb-0 pt-0 mt-0">
                <hr size=7 color="#E64747">
            </div> -->
            <TabView>
                <TabPanel header="Archivos">
                    <DataTable class="ocult" :value="listaArchivos" responsiveLayout="scroll">
                        <Column header="">
                            <template #body="slotProps">
                                <template v-if="slotProps.data.base64 != ''">
                                    <Button @click="verDocumento(slotProps.data)" icon="mdi mdi-file-eye-outline"
                                        class="p-button-rounded p-button-info" v-tooltip.bottom="'Ver Documentos'" />
                                </template>
                                <Button @click="eliminarDocumento(slotProps.data)" icon="mdi mdi-trash-can"
                                    class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar'" />
                            </template>

                        </Column>
                        <Column field="folio" header=""></Column>
                        <Column field="nombreArchivo" header=""></Column>
                        <Column field="total" header="" :bodyStyle="{ 'justify-content': 'right' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.total) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Comprobantes">
                    <DataTable :value="listaComprobantesAsignados" dataKey="folioFiscal" responsiveLayout="scroll">
                        <Column header="">
                            <template #body="slotProps">
                                <Button @click="eliminarComprobante(slotProps.data)" icon="mdi mdi-trash-can"
                                    class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar'" />
                            </template>
                        </Column>

                        <Column field="folioFiscal" header="Folio Fiscal" :styles="{ 'min-width': '25rem' }">
                        </Column>
                        <Column header="SubTotal" field="subTotal"
                            :styles="{ 'min-width': '12rem', 'text-align': 'right', 'background-color': '#2196f326', }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.subTotal) }}</span>
                            </template>
                        </Column>
                        <Column header="Total" field="total"
                            :styles="{ 'min-width': '12rem', 'text-align': 'right', 'background-color': '#effaf1' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.total) }}</span>
                            </template>
                        </Column>

                    </DataTable>
                </TabPanel>
            </TabView>
        </Dialog>

        <!-- DIALOGO PARA SUBIR NOTA -->
        <Dialog :visible.sync="dialogExplorador" :closable="true" header="Cargar Notas" :containerStyle="{ width: '600px' }"
            :modal="true">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarNota" accept=".pdf, image/*"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
        </Dialog>

        <!-- CARGAR NOTAS-->
        <Dialog styleClass="modalResponsivo" header="Asignar Nota" :visible.sync="dialoCargarNotas" :closable="true"
            :containerStyle="{ width: '700px', height: 'auto' }" :modal="true">
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <div class=" field col-12 md:col-12 ">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputText style="text-align: left" type="text" v-model="nota.folio" />
                            <label>Folio</label>
                        </span>
                    </div>
                </div>
                <!-- FECHA -->
                <div class="field col-12 md:col-12 sm:col-12">
                    <span class="p-float-label">
                        <Calendar dateFormat="dd-MM-yy" v-model="nota.fecha" />
                        <label>Fecha</label>
                    </span>
                </div>
                <!-- TOTAL -->
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputNumber mode="decimal" locale="en-US" v-model="nota.total" :minFractionDigits="6" />
                        <label>Total</label>
                    </span>
                </div>
                <div class="md:col-12 col-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <Checkbox :binary="true" v-model="validaArchivo" />
                        </span>
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <InputText v-model="nota.nombreArchivo" readonly />
                                <label>Archivo</label>
                            </span>
                            <Button icon="mdi mdi-upload" class="p-button-success" @click="cargarArchivo()" />
                        </div>
                    </div>
                </div>
                <div class="md:col-12 col-12">
                    <Button label="Guardar" @click="guardarNotas()" />
                </div>
            </div>
        </Dialog>

        <!-- DIALOGO PARA AUTORIZAR -->
        <Dialog :visible.sync="autorizarProducto" :closable="false" :containerStyle="{ width: '390px' }"
            header="¿Quien Autoriza?" :modal="true">
            <Card>
                <template #content>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class=" field col-12">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <AutoComplete style="text-align: left" v-model="usuarioAutoriza"
                                        :suggestions="filteredUsuario" @complete="searchUsuario($event)" field="usuario">
                                    </AutoComplete>
                                    <label>Usuario</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-"
                        :num-inputs="4" :should-auto-focus="true" v-model="password" :is-input-num="true"
                        @on-change="handleOnChange" @on-complete="handleOnComplete" type="password" />
                </template>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="autorizarProducto = false" />
                    <Button label="Autorizar" icon="pi pi-check" class="p-button-text" @click="autorizarProductoAsync()" />
                </template>
            </Card>
        </Dialog>

        <!-- DIALOG DEL PDF -->
        <Dialog :showHeader="false" :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <PdfOrden @cierraDialogPdf="closeDialogPdf"></PdfOrden>
        </Dialog>

        <!-- NUEVA VENTA ORDEN DE COMPRA -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogNuevoOrden" :closeOnEscape="true" :closable="false"
            :modal="false" :style="{ width: '95vw' }">
            <Orden @closeOrden="dialogNuevoOrden = false"></Orden>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar la Orden de compra
                    <b>{{ objetoEditado.serie + ' | ' + objetoEditado.folio }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarOrden()" />
            </template>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="justify-right field col-12 sm:col-12 md:col-6">
                        <Button icon="mdi mdi-arrow-left-bold"
                            class="p-button-rounded p-button-secondary p-button-outlined -mb-1" @click="irCotizaciones" />
                        Órdenes de Compra
                    </div>
                    <div class="col-12 md:col-6 sm:col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                <label>Fecha Inicial</label>
                            </span>
                            <span class="p-float-label">
                                <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                <label>Fecha Final</label>
                            </span>
                            <Button icon="pi pi-plus" class="p-button-success pl-4 pr-4" @click="nuevoOrden()" />
                            <Button icon="pi pi-undo" @click="getOrden()" class="p-button-primary pl-4 pr-4" />
                        </div>
                    </div>
                    <!-- <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div> -->
                    <!-- <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-plus" @click="nuevoOrden()" class="mx-2 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getOrden()" class="p-button-rounded" />
                    </div> -->
                </div>
            </template>
            <template #content>
                <DataTable filterDisplay="menu" :filters.sync="filtrosOrden" :value="itemsOrdenes" :scrollable="true"
                    scrollHeight="800px" scrollDirection="both" class="mt-1 p-datatable-sm" :loading="loadingItemsOrdenes"
                    :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} Órdenes de Compra."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :paginator="true" :rows="10" contextMenu :contextMenuSelection.sync="selectOrdenCompra"
                    @row-contextmenu="onRowContextMenu">
                    <Column header="Acciones" :styles="{ 'width': '250px', }" frozen>
                        <template #body="slotProps">
                            <template v-if="slotProps.data.estatus === 'Nuevo'">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    v-tooltip.bottom="'Eliminar'" @click="confirmDeleteProduct(slotProps.data)" />
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info"
                                    v-tooltip.bottom="'Editar'" @click="editarOrden(slotProps.data)" />
                                <!-- <Button icon="pi pi-check" class="p-button-rounded p-button-success"
                                    v-tooltip.bottom="'Autorizar'" @click="abrirAutorizarProducto(slotProps.data)" /> -->
                            </template>
                            <Button icon="pi pi-replay" class="p-button-rounded p-button-warning"
                                v-tooltip.bottom="'Similar'" @click="OrdenSimilar(slotProps.data)" />
                            <Button icon="pi pi-file-pdf" style="background:#E65100" class="p-button-rounded"
                                v-tooltip.bottom="'PDF'" @click="openDialogPdf(slotProps.data)" />
                            <template v-if="slotProps.data.estatus != 'Nuevo'">
                                <Button icon="mdi mdi-folder-eye" class="p-button-rounded p-button-success"
                                    v-tooltip.bottom="'Carpeta de Documentos'"
                                    @click="abrirCarpetaArchivos(slotProps.data)" />
                            </template>
                        </template>
                    </Column>
                    <!-- <Column field="idCompra" header="ID Compra" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable> 
                    </Column>-->
                    <Column field="serie" header="Serie" :styles="{ 'width': '150px', }"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" sortable>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="folio" header="Folio" :styles="{ 'width': '150px', }" sortable>
                        <template #body="slotProps">
                            <span v-tooltip.right="slotProps.data.estatus"
                                :class="'customer-badge status-' + slotProps.data.estatus">{{
                                    slotProps.data.folio }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="solicitante" header="Solicitante" :styles="{ 'width': '300px' }" sortable>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="rfc" header="RFC Proveedor" :styles="{ 'width': '200px' }" sortable>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="nombre" header="Nombre Proveedor" :styles="{ 'width': '400px' }" sortable>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="proyecto.descripcion" header="Proyecto" :styles="{ 'width': '300px' }" sortable>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'width': '120px' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fecha) }}
                        </template>
                    </Column>

                    <Column field="fechaEntrega" header="Fecha de Entrega" dataType="date" :styles="{ 'width': '120px' }">
                        <template #body="{ data }">
                            {{ formatDate(data.fechaEntrega) }}
                        </template>
                    </Column>
                    <Column field="subTotal" header="SubTotal" :bodyStyle="{ 'justify-content': 'right' }"
                        dataType="numeric"
                        :styles="{ 'width': '150px', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right', 'background-color': '#2196f326', 'justify-content': 'center' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column field="total" header="Total" :bodyStyle="{ 'justify-content': 'right', }" dataType="numeric"
                        :styles="{ 'width': '150px', 'flex-basis': '100px', 'font-size': '90%', 'background-color': '#effaf1', 'justify-content': 'center' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column header="Cotización" :styles="{ 'width': '150px', 'justify-content': 'center' }" sortable>
                        <template #body="{ data }">
                            <span>{{ data.serieR + ' ' + data.folioR }}</span>
                        </template>

                    </Column>

                    <template #footer>
                        <div class="flex align-items-center">
                            <Chip label="Nuevo" class="mr-2 status-Nuevo " />
                            <!-- <Chip label="Autorizado" class="mr-2 status-Autorizado" /> -->
                            <Chip label="Entregado" class="mr-2 status-Entregado" />
                        </div>
                    </template>
                </DataTable>
            </template>
        </Card>
        <ContextMenu :model="menuModel" ref="ordenCompra" />

    </div>
</template>
<script>
import moment from "moment";
import axios from 'axios'
import Orden from "./OrdenCompra.vue";
import PdfOrden from "../../Pdf/CompraOrdenCompraPdf.vue";
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import Movimientos from '../../Inventarios/InventarioAlmacen/MovimientosESAlmacen.vue'
import print from 'print-js'

export default {
    components: {
        Orden, PdfOrden, Movimientos
    },

    data() {
        return {
            filtrosOrden: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'solicitante': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'proyecto.descripcion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },

            fechaI: new Date(),
            fechaF: new Date(),
            dialogNuevoOrden: false,
            loadingItemsOrdenes: false,

            objetoEditado: {},
            deleteProductDialog: false,
            editedIndex: -1,

            loadingMensaje: "",
            loading: false,

            dialogPdf: false,

            listaUsuariosAutorizantes: [],
            filteredUsuario: null,
            usuarioAutoriza: { usuario: '' },
            autorizarProducto: false,
            password: "",

            idCompra: 0,

            selectOrdenCompra: null,

            menuModel: [
                { label: 'Recibir Compra', icon: 'pi pi-fw pi-file', command: () => this.OpenDialogEntrada(this.selectOrdenCompra) },
                { label: 'Asignar Comprobantes', icon: 'pi pi-fw pi-file', command: () => this.abrirDialogoComprobamtes(this.selectOrdenCompra) },
                { label: 'Cargar Notas', icon: 'pi pi-fw pi-file', command: () => this.abrirCargarNotas(this.selectOrdenCompra) },
            ],

            listaComprobantes: [],
            filtros: {
                'folioFiscal': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'serie': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'folio': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'fecha': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
            },
            dialogoAsignarComprobante: false,
            loadingComprobantes: false,
            heaederProveedor: '',
            selectComprobantes: null,

            dialogMovimientos: false,

            dialoCargarNotas: false,
            nota: {
                idCompra: 0,
                total: 0,
                folio: '',
                fecha: new Date(),
                nombreArchivo: '',
                base64: '',
                proyecto: '',
                idProyecto: 0
            },
            dialogExplorador: false,
            listaArchivos: [],
            dialogCarpetaArchivos: false,
            vistaPrevia: false,
            ImagenBase64: '',

            listaComprobantesAsignados: []
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsOrdenes() {
            return this.$store.state.listaComprasOrdenComprasStore
        },
        validaArchivo() {
            if (this.nota.nombreArchivo == '' || this.nota.nombreArchivo == null) {
                return false
            } else {
                return true
            }
        },
    },
    created() {
    },
    methods: {
        async asignarComprobamtes() {
            this.loading = true;
            this.loadingMensaje = "Asignando comprobantes, espere...";

            let comprobantes = []
            for (let c of this.selectComprobantes) {
                comprobantes.push(c.folioFiscal)
            }
            console.log(comprobantes)
            try {
                let response = await axios.post("Compras/PostComprobanteOrdenCompra/" + this.token.empresaBase + '/' + this.selectOrdenCompra.idCompra + '/' + this.selectOrdenCompra.proyecto.idProyecto + '/' + this.selectOrdenCompra.proveedor.rfc, comprobantes);
                console.log(response.data)
                this.dialogoAsignarComprobante = false

                this.loading = false;
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: "error", detail: "Error al asignar.", life: 3000, });
                this.loading = false;
            }
        },
        OpenDialogEntrada(item) {
            if (item.estatus == 'Entregado') {
                this.$toast.add({ severity: "info", detail: "La Orden de Compra ya fue recibida anteriormente.", life: 3000, });
                return
            }
            // if (item.estatus == 'Nuevo') {
            //     this.$toast.add({ severity: "info", detail: "La Orden de Compra debe estar autorizada.", life: 3000, });
            //     return
            // }

            let conceptos = []
            for (let c of item.conceptos) {
                let objetoConcepto = {}

                objetoConcepto.idOrigen = item.idCompra
                objetoConcepto.item = c.item
                objetoConcepto.origen = 'OrdenCompra'
                objetoConcepto.noIdentificacion = c.descripcion
                objetoConcepto.descripcion = c.descripcion
                objetoConcepto.unidad = c.medida
                objetoConcepto.cantidad = c.cantidad
                objetoConcepto.idAlmacen = 0
                objetoConcepto.idProyecto = item.proyecto.idProyecto

                conceptos.push(objetoConcepto)
            }


            let ObjMovimiento = {
                id: -1,
                tipo: 'OrdenCompra',
                movimientos: conceptos
            };
            this.$store.state.movimientoAlmacen = { ...ObjMovimiento };
            this.dialogMovimientos = true;

        },
        async abrirDialogoComprobamtes(data) {
            if (data.estatus == 'Nuevo' || data.estatus == 'Autorizado') {
                this.$toast.add({ severity: "info", detail: "Para poder continuar, la Orden de Compra debe ser recibida.", life: 3000, });
                return
            }
            this.heaederProveedor = 'Proveedor - ' + data.proveedor.rfc
            console.log(data)
            this.listaComprobantes = []
            this.loadingComprobantes = true
            try {
                let response = await axios.get('Comprobantes/GetComprobantesRecibidos/' + this.token.empresaBase + '/' + data.proveedor.rfc);
                console.log(response.data)

                this.listaComprobantes = response.data;
                this.dialogoAsignarComprobante = true
                this.loadingComprobantes = false

            } catch (error) {
                console.log(error);
                this.loadingComprobantes = false
            }
        },
        irCotizaciones() {
            this.$router.push({ name: "ListaCotizaciones" });
        },
        onRowContextMenu(event) {
            this.$refs.ordenCompra.show(event.originalEvent);

        },
        async abrirAutorizarProducto(data) {
            let listaUsuariosAutorizantes = [];
            this.idCompra = data.idCompra
            await this.getUsuarios();

            //BUSCAR EL USUARIO EN LA LISTA
            this.loadingMensaje = 'Validando usuario...'
            let indice = this.listaUsuariosAutorizantes.findIndex(x => x.usuario.toUpperCase() === this.token.nombre.toUpperCase())
            if (indice != -1) {
                let ObjUsuario = {}
                ObjUsuario = { ...this.listaUsuariosAutorizantes[indice] }
                let indicePermiso = ObjUsuario.permisos.findIndex(x => x.permiso === 'Autorizar')
                if (indicePermiso != -1) {
                    console.log('Autorzacion directa')
                    this.usuarioAutoriza.usuario = ObjUsuario.usuario
                    this.password = ObjUsuario.pin
                    await this.autorizarProductoAsync();
                    this.loading = false;
                }
                else {
                    console.log('mostrar dialog')
                    this.password = "";
                    this.usuarioAutoriza = "";
                    this.autorizarProducto = true;
                    this.loading = false;
                }
            }
            else {
                console.log('mostrar dialog')
                this.password = "";
                this.usuarioAutoriza = "";
                this.autorizarProducto = true;
                this.loading = false;
            }
        },
        async getUsuarios() {
            this.loading = true;
            this.loadingMensaje = 'Consultando usuarios...'
            try {
                let response = await axios.get("Usuarios/GetUsuariosAutorizantes/" + this.token.empresaBase);
                this.listaUsuariosAutorizantes = response.data;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        async autorizarProductoAsync() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";

            try {
                let response = await axios.post("Compras/PostAutorizaCompra/" + this.token.empresaBase + '/' + this.idCompra + '/' + this.usuarioAutoriza.usuario + '/' + this.password);
                console.log(response.data)

                this.$toast.add({ severity: "success", detail: "Orden Autorizado con éxito.", life: 3000, });
                let indice = this.$store.state.listaComprasOrdenComprasStore.findIndex(
                    (x) => x.idCompra === this.idCompra
                );
                this.$store.state.listaComprasOrdenComprasStore[indice].estatus = "Autorizado";
                this.loading = false;
                this.autorizarProducto = false
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: "error", detail: "Error al autorizar, intente nuevamente.", life: 3000, });
                this.loading = false;
                this.handleClearInput()
                this.usuarioAutoriza = ''
            }
        },

        nuevoOrden() {
            let Orden = this.$store.state.comprasOrdenCompraStore.idCompra
            if (Orden == -1) {
                let obj = {
                    idCompra: 0,
                    serie: '',
                    folio: '',
                    fecha: new Date(),
                    tiempoEntrega: '',
                    tipoComprobante: 'OrdenCompra',
                    empresa: {},
                    proveedor: {
                        idProveedor: 0,
                        rfc: '',
                        nombre: '',
                        nombreComercial: '',
                        correo: '',
                        telefono: '',
                        contacto: '',
                        direccion: '',
                        proveedor: ''
                    },
                    rfc: '',
                    nombre: '',
                    proyecto: {
                        descripcion: '',
                        presupuesto: 0
                    },
                    solicitante: '',
                    email: '',
                    notas: '',
                    estatus: 'Nuevo',
                    usuarioCreador: '',
                    usuarioModificador: '',
                    conceptos: [],
                    condicionesEntrega: '',
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    idRelacion: 0,
                    serieR: '',
                    folioR: '',
                    total: 0,
                    subTotal: 0,
                    fechaEntrega: new Date()
                }
                this.$store.state.comprasOrdenCompraStore = { ...obj };
            }
            this.dialogNuevoOrden = true;
        },
        OrdenSimilar(item) {
            console.log(item)
            let similar = { ...item }
            similar.idCompra = 0
            similar.folio = ''
            similar.estatus = 'Nuevo'
            similar.fecha = new Date()

            this.$store.state.comprasOrdenCompraStore = { ...similar }
            this.dialogNuevoOrden = true;
        },
        editarOrden(data) {
            this.$store.state.comprasOrdenCompraStore = { ...data };
            this.dialogNuevoOrden = true;
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsOrdenes.indexOf(data);
            this.objetoEditado = { ...data };
            this.deleteProductDialog = true;
        },
        openDialogPdf(data) {
            this.dialogPdf = true;
            console.log(data)
            this.$store.state.comprasOrdenCompraStore = { ...data };
        },
        async eliminarOrden() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";

            try {
                this.objetoEditado.usuarioModificador = this.token.nombre;
                this.objetoEditado.estatus = "Eliminado";

                let response = await axios.put("Compras/DeleteCompra/" + this.token.empresaBase, this.objetoEditado);
                this.itemsOrdenes.splice(this.editedIndex, 1);

                this.deleteProductDialog = false;
                this.loading = false;

                this.$toast.add({ severity: "success", detail: "Orden de Compra eliminada con éxito.", life: 3000, });
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: "error", detail: "Error al eliminar, intente nuevamente.", life: 3000, });
                this.loading = false;
            }
        },


        async getOrden() {
            try {
                this.loadingItemsOrdenes = true;
                let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get("Compras/GetComprasOrdenCompras/" + this.token.empresaBase + "/" + fechaInicial + "/" + fechaFinal + "/OrdenCompra");
                console.log(response.data)

                let array = response.data;
                this.$store.state.listaComprasOrdenComprasStore = array.sort(((a, b) => b.idCompra - a.idCompra))

                this.loadingItemsOrdenes = false;
            } catch (error) {
                console.log(error);
                this.loadingItemsOrdenes = false;
            }
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        searchUsuario(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUsuario = [...this.listaUsuariosAutorizantes];
                }
                else {
                    this.filteredUsuario = this.listaUsuariosAutorizantes.filter((country) => {
                        return country.usuario.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        handleOnComplete(value) {
            console.log("OTP completado: ", value);
            this.password = value;
        },

        handleOnChange(value) {
            console.log("OTP cambio: ", value);
            this.itemsEmpresa = [];
        },

        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },

        closeDialogMovimientos() {
            this.dialogMovimientos = false;
        },

        abrirCargarNotas(item) {
            if (item.estatus == 'Nuevo' || item.estatus == 'Autorizado') {
                this.$toast.add({ severity: "info", detail: "Para poder continuar, la Orden de Compra debe ser recibida.", life: 3000, });
                return
            }
            this.nota.idCompra = 0
            this.nota.folio = ''
            this.nota.total = 0
            this.nota.proyecto = ''
            this.nota.base64 = ''
            this.nota.nombreArchivo = ''
            this.nota.idProyecto = 0
            console.log(item)
            this.nota.idCompra = item.idCompra
            this.nota.proyecto = item.proyecto.descripcion
            this.nota.idProyecto = item.proyecto.idProyecto

            this.dialoCargarNotas = true
        },
        cargarArchivo() {
            this.dialogExplorador = true
        },
        async cargarNota(event) {
            console.log(event)
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.nota.base64 = result;
                this.nota.nombreArchivo = file.name;
                this.dialogExplorador = false
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        async guardarNotas() {
            this.loading = true;
            this.loadingMensaje = "Guardando Nota, espere...";
            console.log(this.nota)
            try {
                let response = await axios.post("Compras/PostNotas/" + this.token.empresaBase, this.nota);
                console.log(response.data);
                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Guardado", detail: "Nota guardado con exito.", life: 3000 });
                this.dialoCargarNotas = false;
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al guardar.", life: 3000 });
            }
        },
        async abrirCarpetaArchivos(item) {
            try {
                let response = await axios.get('Compras/GetNotas/' + this.token.empresaBase + '/' + item.idCompra);
                console.log(response.data)
                this.listaArchivos = response.data.resultadoNotas
                this.listaComprobantesAsignados = response.data.resultadoComprobantes

                this.dialogCarpetaArchivos = true

            } catch (error) {
                console.log(error);
            }
        },
        async verDocumento(item) {
            let documento = item.base64
            // console.log(documento)
            let tipo = item.nombreArchivo.slice(-3)

            if (tipo == 'pdf') {
                print({
                    printable: documento,
                    type: 'pdf',
                    base64: true,
                })
            } else {
                console.log(tipo)
                this.ImagenBase64 = 'data:image/png;base64,' + documento
                this.vistaPrevia = true
            }

        },

        async eliminarDocumento(item) {
            this.loading = true;
            this.loadingMensaje = "Eliminando Nota, espere...";
            console.log(this.nota)
            try {
                let response = await axios.post("Compras/DeleteNota/" + this.token.empresaBase + '/' + item.idNota);
                console.log(response.data);
                let indice = this.listaArchivos.indexOf(item);
                this.listaArchivos.splice(indice, 1);

                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Elminiado", detail: "Nota Elminiado con exito.", life: 3000 });
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al Elminiar.", life: 3000 });
            }
        },

        async eliminarComprobante(item) {
            this.loading = true;
            this.loadingMensaje = "Eliminando Comprobante, espere...";
            console.log(this.nota)
            try {
                let response = await axios.post("Compras/DeleteComprobante/" + this.token.empresaBase + '/' + item.idComprobante);
                console.log(response.data);
                let indice = this.listaComprobantesAsignados.indexOf(item);
                this.listaComprobantesAsignados.splice(indice, 1);

                this.loading = false;
                this.$toast.add({ severity: "success", summary: "Elminiado", detail: "Comprobante Elminiado con exito.", life: 3000 });
            }
            catch (error) {
                console.log(error);
                this.loading = false;
                this.$toast.add({ severity: "error", summary: "Error", detail: "Error al Elminiar.", life: 3000 });
            }
        }
    },
}
</script>
<style>
@media screen and (min-width: 1024px) {
    .modalResponsivo {
        width: 30% !important;
    }
}
</style>