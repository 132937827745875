<template>
    <div>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <!-- ENCABEZADO -->
            <div class="col-4 text-left">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="cierraVentana()" v-tooltip.bottom="'Cerrar Ventana'" />
            </div>
            <div class="col-4 text-center">
                <h1>Inventario </h1>
            </div>
            <!-- BOTONES -->
            <!-- <div class="field col-6 text-right">
                <Button icon="pi pi-replay" class="p-button-rounded p-button-info p-button-outlined"
                    @click="GetMovimientos()" v-tooltip.bottom="'Consultar'" />
            </div> -->
        </div>
        <DataTable :value="listMovimientos" :scrollable="true" scrollHeight="400px" scrollDirection="both">
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-eye" class="p-button-rounded p-button-info"
                        v-tooltip.bottom="'Consultar Almacén'" />
                </template>
            </Column>
            <Column field="noIdentificacion" header="Clave" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }" sorteable>
            </Column>
            <Column field="descripcion" header="Descrpción" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
            <Column field="medida" header="Medida" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
                <template #body="{ data }">
                    <span>{{ FormatoCantidad(data.cantidad) }}</span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {

        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        listMovimientos() {
            return this.$store.state.listMovimientos;
        }

    },
    created() {

    },
    methods: {
        FormatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        cierraVentana() {
            this.$store.state.listMovimientos = [];
            this.$emit('closeMovimiento')
        },
    },
}
</script>