<template>
  <div>

    <!-- DIALOGO PARA AUTORIZAR -->
    <Dialog :visible.sync="autorizarProducto" :closable="false" :containerStyle="{ width: '390px' }"
      header="¿Quien Autoriza?" :modal="true">
      <Card>
        <template #content>
          <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <div class=" field col-12">
              <div class="p-inputgroup">
                <span class="p-float-label">
                  <AutoComplete style="text-align: left" v-model="usuarioAutoriza" :suggestions="filteredUsuario"
                    @complete="searchUsuario($event)" field="usuario">
                  </AutoComplete>
                  <label>Usuario</label>
                </span>
              </div>
            </div>
          </div>
          <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4"
            :should-auto-focus="true" v-model="password" :is-input-num="true" @on-change="handleOnChange"
            @on-complete="handleOnComplete" type="password" />
        </template>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="autorizarProducto = false" />
          <Button label="Autorizar" icon="pi pi-check" class="p-button-text" @click="autorizarProductoAsync()" />
        </template>
      </Card>
    </Dialog>

    <!-- DIALOGO CONFIRMAR ELIMINACION -->
    <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Estas seguro de eliminar el Pedido
          <b>{{ objetoEditado.serie + objetoEditado.folio }}</b>.?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarPedido()" />
      </template>
    </Dialog>

    <!-- DIALOGO DE LOADING -->
    <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>

    <!-- DIALOGO PARA ERRORES -->
    <div class="p-d-flex">
      <Toast />
    </div>

    <!-- NUEVA VENTA PEDIDO -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogVentaPedido" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <VentaPedido @closeVentaPedido="closeDialogVentaPedido()"></VentaPedido>
    </Dialog>

    <!-- NUEVA VENTA REMISION -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogVentaRemision" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <VentaRemision @closeVentaRemision="dialogVentaRemision = false"></VentaRemision>
    </Dialog>

    <!-- DIALOG DEL PDF -->
    <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
      <PdfVentaPedido @cierraDialogPdf="closeDialogPdf"></PdfVentaPedido>
    </Dialog>

    <!-- LISTA DE SELECCION -->
    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
            <Button icon="mdi mdi-arrow-left-bold "
              class="p-button-rounded p-button-secondary p-button-outlined mr-2 -mb-1" @click="irCotizacion()" />
            <Button icon="mdi mdi-arrow-right-bold" class="p-button-rounded p-button-secondary p-button-outlined -mb-1"
              @click="irRemision()" />
            Pedidos
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
              <label>Fecha Inicial</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
              <label>Fecha Final</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
            <Button icon="pi pi-plus" @click="nuevoPedido()" class="mx-2 p-button-rounded" />
            <Button icon="pi pi-undo" @click="getPedido()" class="p-button-rounded" />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable :value="itemsPedidos" :scrollable="true" scrollHeight="800px" scrollDirection="both"
          class="mt-1 p-datatable-sm" :loading="loadingItemsPedidos" :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :paginator="true" :rows="10" contextMenu @row-contextmenu="onRowContextMenu"
          :contextMenuSelection.sync="selectedProduct">
          <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'min-width': '15rem', }" frozen>
            <template #body="slotProps">
              <template v-if="slotProps.data.estatus === 'Nuevo'">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar'"
                  @click="confirmDeleteProduct(slotProps.data)" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info" v-tooltip.bottom="'Editar'"
                  @click="editConcepto(slotProps.data)" />
                <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-tooltip.bottom="'Autorizar'"
                  @click="abrirAutorizarProducto(slotProps.data)" />
              </template>
              <Button icon="pi pi-replay" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Similar'"
                @click="pedidoSimilar(slotProps.data)" />
              <Button icon="pi pi-file-pdf" style="background:#E65100" class="p-button-rounded" v-tooltip.bottom="'PDF'"
                @click="openDialogPdf(slotProps.data)" />
            </template>
          </Column>
          <Column field="idVenta" header="IDventa" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
          </Column>
          <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
          </Column>
          <Column field="folio" header="Folio"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }" sortable>
            <template #body="slotProps">
              <span :class="'customer-badge status-' + slotProps.data.estatus">{{ slotProps.data.folio }}</span>
            </template>
          </Column>
          <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
          </Column>
          <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
          </Column>
          <Column field="ordenTrabajo" header="OT" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
          </Column>
          <Column field="ordenCompra" header="OC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
          </Column>
          <Column field="proyecto.descripcion" header="Proyecto" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }"
            sortable>
          </Column>
          <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
            sortable>
            <template #body="{ data }">
              {{ formatDate(data.fecha) }}
            </template>
          </Column>
          <Column field="vigencia" header="Vigencia" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            <template #body="{ data }">
              {{ formatDate(data.vigencia) }}
            </template>
          </Column>
          <Column field="subTotal" header="SubTotal" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'justify-content': 'right',
          }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.subTotal) }}
            </template>
          </Column>
          <Column field="total" header="Total" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'justify-content': 'right',
          }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.total) }}
            </template>
          </Column>
          <Column field="moneda.clave" header="Moneda" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
          </Column>
        </DataTable>
        <ContextMenu :model="menuModel" ref="cm" />
      </template>
      <template #footer>
        <div class="flex align-items-center">
          <Chip label="Nuevo" class="mr-2 nuevo-chip " />
          <Chip label="Autorizado" class="mr-2 autorizado-chip" />
          <Chip label="Remisionado" class="mr-2 remision-chip" />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import VentaPedido from "./VentaPedido.vue";
import PdfVentaPedido from "../Pdf/VentaPedidoPdf.vue";
import VentaRemision from "../VentasRemision/VentaRemision";

import moment from "moment";

export default {
  components: { VentaPedido, PdfVentaPedido, VentaRemision },
  data() {
    return {
      listaUsuariosAutorizantes: [],
      filteredUsuario: null,
      usuarioAutoriza: { usuario: '' },
      autorizarProducto: false,
      password: "",

      dialogVentaPedido: false,
      dialogPdf: false,
      fechaI: new Date(),
      fechaF: new Date(),
      loadingItemsPedidos: false,

      deleteProductDialog: false,
      editedIndex: -1,
      objetoEditado: {},

      loadingMensaje: "",
      loading: false,

      dialogVentaRemision: false,
      menuModel: [
        { label: 'Remisión', icon: 'pi pi-fw pi-reply', command: () => this.convertirRemision(this.selectedProduct) },
      ],
      selectedProduct: null,
      idPedido: 0,
    };
  },
  computed: {
    itemsPedidos() {
      return this.$store.state.listVentaPedidoStore;
    },
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    irRemision() {
      this.$router.push({ name: "ListaVentasRemision" });

    },
    irCotizacion() {
      this.$router.push({ name: "ListaVentasCotizacion" });

    },
    async getEmpresa() {
      try {
        let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
        this.dialogLoading = false;
      }
    },

    convertirRemision(item) {
      if (item.estatus === 'Nuevo') {
        this.$toast.add({ severity: "info", detail: "El pedido debe de estar autorizado para convertirlo en Remisión.", life: 3000, });
        return
      }

      if (item.estatus === 'Remision') {
        this.$toast.add({ severity: "info", detail: 'El pedido ya ha sido convertida en Remisión.', life: 3000, });
        return
      }

      let pedido = { ...item }
      let obj = {
        idVenta: 0,
        serie: '',
        folio: '',
        fecha: new Date(),
        vigencia: new Date(),
        formaPago: {},
        condicionesPago: pedido.condicionesPago,
        subTotal: pedido.subTotal,
        descuento: 0,
        moneda: pedido.moneda,
        tipoCambio: 0,
        total: pedido.total,
        tipoComprobante: 'Remision',
        metodoPago: {},
        empresa: pedido.empresa,
        cliente: pedido.cliente,
        rfc: pedido.cliente.rfc,
        nombre: pedido.cliente.nombre,
        atn: pedido.atn,
        notas: '',
        estatus: 'Autorizado',
        usuarioCreador: '',
        usuarioModificador: '',
        usuarioAutoriza: '',
        conceptos: pedido.conceptos,
        impuestos: pedido.impuestos,
        proyecto: pedido.proyecto,
        idRelacion: pedido.idVenta,
        ordenCompra: pedido.ordenCompra,
        ordenTrabajo: pedido.ordenTrabajo,
        totalFacturado: 0,
        facturas: 0,
      }
      console.log(obj)
      this.$store.state.ventaRemisionStore = { ...obj }
      this.dialogVentaRemision = true
    },

    async getPedido() {
      try {
        this.loadingItemsPedidos = true;
        let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
        let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
        let response = await axios.get("Ventas/GetVentasPedido/" + this.token.empresaBase + "/" + fechaInicial + "/" + fechaFinal);
        console.log(response.data)
        let array = response.data;
        this.$store.state.listVentaPedidoStore = array.sort(((a, b) => b.folio - a.folio))

        this.loadingItemsPedidos = false;
      } catch (error) {
        console.log(error);
        this.loadingItemsPedidos = false;
      }
    },

    async eliminarPedido() {
      this.loading = true;
      this.loadingMensaje = "Procesando soliciud, espere...";

      try {
        this.objetoEditado.usuarioModificador = this.token.nombre;
        this.objetoEditado.estatus = "Eliminado";

        let response = await axios.put("Ventas/DeleteVenta/" + this.token.empresaBase, this.objetoEditado);
        this.itemsPedidos.splice(this.editedIndex, 1);

        this.deleteProductDialog = false;
        this.loading = false;

        this.$toast.add({
          severity: "success",
          detail: "Pedido eliminado con éxito.",
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          detail: "Error al eliminar, intente nuevamente.",
          life: 3000,
        });
        this.loading = false;
      }
    },

    async abrirAutorizarProducto(data) {
      let listaUsuariosAutorizantes = [];
      this.idPedido = data.idVenta
      await this.getUsuarios();

      //BUSCAR EL USUARIO EN LA LISTA
      this.loadingMensaje = 'Validando usuario...'
      let indice = this.listaUsuariosAutorizantes.findIndex(x => x.usuario.toUpperCase() === this.token.nombre.toUpperCase())
      if (indice != -1) {
        let ObjUsuario = {}
        ObjUsuario = { ...this.listaUsuariosAutorizantes[indice] }
        let indicePermiso = ObjUsuario.permisos.findIndex(x => x.permiso === 'Autorizar')
        if (indicePermiso != -1) {
          console.log('Autorzacion directa')
          this.usuarioAutoriza.usuario = ObjUsuario.usuario
          this.password = ObjUsuario.pin
          await this.autorizarProductoAsync();
          this.loading = false;
        }
        else {
          console.log('mostrar dialog')
          this.password = "";
          this.usuarioAutoriza = "";
          this.autorizarProducto = true;
          this.loading = false;
        }
      }
      else {
        console.log('mostrar dialog')
        this.password = "";
        this.usuarioAutoriza = "";
        this.autorizarProducto = true;
        this.loading = false;
      }
    },

    async autorizarProductoAsync() {
      this.loading = true;
      this.loadingMensaje = "Procesando soliciud, espere...";

      try {
        let response = await axios.post("Ventas/autorizarProducto/" + this.token.empresaBase + '/' + this.idPedido + '/' + this.usuarioAutoriza.usuario + '/' + this.password);
        console.log(response.data)

        this.$toast.add({ severity: "success", detail: "Pedido Autorizado con éxito.", life: 3000, });
        let indice = this.$store.state.listVentaPedidoStore.findIndex(
          (x) => x.idVenta === this.idPedido
        );
        this.$store.state.listVentaPedidoStore[indice].estatus = "Autorizado";
        this.loading = false;
        this.autorizarProducto = false
      } catch (error) {
        console.log(error);
        this.$toast.add({ severity: "error", detail: "Error al autorizar, intente nuevamente.", life: 3000, });
        this.loading = false;
        this.handleClearInput()
        this.usuarioAutoriza = ''
      }
    },

    searchUsuario(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUsuario = [...this.listaUsuariosAutorizantes];
        }
        else {
          this.filteredUsuario = this.listaUsuariosAutorizantes.filter((country) => {
            return country.usuario.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    async getUsuarios() {
      this.loading = true;
      this.loadingMensaje = 'Consultando usuarios...'
      try {
        let response = await axios.get("Usuarios/GetUsuariosAutorizantes/" + this.token.empresaBase);
        this.listaUsuariosAutorizantes = response.data;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    openDialogPdf(data) {
      this.dialogPdf = true;
      this.$store.state.ventaPedidoStore = { ...data };
    },

    closeDialogPdf() {
      this.dialogPdf = false;
    },

    editConcepto(data) {
      this.$store.state.ventaPedidoStore = { ...data };
      this.dialogVentaPedido = true;
    },

    confirmDeleteProduct(data) {
      this.editedIndex = this.itemsPedidos.indexOf(data);
      this.objetoEditado = { ...data };
      this.deleteProductDialog = true;
    },

    nuevoPedido() {
      let pedido = this.$store.state.ventaPedidoStore.idVenta
      if (pedido == -1) {
        let obj = {
          idVenta: 0,
          serie: "",
          folio: "",
          fecha: new Date(),
          vigencia: new Date(),
          formaPago: {},
          condicionesPago: "",
          subTotal: 0,
          descuento: 0,
          moneda: {
            id: 100,
            clave: "MXN",
            descripcion: "Peso Mexicano",
            moneda: "MXN | Peso Mexicano",
          },
          tipoCambio: 0,
          total: 0,
          tipoComprobante: "Pedido",
          metodoPago: {},
          empresa: {},
          cliente: {
            rfc: "",
            nombre: "",
            cliente: "",
            usoCfdi: {},
          },
          rfc: "",
          nombre: "",
          atn: "",
          notas: "",
          estatus: "Nuevo",
          usuarioCreador: "",
          usuarioModificador: "",
          usuarioAutoriza: "",
          conceptos: [],
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          ordenTrabajo: '',
          ordenCompra: '',
          idRelacion: 0,
        };

        this.$store.state.ventaPedidoStore = { ...obj };
      }
      this.dialogVentaPedido = true;
    },

    pedidoSimilar(item) {
      console.log(item)
      let similar = { ...item }
      similar.idVenta = 0
      similar.folio = ''
      similar.estatus = 'Nuevo'
      similar.fecha = new Date()

      this.$store.state.ventaPedidoStore = { ...similar }
      this.dialogVentaPedido = true;
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    formatDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    handleOnComplete(value) {
      console.log("OTP completado: ", value);
      this.password = value;
    },

    handleOnChange(value) {
      console.log("OTP cambio: ", value);
      this.itemsEmpresa = [];
    },

    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },

    closeDialogVentaPedido() {
      this.dialogVentaPedido = false;
    },
  },
};
</script>
<style >
.status-Autorizado {
  background-color: #2BA2DE;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Nuevo {
  background-color: #ED6421;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Remision {
  background-color: #3EBF4F;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.p-chip.nuevo-chip {
  background: #ED6421;
  ;
  color: var(--primary-color-text);
}

.p-chip.autorizado-chip {
  background: #2BA2DE;
  color: var(--primary-color-text);
}

.p-chip.remision-chip {
  background: #3EBF4F;
  color: var(--primary-color-text);
}
</style>
