<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :showHeader="false" :style="{ width: '850px' }" :closable="false">
            <PdfPedido @cierraDialogPdf="closeDialogPdf"></PdfPedido>
        </Dialog>

        <!-- NUEVA VENTA COTIZAION -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogNuevoCotizacion" :closeOnEscape="true"
            :closable="false" :modal="false" :style="{ width: '95vw' }">
            <Cotizacion @closeCotizacion="dialogNuevoCotizacion = false"></Cotizacion>
        </Dialog>

        <!-- NUEVA VENTA PEDIDO -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogNuevoPedido" :closeOnEscape="true" :closable="false"
            :modal="false" :style="{ width: '95vw' }">
            <Pedido @closePedido="dialogNuevoPedido = false"></Pedido>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar el Pedido
                    <b>{{ objetoEditado.idCompra }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarPedido()" />
            </template>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
                        <Button icon="mdi mdi-arrow-right-bold"
                            class="p-button-rounded p-button-secondary p-button-outlined -mb-1" @click="irCotizaciones()" />
                        Pedidos
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-plus" @click="nuevoPedido()" class="mx-2 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getPedido()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :value="itemsPedidos" :scrollable="true" scrollHeight="800px" scrollDirection="both"
                    class="mt-1 p-datatable-sm" :loading="loadingItemsPedidos" :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} Pedidos."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :paginator="true" :rows="10" contextMenu :contextMenuSelection.sync="selecPedido"
                    @row-contextmenu="onRowContextMenu">
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'min-width': '15rem', }"
                        frozen>
                        <template #body="slotProps">
                            <template v-if="slotProps.data.estatus === 'Nuevo'">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    v-tooltip.bottom="'Eliminar'" @click="confirmDeleteProduct(slotProps.data)" />
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info"
                                    v-tooltip.bottom="'Editar'" @click="editarPedido(slotProps.data)" />
                            </template>
                            <Button icon="pi pi-replay" class="p-button-rounded p-button-warning"
                                v-tooltip.bottom="'Similar'" @click="pedidoSimilar(slotProps.data)" />
                            <Button icon="pi pi-file-pdf" style="background:#E65100" class="p-button-rounded"
                                v-tooltip.bottom="'PDF'" @click="openDialogPdf(slotProps.data)" />
                        </template>
                    </Column>
                    <!-- <Column field="idCompra" header="ID Pedido" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable>
                    </Column> -->
                    <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
                        <template #body="slotProps">
                            <span :class="'customer-badge status-' + slotProps.data.estatus"
                                v-tooltip.right="slotProps.data.estatus">{{
                                    slotProps.data.folio }}</span>
                        </template>
                    </Column>
                    <Column field="solicitante" header="Solicitante" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable>
                    </Column>
                    <Column field="proyecto.descripcion" header="Proyecto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
                    </Column>
                    <Column field="fecha" header="Fecha" dataType="date"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fecha) }}
                        </template>
                    </Column>
                    <!-- <Column field="estatus" header="Estatus" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
                    </Column> -->
                    <template #footer>
                        <div class="flex align-items-center">
                            <Chip label="Nuevo" class="mr-2 status-Nuevo " />
                            <Chip label="Enviado" class="mr-2 status-Enviado" />
                        </div>
                    </template>
                </DataTable>
            </template>
        </Card>
        <ContextMenu :model="menuModel" ref="pedido" />
    </div>
</template>
<script>
import moment from "moment";
import axios from 'axios'
import Pedido from "./Pedido.vue";
import PdfPedido from "../../Pdf/CompraPedidoPdf.vue";
import Cotizacion from "../Cotizacion/Cotizacion.vue";

export default {
    components: {
        Pedido, PdfPedido, Cotizacion
    },

    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            dialogNuevoPedido: false,
            loadingItemsPedidos: false,

            objetoEditado: {},
            deleteProductDialog: false,
            editedIndex: -1,

            loadingMensaje: "",
            loading: false,

            dialogPdf: false,

            selecPedido: null,

            dialogNuevoCotizacion: false,
            menuModel: [
                { label: 'Cotizar', icon: 'pi pi-fw pi-file', command: () => this.cotizarPedido(this.selecPedido) },
            ]
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsPedidos() {
            return this.$store.state.listaComprasPedidoStore
        }
    },
    created() {
    },
    methods: {
        irCotizaciones() {
            this.$router.push({ name: "ListaCotizaciones" });
        },
        onRowContextMenu(event) {
            this.$refs.pedido.show(event.originalEvent);
        },
        async cotizarPedido(item) {


            let data = { ...item }
            data.fecha = new Date();
            data.folioR = data.folio
            data.folio = ''
            data.estatus = 'Nuevo'
            data.idRelacion = data.idCompra
            data.serieR = ''
            data.idCompra = 0
            data.tipoComprobante = 'Cotización'
            data.subTotal = 0
            data.total = 0
            console.log(data)


            this.$store.state.comprasCotizacionStore = { ...data }
            this.dialogNuevoCotizacion = true
        },
        nuevoPedido() {
            let pedido = this.$store.state.comprasPedidoStore.idCompra
            if (pedido == -1) {
                let obj = {
                    idCompra: 0,
                    serie: '',
                    folio: '',
                    fecha: new Date(),
                    tiempoEntrega: '',
                    tipoComprobante: 'Pedido',
                    empresa: {},
                    proveedor: {
                        idProveedor: 0,
                        rfc: '',
                        nombre: '',
                        nombreComercial: '',
                        correo: '',
                        telefono: '',
                        contacto: '',
                        direccion: '',
                        proveedor: ''
                    },
                    rfc: '',
                    nombre: '',
                    proyecto: {
                        descripcion: '',
                        presupuesto: 0
                    },
                    solicitante: '',
                    email: '',
                    notas: '',
                    estatus: 'Nuevo',
                    usuarioCreador: '',
                    usuarioModificador: '',
                    conceptos: [],
                    condicionesEntrega: '',
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    idRelacion: 0,
                    serieR: '',
                    folioR: ''
                }
                this.$store.state.comprasPedidoStore = { ...obj };
            }
            this.dialogNuevoPedido = true;
        },
        pedidoSimilar(item) {
            console.log(item)
            let similar = { ...item }
            similar.idCompra = 0
            similar.folio = ''
            similar.estatus = 'Nuevo'
            similar.fecha = new Date()

            this.$store.state.comprasPedidoStore = { ...similar }
            this.dialogNuevoPedido = true;
        },
        editarPedido(data) {
            this.$store.state.comprasPedidoStore = { ...data };
            this.dialogNuevoPedido = true;
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsPedidos.indexOf(data);
            this.objetoEditado = { ...data };
            this.deleteProductDialog = true;
        },
        openDialogPdf(data) {
            this.dialogPdf = true;
            this.$store.state.comprasPedidoStore = { ...data };
        },
        async eliminarPedido() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";

            try {
                this.objetoEditado.usuarioModificador = this.token.nombre;
                this.objetoEditado.estatus = "Eliminado";

                let response = await axios.put("Compras/DeleteCompra/" + this.token.empresaBase, this.objetoEditado);
                this.itemsPedidos.splice(this.editedIndex, 1);

                this.deleteProductDialog = false;
                this.loading = false;

                this.$toast.add({ severity: "success", detail: "Pedido eliminado con éxito.", life: 3000, });
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: "error", detail: "Error al eliminar, intente nuevamente.", life: 3000, });
                this.loading = false;
            }
        },


        async getPedido() {
            try {
                this.loadingItemsPedidos = true;
                let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get("Compras/GetCompras/" + this.token.empresaBase + "/" + fechaInicial + "/" + fechaFinal + "/Pedido");
                console.log(response.data)

                let array = response.data;
                this.$store.state.listaComprasPedidoStore = array.sort(((a, b) => b.idCompra - a.idCompra))

                this.loadingItemsPedidos = false;
            } catch (error) {
                console.log(error);
                this.loadingItemsPedidos = false;
            }
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
    },
}
</script>
<style>
.status-Enviado {
    background-color: #3e5cbf;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}
</style>