<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Receptor
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="receptor.nombre" style="text-align: left" type="text" />
                            <label>Nombre</label>
                        </span>
                    </div>
                    <!-- Validación del Registro de Identidad Tributaria -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="receptor.numRegIdTrib" style="text-align: left" type="text" />
                            <label>Validación del Registro de Identidad Tributaria</label>
                        </span>
                    </div>
                    <!-- DOMICILIO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="receptor.domicilio" :options="itemsDirecciones" optionLabel="domicilio">
                            </Dropdown>
                            <label>Domicilio</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,
            itemsPais: [],
            editedIndex: -1,

            itemsRemolques: [],
        }
    },
    computed: {
        itemsDirecciones() {
            return this.$store.state.listDireccionStore;
        },
        receptor() {
            return this.$store.state.cEReceptorStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getDirecciones()
    },
    methods: {

        async getDirecciones() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetDirecciones/' + this.empresa.empresaBase)
                this.$store.state.listDireccionStore = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        guardarOactualizar() {
            console.log(this.receptor)
            if (this.$store.state.cEReceptorStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Receptor, espere...'
            this.receptor.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('ComercioExterior/PostReceptor/' + this.empresa.empresaBase, this.receptor)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Receptor guardado con exito.', life: 3000 });

                this.receptor.id = response.data
                this.$store.state.listCEReceptorStore.push(this.receptor)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Receptor, espere...'
            this.receptor.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('ComercioExterior/UpdateReceptor/' + this.empresa.empresaBase, this.receptor)
                // console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listCEReceptorStore.findIndex(x => x.id === this.receptor.id);
                Object.assign(this.$store.state.listCEReceptorStore[indice], this.receptor)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Receptor actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

    },
}
</script>