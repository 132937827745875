<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.placa }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogDelete = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminar()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO CONCEPTO -->
        <Dialog :visible.sync="dialogoNuevaMercancia" :closeOnEscape="true" :closable="false" :style="{ width: '90vw' }">
            <CEMercancia @cierraVentana="dialogoNuevaMercancia = false"></CEMercancia>
        </Dialog>

        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Comercio Exterior Mercancías
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevaMercancia()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getMercancias()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosMercancias" removableSort showGridlines ref="clientes"
                    :value="itemsMercancias" responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'id',
                        'descripcion',
                        'noIdentificacion',
                        'jsonFraccionArancelaria',
                        'cantidadAduana',
                        'jsonUnidadAduana',
                        'valorUnitarioAduana',
                        'valorDolares',
                        'marca',
                        'modelo',
                        'subModelo',
                        'numeroSerie',

                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros."
                    :loading="loadingItemsTabla">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosMercancias['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Editar/Eliminar">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editar(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="confirmDeleteProduct(slotProps.data)" />
                        </template>
                    </Column>
                    <Column sortable field="descripcion" header="Descripción"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.descripcion }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="noIdentificacion" header="No. Identificación"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.noIdentificacion }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="fraccion" header="Fraccion Arancelaria"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.fraccionArancelaria.fraccion }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="unidadAduana" header="Unidad Aduana"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.unidadAduana.unidadAduana }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import CEMercancia from "./CEMercancia.vue";
import html2pdf from "html2pdf.js";

export default {
    components: { CEMercancia },

    data() {
        return {
            loadingMensaje: "",
            loading: false,
            dialogoNuevaMercancia: false,

            filtrosMercancias: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'descripcion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'noIdentificacion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'fraccionArancelaria.fraccion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'cantidadAduana': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'unidadAduana.unidadAduana': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'valorUnitarioAduana': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'valorDolares': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'descripciones.marca': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'descripciones.modelo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'descripciones.subModelo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'descripciones.numeroSerie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },

            },
            loadingItemsTabla: false,
            objetoEditado: {},

            dialogDelete: false,
            editedIndex: -1,

            base: ''
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsMercancias() {
            return this.$store.state.listCEMercanciaStore;
        },
    },
    created() {
        this.getMercancias()
    },
    methods: {
        nuevaMercancia() {
            this.dialogoNuevaMercancia = true;

            if (this.$store.state.cEMercanciaStore != 0) {
                let obj = {
                    id: 0,
                    descripcion: '',
                    noIdentificacion: '',
                    fraccionArancelaria: {
                        fraccion: ''
                    },
                    cantidadAduana: 0,
                    unidadAduana: {
                        unidadAduana: ''
                    },
                    valorUnitarioAduana: 0,
                    valorDolares: 0,
                    descripciones: {
                        marca: '',
                        modelo: '',
                        subModelo: '',
                        numeroSerie: '',
                    },
                    usuarioCreador: '',
                    usuarioModificador: ''
                }
                this.$store.state.cEMercanciaStore = { ...obj }
            }
        },

        async getMercancias() {
            this.loadingItemsTabla = true;
            try {
                let response = await axios.get('ComercioExterior/GetMercancias/' + this.empresa.empresaBase)
                this.$store.state.listCEMercanciaStore = response.data
                // console.log(response.data)
                this.loadingItemsTabla = false;

            } catch (error) {
                console.log(error)
                this.loadingItemsTabla = false;
            }
        },

        editar(data) {
            this.$store.state.cEMercanciaStore = { ...data }
            this.dialogoNuevaMercancia = true
        },

        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsMercancias.indexOf(data)
            this.objetoEditado = { ...data };
            this.dialogDelete = true;
        },

        async eliminar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre

                let response = await axios.put('ComercioExterior/PutMercanciaEstatus/' + this.empresa.empresaBase, this.objetoEditado)
                this.itemsMercancias.splice(this.editedIndex, 1)

                this.dialogDelete = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Mercancía eliminada con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
};
</script>