<template>
    <div>
        <!-- <ScrollTop /> -->
        <!-- <ScrollPanel style="height: 445px"> -->
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- SERIE -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Dropdown v-model="ingresoCP.serie" :options="itemsSerie" :editable="true" />
                    <label>Serie</label>
                </span>
            </div>
            <!-- FOLIO -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText v-model="ingresoCP.folio" type="text" />
                    <label>Folio</label>
                </span>
            </div>
            <!-- FECHA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Calendar v-model="ingresoCP.fecha" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                    <label>Fecha</label>
                </span>
            </div>
            <!-- CLIENTE -->
            <div class=" field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="ingresoCP.cliente" :suggestions="filteredCliente"
                            @complete="searchCliente($event)" field="cliente">
                        </AutoComplete>
                        <label>Cliente</label>
                    </span>
                    <!-- <Button icon="pi pi-plus" class="p-button-success" @click="nuevoCliente" /> -->
                </div>
            </div>
            <!-- USO DE CFDI -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ingresoCP.cliente.usoCfdi" :options="itemsUsoCfdi"
                        optionLabel="usoCfdi" />
                    <label>Uso de CFDI</label>
                </span>
            </div>
            <!-- METODO DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ingresoCP.metodoPago" :options="itemsMetodoPago"
                        optionLabel="metodoPago" />
                    <label>Método de Pago</label>
                </span>
            </div>
            <!-- FORMA DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ingresoCP.formaPago" :options="itemsFormaPago"
                        optionLabel="formaPago" />
                    <label>Forma de Pago</label>
                </span>
            </div>
            <!-- CONDICIONES DE PAGO -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <InputText v-model="ingresoCP.condicionesPago" type="text" />
                    <label>Condiciones de Pago</label>
                </span>
            </div>
            <!-- MONEDA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ingresoCP.moneda" :options="itemsMoneda"
                        optionLabel="moneda" />
                    <label>Moneda</label>
                </span>
            </div>
            <!-- TIPO DE CAMBIO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputNumber v-model="ingresoCP.tipoCambio" mode="decimal" locale="en-US" :minFractionDigits="6" />
                    <label>Tipo de Cambio</label>
                </span>
            </div>
            <!-- NOTAS -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <InputText v-model="ingresoCP.notas" type="text" />
                    <label>Notas</label>
                </span>
            </div>
        </div>
        <!-- <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" /> -->
        <!-- </ScrollPanel> -->
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            filteredCliente: null,
            dialogoNuevoCliente: false,
        }
    },
    computed: {
        ingresoCP() {
            return this.$store.state.ingresoCartaPorteStore;
        },
        itemsSerie() {
            return this.$store.state.listIngresoCPSerieStore;
        },
        itemsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
    },
    created() {
        this.getFormaPago();
        this.getMoneda();
        this.getMetododPago();
        this.getClientes();
        this.GetUsoCfdi();
        this.getSeries();
    },
    methods: {
        async getSeries() {
            try {
                let response = await axios.get('Comprobantes/GetSerieComprobantes/' + this.empresa.empresaBase + '/IngresoCP/' + this.datosEmpresa.rfc);
                this.$store.state.listIngresoCPSerieStore = response.data
                console.log(response.data)
                // if (response.data.count != 0) {
                //     this.ingresoCP.serie = response.data[0]
                // }
            } catch (error) {
                console.log(error)
            }
        },
        async GetUsoCfdi() {
            try {
                let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                this.$store.state.usoCfdiStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async getFormaPago() {
            if (this.itemsFormaPago.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetFormaPago/');
                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },

        async getMoneda() {
            if (this.itemsMoneda.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },

        async getMetododPago() {
            if (this.itemsMetodoPago.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMetodoPago/');
                this.$store.state.metodoPagoStore = result.data;
            } catch (error) {

            }
        },

        async getClientes() {
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.empresa.empresaBase);
                // console.log(result.data);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },

        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        nuevoCliente() {
            this.dialogoNuevoCliente = true;
        },
    },
}
</script>