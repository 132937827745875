<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 800px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>
            <!-- DIALOG DEL PDF -->
            <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
                <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
            </Dialog>
            <Card class="shadow-none">
                <!-- TITULO CARD -->
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cierraVentana()" />
                        </div>
                        <div class="text-center field col-4">
                            <span @click="activaPrecio()">Traslado Carta Porte</span>
                        </div>
                        <div class="text-right field col-4">
                            <template v-if="trasladoCP.id === 0">
                                <Button v-tooltip.bottom="'Timbrar'" icon="pi pi-check"
                                    class="p-button-rounded p-button-success p-button-outlined" @click="postFactura" />
                                <Button icon="pi pi-save"
                                    class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                                    @click="postGuardaFactura" v-tooltip.bottom="'Guardar'" />
                            </template>
                            <template v-if="trasladoCP.id != 0">
                                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                    @click="putTimbrado" v-tooltip.bottom="'Timbrar Guardado'" />
                                <Button icon="pi pi-save"
                                    class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                                    @click="putFactura" v-tooltip.bottom="'Guardar Editado'" />
                            </template>
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="vistaPrevia" v-tooltip.bottom="'Vista Previa'" />

                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <!-- CONTENIDO DE LA FACTURA -->
                <template #content>
                    <TabView>
                        <TabPanel header="Datos generales">
                            <General></General>
                        </TabPanel>
                        <TabPanel header="Conceptos">
                            <Concepto></Concepto>
                        </TabPanel>
                        <TabPanel header="Complemento Carta Porte">
                            <Complemento></Complemento>
                        </TabPanel>
                        <TabPanel header="CFDI'S Relacionados">
                            <Relacionados></Relacionados>
                        </TabPanel>
                    </TabView>
                </template>
                <template #footer>
                    <div style="justify-content:right" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber v-model="$store.state.trasladoCartaPorteStore.subTotal" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>SubTotal</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber class="color" v-model="$store.state.trasladoCartaPorteStore.total"
                                    mode="decimal" :minFractionDigits="2" locale="en-US" />
                                <label>Total</label>
                            </span>
                        </div>
                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import General from './TrasladoCPGeneral.vue'
import Concepto from './TrasladoCPConceptos.vue'
import Complemento from './TrasladoCPComplemento.vue'
import Relacionados from './TrasladoCPCfdiRelacionado.vue'
import moment from 'moment'
import ComprobantePdf from "../../Pdf/ComplementoCartaPorteTrasladoPdf.vue";
export default {
    components: {
        General,
        Concepto,
        Complemento,
        Relacionados,
        ComprobantePdf
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogPdf: false,
            datosEmpresa: {},
        }
    },
    computed: {
        trasladoCP() {
            return this.$store.state.trasladoCartaPorteStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getEmpresa();
    },
    methods: {
        async getEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresa.empresaBase);
                this.datosEmpresa = response.data
                console.log('datos', this.datosEmpresa)
            } catch (error) {
                console.log(error)
            }
        },
        async postFactura() {
            console.log(this.trasladoCP)
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.trasladoCP }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.trasladoCP.rfc
                comprobante.nombre = this.trasladoCP.nombre
                comprobante.fecha = moment(this.trasladoCP.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.cartaPorte.ubicacionOrigen.fecha = moment(this.trasladoCP.cartaPorte.ubicacionOrigen.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.trasladoCartaPorteStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.trasladoCartaPorteStore.id = -1;
                this.$store.state.trasladoCartaPorteStore.folio = response.data.folio
                this.$store.state.trasladoCartaPorteStore.folioFiscal = response.data.folioFiscal
                this.$store.state.trasladoCartaPorteStore.rfc = comprobante.cliente.rfc
                this.$store.state.trasladoCartaPorteStore.nombre = comprobante.cliente.nombre

                this.$store.state.listTrasladoCartaPorteStore.push(this.$store.state.trasladoCartaPorteStore);
                let array = this.$store.state.listTrasladoCartaPorteStore;
                this.$store.state.listTrasladoCartaPorteStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async postGuardaFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.trasladoCP }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.trasladoCP.cliente.rfc
                comprobante.nombre = this.trasladoCP.cliente.nombre
                comprobante.fecha = moment(this.trasladoCP.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.cartaPorte.ubicacionOrigen.fecha = moment(this.trasladoCP.cartaPorte.ubicacionOrigen.fecha).format('YYYY-MM-DD HH:mm:ss')

                let response = await axios.post('Comprobantes/PostGuardaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.trasladoCartaPorteStore.id = -1;
                this.$store.state.trasladoCartaPorteStore.idComprobante = response.data;
                this.$store.state.trasladoCartaPorteStore.rfc = comprobante.cliente.rfc
                this.$store.state.trasladoCartaPorteStore.nombre = comprobante.cliente.nombre
                this.$store.state.trasladoCartaPorteStore.folioFiscal = response.data;

                this.$store.state.listTrasladoCartaPorteStore.push(this.$store.state.trasladoCartaPorteStore);
                let array = this.$store.state.listTrasladoCartaPorteStore;
                this.$store.state.listTrasladoCartaPorteStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.trasladoCP }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.trasladoCP.cliente.rfc
                comprobante.nombre = this.trasladoCP.cliente.nombre
                comprobante.fecha = moment(this.trasladoCP.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.cartaPorte.ubicacionOrigen.fecha = moment(this.trasladoCP.cartaPorte.ubicacionOrigen.fecha).format('YYYY-MM-DD HH:mm:ss')

                let response = await axios.put('Comprobantes/PutComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.trasladoCartaPorteStore.rfc = comprobante.cliente.rfc
                this.$store.state.trasladoCartaPorteStore.nombre = comprobante.cliente.nombre

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listTrasladoCartaPorteStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listTrasladoCartaPorteStore[indice] = comprobante
                console.log(indice)

                this.$store.state.trasladoCartaPorteStore.id = -1;
                let array = this.$store.state.listTrasladoCartaPorteStore;
                this.$store.state.listTrasladoCartaPorteStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putTimbrado() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.trasladoCP }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.trasladoCP.cliente.rfc
                comprobante.nombre = this.trasladoCP.cliente.nombre
                comprobante.fecha = moment(this.trasladoCP.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.cartaPorte.ubicacionOrigen.fecha = moment(this.trasladoCP.cartaPorte.ubicacionOrigen.fecha).format('YYYY-MM-DD HH:mm:ss')

                let response = await axios.post('Comprobantes/PostTimbraEditaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.trasladoCartaPorteStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.trasladoCartaPorteStore.id = -1;
                this.$store.state.trasladoCartaPorteStore.folio = response.data.folio
                this.$store.state.trasladoCartaPorteStore.folioFiscal = response.data.folioFiscal
                this.$store.state.trasladoCartaPorteStore.rfc = comprobante.cliente.rfc
                this.$store.state.trasladoCartaPorteStore.nombre = comprobante.cliente.nombre
                this.$store.state.trasladoCartaPorteStore.estatus = 'Vigente'

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listTrasladoCartaPorteStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listTrasladoCartaPorteStore[indice] = this.$store.state.trasladoCartaPorteStore
                console.log(indice)

                let array = this.$store.state.listTrasladoCartaPorteStore;
                this.$store.state.listTrasladoCartaPorteStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        vistaPrevia() {
            console.log(this.trasladoCP)
            this.dialogPdf = true;
        },

        cierraVentana() {
            this.$store.state.trasladoCartaPorteStore.id = -1;
            this.$emit('cierraVentana')
        },

        closeDialogPdf() {
            this.dialogPdf = false
            if (this.trasladoCP.timbreFiscalDigital.folioFiscal != '') {
                this.$emit('cierraVentana')
            }
        },
        //PARA ACTIVAR EL MODO PRECIO
        activaPrecio() {
            this.$store.state.modoImporteStore = false;
        },
    },
}
</script>