<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.iDUbicacion }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogDelete = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminar()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO CONCEPTO -->
        <Dialog :modal="false" :visible.sync="dialogoNuevo" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '800px' }">
            <Origen @cierraVentana="dialogoNuevo = false"></Origen>
        </Dialog>

        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left mt-3 p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Ubicaciones de Origen
                    </div>
                    <div class="mt-2 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoItem()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getCatalogo()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosCatalogos" removableSort showGridlines
                    ref="clientes" :value="itemsCatalogo" responsiveLayout="scroll" :paginator="true" :rows="10"
                    :globalFilterFields="[
                        'id', 'tipoUbicacion', 'iDUbicacion', 'rfcRemitenteDestinatario', 'nombreRemitenteDestinatario', 'numRegIdTrib', 'residenciaFiscal.pais']"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Origenes."
                    :loading="loadingItemsTabla">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosCatalogos['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column sortable field="id" header="ID" :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.id }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="tipoUbicacion" header="Tipo de Ubicación"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.tipoUbicacion }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="iDUbicacion" header="ID Ubicación"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.iDUbicacion }}
                        </template>
                    </Column>
                    <Column sortable field="rfcRemitenteDestinatario" header="RFC"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.rfcRemitenteDestinatario }}
                        </template>
                    </Column>
                    <Column sortable field="nombreRemitenteDestinatario" header="Nombre"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.nombreRemitenteDestinatario }}
                        </template>
                    </Column>
                    <Column sortable field="numRegIdTrib" header="Número de identificación o registro fiscal"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.numRegIdTrib }}
                        </template>
                    </Column>
                    <Column sortable field="residenciaFiscal.pais" header="Residencia Fiscal"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.residenciaFiscal.pais }}
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Editar/Eliminar">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editar(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="confirmDeleteProduct(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Origen from "./Origen.vue";
import moment from "moment";

export default {
    components: { Origen },

    data() {
        return {
            loadingMensaje: "",
            loading: false,
            dialogoNuevo: false,

            filtrosCatalogos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'tipoUbicacion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'iDUbicacion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'rfcRemitenteDestinatario': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'nombreRemitenteDestinatario': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'numRegIdTrib': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'residenciaFiscal.pais': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            loadingItemsTabla: false,
            objetoEditado: {},

            dialogDelete: false,
            editedIndex: -1,

        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsCatalogo() {
            return this.$store.state.listOrigenStore;
        },
    },
    created() {
        this.getCatalogo()
    },
    methods: {
        nuevoItem() {
            this.dialogoNuevo = true;

            if (this.$store.state.ubicacionStore != 0) {
                let obj = {
                    id: 0,
                    tipoUbicacion: '',
                    iDUbicacion: '',
                    rfcRemitenteDestinatario: '',
                    nombreRemitenteDestinatario: '',
                    numRegIdTrib: '',
                    residenciaFiscal: {
                        pais: '',
                    },
                    domicilio: {
                        domicilio: ''
                    },
                    usuarioCreador: '',
                    usuarioModificador: ''

                }
                this.$store.state.ubicacionStore = { ...obj }
            }
        },

        editar(data) {
            this.$store.state.ubicacionStore = { ...data }
            this.dialogoNuevo = true
        },

        confirmDeleteProduct(data) {
            console.log(data)
            this.editedIndex = this.itemsCatalogo.indexOf(data)
            this.objetoEditado = { ...data };
            this.dialogDelete = true;
        },

        async getCatalogo() {
            this.loadingItemsTabla = true;
            try {
                let response = await axios.get('CatalogosCartaPorte/GetUbicaciones/' + this.empresa.empresaBase + '/' + 'OR')
                this.$store.state.listOrigenStore = response.data
                console.log(response)
                this.loadingItemsTabla = false;

            } catch (error) {
                console.log(error)
                this.loadingItemsTabla = false;
            }
        },

        async eliminar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre

                let response = await axios.put('CatalogosCartaPorte/PutUbicacionesEstatus/' + this.empresa.empresaBase, this.objetoEditado)
                this.itemsCatalogo.splice(this.editedIndex, 1)

                this.dialogDelete = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Origen eliminado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
    },
};
</script>
<style>
.p-dialog .p-dialog-content {
    overflow-y: visible !important;
}

.p-dialog-mask {
    position: absolute;
    pointer-events: auto;
    /* (optional, doesn't cover whole area when scroll presents) */
}
</style>
