<template>
    <div>
        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ $store.state.almacenStore.descripcion }}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarAlmacen()" />
            </template>
        </Dialog>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO ALMACEN -->
        <Dialog :visible.sync="dialogoAlmacen" :closeOnEscape="true" :closable="false" :containerStyle="{ width: '700px' }">
            <Almacen @closeAlmacen="dialogoAlmacen = false"></Almacen>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Almacén
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button @click="nuevoAlmacen()" icon="pi pi-plus" class="mr-2 p-button-rounded" />
                        <Button @click="getAlmacenes()" icon="pi pi-undo" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosAlmacen" removableSort showGridlines ref="alamcenes"
                    :value="itemAlmacenes" responsiveLayout="scroll"
                    :globalFilterFields="['clave', 'descripcion', 'direccion', 'tipo']" :paginator="true" :rows="10"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Almacenes"
                    :loading="loadingAlmacenes">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosAlmacen['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column sortable field="clave" header="Clave"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.clave }}
                        </template>
                    </Column>
                    <Column sortable field="descripcion" header="Descripción"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.descripcion }}
                        </template>
                    </Column>
                    <Column sortable field="direccion" header="Dirección"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.direccion }}
                        </template>
                    </Column>
                    <Column sortable field="tipo" header="Tipo"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.tipo }}
                        </template>
                    </Column>
                    <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" header="Acciones"
                        :exportable="false" :styles="{ 'min-width': '8rem' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" style="width:35px; height:35px"
                                class="p-button-rounded p-button-success mr-2" @click="editarAlmacen(slotProps.data)"
                                v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" style="width:35px; height:35px"
                                class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)"
                                v-tooltip.bottom="'Eliminar'" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import Almacen from './Almacen.vue';

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            filtrosAlmacen: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
                "clave": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "descripcion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "direccion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "tipo": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            dialogoAlmacen: false,
            deleteProductDialog: false,
            loadingAlmacenes: false,

            editedIndex: -1,
            objetoEditado: {}
        };
    },
    computed: {
        itemAlmacenes() {
            return this.$store.state.listaAlmacenesStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getAlmacenes();
    },
    methods: {
        nuevoAlmacen() {
            this.dialogoAlmacen = true

            if (this.$store.state.almacenStore != 0) {
                let obj = {
                    idAlmacen: 0,
                    clave: '',
                    descripcion: '',
                    direccion: '',
                    tipo: '',
                }
                this.$store.state.almacenStore = { ...obj }
            }
        },
        async getAlmacenes() {
            this.loadingAlmacenes = true;
            try {
                let response = await axios.get('Almacenes/GetAlmacenes/' + this.empresa.empresaBase)
                this.$store.state.listaAlmacenesStore = response.data
                console.log(response)
                this.loadingAlmacenes = false;

            } catch (error) {
                console.log(error)
                this.loadingAlmacenes = false;

            }
        },
        editarAlmacen(data) {
            this.$store.state.almacenStore = { ...data };
            this.dialogoAlmacen = true
        },
        async eliminarAlmacen() {
            this.loading = true;
            this.loadingMensaje = 'Eliminando almacén, espere...'
            this.$store.state.almacenStore.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.post('Almacenes/DeleteAlmacen/' + this.empresa.empresaBase, this.$store.state.almacenStore)
                console.log(response.data)
                this.itemAlmacenes.splice(this.editedIndex, 1)

                this.loading = false;
                this.deleteProductDialog = false;

                this.$toast.add({ severity: 'success', detail: 'Almacén eliminado con éxito.', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemAlmacenes.indexOf(data)
            this.$store.state.almacenStore = data;
            this.deleteProductDialog = true;
        },
    },
    components: { Almacen }
}
</script>