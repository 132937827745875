<template>
    <Card class="shadow-none">
        <template #content>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- SERIE -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="pago.serie" :options="itemsSerie" :editable="true" />
                        <label>Serie</label>
                    </span>
                </div>
                <!-- FOLIO -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <InputText v-model="pago.folio" type="text" />
                        <label>Folio</label>
                    </span>
                </div>
                <!-- FECHA -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Calendar v-model="pago.fecha" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                        <label>Fecha</label>
                    </span>
                </div>
                <!-- CLIENTE -->
                <div class=" field col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" v-model="pago.cliente" :suggestions="filteredCliente"
                                @complete="searchCliente($event)" field="cliente">
                            </AutoComplete>
                            <label>Cliente</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="nuevoCliente" />
                    </div>
                </div>
                <!-- USO DE CFDI -->
                <div class="field col-12">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="usoCfdiDefault" :options="itemsUsoCfdi"
                            optionLabel="usoCfdi" disabled />
                        <label>Uso de CFDI</label>
                    </span>
                </div>
                <!-- MONEDA -->
                <div class="field col-12">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="pago.moneda" :options="itemsMoneda"
                            optionLabel="moneda" disabled />
                        <label>Moneda</label>
                    </span>
                </div>
                <!-- NOTAS -->
                <div class=" field col-12">
                    <span class="p-float-label">
                        <InputText v-model="pago.notas" type="text" />
                        <label>Notas</label>
                    </span>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
            </div>
        </template>
    </Card>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            filteredCliente: null,
            dialogoNuevoCliente: false,
            usoCfdiDefault: {
                id: 23,
                clave: 'CP01',
                descripcion: 'Pagos',
                fisica: 'Sí',
                moral: 'Sí',
                usoCfdi: 'CP01 | Pagos',
            },
        }
    },
    computed: {
        pago() {
            return this.$store.state.complementoPagoStore;
        },
        itemsSerie() {
            return this.$store.state.listComplementoPagoSerieStore;
        },
        itemsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
    },
    created() {
        this.getMoneda();
        this.getClientes();
        this.GetUsoCfdi();
        this.getSeries();

    },
    methods: {
        async getSeries() {
            try {
                let response = await axios.get('Comprobantes/GetSerieComprobantes/' + this.empresa.empresaBase + '/Complemento Pago/' + this.datosEmpresa.rfc);
                this.$store.state.listComplementoPagoSerieStore = response.data
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        },

        async GetUsoCfdi() {
            try {
                let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                this.$store.state.usoCfdiStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async getMoneda() {
            if (this.itemsMoneda.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },

        async getClientes() {
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.empresa.empresaBase);
                // console.log(result.data);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },

        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        nuevoCliente() {
            this.dialogoNuevoCliente = true;
        },
    },
}
</script>