<template>
  <div>
    <!-- DIALOGO PARA NOTIFICACIONES -->
    <div class="p-d-flex">
      <Toast />
    </div>
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>
    <!-- DIALOG PARA CANCELAR CFDI -->
    <Dialog :visible.sync="dialogCancelacion" :style="{ width: '95vw' }" :closable="false">
      <Cancelacion @cerrarVentanaCancelacion="dialogCancelacion = false"></Cancelacion>
    </Dialog>
    <!-- NUEVO COMPELEMENTO DE PAGO -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogPago" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <pago @closeComplementoPago="closeDialogPago()"></pago>
    </Dialog>
    <!-- DIALOG DEL PDF -->
    <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
      <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
    </Dialog>
    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
            <span style="cursor:pointer" @click="getTimbres()">Complemento de Pago </span>
            <ProgressSpinner v-if="datosGenerales == 1" style="width:36px;height:36px;  vertical-align: middle;"
              strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
            <Badge v-if="datosGenerales == 0" :value="timbresDisponibles" @click="getTimbres()" class="mr-2" size="large"
              severity="success">
            </Badge>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
              <label>Fecha Inicial</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
              <label>Fecha Final</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <Button icon="pi pi-plus" @click="nuevoPago()" class="mx-2 p-button-rounded" />
            <Button icon="pi pi-undo" @click="getPago()" class="p-button-rounded" />
          </div>
        </div>
      </template>

      <template #content>
        <DataTable ref="tabla" :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsPagos" :scrollable="true"
          scrollHeight="800px" scrollDirection="both" :loading="loading">
          <template #header>
            <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
              <div class="field col-3 md:col-3">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                </span>
              </div>
              <div class="field col-6 md:col-6">
              </div>
              <div class="field col-2 md:col-2">
                <span class="p-float-label">
                  <InputNumber readonly onlyread :value="totalComplemento" mode="decimal" locale="en-US"
                    :minFractionDigits="2" />
                  <label>Total</label>
                </span>
              </div>
              <div class="field col-1 md:col-1">
                <Button label="Exportar" @click="exportarExcel()"></Button>
              </div>
            </div>
          </template>
          <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
            <template #body="slotProps">
              <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100" v-tooltip.bottom="'PDF'"
                @click="openDialogPdf(slotProps.data)" />
              <Button icon="pi pi-replay" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Similar'"
                @click="facturasimilar(slotProps.data)" />
              <div v-if="slotProps.data.estatus === 'Sin Timbrar'">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info" v-tooltip.bottom="'Editar'"
                  @click="editarFactura(slotProps.data)" />
              </div>
              <div v-if="slotProps.data.estatus === 'Vigente'">
                <Button v-if="slotProps.data.estatusCancelacion != 'EnProceso'" icon="pi pi-ban"
                  class="p-button-rounded p-button-danger" v-tooltip.bottom="'Cancelar'"
                  @click="openCancelacion(slotProps.data)"></Button>
                <Button icon="pi pi-check-circle" style="background:green" class="p-button-rounded "
                  v-tooltip.bottom="'Actualizar Estatus SAT'" @click="actualizarEstatus(slotProps.data)"></Button>
              </div>
            </template>
          </Column>
          <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
          </Column>
          <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
            <template #body="slotProps">
              <span :class="'customer-badge status-' + slotProps.data.estatus + slotProps.data.estatusCancelacion">{{
                slotProps.data.folio
              }}</span>
            </template>
          </Column>
          <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="nombre" header="Nombre"
            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
          </Column>
          <Column field="pagos.totales.montoTotalPagos" header="Total Pago"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
            <template #body="{ data }">
              <span>{{ formatCurrency(data.pagos.totales.montoTotalPagos) }}</span>
            </template>
          </Column>
          <Column field="folioFiscal" header="Folio fiscal"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="formaPago.formaPago" header="Forma de Pago"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="metodoPago.metodoPago" header="Método de Pago"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="estatusCancelacion" header="Solicitud de Cancelación"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>
<script>
import axios from "axios";
import pago from './ComplementoPago.vue';
import moment from 'moment'
import ComprobantePdf from "../../Pdf/ComplementoPagoPdf.vue";
import Cancelacion from "../../Cancelacion/Cancelacion.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api/";


export default {
  components: { pago, ComprobantePdf, Cancelacion },
  data() {
    return {
      dialogPago: false,
      dialogPdf: false,
      fechaI: new Date(),
      fechaF: new Date(),
      loading: false,
      dialogCancelacion: false,
      dialogLoading: false,
      loadingMensaje: '',
      filtros: {
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        serie: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        folio: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        rfc: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
      },
      datosGenerales: 0
    }
  },
  computed: {
    empresa() {
      return this.$store.state.empresaStore;
    },
    usuario() {
      return this.$store.state.usuario;
    },
    itemsPagos() {
      return this.$store.state.listComplementoPagoStore;
    },
    timbresDisponibles() {
      return this.$store.state.timbresDisponiblesStore;
    },
    totalComplemento() {
      let total = 0;
      for (let a of this.itemsPagos) {
        total += a.pagos.totales.montoTotalPagos;
      }

      return total;
    }
  },
  created() {
    this.getEmpresa();
    this.inicializa();
  },
  methods: {
    closeDialogPago() {
      this.dialogPago = false;
    },

    async getEmpresa() {
      try {
        let response = await axios.get('Empresas/GetEmpresa/' + this.usuario.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
      }
    },

    nuevoPago() {
      console.log(this.$store.state.complementoPagoStore.idComplementoPago)
      let comprobante = this.$store.state.complementoPagoStore.idComplementoPago;
      if (comprobante == -1) {
        const fecha = new Date();
        const anio = fecha.getFullYear()
        const mes = fecha.getMonth()
        const dia = fecha.getDate()
        var fechaNueva = new Date(anio, mes, dia, 12, 0, 0)

        let nuevo = {
          idComplementoPago: 0,
          folioFiscal: '',
          serie: '',
          folio: '',
          fecha: new Date(),
          subTotal: 0,
          descuento: 0,
          moneda: {
            id: 174,
            clave: 'XXX',
            descripcion: 'Los códigos asignados para las transacciones en que intervenga ninguna moneda',
            moneda: 'XXX | Los códigos asignados para las transacciones en que intervenga ninguna moneda'
          },
          tipoCambio: 0,
          total: 0,
          tipoComprobante: 'P',
          tipoComprobanteInterno: 'Complemento Pago',
          empresa: {},
          cliente: {
            rfc: '',
            nombre: '',
            cliente: '',
            usoCfdi: {},
            regimenFiscal: { regimenFiscal: '' }
          },
          rfc: '',
          nombre: '',
          atn: '',
          notas: '',
          estatus: 'Sin Timbrar',
          estatusCancelacion: '',
          usuarioCreador: '',
          usuarioModificador: '',
          usuarioAutoriza: '',
          conceptos: [
            {
              item: 1,
              idConcepto: 0,
              claveProdServ: { 'id': 51586, 'clave': '84111506', 'descripcion': 'Servicios de facturación', 'palabrasSimilares': '', 'claveProdServ': '84111506 | Servicios de facturación' },
              noIdentificacion: '',
              cantidad: 1,
              claveUnidad: { 'id': 241, 'clave': 'ACT', 'descripcion': 'Actividad', 'claveUnidad': 'ACT | Actividad' },
              unidad: '',
              descripcion: 'Pago',
              valorUnitario: 0,
              importe: 0,
              descuento: 0,
              precio: 0,
              total: 0,
              objImp: { 'id': 3, 'clave': '01', 'descripcion': 'No objeto de impuesto.', 'objImp': '01 | No objeto de impuesto.' },
              impuestos: {
                traslados: [],
                retenciones: [],
              },
              numeroCuentaPredial: '',
            }
          ],
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          cfdiRelacionados: [
            {
              tipoRelacion: {},
              cfdiRelacionados: [],
            }
          ],
          pagos: {
            version: '2.0',
            totales: {
              totalRetencionesIVA: 0,
              totalRetencionesISR: 0,
              totalRetencionesIEPS: 0,
              totalTrasladosBaseIVA16: 0,
              totalTrasladosImpuestoIVA16: 0,
              totalTrasladosBaseIVA8: 0,
              totalTrasladosImpuestoIVA8: 0,
              totalTrasladosBaseIVA0: 0,
              totalTrasladosImpuestoIVA0: 0,
              totalTrasladosBaseIVAExento: 0,
              montoTotalPagos: 0,
            },
            pagos: [{
              fechaPago: fechaNueva,
              formaDePagoP: {},
              monedaP: {
                id: 100,
                clave: 'MXN',
                descripcion: 'Peso Mexicano',
                moneda: 'MXN | Peso Mexicano'
              },
              tipoCambioP: 0,
              monto: 0,
              numOperacion: '',
              rfcEmisorCtaOrd: '',
              nomBancoOrdExt: '',
              ctaOrdenante: '',
              rfcEmisorCtaBen: '',
              ctaBeneficiario: '',
              tipoCadPago: {
                clave: '',
              },
              certPago: {
                base64: '',
              },
              cadPago: {
                base64: '',
              },
              selloPago: {
                base64: '',
              },
              doctoRelacionados: [],
              impuestosP: {
                traslados: [],
                retenciones: []
              },
            }],
          },
          timbreFiscalDigital: {
            folioFiscal: '',
            fechaTimbrado: new Date(),
            selloCFDI: { base64: '' },
            noCertificado: '',
            noCertificadoSAT: '',
            selloSAT: { base64: '' },
            cadenaOriginal: '',
            serie: '',
            folio: '',
            Error: '',
            archivoXml: '',
          }
        }
        this.$store.state.complementoPagoStore = { ...nuevo }
      }
      this.dialogPago = true;
    },

    facturasimilar(item) {
      console.log(item)
      let similar = { ...item }
      let timbreFiscalDigital = {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }

      similar.idComplementoPago = 0
      similar.folioFiscal = ''
      similar.folio = ''
      similar.estatus = 'Sin Timbrar'
      similar.estatusCancelacion = ''
      similar.fecha = new Date()
      similar.timbreFiscalDigital = { ...timbreFiscalDigital }

      this.$store.state.complementoPagoStore = { ...similar }
      this.dialogPago = true;
    },

    editarFactura(item) {
      let editar = { ...item }
      this.$store.state.complementoPagoStore = { ...editar }
      this.dialogPago = true;
    },

    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },

    formatDate(value) {
      return "";
    },

    async getPago() {
      this.loading = true;
      this.$store.state.listComplementoPagoStore = []
      try {
        let fI = moment(this.fechaI).format('YYYY-MM-DD')
        let fF = moment(this.fechaF).format('YYYY-MM-DD')
        let response = await axios.get('ComplementoDePago/GetComplementoPago/' + this.usuario.empresaBase + '/Complemento Pago/' + fI + '/' + fF + '/' + this.empresa.rfc);
        console.log(response.data)
        let array = response.data
        this.$store.state.listComplementoPagoStore = array.sort(((a, b) => b.folio - a.folio))
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    openDialogPdf(data) {
      this.dialogPdf = true;
      this.$store.state.complementoPagoStore = { ...data }
    },

    closeDialogPdf() {
      this.dialogPdf = false;
      this.$store.state.listComplementoPagoStore.idFactura = -1;
    },

    openCancelacion(data) {
      this.dialogCancelacion = true
      this.$store.state.cancelacionStore = { ...data }
    },

    async actualizarEstatus(data) {
      this.loadingMensaje = 'Consultando estatus...'
      let actualizado = { ...data }

      console.log(this.usuario)
      this.dialogLoading = true;
      let actualizaEstatus = {
        idComprobante: data.idComprobante,
        rfc: data.empresa.rfc,
        folioFiscal: data.folioFiscal,
        motivoCancelacion: {},
        uuidSustituir: '',
        total: data.total,
        usuarioModificador: this.usuario.nombre
      }
      // console.log(actualizaEstatus)
      try {
        let response = await axios.post('Comprobantes/ActualizarEstatusSat/' + this.usuario.empresaBase + '/', actualizaEstatus)
        actualizado.estatus = response.data.estatus
        actualizado.estatusCancelacion = response.data.estatusCancelacion

        // BUSCAMOS EL INDICE
        let indice = this.itemsPagos.findIndex(x => x.folioFiscal === actualizado.folioFiscal)
        console.log(indice)
        Object.assign(this.itemsPagos[indice], actualizado)
        console.log(actualizado)

        this.$toast.add({ severity: 'success', detail: 'Estatus actualizado.', life: 5000 });
        this.dialogLoading = false;

      } catch (err) {
        console.log(err.response.data)
        this.$toast.add({ severity: 'error', detail: err.response.data, life: 5000 });
        this.dialogLoading = false;
      }
    },

    async inicializa() {
      this.datosGenerales = 1
      // this.dialogLoading = true;
      // this.loadingMensaje = 'Consultando datos...'
      await this.getEmpresa();
      await this.getTimbres();
      // this.dialogLoading = false;
      this.datosGenerales = 0
    },

    async getEmpresa() {

      try {
        let response = await axios.get('Empresas/GetEmpresa/' + this.usuario.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
        this.dialogLoading = false;
      }
    },

    async getTimbres() {
      this.datosGenerales = 1

      // this.dialogLoading = true;
      // this.loadingMensaje = 'Consultando timbres...'
      try {
        let response = await axios.get('Comprobantes/GetTimbres/' + this.empresa.rfc);
        this.$store.state.timbresDisponiblesStore = response.data
        this.datosGenerales = 0
        // this.dialogLoading = false;
      } catch (error) {
        console.log(error)
        this.datosGenerales = 0
        // this.dialogLoading = false;
      }
    },
    exportarExcel() {
      this.$refs.tabla.exportCSV()
    }
  },
}
</script>
<style>
.orange-button {
  background-color: #F46723;
}

.status-Vigente {
  background-color: #4CAF50;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-VigenteSinRespuesta {
  background-color: #4CAF50;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-VigenteEnProceso {
  background-color: #FF6F00;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Sin {
  background-color: #2196F3;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Cancelado {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-CanceladoCanceladoSinAceptacion {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}
</style>