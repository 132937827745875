<template>
  <div>
    <!-- DIALOGO PARA AUTORIZAR -->
    <Dialog :visible.sync="autorizarProducto" :closable="false" :containerStyle="{ width: '390px' }"
      header="¿Quien Autoriza?" :modal="true">
      <Card>
        <template #content>
          <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <div class="field col-12">
              <div class="p-inputgroup">
                <span class="p-float-label">
                  <AutoComplete style="text-align: left" v-model="usuarioAutoriza" :suggestions="filteredUsuario"
                    @complete="searchUsuario($event)" field="usuario">
                  </AutoComplete>
                  <label>Usuario</label>
                </span>
              </div>
            </div>
          </div>
          <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4"
            :should-auto-focus="true" v-model="password" :is-input-num="true" @on-change="handleOnChange"
            @on-complete="handleOnComplete" type="password" />
        </template>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="autorizarProducto = false" />
          <Button label="Autorizar" icon="pi pi-check" class="p-button-text" @click="autorizarProductoAsync()" />
        </template>
      </Card>
    </Dialog>

    <!-- DIALOGO CONFIRMAR ELIMINACION -->
    <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Estas seguro de eliminar la cotización
          <b>{{ objetoEditado.serie + objetoEditado.folio }}</b>.?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarCotizacion()" />
      </template>
    </Dialog>

    <!-- DIALOGO DE LOADING -->
    <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>

    <!-- NUEVA VENTA COTIZACION -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogVentaCotizacion" :closeOnEscape="true"
      :closable="false" :modal="false" :style="{ width: '95vw' }">
      <VentaCotizacion @closeVentaCotizacion="dialogVentaCotizacion = false"></VentaCotizacion>
    </Dialog>

    <!-- NUEVA VENTA PEDIDO -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogVentaPedido" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <VentaPedido @closeVentaPedido="dialogVentaPedido = false"></VentaPedido>
    </Dialog>

    <!-- PDF -->
    <Dialog :showHeader="false" :visible.sync="dialogPdf" :style="{ width: '800px' }" :closable="false" :modal="true"
      position="top">
      <CotizacionPDF @cierraDialogPdf="closeDialogPdf"></CotizacionPDF>
    </Dialog>

    <!-- DIALOGO PARA ERRORES -->
    <div class="p-d-flex">
      <Toast />
    </div>

    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
            <Button icon="mdi mdi-arrow-right-bold" class="p-button-rounded p-button-secondary p-button-outlined -mb-1"
              @click="irPedidos()" />
            Cotizaciones
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
              <label>Fecha Inicial</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
              <label>Fecha Final</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
            <Button icon="pi pi-plus" @click="nuevoCotizacion()" class="mx-2 p-button-rounded" />
            <Button icon="pi pi-undo" @click="getCotizaciones()" class="p-button-rounded" />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable class="mt-1 p-datatable-sm" :filters.sync="filtrosCotizacion" :value="itemsCotizaciones"
          :scrollable="true" scrollHeight="500px" scrollDirection="both" :loading="loadingItemsCotizaciones"
          :contextMenuSelection.sync="selectedProduct" :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :paginator="true" :rows="10" contextMenu @row-contextmenu="onRowContextMenu" :globalFilterFields="[
            'folio',
            'rfc',
            'nombre',
            'proyecto.descripcion',
          ]">
          <template #header>
            <div class="flex justify-content-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filtrosCotizacion['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column header="Acciones" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'min-width': '15rem',
          }" frozen>
            <template #body="slotProps">
              <template v-if="
                slotProps.data.estatus === 'Nuevo' ||
                slotProps.data.estatus === 'Modificada'
              ">
                <Button @click="confirmDeleteProduct(slotProps.data)" icon="pi pi-trash"
                  class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar'" />
                <Button @click="editConcepto(slotProps.data)" icon="pi pi-pencil" class="p-button-rounded p-button-info"
                  v-tooltip.bottom="'Editar'" />
                <Button @click="abrirAutorizarProducto(slotProps.data)" icon="pi pi-check"
                  class="p-button-rounded p-button-success" v-tooltip.bottom="'Autorizar'" />
              </template>
              <Button icon="pi pi-replay" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Similar'"
                @click="cotizacionSimilar(slotProps.data)" />
              <Button @click="openDialogPdf(slotProps.data)" icon="pi pi-file-pdf" class="p-button-rounded"
                style="background: #e65100" v-tooltip.bottom="'PDF'" />
            </template>
          </Column>
          <!-- <Column field="idVenta" header="ID" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
          </Column> -->
          <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
          </Column>
          <Column field="folio" header="Folio" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'text-align': 'center',
          }">
            <template #body="slotProps">
              <span :class="'customer-badge status-' + slotProps.data.estatus">{{ slotProps.data.folio }}</span>
            </template>
          </Column>
          <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
            sortable>
            <template #body="{ data }">
              {{ formatDate(data.fecha) }}
            </template>
          </Column>
          <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
          </Column>
          <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
          </Column>
          <Column field="vigencia" header="Vigencia" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            <template #body="{ data }">
              {{ formatDate(data.vigencia) }}
            </template>
          </Column>
          <Column field="proyecto" header="Proyecto" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            <template #body="{ data }">
              {{ data.proyecto.descripcion }}
            </template>
          </Column>
          <Column field="subTotal" header="SubTotal" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'justify-content': 'right',
          }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.subTotal) }}
            </template>
          </Column>
          <Column field="total" header="Total" :styles="{
            'flex-grow': '1',
            'flex-basis': '100px',
            'justify-content': 'right',
          }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.total) }}
            </template>
          </Column>
          <Column field="moneda.clave" header="Moneda" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
          </Column>
        </DataTable>
        <ContextMenu :model="menuModel" ref="cm" />
      </template>
      <template #footer>
        <div class="flex align-items-center">
          <Chip label="Nuevo" class="mr-2 nuevo-chip" />
          <Chip label="Autorizado" class="mr-2 autorizado-chip" />
          <Chip label="Pedido" class="mr-2 pedido-chip" />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from "moment";
import VentaCotizacion from "./VentaCotizacion.vue";
import CotizacionPDF from "../Pdf/VentaCotizacionPdf";
import VentaPedido from "../VentasPedidos/VentaPedido.vue";

export default {
  components: { VentaCotizacion, CotizacionPDF, VentaPedido },
  data() {
    return {
      listaUsuariosAutorizantes: [],
      filteredUsuario: null,
      usuarioAutoriza: { usuario: '' },
      autorizarProducto: false,
      password: "",

      dialogPdf: false,

      loadingMensaje: "",
      loading: false,

      fechaI: new Date(),
      fechaF: new Date(),

      loadingItemsCotizaciones: false,
      dialogVentaCotizacion: false,

      deleteProductDialog: false,
      editedIndex: -1,
      objetoEditado: {},

      menuModel: [
        {
          label: "Pedido",
          icon: "pi pi-fw pi-reply",
          command: () => this.convertirPedido(this.selectedProduct),
        },
      ],
      dialogVentaPedido: false,
      selectedProduct: null,

      idCotizacion: 0,

      filtrosCotizacion: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rfc: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        folio: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "proyecto.descripcion": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  computed: {
    itemsCotizaciones() {
      return this.$store.state.listVentaCotizacionStore;
    },
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    irPedidos() {
      this.$router.push({ name: "ListaVentasPedidos" });
    },
    async getEmpresa() {
      try {
        let response = await axios.get(
          "Empresas/GetEmpresa/" + this.token.empresaBase
        );
        this.$store.state.empresaStore = response.data;
      } catch (error) {
        console.log(error);
        this.dialogLoading = false;
      }
    },

    convertirPedido(item) {
      if (item.estatus === "Nuevo" || item.estatus === "Modificada") {
        this.$toast.add({
          severity: "info",
          detail:
            "La cotización debe de estar autorizada para convertirla en pedido.",
          life: 3000,
        });
        return;
      }

      if (item.estatus === "Pedido") {
        this.$toast.add({
          severity: "info",
          detail: "La cotización ya ha sido convertida en pedido.",
          life: 3000,
        });
        return;
      }

      let cotizacion = { ...item };
      let obj = {
        idVenta: 0,
        serie: "",
        folio: "",
        fecha: new Date(),
        vigencia: new Date(),
        formaPago: {},
        condicionesPago: cotizacion.condicionesPago,
        subTotal: cotizacion.subTotal,
        descuento: 0,
        moneda: cotizacion.moneda,
        tipoCambio: 0,
        total: cotizacion.total,
        tipoComprobante: "Pedido",
        metodoPago: {},
        empresa: cotizacion.empresa,
        cliente: cotizacion.cliente,
        rfc: cotizacion.rfc,
        nombre: cotizacion.nombre,
        atn: cotizacion.atn,
        notas: cotizacion.notas,
        estatus: "Autorizado",
        usuarioCreador: "",
        usuarioModificador: "",
        usuarioAutoriza: "",
        conceptos: cotizacion.conceptos,
        impuestos: cotizacion.impuestos,
        proyecto: cotizacion.proyecto,
        idRelacion: item.idVenta,
        ordenCompra: "",
        ordenTrabajo: "",
      };

      this.$store.state.ventaPedidoStore = { ...obj };
      this.dialogVentaPedido = true;
      this.$toast.add({
        severity: "info",
        detail: "Seleccione un proyecto para el pedido.",
        life: 3000,
      });
    },

    async getCotizaciones() {
      try {
        this.loadingItemsCotizaciones = true;
        let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
        let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
        let response = await axios.get(
          "Ventas/GetVentasCotizaciones/" +
          this.token.empresaBase +
          "/" +
          fechaInicial +
          "/" +
          fechaFinal
        );
        let array = response.data;
        this.$store.state.listVentaCotizacionStore = array.sort(
          (a, b) => b.folio - a.folio
        );
        this.loadingItemsCotizaciones = false;
      } catch (error) {
        console.log(error);
        this.loadingItemsCotizaciones = false;
      }
    },

    async eliminarCotizacion() {
      this.loading = true;
      this.loadingMensaje = "Procesando soliciud, espere...";

      try {
        let ObjCotizacion = { ...this.objetoEditado };
        ObjCotizacion.usuarioModificador = this.token.nombre;
        ObjCotizacion.estatus = "Eliminado";
        let response = await axios.put(
          "Ventas/DeleteVenta/" + this.token.empresaBase,
          ObjCotizacion
        );
        this.itemsCotizaciones.splice(this.editedIndex, 1);
        this.deleteProductDialog = false;
        this.loading = false;
        this.$toast.add({
          severity: "success",
          detail: "Cotizacion eliminado con éxito.",
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          detail: "Error al eliminar, intente nuevamente.",
          life: 3000,
        });
        this.loading = false;
      }
    },

    async abrirAutorizarProducto(data) {
      let listaUsuariosAutorizantes = [];
      this.idCotizacion = data.idVenta
      await this.getUsuarios();

      //BUSCAR EL USUARIO EN LA LISTA
      this.loadingMensaje = 'Validando usuario...'
      let indice = this.listaUsuariosAutorizantes.findIndex(x => x.usuario.toUpperCase() === this.token.nombre.toUpperCase())
      if (indice != -1) {
        let ObjUsuario = {}
        ObjUsuario = { ...this.listaUsuariosAutorizantes[indice] }
        let indicePermiso = ObjUsuario.permisos.findIndex(x => x.permiso === 'Autorizar')
        if (indicePermiso != -1) {
          console.log('Autorzacion directa')
          this.usuarioAutoriza.usuario = ObjUsuario.usuario
          this.password = ObjUsuario.pin
          await this.autorizarProductoAsync();
          this.loading = false;
        }
        else {
          console.log('mostrar dialog')
          this.password = "";
          this.usuarioAutoriza = "";
          this.autorizarProducto = true;
          this.loading = false;
        }
      }
      else {
        console.log('mostrar dialog')
        this.password = "";
        this.usuarioAutoriza = "";
        this.autorizarProducto = true;
        this.loading = false;
      }
    },

    async autorizarProductoAsync() {
      this.loading = true;
      this.loadingMensaje = "Procesando soliciud, espere...";

      // let ObjCotizacion = { ...this.$store.state.ventaCotizacionStore };
      // ObjCotizacion.estatus = "Autorizado";
      try {
        let response = await axios.post(
          "Ventas/autorizarProducto/" +
          this.token.empresaBase +
          "/" +
          this.idCotizacion +
          "/" +
          this.usuarioAutoriza.usuario +
          "/" +
          this.password
        );
        this.$toast.add({
          severity: "success",
          detail: "Cotización Autorizado con éxito.",
          life: 3000,
        });

        let indice = this.$store.state.listVentaCotizacionStore.findIndex(
          (x) => x.idVenta === this.idCotizacion
        );
        this.$store.state.listVentaCotizacionStore[indice].estatus = "Autorizado"
        this.loading = false;
        this.autorizarProducto = false;
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          detail: "Error al autorizar, intente nuevamente.",
          life: 3000,
        });
        this.loading = false;
        this.handleClearInput();
        this.usuarioAutoriza = "";
      }
    },

    searchUsuario(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUsuario = [...this.listaUsuariosAutorizantes];
        } else {
          this.filteredUsuario = this.listaUsuariosAutorizantes.filter(
            (country) => {
              return country.usuario
                .toLowerCase()
                .includes(event.query.toLowerCase());
            }
          );
        }
      }, 250);
    },

    async getUsuarios() {
      this.loading = true;
      this.loadingMensaje = 'Consultando usuarios...'
      try {
        let response = await axios.get(
          "Usuarios/GetUsuariosAutorizantes/" + this.token.empresaBase
        );
        this.listaUsuariosAutorizantes = response.data;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    openDialogPdf(data) {
      this.dialogPdf = true;
      this.$store.state.ventaCotizacionStore = { ...data };
    },

    closeDialogPdf() {
      this.dialogPdf = false;
    },

    editConcepto(data) {
      this.$store.state.ventaCotizacionStore = { ...data };
      this.dialogVentaCotizacion = true;
    },

    confirmDeleteProduct(data) {
      this.editedIndex = this.itemsCotizaciones.indexOf(data);
      this.objetoEditado = { ...data };
      this.deleteProductDialog = true;
    },

    nuevoCotizacion() {
      let cotizacion = this.$store.state.ventaCotizacionStore.idVenta;
      if (cotizacion == -1) {
        let obj = {
          imagenGeneral: { base64: '' },
          idVenta: 0,
          serie: null,
          folio: "",
          fecha: new Date(),
          vigencia: new Date(),
          formaPago: {},
          condicionesPago: "",
          subTotal: 0,
          descuento: 0,
          moneda: {
            id: 100,
            clave: "MXN",
            descripcion: "Peso Mexicano",
            moneda: "MXN | Peso Mexicano",
          },
          tipoCambio: 0,
          total: 0,
          tipoComprobante: "Cotizacion",
          metodoPago: {},
          empresa: {},
          cliente: {
            rfc: "",
            nombre: "",
            cliente: "",
            usoCfdi: {},
          },
          rfc: "",
          nombre: "",
          atn: "",
          email: "",
          notas: "",
          estatus: "Nuevo",
          usuarioCreador: "",
          usuarioModificador: "",
          usuarioAutoriza: "",
          conceptos: [],
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          proyecto: {
            descripcion: "",
            presupuesto: 0,
          },
          ordenTrabajo: "",
          ordenCompra: "",
          tiempoEntrega: "",
          condicionesEntrega: "",
        };
        this.$store.state.ventaCotizacionStore = { ...obj };
      }
      this.dialogVentaCotizacion = true;
    },

    cotizacionSimilar(item) {
      let similar = { ...item };
      similar.idVenta = 0;
      similar.folio = "";
      similar.estatus = "Nuevo";
      similar.fecha = new Date();

      this.$store.state.ventaCotizacionStore = { ...similar };
      this.dialogVentaCotizacion = true;
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    formatDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    handleOnComplete(value) {
      console.log("OTP completado: ", value);
      this.password = value;
    },

    handleOnChange(value) {
      console.log("OTP cambio: ", value);
      this.itemsEmpresa = [];
    },

    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },
  },
};
</script>
<style>
.status-Autorizado {
  background-color: #2ba2de;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Nuevo {
  background-color: #ed6421;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Modificada {
  background-color: #ed6421;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Pedido {
  background-color: #3ebf4f;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.p-chip.nuevo-chip {
  background: #ed6421;
  color: var(--primary-color-text);
}

.p-chip.autorizado-chip {
  background: #2ba2de;
  color: var(--primary-color-text);
}

.p-chip.pedido-chip {
  background: #3ebf4f;
  color: var(--primary-color-text);
}
</style>
