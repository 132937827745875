<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Origen
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid ">
                    <!-- ID UBICACION -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="6" v-model="item.iDUbicacion" @blur="validaIdUbicacion" />
                            <label>ID de Ubicación</label>
                        </span>
                        <small>Cuando las mercancías tengan diferentes ubicaciones de tipo origen, se les debe asignar
                            un identificador conformado de 6 dígtos, Ejemplo: 000001 , el acrónimo OR se asigna de
                            manera automática al generar el comprobante.</small>
                    </div>
                    <!-- RFC DEL REMITENTE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="13" v-model="item.rfcRemitenteDestinatario" />
                            <label>RFC del Remitente</label>
                        </span>
                        <small>Se deberá registrar el RFC del remitente, 13 posiciones persona física, 12 posiciones
                            persona moral.</small>
                    </div>
                    <!-- NOMBRE DEL REMITENTE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="254" v-model="item.nombreRemitenteDestinatario" />
                            <label>Nombre del Remitente</label>
                        </span>
                        <small>En este campo se debe de registrar el nombre del remitente de los bienes y/o mercancías
                            que se trasladan.</small>
                    </div>
                    <template v-if="validaRfc">
                        <!-- NUMERO DE IDENTIFICACION -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText v-model="item.numRegIdTrib" />
                                <label>Número de identificación o registro fiscal</label>
                            </span>
                            <small>En este campo se podrán registrar los datos de identificación fiscal del
                                remitente</small>
                        </div>
                        <!-- RESIDENCIA FISCAL -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="item.residenciaFiscal" :options="itemsPais" optionLabel="pais">
                                </Dropdown>
                                <label>Residencia Fiscal</label>
                            </span>
                            <small>En este campo podrás registrar la clave del país del remitente, siempre que se haya
                                registrado un valor en el campo “NumRegIdTribFigura” indicado en el apartado Tipo de
                                figura.</small>
                        </div>
                    </template>
                    <!-- DOMICILIO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.domicilio" :options="itemsDirecciones" optionLabel="domicilio">
                            </Dropdown>
                            <label>Domicilio</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',


            editedIndex: -1,
            itemsPais: [],
        }
    },
    computed: {
        itemsDirecciones() {
            return this.$store.state.listDireccionStore;
        },
        item() {
            return this.$store.state.ubicacionStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        validaRfc() {
            if (this.item.rfcRemitenteDestinatario == 'XEXX010101000') {
                return true;
            } else {
                return false;
            }
        },
    },
    created() {
        this.listarPaises();
        this.getDirecciones();
    },
    methods: {

        guardarOactualizar() {
            if (this.$store.state.ubicacionStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Origen, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            this.item.tipoUbicacion = 'OR'
            try {
                let response = await axios.post('CatalogosCartaPorte/PostUbicaciones/' + this.empresa.empresaBase, this.item)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Origen guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listOrigenStore.push(this.item)

                const newData = this.$store.state.listOrigenStore;

                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Origen, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            this.item.tipoUbicacion = 'OR'
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateUbicaciones/' + this.empresa.empresaBase, this.item)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listOrigenStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listOrigenStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Origen actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        validaIdUbicacion() {
            if (this.item.iDUbicacion) {
                try {
                    let variable = this.item.iDUbicacion
                    let contador = this.item.iDUbicacion.length
                    for (var i = 0; i < 6 - contador; i++) {
                        variable = '0' + variable
                    }
                    this.item.iDUbicacion = variable
                } catch (err) {
                    console.log('Error: ' + err)
                }
            }
        },

        async getDirecciones() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetDirecciones/' + this.empresa.empresaBase)
                this.$store.state.listDireccionStore = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        async listarPaises() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetPaises');
                this.itemsPais = response.data
            } catch (error) {
                console.log(error)
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>