<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO BANCO -->
        <Dialog :visible.sync="dialogoNuevoDeposito" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '95vw' }" position="top" :modal="true">
            <Depositos @closeDepositos="closeDialogMovimiento()"></Depositos>
        </Dialog>
        <!-- CONSULTA COMPROBANTES MOVIMIENTO -->
        <Dialog :visible.sync="dialogoComprobantesMov" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <ComprobantesMov @closeComprobantesMov="closeDialogComprobantesMov()"></ComprobantesMov>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOG PARA ELIMINAR BANCOS -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDelete = false" />
                Eliminar
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                    @click="confirmDelete()" />
            </div>
            <hr size="7" color="#ED6421" />
            <br />
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="editedMovimiento">Esta seguro que desea cancelar el movimiento con referencia
                    <b>{{ editedMovimiento.referencia }}</b>?</span>
            </div>
        </Dialog>
        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Depósitos
                    </div>

                    <!-- FECHAS -->
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-plus" @click="nuevoMovimiento()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getDepositos()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosClientes" removableSort showGridlines ref="clientes"
                    :value="itemsMovimientos" responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'jsonBancosSat.jsonBancosSat.banco',
                        'referencia',
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Datos."
                    :loading="loadingItemsClientes">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosClientes['global'].value" placeholder="Buscar" />
                            </span>
                            <span class="p-float-label">
                                <Dropdown v-model="selectedCajaBanco" :options="CajaBanco" optionLabel="name"
                                    placeholder="Tipo de Busqueda" />
                            </span>
                        </div>
                    </template>

                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editMovimiento(slotProps.data)" v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="openDialogDelete(slotProps.data)" v-tooltip.bottom="'Eliminar'" />
                            <Button icon="pi pi-search" class="p-button-rounded p-button-warning"
                                @click="openDialogComprobantesMov(slotProps.data)"
                                v-tooltip.bottom="'Consultar Comprobantes'" />
                        </template>
                    </Column>
                    <Column sortable header="Banco/Caja" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.jsonBancosSat.jsonBancosSat.banco }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <!-- <Column sortable field="idCatalogoBancos" header="idCatalogoBancos"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                    </Column> -->
                    <Column sortable field="referencia" header="Referencia"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.referencia }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha Movimiento"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatDate(slotProps.data.fechaMovimiento) }}</span>
                        </template>
                    </Column>
                    <Column field="monto" header="Monto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.monto) }}</span>
                        </template>
                    </Column>
                    <Column sortable header="Moneda" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.moneda.clave }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable header="Notas" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.notas }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable header="Numero de comprobantes"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.comprobantes.length }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Depositos from "./Depositos.vue";
import ComprobantesMov from "./ConsultaComprobantesMov.vue"
import moment from 'moment'
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            filtrosClientes: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                "jsonBancosSat.jsonBancosSat.banco": {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                referencia: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },

            },
            dialogoNuevoDeposito: false,
            loadingItemsClientes: false,
            dialogDelete: false,
            editedIndexMovimiento: -1,
            editedMovimiento: {},
            fechaI: new Date(),
            fechaF: new Date(),
            selectedCajaBanco: null,
            CajaBanco: [
                { name: 'Todo', code: 'todo' },
                { name: 'Caja', code: 'caja' },
                { name: 'Banco', code: 'banco' },
            ],
            dialogoComprobantesMov: false,
            consultaIndexFolio: -1,
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsMovimientos() {
            return this.$store.state.listMovimientos;
        },
    },
    created() {
        this.getDepositos();
    },
    methods: {
        async getDepositos() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Bancos';
            try {
                let fechaInicial = moment(this.fechaI).format('YYYY-MM-DD')
                let fechafinal = moment(this.fechaF).format('YYYY-MM-DD')
                let response = await axios.get("Bancos/getDepositos/" + this.empresa.empresaBase + '/Deposito/' + fechaInicial + '/' + fechafinal);
                this.$store.state.listMovimientos = response.data;
                // console.log(response)
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        nuevoMovimiento() {
            let nuevoMovimiento = {
                idMovimientosBanco: 0,
                referencia: '',
                fechaMovimiento: new Date(),
                monto: 0,
                notas: '',
                moneda: {},
                cliente: {},
                comprobantes: [],
                jsonBancosSat: {},
                tipoMovimiento: '',
                usuarioCreador: '',
            }
            if (this.$store.state.movimientosStore.idMovimientosBanco == -1) {
                this.$store.state.movimientosStore = { ...nuevoMovimiento }
            }
            this.dialogoNuevoDeposito = true;
        },

        editMovimiento(item) {
            // console.log(item)
            this.$store.state.movimientosStore = { ...item };
            this.dialogoNuevoDeposito = true;
        },

        openDialogDelete(item) {
            this.editedIndexMovimiento = this.itemsMovimientos.indexOf(item);
            this.editedMovimiento = { ...item };
            this.dialogDelete = true;
        },

        openDialogComprobantesMov(item) {
            this.$store.state.listComprobantesMov = { ...item };
            this.dialogoComprobantesMov = true;
        },

        async confirmDelete() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            try {
                this.editedMovimiento.usuarioModificador = this.empresa.nombre;
                let response = await axios.put(
                    "Bancos/PutMovimientoEstatus/" + this.empresa.empresaBase,
                    this.editedMovimiento
                );
                this.itemsMovimientos.splice(this.editedIndexMovimiento, 1);
                this.dialogDelete = false;
                this.loading = false;
                this.$toast.add({
                    severity: "success",
                    detail: "Registro eliminado con éxito.",
                    life: 3000,
                });
            } catch (error) {
                // console.log(error)
                this.$toast.add({
                    severity: "error",
                    detail: "Error al eliminar, intente nuevamente.",
                    life: 3000,
                });
                this.loading = false;
            }
        },

        closeDialogMovimiento() {
            this.$store.state.movimientosStore.idMovimientosBanco = -1
            this.dialogoNuevoDeposito = false;
        },

        closeDialogComprobantesMov() {
            // this.$store.state.movimientosStore.idMovimientosBanco = -1
            this.dialogoComprobantesMov = false;
        },

        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

    },
    components: { Depositos, ComprobantesMov },
};
</script>