<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOGO PARA BUSCAR SUSTITUCIONA A CANCELAR -->
        <Dialog :visible.sync="dialogCFDIS" :closable="false" :containerStyle="{ width: '1200px' }" :modal="true">
            <Card>
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="dialogCFDIS = false" />
                        </div>
                        <div class="text-center field col-4">
                            Buscar CFDI´S
                        </div>
                    </div>
                </template>
                <template #content>
                    <!-- CAMPOS PARA BUSCAR -->
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.folio"
                                        @keydown.enter="getComprobantes(1)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantes(1)" />
                                    <label>Folio</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.rfc"
                                        @keydown.enter="getComprobantes(2)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantes(2)" />
                                    <label>RFC</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.nombre"
                                        @keydown.enter="getComprobantes(3)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantes(3)" />
                                    <label>Nombre</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.folioFiscal"
                                        @keydown.enter="getComprobantes(4)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantes(4)" />
                                    <label>Folio Fiscal</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <DataTable :value="itemsBuscados" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                        class="mt-3">
                        <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'min-width': '10rem' }"
                            frozen>
                            <template #body="slotProps">
                                <Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2"
                                    @click="pushSustituto(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="serie" header="Serie"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80', 'font-size': '90%' }">
                        </Column>
                        <Column field="folio" header="Folio"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%' }">
                        </Column>
                        <Column field="fecha" header="Fecha"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                        <Column field="rfc" header="RFC"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        </Column>
                        <Column field="nombre" header="Nombre"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                        <Column field="subTotal" header="SubTotal"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.subTotal) }}</span>
                            </template>
                        </Column>
                        <Column field="total" header="Total"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.total) }}</span>
                            </template>
                        </Column>
                        <Column field="uuid" header="FolioFiscal"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                    </DataTable>
                </template>
            </Card>
        </Dialog>
        <Card>
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button @click="cerrarVentanaCancelacion()" icon="pi pi-times"
                            class="p-button-rounded p-button-danger p-button-outlined" />
                    </div>
                    <div class="text-center field col-4">
                        Solicitud de Cancelación CFDI
                    </div>
                    <div class="text-right field col-4">
                        <Button icon="pi pi-send" class="p-button-rounded p-button-success p-button-outlined"
                            @click="cancelarUUID()" />
                    </div>
                </div>
            </template>
            <template #content>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <div class="field col-12 md:col-2">
                        <span class="p-float-label">
                            <InputText readonly v-model="item.serie" type="text" />
                            <label>Serie</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-2">
                        <span class="p-float-label">
                            <InputText readonly v-model="item.folio" type="text" />
                            <label>Folio</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText readonly v-model="item.folioFiscal" type="text" />
                            <label>Folio Fiscal - UUID</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputNumber v-model="item.total" disabled mode="decimal" locale="en-US"
                                :minFractionDigits="2" />
                            <label>Total</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText readonly v-model="item.cliente.rfc" type="text" />
                            <label>RFC</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText readonly v-model="item.cliente.nombre" type="text" />
                            <label>Nombre</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="motivoCancelacion"
                                :options="itemsMotivosCancelacion" optionLabel="motivoCancelacion" />
                            <label>Motivo de Cancelación</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-12"
                        v-if="motivoCancelacion.motivoCancelacion == '01 | Comprobante emitido con errores con relación.'">
                        <DataTable :value="uuidSustituto" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                            class="mt-3">
                            <template #header>
                                <div class="table-header">
                                    CFDI a Sutituir
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        @click="dialogCFDIS = true" />
                                </div>
                            </template>
                            <Column header="Acciones"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'min-width': '10rem' }" frozen>
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                                        @click="deleteCFDI(slotProps.data)" />
                                </template>
                            </Column>
                            <Column field="serie" header="Serie"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                            <Column field="folio" header="Folio"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                            <Column field="fecha" header="Fecha"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                            <Column field="rfc" header="RFC"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                            <Column field="nombre" header="Nombre"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                            <Column field="subTotal" header="SubTotal"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.subTotal) }}</span>
                                </template>
                            </Column>
                            <Column field="total" header="Total"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.total) }}</span>
                                </template>
                            </Column>
                            <Column field="uuid" header="FolioFiscal"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    data() {
        return {
            loadingMensaje: "",
            loading: false,

            dialogCFDIS: false,
            itemsBuscados: [],
            busqueda: {
                folio: '',
                rfc: '',
                nombre: '',
                folioFiscal: '',
            },

            uuidSustituto: [],
            motivoCancelacion: {}
        }
    },
    computed: {
        item() {
            return this.$store.state.cancelacionStore
        },
        itemsMotivosCancelacion() {
            return this.$store.state.listMotivosCancelacionStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        itemsFacturas() {
            return this.$store.state.listFacturaStore;
        },
    },
    created() {
        this.GetMotivoCancelacion();
    },
    methods: {
        async GetMotivoCancelacion() {
            try {
                let response = await axios.get('CatalogosSat/GetMotivoCancelacion')
                this.$store.state.listMotivosCancelacionStore = response.data
                console.log(response.data)
            } catch (err) {
                console.log(err)
            }
        },

        pushSustituto(item) {
            console.log(item)
            this.uuidSustituto = []
            this.uuidSustituto.push(item);

            this.dialogCFDIS = false
        },

        deleteCFDI() {
            this.uuidSustituto = []

        },
        async cancelarUUID() {
            console.log('factura', this.$store.state.cancelacionStore)
            let motivo = this.motivoCancelacion.clave
            if (motivo == "" || !motivo) {
                this.$toast.add({ severity: 'info', detail: 'Indique un Motivo de Cancelación.', life: 5000 });
                return;
            }

            this.loadingMensaje = 'Enviando solicitud de Cancelación.'
            this.loading = true

            let folioFiscal = ''

            if (this.motivoCancelacion.clave == '01') {
                folioFiscal = this.uuidSustituto[0].uuid;
                console.log(this.uuidSustituto[0].uuid)

            }

            let datosCancelacion = {
                idComprobante: this.$store.state.cancelacionStore.idComprobante,
                rfc: this.$store.state.cancelacionStore.empresa.rfc,
                folioFiscal: this.$store.state.cancelacionStore.folioFiscal,
                motivoCancelacion: this.motivoCancelacion,
                uuidSustituir: folioFiscal,
                total: this.$store.state.cancelacionStore.total,
                usuarioModificador: this.empresa.nombre
            }
            console.log(datosCancelacion)
            try {
                let response = await axios.post('Comprobantes/PostCancelacionCFDI/' + this.empresa.empresaBase + '/', datosCancelacion)
                console.log(response)
                await this.actualizaLista(response.data, this.$store.state.cancelacionStore)

                this.$toast.add({ severity: 'success', detail: 'Se envio correctamente la solicidud.', life: 5000 });
                this.loading = false;
                this.cerrarVentanaCancelacion();
            } catch (err) {
                console.log(err.response.data)
                this.$toast.add({ severity: 'error', detail: err.response.data, life: 5000 });
                this.loading = false;
            }
        },

        async getComprobantes(tabla) {
            console.log(this.empresa)
            this.loading = true;
            this.loadingMensaje = 'Buscando comprobantes...'
            let ta = '';
            let dato = '';
            if (tabla == 1) {
                ta = 'folio'
                dato = this.busqueda.folio;
            }
            else if (tabla == 2) {
                ta = 'rfc'
                dato = this.busqueda.rfc;
            }
            else if (tabla == 3) {
                ta = 'nombre'
                dato = this.busqueda.nombre;
            }
            else if (tabla == 4) {
                ta = 'folioFiscal'
                dato = this.busqueda.folioFiscal
            }

            try {
                let response = await axios.get("Comprobantes/GetComprobantesR/" + this.empresa.empresaBase + '/' + ta + '/' + dato);
                console.log(response);
                this.itemsBuscados = response.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log(error)
            }
        },

        actualizaLista(model, comprobante) {
            console.log('model', model)
            console.log('comprobante', comprobante)
            comprobante.estatus = model.estatus
            comprobante.estatusCancelacion = model.estatusCancelacion

            if (comprobante.tipoComprobanteInterno == 'IngresoCP') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listIngresoCartaPorteStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.$store.state.listIngresoCartaPorteStore[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'TrasladoCP') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listTrasladoCartaPorteStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.$store.state.listTrasladoCartaPorteStore[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'Factura') {
                // BUSCAMOS EL INDICE
                let indice = this.itemsFacturas.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.itemsFacturas[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'Complemento Pago') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listComplementoPagoStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.$store.state.listComplementoPagoStore[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'Arrendamiento') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listArrendamientoStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.$store.state.listArrendamientoStore[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'CE') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listComercioExteriorStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                Object.assign(this.$store.state.listComercioExteriorStore[indice], comprobante)
            }
            else if (comprobante.tipoComprobanteInterno == 'FacturaGas') {
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listVentasGasStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal);
                console.log(indice)
                this.$store.state.listVentasGasStore[indice].estatus = 'Validar estatus'
                // Object.assign(this.$store.state.listVentasGasStore[indice], comprobante)
            }
        },
        cerrarVentanaCancelacion() {
            this.$emit('cerrarVentanaCancelacion')
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>