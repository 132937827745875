<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <!-- DIALOG DEL CORREO -->
        <Dialog :visible.sync="dialogCorreo" :style="{ width: '1000px' }" :closable="false" :modal="false">
            <Card>
                <template #header>
                    <div class="flex justify-content-between flex-wrap pb-2">
                        <Button @click="closeDialogCorreo"> Cerrar</Button>
                        Enviar comprobante
                        <Button @click="enviaCorreo"> Enviar</Button>
                    </div>
                </template>
                <template #content>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <InputText v-model="correo.asunto" type="text" autofocus tabindex="1" />
                                <label>Asunto</label>
                            </span>
                        </div>
                    </div>
                    <InlineMessage severity="info">Para agregar un DESTINATARIO primero agregue un NOMBRE y un
                        CORREO</InlineMessage>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-6">
                            <span class="p-float-label">
                                <InputText v-model="nuevoDestinatario.nombre" type="text" ref="nombreF" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <div class="field col-6">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText v-model="nuevoDestinatario.correo" type="text"
                                        @keydown.enter="pushCorreo()" />
                                    <label>Correo</label>
                                </span>
                                <Button icon="pi pi-plus" class="p-button-success" @click="pushCorreo" />
                            </div>
                        </div>
                    </div>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <Chips v-model="destinatarios" :allowDuplicate="false">
                                    <template #chip="slotProps">
                                        <div>
                                            <span style="font-size: 12px">{{ slotProps.value.nombre }} - {{
                                                slotProps.value.correo
                                            }}</span>
                                        </div>
                                    </template>
                                </Chips>
                                <label>Destinatarios</label>
                            </span>
                        </div>
                    </div>
                    <Editor v-model="correo.mensaje" editorStyle="height: 200px" />
                </template>
            </Card>
        </Dialog>
        <div>
            <Button class="mr-1" @click="closeDialogPdf"> Cerrar</Button>
            <Button class="mr-1" @click="descargaComprobanteXml"> Descargar XML</Button>
            <Button class="mr-1" @click="descargaComprobante"> Descargar PDF</Button>
            <Button class="mr-1" @click="openDialogCorreo"> Enviar</Button>
        </div>

        <div v-html="comprobante"></div>
    </div>
</template>
  
<script>
import axios from 'axios'
export default {
    data() {
        return {
            dialogCorreo: false,
            loadingMensaje: '',
            loading: false,
            destinatarios: [],
            nuevoDestinatario: {
                nombre: '',
                correo: ''
            },
        };
    },

    computed: {
        fileName() {
            return this.$store.state.comprobanteStore.nombre;
        },
        comprobante() {
            return `<object type="application/pdf" width="100%" height="600px" data="data:application/pdf;base64,${this.$store.state.comprobanteStore.base64}"></object>`;
        },
        correo() {
            return this.$store.state.correoStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },

    created() {
        this.getCorreo();
    },

    methods: {
        async getCorreo() {
            let ObjFacturado = this.$store.state.comprobanteStore.timbrado
            if (ObjFacturado === 'SI') {
                this.correo.asunto = 'Se envía factura correspondiente'
            } else {
                this.correo.asunto = 'Se envía documento'
            }
            this.loadingMensaje = 'Espere...'
            this.loading = true
            let idCliente = this.$store.state.comprobanteStore.idCliente
            try {
                let response = await axios.get('Correos/GetCorreoContacto/' + this.empresa.empresaBase + '/' + idCliente);
                let correos = response.data;
                for (let c of correos) {
                    this.destinatarios.push(c)
                }
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },

        pushCorreo() {
            if (this.nuevoDestinatario.correo === '') {
                this.$toast.add({ severity: 'error', detail: 'Error al agregar destinatario, indique un correo', life: 5000 });
                return
            }
            let nuevo = { ...this.nuevoDestinatario }
            this.destinatarios.push(nuevo)
            let inicializa = {
                nombre: '',
                correo: ''
            }
            this.nuevoDestinatario = { ...inicializa }
            this.$refs.nombreF.$el.focus();
        },

        openDialogCorreo() {
            this.correo.archivos = []

            this.correo.archivos.push()
            this.dialogCorreo = true;
        },

        closeDialogCorreo() {
            this.dialogCorreo = false;
        },

        async enviaCorreo() {
            this.loading = true;
            this.loadingMensaje = 'Enviando correo...'

            let correos = {
                asunto: this.correo.asunto,
                mensaje: this.correo.mensaje,
                emisor: {
                    nombre: '',
                    correo: '',
                    contra: '',
                    hostt: '',
                    puerto: 0,
                },
                receptores: [],
                archivos: [],
            }


            var xml_ = {
                base64: this.$store.state.comprobanteStore.archivoXml,
                nombre: this.$store.state.comprobanteStore.nombre + ".xml"
            }
            var pdf_ = {
                base64: this.$store.state.comprobanteStore.base64,
                nombre: this.$store.state.comprobanteStore.nombre + ".pdf"
            }

            correos.receptores = this.destinatarios;
            correos.archivos.push(xml_);
            correos.archivos.push(pdf_);

            try {
                let response = await axios.post('Correos/PostCorreos/' + this.empresa.empresaBase + '/' + this.empresa.idusuariosApp, correos)
                this.dialogCorreo = false;
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Correo enviado con éxito', life: 5000 });

            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al enviar:' + error.response.data, life: 5000 });
                console.log(error)
                this.loading = false;
            }
        },

        closeDialogPdf() {
            this.$emit('cierraDialogPdf')
        },

        async descargaComprobanteXml() {
            const xmlBase64 = this.$store.state.comprobanteStore.archivoXml
            const fileName = this.$store.state.comprobanteStore.nombre + ".xml";
            const link = document.createElement("a");
            link.href = "data:text/xml;base64," + xmlBase64;
            link.download = fileName;
            link.click();
        },

        async descargaComprobante() {
            const pdfBase64 = this.$store.state.comprobanteStore.base64;
            const fileName = this.$store.state.comprobanteStore.nombre + ".pdf";
            const link = document.createElement("a");
            link.href = "data:application/pdf;base64," + pdfBase64;
            link.download = fileName;
            link.click();
        },

    }
};
</script>