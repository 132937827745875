<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- CLAVE PROD SERV -->
            <template v-if="editedIndex === -1">
                <div class=" field col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoSelect" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" field="concepto" @item-select="asignaConcepto">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
            </template>
            <!-- NO DE INDENTIFICACION -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                    <label>No. Identificación</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <!-- <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.descripcion" />
                    <label>Descripción</label>
                </span>
            </div> -->
            <div class="field col-12">
                <span>Descripción</span>
                <Editor class="editor editor1" style="border:  1px solid black; font-size: 15px;"
                    v-model="concepto.descripcion">
                    <template hidden #toolbar>
                        <span style="font-size: 15px;" hidden class="ql-formats">
                        </span>
                    </template>
                </Editor>
            </div>
            <!-- MATERIAL -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.material" />
                    <label>Material</label>
                </span>
            </div>
            <!-- CANTIDAD -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <span class="p-float-label">
                    <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- UNIDAD -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.unidad" />
                    <label>Unidad</label>
                </span>
            </div>
            <!-- VALOR UNITARIO -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber v-model="concepto.valorUnitario" mode="decimal" locale="en-US"
                            :minFractionDigits="6" />
                        <label>Valor unitario</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                </div>
            </div>
            <!-- OBJETO DE IMPUESTOS -->
            <div class="justify-left field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp"
                        optionLabel="objImp" />
                    <label>Objeto de impuestos(SAT)</label>
                </span>
            </div>
            <template v-if="concepto.objImp.clave === '02'">
                <!-- TRASLADADOS -->
                <div class="field md:col-6 sm:col-12">
                    <h1>Traslados</h1>
                    <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                        :selection.sync="impuestos.traslados">
                        <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                        <Column field="traslado" header="Impuesto"></Column>
                        <Column field="tipoFactor" header="Tipo Factor"></Column>
                        <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                    </DataTable>
                </div>
                <!-- RETENIDOS -->
                <div class="field md:col-6 sm:col-12">
                    <h1>Retenciones</h1>
                    <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                        :selection.sync="impuestos.retenciones">
                        <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                        <Column field="retencion" header="Impuesto"></Column>
                        <Column field="tipoFactor" header="Tipo Factor"></Column>
                        <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                    </DataTable>
                </div>
            </template>
        </div>
        <!-- TABLA DE CONCEPTOS -->
        <DataTable :value="ventaRemision.conceptos" :scrollable="true" scrollHeight="500px" scrollDirection="both"
            editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table">
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }"
                frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="editConcepto(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="deleteConcepto(slotProps.data)" />
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="noIdentificacion" header="No. Indentificación"
                :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.noIdentificacion" autofocus />
                </template>
            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }">
                <!-- <template #editor="slotProps">
                    <InputText v-model="slotProps.data.descripcion" autofocus />
                </template> -->
                <template #body="{ data }">
                    <Editor readonly :value="data.descripcion" class="editor" style="font-size: 15px;">
                        <template hidden #toolbar>
                            <span hidden class="ql-formats">
                            </span>
                        </template>
                    </Editor>
                </template>
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                </template>
            </Column>
            <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="valorUnitario" header="Valor Unitario" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                </template>
            </Column>
            <Column field="importe" header="Importe" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
            <Column field="material" header="Material" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.material" autofocus />
                </template>
            </Column>
            <Column field="objImp" header="Obj Impuestos" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.objImp.objImp) }}</span>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import { mapState } from 'vuex'

export default {
    components: {

    },
    data() {
        return {
            blockedPanel: true,
            conceptoDefault: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            filteredConcepto: null,
            concepto: {
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },
            conceptoSelect: null,
            filtrosConceptos: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
                "claveProdServ.clave": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "noIdentificacion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "claveUnidad.clave": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "unidad": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "descripcion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            editedIndex: -1,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
        }
    },
    computed: {
        listaConceptos() {
            return this.$store.state.listaConceptosStore
        },
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        ventaRemision() {
            return this.$store.state.ventaRemisionStore;
        },
        objImp() {
            return this.$store.state.objImpStore
        },
    },
    created() {

    },
    methods: {
        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            data[field] = newValue;
            console.log(field)
        },

        async uploadImage(event) {
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.$store.state.conceptoStore.imagen.base64 = result;
            } catch (error) {
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.descripcion.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        asignaConcepto() {
            this.concepto = { ...this.conceptoSelect }
            this.impuestos = { ...this.conceptoSelect.impuestos }
        },

        pushConcepto() {
            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            if (this.editedIndex === -1) {
                this.ventaRemision.conceptos.push(objConcepto);

            } else {
                Object.assign(this.ventaRemision.conceptos[this.editedIndex], objConcepto)

            }

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.editedIndex = -1;
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
        },

        deleteConcepto(item) {
            let indice = this.ventaRemision.conceptos.indexOf(item);
            this.ventaRemision.conceptos.splice(indice, 1);
            this.calcularImpuestos();
            this.sumatoria();
        },

        editConcepto(item) {
            this.conceptoSelect = null;
            this.impuestos = { ...item.impuestos }

            this.editedIndex = this.ventaRemision.conceptos.indexOf(item);
            this.concepto = { ...item }

        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        calcularImpuestos() {
            this.$store.state.ventaRemisionStore.impuestos.traslados = [];
            this.$store.state.ventaRemisionStore.impuestos.retenciones = [];

            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.ventaRemision.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {

                for (let t of a.impuestos.traslados) {
                    // t.base_ = a.importe
                    // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                    sumaT = sumaT + t.importe
                    sumaT = Math.round(sumaT * 100) / 100
                    //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                    let im = { ...t }
                    listTrasladados.push(im);
                    im = {}
                }

                for (let r of a.impuestos.retenciones) {
                    // r.base_ = a.impo  rte
                    // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                    sumaR = sumaR + r.importe
                    sumaR = Math.round(sumaR * 100) / 100
                    //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                    let im = { ...r }
                    listRetenidos.push(im);
                    im = {}
                }


                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.ventaRemisionStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.ventaRemisionStore.impuestos.traslados.push(tra)
                }
                this.$store.state.ventaRemisionStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.ventaRemisionStore.impuestos.retenciones.push(ret)
                }
            }


        },

        sumatoria() {
            let indice = 1;
            for (let x of this.ventaRemision.conceptos) {
                x.item = indice;
                indice++;
            }
            let tras = Math.round(this.sum(this.$store.state.ventaRemisionStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.ventaRemisionStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.ventaRemision.subTotal = Math.round(this.sum(this.ventaRemision.conceptos, 'importe') * 100) / 100;
            this.ventaRemision.total = this.ventaRemision.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.tipo };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },
    },
}
</script>