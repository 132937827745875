<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- FECHA -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <Calendar v-model="pedido.fecha" dateFormat="dd-MM-yy" />
                    <label>Fecha</label>
                </span>
            </div>
            <div class="field col-9">
                <div class="p-inputgroup justify-center">
                    <span class="p-float-label">
                        <AutoComplete :forceSelection="true" style="text-align: left" v-model="pedido.solicitante"
                            :suggestions="filteredUsuario" @complete="searchUsuario($event)">
                        </AutoComplete>
                        <label>Solicitante</label>
                    </span>
                </div>
            </div>
            <!-- PROVEEDOR -->
            <!-- <div class=" field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="pedido.proveedor" :suggestions="filtroProveedor"
                            @complete="searchProveedor($event)" field="proveedor">
                        </AutoComplete>
                        <label>Proveedor</label>
                    </span>
                </div>
            </div> -->
            <!-- PROYECTO -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown v-model="pedido.proyecto" optionLabel="descripcion" :options="itemsProyectos" />
                    <label>Proyecto</label>
                </span>
            </div>
            <!-- NOTAS -->
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <InputText v-model="pedido.notas" type="text" />
                    <label>Notas</label>
                </span>
            </div>

        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },

    data() {
        return {
            filtroProveedor: null,
            filteredUsuario: null,
            listaUsuarios: []
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        pedido() {
            return this.$store.state.comprasPedidoStore
        },
        itemsSerie() {
            return this.$store.state.listComprasPedidoSerieStore;
        },
        proveedores() {
            return this.$store.state.listaProveedorStore;
        },
        itemsProyectos() {
            return this.$store.state.listProyectosStore.filter(x => x.estatus == 'Vigente');;
        },
    },
    created() {
        this.getUsuarios()
    },
    methods: {
        searchProveedor(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroProveedor = [...this.proveedores];
                }
                else {
                    this.filtroProveedor = this.proveedores.filter((country) => {
                        return country.proveedor.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        searchUsuario(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUsuario = [...this.listaUsuarios];
                }
                else {
                    this.filteredUsuario = this.listaUsuarios.filter((country) => {
                        return country.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        async getUsuarios() {
            try {
                let response = await axios.get('Usuarios/GetUsuariosCompras/' + this.token.empresaBase)
                this.listaUsuarios = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>