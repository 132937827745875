<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- CARGAR EXCEL PRECIOS -->
        <Dialog :visible.sync="dialogExcel" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraExcelPrecios()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR</h3>
                </div>
            </div>
            <hr size=7 color="#ED6421">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarExcelPrecios" accept=".xlsx"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>

        </Dialog>

        <DataTable ref="dt" :paginator="true" class="p-datatable-customers" :rowHover="true" :value="articulos"
            responsiveLayout="scroll" :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <template #header>
                <div style="text-align: left">
                    <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                </div>
                <div class="table-header">
                    Articulos
                    <Button label="Guardar Lista" icon="pi pi-save" @click="postArticulos()" />
                    <Button label="Abrir Dialogo" icon="pi pi-plus" @click="abrirDialog()" />
                    <Button label="Consultar" icon="pi pi-search" @click="GetArticulos()" />
                    <Button label="Asigna" icon="pi pi-search" @click="Asigna()" />
                </div>
            </template>
            <Column header="Codigo" field="codigo"></Column>
            <Column header="Descripcion" field="descripcion"></Column>
            <Column header="ClaveProdServicio" field="claveProdServicio"></Column>
            <Column header="ClaveUnidad" field="claveUnidad"></Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import readXlsFile from 'read-excel-file'
import * as XLSX from 'xlsx/xlsx.js'

export default {
    components: {
    },
    data() {
        return {
            articulos: [],
            loading: false,
            loadingMensaje: '',
            dialogExcel: false,
            litsaNueva: []
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        usuario() {
            return this.$store.state.usuario;
        },
    },
    created() {

    },
    methods: {
        async postArticulos() {
            this.loading = true;
            this.loadingMensaje = 'Guardando, espere...'

            try {
                let response = await axios.post('Ventas/PostArticulosParadox/' + this.usuario.empresaBase)
                console.log(response.data)
                this.loading = false;


            } catch (error) {
                console.log(error)
                this.loading = false;

            }
        },
        async GetArticulos() {
            this.loading = true;
            this.articulos = []
            try {
                let response = await axios.get('Ventas/GetArticulos/' + this.usuario.empresaBase)
                console.log(response.data)
                this.articulos = response.data
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },
        cargarExcelPrecios(event) {
            const file = event.files[0];
            console.log(file)
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                this.litsaNueva = []
                for (var cont = 1; cont < num; cont++) {
                    try {
                        let objeto = {
                            codigo: rows[cont]['0'],
                            descripcion: rows[cont]['1'],
                            claveProdServicio: rows[cont]['2'],
                            claveUnidad: rows[cont]['3']
                        }

                        this.litsaNueva.push(objeto)
                    } catch (error) {
                        console.log(error)
                    }
                }
                console.log(this.litsaNueva)
            });
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        abrirDialog() {
            this.dialogExcel = true
        },
        async postArticulos() {
            this.loading = true;
            this.loadingMensaje = 'Guardando, espere...'
            try {
                let response = await axios.post('Ventas/PostArticulos/' + this.usuario.empresaBase, this.litsaNueva)
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },
        async Asigna() {
            this.loading = true;
            this.loadingMensaje = 'Guardando, espere...'
            try {
                let response = await axios.post('Ventas/AsignaClaves/' + this.usuario.empresaBase, this.litsaNueva)
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },
    },
}
</script>

