<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Cliente
                    <template v-if="cliente.idCliente === 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                            @click="postCliente()" />
                    </template>
                    <template v-if="cliente.idCliente != 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                            @click="putCliente()" />
                    </template>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" type="text" v-model="cliente.nombre" />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- RFC -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="rfc" type="text" v-model="cliente.rfc" maxlength="13" />
                                <label for="rfc">RFC</label>
                            </span>
                        </span>
                    </div>
                    <!-- DOMICILIO FISCAL -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="domicilioFiscal" type="text" v-model="cliente.domicilioFiscal" maxlength="6"
                                    onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;" />
                                <label for="domicilioFiscal">Domicilio Fiscal</label>
                            </span>
                        </span>
                    </div>
                    <!-- numRegIdTrib -->
                    <div class="justify-left field col-6" v-if="cliente.rfc == 'XEXX010101000'">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="numRegIdTrib" type="text" v-model="cliente.numRegIdTrib" />
                                <label for="numRegIdTrib">Num Reg Id Trib</label>
                            </span>
                        </span>
                    </div>
                    <!-- PAIS -->
                    <div class="justify-left field col-6" v-if="cliente.rfc == 'XEXX010101000'">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="cliente.residenciaFiscal"
                                :options="ResidenciaFiscal" optionLabel="pais" />
                            <label>Residencia Fiscal</label>
                        </span>
                    </div>
                    <!-- REGIMEN FISCAL -->
                    <div class="justify-left field col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="cliente.regimenFiscal" :options="RegimenFiscal"
                                optionLabel="regimenFiscal" />
                            <label>Régimen Fiscal</label>
                        </span>
                    </div>
                    <!-- USO CFDI -->
                    <div class="justify-left field col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="cliente.usoCfdi" :options="UsoCfdi"
                                optionLabel="usoCfdi" />
                            <label>Uso de CFDI</label>
                        </span>
                    </div>
                    <!-- DIRECCION -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="direccion" type="text" v-model="cliente.direccion" />
                                <label for="direccion">Dirección</label>
                            </span>
                        </span>
                    </div>
                    <!-- LISTA DE CORREOS -->
                    <div class="p-fluid grid field col-12">
                        <div class="justify-right field col-12">
                            <h3>Correos</h3>
                            <hr size=7 color="#ED6421">
                        </div>
                        <!-- NOMBRE DEL CORREO -->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="nombreCorreo" style="text-align: left" type="text" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <!-- CORREO -->
                        <div class="justify-right field col-6">
                            <div class="p-inputgroup">
                                <InputText v-model="correo" placeholder="Correo" @keydown.enter="pushCorreo()" />
                                <Button icon="pi pi-plus" @click="pushCorreo()" class="p-button-warning" />
                            </div>
                        </div>
                        <!-- TABLA DE CORREOS -->
                        <div class="justify-right field col-12">
                            <DataTable :value="cliente.correos" responsiveLayout="scroll">
                                <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" :exportable="false"
                                    :styles="{ 'min-width': '1rem' }">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" style="width:35px; height:35px"
                                            class="p-button-rounded p-button-danger"
                                            @click="eliminarCorreo(slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="nombre" header="Nombre"></Column>
                                <Column field="correo" header="Correo"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios';
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",
            correo: '',
            nombreCorreo: '',
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },

        cliente() {
            return this.$store.state.clienteStore;
        },

        RegimenFiscal() {
            let filtro = []
            let rfc = this.$store.state.clienteStore.rfc
            let rfcL = this.$store.state.clienteStore.rfc.length
            switch (rfcL) {
                case 12:
                    return filtro = this.$store.state.regimenFiscalStore.filter(regimen => regimen.moral === 'Sí');
                    break;
                case 13:
                    if (rfc == "XAXX010101000" || rfc == "XEXX010101000") {
                        return filtro = this.$store.state.regimenFiscalStore.filter(regimen => regimen.descripcion === 'Sin obligaciones fiscales');
                    } else {
                        return filtro = this.$store.state.regimenFiscalStore.filter(regimen => regimen.fisica === 'Sí');
                    }
                    break;
            }
            return filtro;
        },

        UsoCfdi() {
            let filtro = []
            let rfc = this.$store.state.clienteStore.rfc
            let rfcL = this.$store.state.clienteStore.rfc.length
            switch (rfcL) {
                case 12:
                    let lista = this.$store.state.usoCfdiStore.filter(regimen => regimen.fisica === 'Sí');

                    if (this.cliente.regimenFiscal.clave === '601' || this.cliente.regimenFiscal.clave === '603' || this.cliente.regimenFiscal.clave === '620' || this.cliente.regimenFiscal.clave === '622' || this.cliente.regimenFiscal.clave === '623' || this.cliente.regimenFiscal.clave === '624' || this.cliente.regimenFiscal.clave === '626') {
                        let a1 = lista.find(regimen => regimen.clave === 'G01')
                        let a2 = lista.find(regimen => regimen.clave === 'G02')
                        let a3 = lista.find(regimen => regimen.clave === 'G03')
                        let a4 = lista.find(regimen => regimen.clave === 'I01')
                        let a5 = lista.find(regimen => regimen.clave === 'I02')
                        let a6 = lista.find(regimen => regimen.clave === 'I03')
                        let a7 = lista.find(regimen => regimen.clave === 'I04')
                        let a8 = lista.find(regimen => regimen.clave === 'I05')
                        let a9 = lista.find(regimen => regimen.clave === 'I06')
                        let a10 = lista.find(regimen => regimen.clave === 'I07')
                        let a11 = lista.find(regimen => regimen.clave === 'I08')
                        let a12 = lista.find(regimen => regimen.clave === 'S01')
                        let a13 = lista.find(regimen => regimen.clave === 'CP01')
                        filtro = []
                        filtro.push(a1)
                        filtro.push(a2)
                        filtro.push(a3)
                        filtro.push(a4)
                        filtro.push(a5)
                        filtro.push(a6)
                        filtro.push(a7)
                        filtro.push(a8)
                        filtro.push(a9)
                        filtro.push(a10)
                        filtro.push(a11)
                        filtro.push(a12)
                        filtro.push(a13)
                        return filtro
                    } else {
                        return filtro = this.$store.state.usoCfdiStore.filter(regimen => regimen.moral === 'Sí');

                    }
                    break;
                case 13:
                    if (rfc == "XAXX010101000" || rfc == "XEXX010101000") {
                        return filtro = this.$store.state.usoCfdiStore.filter(regimen => regimen.clave === 'S01');
                    } else {
                        console.log(this.cliente.regimenFiscal.clave)
                        let lista = this.$store.state.usoCfdiStore.filter(regimen => regimen.fisica === 'Sí');

                        if (this.cliente.regimenFiscal.clave === '605') {
                            let a1 = lista.find(regimen => regimen.clave === 'D01')
                            let a2 = lista.find(regimen => regimen.clave === 'D02')
                            let a3 = lista.find(regimen => regimen.clave === 'D03')
                            let a4 = lista.find(regimen => regimen.clave === 'D04')
                            let a5 = lista.find(regimen => regimen.clave === 'D05')
                            let a6 = lista.find(regimen => regimen.clave === 'D06')
                            let a7 = lista.find(regimen => regimen.clave === 'D07')
                            let a8 = lista.find(regimen => regimen.clave === 'D08')
                            let a9 = lista.find(regimen => regimen.clave === 'D09')
                            let a10 = lista.find(regimen => regimen.clave === 'D10')
                            let a11 = lista.find(regimen => regimen.clave === 'S01')
                            let a12 = lista.find(regimen => regimen.clave === 'CP01')
                            let a13 = lista.find(regimen => regimen.clave === 'CN01')
                            filtro = []
                            filtro.push(a1)
                            filtro.push(a2)
                            filtro.push(a3)
                            filtro.push(a4)
                            filtro.push(a5)
                            filtro.push(a6)
                            filtro.push(a7)
                            filtro.push(a8)
                            filtro.push(a9)
                            filtro.push(a10)
                            filtro.push(a11)
                            filtro.push(a12)
                            filtro.push(a13)
                            return filtro
                        } else if (this.cliente.regimenFiscal.clave === '606' || this.cliente.regimenFiscal.clave === '612' || this.cliente.regimenFiscal.clave === '625') {
                            let a1 = lista.find(regimen => regimen.clave === 'G01')
                            let a2 = lista.find(regimen => regimen.clave === 'G02')
                            let a3 = lista.find(regimen => regimen.clave === 'G03')
                            let a4 = lista.find(regimen => regimen.clave === 'I01')
                            let a5 = lista.find(regimen => regimen.clave === 'I02')
                            let a6 = lista.find(regimen => regimen.clave === 'I03')
                            let a7 = lista.find(regimen => regimen.clave === 'I04')
                            let a8 = lista.find(regimen => regimen.clave === 'I05')
                            let a9 = lista.find(regimen => regimen.clave === 'I06')
                            let a10 = lista.find(regimen => regimen.clave === 'I07')
                            let a11 = lista.find(regimen => regimen.clave === 'I08')
                            let a12 = lista.find(regimen => regimen.clave === 'D01')
                            let a13 = lista.find(regimen => regimen.clave === 'D02')
                            let a14 = lista.find(regimen => regimen.clave === 'D03')
                            let a15 = lista.find(regimen => regimen.clave === 'D04')
                            let a16 = lista.find(regimen => regimen.clave === 'D05')
                            let a17 = lista.find(regimen => regimen.clave === 'D06')
                            let a18 = lista.find(regimen => regimen.clave === 'D07')
                            let a19 = lista.find(regimen => regimen.clave === 'D08')
                            let a20 = lista.find(regimen => regimen.clave === 'D09')
                            let a21 = lista.find(regimen => regimen.clave === 'D10')
                            let a22 = lista.find(regimen => regimen.clave === 'S01')
                            let a23 = lista.find(regimen => regimen.clave === 'CP01')

                            filtro = []

                            filtro.push(a1)
                            filtro.push(a2)
                            filtro.push(a3)
                            filtro.push(a4)
                            filtro.push(a5)
                            filtro.push(a6)
                            filtro.push(a7)
                            filtro.push(a8)
                            filtro.push(a9)
                            filtro.push(a10)
                            filtro.push(a11)
                            filtro.push(a12)
                            filtro.push(a13)
                            filtro.push(a14)
                            filtro.push(a15)
                            filtro.push(a16)
                            filtro.push(a17)
                            filtro.push(a18)
                            filtro.push(a19)
                            filtro.push(a20)
                            filtro.push(a21)
                            filtro.push(a22)
                            filtro.push(a23)
                            return filtro
                        } else if (this.cliente.regimenFiscal.clave === '607' || this.cliente.regimenFiscal.clave === '608' || this.cliente.regimenFiscal.clave === '611' || this.cliente.regimenFiscal.clave === '614' || this.cliente.regimenFiscal.clave === '615' || this.cliente.regimenFiscal.clave === '621') {
                            let a1 = lista.find(regimen => regimen.clave === 'D01')
                            let a2 = lista.find(regimen => regimen.clave === 'D02')
                            let a3 = lista.find(regimen => regimen.clave === 'D03')
                            let a4 = lista.find(regimen => regimen.clave === 'D04')
                            let a5 = lista.find(regimen => regimen.clave === 'D05')
                            let a6 = lista.find(regimen => regimen.clave === 'D06')
                            let a7 = lista.find(regimen => regimen.clave === 'D07')
                            let a8 = lista.find(regimen => regimen.clave === 'D08')
                            let a9 = lista.find(regimen => regimen.clave === 'D09')
                            let a10 = lista.find(regimen => regimen.clave === 'D10')
                            let a11 = lista.find(regimen => regimen.clave === 'S01')
                            let a12 = lista.find(regimen => regimen.clave === 'CP01')

                            filtro = []
                            filtro.push(a1)
                            filtro.push(a2)
                            filtro.push(a3)
                            filtro.push(a4)
                            filtro.push(a5)
                            filtro.push(a6)
                            filtro.push(a7)
                            filtro.push(a8)
                            filtro.push(a9)
                            filtro.push(a10)
                            filtro.push(a11)
                            filtro.push(a12)
                            return filtro
                        } else if (this.cliente.regimenFiscal.clave === '610' || this.cliente.regimenFiscal.clave === '616') {
                            let a1 = lista.find(regimen => regimen.clave === 'S01')
                            let a2 = lista.find(regimen => regimen.clave === 'CP01')
                            filtro = []
                            filtro.push(a1)
                            filtro.push(a2)
                            return filtro
                        } else {
                            return filtro = this.$store.state.usoCfdiStore.filter(regimen => regimen.fisica === 'Sí');

                        }

                    }
                    break;
            }
            return filtro;
        },

        ResidenciaFiscal() {
            return this.$store.state.residenciaFiscalStore
        },
    },

    created() {
        this.Inicializa();
    },
    mounted() {

    },
    methods: {
        //INICILIALIZA
        async Inicializa() {
            this.loading = true;
            this.loadingMensaje = 'Consultanod lista de Régimen...'
            await this.GetRegimenFiscal();
            this.loadingMensaje = 'Consultanod lista de Uso de CFDI...'
            await this.GetUsoCfdi();
            this.loadingMensaje = 'Consultanod lista de Residencia Fiscal...'
            await this.GetResidenciaFiscal();
            this.loading = false;
        },

        async GetRegimenFiscal() {
            if (this.$store.state.regimenFiscalStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetRegimenFiscal/");
                    this.$store.state.regimenFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetUsoCfdi() {
            if (this.$store.state.usoCfdiStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                    this.$store.state.usoCfdiStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetResidenciaFiscal() {
            if (this.$store.state.residenciaFiscalStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSatCartaPorte/GetPaises/");
                    this.$store.state.residenciaFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        //CORREO
        pushCorreo() {
            let objeto = {
                nombre: this.nombreCorreo,
                correo: this.correo
            }
            this.cliente.correos.push(objeto)

            this.nombreCorreo = ''
            this.correo = ''
        },

        eliminarCorreo(data) {
            let editedIndexCorreo = this.cliente.correos.indexOf(data)
            this.cliente.correos.splice(editedIndexCorreo, 1)
        },

        validaRegimen(val) {
            switch (val.length) {
                case 12:
                    return this.itemsRegimenFiscal = this.$store.state.listRegimenFiscalStore.filter(regimen => regimen.moral === 'Sí');
                    break;
                case 13:
                    if (val == "XAXX010101000" || val == "XEXX010101000") {
                        return this.itemsRegimenFiscal = this.$store.state.listRegimenFiscalStore.filter(regimen => regimen.descripcion === 'Sin obligaciones fiscales');
                    } else {
                        return this.itemsRegimenFiscal = this.$store.state.listRegimenFiscalStore.filter(regimen => regimen.fisica === 'Sí');
                    }
                    break;
                default:
                    this.$toast.add({ severity: 'error', summary: 'Error RFC no valido', detail: 'El RFC debe contener entre 12 y 13 caracteres.', life: 3000 });
                    break;
            }
        },

        //GUARDA O EDITA
        async postCliente() {
            let ObjCliente = { ...this.cliente }
            //PRIMERO VAMOS A VALIDAR
            if (!ObjCliente.rfc || ObjCliente.rfc === '') {
                this.$toast.add({ severity: 'warn', summary: 'RFC no válido', detail: 'El RFC debe contener entre 12 y 13 caracteres.', life: 5000 });
                return;
            }
            if (!ObjCliente.nombre || ObjCliente.nombre === '') {
                this.$toast.add({ severity: 'warn', summary: 'Nombre no válido', detail: 'Indique un nombre', life: 5000 });
                return;
            }

            if (!ObjCliente.domicilioFiscal || ObjCliente.domicilioFiscal === '') {
                this.$toast.add({ severity: 'warn', summary: 'Domicilio fiscal no válido', detail: 'Indique el domicilio fiscal, este es el código postal que aparece en su CIF', life: 5000 });
                return;
            }

            if (!ObjCliente.regimenFiscal.regimenFiscal || ObjCliente.regimenFiscal.regimenFiscal === '') {
                this.$toast.add({ severity: 'warn', summary: 'Régimen fiscal no válido', detail: 'Indique el Régimen fiscal', life: 5000 });
                return;
            }

            if (!ObjCliente.usoCfdi.usoCfdi || ObjCliente.usoCfdi.usoCfdi === '') {
                this.$toast.add({ severity: 'warn', summary: 'Uso de CFDI no válido', detail: 'Indique el uso de CFDI', life: 5000 });
                return;
            }

            this.loading = true;
            this.loadingMensaje = 'Guardando cliente, espere...'
            ObjCliente.usuarioCreador = this.empresa.nombre

            try {
                let response = await axios.post('Clientes/PostCliente/' + this.empresa.empresaBase, ObjCliente)
                ObjCliente.idCliente = response.data
                console.log(ObjCliente)
                this.loading = false;

                this.$store.state.listClienteStore.push(ObjCliente);
                this.$store.state.clienteStore.idCliente = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },

        async putCliente() {
            let ObjCliente = { ...this.cliente }
            //PRIMERO VAMOS A VALIDAR
            if (!ObjCliente.rfc || ObjCliente.rfc === '') {
                this.$toast.add({ severity: 'warn', summary: 'RFC no válido', detail: 'El RFC debe contener entre 12 y 13 caracteres.', life: 5000 });
                return;
            }
            if (!ObjCliente.nombre || ObjCliente.nombre === '') {
                this.$toast.add({ severity: 'warn', summary: 'Nombre no válido', detail: 'Indique un nombre', life: 5000 });
                return;
            }

            if (!ObjCliente.domicilioFiscal || ObjCliente.domicilioFiscal === '') {
                this.$toast.add({ severity: 'warn', summary: 'Domicilio fiscal no válido', detail: 'Indique el domicilio fiscal, este es el código postal que aparece en su CIF', life: 5000 });
                return;
            }

            if (!ObjCliente.regimenFiscal.regimenFiscal || ObjCliente.regimenFiscal.regimenFiscal === '') {
                this.$toast.add({ severity: 'warn', summary: 'Régimen fiscal no válido', detail: 'Indique el Régimen fiscal', life: 5000 });
                return;
            }

            if (!ObjCliente.usoCfdi.usoCfdi || ObjCliente.usoCfdi.usoCfdi === '') {
                this.$toast.add({ severity: 'warn', summary: 'Uso de CFDI no válido', detail: 'Indique el uso de CFDI', life: 5000 });
                return;
            }

            this.loading = true;
            this.loadingMensaje = 'Actualizando cliente, espere...'
            ObjCliente.usuarioModificador = this.empresa.nombre

            try {
                let response = await axios.put('Clientes/PutClientes/' + this.empresa.empresaBase, ObjCliente)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listClienteStore.findIndex(x => x.idCliente === ObjCliente.idCliente);
                Object.assign(this.$store.state.listClienteStore[indice], ObjCliente)
                this.$store.state.clienteStore.idCliente = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeCliente')
            this.$store.state.clienteStore.idCliente = -1
        },
    },
};
</script>
