<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Solicitante
                    <template v-if="solicitante.idSolicitante === 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                            @click="postSolicitante()" />
                    </template>
                    <template v-if="solicitante.idSolicitante != 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                            @click="putSolicitante()" />
                    </template>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" type="text" v-model="solicitante.nombre" maxlength="80" />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- CORREO ELECTRONICO -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="correoElectronico" type="text" v-model="solicitante.correo" maxlength="80" />
                                <label for="correoElectronico">Correo Electronico</label>
                            </span>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios';
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",
            correo: '',
            nombreCorreo: '',
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },

        solicitante() {
            return this.$store.state.solicitanteVwStore;
        },

    },

    created() {
    },
    mounted() {
    },
    methods: {
        //GUARDA O EDITA
        async postSolicitante() {
            let ObjSolicitante = { ...this.solicitante }

            this.loading = true;
            this.loadingMensaje = 'Guardando datos, espere...'
            ObjSolicitante.usuarioCreador = this.empresa.nombre

            try {
                let response = await axios.post('Addendas/PostSolicitanteVwPsv/' + this.empresa.empresaBase, ObjSolicitante)
                ObjSolicitante.idSolicitante = response.data
                console.log(ObjSolicitante)
                this.loading = false;

                this.$store.state.listSolicitanteVwStore.push(ObjSolicitante);
                this.$store.state.solicitanteVwStore.idSolicitante = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }

        },

        async putSolicitante() {
            let ObjSolicitante = { ...this.solicitante }

            this.loading = true;
            this.loadingMensaje = 'Actualizando datos, espere...'
            ObjSolicitante.usuarioModificador = this.empresa.nombre
            console.log(ObjSolicitante)
            try {
                let response = await axios.put('Addendas/PutSolicitanteVw/' + this.empresa.empresaBase, ObjSolicitante)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listSolicitanteVwStore.findIndex(x => x.idSolicitante === ObjSolicitante.idSolicitante);
                Object.assign(this.$store.state.listSolicitanteVwStore[indice], ObjSolicitante)
                this.$store.state.solicitanteVwStore.idSolicitante = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeSolicitante')
        },
    },
};
</script>