<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Remolque
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- TIPO DE REMOLQUE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="remolque.subTipoRem" :options="itemsRemolques" optionLabel="subTipoRem"
                                placeholder="Selecciona un tipo de Remolque." />
                            <label>Descripción</label>
                        </span>
                    </div>
                    <!-- PLACA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="remolque.placa" style="text-align: left" type="text" maxlength="7" />
                            <label>Placa</label>
                        </span>
                    </div>

                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

            itemsRemolques: [],
        }
    },
    computed: {
        remolque() {
            return this.$store.state.remolqueStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.searchsubTipoRem();
    },
    methods: {
        async searchsubTipoRem() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetSubTipoRem/')
                this.itemsRemolques = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        guardarOactualizar() {
            if (this.$store.state.remolqueStore.id == 0) {
                this.postRemolque();
            } else {
                this.updateRemolque();
            }
        },

        async postRemolque() {
            this.loading = true;
            this.loadingMensaje = 'Guardando remolque, espere...'
            this.remolque.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostRemolques/' + this.empresa.empresaBase, this.remolque)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Remolque guardado con exito.', life: 3000 });

                this.remolque.id = response.data
                this.$store.state.listRemolqueStore.push(this.remolque)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateRemolque() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando remolque, espere...'
            this.remolque.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateRemolques/' + this.empresa.empresaBase, this.remolque)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listRemolqueStore.findIndex(x => x.id === this.remolque.id);
                Object.assign(this.$store.state.listRemolqueStore[indice], this.remolque)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Remolque actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>