<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
        </Dialog>
        <Card class="shadow-none">
            <!-- TITULO CARD -->
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                    </div>
                    <div class="text-center field col-4">
                        <span @click="activaPrecio()">Comercio Exterior</span>
                    </div>
                    <div class="text-right field col-4">
                        <template v-if="comprobante.idComprobante === 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="postComprobante" v-tooltip.bottom="'Timbrar'" />
                            <template v-if="comprobante.remisiones.length == 0 && comprobante.protocolos.length == 0">
                                <Button icon="pi pi-save"
                                    class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                                    @click="postGuardaComprobante" v-tooltip.bottom="'Guardar'" />
                            </template>
                        </template>

                        <template v-if="comprobante.idComprobante != 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="putTimbrado" v-tooltip.bottom="'Timbrar Guardado'" />
                            <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                                @click="putComprobante" v-tooltip.bottom="'Guardar Editado'" />
                        </template>
                        <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="vistaPrevia" v-tooltip.bottom="'Vista Previa'" />
                    </div>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <!-- CONTENIDO DEL COMPROBANTE -->
            <template #content>
                <TabView>
                    <TabPanel header="Datos generales">
                        <General></General>
                    </TabPanel>
                    <TabPanel header="Conceptos">
                        <Concepto></Concepto>
                    </TabPanel>
                    <TabPanel header="Complemento">
                        <Complemento></Complemento>
                    </TabPanel>
                    <TabPanel header="CFDI'S Relacionados">
                        <Relacionados></Relacionados>
                    </TabPanel>
                </TabView>
            </template>
        </Card>
    </div>
</template>
<script>
import General from './ComercioExteriorGeneral.vue'
import Concepto from './ComercioExteriorConcepto.vue'
import Complemento from './ComercioExteriorComplemento.vue'
import Relacionados from './ComercioExteriorCfdiRelacionado.vue'
import ComprobantePdf from "../../Pdf/ComplementoComercioExteriorPdf.vue";
import axios from 'axios'
import moment from 'moment'

export default {
    components: {
        General,
        Concepto,
        Complemento,
        Relacionados,
        ComprobantePdf,
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogPdf: false,
            datosEmpresa: {},
        }
    },
    computed: {
        comprobante() {
            return this.$store.state.comercioExteriorStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.inicializa();
    },
    methods: {
        async inicializa() {
            this.loading = true;
            await this.getEmpresa();
            await this.getMotivoTraslado();
            await this.getTipoOperacion();
            await this.getClavePedimento();
            await this.getCertificadoOrigen();
            await this.getIncoterm();
            await this.getFraccionArancelaria();
            await this.getUnidadAduana();

            await this.getEmisores();
            await this.getPropietarios();
            await this.getReceptores();
            await this.getDestinatarios();
            await this.getMercancias();
            this.loading = false;
        },
        async getEmpresa() {
            try {
                this.loadingMensaje = 'Consultando datos de la empresa...'
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresa.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },

        //CATALOGOS DEL SAT
        async getMotivoTraslado() {
            if (this.$store.state.motivoTrasladoStore.length == 0) {
                this.loadingMensaje = 'Consultando motivos de traslado...'
                try {
                    let response = await axios.get('CatalogosSat/GetMotivoTraslado');
                    this.$store.state.motivoTrasladoStore = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getTipoOperacion() {
            if (this.$store.state.tipoOperacionStore.length == 0) {
                this.loadingMensaje = 'Consultando tipos de operación...'
                try {
                    let response = await axios.get('CatalogosSat/GetTipoOperacion');
                    this.$store.state.tipoOperacionStore = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getClavePedimento() {
            if (this.$store.state.claveDePedimentoStore.length == 0) {
                this.loadingMensaje = 'Consultando claves de pedimento...'
                try {
                    let response = await axios.get('CatalogosSat/GetClavePedimento');
                    this.$store.state.claveDePedimentoStore = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getCertificadoOrigen() {
            if (this.$store.state.certificadoOrigenStore.length == 0) {
                this.loadingMensaje = 'Consultando certificado de origen...'
                try {
                    let response = await axios.get('CatalogosSat/GetCertificadoOrigen');
                    this.$store.state.certificadoOrigenStore = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getIncoterm() {
            if (this.$store.state.incotermStore.length == 0) {
                this.loadingMensaje = 'Consultando incoterm...'
                try {
                    let response = await axios.get('CatalogosSat/GetIncoterm');
                    this.$store.state.incotermStore = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getFraccionArancelaria() {
            if (this.$store.state.listFraccionArancelariaStore.length == 0) {
                this.loadingMensaje = 'Consultando fracción arancelária...'
                try {
                    let response = await axios.get('CatalogosSatCartaPorte/GetFraccion/')
                    this.$store.state.listFraccionArancelariaStore = response.data
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getUnidadAduana() {
            if (this.$store.state.listUnidadAduanaStore.length == 0) {
                this.loadingMensaje = 'Consultando unidad aduana...'
                try {
                    let response = await axios.get('CatalogosSat/GetUnidadAduana/')
                    this.$store.state.listUnidadAduanaStore = response.data
                } catch (error) {
                    console.log(error)
                }
            }
        },

        //CATALOGOS INTERNOS
        async getEmisores() {
            this.loadingMensaje = 'Consultando emisores...'
            try {
                let response = await axios.get('ComercioExterior/GetEmisores/' + this.empresa.empresaBase);
                this.$store.state.listCEEmisorStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getPropietarios() {
            this.loadingMensaje = 'Consultando emisores...'
            try {
                let response = await axios.get('ComercioExterior/GetPropietarios/' + this.empresa.empresaBase);
                this.$store.state.listCEPropietarioStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getReceptores() {
            this.loadingMensaje = 'Consultando receptores...'
            try {
                let response = await axios.get('ComercioExterior/GetReceptores/' + this.empresa.empresaBase);
                this.$store.state.listCEReceptorStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getDestinatarios() {
            this.loadingMensaje = 'Consultando receptores...'
            try {
                let response = await axios.get('ComercioExterior/GetDestinatarios/' + this.empresa.empresaBase);
                this.$store.state.listCEDestinatarioStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async getMercancias() {
            this.loadingMensaje = 'Consultando mercancías...'
            try {
                let response = await axios.get('ComercioExterior/GetMercancias/' + this.empresa.empresaBase);
                this.$store.state.listCEMercanciaStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async postComprobante() {
            console.log(this.comprobante)
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.comprobante }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.comprobante.cliente.rfc
                comprobante.nombre = this.comprobante.cliente.nombre
                comprobante.fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.comercioExteriorStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.comercioExteriorStore.idComprobante = -1;
                this.$store.state.comercioExteriorStore.folio = response.data.folio
                this.$store.state.comercioExteriorStore.folioFiscal = response.data.folioFiscal
                this.$store.state.comercioExteriorStore.rfc = comprobante.cliente.rfc
                this.$store.state.comercioExteriorStore.nombre = comprobante.cliente.nombre
                this.$store.state.comercioExteriorStore.estatus = 'Vigente'

                this.$store.state.listComercioExteriorStore.push(this.$store.state.comercioExteriorStore);
                let array = this.$store.state.listComercioExteriorStore;
                this.$store.state.listComercioExteriorStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;
                // console.log(response.data)
                if (comprobante.remisiones.length != 0) {
                    for (var r of comprobante.remisiones) {
                        let fac = {
                            idVenta: r.id,
                            serie: comprobante.serie,
                            folio: response.data.folio,
                            fecha: comprobante.fecha,
                            rfc: comprobante.cliente.rfc,
                            nombre: comprobante.cliente.nombre,
                            moneda: comprobante.moneda,
                            subTotal: comprobante.subTotal,
                            total: comprobante.total,
                            folioFiscal: response.data.folioFiscal,
                            documento: 'Remisión',
                            tipoInterno: 'Factura',
                        }
                        let indice = this.$store.state.listVentaRemisionStore.findIndex(x => x.idVenta === r.id);
                        this.$store.state.listVentaRemisionStore[indice].estatus = 'Facturado';
                        this.$store.state.listVentaRemisionStore[indice].facturas++;
                        this.$store.state.listVentaRemisionStore[indice].totalFacturado += comprobante.total;
                        this.$store.state.listVentaRemisionStore[indice].comprobantes.push(fac);
                        fac = {};
                    }
                }
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async postGuardaComprobante() {
            // console.log(this.comprobante)
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.comprobante }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.comprobante.cliente.rfc
                comprobante.nombre = this.comprobante.cliente.nombre
                comprobante.fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostGuardaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.comercioExteriorStore.idComprobante = -1;
                this.$store.state.comercioExteriorStore.idComprobante = response.data;
                this.$store.state.comercioExteriorStore.rfc = comprobante.cliente.rfc
                this.$store.state.comercioExteriorStore.nombre = comprobante.cliente.nombre
                this.$store.state.comercioExteriorStore.folioFiscal = response.data;

                this.$store.state.listComercioExteriorStore.push(this.$store.state.comercioExteriorStore);
                let array = this.$store.state.listComercioExteriorStore;
                this.$store.state.listComercioExteriorStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putComprobante() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.comprobante }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.comprobante.cliente.rfc
                comprobante.nombre = this.comprobante.cliente.nombre
                comprobante.fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.put('Comprobantes/PutComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.comercioExteriorStore.rfc = comprobante.cliente.rfc
                this.$store.state.comercioExteriorStore.nombre = comprobante.cliente.nombre

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listComercioExteriorStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listComercioExteriorStore[indice] = comprobante
                console.log(indice)

                // this.$store.state.listComercioExteriorStore.push(this.$store.state.comercioExteriorStore);
                this.$store.state.comercioExteriorStore.idComprobante = -1;
                let array = this.$store.state.listComercioExteriorStore;
                this.$store.state.listComercioExteriorStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putTimbrado() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.comprobante }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.comprobante.cliente.rfc
                comprobante.nombre = this.comprobante.cliente.nombre
                comprobante.fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraEditaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.comercioExteriorStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.comercioExteriorStore.idComprobante = -1;
                this.$store.state.comercioExteriorStore.folio = response.data.folio
                this.$store.state.comercioExteriorStore.folioFiscal = response.data.folioFiscal
                this.$store.state.comercioExteriorStore.rfc = comprobante.cliente.rfc
                this.$store.state.comercioExteriorStore.nombre = comprobante.cliente.nombre
                this.$store.state.comercioExteriorStore.estatus = 'Vigente'

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listComercioExteriorStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listComercioExteriorStore[indice] = this.$store.state.comercioExteriorStore
                console.log(indice)

                let array = this.$store.state.listComercioExteriorStore;
                this.$store.state.listComercioExteriorStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.loading = false;
                this.dialogPdf = true;
                console.log(response.data)
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        vistaPrevia() {
            console.log(this.$store.state.comercioExteriorStore.timbreFiscalDigital)
            this.dialogPdf = true;
        },

        cierraVentana() {
            this.$store.state.comercioExteriorStore.idComprobante = -1;
            this.$emit('closeComprobante')
        },

        closeDialogPdf() {
            this.dialogPdf = false
            if (this.comprobante.timbreFiscalDigital.folioFiscal != '') {
                this.$emit('closeComprobante')
            }
        },

        //PARA ACTIVAR EL MODO PRECIO
        activaPrecio() {
            this.$store.state.modoImporteStore = false;
        },
    },
}
</script>