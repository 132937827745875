<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Nuevo Emisor
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>

            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="emisor.nombre" style="text-align: left" type="text" />
                            <label>NOMBRE</label>
                        </span>
                    </div>
                    <!-- CURP -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="emisor.curp" style="text-align: left" type="text" maxlength="18" />
                            <label>CURP</label>
                        </span>
                    </div>
                    <!-- DOMICILIO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="emisor.domicilio" :options="itemsDirecciones" optionLabel="domicilio">
                            </Dropdown>
                            <label>Domicilio</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
        }
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        emisor() {
            return this.$store.state.cEEmisorStore;
        },
        itemsDirecciones() {
            return this.$store.state.listDireccionStore;
        },
    },
    created() {
        this.getDirecciones();
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async getDirecciones() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetDirecciones/' + this.empresa.empresaBase)
                this.$store.state.listDireccionStore = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        guardarOactualizar() {
            if (this.$store.state.cEEmisorStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Emisor, espere...'
            this.emisor.usuarioCreador = this.empresa.nombre

            try {
                let response = await axios.post('ComercioExterior/PostEmisor/' + this.empresa.empresaBase, this.emisor)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Emisor guardado con exito.', life: 3000 });

                this.emisor.id = response.data
                this.$store.state.listCEEmisorStore.push(this.emisor)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Emisor, espere...'
            this.emisor.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('ComercioExterior/UpdateEmisor/' + this.empresa.empresaBase, this.emisor)
                // console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listCEEmisorStore.findIndex(x => x.id === this.emisor.id);
                Object.assign(this.$store.state.listCEEmisorStore[indice], this.emisor)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Emisor actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

    },
}
</script>