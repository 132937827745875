<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <Dialog :visible.sync="dialogComparacion" header="Comparar Cotización" :modal="true">
            <div>
                <hr size=7 color="#ED6421">

                <div class="text-center p-d-flex justify-center p-fluid grid formgrid">
                    <DataTable class="mt-1 p-datatable-sm mr-2" style="width: 300px;" v-for="tablas of listaComparacion"
                        :value="tablas.conceptos" v-bind:key="tablas.idCompra">
                        <template #header>
                            <div class="flex justify-content-between align-items-center">
                                <h5 class="m-0">{{ tablas.proveedor.rfc }}</h5>
                                <Button icon="pi pi-check" class="p-button-rounded p-button-outlined"
                                    @click="ordenarCotizacion(tablas)" />
                            </div>
                        </template>
                        <Column field="item" header="Item" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }">
                        </Column>
                        <Column field="descripcion" header="descripcion"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }">
                        </Column>
                        <Column field="valorUnitario" header="Valor Unitario"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'background-color': '#effaf1' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.valorUnitario) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </Dialog>

        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :showHeader="false" :style="{ width: '850px' }" :closable="false">
            <PdfCotizacion @cierraDialogPdf="closeDialogPdf"></PdfCotizacion>
        </Dialog>

        <!-- NUEVA VENTA COTIZAION -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogNuevoCotizacion" :closeOnEscape="true"
            :closable="false" :modal="false" :style="{ width: '95vw' }">
            <Cotizacion @closeCotizacion="dialogNuevoCotizacion = false"></Cotizacion>
        </Dialog>

        <!-- NUEVA VENTA ORDEN DE COMPRA -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogNuevoOrden" :closeOnEscape="true" :closable="false"
            :modal="false" :style="{ width: '95vw' }">
            <Orden @closeOrden="dialogNuevoOrden = false"></Orden>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar el Cotizacion
                    <b>{{ objetoEditado.idCompra }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarCotizacion()" />
            </template>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
                        <Button icon="mdi mdi-arrow-left-bold "
                            class="p-button-rounded p-button-secondary p-button-outlined mr-2 -mb-1" @click="irPedidos()" />
                        <Button icon="mdi mdi-arrow-right-bold"
                            class="p-button-rounded p-button-secondary p-button-outlined -mb-1" @click="irOrdenCompra()" />
                        Cotizaciones
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-plus" @click="nuevoCotizacion()" class="mx-2 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getCotizacion()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :value="itemsCotizaciones" :scrollable="true" scrollHeight="800px" scrollDirection="both"
                    class="mt-1 p-datatable-sm" :loading="loadingItemsCotizaciones" :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} Cotizaciones."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :paginator="true" :rows="10" contextMenu :contextMenuSelection.sync="selectCotizacion"
                    @row-contextmenu="onRowContextMenu">
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '90px', 'min-width': '12rem', }"
                        frozen>
                        <template #body="slotProps">
                            <template v-if="slotProps.data.estatus === 'Nuevo'">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    v-tooltip.bottom="'Eliminar'" @click="confirmDeleteProduct(slotProps.data)" />
                                <Button icon="mdi mdi-currency-usd" class="p-button-rounded p-button-success"
                                    v-tooltip.bottom="'Asignar Valores'" @click="editarCotizacion(slotProps.data)" />
                            </template>
                            <Button icon="pi pi-replay" class="p-button-rounded p-button-warning"
                                v-tooltip.bottom="'Similar'" @click="CotizacionSimilar(slotProps.data)" />
                            <Button icon="pi pi-file-pdf" style="background:#E65100" class="p-button-rounded"
                                v-tooltip.bottom="'PDF'" @click="openDialogPdf(slotProps.data)" />
                            <template v-if="slotProps.data.estatus === 'Cotizada'">
                                <Button icon="mdi mdi-select-compare" style="background:purple" class="p-button-rounded"
                                    v-tooltip.bottom="'Comparar'" @click="compararCotizaciones(slotProps.data)" />
                            </template>
                        </template>
                    </Column>
                    <!-- <Column field="idCompra" header="ID Compra" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable>
                    </Column> -->
                    <Column field="serie" header="Serie" :styles="{ 'width': '100px', }"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" sortable>
                    </Column>
                    <Column field="folio" header="Folio" :styles="{ 'width': '100px', }" sortable>
                        <template #body="slotProps">
                            <span v-tooltip.right="slotProps.data.estatus"
                                :class="'customer-badge status-' + slotProps.data.estatus">{{
                                    slotProps.data.folio }}</span>
                        </template>
                    </Column>
                    <Column field="solicitante" header="Solicitante" :styles="{ 'width': '300px' }" sortable>
                    </Column>
                    <Column field="rfc" header="RFC Proveedor" :styles="{ 'width': '200px', }" sortable>
                    </Column>
                    <Column field="nombre" header="Nombre Proveedor" :styles="{ 'width': '400px' }" sortable>
                    </Column>
                    <Column field="proyecto.descripcion" header="Proyecto" :styles="{ 'width': '300px' }" sortable>
                    </Column>
                    <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'width': '120px' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fecha) }}
                        </template>
                    </Column>
                    <Column field="tiempoEntrega" header="Tiempo de Entrega" :styles="{ 'width': '150px' }" sortable>
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '150px', 'background-color': '#2196f326', 'justify-content': 'center' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total" :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '150px', 'background-color': '#effaf1', 'justify-content': 'center' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <!-- <Column field="estatus" header="Estatus" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable> -->
                    <!-- </Column> -->
                    <Column field="folioR" header="Pedido Folio" :styles="{ 'width': '120px', 'justify-content': 'center' }"
                        sortable>
                    </Column>
                    <template #footer>
                        <div class="flex align-items-center">
                            <Chip label="Nuevo" class="mr-2 status-Nuevo " />
                            <Chip label="Cotizado" class="mr-2 status-Cotizada" />
                        </div>
                    </template>
                </DataTable>
            </template>

        </Card>
        <ContextMenu :model="menuModel" ref="cotizacion" />

    </div>
</template>
<script>
import moment from "moment";
import axios from 'axios'
import Cotizacion from "./Cotizacion.vue";
import PdfCotizacion from "../../Pdf/CompraCotizacionPdf.vue";
import Orden from "../OrdenCompras/OrdenCompra.vue";

export default {
    components: {
        Cotizacion, PdfCotizacion, Orden
    },

    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            dialogNuevoCotizacion: false,
            loadingItemsCotizaciones: false,

            objetoEditado: {},
            deleteProductDialog: false,
            editedIndex: -1,

            loadingMensaje: "",
            loading: false,

            dialogPdf: false,

            selectCotizacion: null,

            menuModel: [
                { label: 'Ordenar Compra', icon: 'pi pi-fw pi-file', command: () => this.ordenarCotizacion(this.selectCotizacion) },
            ],

            dialogNuevoOrden: false,

            listaComparacion: [],
            itemsComparacion: [],
            columnsComparacion: [],
            dialogComparacion: false,


        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsCotizaciones() {
            return this.$store.state.listaComprasCotizacionStore
        }
    },
    created() {
    },
    methods: {
        irPedidos() {
            this.$router.push({ name: "ListaPedidos" });
        },
        irOrdenCompra() {
            this.$router.push({ name: "ListaOrdenCompras" });
        },
        onRowContextMenu(event) {
            this.$refs.cotizacion.show(event.originalEvent);

        },
        ordenarCotizacion(item) {
            if (item.estatus == 'Nuevo') {
                this.$toast.add({ severity: "info", detail: "Antes de Ordenar la compra, debera asignar precios.", life: 3000, });
                return
            }
            let data = { ...item }
            data.serieR = data.serie
            data.folioR = data.folio

            data.fecha = new Date();
            data.fechaEntrega = new Date()
            data.serie = ''
            data.folio = ''
            data.estatus = 'Nuevo'
            data.idRelacion = data.idCompra
            data.idCompra = 0
            data.tipoComprobante = 'OrdenCompra'
            console.log(data)
            this.$store.state.comprasOrdenCompraStore = { ...data }
            this.dialogNuevoOrden = true
        },
        nuevoCotizacion() {
            let Cotizacion = this.$store.state.comprasCotizacionStore.idCompra
            if (Cotizacion == -1) {
                let obj = {
                    idCompra: 0,
                    serie: '',
                    folio: '',
                    fecha: new Date(),
                    tiempoEntrega: '',
                    tipoComprobante: 'Cotización',
                    empresa: {},
                    proveedor: {
                        idProveedor: 0,
                        rfc: '',
                        nombre: '',
                        nombreComercial: '',
                        correo: '',
                        telefono: '',
                        contacto: '',
                        direccion: '',
                        proveedor: ''
                    },
                    rfc: '',
                    nombre: '',
                    proyecto: {
                        descripcion: '',
                        presupuesto: 0
                    },
                    solicitante: '',
                    email: '',
                    notas: '',
                    estatus: 'Cotizada',
                    usuarioCreador: '',
                    usuarioModificador: '',
                    conceptos: [],
                    condicionesEntrega: '',
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    idRelacion: 0,
                    serieR: '',
                    folioR: '',
                    total: 0,
                    subTotal: 0
                }
                this.$store.state.comprasCotizacionStore = { ...obj };
            }
            this.dialogNuevoCotizacion = true;
        },
        CotizacionSimilar(item) {
            console.log(item)
            let similar = { ...item }
            similar.idCompra = 0
            similar.folio = ''
            similar.total = 0.00
            similar.subTotal = 0.00
            similar.idRelacion = 0
            similar.estatus = 'Cotizada'
            similar.fecha = new Date()
            similar.impuestos = {
                traslados: [],
                retenciones: [],
            }
            for (let obj of similar.conceptos) {
                obj.precio = 0.00
                obj.valorUnitario = 0.00
                obj.importe = 0.00
                obj.total = 0.00
                obj.objImp = {
                    objImp: ''
                }
                obj.impuestos = {
                    traslados: [],
                    retenciones: [],
                }
            }

            this.$store.state.comprasCotizacionStore = { ...similar }
            this.dialogNuevoCotizacion = true;
        },
        editarCotizacion(data) {
            let obj = { ...data }
            obj.estatus = 'Cotizada'
            this.$store.state.comprasCotizacionStore = { ...obj };
            this.dialogNuevoCotizacion = true;
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsCotizaciones.indexOf(data);
            this.objetoEditado = { ...data };
            this.deleteProductDialog = true;
        },
        openDialogPdf(data) {
            this.dialogPdf = true;
            console.log(data)
            this.$store.state.comprasCotizacionStore = { ...data };
        },
        async eliminarCotizacion() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            console.log(this.editedIndex)
            try {
                this.objetoEditado.usuarioModificador = this.token.nombre;
                this.objetoEditado.estatus = "Eliminado";

                let response = await axios.put("Compras/DeleteCompra/" + this.token.empresaBase, this.objetoEditado);
                this.$store.state.listaComprasCotizacionStore.splice(this.editedIndex, 1);

                this.deleteProductDialog = false;
                this.loading = false;

                this.$toast.add({ severity: "success", detail: "Cotización eliminada con éxito.", life: 3000, });
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: "error", detail: "Error al eliminar, intente nuevamente.", life: 3000, });
                this.loading = false;
            }
        },
        async getCotizacion() {
            try {
                this.loadingItemsCotizaciones = true;
                let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get("Compras/GetComprasCotizacion/" + this.token.empresaBase + "/" + fechaInicial + "/" + fechaFinal + "/Cotización");
                console.log(response.data)

                let array = response.data;
                this.$store.state.listaComprasCotizacionStore = array.sort(((a, b) => b.idCompra - a.idCompra))

                this.loadingItemsCotizaciones = false;
            } catch (error) {
                console.log(error);
                this.loadingItemsCotizaciones = false;
            }
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        compararCotizaciones(item) {
            this.listaComparacion = this.$store.state.listaComprasCotizacionStore.filter(x => x.folioR == item.folioR && x.estatus == 'Cotizada');
            this.columnsComparacion = []
            const columnasDefault = {
                field: "item",
                header: "Item",
                width: "50px",
            };
            const columnasDefault2 = {
                field: "concepto",
                header: "Concepto",
                width: "200px",
            };
            this.columnsComparacion.push(columnasDefault);
            this.columnsComparacion.push(columnasDefault2);

            for (let a of this.listaComparacion) {
                const newColumn = {
                    field: a.proveedor.rfc + "-ValorUnitario",
                    header: a.proveedor.rfc + " Valor Unitario ",
                    width: "200px",
                };
                this.columnsComparacion.push(newColumn);

            }


            this.dialogComparacion = true


            console.log(this.listaComparacion)
        }
    },
}
</script>
<style>
.status-Cotizada {
    background-color: #3e60bf;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-Entregado {
    background-color: #36a745;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}
</style>
