<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO BANCO -->
        <Dialog :visible.sync="dialogoNuevoBanco" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <Bancos @closeBanco="closeDialogBanco()"></Bancos>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOG PARA ELIMINAR BANCOS -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDelete = false" />
                Eliminar
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                    @click="confirmDelete()" />
            </div>
            <hr size="7" color="#ED6421" />
            <br />
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="editedBanco">Esta seguro de borrar el banco con el numero de cuenta
                    <b>{{ editedBanco.cuenta }}</b>?</span>
            </div>
        </Dialog>
        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Bancos
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoBanco()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getBancos()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosClientes" removableSort showGridlines ref="clientes"
                    :value="itemsBancosEmpresa" responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'cuenta',
                        'jsonBancosSat.banco'
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Datos."
                    :loading="loadingItemsClientes">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosClientes['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column sortable field="cuenta" header="Cuenta"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.cuenta }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="banco" header="Banco/Caja"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.jsonBancosSat.banco }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editBanco(slotProps.data)" v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="openDialogDelete(slotProps.data)" v-tooltip.bottom="'Eliminar'" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Bancos from "./Bancos.vue";
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            filtrosClientes: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                cuenta: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                "jsonBancosSat.banco": {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            },
            dialogoNuevoBanco: false,
            loadingItemsClientes: false,
            dialogDelete: false,
            editedIndexBanco: -1,
            editedBanco: {},
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsBancosEmpresa() {
            return this.$store.state.listBancosEmpresa;
        },
    },
    created() {
        this.getBancos();
    },
    methods: {
        async getBancos() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Bancos';
            try {
                let response = await axios.get("Bancos/getBancos/" + this.empresa.empresaBase);
                // console.log(response.data)
                this.$store.state.listBancosEmpresa = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        nuevoBanco() {
            let nuevoBanco = {
                idCatalogoBancos: 0,
                cuenta: '',
                jsonBancosSat: {
                    banco: '',
                    clave: '',
                    descripcion: '',
                },
            }
            if (this.$store.state.bancosEmpresaStore.idCatalogoBancos == -1) {
                this.$store.state.bancosEmpresaStore = { ...nuevoBanco }
            }
            this.dialogoNuevoBanco = true;
        },

        editBanco(item) {
            // console.log(item)
            this.$store.state.bancosEmpresaStore = { ...item };
            this.dialogoNuevoBanco = true;
        },

        openDialogDelete(item) {
            this.editedIndexBanco = this.itemsBancosEmpresa.indexOf(item);
            this.editedBanco = { ...item };
            this.dialogDelete = true;
        },

        async confirmDelete() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            try {
                this.editedBanco.usuarioModificador = this.empresa.nombre;
                let response = await axios.put(
                    "Bancos/PutBancoEmpresaEstatus/" + this.empresa.empresaBase,
                    this.editedBanco
                );
                this.itemsBancosEmpresa.splice(this.editedIndexBanco, 1);
                this.dialogDelete = false;
                this.loading = false;
                this.$toast.add({
                    severity: "success",
                    detail: "Registro eliminado con éxito.",
                    life: 3000,
                });
            } catch (error) {
                // console.log(error)
                this.$toast.add({
                    severity: "error",
                    detail: "Error al eliminar, intente nuevamente.",
                    life: 3000,
                });
                this.loading = false;
            }
        },

        closeDialogBanco() {
            this.dialogoNuevoBanco = false;
        },
    },
    components: { Bancos },
};
</script>