<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <ProtocoloPdf @cierraDialogPdf="closeDialogPdf"></ProtocoloPdf>
        </Dialog>

        <Card class="shadow-none">
            <!-- TITULO CARD -->
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                    </div>
                    <div class="text-center field col-4">
                        Protocolo
                    </div>
                    <div class="text-right field col-4">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                            @click="postGuardaProtocolo" v-tooltip.bottom="'Guardar'" />
                        <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="vistaPrevia" v-tooltip.bottom="'Vista Previa'" />
                    </div>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <!-- CONTENIDO DE LA FACTURA -->
            <template #content>
                <div class="field col-12 text-center mt-3">
                    <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                        DETALLES GENERALES
                    </h2>
                </div>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- SERIE -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputText v-model="protocolo.serie" readonly type="text" />
                            <label>Serie</label>
                        </span>
                    </div>
                    <!-- FOLIO -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputText v-model="protocolo.folio" readonly type="text" />
                            <label>Folio</label>
                        </span>
                    </div>
                    <!-- FECHA -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <Calendar v-model="protocolo.fechaProtocolo" dateFormat="dd-MM-yy" />
                            <label>Fecha</label>
                        </span>
                    </div>
                    <!-- CLIENTE -->
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText v-model="protocolo.cliente.cliente" readonly type="text" />
                            <label>Cliente</label>
                        </span>
                    </div>
                    <!-- PROYECTO  -->
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText v-model="protocolo.proyecto.descripcion" readonly type="text" />
                            <label>Proyecto</label>
                        </span>
                    </div>
                </div>

                <div class="field col-12 text-center mt-3">
                    <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                        ARTÍCULOS
                    </h2>
                </div>

                <DataTable :value="protocolo.conceptos" editMode="cell" @cell-edit-complete="onCellEditComplete"
                    class="editable-cells-table" responsiveLayout="scroll">
                    <Column field="item" header="Item"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'justify-content': 'center' }">
                    </Column>

                    <Column field="concepto" header="Concepto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'center' }">
                    </Column>
                    <Column field="noIdentificacion" header="No. Identificación"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'center' }">
                    </Column>
                    <Column field="unidad" header="Unidad"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'center' }">
                    </Column>
                    <Column field="cantidad" header="Cantidad"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'justify-content': 'center' }">
                    </Column>
                    <Column field="cantidadEntregada" header="Cantidad Entregada"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'justify-content': 'center' }">
                    </Column>
                    <Column field="cantidadEntregar" header="Cantidad A Entregar"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'justify-content': 'center' }">
                        <template #editor="slotProps">
                            <InputNumber v-model="slotProps.data.cantidadEntregar" autofocus />
                        </template>
                    </Column>
                    <Column field="cantidadRestante" header="Cantidad Restante"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '10px', 'justify-content': 'center' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import Detalles from './ProtocoloDetalles.vue'
import ProtocoloPdf from "../Pdf/VentaProtocoloPdf";
import moment from 'moment'

export default {
    components: {
        Detalles, ProtocoloPdf
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogPdf: false,
            timbres: 0,
        }
    },
    computed: {
        protocolo() {
            for (let a of this.$store.state.protocoloStore.conceptos) {
                if (a.cantidadEntregada > 0) {
                    a.cantidadRestante = a.cantidad - a.cantidadEntregada
                } else {
                    a.cantidadRestante = a.cantidad - a.cantidadEntregar
                }
            }
            return this.$store.state.protocoloStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
    },
    created() {
    },
    methods: {
        onCellEditComplete(event) {

            for (let a of this.protocolo.conceptos) {
                if (a.cantidadEntregada > 0) {
                    let resta = a.cantidad - a.cantidadEntregada
                    a.cantidadRestante = resta - a.cantidadEntregar
                } else {
                    a.cantidadRestante = a.cantidadRestante - a.cantidadEntregar
                }
            }

            let { data, newValue, field } = event;

            switch (field) {
                case 'cantidad':
                case 'cantidadEntregar':

                    let diferencia = data['cantidadRestante']

                    if (newValue > diferencia) {
                        this.$toast.add({ severity: 'error', detail: 'Error: La cantidad a entregar no puede ser mayor, a la restante.', life: 5000 });
                        event.preventDefault();
                        break;
                    } else {
                        data[field] = newValue;
                    }
                    for (let a of this.protocolo.conceptos) {
                        if (a.cantidadEntregada > 0) {
                            let resta = a.cantidad - a.cantidadEntregada
                            a.cantidadRestante = resta - a.cantidadEntregar
                        } else {
                            a.cantidadRestante = a.cantidad - a.cantidadEntregar
                        }
                    }
            }
        },

        vistaPrevia() {
            this.dialogPdf = true;
            console.log(this.$store.state.protocoloStore)
        },

        async postGuardaProtocolo() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                this.protocolo.usuarioCreador = this.empresa.nombre
                this.protocolo.fechaProtocolo = moment(this.protocolo.fechaProtocolo).format('YYYY-MM-DD HH:mm:ss')
                this.sumatoriaCantidadeaEntregadas()

                let response = await axios.post('Ventas/PostVentaProtocolo/' + this.empresa.empresaBase, this.protocolo)
                console.log(response.data)
                this.protocolo.idVentaProtocolo = response.data.id;
                this.$store.state.listProtocolosStore.push(this.protocolo)

                let array = this.$store.state.listProtocolosStore;
                this.$store.state.listProtocolosStore = array.sort(((a, b) => b.idVentaProtocolo - a.idVentaProtocolo))

                this.loading = false;
                this.dialogPdf = true;

                this.$toast.add({ severity: 'success', detail: 'Protocolo guardado con éxito', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }

        },
        cantidadesEnCero() {
            for (let a of this.protocolo.conceptos) {
                a.cantidadEntregar = 0
            }
        },

        sumatoriaCantidadeaEntregadas() {
            for (let a of this.protocolo.conceptos) {

                let suma = a.cantidadEntregada + a.cantidadEntregar
                a.cantidadEntregada = suma

                console.log('suma', a.cantidadEntregada)
                if (a.cantidadRestante == 0) {
                    a.estatus = 'Entregado'
                } else {
                    a.estatus = 'Pendiente'
                }
            }
            const contador = this.protocolo.conceptos.filter(e => e.estatus === 'Pendiente').length;
            if (contador != 0) {
                this.protocolo.estatus = 'Incompleto'
            } else {
                this.protocolo.estatus = 'Completo'
                console.log(this.$store.state.listProtocoloRemisionesStore)
                console.log(this.protocolo)

                let indice = this.$store.state.listProtocoloRemisionesStore.findIndex(x => x.idVentaPedido === this.protocolo.idVentaPedido)
                console.log('indice', indice)
                this.$store.state.listProtocoloRemisionesStore.splice(indice, 1)
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        formatCurrency(value) {
            return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        closeDialogPdf() {
            this.dialogPdf = false

            if (this.protocolo.folio != '') {
                this.$emit('cierraVentana')
            }
        },
    },
}
</script>
<style>
.color {
    background: #cfd3e9 !important;
}
</style>