<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- SERIE -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <Dropdown v-model="ventaRemision.serie" :options="itemsSerie" :editable="true" />
                    <label>Serie</label>
                </span>
            </div>
            <!-- FOLIO -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.folio" type="text" />
                    <label>Folio</label>
                </span>
            </div>
            <!-- FECHA -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <Calendar v-model="ventaRemision.fecha" dateFormat="dd-MM-yy" />
                    <label>Fecha</label>
                </span>
            </div>
            <!-- VIGENCIA
                <div class="field col-12 md:col-3">
                    <span class="p-float-label">
                        <Calendar v-model="ventaRemision.vigencia" dateFormat="dd-MM-yy" />
                        <label>Vigencia</label>
                    </span>
                </div> -->
            <!-- CLIENTE -->
            <div class=" field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="ventaRemision.cliente"
                            :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                        </AutoComplete>
                        <label>Cliente</label>
                    </span>
                </div>
            </div>
            <!-- ATN -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.atn" type="text" />
                    <label>Atn</label>
                </span>
            </div>
            <!-- MONEDA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ventaRemision.moneda" :options="itemsMoneda"
                        optionLabel="moneda" />
                    <label>Moneda</label>
                </span>
            </div>
            <!-- ORDEN DE TRABAJO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.ordenTrabajo" type="text" />
                    <label>Orden de trabajo</label>
                </span>
            </div>
            <!-- ORDEN DE COMPRA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.ordenCompra" type="text" />
                    <label>Orden de compra</label>
                </span>
            </div>
            <!-- PROYECTO -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown v-model="ventaRemision.proyecto" optionLabel="descripcion" :options="itemsProyectos" />
                    <label>Proyecto</label>
                </span>
            </div>
            <!-- METODO DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ventaRemision.metodoPago" :options="itemsMetodoPago"
                        optionLabel="metodoPago" />
                    <label>Método de Pago</label>
                </span>
            </div>
            <!-- FORMA DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ventaRemision.formaPago" :options="itemsFormaPago"
                        optionLabel="formaPago" />
                    <label>Forma de Pago</label>
                </span>
            </div>
            <!-- CONDICIONES DE PAGO -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.condicionesPago" type="text" />
                    <label>Condiciones de Pago</label>
                </span>
            </div>
            <!-- MONEDA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="ventaRemision.moneda" :options="itemsMoneda"
                        optionLabel="moneda" />
                    <label>Moneda</label>
                </span>
            </div>
            <!-- NOTAS -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <InputText v-model="ventaRemision.notas" type="text" />
                    <label>Notas</label>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import Clientes from "../Clientes/Clientes.vue";
import Proyecto from "../Proyectos/Proyecto.vue"

export default {
    components: { Clientes, Proyecto },

    data() {
        return {
            filteredCliente: null,
            filteredProyecto: null,
            dialogoNuevoCliente: false,
            dialogoNuevoProyecto: false,
        }
    },
    watch: {

    },
    computed: {
        itemsProyectos() {
            return this.$store.state.listProyectosStore.filter(proyecto => proyecto.rfc === this.ventaRemision.cliente.rfc && proyecto.estatus == 'Vigente');
        },
        itemsSerie() {
            return this.$store.state.listVentaRemisionSerieStore;
        },
        ventaRemision() {
            return this.$store.state.ventaRemisionStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
    },
    created() {

    },
    methods: {
        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
    },
}
</script>