<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.curp }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogDelete = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminar()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO CONCEPTO -->
        <Dialog :modal="false" :visible.sync="dialogoNuevoEmisor" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1000px' }">
            <Emisor @cierraVentana="dialogoNuevoEmisor = false"></Emisor>
        </Dialog>

        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left mt-3 p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Comercio Exterior EMISOR
                    </div>
                    <div
                        class="mt-2 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoEmisor()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getEmisor()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosEmisores" removableSort showGridlines :value="itemsEmisor"
                    responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'id',
                        'nombre',
                        'curp',
                        'domicilio.domicilio',
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros."
                    :loading="loadingItemsTabla">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosEmisores['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column sortable field="id" header="ID"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.id }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.nombre }}
                        </template>
                    </Column>
                    <Column sortable field="curp" header="CURP"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.curp }}
                        </template>
                    </Column>
                    <Column sortable field="domicilio.domicilio" header="Domicilio"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.domicilio.domicilio }}
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Editar/Eliminar">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editar(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="confirmDeleteProduct(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Emisor from "./ComercioExteriorEmisor.vue";
import moment from "moment";

export default {
    components: { Emisor },

    data() {
        return {
            loadingMensaje: "",
            loading: false,
            dialogoNuevoEmisor: false,

            filtrosEmisores: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'curp': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'domicilio.domicilio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            loadingItemsTabla: false,
            objetoEditado: {},

            dialogDelete: false,
            editedIndex: -1,

        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsEmisor() {
            return this.$store.state.listCEEmisorStore;
        },
    },
    created() {
        this.getEmisor()
    },
    methods: {
        nuevoEmisor() {
            this.dialogoNuevoEmisor = true;

            if (this.$store.state.cEEmisorStore != 0) {
                let obj = {
                    id: 0,
                    curp: '',
                    domicilio: {
                        domicilio: ''
                    },
                    usuarioCreador: '',
                    usuarioModificador: '',
                }
                this.$store.state.cEEmisorStore = { ...obj }
            }
        },

        async getEmisor() {
            this.loadingItemsTabla = true;
            try {
                let response = await axios.get('ComercioExterior/GetEmisores/' + this.empresa.empresaBase)
                this.$store.state.listCEEmisorStore = response.data
                // console.log(response.data)
                this.loadingItemsTabla = false;

            } catch (error) {
                console.log(error)
                this.loadingItemsTabla = false;
            }
        },

        editar(data) {
            this.$store.state.cEEmisorStore = { ...data }
            this.dialogoNuevoEmisor = true
        },

        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsEmisor.indexOf(data)
            this.objetoEditado = { ...data };
            this.dialogDelete = true;
        },

        async eliminar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre

                let response = await axios.put('ComercioExterior/PutEmisorEstatus/' + this.empresa.empresaBase, this.objetoEditado)
                this.itemsEmisor.splice(this.editedIndex, 1)

                this.dialogDelete = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Emisor eliminado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
    },
};
</script>
<style>
.p-dialog .p-dialog-content {
    overflow-y: visible !important;
}

.p-dialog-mask {
    position: absolute;
    pointer-events: auto;
    /* (optional, doesn't cover whole area when scroll presents) */
}
</style>