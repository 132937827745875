<template>
    <div>
        <web-cam ref="webcam" :device-id="deviceId" width="100%" @started="onStarted" @stopped="onStopped"
            @error="onError" @cameras="onCameras" @camera-change="onCameraChange" />
        <!-- <select v-model="camera">
            <option>-- Select Device --</option>
            <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">{{
            device.label }}</option>
        </select> -->
        <Button type="button" class="mr-3" label="Capurar foto" @click="onCapture" />
        <Button type="button" class="mr-3" label="Stop" @click="onStop" />
        <Button type="button" class="mr-3" label="Start" @click="onStart" />
        <!-- <h2>Captured Image</h2>
        <figure class="figure">
            <img :src="img" class="img-responsive">
        </figure> -->
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import { WebCam } from "vue-web-cam";
import { find, head } from "lodash";
export default {
    components: {
        WebCam
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',

            // camara
            img: null,
            camera: null,
            deviceId: null,
            devices: []
        }
    },
    computed: {
        device() {
            return find(this.devices, n => n.deviceId == this.deviceId);
        }
    },
    watch: {
        camera: function (id) {
            this.deviceId = id;
        },
        devices: function () {
            // Once we have a list select the first one
            let first = head(this.devices);
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    created() {
    },
    methods: {
        onCapture() {
            this.img = this.$refs.webcam.capture();
            console.log(this.img)
            this.$store.state.usuarioInternosStore.foto.base64 = this.img;
            this.cierraVentana()
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        cierraVentana() {
            this.$emit('closeWebCam')
        },
    },
}
</script>
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>