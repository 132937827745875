<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <complemento-pago-pdf @cierraDialogPdf="closeDialogPdf"></complemento-pago-pdf>
        </Dialog>

        <Card class="shadow-none">
            <!-- TITULO CARD -->
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                    </div>

                    <div class="text-center field col-4">
                        Complemento de Pagos
                    </div>
                    <div class="text-right field col-4">
                        <template v-if="pago.idComplementoPago === 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="postPago" v-tooltip.bottom="'Timbrar'" />
                            <!-- <Button icon="pi pi-save"
                                class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                                @click="postGuardaFactura" v-tooltip.bottom="'Guardar'" /> -->
                        </template>
                        <template v-if="pago.idComplementoPago != 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="putTimbrado" v-tooltip.bottom="'Timbrar Guardado'" />
                            <Button icon="pi pi-save"
                                class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                                @click="putFactura" v-tooltip.bottom="'Guardar Editado'" />
                        </template>
                        <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="vistaPrevia" v-tooltip.bottom="'Vista Previa'" />
                    </div>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <!-- CONTENIDO DEEL COMPLEMENTO DE PAGO -->
            <template #content>
                <TabView>
                    <TabPanel header="Datos generales">
                        <General></General>
                    </TabPanel>
                    <TabPanel header="Pagos">
                        <pagos></pagos>
                    </TabPanel>
                    <TabPanel header="CFDI'S Relacionados">
                        <Relacionados></Relacionados>
                    </TabPanel>
                </TabView>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import General from './ComplementoPagoGeneral.vue'
import Pagos from './ComplementoPagoPagos.vue'
import moment from 'moment'
import ComplementoPagoPdf from "../../Pdf/ComplementoPagoPdf.vue";
import Relacionados from './ComplementoPagoCfdiRelacionado.vue'

export default {
    components: {
        General,
        Pagos,
        ComplementoPagoPdf,
        Relacionados
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',

            dialogPdf: false,
        }
    },
    computed: {
        pago() {
            return this.$store.state.complementoPagoStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getEmpresa();
    },
    methods: {
        async getEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresa.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async postPago() {
            this.loading = true;
            this.loadingMensaje = 'Timbrando comprobante ...'
            console.log(this.pago)
            try {
                let comprobante = { ...this.pago }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.pago.cliente.rfc
                comprobante.nombre = this.pago.cliente.nombre
                comprobante.fecha = moment(this.pago.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.pagos.pagos[0].fechaPago = moment(comprobante.pagos.pagos[0].fechaPago).format('YYYY-MM-DD HH:mm:ss')

                let response = await axios.post('Comprobantes/PostTimbraComprobante/' + this.empresa.empresaBase, comprobante)

                //ASIGNAMOS VALORES
                this.$store.state.complementoPagoStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.complementoPagoStore.idComplementoPago = -1;
                this.$store.state.complementoPagoStore.folio = response.data.folio
                this.$store.state.complementoPagoStore.folioFiscal = response.data.folioFiscal
                this.$store.state.complementoPagoStore.rfc = comprobante.cliente.rfc
                this.$store.state.complementoPagoStore.nombre = comprobante.cliente.nombre
                this.$store.state.complementoPagoStore.estatus = 'Vigente'

                this.$store.state.listComplementoPagoStore.push(this.$store.state.complementoPagoStore);
                let array = this.$store.state.listComplementoPagoStore;
                this.$store.state.listComplementoPagoStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                console.log(error.response.data)
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                this.loading = false;
            }
        },

        async postGuardaFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.pago }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.pago.cliente.rfc
                comprobante.nombre = this.pago.cliente.nombre
                comprobante.fecha = moment(this.pago.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostGuardaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.complementoPagoStore.idComplementoPago = -1;
                this.$store.state.complementoPagoStore.idComprobante = response.data;
                this.$store.state.complementoPagoStore.rfc = comprobante.cliente.rfc
                this.$store.state.complementoPagoStore.nombre = comprobante.cliente.nombre
                this.$store.state.complementoPagoStore.folioFiscal = response.data;

                this.$store.state.listComplementoPagoStore.push(this.$store.state.complementoPagoStore);
                let array = this.$store.state.listComplementoPagoStore;
                this.$store.state.listComplementoPagoStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putTimbrado() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.pago }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.pago.cliente.rfc
                comprobante.nombre = this.pago.cliente.nombre
                comprobante.fecha = moment(this.pago.fecha).format('YYYY-MM-DD HH:mm:ss')
                comprobante.pagos.pagos[0].fechaPago = moment(comprobante.pagos.pagos[0].fechaPago).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraEditaComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.complementoPagoStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.complementoPagoStore.idComplementoPago = -1;
                this.$store.state.complementoPagoStore.folio = response.data.folio
                this.$store.state.complementoPagoStore.folioFiscal = response.data.folioFiscal
                this.$store.state.complementoPagoStore.rfc = comprobante.cliente.rfc
                this.$store.state.complementoPagoStore.nombre = comprobante.cliente.nombre
                this.$store.state.complementoPagoStore.estatus = 'Vigente'

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listComplementoPagoStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listComplementoPagoStore[indice] = this.$store.state.complementoPagoStore
                console.log(indice)

                let array = this.$store.state.listComplementoPagoStore;
                this.$store.state.listComplementoPagoStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.pago }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.pago.cliente.rfc
                comprobante.nombre = this.pago.cliente.nombre
                comprobante.fecha = moment(this.pago.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.put('Comprobantes/PutComprobante/' + this.empresa.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                this.$store.state.complementoPagoStore.rfc = comprobante.cliente.rfc
                this.$store.state.complementoPagoStore.nombre = comprobante.cliente.nombre

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listComplementoPagoStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listComplementoPagoStore[indice] = comprobante
                console.log(indice)

                // this.$store.state.listComplementoPagoStore.push(this.$store.state.complementoPagoStore);
                this.$store.state.complementoPagoStore.idComplementoPago = -1;
                let array = this.$store.state.listComplementoPagoStore;
                this.$store.state.listComplementoPagoStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        vistaPrevia() {
            this.dialogPdf = true;
        },

        cierraVentana() {
            this.$store.state.complementoPagoStore.idComplementoPago = -1;
            this.$emit('closeComplementoPago')
        },

        closeDialogPdf() {
            this.dialogPdf = false
            if (this.pago.timbreFiscalDigital.folioFiscal != '') {
                this.$emit('closeComplementoPago')
            }
        },
    },
}
</script>