<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- ENLISTA LOS MOVIMIENTOS -->
        <Dialog :visible.sync="dialogMovimientos" :closeOnEscape="true" :closable="false" :style="{ width: '90vw' }"
            :modal="true">
            <Movimientos @closeMovimiento="closeDialogMovimientos()"></Movimientos>
        </Dialog>
        <!-- TABLA DE MOVIMIENTOS -->
        <DataTable :value="listaESAlmacen" :scrollable="true" scrollHeight="400px" scrollDirection="both"
            :rowsPerPageOptions="[10, 25, 50]" currentPageReportTemplate="{first} a {last} de {totalRecords}"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :paginator="true" :rows="10">
            <template #header>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- ENCABEZADO -->
                    <div class="col-4 text-left">
                        <h1>Entradas / Salidas </h1>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-6 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-6 text-left">
                                <span class="p-float-label">
                                    <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                    <label>Fecha Inicial</label>
                                </span>
                            </div>
                            <div class="col-12 md:col-6 text-left">
                                <span class="p-float-label">
                                    <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                    <label>Fecha Final</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- BOTONES -->
                    <div class="col-2 text-right">
                        <Button icon="mdi mdi-reload" class="p-button-rounded p-button-info p-button-outlined"
                            @click="GetMovimientosFecha" v-tooltip.bottom="'Consultar Movimientos'" />
                        <Button icon="mdi mdi-plus" class="p-button-rounded p-button-success p-button-outlined"
                            @click="OpenDialogEntrada()" v-tooltip.bottom="'Nueva Entrada'" />
                        <Button icon="mdi mdi-minus" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="OpenDialogSalida()" v-tooltip.bottom="'Nueva Salida'" />
                    </div>
                </div>
            </template>
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-eye" class="p-button-rounded p-button-info" v-tooltip.bottom="'Ver Detalle'"
                        @click="VerDetalle(slotProps.data)" />
                </template>
            </Column>
            <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }" sorteable>
            </Column>
            <Column field="fecha" header="Fecha" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
                <template #body="slotProps">
                    <span>{{ formatDate(slotProps.data.fecha) }}</span>
                </template>
            </Column>
            <Column field="solicitante" header="Solicitante" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }"
                sorteable>
            </Column>
            <Column field="tipo" header="Tipo" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sorteable>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import Movimientos from './MovimientosESAlmacen.vue'
export default {
    components: {
        Movimientos,
    },
    data() {
        return {
            dialogMovimientos: false,
            fechaI: new Date(),
            fechaF: new Date(),
            loading: false,
            loadingMensaje: '',
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        listaESAlmacen() {
            return this.$store.state.listaESAlmacenStore;
        },
    },
    created() {
        this.Iniciales();
    },
    methods: {
        async Iniciales() {
            if (this.$store.state.listaESAlmacenStore.length == 0) {
                await this.GetMovimientosInicial();
            }
        },

        async GetMovimientosInicial() {
            this.loadingMensaje = 'Consultando...'
            this.loading = true;
            try {
                let response = await axios.get('Inventarios/GetMovimientosAlmacenInicial/' + this.token.empresaBase)
                let array = response.data
                this.$store.state.listaESAlmacenStore = array.sort(((a, b) => b.folio - a.folio))
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        async GetMovimientosFecha() {
            this.loadingMensaje = 'Consultando...'
            this.loading = true;
            try {
                let fechaIni = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFin = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get('Inventarios/GetMovimientosAlmacenFecha/' + this.token.empresaBase + '/' + fechaIni + '/' + fechaFin)
                let array = response.data
                this.$store.state.listaESAlmacenStore = array.sort(((a, b) => b.folio - a.folio))
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        OpenDialogEntrada() {
            this.dialogMovimientos = true;
            let ObjMovimiento = {
                id: -1,
                tipo: 'Entrada'
            };
            this.$store.state.movimientoAlmacen = { ...ObjMovimiento };
        },

        OpenDialogSalida() {
            this.dialogMovimientos = true;
            let ObjMovimiento = {
                id: -1,
                tipo: 'Salida'
            };
            this.$store.state.movimientoAlmacen = { ...ObjMovimiento };
        },

        closeDialogMovimientos() {
            this.dialogMovimientos = false;
        },

        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },

        VerDetalle(item) {

        },
    },
}
</script>