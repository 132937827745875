<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 800px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>
            <!-- DIALOG DEL CORREO -->
            <Dialog :visible.sync="dialogCorreo" :style="{ width: '800px' }" :closable="false">
                <Card>
                    <template #header>
                        <div class="flex justify-content-between flex-wrap pb-2">
                            <Button @click="closeDialogCorreo"> Cerrar</Button>
                            Enviar comprobante
                            <Button @click="enviaCorreo"> Enviar</Button>
                        </div>
                    </template>
                    <template #content>
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <InputText v-model="correo.asunto" type="text" autofocus tabindex="1" />
                                    <label>Asunto</label>
                                </span>
                            </div>
                        </div>
                        <InlineMessage severity="info">Para agregar un DESTINATARIO primero agregue un NOMBRE y un
                            CORREO</InlineMessage>
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-6">
                                <span class="p-float-label">
                                    <InputText v-model="nuevoDestinatario.nombre" type="text" ref="nombreF" />
                                    <label>Nombre</label>
                                </span>
                            </div>
                            <div class="field col-6">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <InputText v-model="nuevoDestinatario.correo" type="text"
                                            @keydown.enter="pushCorreo()" />
                                        <label>Correo</label>
                                    </span>
                                    <Button icon="pi pi-plus" class="p-button-success" @click="pushCorreo" />
                                </div>
                            </div>
                        </div>
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <Chips v-model="destinatarios" :allowDuplicate="false">
                                        <template #chip="slotProps">
                                            <div>
                                                <span style="font-size: 12px">{{ slotProps.value.nombre }} - {{
                                                    slotProps.value.correo
                                                }}</span>
                                            </div>
                                        </template>
                                    </Chips>
                                    <label>Destinatarios</label>
                                </span>
                            </div>
                        </div>
                        <Editor v-model="correo.mensaje" editorStyle="height: 500px" />
                    </template>
                </Card>
            </Dialog>
            <Card>
                <template #header>
                    <Button @click="closeDialogPdf"> Cerrar</Button>
                    <Button @click="descargaComprobante"> Descargar PDF</Button>
                    <Button @click="descargaComprobanteXml"> Descargar XML</Button>
                    <Button @click="openDialogCorreo"> Enviar</Button>
                </template>
            </Card>
            <Card>
                <template #content>
                    <div id="invoice" class="ma-0 pa-0">
                        <!-- CABECERA DEL COMPROBANTE -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="md:col">
                                <a href="https://contago.com.mx/" target="_blank">
                                    <img :src="datosEmpresa.logo.base64" width="250">
                                </a>
                            </div>
                            <div class="md:col text-right ma-0 pa-0">
                                <font size="4">
                                    <div><strong>Complemento Ingreso Carta Porte</strong></div>
                                </font>
                                <font size="3">
                                    <div>{{ datosEmpresa.rfc }}</div>
                                </font>
                                <font size="3">
                                    <div>{{ datosEmpresa.nombre }}</div>
                                </font>
                                <font size="2">
                                    <div>{{ datosEmpresa.regimenFiscal.regimenFiscal }}</div>
                                </font>
                            </div>
                        </div>
                        <!-- DATOS DEL CLIENTE -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="md:col-6 text-left">
                                <font size="3">
                                    <div>Datos del cliente:</div>
                                </font>
                                <font size="3">
                                    <div><strong>{{ item.cliente.cliente }}</strong></div>
                                </font>
                                <font size="2">
                                    <div>{{ item.cliente.domicilioFiscal }}</div>
                                </font>
                                <font size="2">
                                    <div>{{ item.cliente.direccion }}</div>
                                </font>
                            </div>
                            <div class="md:col-6 text-right">
                                <h5>{{ item.serie }} {{ item.folio }}</h5>
                                <div>Fecha: {{ formatDate(item.fecha) }}</div>
                                <div>Moneda: {{ item.moneda.clave }}</div>
                                <div>Tipo de comprobante: I | Ingreso</div>
                                <div>Lugar de Expedición: {{ datosEmpresa.domicilioFiscal }}</div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col text-center">
                                <p class="font-bold" style="font-size: 12px">Metodo de Pago</p>
                                <p style="font-size: 12px">{{ item.metodoPago.metodoPago }}</p>
                            </div>
                            <div class="col text-center">
                                <p class="font-bold" style="font-size: 12px">Forma de Pago</p>
                                <p style="font-size: 12px">{{ item.formaPago.formaPago }}</p>
                            </div>
                            <div class="col text-center">
                                <p class="font-bold" style="font-size: 12px">Condiciones de Pago</p>
                                <p style="font-size: 12px">{{ item.condicionesPago }}</p>
                            </div>
                        </div>
                        <!-- DOCUMENTOS RELACIONADOS -->
                        <template v-if="item.cfdiRelacionados[0].cfdiRelacionados.length != 0">
                            <div class="grid">
                                <div v-for="rel in item.cfdiRelacionados" :key="rel.item">
                                    <DataTable class="mt-1" :value="rel.cfdiRelacionados">
                                        <template #header>
                                            {{ rel.tipoRelacion.tipoRelacion }}
                                        </template>
                                        <Column field="serie" header="Serie"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '65%' }">
                                        </Column>
                                        <Column field="folio" header="Folio"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '65%' }">
                                        </Column>
                                        <Column field="fecha" header="Fecha"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                        </Column>
                                        <Column field="rfc" header="RFC"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                        </Column>
                                        <Column field="nombre" header="Nombre"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                        </Column>
                                        <Column field="subTotal" header="SubTotal"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '65%', 'text-align': 'right' }">
                                            <template #body="{ data }">
                                                <span>{{ formatCurrency(data.subTotal) }}</span>
                                            </template>
                                        </Column>
                                        <Column field="total" header="Total"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '65%', 'text-align': 'right' }">
                                            <template #body="{ data }">
                                                <span>{{ formatCurrency(data.total) }}</span>
                                            </template>
                                        </Column>
                                        <Column field="uuid" header="FolioFiscal"
                                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                        </Column>
                                    </DataTable>
                                    <hr color="#FF9100">
                                </div>
                            </div>
                        </template>
                        <!-- CONCEPTOS -->
                        <DataTable class="mt-1" :value="item.conceptos">
                            <Column field="descripcion" header="Descripción" class="flex"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                                <template #body="{ data }">
                                    <h4>{{ data.descripcion }}</h4>
                                    <div>{{ data.claveProdServ.claveProdServ }}</div>
                                </template>
                            </Column>
                            <Column field="cantidad" header="Cantidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                            </Column>
                            <Column field="claveUnidad.claveUnidad" header="Clave Unidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                            </Column>
                            <Column field="valorUnitario" header="Valor Unitario"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                </template>
                            </Column>
                            <Column field="importe" header="Importe"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.importe) }}</span>
                                </template>
                            </Column>
                        </DataTable>
                        <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                        <div class="grid">
                            <div class="col-8 text-left">
                                <p style="font-size: 12px; text-align: center;"><strong>Importe con letra: </strong> {{
                                    importeLetra }}
                                </p>
                                <br>
                                <template v-if="item.timbreFiscalDigital.folioFiscal != ''">
                                    <p style="font-size: 12px"><strong>Fecha de Timbrado: </strong> {{
                                        item.timbreFiscalDigital.fechaTimbrado
                                    }}</p>
                                    <p style="font-size: 12px"><strong>No. Certificado: </strong> {{
                                        item.timbreFiscalDigital.noCertificado
                                    }}</p>
                                    <p style="font-size: 12px"><strong>No. Certificado SAT: </strong> {{
                                        item.timbreFiscalDigital.noCertificadoSAT
                                    }}</p>
                                    <p style="font-size: 13px"> <strong>Folio Fiscal: </strong> {{
                                        item.timbreFiscalDigital.folioFiscal
                                    }}</p>
                                </template>
                            </div>
                            <div class="col-4">
                                <DataTable :value="itemsImpuestos" class="ocult">
                                    <Column field="impuesto" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                    </Column>
                                    <Column field="importe" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                        <template #body="{ data }">
                                            <span>{{ formatCurrency(data.importe) }}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <div class="col-12">
                                <p style="font-size: 15px; text-align: center;">{{ item.notas }}</p>
                            </div>
                        </div>
                        <br>
                        <h4>Complemento de Ingreso Carta Porte </h4>
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <!-- VERSION -->
                            <div class="field col-4">
                                <span class="p-float-label">
                                    <InputText class="p-inputtext-sm" style="text-align: center"
                                        v-model="item.cartaPorte.version" type="text" readonly />
                                    <label>Versión</label>
                                </span>
                            </div>
                            <!-- TRANSPORTE INTERNACIONAL -->
                            <div class="field col-4">
                                <span class="p-float-label">
                                    <InputText class="p-inputtext-sm" style="text-align: center"
                                        v-model="item.cartaPorte.transpInternac" type="text" readonly />
                                    <label>Transporte Internacional</label>
                                </span>
                            </div>
                            <!-- TOTAL DE DISTANCIA RECCORRIDA -->
                            <div class="field col-4" style="justify-content: right">
                                <span class="p-float-label">
                                    <InputNumber class="p-inputtext-sm" v-model="item.cartaPorte.totalDistRec"
                                        mode="decimal" suffix=" km" locale="en-US" readonly />
                                    <label>Total Distancia Recorrida</label>
                                </span>
                            </div>
                        </div>
                        <template v-if="item.cartaPorte.transpInternac == 'SI'">
                            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                                <!-- VERSION -->
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputText class="p-inputtext-sm" style="text-align: center"
                                            v-model="item.cartaPorte.entradaSalidaMerc" type="text" readonly />
                                        <label>Entrada o Salida Mercancía</label>
                                    </span>
                                </div>
                                <!-- VERSION -->
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputText class="p-inputtext-sm" style="text-align: center"
                                            v-model="item.cartaPorte.viaEntradaSalida.transporte" type="text" readonly />
                                        <label>Via Entrada/Salida</label>
                                    </span>
                                </div>
                                <!-- VERSION -->
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputText class="p-inputtext-sm" style="text-align: center"
                                            v-model="item.cartaPorte.paisOrigenDestino.pais" type="text" readonly />
                                        <label>País Origen/Destino</label>
                                    </span>
                                </div>
                            </div>
                        </template>
                        <!-- AUTOTRANSPORTE -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    AUTOTRANSPORTE
                                </h1>
                            </div>
                            <div class="grid">
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Num. Permiso</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.permSCT.numPermisoSCT
                                    }}
                                    </p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Permiso SCT</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.permSCT.permSCT.permSCT
                                    }}
                                    </p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Configuración</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.identificacionVehicular.configAutotransporte.configAutotransporte
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Placa</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.identificacionVehicular.placaVM
                                    }}</p>
                                    <p style="font-size: 12px">{{
                                        'Modelo: ' +
                                        item.cartaPorte.autotransporte.identificacionVehicular.anioModelo
                                    }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- SEGUROS -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    SEGUROS
                                </h1>
                            </div>
                            <div class="grid">
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Aseguradora Civil</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroCivil.aseguradora
                                    }}
                                    </p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Póliza Civil</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroCivil.numPoliza
                                    }}
                                    </p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Aseguradora Ambiental</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroAmbiente.aseguradora
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Póliza Ambiental</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroAmbiente.numPoliza
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Aseguradora Carga</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroCarga.aseguradora
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Póliza Carga</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.autotransporte.seguroCarga.numPoliza
                                    }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- UBICACION ORIGEN -->
                        <div class=" p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    UBICACIÓN DE ORIGEN
                                </h1>
                            </div>
                            <div class="grid">
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Tipo de Ubicacíon</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.ubicacionOrigen.tipoUbicacion
                                        +
                                        item.cartaPorte.ubicacionOrigen.iDUbicacion
                                    }}
                                    </p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Fecha de Salida</p>
                                    <p style="font-size: 12px">{{
                                        formatDateUbicaciones(item.cartaPorte.ubicacionOrigen.fecha)
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">RFC</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.ubicacionOrigen.rfcRemitenteDestinatario
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Nombre</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.ubicacionOrigen.nombreRemitenteDestinatario
                                    }}</p>
                                </div>
                                <div class="col text-center">
                                    <p class="font-bold" style="font-size: 12px">Domicilio</p>
                                    <p style="font-size: 12px">{{
                                        item.cartaPorte.ubicacionOrigen.domicilio.domicilio
                                    }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- UBICACIONES DESTINO -->
                        <div class="salto-destinos p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    UBICACIONES DE DESTINO
                                </h1>
                            </div>
                            <DataTable class="mt-0" :value="item.cartaPorte.ubicacionesDestino">
                                <Column header="Tipo de Ubicación"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                    <template #body="{ data }">
                                        <span>{{ data.tipoUbicacion + data.iDUbicacion }}</span>
                                    </template>
                                </Column>
                                <Column field="fecha" header="Fecha de Llegada"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                    <template #body="{ data }">
                                        <span>{{ formatDateUbicaciones(data.fecha) }}</span>
                                    </template>
                                </Column>
                                <Column field="rfcRemitenteDestinatario" header="RFC"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="nombreRemitenteDestinatario" header="Nombre"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="domicilio.domicilio" header="Domicilio"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                            </DataTable>
                        </div>
                        <!-- UBICACIONES DESTINO -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    TIPOS DE FIGURA
                                </h1>
                            </div>
                            <DataTable class="mt-0" :value="item.cartaPorte.figuraDelTransporte">
                                <Column field="tipoFigura.figuraTransporte" header="Tipo de Figrua"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="rfcFigura" header="RFC"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="nombreFigura" header="Nombre"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="numLicencia" header="Num. Licencia"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="numRegIdTribFigura" header="Num. Reg. Trib."
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="residenciaFiscalFigura.clave" header="Residencia Fiscal"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="partesDelTransporte.parteTransporte" header="Parte Transporte"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="domicilio.domicilio" header="Domicilio"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                            </DataTable>
                        </div>
                        <!-- MERCANCIA -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    MERCANCÍA
                                </h1>
                            </div>
                            <div class="col-4 text-center">
                                <p class="font-bold" style="font-size: 12px">Total de Mercancías</p>
                                <p style="font-size: 12px">{{ item.cartaPorte.mercancia.numTotalMercancias }}
                                </p>
                            </div>
                            <div class="col-4 text-center">
                                <p class="font-bold" style="font-size: 12px">Peso Bruto Total</p>
                                <p style="font-size: 12px">{{ item.cartaPorte.mercancia.pesoBrutoTotal }}
                                </p>
                            </div>
                            <div class="col-4 text-center">
                                <p class="font-bold" style="font-size: 12px">Unidad de Peso</p>
                                <p style="font-size: 12px">{{
                                    item.cartaPorte.mercancia.unidadPeso.claveUnidadPeso
                                }}
                                </p>
                            </div>
                        </div>
                        <!-- MERCANCIAS A TRANPORTAR -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="field col-12 text-center">
                                <h1 style="background: gray;color: white;font-size: 16px;font-family: arial;">
                                    MERCANCÍAS A TRANSPORTAR
                                </h1>
                            </div>
                            <DataTable class="mt-0" :value="item.cartaPorte.mercancias">
                                <Column field="bienesTransp.claveProdServCP" header="Bienes Trans."
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="cantidad" header="Cantidad"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="descripcion" header="Descripción"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="claveUnidad.claveUnidad" header="Unidad"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="dimensiones" header="Dimensiones"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="cveMaterialPeligroso.materialPeligroso" header="Material Peligroso"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="embalaje.tipoEmbalaje" header="Embalaje"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="pesoEnKg" header="Peso en KGM"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                </Column>
                                <Column field="valorMercancia" header="Valor"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '65%' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.valorMercancia) }}</span>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <!-- DATOS DEL SAT -->
                        <template class="datosFiscales" v-if="item.timbreFiscalDigital.folioFiscal != ''">
                            <div class="grid">
                                <div class="col-3">
                                    <a href="https://contago.com.mx/" target="_blank">
                                        <img :src="imagenQR" width="160">
                                    </a>
                                </div>
                                <div class="col-9 text-left" style="word-break: break-all;">
                                    <p class="font-bold" style="font-size: 10px;">
                                        Sello CFDI</p>
                                    <!-- <p style="font-size: 9px">{{ item.timbreFiscalDigital.selloCFDI.base64 }}</p> -->
                                    <div style="font-size: 9px;"><wbr> {{ item.timbreFiscalDigital.selloCFDI.base64 }}
                                    </div>
                                    <p class="font-bold" style="font-size: 10px">Sello SAT</p>
                                    <!-- <p style="font-size: 9px">{{ item.timbreFiscalDigital.selloSAT.base64 }}</p> -->
                                    <div style="font-size: 9px">{{ item.timbreFiscalDigital.selloSAT.base64 }}</div>
                                    <p class="font-bold" style="font-size: 10px">Cadena Original</p>
                                    <!-- <p style="font-size: 9px">{{ item.timbreFiscalDigital.cadenaOriginal }}</p> -->
                                    <div style="font-size: 9px">{{ item.timbreFiscalDigital.cadenaOriginal }}</div>
                                </div>
                            </div>
                        </template>

                    </div>
                </template>

            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>

<script>
import moment from "moment";
import html2pdf from "html2pdf.js";
import axios from 'axios'
import { numeroALetras } from '../../plugins/numeroALetras.js'

export default {
    components: {
        // numeroALetras,
    },
    data() {
        return {
            dialogCorreo: false,
            loadingMensaje: '',
            loading: false,
            destinatarios: [],
            nuevoDestinatario: {
                nombre: '',
                correo: ''
            }
        };
    },
    computed: {
        item() {
            console.log(this.$store.state.ingresoCartaPorteStore)
            return this.$store.state.ingresoCartaPorteStore;
        },
        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.ingresoCartaPorteStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.ingresoCartaPorteStore.impuestos.traslados) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.ingresoCartaPorteStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000
                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.ingresoCartaPorteStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        imagenQR() {
            let imagen = 'data:image/jpeg;base64,'
            return imagen + this.$store.state.ingresoCartaPorteStore.timbreFiscalDigital.archioQr
        },
        importeLetra() {
            const func = require('../../plugins/numeroALetras.js')
            let a = func.numeroALetras(this.$store.state.ingresoCartaPorteStore.total, this.$store.state.ingresoCartaPorteStore.moneda.clave);
            return a;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore
        },

        correo() {
            return this.$store.state.correoStore
        },

    },
    watch: {

    },
    mounted() {

    },
    created() {
        this.getCorreo();
    },
    methods: {
        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy");
            return fechaR;
        },
        formatDateUbicaciones(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MM-yyyy hh:mm:ss a");
            return fechaR;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async exportToPDF() {
            var opt = {
                margin: .5,
                filename: 'CPIngreso_' + this.item.cliente.rfc + '_' + this.item.serie + "_" + this.item.folio + "_" + this.item.folioFiscal + '_' + this.item.subTotal + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //EXPORTA EL PDF
            html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {

                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');

                    // you can add the line separator as an image, consult the docs below to properly set the place of the image
                    // pdf.addImage(img, 'png', 0, 0, 52, 23)
                }

            }).save();
        },

        async convertBase64() {
            var opt = {
                margin: .5,
                filename: 'CPIngreso_' + this.item.cliente.rfc + '_' + this.item.serie + "_" + this.item.folio + "_" + this.item.folioFiscal + '_' + this.item.subTotal + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                // console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');
                }

            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return 'data:application/pdf;base64,' + file
        },

        async descargaComprobante() {
            await this.exportToPDF()
        },

        openDialogCorreo() {
            this.correo.archivos = []

            this.correo.archivos.push()
            this.dialogCorreo = true;
        },

        closeDialogPdf() {
            this.$emit('cierraDialogPdf')
        },

        closeDialogCorreo() {
            this.dialogCorreo = false;
        },

        async enviaCorreo() {
            this.loading = true;
            this.loadingMensaje = 'Enviando correo...'

            let correos = {
                asunto: this.correo.asunto,
                mensaje: this.correo.mensaje,
                emisor: {
                    nombre: '',
                    correo: '',
                    contra: '',
                    hostt: '',
                    puerto: 0,
                },
                receptores: [],
                archivos: [],
            }


            var xml_ = {
                base64: this.item.timbreFiscalDigital.archivoXml,
                nombre: this.item.folioFiscal + '.xml'
            }
            var pdf_ = {
                base64: await this.convertBase64(),
                nombre: this.item.folioFiscal + '.pdf'
            }

            // for (let d of this.destinatarios) {
            //     var des = {
            //         nombre: '',
            //         correo: d,
            //     }
            correos.receptores = this.destinatarios;
            // des = {}
            // }

            correos.archivos.push(xml_);
            correos.archivos.push(pdf_);

            console.log(correos)


            try {
                let response = await axios.post('Correos/PostCorreos/' + this.empresa.empresaBase + '/' + this.empresa.idusuariosApp, correos)
                console.log(response.data)
                this.dialogCorreo = false;
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Correo enviado con éxito', life: 5000 });

            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al enviar:' + error.response.data, life: 5000 });
                console.log(error)
                this.loading = false;
            }
        },

        async getCorreo() {
            if (this.item.timbreFiscalDigital.uuid != '') {
                this.correo.asunto = 'Se envía factura correspondiente'
            } else {
                this.correo.asunto = 'Se envía documento'
            }
            this.loadingMensaje = 'Espere...'
            this.loading = true
            let idCliente = this.item.cliente.idCliente
            try {
                let response = await axios.get('Correos/GetCorreoContacto/' + this.empresa.empresaBase + '/' + idCliente);
                console.log(response)
                let correos = response.data;
                for (let c of correos) {
                    this.destinatarios.push(c)
                }
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },

        pushCorreo() {
            if (this.nuevoDestinatario.correo === '') {
                this.$toast.add({ severity: 'error', detail: 'Error al agregar destinatario, indique un correo', life: 5000 });
                return
            }
            let nuevo = { ...this.nuevoDestinatario }
            this.destinatarios.push(nuevo)
            let inicializa = {
                nombre: '',
                correo: ''
            }
            this.nuevoDestinatario = { ...inicializa }
            this.$refs.nombreF.$el.focus();
        },

        async descargaComprobanteXml() {
            let archivo = this.item.timbreFiscalDigital.archivoXml;
            let nombreA = 'CPIngreso_' + this.item.cliente.rfc + '_' + this.item.serie + "_" + this.item.folio + "_" + this.item.folioFiscal + '_' + this.item.subTotal
            this.download(archivo, nombreA, 'xml');
        },

        download(archivo, nombre, tipo) {
            var arrBuffer = this.base64ToArrayBuffer(archivo);
            var newBlob = new Blob([arrBuffer]);

            var data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = data;
            link.download = nombre + '.' + tipo;
            link.click();
            window.URL.revokeObjectURL(data);
            link.remove();
        },

        base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        },
    },
};
</script>
<style>
.col {
    background-color: white;
}

.grid {
    background-color: white;
}
</style>