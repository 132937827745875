<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Figura de Transporte
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- TIPO FIGURA DE TRANSPORTE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.tipoFigura" :options="itemsTipoFigura"
                                optionLabel="figuraTransporte">
                            </Dropdown>
                            <label>Tipo de Figura</label>
                        </span>
                    </div>

                    <!-- RFC DE LA FIGURA-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="13" v-model="item.rfcFigura" />
                            <label>RFC de la Figura de Tranporte</label>
                        </span>
                        <small>En este campo se deberá registrar la clave en el RFC de la figura de transporte que
                            interviene en el traslado de bienes y/o mercancías. Si la figura de transporte que
                            interviene en el traslado de bienes y/o mercancías es un residente en el extranjero, este
                            campo se debe omitir.</small>
                    </div>

                    <!-- RFC DEL REMITENTE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="item.nombreFigura" />
                            <label>Nombre de la Figura</label>
                        </span>
                    </div>
                    <!-- NUMERO DE LICENCIA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="16" v-model="item.numLicencia" />
                            <label>Número de la Licencia</label>
                        </span>
                        <small>En este campo se deberá registrar el número de la licencia de conducir o permiso otorgado
                            al operador de la unidad en la que se está realizando el traslado de bienes y/o mercancías
                            vía autotransporte.</small>
                    </div>
                    <!-- NUMERO DE IDENTIFICACION -->
                    <div class="justify-right field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText v-model="item.numRegIdTribFigura" />
                            <label>Número de identificación o registro fiscal</label>
                        </span>
                        <small>En este campo se podrá registrar el número de identificación fiscal que corresponde al
                            tipo de la figura de transporte.</small>
                    </div>
                    <!-- RESIDENCIA FISCAL -->
                    <div class="justify-right field col-6">
                        <span class="p-float-label">
                            <Dropdown v-model="item.residenciaFiscalFigura" :options="itemsPais" optionLabel="pais">
                            </Dropdown>
                            <label>Residencia Fiscal</label>
                        </span>
                        <small>En este campo podrás registrar la clave del país que corresponde al tipo de figura de
                            transporte.”</small>
                    </div>
                    <!-- PARTE DEL TRANSPORTE -->
                    <template v-if="item.tipoFigura.clave === '02' || item.tipoFigura.clave === '03'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="item.partesDelTransporte" :options="itemsParteTransporte"
                                    optionLabel="parteTransporte">
                                </Dropdown>
                                <label>Tipo de Figura</label>
                            </span>
                        </div>
                    </template>
                    <!-- DOMICILIO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.domicilio" :options="itemsDirecciones" optionLabel="domicilio">
                            </Dropdown>
                            <label>Domicilio</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',


            editedIndex: -1,
            itemsTipoFigura: [],
            itemsParteTransporte: [],

            itemsPais: [],
        }
    },
    computed: {
        itemsDirecciones() {
            return this.$store.state.listDireccionStore;
        },
        item() {
            return this.$store.state.figuraTransporteStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getDirecciones();
        this.GetFiguraTransporte();
        this.GetParteTransporte();
        this.listarPaises();
    },
    methods: {
        guardarOactualizar() {
            if (this.$store.state.figuraTransporteStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Figura, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostFiguras/' + this.empresa.empresaBase, this.item)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Figura guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listFigurasTransporteStore.push(this.item)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Figura, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateFiguras/' + this.empresa.empresaBase, this.item)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listFigurasTransporteStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listFigurasTransporteStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Figura actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },
        async getDirecciones() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetDirecciones/' + this.empresa.empresaBase)
                this.$store.state.listDireccionStore = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        async GetFiguraTransporte() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetFiguraTransporte');
                this.itemsTipoFigura = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetParteTransporte() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetParteTransporte');
                this.itemsParteTransporte = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async listarPaises() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetPaises');
                this.itemsPais = response.data
            } catch (error) {
                console.log(error)
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>