<template>
    <div>
        <Card>
            <template #content>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- SERIE -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <Dropdown v-model="ventaCotizacion.serie" :options="itemsSerie" :editable="true" />
                            <label>Serie</label>
                        </span>
                    </div>
                    <!-- FOLIO -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.folio" type="text" />
                            <label>Folio</label>
                        </span>
                    </div>
                    <!-- FECHA -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <Calendar v-model="ventaCotizacion.fecha" dateFormat="dd-MM-yy" />
                            <label>Fecha</label>
                        </span>
                    </div>
                    <!-- VIGENCIA -->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <Calendar v-model="ventaCotizacion.vigencia" dateFormat="dd-MM-yy" />
                            <label>Vigencia</label>
                        </span>
                    </div>
                    <!-- CLIENTE -->
                    <div class=" field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <AutoComplete style="text-align: left" v-model="ventaCotizacion.cliente"
                                    :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                                </AutoComplete>
                                <label>Cliente</label>
                            </span>
                        </div>
                    </div>
                    <!-- ATN -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.atn" type="text" />
                            <label>Atn</label>
                        </span>
                    </div>
                    <!-- EMAIL -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.email" type="text" />
                            <label>Email</label>
                        </span>
                    </div>
                    <!-- MONEDA -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="ventaCotizacion.moneda" :options="itemsMoneda"
                                optionLabel="moneda" />
                            <label>Moneda</label>
                        </span>
                    </div>
                    <!-- PROYECTO -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="ventaCotizacion.proyecto" optionLabel="descripcion" :options="itemsProyectos"
                                :showClear="true" />
                            <label>Proyecto</label>
                        </span>
                    </div>
                    <!-- TIEMPO DE ENTREGA -->
                    <div class=" field col-12">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.tiempoEntrega" type="text" />
                            <label>Tiempo de Entrega</label>
                        </span>
                    </div>
                    <!-- NOTAS -->
                    <div class=" field col-12">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.notas" type="text" />
                            <label>Observaciones</label>
                        </span>
                    </div>
                    <!-- CONDICIONES DE PAGO -->
                    <div class=" field col-12 md:col-12">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.condicionesPago" type="text" />
                            <label>Condiciones de Pago</label>
                        </span>
                    </div>
                    <!-- CONDICIONES DE ENTREGA -->
                    <div class=" field col-12 md:col-12">
                        <span class="p-float-label">
                            <InputText v-model="ventaCotizacion.condicionesEntrega" type="text" />
                            <label>Condiciones de Entrega</label>
                        </span>
                    </div>
                    <!-- IMAGEN GENERAL -->
                    <div class="field col-12 md:col-12">
                        <div class="field-checkbox text-right">
                            <InputSwitch class="mr-2" id="city1" v-model="validarImagenGeneral" />
                            <h1 style=";  font-size: 15px; font-family: arial;display:inline;">
                                IMAGEN GENERAL
                            </h1>
                        </div>
                    </div>
                    <template v-if="validarImagenGeneral == true">
                        <div class="field col-6 md:col-6">
                            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadImage" accept="image/*"
                                :maxFileSize="5242880">
                                <template #empty>
                                    <p>Arrastre y suelte para cargar</p>
                                </template>
                            </FileUpload>
                        </div>
                        <div class="field col-6 md:col-6">
                            <ImagePreview :src="ventaCotizacion.imagenGeneral.base64" alt="Image" height="200" preview />
                        </div>
                        <div class="fiel justify-right col-12 md:col-12">
                            <div class="justify-right text-right">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    @click="eliminarImagen()"></Button>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import Clientes from "../Clientes/Clientes.vue";
import Proyecto from "../Proyectos/Proyecto.vue"

export default {
    components: { Clientes, Proyecto },

    data() {
        return {
            filteredCliente: null,
            filteredProyecto: null,
            validarImagenGeneral: false
        }
    },
    watch: {

    },
    computed: {

        itemsProyectos() {
            try {
                return this.$store.state.listProyectosStore.filter(proyecto => proyecto.rfc === this.ventaCotizacion.cliente.rfc);
            } catch (error) {
                console.log(error);
            }
        },
        itemsSerie() {
            return this.$store.state.listVentaCotizacionSerieStore;
        },
        ventaCotizacion() {
            return this.$store.state.ventaCotizacionStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
    },
    created() {
        this.validarImagen()
    },
    methods: {
        validarImagen() {
            if (this.$store.state.ventaCotizacionStore.imagenGeneral.base64 == '') {
                this.validarImagenGeneral = false
            } else {
                this.validarImagenGeneral = true
            }
        },
        async uploadImage(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.ventaCotizacionStore.imagenGeneral.base64 = result;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.$store.state.listClienteStore];
                }
                else {
                    this.filteredCliente = this.$store.state.listClienteStore.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchProyecto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProyecto = [...this.itemsProyectos];
                }
                else {
                    this.filteredProyecto = this.itemsProyectos.filter((country) => {
                        return country.descripcion.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        eliminarImagen() {
            this.$store.state.ventaCotizacionStore.imagenGeneral.base64 = ''
        }
    },
}
</script>