<template>
  <div id="app">
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>

    <div>
      <Toolbar id="border" class="pl-0 pt-2 pr-0 border" v-if="logueado" style="background: #F46722;">
        <template #start>
          <i @click="visibleLeft = true" style="font-size: 25px; cursor: pointer" class="ml-4 mr-4 pi pi-bars"></i>
          <h4>{{ $store.state.usuario.empresa }}</h4>
        </template>
        <template #end>
          <h4>{{ $store.state.usuario.rfc }}</h4>
          <!-- <Button icon="pi pi-moon" :label="darkMode ? 'Modo claro' : 'Modo oscuro'" @click="toggleDarkMode()" /> -->
          <div type="button" @click="toggle">
            <Avatar style="cursor: pointer" type="button" icon="pi pi-user" class="ml-5 mr-5" size="large" shape="circle"
              aria-haspopup="true" aria-controls="overlay_tmenu" />
          </div>
          <TieredMenu id="overlay_tmenu" ref="menu" :model="itemsSalir" :popup="true" />
        </template>
      </Toolbar>
      <Sidebar :visible.sync="visibleLeft" :showCloseIcon="false" :baseZIndex="1000">
        <div @click="inicio()" style="cursor: pointer">
          <img src="../src/assets/logo_contago_erp.png" height="70" />
        </div>
        <ProgressBar :value="100" style="height: 0.3em" />
        <PanelMenu :model="items" />
      </Sidebar>
    </div>
    <!-- <MegaMenu v-if="logueado" :model="items" /> -->

    <router-view />
    <div id="footer" class="footer"></div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      darkMode: false,
      loading: false,
      loadingMensaje: "",
      nombreUsuario: "",
      visibleLeft: false,
      itemsSalir: [
        {
          label: "Configuración",
          icon: "pi pi-fw pi-cog",
          command: (event) => {
            this.configuracion();
          },
        },
        {
          separator: true,
        },
        {
          label: "Salir",
          icon: "pi pi-fw pi-power-off",
          command: (event) => {
            this.salir();
          },
        },
      ],

      items: [
        {
          label: "Facturación",
          icon: "mdi mdi-file-sign",
          items: [
            {
              label: "Factura",
              icon: "mdi mdi-file-edit",
              to: "/ListaFacturas",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Arrendamiento",
              icon: "mdi mdi-file-move-outline",
              to: "/ListaArrendamientos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Nota de Crédito",
              icon: "mdi mdi-file-document-outline",
              to: "/ListaNotaCredito",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Complementos de Pago",
              icon: "mdi mdi-file-document-check-outline",
              to: "/ListaComplementoPago",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Ingreso Carta Porte",
              icon: "mdi mdi-car-arrow-left",
              to: "/ListaIngresoCartaPorte",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Traslado Carta Porte",
              icon: "mdi mdi-car-arrow-right",
              to: "/ListaTrasladoCartaPorte",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Comercio Exterior",
              icon: "mdi mdi-file-replace-outline",
              to: "/ListaComercioExterior",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Factura GAS",
              icon: "mdi mdi-gas-station-outline",
              to: "/ListaFacturaGas",
              command: () => {
                this.visibleLeft = false;
              },
            },
          ],
        },
        {
          label: "Ventas",
          icon: "mdi mdi-cart-arrow-up",
          items: [
            {
              label: "Cotización",
              icon: "mdi mdi-clipboard-text-clock-outline",
              to: "/ListaVentasCotizacion",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Pedidos",
              icon: "mdi mdi-order-bool-ascending-variant",
              to: "/ListaVentasPedidos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Remisión",
              icon: "mdi mdi-file-document-check",
              to: "/ListaVentasRemision",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Protocolos",
              icon: "mdi mdi-check-circle",
              to: "/ListaProtocolos",
              command: () => {
                this.visibleLeft = false;
              },
            },
          ],
        },
        {
          label: "Compras",
          icon: "mdi mdi-cart-arrow-down",
          items: [
            {
              label: "Pedidos",
              icon: "mdi mdi-order-bool-ascending-variant",
              to: "/ListaPedidos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Cotización",
              icon: "mdi mdi-clipboard-text-clock-outline",
              to: "/ListaCotizaciones",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Orden de Compras",
              icon: "mdi mdi-dolly",
              to: "/ListaOrdenCompras",
              command: () => {
                this.visibleLeft = false;
              },
            },
          ]
        },
        {
          label: "Inventarios",
          icon: "mdi mdi-package-variant-closed",
          items: [
            {
              label: "Almacén",
              icon: "mdi mdi-package-variant",
              items: [
                {
                  label: "Inventario",
                  icon: "mdi mdi-clipboard-check",
                  to: "/ListaInventarioAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Entradas / Salidas",
                  icon: "mdi mdi-swap-horizontal",
                  to: "/ListaESAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Préstamos / Devoluciones",
                  icon: "mdi mdi-arrow-left-right",
                  to: "/ListaInventarioAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Traspasos",
                  icon: "mdi mdi-cube-send",
                  to: "/ListaInventarioAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Mermas",
                  icon: "mdi mdi-trash-can-outline",
                  to: "/ListaInventarioAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Reparaciones",
                  icon: "mdi mdi-wrench-outline",
                  to: "/ListaInventarioAlmacen",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
              ],
            },
            // {
            //   label: "Proyectos",
            //   icon: "mdi mdi-folder",
            //   to: "/ListaInventarioProyecto",
            //   command: () => {
            //     this.visibleLeft = false;
            //   },
            // },
          ]
        },
        {
          label: "Catálogos",
          icon: "mdi mdi-notebook-plus",
          items: [
            {
              label: "Articulos",
              icon: "mdi mdi-clipboard-list-outline",
              to: "/ListaArticulos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Conceptos",
              icon: "mdi mdi-clipboard-list-outline",
              to: "/ListaConceptos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Clientes",
              icon: "mdi mdi-account-supervisor",
              to: "/ListaClientes",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Proveedores",
              icon: "mdi mdi-human-dolly",
              to: "/ListaProveedores",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Almacenes",
              icon: "pi pi-fw pi-file",
              to: "/ListaAlmacenes",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Usuarios",
              icon: "mdi mdi-clipboard-account",
              to: "/ListaUsuarios",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Proyectos",
              icon: "mdi mdi-folder",
              to: "/ListaProyectos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Bancos",
              icon: "mdi mdi-bank",
              to: "/ListaBancos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Direcciones",
              icon: "mdi mdi-map-marker-multiple",
              to: "/ListaDirecciones",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Carta Porte",
              icon: "mdi mdi-truck",
              items: [
                {
                  label: "Vehículo",
                  icon: "mdi mdi-car-multiple",
                  to: "/ListaVehiculo",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Remolques",
                  icon: "mdi mdi-truck-trailer",
                  to: "/ListaRemolques",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Permiso SCT",
                  icon: "mdi mdi-card-bulleted",
                  to: "/ListaPermisosSCT",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Seguro",
                  icon: "mdi mdi-shield-car",
                  to: "/ListaSeguros",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Mercancía",
                  icon: "mdi mdi-dolly",
                  to: "/ListaMercancia",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Ubicación de Origen",
                  icon: "mdi mdi-home-map-marker",
                  to: "/ListaOrigenes",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Ubicación de Destino",
                  icon: "mdi mdi-map-marker-radius",
                  to: "/ListaDestinos",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },

                {
                  label: "Figuras de transporte",
                  icon: "mdi mdi-card-account-details",
                  to: "/ListaFiguras",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Autotransporte",
                  icon: "mdi mdi-car-cog",
                  to: "/ListaAutotransporte",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
              ],
            },
            {
              label: "Comercio Exterior",
              icon: "mdi mdi-cash-refund",
              items: [
                {
                  label: "Emisor",
                  icon: "mdi mdi-account-arrow-right",
                  to: "/ListaComercioExteriorEmisor",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Propietario",
                  icon: "mdi mdi-account-star",
                  to: "/ListaComercioExteriorEPropietario",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Receptor",
                  icon: "mdi mdi-account-arrow-left",
                  to: "/ListaComercioExteriorReceptor",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Destinatario",
                  icon: "mdi mdi-map-marker-account",
                  to: "/ListaComercioExteriorDestinatario",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Mercancías",
                  icon: "mdi mdi-mdi mdi-dolly",
                  to: "/ListaComercioExteriorMercancia",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
              ],
            },
            {
              label: "Catálogo VW",
              icon: "mdi mdi-clipboard-list",
              items: [
                {
                  label: "Proveedor",
                  icon: "mdi mdi-human-dolly",
                  to: "/ListaProveedorVw",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
                {
                  label: "Solicitantes",
                  icon: "mdi mdi-account-check",
                  to: "/ListaSolicitanteVw",
                  command: () => {
                    this.visibleLeft = false;
                  },
                },
              ],
            },

          ],
        },

        {
          label: "Punto de Venta",
          icon: "mdi mdi-cash-register",
          items: [
            {
              label: "Punto de Venta",
              icon: "mdi mdi-cash-register",
              to: "/PuntoVenta",
              command: () => {
                this.visibleLeft = false;
                // this.usuarioVentas.idUsuarios = 0
              },
            },
            // {
            //   label: "Historial",
            //   icon: "pi pi-fw pi-file",
            //   to: "/ListaVentas",
            //   command: () => {
            //     this.visibleLeft = false;
            //     // this.usuarioVentas.idUsuarios = 0
            //   },
            // },
            {
              label: "Turnos",
              icon: "mdi mdi-account-clock",
              to: "/ListaTurnos",
              command: () => {
                this.visibleLeft = false;
                // this.usuarioVentas.idUsuarios = 0
              },
            },
          ]
        },

        {
          label: "Movimientos Bancos",
          icon: "mdi mdi-bank-transfer",
          items: [
            {
              label: "Depósitos",
              icon: "mdi mdi-bank-transfer-in",
              to: "/ListaDepositos",
              command: () => {
                this.visibleLeft = false;
              },
            },
            {
              label: "Retiros",
              icon: "mdi mdi-bank-transfer-out",
              to: "/ListaRetiros",
              command: () => {
                this.visibleLeft = false;
              },
            },
          ],
        },

      ],
    };
  },
  computed: {
    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },

    esAdministrador() {
      return (
        this.$store.state.usuario &&
        this.$store.state.usuario.rol == "Administrador"
      );
    },
    Usuario() {
      return this.$store.state.usuario.rol;
    },
    usuarioVentas() {
      return this.$store.state.usuarioVentasStore
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
    this.$store.dispatch("recuperarToken");
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    inicio() {
      this.$router.push({ name: "Home" }), (this.visibleLeft = false);
      // var intro = document.getElementById('footer');
      // intro.style.backgroundColor = '#1F0381';

      // var border = document.getElementById('border');
      // border.style.backgroundColor = '#1F0381';
    },

    configuracion() {
      this.$router.push({ name: "Configuracion" });
      let empresa = {
        logo: {
          extencion: '',
          base64: '',
        },
        idEmpresa: 0,
        rfc: '',
        domicilioFiscal: '',
        nombre: '',
        regimenFiscal: {}
      }
      this.$store.state.empresaStore = { ...empresa }

    },
    async salir() {
      this.loading = true;
      this.loadingMensaje = "Cerrando Sesión...";
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
          this.$store.state.usuario.nombre +
          "/INACTIVA"
        );
        this.$store.dispatch("salir");

        //INICIALIZAMOS LOS CATALOGOS
        let correo = {
          asunto: '',
          mensaje: '',
          emisor: {},
          archivos: [],
          receptores: [],
        }
        let empresa = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {}
        }
        let cancelacion = {}

        this.$store.state.listTrasladoCPSerieStore = []
        this.$store.state.listTrasladoCartaPorteStore = []
        this.$store.state.listCEMercanciaStore = []
        this.$store.state.listCEDestinatarioStore = []
        this.$store.state.listCEReceptorStore = []
        this.$store.state.listCEPropietarioStore = []
        this.$store.state.listCEEmisorStore = []
        this.$store.state.listIngresoCPSerieStore = []
        this.$store.state.listIngresoCartaPorteStore = []
        this.$store.state.listAutotransporteStore = []
        this.$store.state.listFigurasTransporteStore = []
        this.$store.state.listDireccionStore = []
        this.$store.state.listOrigenStore = []
        this.$store.state.listDestinoStore = []
        this.$store.state.listMercanciaStore = []
        this.$store.state.listSeguroStore = []
        this.$store.state.listPermisoSCTStore = []
        this.$store.state.listRemolqueStore = []
        this.$store.state.listVehiculoStore = []
        this.$store.state.listVentaCotizacionStore = []
        this.$store.state.listComercioExteriorStore = []
        this.$store.state.listComplementoPagoStore = []
        this.$store.state.listArrendamientoStore = []
        this.$store.state.listArrendamientoSerieStore = []
        this.$store.state.listFacturaStore = []
        this.$store.state.listFacturaSerieStore = []
        this.$store.state.listProyectosStore = []
        this.$store.state.listVentaPedidoStore = []
        this.$store.state.listVentaPedidoSerieStore = []
        this.$store.state.listaUsuariosInternosStore = []
        this.$store.state.listaAlmacenesStore = []
        this.$store.state.listaConceptosStore = []
        this.$store.state.listaLineaStore = []
        this.$store.state.listaMarcaStore = []
        this.$store.state.listaSubmarcaStore = []

        this.$store.state.correoStore = { ...correo }
        this.$store.state.cancelacionStore = { ...cancelacion }
        this.$store.state.empresaStore = { ...empresa }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    toggleDarkMode() {
      console.log('aqui')
      const app = document.querySelector('#app');
      if (app.classList.contains('p-dark')) {
        app.classList.remove('p-dark');
      } else {
        app.classList.add('p-dark');
      }
    }
  },
};
</script>
<style>
.p-sidebar .p-sidebar-header {
  padding: 0rem;
}

.p-sidebar .p-sidebar-content {
  padding: 0rem;
}

.p-panelmenu .p-panelmenu-header>a {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
  border-color: transparent;
  background: #BBDEFB;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #E3F2FD;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header>a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover>a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight)>a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.border {
  /* border-top: 3px; */
  background-color: #f46722;
  height: 60px;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 20px;
  width: 100%;
  background: #f46722;
}

.p-toolbar {
  padding: 0.5rem;
  border-radius: 0px;
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* border: 1px solid #e5e5e5; */
  border-radius: 4px;
  /* width: 12.5rem; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.p-tieredmenu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding: 0.75rem 1.25rem;
  color: #000000;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-text {
  line-height: 1;
  padding-left: 10px;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 25px;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
