<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold  border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>

        <!-- DIALOG DEL PDF -->
        <Dialog :showHeader="false" :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <PdfPedido @cierraDialogPdf="closeDialogPdf"></PdfPedido>
        </Dialog>

        <Card class="shadow-none">
            <!-- TITULO CARD -->
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                    </div>
                    <div class="text-center field col-4">
                        Pedido
                    </div>
                    <div class="text-right field col-4">
                        <template v-if="pedido.idCompra === 0">
                            <Button icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined"
                                @click="postPedido()" />
                        </template>
                        <template v-if="pedido.idCompra != 0">
                            <Button icon="pi pi-save" class="p-button-rounded p-button-warning p-button-outlined"
                                @click="editarPedido()" />
                        </template>
                        <Button icon="pi pi-file-pdf" class="ml-3 mr-1 p-button-rounded p-button-danger p-button-outlined"
                            @click="vistaPrevia()" />
                    </div>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <TabView>
                    <TabPanel header="Datos generales">
                        <General></General>
                    </TabPanel>
                    <TabPanel header="Conceptos">
                        <Conceptos></Conceptos>
                    </TabPanel>
                </TabView>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import General from './PedidoGeneral';
import Conceptos from './PedidoConceptos'
import PdfPedido from "../../Pdf/CompraPedidoPdf.vue";

export default {
    components: { General, Conceptos, PdfPedido },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            datosEmpresa: null,
            dialogPdf: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        pedido() {
            return this.$store.state.comprasPedidoStore
        },
    },
    created() {
        this.Inicializa();
    },
    methods: {
        //INICIALES
        async Inicializa() {
            this.loading = true;
            await this.getProveedores()
            await this.GetProyectos()
            await this.GetConceptos()
            await this.GetEmpresa()
            this.loading = false;
        },

        cierraVentana() {
            this.$store.state.comprasPedidoStore.idCompra = -1;
            this.$emit('closePedido')
        },
        async postPedido() {
            try {
                if (this.pedido.solicitante == '') {
                    this.$toast.add({ severity: 'error', summary: 'Error de Guardado.', detail: 'Indique el usuario de quien lo solicita...', life: 5000 });
                    return;
                }

                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                let objPedido = { ...this.pedido }
                objPedido.empresa = this.datosEmpresa
                //objPedido.rfc = objPedido.proveedor.rfc
                //objPedido.nombre = objPedido.proveedor.nombre

                objPedido.usuarioCreador = this.token.nombre

                let response = await axios.post('Compras/PostComprasPedido/' + this.token.empresaBase, objPedido)
                console.log(response.data)
                objPedido.idCompra = response.data.id;
                objPedido.folio = response.data.folio;
                this.$store.state.listaComprasPedidoStore.push(objPedido)

                let array = this.$store.state.listaComprasPedidoStore;
                this.$store.state.listaComprasPedidoStore = array.sort(((a, b) => b.idCompra - a.idCompra))

                this.$store.state.comprasPedidoStore.idCompra = -1;

                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Pedido guardado con éxito', life: 3000 });
                this.dialogPdf = true;
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },
        async editarPedido() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                this.pedido.empresa = this.datosEmpresa
                //this.pedido.rfc = this.pedido.proveedor.rfc
                //this.pedido.nombre = this.pedido.proveedor.nombre

                this.pedido.usuarioModificador = this.token.nombre

                let response = await axios.post('Compras/UpdateComprasPedidos/' + this.token.empresaBase, this.pedido)
                console.log(response.data)

                let indice = this.$store.state.listaComprasPedidoStore.findIndex(x => x.idCompra === this.pedido.idCompra);
                Object.assign(this.$store.state.listaComprasPedidoStore[indice], this.pedido)

                this.loading = false;
                this.cierraVentana();
                this.$toast.add({ severity: 'success', detail: 'Pedido guardado con éxito', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },
        vistaPrevia() {
            this.dialogPdf = true;
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        async getProveedores() {
            this.loadingMensaje = 'Consultando Proveedores'
            try {
                let response = await axios.get('Proveedores/GetProveedores/' + this.token.empresaBase);
                this.$store.state.listaProveedorStore = response.data
            } catch (error) {
                console.log(error);
            }
        },
        async GetProyectos() {
            this.loadingMensaje = 'Consultando Proyectos';
            try {
                let response = await axios.get('Proyectos/GetProyectos/' + this.token.empresaBase)
                this.$store.state.listProyectosStore = response.data
            } catch (error) {
                this.loadingItemsProyectos = false;
            }
        },
        async GetConceptos() {
            try {
                this.loadingMensaje = 'Consultando Conceptos';
                let response = await axios.get('Conceptos/GetConceptos/' + this.token.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetEmpresa() {
            this.loadingMensaje = 'Consultando datos de la empresa'
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>