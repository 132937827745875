<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Comprobantes
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>

                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="field col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" type="text" v-model="itemsMovimientoComp.cliente.nombre" disabled />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- RFC -->
                    <div class="field col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="rfc" type="text" v-model="itemsMovimientoComp.cliente.rfc" disabled />
                                <label for="rfc">RFC</label>
                            </span>
                        </span>
                    </div>
                    <!-- FECHA -->
                    <div class="col-3 md:col-4 text-left">
                        <span class="p-float-label">
                            <Calendar v-model="itemsMovimientoComp.fechaMovimiento" dateFormat="dd-MM-yy" disabled />
                            <label>Fecha Movimiento</label>
                        </span>
                    </div>
                </div>
                <DataTable class="p-datatable-sm" removableSort showGridlines ref="comprobantes"
                    :value="itemsMovimientoComp.comprobantes" responsiveLayout="scroll" :paginator="true" :rows="10"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Datos."
                    :loading="loadingItemsComprobantes">

                    <Column sortable header="Folio Fiscal" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.folioFiscal }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column field="monto" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column sortable header="Metodo de Pago" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.formaPago.descripcion }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable header="Moneda" :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.moneda.clave }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            loadingItemsComprobantes: false,
        };
    },
    computed: {
        itemsMovimientoComp() {
            return this.$store.state.listComprobantesMov;
        },
    },
    created() {

    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        cierraVentana() {
            this.$emit('closeComprobantesMov')
        },

    },
};
</script>