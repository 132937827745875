<template>
  <div>
    <!-- DIALOGO PARA ERRORES -->
    <div class="p-d-flex">
      <Toast />
    </div>
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>
    <!-- DIALOG PARA CANCELAR CFDI -->
    <Dialog :visible.sync="dialogCancelacion" :style="{ width: '95vw' }" :closable="false">
      <Cancelacion @cerrarVentanaCancelacion="dialogCancelacion = false"></Cancelacion>
    </Dialog>
    <!-- NUEVA FACTURA -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogFactura" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <factura @closeFactura="closeDialogFactura"></factura>
    </Dialog>
    <!-- DIALOG DEL PDF -->
    <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <!-- <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf> -->
      <ComprobantePdfG @cierraDialogPdf="closeDialogPdf"></ComprobantePdfG>
    </Dialog>
    <!-- DIALOG DE LA ADDENDA VW PSV -->
    <Dialog :visible.sync="dialogVwPsv" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <vw-psv @cierraDialogVwPsv="closeDialogAddendaVWPSV"></vw-psv>
    </Dialog>
    <!-- DIALOG PARA EDITAR LAS NOTAS -->
    <Dialog :visible.sync="dialogNotasOc" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <Card class="shadow-none">
        <template #title>
          <div class="p-d-flex p-fluid grid formgrid">
            <div class="text-left field col-3">
              <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="closeDialogNotasOc()" />
            </div>
            Notas / Orden compra
            <div class="text-right field col-5">
              <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                v-tooltip.bottom="'Generar'" @click="PutNotasOC" />
            </div>
          </div>
          <hr size=7 color="#ED6421">
        </template>
        <template #content>
          <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <div class="field col-12">
              <span>{{ selectedComprobante.serie }} {{ selectedComprobante.folio }}</span>
              <br>
              <span>{{ selectedComprobante.folioFiscal }}</span>
            </div>
            <div class="field col-12">
              <span class="p-float-label">
                <InputText type="text" v-model="notas" />
                <label for="username">Notas</label>
              </span>
            </div>
            <div class="field col-12">
              <span class="p-float-label">
                <InputText type="text" v-model="ordenCompra" />
                <label for="username">Orden compra</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </Dialog>
    <!-- DIALOG PARA REGISTRAR QUE SE SUBIO A PORTAL -->
    <Dialog :visible.sync="dialogPortal" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <Card class="shadow-none">
        <template #title>
          <div class="p-d-flex p-fluid grid formgrid">
            <div class="text-left field col-4">
              <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="closeDialogPortal()" />
            </div>
            Portal
            <div class="text-right field col-6">
              <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                v-tooltip.bottom="'Registrar'" @click="PostPortal" />
            </div>
          </div>
          <hr size=7 color="#ED6421">
        </template>
        <template #content>
          <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <div class="field col-12">
              <span>{{ selectedComprobante.serie }} {{ selectedComprobante.folio }}</span>
              <br>
              <span>{{ selectedComprobante.folioFiscal }}</span>
            </div>
            <div class="field col-12">
              <span class="p-float-label">
                <Calendar v-model="fechaPortal" dateFormat="dd-MM-yy" />
                <label>Fecha</label>
              </span>
            </div>
            <div class="field col-12">
              <span class="p-float-label">
                <InputText type="text" v-model="notas" />
                <label>Notas</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </Dialog>
    <!-- DIALOG PARA VER EL HISTORIAL DEL PORTAL -->
    <Dialog :visible.sync="dialogPortalHistorial" :style="{ width: '850px' }" :closable="false" :modal="true"
      position="top">
      <Card class="shadow-none">
        <template #title>
          <div class="p-d-flex p-fluid grid formgrid">
            <div class="text-left field col-5">
              <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                @click="dialogPortalHistorial = false" />
            </div>
            Portal
          </div>
          <hr size=7 color="#ED6421">
        </template>
        <template #content>
          <DataTable class="mt-1 p-datatable-sm" :value="itemsPortalHistorial" :scrollable="true" scrollHeight="500px"
            scrollDirection="both"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            :paginator="true" :rows="10">
            <Column field="fecha" header="Fecha"
              :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
              <template #body="slotProps">
                <span>{{ formatDate(slotProps.data.fecha) }}</span>
              </template>
            </Column>
            <Column field="notas" header="Notas"
              :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
            </Column>
            <Column field="usuario" header="Usuario"
              :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
            </Column>
          </DataTable>
        </template>
      </Card>
      <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
      </div>
    </Dialog>
    <!-- DIALOG PARA LOS COMPROBANTES -->
    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <!-- TITULO -->
          <div class="col-5 text-left pa-0 ma-0 pl-3">
            <span style="cursor:pointer" @click="getTimbres()">Factura </span>
            <ProgressSpinner v-if="datosGenerales == 1" style="width:36px;height:36px;  vertical-align: middle;"
              strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
            <Badge v-if="datosGenerales == 0" :value="timbresDisponibles" @click="getTimbres()" class="mr-2" size="large"
              severity="success">
            </Badge>
          </div>
          <!-- FECHAS -->
          <div class="col-6 text-right">
            <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
              <div class="col-12 md:col-6 text-left">
                <span class="p-float-label">
                  <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                  <label>Fecha Inicial</label>
                </span>
              </div>
              <div class="col-12 md:col-6 text-left">
                <span class="p-float-label">
                  <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                  <label>Fecha Final</label>
                </span>
              </div>
            </div>
          </div>
          <!-- BOTONES -->
          <div class="col-1 text-right">
            <Button icon="pi pi-plus" @click="nuevaFactura()" class="mx-2 p-button-rounded" />
            <Button icon="pi pi-undo" @click="getFactura()" class="p-button-rounded" />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable :filters.sync="filtrosFact" class="mt-1 p-datatable-sm" :value="itemsFacturas" :scrollable="true"
          scrollHeight="800px" scrollDirection="both" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Facturas." :paginator="true" :rows="10"
          contextMenu :contextMenuSelection.sync="selectedComprobante" @row-contextmenu="onRowContextMenu">
          <template #header>
            <div class="flex justify-content-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filtrosFact['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>
          <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
            <template #body="slotProps">

              <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100" v-tooltip.bottom="'PDF'"
                @click="nuevoPdf(slotProps.data)" />

              <!-- <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100" v-tooltip.bottom="'PDF'"
                @click="openDialogPdf(slotProps.data)" /> -->

              <Button icon="pi pi-replay" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Similar'"
                @click="facturasimilar(slotProps.data)" />
              <div v-if="slotProps.data.estatus === 'Sin Timbrar'">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info" v-tooltip.bottom="'Editar'"
                  @click="editarFactura(slotProps.data)" />
              </div>
              <div v-if="slotProps.data.estatus != 'Sin Timbrar'">
                <Button icon="pi pi-check-circle" style="background:green" class="p-button-rounded "
                  v-tooltip.bottom="'Actualizar Estatus SAT'" @click="actualizarEstatus(slotProps.data)"></Button>
              </div>
              <div v-if="slotProps.data.estatus === 'Vigente'">
                <Button v-if="slotProps.data.estatusCancelacion != 'EnProceso'" icon="pi pi-ban"
                  class="p-button-rounded p-button-danger" v-tooltip.bottom="'Cancelar'"
                  @click="openCancelacion(slotProps.data)"></Button>
              </div>
            </template>
          </Column>
          <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
          </Column>
          <Column field="folio" header="Folio"
            :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'text-align': 'center' }">
            <template #body="slotProps">
              <span :class="'customer-badge status-' + slotProps.data.estatus + slotProps.data.estatusCancelacion">{{
                slotProps.data.folio
              }}</span>
            </template>
          </Column>
          <Column field="fecha" header="Fecha" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
            <template #body="slotProps">
              <span>{{ formatDate(slotProps.data.fecha) }}</span>
            </template>
          </Column>
          <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="nombre" header="Nombre"
            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
          </Column>
          <Column field="subTotal" header="SubTotal"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
            <template #body="{ data }">
              <span>{{ formatCurrency(data.subTotal) }}</span>
            </template>
          </Column>
          <Column field="total" header="Total"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
            <template #body="{ data }">
              <span>{{ formatCurrency(data.total) }}</span>
            </template>
          </Column>
          <Column field="timbreFiscalDigital.folioFiscal" header="Folio fiscal"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="formaPago.formaPago" header="Forma de Pago"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="metodoPago.metodoPago" header="Método de Pago"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column field="estatusCancelacion" header="Solicitud de Cancelación"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'overflow-wrap': 'break-word' }">
          </Column>
          <Column field="ordenCompra" header="OC"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
          </Column>
          <Column header="Portal" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', }">
            <template #body="slotProps">
              <div v-if="slotProps.data.portales.length != 0">
                <Button icon="pi pi-search" class="p-button-rounded p-button-success" v-tooltip.bottom="'Ver historial'"
                  @click="openHistorialPortal(slotProps.data)"></Button>
              </div>
            </template>
          </Column>
        </DataTable>
        <ContextMenu :model="menuModel" ref="cm" />
      </template>
    </Card>
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import axios from "axios";
import factura from './Factura.vue';
import moment from 'moment'
import ComprobantePdf from "../../Pdf/ComprobantePdf.vue";
import Cancelacion from "../../Cancelacion/Cancelacion.vue";
import VwPsv from '../../Addendas/AddendaVW_PSV';

import { comprobanteBase64 } from '../../../plugins/comprobanteBase64.js'
import ComprobantePdfG from "../../Pdf/ComprobantePDFG.vue";

export default {
  components: { factura, ComprobantePdf, Cancelacion, VwPsv, ComprobantePdfG },
  data() {
    return {
      dialogFactura: false,
      dialogPdf: false,
      fechaI: new Date(),
      fechaF: new Date(),
      loading: false,
      dialogCancelacion: false,
      dialogLoading: false,
      loadingMensaje: '',
      filtrosFact: {
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        serie: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        folio: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        rfc: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'timbreFiscalDigital.folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
      },
      datosGenerales: 0,
      //MENU PARA MAS ACCIONES
      selectedComprobante: { serie: '', folio: '' },
      menuModel: [
        { label: 'Addenda VW PSV', icon: 'pi pi-fw pi-file', command: () => this.creaAddendaVWPSV(this.selectedComprobante) },
        { label: 'Notas / OC', icon: 'pi pi-fw pi-book', command: () => this.openDialogNotasOc(this.selectedComprobante) },
        { label: 'Portal', icon: 'pi pi-fw pi-inbox', command: () => this.openDialogPortal(this.selectedComprobante) },
      ],
      dialogVwPsv: false,
      dialogNotasOc: false,
      dialogPortal: false,
      dialogPortalHistorial: false,
      itemsPortalHistorial: [],
      notas: '',
      ordenCompra: '',
      fechaPortal: null,
    }
  },
  computed: {
    empresa() {
      return this.$store.state.empresaStore;
    },
    token() {
      return this.$store.state.usuario;
    },
    itemsFacturas() {
      return this.$store.state.listFacturaStore;
    },
    timbresDisponibles() {
      return this.$store.state.timbresDisponiblesStore;
    },
  },
  created() {
    this.inicializa();
  },
  methods: {
    async inicializa() {
      this.dialogLoading = true;
      this.datosGenerales = 1
      await this.getEmpresa();
      await this.getTimbres();
      this.datosGenerales = 0
      this.dialogLoading = false;
    },

    async getEmpresa() {
      try {
        this.loadingMensaje = 'Consultando datos...'
        let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
        this.dialogLoading = false;
      }
    },

    async getTimbres() {
      this.datosGenerales = 1
      this.loadingMensaje = 'Consultando timbres...'
      try {
        let response = await axios.get('Comprobantes/GetTimbres/' + this.empresa.rfc);
        this.$store.state.timbresDisponiblesStore = response.data
        this.datosGenerales = 0
      } catch (error) {
        console.log(error)
        this.datosGenerales = 0
      }
    },

    closeDialogFactura() {
      this.dialogFactura = false;
    },

    nuevaFactura() {
      let comprobante = this.$store.state.facturaStore.idFactura;
      console.log(comprobante)
      if (comprobante == -1) {
        let nuevo = {
          idFactura: 0,
          folioFiscal: '',
          serie: '',
          folio: '',
          fecha: new Date(),
          formaPago: {},
          condicionesPago: '',
          subTotal: 0,
          descuento: 0,
          moneda: {
            id: 100,
            clave: 'MXN',
            descripcion: 'Peso Mexicano',
            moneda: 'MXN | Peso Mexicano'
          },
          tipoCambio: 0,
          total: 0,
          tipoComprobante: 'I',
          tipoComprobanteInterno: 'Factura',
          metodoPago: {},
          empresa: {},
          cliente: {
            rfc: '',
            nombre: '',
            cliente: '',
            usoCfdi: {},
            regimenFiscal: { regimenFiscal: '' }
          },
          rfc: '',
          nombre: '',
          atn: '',
          notas: '',
          periodicidad: '',
          meses: '',
          año: 2023,
          estatus: 'Sin Timbrar',
          estatusCancelacion: '',
          usuarioCreador: '',
          usuarioModificador: '',
          usuarioAutoriza: '',
          conceptos: [],
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          cfdiRelacionados: [
            {
              tipoRelacion: {},
              cfdiRelacionados: [],
            }
          ],
          timbreFiscalDigital: {
            folioFiscal: '',
            fechaTimbrado: new Date(),
            selloCFDI: { base64: '' },
            noCertificado: '',
            noCertificadoSAT: '',
            selloSAT: { base64: '' },
            cadenaOriginal: '',
            serie: '',
            folio: '',
            Error: '',
            archivoXml: '',
          },
          remisiones: [],
          protocolos: [],
          ordenCompra: '',
          ordenTrabajo: '',
          portales: [],
        }
        this.$store.state.facturaStore = { ...nuevo }
      }
      this.dialogFactura = true;
    },

    facturasimilar(item) {
      console.log(item)
      let similar = { ...item }
      let timbreFiscalDigital = {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }

      similar.idFactura = 0
      similar.folioFiscal = ''
      similar.folio = ''
      similar.estatus = 'Sin Timbrar'
      similar.estatusCancelacion = ''
      similar.fecha = new Date()
      similar.timbreFiscalDigital = { ...timbreFiscalDigital }

      this.$store.state.facturaStore = { ...similar }
      this.dialogFactura = true;
    },

    editarFactura(item) {
      let editar = { ...item }
      this.$store.state.facturaStore = { ...editar }
      this.dialogFactura = true;
    },

    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },

    formatDate(value) {
      moment.locale('es-mx');
      return moment(value).format('DD-MMMM-YYYY')
    },

    async getFactura() {
      this.loading = true;
      this.$store.state.listFacturaStore = []
      try {
        let fI = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')
        let fF = moment(this.fechaF).format('YYYY-MM-DD HH:mm:ss')
        let response = await axios.get('Comprobantes/GetComprobantes/' + this.token.empresaBase + '/Factura/' + fI + '/' + fF + '/' + this.empresa.rfc);
        console.log(response.data)
        let array = response.data
        this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    openDialogPdf(data) {
      console.log(data)
      this.dialogPdf = true;
      this.$store.state.facturaStore = { ...data }
    },

    closeDialogPdf() {
      this.dialogPdf = false;
      this.$store.state.facturaStore.idFactura = -1;
    },

    openCancelacion(data) {
      this.dialogCancelacion = true
      this.$store.state.cancelacionStore = { ...data }
    },

    async actualizarEstatus(data) {
      this.loadingMensaje = 'Consultando estatus...'
      let actualizado = { ...data }
      this.dialogLoading = true;
      let actualizaEstatus = {
        idComprobante: data.idComprobante,
        rfc: data.empresa.rfc,
        folioFiscal: data.folioFiscal,
        motivoCancelacion: {},
        uuidSustituir: '',
        total: data.total,
        usuarioModificador: this.token.nombre
      }
      // console.log(actualizaEstatus)
      try {
        let response = await axios.post('Comprobantes/ActualizarEstatusSat/' + this.token.empresaBase + '/', actualizaEstatus)
        actualizado.estatus = response.data.estatus
        actualizado.estatusCancelacion = response.data.estatusCancelacion

        // BUSCAMOS EL INDICE
        let indice = this.itemsFacturas.findIndex(x => x.folioFiscal === actualizado.folioFiscal)
        console.log(indice)
        Object.assign(this.itemsFacturas[indice], actualizado)
        console.log(actualizado)

        this.$toast.add({ severity: 'success', detail: 'Estatus actualizado.', life: 5000 });
        this.dialogLoading = false;

      } catch (err) {
        console.log(err.response.data)
        this.$toast.add({ severity: 'error', detail: err.response.data, life: 5000 });
        this.dialogLoading = false;
      }
    },

    //ACCIONES EXTRAS
    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },

    async creaAddendaVWPSV(comprobante_) {
      this.dialogLoading = true;
      this.loadingMensaje = 'Consultando datos...'
      //VERIFICAMOS SI NO TIENE DATOS LA ADDENDA
      try {
        let objAddenda = {};
        let response = await axios.get('Addendas/GetAddendaVwPsv/' + this.token.empresaBase + '/' + comprobante_.folioFiscal)
        let objAddendaNuevo =
        {
          id: 0,
          version: 1,
          divisionVwPsv: {},
          tipoDocumentoVWMVwPsv: {},
          tipoDocumentoFiscalVwPsv: {},
          moneda: {
            codigoImpuesto: {},
            tipoCambio: 1,
            tipoMonedaVwPsv: {},
          },
          proveedor: {},
          origen: {},
          referencia: {
            numeroASN: '',
            referenciaProveedor: '',
            remision: '',
            unidadNegocios: '',
          },
          solicitante: {},
          archivos: [],
          partes: [],
          folioFiscal: comprobante_.folioFiscal,
          ordenCompra: comprobante_.ordenCompra,
          conceptos: comprobante_.conceptos,
        }
        objAddenda = { ...objAddendaNuevo }
        this.$store.state.addendaVwPsvStore = { ...objAddenda }
        this.dialogLoading = false;
        this.dialogVwPsv = true;
      } catch (error) {
        console.log(error)
        this.dialogLoading = false;
      }
    },

    closeDialogAddendaVWPSV() {
      this.dialogVwPsv = false;
    },

    //EDITAR NOTAS U ORDEN DE COMPRA
    openDialogNotasOc(item) {
      this.selectedComprobante = { ...item }
      this.notas = this.selectedComprobante.notas
      this.ordenCompra = this.selectedComprobante.ordenCompra
      this.dialogNotasOc = true;
    },

    closeDialogNotasOc() {
      this.dialogNotasOc = false;
    },

    async PutNotasOC() {
      try {
        this.dialogLoading = true;
        this.loadingMensaje = 'Guardando cambios espere...'
        let objNotasOc = {
          notas: this.notas,
          ordenCompra: this.ordenCompra,
          idComprobante: this.selectedComprobante.idComprobante,
        }
        this.dialogNotasOc = false;
        let response = await axios.put('Comprobantes/PutNotasOrdenCompra/' + this.token.empresaBase, objNotasOc)
        this.dialogLoading = false;

        let indice = this.$store.state.listFacturaStore.findIndex(x => x.idComprobante === this.selectedComprobante.idComprobante)
        this.$store.state.listFacturaStore[indice].notas = this.notas;
        this.$store.state.listFacturaStore[indice].ordenCompra = this.ordenCompra;

        this.$toast.add({ severity: 'success', detail: 'Datos actualizados con éxito.', life: 5000 });
      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Error al actualizar', life: 5000 });
      }
    },

    openDialogPortal(item) {
      this.notas = '';
      this.dialogPortal = true;
      this.selectedComprobante = { ...item }
    },

    closeDialogPortal() {
      this.dialogPortal = false;
    },

    async PostPortal() {
      try {
        this.dialogLoading = true;
        this.loadingMensaje = 'Registrando espere...'
        let ObjPortal = {
          folioFiscal: this.selectedComprobante.folioFiscal,
          fecha: this.fechaPortal,
          notas: this.notas,
          usuario: this.token.nombre
        }
        let response = await axios.post('Comprobantes/PostPortal/' + this.token.empresaBase, ObjPortal)
        this.dialogLoading = false;

        let indice = this.$store.state.listFacturaStore.findIndex(x => x.idComprobante === this.selectedComprobante.idComprobante)
        this.$store.state.listFacturaStore[indice].portales.push(ObjPortal);
        this.dialogPortal = false;
        this.$toast.add({ severity: 'success', detail: 'Datos actualizados con éxito.', life: 5000 });
      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Error al actualizar', life: 5000 });
      }
    },

    openHistorialPortal(item) {
      this.dialogPortalHistorial = true;
      this.itemsPortalHistorial = item.portales;
    },

    async nuevoPdf(item) {
      try {
        let logo = this.empresa.logo.base64;
        let estatus = item.estatus
        let colores = this.empresa.colores.color;
        console.log(item);

        let base64 = await comprobanteBase64(logo, 'Factura', item, colores, estatus);
        let nombreDoc = '';
        if (item.serie === '') {
          nombreDoc = item.folio + '_' + item.cliente.rfc + '_' + item.timbreFiscalDigital.folioFiscal
        } else {
          nombreDoc = item.serie + '_' + item.folio + '_' + item.cliente.rfc + '_' + item.timbreFiscalDigital.folioFiscal
        }
        let timbrado = 'SI';
        if (item.timbreFiscalDigital.folioFiscal === '') {
          timbrado = 'NO'
        }
        let idCliente = item.cliente.idCliente;

        let ObjComprobante = {
          nombre: nombreDoc,
          base64: base64.split(',')[1],
          archivoXml: item.timbreFiscalDigital.archivoXml,
          timbrado: timbrado,
          idCliente: idCliente,
        }

        this.$store.state.comprobanteStore = { ...ObjComprobante };
        this.dialogPdf = true;
        // console.log(this.$store.state.comprobanteStore);
      } catch (error) {
        console.error(error);
      }
    },
  },
}
</script>
<style>
.orange-button {
  background-color: #F46723;
}

.status-Vigente {
  background-color: #4CAF50;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Facturado {
  background-color: #4CAF50;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-VigenteSinRespuesta {
  background-color: #4CAF50;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-VigenteEnProceso {
  background-color: #FF6F00;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Sin {
  background-color: #2196F3;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Cancelado {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-CanceladoPlazoVencido {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-CanceladoCanceladoSinAceptacion {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-CanceladoCanceladoConAceptacion {
  background-color: #B71C1C;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}
</style>