<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 600px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold  border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>
            <!-- TARJETA CON LA INFORMACION DEL CONCEPTO -->
            <Card>
                <!-- TITULO CARD -->
                <template #title>
                    <div class="flex justify-content-between flex-wrap pb-2">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                        Concepto
                        <template v-if="concepto.idConcepto === 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="postConcepto()" />
                        </template>
                        <template v-if="concepto.idConcepto != 0">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-warning p-button-outlined"
                                @click="putConcepto()" />
                        </template>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <div class="p-fluid grid">
                        <!-- CLAVE PROD SERV -->
                        <div class=" field col-12">
                            <span class="p-float-label">
                                <AutoComplete style="text-align: left" v-model="concepto.claveProdServ"
                                    :suggestions="filteredClaveProdServ" @complete="searchClaveProdServ($event)"
                                    field="claveProdServ"></AutoComplete>
                                <label>Clave(SAT)</label>
                            </span>
                        </div>
                        <!-- NO DE INDENTIFICACION -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                                <label>No. Identificación</label>
                            </span>
                        </div>
                        <!-- UNIDAD -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <AutoComplete v-model="concepto.claveUnidad" :suggestions="filteredClaveUnidad"
                                    @complete="searchClaveUnidad($event)" field="claveUnidad"></AutoComplete>
                                <label>Unidad(SAT)</label>
                            </span>
                        </div>
                        <!-- UNIDAD -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText type="text" v-model="concepto.unidad" />
                                <label>Unidad</label>
                            </span>
                        </div>
                        <!-- DESCRIPCION -->
                        <div class="field col-12">
                            <span class="p-float-label">
                                <InputText type="text" v-model="concepto.descripcion" style="text-transform: uppercase" />
                                <label>Descripción</label>
                            </span>
                        </div>
                        <!-- VALOR UNITARIO -->
                        <div class="justify-right field col-12 md:col-4 sm:col-12">
                            <span class="p-float-label">
                                <InputNumber v-model="concepto.valorUnitario" mode="decimal" locale="en-US"
                                    :minFractionDigits="6" />
                                <label>Valor unitario</label>
                            </span>
                        </div>
                        <!-- PRECIO -->
                        <div class="justify-right field col-12 md:col-4 sm:col-12">
                            <span class="p-float-label">
                                <InputNumber v-model="concepto.precio" mode="decimal" locale="en-US"
                                    :minFractionDigits="2" />
                                <label>Precio</label>
                            </span>
                        </div>
                        <!-- IEPS GASOLINA -->
                        <template
                            v-if="concepto.claveProdServ.clave === '15101514' || concepto.claveProdServ.clave === '15101515' || concepto.claveProdServ.clave === '15101505'">
                            <div class="justify-right field col-12 md:col-4 sm:col-12">
                                <span class="p-float-label">
                                    <InputNumber v-model="concepto.iepsGas" mode="decimal" locale="en-US"
                                        :minFractionDigits="6" />
                                    <label>IEPS Gasolina</label>
                                </span>
                            </div>
                        </template>
                        <!-- NUMERO CUENTA PREDIAL -->
                        <template
                            v-if="concepto.claveProdServ.clave === '80131500' || concepto.claveProdServ.clave === '80131501' || concepto.claveProdServ.clave === '80131502' || concepto.claveProdServ.clave === '80131503'">
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <InputText type="text" v-model="concepto.numeroCuentaPredial"
                                        style="text-transform: uppercase" />
                                    <label>Número cuenta predial</label>
                                </span>
                            </div>
                        </template>
                        <!-- MATERIAL -->
                        <div class="field col-12">
                            <span class="p-float-label">
                                <InputText type="text" v-model="concepto.material" style="text-transform: uppercase" />
                                <label>Material</label>
                            </span>
                        </div>
                        <!-- LINEA -->
                        <div class="field col-12 md:col-4 sm:col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="concepto.linea" :options="listaLinea" :editable="true" />
                                <label>Linea</label>
                            </span>
                        </div>
                        <!-- MARCA -->
                        <div class="field col-12 md:col-4 sm:col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="concepto.marca" :options="listaMarca" :editable="true" />
                                <label>Marca</label>
                            </span>
                        </div>
                        <!-- SUBMARCA -->
                        <div class="field col-12 md:col-4 sm:col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="concepto.submarca" :options="listaSubmarca" :editable="true" />
                                <label>Submarca</label>
                            </span>
                        </div>
                        <!-- ALMACENABLE -->
                        <div class="field col-12 md:col-4 sm:col-12">
                            <h5>Almacenable</h5>
                            <div class="field-radiobutton">
                                <RadioButton name="almacenable" value="SI" v-model="concepto.almacenable"
                                    id="almacenable1" />
                                <label for="almacenable1">SI</label>
                            </div>
                            <div class="field-radiobutton">
                                <RadioButton name="almacenable" value="NO" v-model="concepto.almacenable"
                                    id="almacenable2" />
                                <label for="almacenable2">NO</label>
                            </div>
                        </div>
                        <!-- OBJETO DE IMPUESTOS -->
                        <div class="justify-left field col-12">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp"
                                    optionLabel="objImp" />
                                <label>Objeto de impuestos(SAT)</label>
                            </span>
                        </div>
                        <!-- IMPUESTOS -->
                        <template v-if="concepto.objImp.clave === '02'">
                            <!-- TRASLADADOS -->
                            <div class="field md:col-6 sm:col-12">
                                <h1>Traslados</h1>
                                <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                                    :selection.sync="concepto.impuestos.traslados">
                                    <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                    <Column field="traslado" header="Impuesto"></Column>
                                    <Column field="tipoFactor" header="Tipo Factor"></Column>
                                    <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                                </DataTable>
                            </div>
                            <!-- RETENIDOS -->
                            <div class="field md:col-6 sm:col-12">
                                <h1>Retenciones</h1>
                                <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                                    :selection.sync="concepto.impuestos.retenciones">
                                    <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                    <Column field="retencion" header="Impuesto"></Column>
                                    <Column field="tipoFactor" header="Tipo Factor"></Column>
                                    <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                                </DataTable>
                            </div>
                        </template>
                        <!-- IMAGEN -->
                        <div class="field col-12">
                            <h5>Imagen</h5>
                            <!-- <ImagePreview src="img/logo_contago_erp.750590c5.png" width="400" preview="" /> -->
                            <ImagePreview :src="concepto.imagen.base64" alt="Image" width="250" preview />
                        </div>
                        <!-- CARGAR IMAGEN -->
                        <div class="field col-12">
                            <h3>Seleccione la imagen</h3>
                            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadImage" accept="image/*"
                                :maxFileSize="5242880">
                                <template #empty>
                                    <p>Arrastre y suelte para cargar</p>
                                </template>
                            </FileUpload>
                        </div>
                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
export default {
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredClaveProdServ: null,
            filteredClaveUnidad: null,
            filteredObjImp: null,
            optionsAlmacenable: ['SI', 'NO'],
        }
    },
    computed: {
        concepto() {
            return this.$store.state.conceptoStore
        },
        listaLinea() {
            return this.$store.state.listaLineaStore
        },
        listaMarca() {
            return this.$store.state.listaMarcaStore
        },
        listaSubmarca() {
            return this.$store.state.listaSubmarcaStore
        },
        claveProdServ() {
            return this.$store.state.claveProdServStore
        },
        claveUnidad() {
            return this.$store.state.claveUnidadStore
        },
        objImp() {
            return this.$store.state.objImpStore
        },
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        empresa() {
            return this.$store.state.usuario;
        },

    },
    created() {
        this.GetClaveProdServ();
        this.GetClaveUnidad();
        this.GetObjImp();
        this.GetTraslados();
        this.GetRetenciones();
    },
    methods: {
        async GetClaveProdServ() {
            if (this.claveProdServ.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            if (this.claveUnidad.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            if (this.objImp.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTraslados() {
            if (this.traslados.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            if (this.retenciones.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        searchClaveProdServ(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveProdServ = [...this.claveProdServ];
                }
                else {
                    this.filteredClaveProdServ = this.claveProdServ.filter((country) => {
                        return country.claveProdServ.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchClaveUnidad(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveUnidad = [...this.claveUnidad];
                }
                else {
                    this.filteredClaveUnidad = this.claveUnidad.filter((country) => {
                        return country.claveUnidad.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchObjImp(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredObjImp = [...this.objImp];
                }
                else {
                    this.filteredObjImp = this.objImp.filter((country) => {
                        return country.objImp.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        async uploadImage(event) {
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.$store.state.conceptoStore.imagen.base64 = result;
            } catch (error) {
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        async postConcepto() {
            //PRIMERO VAMOS A VALIDAR
            if (!this.concepto.descripcion || this.concepto.descripcion === '') {
                this.$toast.add({ severity: 'warn', summary: 'Warn Message', detail: 'Indique una descripción.', life: 3000 });
                return;
            }
            this.loading = true;
            this.loadingMensaje = 'Guardando concepto, espere...'

            this.concepto.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('Conceptos/PostConcepto/' + this.empresa.empresaBase, this.concepto)
                console.log(response.data)
                this.concepto.idConcepto = response.data
                console.log(this.concepto);
                this.$store.state.listaConceptosStore.push(this.concepto)
                this.loading = false;

                //INICIALIZAMOS EL CONCEPTO PARA DAR DE ALTA UNO NUEVO
                let conceptoDefault = {
                    idDocumento: 0,
                    idConcepto: 0,
                    claveProdServ: {
                        claveProdServ: '',
                    },
                    noIdentificacion: '',
                    claveUnidad: {
                        claveUnidad: '',
                    },
                    unidad: '',
                    descripcion: '',
                    valorUnitario: 0,
                    importe: 0,
                    descuento: 0,
                    precio: 0,
                    total: 0,
                    objImp: {},
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    numeroCuentaPredial: '',
                    material: '',
                    imagen: {
                        base64: '',
                    },
                    linea: '',
                    marca: '',
                    submarca: '',
                    iepsGas: 0,
                    almacenable: 'NO',
                    estatus: '',
                    usuarioCreador: '',
                    usuarioModificador: '',
                }
                this.$store.state.conceptoStore = { ...conceptoDefault }
                this.$toast.add({ severity: 'success', detail: 'Concepto registrado con éxito.', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, verifique que el concepto no exista.', life: 3000 });
                this.loading = false;
            }
        },

        async putConcepto() {
            //PRIMERO VAMOS A VALIDAR
            if (!this.concepto.descripcion || this.concepto.descripcion === '') {
                this.$toast.add({ severity: 'warn', detail: 'Indique una descripción.', life: 3000 });
                return;
            }
            this.loading = true;
            this.loadingMensaje = 'Guardando concepto, espere...'

            this.concepto.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('Conceptos/PutConcepto/' + this.empresa.empresaBase, this.concepto)
                this.loading = false;

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaConceptosStore.findIndex(x => x.idConcepto === this.concepto.idConcepto);
                Object.assign(this.$store.state.listaConceptosStore[indice], this.concepto)

                //INICIALIZAMOS EL CONCEPTO PARA DAR DE ALTA UNO NUEVO
                let conceptoDefault = {
                    idDocumento: 0,
                    idConcepto: 0,
                    claveProdServ: {
                        claveProdServ: '',
                    },
                    noIdentificacion: '',
                    claveUnidad: {
                        claveUnidad: '',
                    },
                    unidad: '',
                    descripcion: '',
                    valorUnitario: 0,
                    importe: 0,
                    descuento: 0,
                    precio: 0,
                    total: 0,
                    objImp: {},
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    numeroCuentaPredial: '',
                    material: '',
                    imagen: {
                        base64: '',
                    },
                    linea: '',
                    marca: '',
                    submarca: '',
                    iepsGas: 0,
                    almacenable: 'NO',
                    estatus: '',
                    usuarioCreador: '',
                    usuarioModificador: '',
                }
                this.$store.state.conceptoStore = { ...conceptoDefault }
                this.$toast.add({ severity: 'success', detail: 'Concepto actualizado con éxito.', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al actualizar.', life: 3000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            console.log(this.$store.state.conceptoStore)
            this.$emit('closeConcepto')
        },
    },
}
</script>