<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 700px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: 0.5em" />
                <span>{{ loadingMensaje }}</span>
            </Dialog>
            <Card>
                <!-- TITULO CARD -->
                <template #title>
                    <div class="flex justify-content-between flex-wrap pb-2">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                        Nuevo Retiro
                        <template v-if="movimiento.idMovimientosBanco === 0">
                            <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                                @click="postMovimiento()" />
                        </template>
                        <template v-if="movimiento.idMovimientosBanco != 0">
                            <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                                @click="putMovimiento()" />
                        </template>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <div class="p-fluid grid">

                        <!-- FISCAL O NO FISCAL -->
                        <div style="text-align: center;" class="field col-12 md:col-4">
                            <h5>¿Tipo de retiro?</h5>
                            <div class="formgroup-inline justify-center">
                                <div class="ml-6 mr-6 field-radiobutton">
                                    <RadioButton id="fiscal" name="cajabanco" value="fiscal" v-model="radioB" />
                                    <label for="fiscal">Fiscal</label>
                                </div>
                                <div class="ml-6 field-radiobutton">
                                    <RadioButton id="noFiscal" name="cajabanco" value="noFiscal" v-model="radioB" />
                                    <label for="noFiscal">No Fiscal</label>
                                </div>
                            </div>
                        </div>

                        <!-- FECHA -->
                        <div class="col-6 text-right">
                            <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                                <span class="p-float-label">
                                    <Calendar v-model="movimiento.fechaMovimiento" dateFormat="dd-MM-yy" />
                                    <label>Fecha del Retiro</label>
                                </span>
                                <!-- </div> -->
                            </div>
                        </div>

                        <!-- BANCO -->
                        <div class="justify-left field col-12">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="movimiento.jsonBancosSat"
                                    :options="bancoEmpresa" optionLabel="jsonBancosSat.banco" />
                                <label>Banco</label>
                            </span>
                        </div>

                        <!-- CLIENTES -->
                        <div class="justify-left field col-12" v-if="radioB === 'fiscal'">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="movimiento.cliente" :options="itemsClientes"
                                    optionLabel="cliente" />
                                <label>Cliente</label>
                            </span>
                        </div>

                        <!-- BOTON DE  BUSCAR -->
                        <div class="justify-left field col-12" v-if="radioB === 'fiscal'">
                            <span class="p-float-label">
                                <Button id="buscaF" icon="pi pi-check"
                                    class="p-button-rounded p-button-success p-button-outlined" @click="GetFacturas" />
                            </span>
                            <label for="buscaF">Buscar Facturas</label>
                        </div>


                        <!-- FACTURAS -->
                        <div :class="tamanio" v-if="radioB === 'fiscal'">
                            <h1>Facturas Libres</h1>
                            <DataTable :value="listaFacturas" :scrollable="true" scrollHeight="400px"
                                responsiveLayout="scroll" dataKey="idComprobante" :selection.sync="movimiento.comprobantes">
                                <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                <Column field="folioFiscal" header="Folio Fiscal"></Column>
                                <Column field="serie" header="Serie"></Column>
                                <Column field="folio" header="Folio"></Column>
                                <Column field="fecha" header="Fecha"></Column>
                                <Column field="rfc" header="RFC"></Column>
                                <Column field="nombre" header="Nombre"></Column>
                                <Column field="total" header="Total"></Column>
                                <Column field="moneda.clave" header="Moneda"></Column>
                                <Column field="formaPago.formaPago" header="Forma de Pago"></Column>
                            </DataTable>
                        </div>
                        <template v-if="movimiento.idMovimientosBanco != 0">
                            <div class="field md:col-6 sm:col-12" v-if="radioB === 'fiscal'">
                                <h1>Facturas Usadas</h1>
                                <DataTable :value="foliosTotal" :scrollable="true" scrollHeight="400px"
                                    responsiveLayout="scroll" dataKey="idComprobante"
                                    :selection.sync="movimiento.comprobantes">
                                    <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                    <Column field="folioFiscal" header="Folio Fiscal"></Column>
                                    <Column field="serie" header="Serie"></Column>
                                    <Column field="folio" header="Folio"></Column>
                                    <Column field="fecha" header="Fecha"></Column>
                                    <Column field="rfc" header="RFC"></Column>
                                    <Column field="nombre" header="Nombre"></Column>
                                    <Column field="total" header="Total"></Column>
                                    <Column field="moneda.clave" header="Moneda"></Column>
                                    <Column field="formaPago.formaPago" header="Forma de Pago"></Column>
                                </DataTable>
                            </div>
                        </template>

                        <!-- MONTO -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <InputNumber class="color" v-model="monto" :value="variablesMonto" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>Monto</label>
                            </span>
                        </div>

                        <!-- MONEDA -->
                        <div class="field col-12 md:col-6" v-if="radioB === 'fiscal'">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="movimiento.moneda" :options="itemsMoneda"
                                    optionLabel="moneda" />
                                <label>Moneda</label>
                            </span>
                        </div>

                        <!-- REFERENCIA -->
                        <div class="field col-6">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText id="referencia" type="text" v-model="movimiento.referencia" />
                                    <label for="referencia">Referencia</label>
                                </span>
                            </span>
                        </div>

                        <!-- NOTAS -->
                        <div class="field col-6">
                            <h3>Notas</h3>
                            <Textarea v-model="movimiento.notas" rows="5" cols="30" />
                        </div>

                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios';
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from 'moment'

export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",
            objetoBanco: {
                jsonBancosSat: { banco: '' }
            },
            radioB: 'fiscal',
            listaFacturas: [],
            selectLista: null,
            foliosTotal: [],
            // fecha: new Date(),
            classVariable: 'field md:col-6 sm:col-12',
            monto: null
        };
    },
    watch: {

    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },

        bancoEmpresa() {
            return this.$store.state.listBancosEmpresa;
        },

        movimiento() {
            return this.$store.state.movimientosStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        itemsClientes() {
            return this.$store.state.listClienteStore;
        },
        itemsFolios() {
            return this.$store.state.listComprobantesMov;
        },

        tamanio() {
            if (this.movimiento.idMovimientosBanco == 0) {
                return 'field md:col-12 sm:col-12'
            } else {
                return 'field md:col-6 sm:col-12'
            }
        },
        variablesMonto() {

            if (this.radioB == "fiscal") {
                let suma = this.sumaLista()
                this.monto = suma
                return suma
            } else {
                let suma = this.monto2()
                this.monto = suma

                return suma
            }
        },

    },

    created() {
        this.Inicializa();
    },
    mounted() {

    },

    methods: {
        sumaLista() {
            return this.sum(this.movimiento.comprobantes, 'total')

        },
        monto2() {
            return this.monto
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        // INICILIALIZA
        async Inicializa() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Facturas...'
            await this.GetBancosEmpresa();
            await this.GetClientes();
            this.getMoneda();
            if (this.movimiento.idMovimientosBanco != 0) {
                await this.GetFacturas()
                await this.getFoliosUsados()
            }
            this.loading = false;
        },

        async GetFacturas() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Comprobantes';
            try {
                let forma = '02'
                if (this.movimiento.jsonBancosSat.jsonBancosSat.clave === 'Caja') {
                    forma = '01'
                }
                let response = await axios.get("Bancos/GetComprobantesRetiroBanco/" + this.empresa.empresaBase + '/' + this.movimiento.cliente.rfc + '/' + forma);
                this.listaFacturas = response.data;
                // console.log(response.data)
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        async getMoneda() {
            if (this.itemsMoneda.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },

        async GetBancosEmpresa() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Bancos';
            try {
                let response = await axios.get("Bancos/getBancos/" + this.empresa.empresaBase);
                // for (var item of response.data) {
                //     this.$store.state.listBancosEmpresa.push(item.jsonBancosSat)
                // }
                this.$store.state.listBancosEmpresa = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        async GetClientes() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Clientes';
            try {
                let response = await axios.get("Clientes/GetClientes/" + this.empresa.empresaBase);
                // for (var item of response.data) {
                //     this.$store.state.listBancosEmpresa.push(item.jsonBancosSat)
                // }
                this.$store.state.listClienteStore = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        //GUARDA O EDITA
        async postMovimiento() {
            try {
                let objMovimiento = {}
                objMovimiento = { ...this.movimiento }
                objMovimiento.tipoMovimiento = 'Retiro';
                objMovimiento.usuarioCreador = this.empresa.nombre;
                objMovimiento.monto = this.monto
                let response = await axios.post("Bancos/PostMovimiento/" + this.empresa.empresaBase, objMovimiento);
                this.$store.state.listMovimientos.push(objMovimiento);
                this.$store.state.movimientosStore.idMovimientosBanco = -1;
                this.cierraVentana()
                // console.log(response);
            } catch (error) {
                console.log(error)
            }
        },

        cierraVentana() {
            this.$emit('closeRetiros')
        },

        async getFoliosUsados() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Comprobantes';
            // console.log(this.movimiento)
            try {
                let forma = '02'
                if (this.movimiento.jsonBancosSat.jsonBancosSat.clave === 'Caja') {
                    forma = '01'
                }
                let response = await axios.get("Bancos/getFoliosUsados/" + this.empresa.empresaBase + '/' + this.movimiento.cliente.rfc + '/' + forma + '/' + this.movimiento.idMovimientosBanco);
                this.foliosTotal = response.data;
                // this.foliosTotal = this.listaFacturas.concat(response.data);
                this.movimiento.comprobantes = response.data
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        async putMovimiento() {

            let ObjMovimiento = { ...this.movimiento }

            this.loading = true;
            this.loadingMensaje = 'Actualizando datos, espere...'
            ObjMovimiento.usuarioModificador = this.empresa.nombre
            ObjMovimiento.monto = this.monto
            // console.log(ObjMovimiento)

            try {
                let response = await axios.put('Bancos/PutMovimientoCompleto/' + this.empresa.empresaBase, ObjMovimiento)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listMovimientos.findIndex(x => x.idMovimientosBanco === ObjMovimiento.idMovimientosBanco);
                Object.assign(this.$store.state.listMovimientos[indice], ObjMovimiento)
                this.$store.state.movimientosStore.idMovimientosBanco = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
            // this.loading = false;

        },
    },
};
</script>