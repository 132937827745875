<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Autotransporte
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- PERMISO SCT -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.permSCT" :options="itemPermisos" optionLabel="numPermisoSCT">
                            </Dropdown>
                            <label>Permiso SCT</label>
                        </span>
                    </div>

                    <!-- IDENTIFICACION VEHICULAR -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.identificacionVehicular" :options="itemVehiculos"
                                optionLabel="placaVM">
                            </Dropdown>
                            <label>Configuración Vehicular</label>
                        </span>
                    </div>

                    <!-- SEGURO DE RESPONSABILIDAD CIVIL -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.seguroCivil" :options="itemSegurosCivil" optionLabel="seguro">
                            </Dropdown>
                            <label>Seguro de Responsabilidad Civil</label>
                        </span>
                    </div>

                    <!-- SEGURO DE MEDIO AMBIENTE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.seguroAmbiente" :options="itemSegurosAmbiente" optionLabel="seguro">
                            </Dropdown>
                            <label>Seguro de Medio Ambiente</label>
                        </span>
                    </div>

                    <!-- SEGURO DE CARGA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.seguroCarga" :options="itemSegurosCarga" optionLabel="seguro">
                            </Dropdown>
                            <label>Seguro de Carga</label>
                        </span>
                    </div>

                    <!-- REMOLQUE A -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.remolqueA" :options="itemRemolques" optionLabel="placa">
                            </Dropdown>
                            <label>Remolque A</label>
                        </span>
                    </div>

                    <!-- REMOLQUE B -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.remolqueB" :options="itemRemolques" optionLabel="placa">
                            </Dropdown>
                            <label>Remolque B</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',

            editedIndex: -1,
        }
    },
    computed: {
        itemSegurosCivil() {
            return this.$store.state.listSeguroStore.filter(a => a.tipo == 'Responsabilidad Civil');;
        },
        itemSegurosAmbiente() {
            return this.$store.state.listSeguroStore.filter(a => a.tipo == 'Daños al medio ambiente');;
        },
        itemSegurosCarga() {
            return this.$store.state.listSeguroStore.filter(a => a.tipo == 'Carga Transportada');;
        },
        itemRemolques() {
            return this.$store.state.listRemolqueStore
        },
        itemPermisos() {
            return this.$store.state.listPermisoSCTStore
        },
        item() {
            return this.$store.state.autotransporteStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        itemVehiculos() {
            return this.$store.state.listVehiculoStore
        },
    },
    created() {
        this.getRemolques()
        this.getSeguros()
        this.getPermisos()
        this.getVehiculos()
    },
    methods: {
        guardarOactualizar() {
            if (this.$store.state.autotransporteStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Autotransporte, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostAutotransporte/' + this.empresa.empresaBase, this.item)
                console.log(this.item)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Autotransporte guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listAutotransporteStore.push(this.item)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Autotransporte, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateAutotransporte/' + this.empresa.empresaBase, this.item)
                // console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listAutotransporteStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listAutotransporteStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Autotransporte actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },
        async getSeguros() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetSeguros/' + this.empresa.empresaBase)
                this.$store.state.listSeguroStore = response.data
                // console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        async getRemolques() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetRemolques/' + this.empresa.empresaBase)
                this.$store.state.listRemolqueStore = response.data
                // console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        async getPermisos() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetPermisos/' + this.empresa.empresaBase)
                this.$store.state.listPermisoSCTStore = response.data
                // console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
        // VEHICULOS
        async getVehiculos() {
            try {
                let response = await axios.get("CatalogosCartaPorte/GetVehiculo/" + this.empresa.empresaBase);
                this.$store.state.listVehiculoStore = response.data;
                // console.log('vehiculo')
                // console.log(response.data)
            } catch (error) {
                console.log(error);
                this.loadingItemsVehiculo = false;
            }
        },
    },
}
</script>