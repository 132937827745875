<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Proveedor
                    <template v-if="proveedor.idProveedor === 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                            @click="postProveedor()" />
                    </template>
                    <template v-if="proveedor.idProveedor != 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                            @click="putProveedor()" />
                    </template>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- NOMBRE -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" type="text" v-model="proveedor.nombre" />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- CODIGO PROVEEDOR -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="codigoProveedor" type="text" v-model="proveedor.codigo" maxlength="10" />
                                <label for="codigoProveedor">Código del proveedor</label>
                            </span>
                        </span>
                    </div>
                    <!-- CORREO ELECTRONICO -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="correoElectronico" type="text" v-model="proveedor.correoContacto" />
                                <label for="correoElectronico">Correo Electronico</label>
                            </span>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios';
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",
            correo: '',
            nombreCorreo: '',
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },

        proveedor() {
            return this.$store.state.proveedorVwStore;
        },

    },

    created() {
    },
    mounted() {
    },
    methods: {
        //GUARDA O EDITA
        async postProveedor() {
            let ObjProveedor = { ...this.proveedor }

            this.loading = true;
            this.loadingMensaje = 'Guardando datos, espere...'
            ObjProveedor.usuarioCreador = this.empresa.nombre

            try {
                let response = await axios.post('Addendas/PostProveedorVwPsv/' + this.empresa.empresaBase, ObjProveedor)
                ObjProveedor.idProveedor = response.data
                console.log(ObjProveedor)
                this.loading = false;

                this.$store.state.listProveedorVwStore.push(ObjProveedor);
                this.$store.state.proveedorVwStore.idProveedor = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }

        },

        async putProveedor() {
            let ObjProveedor = { ...this.proveedor }

            this.loading = true;
            this.loadingMensaje = 'Actualizando datos, espere...'
            ObjProveedor.usuarioModificador = this.empresa.nombre

            try {
                let response = await axios.put('Addendas/PutProveedorVw/' + this.empresa.empresaBase, ObjProveedor)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listProveedorVwStore.findIndex(x => x.idProveedor === ObjProveedor.idProveedor);
                Object.assign(this.$store.state.listProveedorVwStore[indice], ObjProveedor)
                this.$store.state.proveedorVwStore.idProveedor = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeProveedor')
        },
    },
};
</script>