<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOGO PARA VER LAS VENTAS AGRUPADOS POR FORMAS DE PAGO -->
        <Dialog :visible.sync="dialogAgruparVentas" header="Ventas" :style="{ width: '1200px' }" position="top"
            :modal="false" :closable="true">
            <hr size=7 color="#ED6421">
            <DataTable :value="listaCorte" class="p-datatable-sm" responsiveLayout="scroll">
                <Column field="formaPago.formaPago" header="Forma de Pago">
                </Column>
                <Column header="Monto" field="monto" :styles="{ 'background-color': '#effaf1', }"
                    :bodyStyle="{ 'text-align': 'right', overflow: 'visible' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.monto) }}</span>
                    </template>
                </Column>
                <ColumnGroup type="footer">
                    <Row>
                        <Column footer="Total:" :colspan="0" :footerStyle="{ 'text-align': 'right' }" />
                        <Column :footer="totalVentas" :footerStyle="{ 'text-align': 'right' }" />
                    </Row>
                </ColumnGroup>
            </DataTable>
        </Dialog>

        <!-- DIALOGO PARA VALIDAR USUARIOS -->
        <Dialog v-if="validarUsuario == 0" :visible.sync="dialogValidarUsuario" header="Validar Usuario"
            :style="{ width: '400px' }" position="center" :modal="true" :closable="false">
            <div class="p-d-flex  p-fluid grid formgrid col-12">
                <div class="field col-12">
                    <div class="p-inputgroup justify-center">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" v-model="usuario" :suggestions="filteredUsuario"
                                @complete="searchUsuario($event)" field="usuario">
                            </AutoComplete>
                            <label>Usuario</label>
                        </span>
                    </div>
                </div>
            </div>
            <div style="justify-content:center" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <div class="field col-12">
                    <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-"
                        :num-inputs="4" :should-auto-focus="true" v-model="password" :is-input-num="true"
                        input-type="password" @on-change="handleOnChange" @on-complete="handleOnComplete" type="password" />
                </div>
            </div>
            <div style="justify-content: right;" class="p-d-flex  p-fluid grid formgrid col-12">
                <div class="field col-4">
                    <Button label="Cancelar" @click="cerrarLogin()" />
                </div>
            </div>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- TITULO -->
                    <div class="col-7 text-left pa-0 ma-0 pl-3">
                        <span>Turnos</span>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-5 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getTurnos()" />
                                    <Button icon="pi pi-shopping-cart" @click="irPuntoVenta()"
                                        class="p-button-primary pl-4 pr-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #content>
                <DataTable showGridlines :filters.sync="filtrosTurnos" class="mt-1 p-datatable-sm" :value="itemsTurnos"
                    :scrollable="true" scrollHeight="800px" scrollDirection="both" :loading="loadingTurnos"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Turnos." :paginator="true"
                    :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosTurnos['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="getAgruparVentas(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                                v-tooltip.bottom="'Total Formas de Pago'" />
                        </template>
                    </Column>
                    <Column field="idTurno" header="Turno" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombre" header="Nombre" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                    </Column>
                    <Column field="efectivoInicial" header="Efectivo Inicial"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.efectivoInicial) }}</span>
                        </template>
                    </Column>
                    <Column field="efectivoFinal" header="Efectivo Final"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.efectivoFinal) }}</span>
                        </template>
                    </Column>
                    <Column field="caja" header="Caja" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="fechaEntrada" header="Fecha Entrada"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="fechaSalida" header="Fecha Salida"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        <template #body="{ data }">
                            <span v-if="data.estatus != 'Abierto'">{{ data.fechaSalida }}</span>
                        </template>
                    </Column>
                    <Column field="estatus" header="Estatus"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span :class="'product-badge-turno turno-' + slotProps.data.estatus">{{
                                slotProps.data.estatus
                            }}</span>
                        </template>
                    </Column>
                </DataTable>
            </template>

        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from 'moment'
import axios from "axios";

export default {
    data() {
        return {
            loading: null,
            loadingMensaje: '',
            fechaI: new Date(),
            fechaF: new Date(),
            loadingTurnos: null,
            filtrosTurnos: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                nombre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                caja: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            itemsTurnos: [],
            listaCorte: [],
            dialogAgruparVentas: false,
            dialogValidarUsuario: false,

            usuario: '',
            password: '',
            filteredUsuario: null,
            listaUsuarios: [],
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        totalVentas() {
            let total = 0;
            for (let sale of this.listaCorte) {
                total += sale.monto;
            }

            return this.formatCurrency(total);
        },
        validarUsuario() {
            return this.$store.state.validaUsuarioPuntoVenta
        }
    },
    created() {
        this.abrirDialogValidarUsuario()
        this.getUsuarios()
    },
    methods: {
        searchUsuario(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUsuario = [...this.listaUsuarios];
                }
                else {
                    this.filteredUsuario = this.listaUsuarios.filter((country) => {
                        return country.usuario.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        async getUsuarios() {
            try {
                let response = await axios.get('Usuarios/GetUsuariosVentas/' + this.token.empresaBase)
                this.listaUsuarios = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },
        abrirDialogValidarUsuario() {
            this.dialogValidarUsuario = true
        },
        async getTurnos() {
            this.loadingTurnos = true;
            this.itemsTurnos = []
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(this.fechaF).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('LoginPuntoVenta/GetTurnos/' + this.token.empresaBase + '/' + fI + '/' + fF + '/' + this.empresa.rfc);
                console.log(response.data)
                this.itemsTurnos = response.data
                this.loadingTurnos = false;
            } catch (error) {
                console.log(error);
                this.itemsTurnos = false;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        irPuntoVenta() {
            this.$router.push({ name: "PuntoVenta" });
        },
        async getAgruparVentas(data) {
            this.loadingMensaje = 'Agrupando ventas...'
            this.loading = true
            try {
                let response = await axios.get('Ventas/GetPuntoVentaPorTurno/' + this.token.empresaBase + '/PuntoVenta/' + this.token.rfc + '/' + data.idTurno);
                console.log(response.data)
                this.listaCorte = response.data
                this.dialogAgruparVentas = true
                this.loading = false

            } catch (error) {
                console.log(error);
                this.loading = false

            }
        },

        handleOnComplete(value) {
            console.log("OTP completado: ", value);
            this.password = value;
            this.loginVentasAsync();
            this.loadingInicio = 0
        },

        handleOnChange(value) {
            console.log("OTP cambio: ", value);
        },

        handleClearInput() {
            this.password = 0
            this.loadingInicio = null
            // this.$refs.otpInput.clearInput();
        },
        cerrarLogin() {
            this.$router.push({ name: "Home" });
        },

        async loginVentasAsync() {
            try {
                let response = await axios.post("LoginPuntoVenta/validarUsuario/" + this.token.empresaBase + '/' + this.usuario.usuario + '/' + this.password);
                console.log('login', response.data)
                this.$store.state.validaUsuarioPuntoVenta = response.data
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: 'error', detail: 'Datos incorrectos.', life: 5000 });
                this.handleClearInput()
                this.usuario = ''
            }
        },
    },
}
</script>
<style >
.product-badge-turno.turno-Cerrado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge-turno.turno-Abierto {
    background: #c8e6c9;
    color: #256029;
}

.product-badge-turno {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .3px;
}
</style>