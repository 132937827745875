<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- CODIGO IMPUESTO -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown v-model="parte.codigoImpuesto" :options="codigoImpuestoVwPsv" optionLabel="codigoImpuesto" />
                    <label>Código Impuesto</label>
                </span>
            </div>
            <!-- POSICIÓN -->
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <InputText v-model="parte.posicion" type="text" />
                    <label>Posición</label>
                </span>
            </div>
            <!-- DESCRIPCIÓN MATERIAL -->
            <div class="field col-12 md:col-10">
                <span class="p-float-label">
                    <InputText v-model="parte.descripcionMaterial" type="text" maxlength="40" />
                    <label>Descripción Material</label>
                </span>
            </div>
            <!-- CANTIDAD MATERIAL -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="parte.cantidadMaterial" mode="decimal" locale="en-US" :minFractionDigits="2" />
                    <label>Cantidad Material</label>
                </span>
            </div>
            <!-- UNIDAD MEDIDA -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputText v-model="parte.unidadMedida" type="text" maxlength="3" />
                    <label>Unidad Medida</label>
                </span>
            </div>
            <!-- PRECIO UNITARIO -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="parte.precioUnitario" mode="decimal" locale="en-US" :minFractionDigits="2" />
                    <label>Precio Unitario</label>
                </span>
            </div>
            <!-- MONTO LÍNEA -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="parte.montoLinea" mode="decimal" locale="en-US" :minFractionDigits="2" />
                    <label>Monto Línea</label>
                </span>
            </div>
            <!-- NÚMERO MATERIAL -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputText v-model="parte.numeroMaterial" type="text" maxlength="18" />
                    <label>Número Material</label>
                </span>
            </div>
            <!-- PESO BRUTO -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="parte.pesoBruto" mode="decimal" locale="en-US" :minFractionDigits="2" />
                    <label>Peso Bruto</label>
                </span>
            </div>
            <!-- PESO NETO -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="parte.pesoNeto" mode="decimal" locale="en-US" :minFractionDigits="2" />
                    <label>Peso Neto</label>
                </span>
            </div>
            <!-- ORDEN COMPRA -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputText v-model="parte.ordenCompra" type="text" maxlength="10" />
                    <label>Orden Compra</label>
                </span>
                <small>{{ addenda.ordenCompra }}</small>
            </div>
            <Button icon="pi pi-check" class="p-button-rounded p-button-outlined" @click="pushParte" />
        </div>
        <div class="field col-12">
            <DataTable :value="addenda.partes" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                class="mt-3 editable-cells-table" editMode="cell" @cell-edit-complete="onCellEditComplete">
                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Conciliar desde conceptos" @click="conciliar()" />
                    </div>
                </template>
                <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'min-width': '10rem' }"
                    frozen>
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            @click="deleteParte(slotProps.data)" />
                    </template>
                </Column>
                <Column field="codigoImpuesto.codigoImpuesto" header="Código Impuesto"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                </Column>
                <Column field="posicion" header="Posición" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="descripcionMaterial" header="Decripción Material"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
                    <template #editor="slotProps">
                        <InputText v-model="slotProps.data.descripcionMaterial" autofocus />
                    </template>
                </Column>

                <Column field="cantidadMaterial" header="Cantidad Material"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    <template #body="{ data }">
                        <span>{{ formatCantidad(data.cantidadMaterial) }}</span>
                    </template>
                    <template #editor="slotProps">
                        <InputNumber v-model="slotProps.data.cantidadMaterial" mode="decimal" locale="en-US"
                            :minFractionDigits="2" autofocus />
                    </template>
                </Column>

                <Column field="unidadMedida" header="Unidad Medida" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="precioUnitario" header="Precio Unitario"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.precioUnitario) }}</span>
                    </template>
                </Column>
                <Column field="montoLinea" header="Monto Línea" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.montoLinea) }}</span>
                    </template>
                </Column>
                <Column field="numeroMaterial" header="Número Material"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
                <Column field="pesoBruto" header="Peso Bruto" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    <template #body="{ data }">
                        <span>{{ formatCantidad(data.pesoBruto) }}</span>
                    </template>
                </Column>
                <Column field="pesoNeto" header="Peso Neto" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    <template #body="{ data }">
                        <span>{{ formatCantidad(data.pesoNeto) }}</span>
                    </template>
                </Column>
                <Column field="ordenCompra" header="Orden Compra" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InputNumber from 'primevue/inputnumber/InputNumber';

export default {
    components: {
        InputNumber
    },
    data() {
        return {
            parte: {
                codigoImpuesto: {},
                posicion: '',
                descripcionMaterial: '',
                cantidadMaterial: 0,
                unidadMedida: '',
                precioUnitario: 0,
                montoLinea: 0,
                numeroMaterial: '',
                pesoBruto: 0,
                pesoNeto: 0,
                ordenCompra: '',
            },
            parteDefault: {
                codigoImpuesto: {},
                posicion: '',
                descripcionMaterial: '',
                cantidadMaterial: 0,
                unidadMedida: '',
                precioUnitario: 0,
                montoLinea: 0,
                numeroMaterial: '',
                pesoBruto: 0,
                pesoNeto: 0,
                ordenCompra: '',
            },
        }
    },
    computed: {
        addenda() {
            return this.$store.state.addendaVwPsvStore;
        },
        codigoImpuestoVwPsv() {
            return this.$store.state.codigoImpuestoVwPsvStore;
        },

    },
    created() {

    },
    methods: {
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'cantidadMaterial':
                    data[field] = newValue;
                    break;
                case 'descripcionMaterial':
                    data[field] = newValue;
                    break;
            }
        },
        conciliar() {
            let pos = 1;
            console.log('Conceptos', this.addenda.conceptos)

            for (let c of this.addenda.conceptos) {
                let codigoImpuesto = {}
                if (c.impuestos.traslados[0].idImpuesto == 9) {
                    codigoImpuesto = { "clave": "1A", "descripcion": "IVA 16%", "codigoImpuesto": "1A | IVA 16%" }
                }
                let desc = c.descripcion.substring(0, 40);
                let um = c.unidad.substring(0, 3);
                let parte = {
                    codigoImpuesto: codigoImpuesto,
                    posicion: pos,
                    descripcionMaterial: desc,
                    cantidadMaterial: c.cantidad,
                    unidadMedida: um,
                    precioUnitario: c.valorUnitario,
                    montoLinea: c.importe,
                    numeroMaterial: '',
                    pesoBruto: 0,
                    pesoNeto: 0,
                    ordenCompra: this.addenda.ordenCompra,
                }
                this.addenda.partes.push(parte);
                pos++;
            }
        },

        pushParte() {
            this.addenda.partes.push(this.parte);
            this.parte = { ...this.parteDefault }
        },

        deleteParte(item) {
            let indice = this.addenda.partes.indexOf(item);
            this.addenda.partes.splice(indice, 1);
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    },
}
</script>
<style></style>