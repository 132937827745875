<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.placa }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogDelete = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminar()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO CONCEPTO -->
        <Dialog :visible.sync="dialogoNuevoPropietario" :closeOnEscape="true" :closable="false" :style="{ width: '70vw' }">
            <Propietario @cierraVentana="dialogoNuevoPropietario = false"></Propietario>
        </Dialog>

        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Comercio Exterior PROPIETARIOS
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoPropietario()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getPropietarios()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosRemolques" removableSort showGridlines ref="clientes" :value="propietario"
                    responsiveLayout="scroll" :paginator="true" :rows="10" :globalFilterFields="[
                        'id',
                        'numRegIdTrib',
                        // 'descripcion',
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros."
                    :loading="loadingPropietarios">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosRemolques['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column sortable field="id" header="ID" :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.id }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.nombre }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="numRegIdTrib" header="No. Identificación"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.numRegIdTrib }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="residenciaFiscal.pais" header="Residencia Fiscal"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.residenciaFiscal.pais }}
                        </template>
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Editar/Eliminar">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editar(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="confirmDeleteProduct(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Propietario from "./Propietario.vue";
import html2pdf from "html2pdf.js";

export default {
    components: { Propietario },

    data() {
        return {
            loadingMensaje: "",
            loading: false,
            dialogoNuevoPropietario: false,

            filtrosRemolques: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                'numRegIdTrib': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                // 'pais.pais': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },

            },
            loadingPropietarios: false,
            objetoEditado: {},

            dialogDelete: false,
            editedIndex: -1,
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        propietario() {
            return this.$store.state.listCEPropietarioStore;
        },
    },
    created() {
        this.getPropietarios()
    },
    methods: {
        nuevoPropietario() {
            this.dialogoNuevoPropietario = true;

            if (this.$store.state.cEPropietarioStore != 0) {
                let obj = {
                    id: 0,
                    numRegIdTrib: '',
                    residenciaFiscal: {
                        pais: ''
                    },
                    usuarioCreador: '',
                    usuarioModificador: '',
                }
                this.$store.state.cEPropietarioStore = { ...obj }
            }
        },

        editar(data) {
            this.$store.state.cEPropietarioStore = { ...data }
            this.dialogoNuevoPropietario = true
        },

        confirmDeleteProduct(data) {
            this.editedIndex = this.propietario.indexOf(data)
            this.objetoEditado = { ...data };
            this.dialogDelete = true;
        },

        async getPropietarios() {
            this.loadingPropietarios = true;
            try {
                let response = await axios.get('ComercioExterior/GetPropietarios/' + this.empresa.empresaBase)
                this.$store.state.listCEPropietarioStore = response.data
                // console.log(response)
                this.loadingPropietarios = false;

            } catch (error) {
                console.log(error)
                this.loadingPropietarios = false;
            }
        },

        async eliminar() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre

                let response = await axios.put('ComercioExterior/PutPropietarioEstatus/' + this.empresa.empresaBase, this.objetoEditado)
                this.propietario.splice(this.editedIndex, 1)

                this.dialogDelete = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Propietario eliminado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
    },
};
</script>