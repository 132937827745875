<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 600px">
            <!-- COMPLEMENTO COMERCIO EXTERIOR -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    COMPLEMENTO COMERCIO EXTERIOR
                </h1>
            </div>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- MOTIVO TRASLADO -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.motivoTraslado" :options="motivoTraslado"
                            optionLabel="motivoTraslado" />
                        <label>Motivo de Traslado</label>
                    </span>
                </div>
                <!-- TIPO OPERACION -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.tipoOperacion" :options="tipoOperacion" optionLabel="tipoOperacion" />
                        <label>Tipo de Operación</label>
                    </span>
                </div>
                <!-- CLAVE DE PEDIMENTO -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.claveDePedimento" :options="claveDePedimento"
                            optionLabel="claveDePedimento" />
                        <label>Clave de Pedimento</label>
                    </span>
                </div>
                <!-- CERTIFICADO DE ORIGEN -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.certificadoOrigen" :options="certificadoOrigen"
                            optionLabel="certificadoOrigen" />
                        <label>Certificado de Origen</label>
                    </span>
                </div>
                <!-- NUM CERTIFICADO ORIGEN -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <InputText type="text" v-model="comercio.numCertificadoOrigen" />
                        <label>Número de Certificado de Orígen</label>
                    </span>
                </div>
                <!-- NUMERO EXPORTADOR CONFIABLE -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <InputText type="text" v-model="comercio.numeroExportadorConfiable" />
                        <label>Número de Exportador Confiable</label>
                    </span>
                </div>
                <!-- INCOTERM -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.incoterm" :options="incoterm" optionLabel="incoterm" />
                        <label>Incoterm</label>
                    </span>
                </div>
                <!-- SUBDIVISION -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <InputText type="text" v-model="comercio.subdivision" />
                        <label>Subdivisión</label>
                    </span>
                </div>
                <!-- OBSERVACIONES -->
                <div class="field col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="comercio.observaciones" />
                        <label>Observaciones</label>
                    </span>
                </div>
                <!-- TOTAL TIPO CAMBIO USD -->
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <InputNumber mode="decimal" locale="en-US" :minFractionDigits="4"
                            v-model="comercio.tipoCambioUSD" />
                        <label>Tipo de CambioUSD</label>
                    </span>
                </div>
                <!-- TOTAL USD -->
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <InputNumber mode="decimal" locale="en-US" :minFractionDigits="2" v-model="comercio.totalUSD" />
                        <label>Total USD</label>
                    </span>
                </div>
            </div>

            <!-- DATOS DEL EMISOR -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    EMISOR
                </h1>
            </div>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- EMISOR -->
                <div class="field col-12">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.emisor" :options="emisores" optionLabel="nombre" />
                        <label>Emisor</label>
                    </span>
                </div>
            </div>

            <!-- DATOS DEL O LOS PROPIETARIOS -->
            <template v-if="comercio.motivoTraslado.clave === '05'">
                <div class="field col-12 text-center">
                    <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                        PROPIETARIO
                    </h1>
                </div>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- PROPIETARIO -->
                    <div class="field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <Dropdown v-model="propietarioSelect" :options="propietarios" optionLabel="nombre" />
                                <label>Propietario</label>
                            </span>
                            <Button icon="pi pi-plus" class="p-button-success" @click="pushPropietario" />
                        </div>
                    </div>
                    <!-- TABLA DE PROPIETARIOS -->
                    <div class="field col-12">
                        <DataTable :value="comercio.propietarios" :scrollable="true" scrollHeight="300px"
                            scrollDirection="both" class="mt-3">
                            <Column header="Acciones"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '250px', 'min-width': '10rem' }" frozen>
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                        @click="deletePropietario(slotProps.data)" />
                                </template>
                            </Column>
                            <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                            </Column>
                            <Column field="numRegIdTrib" header="Num Reg Id Trib"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
                            </Column>
                            <Column field="residenciaFiscal.pais" header="ResidenciaFiscal"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '250px' }">
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </template>

            <!-- DATOS DEL RECEPTOR -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    RECEPTOR
                </h1>
            </div>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- RECEPTOR -->
                <div class="field col-12">
                    <span class="p-float-label">
                        <Dropdown v-model="comercio.receptor" :options="receptores" optionLabel="nombre" />
                        <label>Receptor</label>
                    </span>
                </div>
            </div>

            <!-- DATOS DEL O LOS DESTINATARIOS -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    DESTINATARIOS
                </h1>
            </div>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- DESTINATARIO -->
                <div class="field col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <Dropdown v-model="destinatarioSelect" :options="destinatarios" optionLabel="nombre" />
                            <label>Destinatario</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushDestinatario" />
                    </div>
                </div>
                <!-- TABLA DE DESTINATARIOS -->
                <div class="field col-12">
                    <DataTable :value="comercio.destinatarios" :scrollable="true" scrollHeight="300px"
                        scrollDirection="both" class="mt-3">
                        <Column header="Acciones"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '250px', 'min-width': '10rem' }" frozen>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    @click="deleteDestinatario(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                        <Column field="numRegIdTrib" header="Num Reg Id Trib"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
                        </Column>
                    </DataTable>
                </div>
            </div>

            <!-- DATOS DE MERCANCIAS -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    MERCANCÍAS
                </h1>
            </div>
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- MERCANCIA -->
                <div class=" field col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" v-model="mercancia" :suggestions="filteredMercancia"
                                @complete="searchMercancia($event)" field="mercancia">
                            </AutoComplete>
                            <label>Mercancía</label>
                        </span>
                    </div>
                </div>
                <!--  FRACCION ARANCELARIA  -->
                <div class=" field col-12 ">
                    <span class="p-float-label">
                        <AutoComplete style="text-align:left" v-model="mercancia.fraccionArancelaria"
                            :suggestions="filteredFraccion" @complete="searchFraccion($event)" field="fraccion">
                        </AutoComplete>
                        <label>Fracción Arancelaria</label>
                    </span>
                </div>
                <!-- NO DE INDENTIFICACION -->
                <div class="justify-right field col-12">
                    <span class="p-float-label">
                        <InputText style="text-align: left" type="text" v-model="mercancia.noIdentificacion" />
                        <label>No. Identificación</label>
                    </span>
                </div>
                <!-- CANTIDAD ADUANA -->
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <span class="p-float-label">
                        <InputNumber mode="decimal" locale="en-US" :minFractionDigits="3"
                            v-model="mercancia.cantidadAduana" />
                        <label>Cantidad Aduana</label>
                    </span>
                </div>
                <!-- UNIDAD ADUANA -->
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <span class="p-float-label">
                        <Dropdown v-model="mercancia.unidadAduana" :options="unidadAduana" optionLabel="unidadAduana">
                        </Dropdown>
                        <label>Unidad Aduanas</label>
                    </span>
                </div>
                <!-- VALOR UNITARIO ADUANA -->
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber v-model="mercancia.valorUnitarioAduana" mode="decimal" locale="en-US"
                                :minFractionDigits="2" />
                            <label>Valor unitario Aduana</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushMercancia" />
                    </div>
                </div>
                <!-- VALOR DOLARES -->
                <div class="justify-right field col-12 md:col-3 sm:col-12">
                    <span class="p-float-label">
                        <InputNumber v-model="valorDolares" mode="decimal" locale="en-US" :minFractionDigits="2" />
                        <label>Valor Dólares</label>
                    </span>
                </div>

                <!-- TABLA DE MERCANCIAS -->
                <div class="field col-12">
                    <DataTable :value="comercio.mercancias" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                        class="mt-3">
                        <template #header>
                            <div style="text-align: left">
                                <Button icon="pi pi-external-link" label="Conciliar desde conceptos" @click="conciliar()" />
                            </div>
                        </template>
                        <Column header="Acciones"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'min-width': '10rem' }" frozen>
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    @click="deleteMercancia(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="noIdentificacion" header="No. Identificación"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                        <Column field="fraccionArancelaria.fraccion" header="Fracción Arancelaria"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                        <Column field="cantidadAduana" header="Cantidad Aduana"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                            <template #body="{ data }">
                                <span>{{ formatCantidad(data.cantidadAduana) }}</span>
                            </template>
                        </Column>
                        <Column field="unidadAduana.unidadAduana" header="Unidad Aduana"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                        <Column field="valorUnitarioAduana" header="Valor Unitario Aduana"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.valorUnitarioAduana) }}</span>
                            </template>
                        </Column>
                        <Column field="valorDolares" header="Valor Dólares"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.valorDolares) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            propietarioSelect: {},
            destinatarioSelect: {},
            mercancia:
            {
                noIdentificacion: '',
                fraccionArancelaria: null,
                cantidadAduana: 1,
                unidadAduana: {},
                valorUnitarioAduana: 0,
                valorDolares: 0,
                descripcionesEspecificas:
                {
                    marca: '',
                    modelo: '',
                    subModelo: '',
                    numeroSerie: '',
                },
                mercancia: '',
            },
            filteredFraccion: null,
            filteredMercancia: null,
        }
    },
    computed: {
        comercio() {
            return this.$store.state.comercioExteriorStore.comercio;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        motivoTraslado() {
            return this.$store.state.motivoTrasladoStore;
        },
        tipoOperacion() {
            return this.$store.state.tipoOperacionStore;
        },
        claveDePedimento() {
            return this.$store.state.claveDePedimentoStore;
        },
        certificadoOrigen() {
            return this.$store.state.certificadoOrigenStore;
        },
        incoterm() {
            return this.$store.state.incotermStore;
        },
        emisores() {
            return this.$store.state.listCEEmisorStore;
        },
        propietarios() {
            return this.$store.state.listCEPropietarioStore;
        },
        receptores() {
            return this.$store.state.listCEReceptorStore;
        },
        destinatarios() {
            return this.$store.state.listCEDestinatarioStore;
        },
        fraccionArancelaria() {
            return this.$store.state.listFraccionArancelariaStore;
        },
        unidadAduana() {
            return this.$store.state.listUnidadAduanaStore;
        },
        valorDolares() {
            let resultado = 0;
            resultado = this.mercancia.cantidadAduana * this.mercancia.valorUnitarioAduana;
            return resultado;
        },
        mercancias() {
            return this.$store.state.listCEMercanciaStore;
        },
    },
    created() {

    },
    methods: {
        pushPropietario() {
            let prop = { ...this.propietarioSelect }
            this.comercio.propietarios.push(prop);
            prop = {}
            this.propietarioSelect = null;
        },

        deletePropietario(item) {
            let indice = this.comercio.propietarios.indexOf(item);
            this.comercio.propietarios.splice(indice, 1);
        },

        pushDestinatario() {
            let des = { ...this.destinatarioSelect }
            this.comercio.destinatarios.push(des);
            des = {}
            this.destinatarioSelect = null;
        },

        deleteDestinatario(item) {
            let indice = this.comercio.destinatarios.indexOf(item);
            this.comercio.destinatarios.splice(indice, 1);
        },

        searchFraccion(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredFraccion = [...this.fraccionArancelaria];
                } else {
                    this.filteredFraccion = this.fraccionArancelaria.filter(
                        (country) => {
                            return country.fraccion
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        searchMercancia(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredMercancia = [...this.mercancias];
                } else {
                    this.filteredMercancia = this.mercancias.filter(
                        (country) => {
                            return country.mercancia
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        pushMercancia() {
            let mer = { ...this.mercancia }
            if (mer.noIdentificacion === '') {
                this.$toast.add({ severity: 'error', detail: 'Indique "No. Identificación"', life: 5000 });
                return
            }
            if (!mer.fraccionArancelaria) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Fracción Arancelaria"', life: 5000 });
                return
            }
            if (mer.cantidadAduana == 0 || mer.cantidadAduana == '' || !mer.cantidadAduana) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Cantidad Aduana"', life: 5000 });
                return
            }
            if (!mer.unidadAduana) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Unidad Aduana"', life: 5000 });
                return
            }
            if (mer.valorUnitarioAduana == 0 || mer.valorUnitarioAduana == '' || !mer.valorUnitarioAduana) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Valor Unitario Aduana"', life: 5000 });
                return
            }
            if (this.valorDolares == 0 || this.valorDolares == '' || !this.valorDolares) {
                this.$toast.add({ severity: 'error', detail: 'Indique "Valor Dólares"', life: 5000 });
                return
            }

            let def = {
                noIdentificacion: '',
                fraccionArancelaria: null,
                cantidadAduana: 1,
                unidadAduana: {},
                valorUnitarioAduana: 0,
                valorDolares: 0,
                descripcionesEspecificas:
                {
                    marca: '',
                    modelo: '',
                    subModelo: '',
                    numeroSerie: '',
                },
                mercancia: '',
            }
            mer.valorDolares = this.valorDolares;
            this.comercio.mercancias.push(mer);
            mer = {}
            this.mercancia = { ...def };
        },

        deleteMercancia(item) {
            let indice = this.comercio.mercancias.indexOf(item);
            this.comercio.mercancias.splice(indice, 1);
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        conciliar() {
            this.comercio.mercancias = [];
            let conceptos = this.$store.state.comercioExteriorStore.conceptos

            for (var c of conceptos) {
                try {
                    let indice = this.mercancias.findIndex(x => x.noIdentificacion === c.noIdentificacion)
                    let Obj = { ...this.mercancias[indice] }
                    Obj.cantidadAduana = c.cantidad;
                    Obj.valorUnitarioAduana = c.valorUnitario;
                    Obj.valorDolares = c.importe;
                    console.log(Obj);
                    this.comercio.mercancias.push(Obj);
                    Obj = {};
                } catch (error) {
                    console.log(error)
                }

            }
            this.comercio.totalUSD = this.sum(this.comercio.mercancias, 'valorDolares')
        },
    },
}
</script>
<style>
.p-autocomplete .p-autocomplete-panel {
    width: 100%
}
</style>