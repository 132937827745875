<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOG PARA CANCELAR CFDI -->
        <Dialog :visible.sync="dialogCancelacion" :style="{ width: '95vw' }" :closable="false">
            <Cancelacion @cerrarVentanaCancelacion="dialogCancelacion = false"></Cancelacion>
        </Dialog>
        <!-- NUEVA FACTURA -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogFactura" :closeOnEscape="true" :closable="false"
            :modal="false" :style="{ width: '95vw' }">
            <factura @closeFactura="closeDialogFactura"></factura>
        </Dialog>
        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="false" position="top">
            <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
        </Dialog>
        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        <span style="cursor:pointer" @click="getTimbres()">Notas de Crédito </span>
                        <ProgressSpinner v-if="datosGenerales == 1"
                            style="width:36px;height:36px;  vertical-align: middle;" strokeWidth="8" fill="#EEEEEE"
                            animationDuration=".5s" />
                        <Badge v-if="datosGenerales == 0" :value="timbresDisponibles" @click="getTimbres()" class="mr-2"
                            size="large" severity="success">
                        </Badge>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <Button icon="pi pi-plus" @click="nuevaFactura()" class="mx-2 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getFactura()" class="p-button-rounded" />
                    </div>
                </div>
            </template>

            <template #content>
                <DataTable :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsFacturas" :scrollable="true"
                    scrollHeight="800px" scrollDirection="both" :loading="loading"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Notas de Credito."
                    :paginator="true" :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column header="Acciones"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100"
                                v-tooltip.bottom="'PDF'" @click="openDialogPdf(slotProps.data)" />
                            <Button icon="pi pi-replay" class="p-button-rounded p-button-warning"
                                v-tooltip.bottom="'Similar'" @click="facturasimilar(slotProps.data)" />
                            <div v-if="slotProps.data.estatus === 'Sin Timbrar'">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-info"
                                    v-tooltip.bottom="'Editar'" @click="editarFactura(slotProps.data)" />
                            </div>
                            <div v-if="slotProps.data.estatus === 'Vigente'">
                                <Button v-if="slotProps.data.estatusCancelacion != 'EnProceso'" icon="pi pi-ban"
                                    class="p-button-rounded p-button-danger" v-tooltip.bottom="'Cancelar'"
                                    @click="openCancelacion(slotProps.data)"></Button>
                                <Button icon="pi pi-check-circle" style="background:green" class="p-button-rounded "
                                    v-tooltip.bottom="'Actualizar Estatus SAT'"
                                    @click="actualizarEstatus(slotProps.data)"></Button>
                            </div>
                        </template>
                    </Column>
                    <Column field="idComprobante" header="id"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="serie" header="Serie"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="folio" header="Folio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                        <template #body="slotProps">
                            <span
                                :class="'customer-badge status-' + slotProps.data.estatus + slotProps.data.estatusCancelacion">{{
                                    slotProps.data.folio
                                }}</span>
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfc" header="RFC"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombre" header="Nombre"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column field="timbreFiscalDigital.folioFiscal" header="Folio fiscal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="formaPago.formaPago" header="Forma de Pago"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="metodoPago.metodoPago" header="Método de Pago"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="estatusCancelacion" header="Solicitud de Cancelación"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import factura from './NotaCredito.vue';
import moment from 'moment'
import ComprobantePdf from "../../Pdf/ComprobanteNotaCreditoPdf.vue";
import Cancelacion from "../../Cancelacion/Cancelacion.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api/";


export default {
    components: { factura, ComprobantePdf, Cancelacion },
    data() {
        return {
            dialogFactura: false,
            dialogPdf: false,
            fechaI: new Date(),
            fechaF: new Date(),
            loading: false,
            dialogCancelacion: false,
            dialogLoading: false,
            loadingMensaje: '',
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                serie: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                folio: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                rfc: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                nombre: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                'timbreFiscalDigital.folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            datosGenerales: 0
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        usuario() {
            return this.$store.state.usuario;
        },
        itemsFacturas() {
            return this.$store.state.listNotaCreditoStore;
        },
        timbresDisponibles() {
            return this.$store.state.timbresDisponiblesStore;
        },
    },
    created() {
        this.getEmpresa();
        this.inicializa();
    },
    methods: {
        async getEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.usuario.empresaBase);
                this.$store.state.empresaStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        closeDialogFactura() {
            this.dialogFactura = false;
        },

        nuevaFactura() {
            let comprobante = this.$store.state.notaCreditoStore.idNotaCredito;
            console.log(comprobante)
            if (comprobante == -1) {
                let nuevo = {
                    idNotaCredito: 0,
                    folioFiscal: '',
                    serie: '',
                    folio: '',
                    fecha: new Date(),
                    formaPago: {},
                    condicionesPago: '',
                    subTotal: 0,
                    descuento: 0,
                    moneda: {
                        id: 100,
                        clave: 'MXN',
                        descripcion: 'Peso Mexicano',
                        moneda: 'MXN | Peso Mexicano'
                    },
                    tipoCambio: 0,
                    total: 0,
                    tipoComprobante: 'E',
                    tipoComprobanteInterno: 'NC',
                    metodoPago: {},
                    empresa: {},
                    cliente: {
                        rfc: '',
                        nombre: '',
                        cliente: '',
                        usoCfdi: {},
                        regimenFiscal: { regimenFiscal: '' }
                    },
                    rfc: '',
                    nombre: '',
                    atn: '',
                    notas: '',
                    estatus: 'Sin Timbrar',
                    estatusCancelacion: '',
                    usuarioCreador: '',
                    usuarioModificador: '',
                    usuarioAutoriza: '',
                    conceptos: [],
                    impuestos: {
                        traslados: [],
                        retenciones: [],
                    },
                    cfdiRelacionados: [
                        {
                            tipoRelacion: {},
                            cfdiRelacionados: [],
                        }
                    ],
                    timbreFiscalDigital: {
                        folioFiscal: '',
                        fechaTimbrado: new Date(),
                        selloCFDI: { base64: '' },
                        noCertificado: '',
                        noCertificadoSAT: '',
                        selloSAT: { base64: '' },
                        cadenaOriginal: '',
                        serie: '',
                        folio: '',
                        Error: '',
                        archivoXml: '',
                    }
                }
                this.$store.state.notaCreditoStore = { ...nuevo }
            }
            this.dialogFactura = true;
        },

        facturasimilar(item) {
            console.log(item)
            let similar = { ...item }
            let timbreFiscalDigital = {
                folioFiscal: '',
                fechaTimbrado: new Date(),
                selloCFDI: { base64: '' },
                noCertificado: '',
                noCertificadoSAT: '',
                selloSAT: { base64: '' },
                cadenaOriginal: '',
                serie: '',
                folio: '',
                Error: '',
                archivoXml: '',
            }

            similar.idNotaCredito = 0
            similar.folioFiscal = ''
            similar.folio = ''
            similar.estatus = 'Sin Timbrar'
            similar.estatusCancelacion = ''
            similar.fecha = new Date()
            similar.timbreFiscalDigital = { ...timbreFiscalDigital }

            this.$store.state.notaCreditoStore = { ...similar }
            this.dialogFactura = true;
        },

        editarFactura(item) {
            let editar = { ...item }
            this.$store.state.notaCreditoStore = { ...editar }
            this.dialogFactura = true;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async getFactura() {
            this.loading = true;
            this.$store.state.listNotaCreditoStore = []
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(this.fechaF).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('Comprobantes/GetComprobantes/' + this.usuario.empresaBase + '/NC/' + fI + '/' + fF + '/' + this.empresa.rfc);
                console.log(response.data)
                let array = response.data
                this.$store.state.listNotaCreditoStore = array.sort(((a, b) => b.folio - a.folio))
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        openDialogPdf(data) {
            // console.log(data)
            this.dialogPdf = true;
            this.$store.state.notaCreditoStore = { ...data }
        },

        closeDialogPdf() {
            this.dialogPdf = false;
            this.$store.state.notaCreditoStore.idNotaCredito = -1;
        },

        openCancelacion(data) {
            this.dialogCancelacion = true
            this.$store.state.cancelacionStore = { ...data }
        },
        async actualizarEstatus(data) {
            this.loadingMensaje = 'Consultando estatus...'
            let actualizado = { ...data }

            console.log(this.usuario)
            this.dialogLoading = true;
            let actualizaEstatus = {
                idComprobante: data.idComprobante,
                rfc: data.empresa.rfc,
                folioFiscal: data.folioFiscal,
                motivoCancelacion: {},
                uuidSustituir: '',
                total: data.total,
                usuarioModificador: this.usuario.nombre
            }
            // console.log(actualizaEstatus)
            try {
                let response = await axios.post('Comprobantes/ActualizarEstatusSat/' + this.usuario.empresaBase + '/', actualizaEstatus)
                actualizado.estatus = response.data.estatus
                actualizado.estatusCancelacion = response.data.estatusCancelacion

                // BUSCAMOS EL INDICE
                let indice = this.itemsFacturas.findIndex(x => x.folioFiscal === actualizado.folioFiscal)
                console.log(indice)
                Object.assign(this.itemsFacturas[indice], actualizado)
                console.log(actualizado)

                this.$toast.add({ severity: 'success', detail: 'Estatus actualizado.', life: 5000 });
                this.dialogLoading = false;

            } catch (err) {
                console.log(err.response.data)
                this.$toast.add({ severity: 'error', detail: err.response.data, life: 5000 });
                this.dialogLoading = false;
            }
        },

        async inicializa() {
            this.datosGenerales = 1
            // this.dialogLoading = true;
            // this.loadingMensaje = 'Consultando datos...'
            await this.getEmpresa();
            await this.getTimbres();
            this.datosGenerales = 0
            // this.dialogLoading = false;
        },

        async getEmpresa() {

            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.usuario.empresaBase);
                this.$store.state.empresaStore = response.data
            } catch (error) {
                console.log(error)
                this.dialogLoading = false;
            }
        },

        async getTimbres() {
            this.datosGenerales = 1
            // this.dialogLoading = true;
            // this.loadingMensaje = 'Consultando timbres...'
            try {
                let response = await axios.get('Comprobantes/GetTimbres/' + this.empresa.rfc);
                this.$store.state.timbresDisponiblesStore = response.data
                this.datosGenerales = 0
                // this.dialogLoading = false;
            } catch (error) {
                console.log(error)
                this.datosGenerales = 0
                // this.dialogLoading = false;
            }
        },
    },
}
</script>
<style>
.orange-button {
    background-color: #F46723;
}

.status-Vigente {
    background-color: #4CAF50;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-VigenteSinRespuesta {
    background-color: #4CAF50;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-VigenteEnProceso {
    background-color: #FF6F00;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-Sin {
    background-color: #2196F3;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-Cancelado {
    background-color: #B71C1C;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-CanceladoCanceladoSinAceptacion {
    background-color: #B71C1C;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}
</style>