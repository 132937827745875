<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Almacén
                    <Button @click="guardarOactualizar()" icon="pi pi-save"
                        class="p-button-rounded p-button-success p-button-outlined" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- CLAVE-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="almacen.clave" style="text-align: left" type="text" />
                            <label>Clave</label>
                        </span>
                    </div>
                    <!-- CLAVE-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="almacen.descripcion" style="text-align: left" type="text" />
                            <label>Descripción</label>
                        </span>
                    </div>
                    <!-- CLAVE-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="almacen.direccion" style="text-align: left" type="text" />
                            <label>Dirección</label>
                        </span>
                    </div>
                    <!-- CLAVE-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="almacen.tipo" style="text-align: left" type="text" />
                            <label>Tipo</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
export default {
    data() {
        return {
            loading: false,
            loadingMensaje: '',
        }
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        almacen() {
            return this.$store.state.almacenStore
        },

    },
    created() {
    },
    methods: {
        guardarOactualizar() {
            if (this.$store.state.almacenStore.idAlmacen == 0) {
                this.postAlmacen();
            } else {
                this.updateAlmacen();
            }
        },
        async postAlmacen() {
            this.loading = true;
            this.loadingMensaje = 'Guardando almacénn, espere...'

            this.almacen.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('Almacenes/PostAlmacen/' + this.empresa.empresaBase, this.almacen)
                console.log(response.data)

                this.almacen.idAlmacen = response.data
                this.$store.state.listaAlmacenesStore.push(this.almacen)
                this.loading = false;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Almacén guardado con exito.', life: 3000 });
                this.cierraVentana();

            } catch (error) {
                console.log(error)

                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });

            }
        },

        async updateAlmacen() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando almacén, espere...'

            this.almacen.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.post('Almacenes/UpdateAlmacen/' + this.empresa.empresaBase, this.almacen)
                console.log(response.data)
                this.loading = false;

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaAlmacenesStore.findIndex(x => x.idAlmacen === this.almacen.idAlmacen);
                Object.assign(this.$store.state.listaAlmacenesStore[indice], this.almacen)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Almacén actualizado con exito.', life: 3000 });

                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },
        cierraVentana() {
            this.$emit('closeAlmacen')
        },

    },
}
</script>