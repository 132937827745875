<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 800px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold  border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>

            <!-- DIALOG DEL PDF -->
            <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
                <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
            </Dialog>

            <Card>
                <!-- TITULO CARD -->
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cierraVentana()" />
                        </div>

                        <div class="text-center field col-4">
                            Pedido
                        </div>
                        <div class="text-right field col-4">
                            <template v-if="ventaPedido.idVenta === 0">
                                <Button icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined"
                                    @click="postVentaPedido" />
                            </template>
                            <template v-if="ventaPedido.idVenta != 0">
                                <Button icon="pi pi-save" class="p-button-rounded p-button-warning p-button-outlined"
                                    @click="editVentaPedido()" />
                            </template>
                            <Button icon="pi pi-file-pdf"
                                class="ml-3 mr-1 p-button-rounded p-button-danger p-button-outlined"
                                @click="vistaPrevia" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <TabView>
                        <TabPanel header="Datos generales">
                            <General></General>
                        </TabPanel>
                        <TabPanel header="Conceptos">
                            <Conceptos></Conceptos>
                        </TabPanel>
                    </TabView>
                </template>
                <template #footer>
                    <div style="justify-content:right" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber v-model="$store.state.ventaPedidoStore.subTotal" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>SubTotal</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber class="color" v-model="$store.state.ventaPedidoStore.total" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>Total</label>
                            </span>
                        </div>
                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import General from './VentaPedidoGeneral';
import Conceptos from './VentaPedidoConcepto'
import ComprobantePdf from "../Pdf/VentaPedidoPdf.vue";
export default {
    components: {
        General,
        Conceptos,
        ComprobantePdf,
    },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            datosEmpresa: null,
            dialogPdf: false,
        }
    },
    computed: {
        ventaPedido() {
            return this.$store.state.ventaPedidoStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.Inicializa();
    },
    methods: {
        //INICIALES
        async Inicializa() {
            this.loading = true;
            await this.GetEmpresa();
            await this.GetSerie();
            await this.GetClientes();
            await this.GetMoneda();
            await this.GetProyectos();
            await this.GetConceptos();
            await this.GetObjImpuestos();
            await this.GetTraslados();
            await this.GetRetenciones();
            this.loading = false;
        },

        async GetEmpresa() {
            this.loadingMensaje = 'Consultando datos de la empresa'
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetSerie() {
            this.loadingMensaje = 'Consultando serie'
            try {
                let response = await axios.get("Ventas/GetVentasPedidoSeries/" + this.token.empresaBase);
                this.$store.state.listVentaPedidoSerieStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async GetClientes() {
            this.loadingMensaje = 'Consultanod Clientes';
            try {
                let response = await axios.get("Clientes/GetClientes/" + this.token.empresaBase);
                this.$store.state.listClienteStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async GetMoneda() {
            this.loadingMensaje = 'Consultando Monedas';
            if (this.$store.state.monedaStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },
        async GetProyectos() {
            this.loadingMensaje = 'Consultando Proyectos';
            try {
                let response = await axios.get('Proyectos/GetProyectos/' + this.token.empresaBase)
                this.$store.state.listProyectosStore = response.data
            } catch (error) {
                this.loadingItemsProyectos = false;
            }
        },
        async GetConceptos() {
            try {
                this.loadingMensaje = 'Consultando Conceptos';
                let response = await axios.get('Conceptos/GetConceptos/' + this.token.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetObjImpuestos() {
            this.loadingMensaje = 'Consultando Objeto de impuestos';
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetTraslados() {
            this.loadingMensaje = 'Consultando Impuestos Trasladados';
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetRetenciones() {
            this.loadingMensaje = 'Consultando Impuestos Retenidos';
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async postVentaPedido() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                let objPedido = { ...this.ventaPedido }
                objPedido.empresa = this.datosEmpresa
                objPedido.rfc = objPedido.cliente.rfc
                objPedido.nombre = objPedido.cliente.nombre

                objPedido.usuarioCreador = this.token.nombre

                let response = await axios.post('Ventas/PostVentaPedido/' + this.token.empresaBase, objPedido)
                console.log(response.data)
                objPedido.idVenta = response.data.id;
                objPedido.folio = response.data.folio;
                this.$store.state.listVentaPedidoStore.push(objPedido)

                if (objPedido.idRelacion != 0) {
                    let indice = this.$store.state.listVentaCotizacionStore.findIndex(x => x.idVenta === objPedido.idRelacion);
                    this.$store.state.listVentaCotizacionStore[indice].estatus = 'Pedido';
                    console.log(indice)
                    console.log(this.$store.state.listVentaCotizacionStore[indice])
                }

                let array = this.$store.state.listVentaPedidoStore;
                this.$store.state.listVentaPedidoStore = array.sort(((a, b) => b.folio - a.folio))
                this.$store.state.ventaPedidoStore.idVenta = -1;

                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Pedido guardado con éxito', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },

        async editVentaPedido() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                this.ventaPedido.empresa = this.datosEmpresa
                this.ventaPedido.rfc = this.ventaPedido.cliente.rfc
                this.ventaPedido.nombre = this.ventaPedido.cliente.nombre
                this.ventaPedido.estatus = 'Modificada'

                this.ventaPedido.usuarioModificador = this.token.nombre

                let response = await axios.post('Ventas/UpdateVentasPedidos/' + this.token.empresaBase, this.ventaPedido)
                console.log(response.data)

                let indice = this.$store.state.listVentaPedidoStore.findIndex(x => x.idVenta === this.ventaPedido.idVenta);
                Object.assign(this.$store.state.listVentaPedidoStore[indice], this.ventaPedido)

                this.loading = false;
                this.cierraVentana();
                this.$toast.add({ severity: 'success', detail: 'Pedido guardado con éxito', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeVentaPedido')
            this.$store.state.ventaPedidoStore.idVenta = -1;
        },

        vistaPrevia() {
            this.dialogPdf = true;
            console.log(this.$store.state.ventaPedidoStore)
        },

        closeDialogPdf() {
            this.dialogPdf = false

            if (this.ventaPedido.folio != '') {
                this.$emit('closeVentaPedido')
            }
        },
    },
}
</script>