<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 700px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>
            <Card class="shadow-none">
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cierraVentana()" />
                        </div>
                        ADDENDA VW PSV
                        <div class="text-right field col-4">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                v-tooltip.bottom="'Generar'" @click="PostAddenda" />
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                                v-tooltip.bottom="'Vista Previa'" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <TabView>
                        <TabPanel header="Datos generales">
                            <addenda-general></addenda-general>
                        </TabPanel>
                        <TabPanel header="Archivos">
                            <addenda-archivos></addenda-archivos>
                        </TabPanel>
                        <TabPanel header="Partes">
                            <addenda-partes></addenda-partes>
                        </TabPanel>
                    </TabView>
                </template>
            </Card>
        </ScrollPanel>
        <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import addendaGeneral from './AddendaVW_PSVGeneral.vue'
import addendaArchivos from './AddendaVW_PSVArchivos.vue'
import addendaPartes from './AddendaVW_PSVPartes.vue'

export default {
    components: {
        addendaGeneral,
        addendaArchivos,
        addendaPartes,
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        addenda() {
            return this.$store.state.addendaVwPsvStore;
        },
    },
    created() {
        this.Iniciales();
    },
    methods: {
        //CARGA DE CATALOGOS INICIALES
        async Iniciales() {
            this.loading = true;
            await this.GetDivisionVwPsv();
            await this.GetTipoDocumentoFiscalVwPsv();
            await this.GetCodigoImpuestoVwPsv();
            await this.GetTipoMonedaVwPsv();
            await this.GetOrigenVwPsv();
            await this.GetProveedorVwPsv();
            await this.GetSolicitanteVwPsv();
            this.loading = false;
        },

        async GetDivisionVwPsv() {
            this.loadingMensaje = 'Consultando Divisiones...'
            try {
                let response = await axios.get('Addendas/GetDivisionVwPsv/');
                this.$store.state.divisionVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetTipoDocumentoFiscalVwPsv() {
            this.loadingMensaje = 'Consultando Tipo de Documento Fiscal...'
            try {
                let response = await axios.get('Addendas/GetTipoDocumentoFiscalVwPsv/');
                this.$store.state.tipoDocumentoFiscalVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetCodigoImpuestoVwPsv() {
            this.loadingMensaje = 'Consultando Código de Impuestos...'
            try {
                let response = await axios.get('Addendas/GetCodigoImpuestoVwPsv/');
                this.$store.state.codigoImpuestoVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetTipoMonedaVwPsv() {
            this.loadingMensaje = 'Consultando Tipo de Moneda...'
            try {
                let response = await axios.get('Addendas/GetTipoMonedaVwPsv/');
                this.$store.state.tipoMonedaVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetOrigenVwPsv() {
            this.loadingMensaje = 'Consultando Origen...'
            try {
                let response = await axios.get('Addendas/GetOrigenVwPsv/');
                this.$store.state.origenVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetProveedorVwPsv() {
            this.loadingMensaje = 'Consultando Proveedores...'
            try {
                let response = await axios.get('Addendas/GetProveedorVwPsv/' + this.token.empresaBase);
                this.$store.state.proveedorVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetSolicitanteVwPsv() {
            this.loadingMensaje = 'Consultando Solicitantes...'
            try {
                let response = await axios.get('Addendas/GetSolicitanteVwPsv/' + this.token.empresaBase);
                this.$store.state.solicitanteVwPsvStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        cierraVentana() {
            this.$emit('cierraDialogVwPsv')
        },

        //FUNCIONES PARA ARMAR LA ADDENDA
        async PostAddenda() {
            this.loading = true;
            this.loadingMensaje = 'Generando Addenda Espere...';
            let ObjAddenda = { ...this.addenda }
            try {
                let response = await axios.post('Addendas/PostAddendaVwPsv/' + this.token.empresaBase, ObjAddenda);
                await this.download(response.data, ObjAddenda.folioFiscal + '_addenda_vw_psv', 'xml')
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Addenda generada con éxito', life: 5000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: 'error', detail: 'Error al generar addenda, verifique que todos los datos sean correctos', life: 5000 });
                this.loading = false;
            }
        },

        download(archivo, nombre, tipo) {
            var arrBuffer = this.base64ToArrayBuffer(archivo);
            var newBlob = new Blob([arrBuffer]);

            var data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = data;
            link.download = nombre + '.' + tipo;
            link.click();
            window.URL.revokeObjectURL(data);
            link.remove();
        },

        base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        },
    },
}
</script>
<style>
.color {
    background: #cfd3e9 !important;
}
</style>