<template>
    <div>
        <!-- NUEVO PROTOCOLO -->
        <Dialog :modal="false" position="top" :visible.sync="dialogoNuevo" :closeOnEscape="true" :closable="false"
            :style="{ width: '95vw' }">
            <Protocolo @cierraVentana="dialogoNuevo = false"></Protocolo>
        </Dialog>
        <Card class="shadow-none">
            <template #content>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-undo" @click="getRemisiones()" class="p-button-rounded" />
                    </div>
                </div>
                <DataTable class="mt-1 p-datatable-sm" :value="itemsRemisiones" :scrollable="true" scrollHeight="500px"
                    scrollDirection="both" :loading="loadingItemsRemisiones" :rowsPerPageOptions="[5, 10, 25, 50]"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :paginator="true" :rows="10">
                    <Column header="Acciones"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="abrirAutorizarProducto(slotProps.data)" icon="pi pi-check"
                                class="p-button-rounded p-button-success" v-tooltip.bottom="'Autorizar'" />
                        </template>
                    </Column>
                    <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
                    </Column>
                    <Column field="folio" header="Folio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                    </Column>
                    <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
                    </Column>
                    <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }"
                        sortable>
                    </Column>
                    <Column field="proyecto.descripcion" header="Proyecto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
                    </Column>
                    <Column field="fecha" header="Fecha" dataType="date"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fecha) }}
                        </template>
                    </Column>
                    <Column field="vigencia" header="Vigencia" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
                        sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.vigencia) }}
                        </template>
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
                        <template #body="slotProps">
                            {{ formatCurrency(slotProps.data.subTotal) }}
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
                        <template #body="slotProps">
                            {{ formatCurrency(slotProps.data.total) }}
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import Protocolo from "./Protocolo.vue";
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: { Protocolo },

    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            loadingItemsRemisiones: false,
            expandedRows: [],
            dialogoNuevo: false
        }
    },
    computed: {
        itemsRemisiones() {
            return this.$store.state.listProtocoloRemisionesStore;
        },
        empresa() {
            return this.$store.state.usuario;
        }
    },
    created() {

    },
    methods: {
        async getRemisiones() {
            try {
                this.loadingItemsRemisiones = true;
                let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get("Ventas/GetVentasRemisionesIncompletos/" + this.empresa.empresaBase + "/" + fechaInicial + "/" + fechaFinal);
                let array = response.data;
                this.$store.state.listProtocoloRemisionesStore = array.sort(((a, b) => b.folio - a.folio))

                this.loadingItemsRemisiones = false;
            } catch (error) {
                console.log(error);
                this.loadingItemsRemisiones = false;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        async abrirAutorizarProducto(item) {
            console.log(item)
            try {
                let response = await axios.get("Ventas/GetRemisionProtocolo/" + this.empresa.empresaBase + '/' + item.idVenta);
                let respuesta = response.data;

                console.log(respuesta)

                if (respuesta.idVentaRemision == 0 && respuesta.idVentaProtocolo == 0) {
                    this.$store.state.protocoloStore = { ...item }
                    this.agregaColumnas();
                    this.$store.state.protocoloStore.idVentaRemision = item.idVenta;
                    this.dialogoNuevo = true
                    console.log('conceptos', this.$store.state.protocoloStore.conceptos)
                } else {
                    this.$store.state.protocoloStore = { ...respuesta }
                    this.cantidadesEnCero();
                    this.dialogoNuevo = true
                }
                this.$store.state.protocoloStore.fechaProtocolo = new Date();
                this.$store.state.protocoloStore.tipoComprobante = 'Protocolo';
            } catch (error) {
                console.log(error);
            }
            console.log(item)
        },

        //AGREGA COLUMNAS FALTANTES A LOS CONCEPTOS PARA NUEVOS PROTOCOLOS
        agregaColumnas() {
            for (let a of this.$store.state.protocoloStore.conceptos) {
                a.cantidadEntregada = 0
                a.cantidadEntregar = 0
                a.cantidadRestante = 0
            }
        },
        cantidadesEnCero() {
            for (let a of this.$store.state.protocoloStore.conceptos) {
                a.cantidadEntregar = 0
            }
        },
        openDialogPdf() { },
    },
}
</script>
<style>
.p-datatable-a .p-datatable-thead>tr>th {
    background: #f5f5f5 !important;
    /* border-bottom: 1px solid #f46722; */
}
</style>