<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Mercancías
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- DESCRIPCION -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.descripcion" style="text-align: left" type="text" />
                            <label>Descripción</label>
                        </span>
                    </div>
                    <!-- No. IDENTIFICACION -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.noIdentificacion" style="text-align: left" type="text" />
                            <label>No. Identificación</label>
                        </span>
                    </div>
                    <!-- FRACCION ARANCELARIA -->
                    <div style="text-align:left" class="field col-12 md:col-12">
                        <span class="p-float-label">
                            <AutoComplete v-model="mercancia.fraccionArancelaria" :suggestions="filteredFraccion"
                                @complete="searchFraccion($event)" field="fraccion">
                            </AutoComplete>
                            <label>Fracción Arancelaria</label>
                        </span>
                    </div>
                    <!-- UNIDAD ADUANA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="mercancia.unidadAduana" :options="itemsUnidadAduanas"
                                optionLabel="unidadAduana">
                            </Dropdown>
                            <label>Unidad Aduanas</label>
                        </span>
                    </div>
                    <!-- MARCA -->
                    <div class="justify-right field col-3">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.descripciones.marca" style="text-align: left" type="text" />
                            <label>Marca</label>
                        </span>
                    </div>
                    <!-- MODELO -->
                    <div class="justify-right field col-3">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.descripciones.modelo" style="text-align: left" type="text" />
                            <label>Modelo</label>
                        </span>
                    </div>
                    <!-- SUB MODELO -->
                    <div class="justify-right field col-3">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.descripciones.subModelo" style="text-align: left" type="text" />
                            <label>SubModelo</label>
                        </span>
                    </div>
                    <!-- NUMERO/SERIE -->
                    <div class="justify-right field col-3">
                        <span class="p-float-label">
                            <InputText v-model="mercancia.descripciones.numeroSerie" style="text-align: left" type="text" />
                            <label>Numero/Serie</label>
                        </span>
                    </div>

                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

            filteredFraccion: null,
            // itemsFraccionArancelaria: [],

        }
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsUnidadAduanas() {
            return this.$store.state.listUnidadAduanaStore;
        },
        itemsFraccionArancelaria() {
            return this.$store.state.listFraccionArancelariaStore;
        },
        mercancia() {
            return this.$store.state.cEMercanciaStore;
        },
    },
    created() {
        this.getFraccionArancelaria();
        this.GetUnidadAduana();
    },
    methods: {
        async getFraccionArancelaria() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetFraccion/')
                this.$store.state.listFraccionArancelariaStore = response.data
                // console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        async GetUnidadAduana() {
            try {
                let response = await axios.get('CatalogosSat/GetUnidadAduana/')
                this.$store.state.listUnidadAduanaStore = response.data
                // console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        searchFraccion(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredFraccion = [...this.itemsFraccionArancelaria];
                } else {
                    this.filteredFraccion = this.itemsFraccionArancelaria.filter(
                        (country) => {
                            return country.fraccion
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        guardarOactualizar() {
            if (this.$store.state.cEMercanciaStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },
        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Mercancía, espere...'
            this.mercancia.usuarioCreador = this.empresa.nombre
            // console.log(this.mercancia)
            try {
                let response = await axios.post('ComercioExterior/PostMercancias/' + this.empresa.empresaBase, this.mercancia)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Mercancía guardada con exito.', life: 3000 });

                this.mercancia.id = response.data
                this.$store.state.listCEMercanciaStore.push(this.mercancia)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando Mercancía, espere...'
            this.mercancia.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('ComercioExterior/UpdateMercancia/' + this.empresa.empresaBase, this.mercancia)
                // console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listCEMercanciaStore.findIndex(x => x.id === this.mercancia.id);
                Object.assign(this.$store.state.listCEMercanciaStore[indice], this.mercancia)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Mercancía actualizada con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

    },
}
</script>