<template>
    <div class="card">
        <!-- DIALOGO DE LOADING -->
        <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- ENCABEZADO -->
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <div class="col-4 text-left">
                <Button icon="mdi mdi-window-close" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="cierraVentana()" v-tooltip.bottom="'Cerrar Ventana'" />
            </div>
            <div class="col-4 text-center">
                <h1>{{ movimiento.tipo }}</h1>
            </div>
            <div class="col-4 text-right">
                <Button icon="mdi mdi-content-save" class="p-button-rounded p-button-success p-button-outlined"
                    @click="PostMovimiento()" v-tooltip.bottom="'Guardar'" />
            </div>
        </div>
        <br>
        <!-- DATOS GENERALES -->
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <!-- FECHA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Calendar v-model="general.fecha" dateFormat="dd-MM-yy" />
                    <label>Fecha</label>
                </span>
            </div>
            <div class="justify-right field col-4">
                <span class="p-float-label">
                    <Dropdown v-model="general.almacen" :options="$store.state.listaAlmacenesStore"
                        optionLabel="descripcion" />
                    <label>Almacén</label>
                </span>
            </div>
            <div class="field col-4">
                <div class="p-inputgroup justify-center">
                    <span class="p-float-label">
                        <AutoComplete :forceSelection="true" style="text-align: left" v-model="general.solicitante"
                            :suggestions="filteredUsuario" @complete="searchUsuario($event)">
                        </AutoComplete>
                        <label>Recepetor</label>
                    </span>
                </div>
            </div>
        </div>
        <!-- PARA INGRESAR ARTICULOS -->
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <!-- BUSCAR POR CLAVE O DESCRIPCION -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    CONCEPTOS
                </h1>
            </div>
            <!-- NO DE INDENTIFICACION -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                    <label>No. Identificación</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.descripcion" />
                    <label>Descripción</label>
                </span>
            </div>
            <!-- UNIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.unidad" />
                    <label>Unidad</label>
                </span>
            </div>
            <!-- CANTIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                        <label>Cantidad</label>
                    </span>
                    <Button icon="mdi mdi-plus" class="p-button-success" @click="PushConcepto" />
                </div>
            </div>
        </div>
        <!-- TABLA DE CONCEPTOS A INGRESAR -->
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <div class="col-12">
                <DataTable :value="general.conceptos" :scrollable="true" scrollHeight="300px" scrollDirection="both"
                    editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table">
                    <template #header>
                        <div class="table-header">
                            <h2>Conceptos</h2>
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }"
                        frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editConcepto(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deleteConcepto(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="noIdentificacion" header="No. Indentificación"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.noIdentificacion" autofocus />
                        </template>
                    </Column>
                    <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }">
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.descripcion" autofocus />
                        </template>
                    </Column>
                    <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            general: {
                almacen: {},
                solicitante: '',
                fecha: new Date(),
                conceptos: [],
            },
            concepto: {
                noIdentificacion: '',
                descripcion: '',
                cantidad: 0,
                unidad: '',
                almacen: {},
            },
            filteredUsuario: null,
            listaUsuarios: [],
            loadingMensaje: '',
            loading: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        movimiento() {
            return this.$store.state.movimientoAlmacen;
        },

    },
    created() {
        this.getUsuarios();
        this.GetAlmacenes();
        this.conceptosOrdenCompra();
    },
    methods: {
        conceptosOrdenCompra() {
            if (this.movimiento.tipo == 'OrdenCompra') {
                this.general.conceptos = this.movimiento.movimientos
            }
        },
        FormatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        cierraVentana() {
            this.$emit('closeMovimiento')
        },

        searchUsuario(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUsuario = [...this.listaUsuarios];
                }
                else {
                    this.filteredUsuario = this.listaUsuarios.filter((country) => {
                        return country.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        async getUsuarios() {
            try {
                let response = await axios.get('Usuarios/GetUsuariosTodos/' + this.token.empresaBase)
                this.listaUsuarios = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            data[field] = newValue;
            console.log(field)
        },

        PushConcepto() {
            console.log(this.general.almacen)
            let item = 1;
            let ObjDefault = {
                noIdentificacion: '',
                descripcion: '',
                cantidad: 0,
                unidad: '',
            }
            let x = {}
            x = { ...this.concepto }
            x.origen = this.movimiento.tipo
            x.idAlmacen = this.general.almacen.idAlmacen
            if (this.movimiento.tipo === 'Salida') {
                x.cantidad = x.cantidad * -1
            }
            this.general.conceptos.push(x);
            this.concepto = { ...ObjDefault }

            for (let a of this.general.conceptos) {
                a.item = item;
                item++;
            }
        },

        async PostMovimiento() {
            this.loadingMensaje = 'Registrando movimiento...'
            this.loading = true;
            try {
                // ASIGNAMOS EL PROYECTO A LOS CONCEPTOS DE LA ORDEN DE COMPRA
                if (this.movimiento.tipo == 'OrdenCompra') {
                    for (let a of this.general.conceptos) {
                        a.idAlmacen = this.general.almacen.idAlmacen;
                    }
                }

                let ObjInventario = {
                    folio: 1,
                    fecha: this.general.fecha,
                    solicitante: this.general.solicitante,
                    tipo: this.movimiento.tipo,
                    almacen: 'A',
                    usuarioCreador: this.token.nombre,
                    movimientos: this.general.conceptos,
                }



                let response = await axios.post('Inventarios/PostInventario/' + this.token.empresaBase, ObjInventario)
                this.listaUsuarios = response.data
                console.log(response)

                // ACTULIZA LISTA EN CASAO DE QUE EL MOVIMIENTO VENGA DE UNA ORDEN DE COMPRA
                if (ObjInventario.tipo == 'OrdenCompra') {
                    let indice = this.$store.state.listaComprasOrdenComprasStore.findIndex((x) => x.idCompra === ObjInventario.movimientos[0].idOrigen);
                    this.$store.state.listaComprasOrdenComprasStore[indice].estatus = "Entregado";
                }
                this.loading = false;
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },
        async GetAlmacenes() {
            this.loadingAlmacenes = true;
            try {
                let response = await axios.get('Almacenes/GetAlmacenes/' + this.token.empresaBase)
                this.$store.state.listaAlmacenesStore = response.data
                console.log(response)
                this.loadingAlmacenes = false;

            } catch (error) {
                console.log(error)
                this.loadingAlmacenes = false;

            }
        },
    },
}
</script>