<template>
    <div>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold  border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>

        <!-- DIALOG DEL PDF -->
        <Dialog :showHeader="false" :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <PdfCotizacion @cierraDialogPdf="closeDialogPdf"></PdfCotizacion>
        </Dialog>

        <Card class="shadow-none">
            <!-- TITULO CARD -->
            <template #title>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="text-left field col-4">
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                            @click="cierraVentana()" />
                    </div>

                    <div class="text-center field col-4">
                        Cotización
                    </div>
                    <div class="text-right field col-4">
                        <template v-if="cotizacion.idCompra === 0">
                            <Button icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined"
                                @click="postCotizacion()" />
                        </template>
                        <template v-if="cotizacion.idCompra != 0">
                            <Button icon="pi pi-save" class="p-button-rounded p-button-warning p-button-outlined"
                                @click="editarCotizacion()" />
                        </template>
                        <Button icon="pi pi-file-pdf" class="ml-3 mr-1 p-button-rounded p-button-danger p-button-outlined"
                            @click="vistaPrevia()" />
                    </div>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <ScrollTop />
                <ScrollPanel style="height: 500px">
                    <TabView>
                        <TabPanel header="Datos generales">
                            <General></General>
                        </TabPanel>
                        <TabPanel header="Conceptos">
                            <Conceptos></Conceptos>
                        </TabPanel>
                    </TabView>
                </ScrollPanel>
            </template>
            <template v-if="cotizacion.estatus == 'Cotizada'" #footer>
                <div style="justify-content:right" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputNumber :value="$store.state.comprasCotizacionStore.subTotal" mode="decimal"
                                :minFractionDigits="2" locale="en-US" />
                            <label>SubTotal</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputNumber class="color" :value="$store.state.comprasCotizacionStore.total" mode="decimal"
                                :minFractionDigits="2" locale="en-US" />
                            <label>Total</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
        <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />

    </div>
</template>
<script>
import axios from 'axios'
import General from './CotizacionGeneral';
import Conceptos from './CotizacionConceptos'
import PdfCotizacion from "../../Pdf/CompraCotizacionPdf.vue";

export default {
    components: { General, Conceptos, PdfCotizacion },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            datosEmpresa: null,
            dialogPdf: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        cotizacion() {
            return this.$store.state.comprasCotizacionStore
        },
    },
    created() {
        this.Inicializa();
    },
    methods: {
        //INICIALES
        async Inicializa() {
            this.loading = true;
            await this.getProveedores()
            await this.GetProyectos()
            await this.GetConceptos()
            await this.GetEmpresa()
            await this.GetSerie()
            await this.GetObjImpuestos()
            await this.GetTraslados();
            await this.GetRetenciones();
            this.loading = false;
        },
        cierraVentana() {
            this.$store.state.comprasCotizacionStore.idCompra = -1;
            this.$emit('closeCotizacion')
        },
        async GetTraslados() {
            this.loadingMensaje = 'Consultando Trasladados...'
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            this.loadingMensaje = 'Consultando Retenciones...'
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetObjImpuestos() {
            this.loadingMensaje = 'Consultando Objeto de impuestos';
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetSerie() {
            this.loadingMensaje = 'Consultando serie'
            try {
                let response = await axios.get("Compras/GetComprasSeries/" + this.token.empresaBase + "/Cotización");
                this.$store.state.listComprasCotizacionSerieStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async postCotizacion() {
            try {
                if (this.cotizacion.solicitante == '') {
                    this.$toast.add({ severity: 'error', summary: 'Error de Guardado.', detail: 'Indique el usuario de quien lo solicita...', life: 5000 });
                    return;
                }

                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                let objCotizacion = { ...this.cotizacion }
                objCotizacion.empresa = this.datosEmpresa
                // objCotizacion.estatus = 'Cotizado'
                objCotizacion.rfc = objCotizacion.proveedor.rfc
                objCotizacion.nombre = objCotizacion.proveedor.nombre

                objCotizacion.usuarioCreador = this.token.nombre

                let response = await axios.post('Compras/PostComprasCotizacion/' + this.token.empresaBase, objCotizacion)
                console.log(response.data)
                objCotizacion.idCompra = response.data.id;
                objCotizacion.folio = response.data.folio;
                this.$store.state.listaComprasCotizacionStore.push(objCotizacion)

                let array = this.$store.state.listaComprasCotizacionStore;
                this.$store.state.listaComprasCotizacionStore = array.sort(((a, b) => b.idCompra - a.idCompra))

                this.$store.state.comprasCotizacionStore.idCompra = -1;

                // ACTUALIZA ESTATUS DEL PEDIDO
                let indice = this.$store.state.listaComprasPedidoStore.findIndex(x => x.idCompra === this.cotizacion.idRelacion);
                this.$store.state.listaComprasPedidoStore[indice].estatus = 'Enviado'


                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Cotización guardado con éxito', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },
        async actualizaEstatusPedido(idCompra) {
            try {
                let response = await axios.put("Compras/ActualizarCompra/" + this.token.empresaBase + '/Enviado/' + idCompra);
                let indice = this.$store.state.listaComprasPedidoStore.findIndex(x => x.idCompra === idCompra);
                Object.assign(this.$store.state.listaComprasPedidoStore[indice].estatus, 'Enviado')
            } catch (error) {
                console.log(error);
            }
        },
        async editarCotizacion() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                this.cotizacion.empresa = this.datosEmpresa
                this.cotizacion.rfc = this.cotizacion.proveedor.rfc
                this.cotizacion.nombre = this.cotizacion.proveedor.nombre
                this.cotizacion.estatus = 'Cotizada'

                this.cotizacion.usuarioModificador = this.token.nombre

                let response = await axios.post('Compras/UpdateComprasPedidos/' + this.token.empresaBase, this.cotizacion)
                console.log(response.data)

                let indice = this.$store.state.listaComprasCotizacionStore.findIndex(x => x.idCompra === this.cotizacion.idCompra);
                Object.assign(this.$store.state.listaComprasCotizacionStore[indice], this.cotizacion)

                this.loading = false;
                this.cierraVentana();
                this.$toast.add({ severity: 'success', detail: 'Cotización guardado con éxito', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },
        vistaPrevia() {
            this.dialogPdf = true;
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        async getProveedores() {
            this.loadingMensaje = 'Consultando Proveedores'
            try {
                let response = await axios.get('Proveedores/GetProveedores/' + this.token.empresaBase);
                this.$store.state.listaProveedorStore = response.data
            } catch (error) {
                console.log(error);
            }
        },
        async GetProyectos() {
            this.loadingMensaje = 'Consultando Proyectos';
            try {
                let response = await axios.get('Proyectos/GetProyectos/' + this.token.empresaBase)
                this.$store.state.listProyectosStore = response.data
            } catch (error) {
                this.loadingItemsProyectos = false;
            }
        },
        async GetConceptos() {
            try {
                this.loadingMensaje = 'Consultando Conceptos';
                let response = await axios.get('Conceptos/GetConceptos/' + this.token.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetEmpresa() {
            this.loadingMensaje = 'Consultando datos de la empresa'
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>