import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Configuracion from '../views/Configuracion.vue'

import Login from '../views/Login.vue'
import store from '../store/index'
import Concepto from '../components/Conceptos/Concepto.vue'
import ListaConceptos from '../components/Conceptos/ListaConceptos.vue'
import ListaClientes from '../components/Clientes/ListaClientes.vue'
import ListaAlmacenes from '../components/Almacenes/ListaAlmacenes.vue'
import ListaUsuarios from '../components/UsuariosInternos/ListaUsuarios.vue'
import ListaVentasPedidos from '../components/VentasPedidos/ListaVentasPedidos.vue'
import ListaProyectos from '../components/Proyectos/ListaProyectos.vue'
import ListaFacturas from '../components/Comprobantes/Factura/ListaFacturas.vue'
import ListaArrendamientos from '../components/Comprobantes/Arrendamiento/ListaArrendamientos.vue'
import ListaComplementoPago from '../components/Comprobantes/ComplementoPago/ListaComplementoPago.vue'
import ListaVentasRemision from '../components/VentasRemision/ListaVentasRemision.vue'
import ListaVentasCotizacion from '../components/VentasCotizacion/ListaVentasCotizacion.vue'
import ListaVehiculo from '../components/CartaPorte/Vehiculo/ListaVehiculo.vue'
import ListaRemolques from '../components/CartaPorte/Remolques/ListaRemolques.vue'
import ListaPermisosSCT from '../components/CartaPorte/PermisoSCT/ListaPermisosSCT.vue'
import ListaSeguros from '../components/CartaPorte/Seguros/ListaSeguros.vue'
import ListaMercancia from '../components/CartaPorte/Mercancia/ListaMercancia.vue'
import ListaOrigenes from '../components/CartaPorte/UbicacionOrigenes/ListaOrigenes.vue'
import ListaDestinos from '../components/CartaPorte/UbicacionDestinos/ListaDestinos.vue'
import ListaDirecciones from '../components/CartaPorte/Direcciones/ListaDirecciones.vue'
import ListaFiguras from '../components/CartaPorte/FigurasTransporte/ListaFiguras.vue'
import ListaAutotransporte from '../components/CartaPorte/Autotransporte/ListaAutotransporte.vue'
import ListaIngresoCartaPorte from '../components/Comprobantes/IngresoCartaPorte/ListaIngresoCartaPorte.vue'
import ListaTrasladoCartaPorte from '../components/Comprobantes/TrasladoCartaPorte/ListaTrasladoCartaPorte.vue'
import ListaComercioExterior from '../components/Comprobantes/ComercioExterior/ListaComercioExterior.vue'
import ListaComercioExteriorEmisor from '../components/ComercioExterior/Emisor/ListaComercioExteriorEmisor.vue'
import ListaComercioExteriorEPropietario from '../components/ComercioExterior/Propietario/ListaComercioExteriorEPropietario.vue'
import ListaComercioExteriorReceptor from '../components/ComercioExterior/Receptor/ListaReceptor.vue'
import ListaComercioExteriorDestinatario from '../components/ComercioExterior/Destinatario/ListaDestinatario.vue'
import ListaComercioExteriorMercancia from '../components/ComercioExterior/Mercancia/ListaCEMercancia.vue'
import ListaNotaCredito from '../components/Comprobantes/NotaCredito/ListaNotaCredito.vue'
import ListaProtocolos from '../components/Protocolos/ListaProtocolos.vue'
import PuntoVenta from '../components/PuntoVenta/PuntoVenta.vue'
import ListaVentas from '../components/PuntoVenta/ListaVentas.vue'
import ListaTurnos from '../components/PuntoVenta/ListaTurnos.vue'
import ListaProveedorVw from '../components/CatalogosVW/ListaProveedorVw.vue'
import ListaSolicitanteVw from '../components/CatalogosVW/ListaSolicitanteVw.vue'
import ListaBancos from '../components/Bancos/ListaBancos.vue'
import ListaDepositos from '../components/MovimientosBancos/ListaDepositos.vue'
import ListaRetiros from '../components/MovimientosBancos/ListaRetiros.vue'
import ListaProveedores from '../components/Proveedores/ListaProveedores.vue'
import ListaPedidos from '../components/Compras/Pedido/ListaPedidos.vue'
import ListaCotizaciones from '../components/Compras/Cotizacion/ListaCotizaciones.vue'
import ListaOrdenCompras from '../components/Compras/OrdenCompras/ListaOrdenCompras.vue'
import ListaInventarioAlmacen from '../components/Inventarios/InventarioAlmacen/ListaInventarioAlmacen.vue'
import ListaESAlmacen from '../components/Inventarios/InventarioAlmacen/ListaESAlmacen.vue'
import ListaFacturaGas from '../components/Comprobantes/FacturaGas/ListaFacturaGas.vue'
import ListaInventarioProyecto from '../components/Inventarios/InventarioProyecto/ListaInventarioProyecto.vue'
import ListaArticulos from '../components/Articulos/ListaArticulos.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  { path: '/', name: 'Home', component: Home, meta: { Administrador: true } },
  { path: '/about', name: 'About', component: () => import('../views/About.vue') },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion, meta: { Administrador: true } },
  { path: '/ListaConceptos', name: 'ListaConceptos', component: ListaConceptos, meta: { Administrador: true } },
  { path: '/Concepto', name: 'Concepto', component: Concepto, meta: { Administrador: true } },
  { path: '/ListaClientes', name: 'ListaClientes', component: ListaClientes, meta: { Administrador: true } },
  { path: '/ListaAlmacenes', name: 'ListaAlmacenes', component: ListaAlmacenes, meta: { Administrador: true } },
  { path: '/ListaUsuarios', name: 'ListaUsuarios', component: ListaUsuarios, meta: { Administrador: true } },
  { path: '/ListaVentasPedidos', name: 'ListaVentasPedidos', component: ListaVentasPedidos, meta: { Administrador: true } },
  { path: '/ListaProyectos', name: 'ListaProyectos', component: ListaProyectos, meta: { Administrador: true } },
  { path: '/ListaFacturas', name: 'ListaFacturas', component: ListaFacturas, meta: { Administrador: true } },
  { path: '/ListaArrendamientos', name: 'ListaArrendamientos', component: ListaArrendamientos, meta: { Administrador: true } },
  { path: '/ListaComplementoPago', name: 'ListaComplementoPago', component: ListaComplementoPago, meta: { Administrador: true } },
  { path: '/ListaVentasRemision', name: 'ListaVentasRemision', component: ListaVentasRemision, meta: { Administrador: true } },
  { path: '/ListaVentasCotizacion', name: 'ListaVentasCotizacion', component: ListaVentasCotizacion, meta: { Administrador: true } },
  { path: '/ListaVehiculo', name: 'ListaVehiculo', component: ListaVehiculo, meta: { Administrador: true } },
  { path: '/ListaRemolques', name: 'ListaRemolques', component: ListaRemolques, meta: { Administrador: true } },
  { path: '/ListaPermisosSCT', name: 'ListaPermisosSCT', component: ListaPermisosSCT, meta: { Administrador: true } },
  { path: '/ListaSeguros', name: 'ListaSeguros', component: ListaSeguros, meta: { Administrador: true } },
  { path: '/ListaMercancia', name: 'ListaMercancia', component: ListaMercancia, meta: { Administrador: true } },
  { path: '/ListaOrigenes', name: 'ListaOrigenes', component: ListaOrigenes, meta: { Administrador: true } },
  { path: '/ListaDestinos', name: 'ListaDestinos', component: ListaDestinos, meta: { Administrador: true } },
  { path: '/ListaDirecciones', name: 'ListaDirecciones', component: ListaDirecciones, meta: { Administrador: true } },
  { path: '/ListaFiguras', name: 'ListaFiguras', component: ListaFiguras, meta: { Administrador: true } },
  { path: '/ListaAutotransporte', name: 'ListaAutotransporte', component: ListaAutotransporte, meta: { Administrador: true } },
  { path: '/ListaIngresoCartaPorte', name: 'ListaIngresoCartaPorte', component: ListaIngresoCartaPorte, meta: { Administrador: true } },
  { path: '/ListaTrasladoCartaPorte', name: 'ListaTrasladoCartaPorte', component: ListaTrasladoCartaPorte, meta: { Administrador: true } },
  { path: '/ListaComercioExterior', name: 'ListaComercioExterior', component: ListaComercioExterior, meta: { Administrador: true } },
  { path: '/ListaComercioExteriorEmisor', name: 'ListaComercioExteriorEmisor', component: ListaComercioExteriorEmisor, meta: { Administrador: true } },
  { path: '/ListaComercioExteriorEPropietario', name: 'ListaComercioExteriorEPropietario', component: ListaComercioExteriorEPropietario, meta: { Administrador: true } },
  { path: '/ListaComercioExteriorReceptor', name: 'ListaComercioExteriorReceptor', component: ListaComercioExteriorReceptor, meta: { Administrador: true } },
  { path: '/ListaComercioExteriorDestinatario', name: 'ListaComercioExteriorDestinatario', component: ListaComercioExteriorDestinatario, meta: { Administrador: true } },
  { path: '/ListaComercioExteriorMercancia', name: 'ListaComercioExteriorMercancia', component: ListaComercioExteriorMercancia, meta: { Administrador: true } },
  { path: '/ListaNotaCredito', name: 'ListaNotaCredito', component: ListaNotaCredito, meta: { Administrador: true } },
  { path: '/ListaProtocolos', name: 'ListaProtocolos', component: ListaProtocolos, meta: { Administrador: true } },
  { path: '/PuntoVenta', name: 'PuntoVenta', component: PuntoVenta, meta: { Administrador: true } },
  { path: '/ListaVentas', name: 'ListaVentas', component: ListaVentas, meta: { Administrador: true } },
  { path: '/ListaTurnos', name: 'ListaTurnos', component: ListaTurnos, meta: { Administrador: true } },
  { path: '/ListaProveedorVw', name: 'ListaProveedorVw', component: ListaProveedorVw, meta: { Administrador: true } },
  { path: '/ListaSolicitanteVw', name: 'ListaSolicitanteVw', component: ListaSolicitanteVw, meta: { Administrador: true } },
  { path: '/ListaBancos', name: 'ListaBancos', component: ListaBancos, meta: { Administrador: true } },
  { path: '/ListaDepositos', name: 'ListaDepositos', component: ListaDepositos, meta: { Administrador: true } },
  { path: '/ListaRetiros', name: 'ListaRetiros', component: ListaRetiros, meta: { Administrador: true } },
  { path: '/ListaProveedores', name: 'ListaProveedores', component: ListaProveedores, meta: { Administrador: true } },
  { path: '/ListaPedidos', name: 'ListaPedidos', component: ListaPedidos, meta: { Administrador: true } },
  { path: '/ListaCotizaciones', name: 'ListaCotizaciones', component: ListaCotizaciones, meta: { Administrador: true } },
  { path: '/ListaOrdenCompras', name: 'ListaOrdenCompras', component: ListaOrdenCompras, meta: { Administrador: true } },
  { path: '/ListaInventarioAlmacen', name: 'ListaInventarioAlmacen', component: ListaInventarioAlmacen, meta: { Administrador: true } },
  { path: '/ListaESAlmacen', name: 'ListaESAlmacen', component: ListaESAlmacen, meta: { Administrador: true } },
  { path: '/ListaInventarioProyecto', name: 'ListaInventarioProyecto', component: ListaInventarioProyecto, meta: { Administrador: true } },
  { path: '/ListaFacturaGas', name: 'ListaFacturaGas', component: ListaFacturaGas, meta: { Administrador: true } },
  { path: '/ListaArticulos', name: 'ListaArticulos', component: ListaArticulos, meta: { Administrador: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
