<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- TIKCET A IMPRIMIR -->
        <div hidden>
            <div class="ticket" id="ticket">
                <!-- CABECERA DEL COMPROBANTE -->
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <div class="col-12" style="text-align: center;">
                        <a style="text-align: center;" href="https://contago.com.mx/" target="_blank">
                            <img :src="datosEmpresa.logo.base64" width="150">
                        </a>
                    </div>
                    <div class="col-12 text-center">
                        <font size="3">
                            <div><strong>Ticket</strong></div>
                        </font>
                        <h4>{{ venta.serie }} {{ venta.folio }}</h4>
                        <div>{{ formatDate(venta.fecha) }}</div>
                        <div><b>Lugar de Expedición: </b>{{ datosEmpresa.domicilioFiscal }}</div>
                    </div>
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>{{ datosEmpresa.rfc }}</div>
                        </font>
                        <font size="2">
                            <div>{{ datosEmpresa.nombre }}</div>
                        </font>
                    </div>
                    <!-- DATOS DEL CLIENTE -->
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>Datos del cliente:</div>
                        </font>
                        <font size="2">
                            <div><strong>{{ venta.cliente.cliente }}</strong></div>
                        </font>
                    </div>
                    <!-- CONCEPTOS -->
                    <DataTable style="width:100%" class="mt-1 p-datatable-sm" :value="venta.conceptos"
                        responsiveLayout="scroll">
                        <Column field="descripcion" header="Descripción" :styles="{ 'font-size': '70%' }">
                            <template #body="{ data }">
                                <div>{{ data.noIdentificacion }}</div>
                                <h4>{{ data.descripcion }}</h4>
                            </template>
                        </Column>
                        <Column field="cantidad" header="Cantidad" :styles="{ 'font-size': '70%' }">
                        </Column>
                        <Column field="precio" header="Precio">
                            <template #body="{ data }" :styles="{ 'font-size': '70%' }">
                                <span>{{ formatCurrency(data.precio) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                    <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12 mr-0 pr-0">
                        <div class="col-6 pt-3">
                            <font size="2">
                                <div>Pago con: {{ formatCurrency(venta.pagoCon) }}</div>
                            </font>
                            <font size="2">
                                <div>Cambio: {{ formatCurrency(venta.cambioTotal) }}</div>
                            </font>
                        </div>
                        <div class="col-6 mr-0 pr-0">
                            <DataTable :value="itemsImpuestos" class="ocult" responsiveLayout="scroll">
                                <Column field="impuesto" header=""
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '70%' }">
                                </Column>
                                <Column field="importe" header=""
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '70%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.importe) }}</span>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <div class="col-12 text-left">
                            <p style="font-size: 14px; text-align: center;"> {{
                                importeLetra }}
                            </p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">{{ venta.notas }}</p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 10px; text-align: center;">Para emitir su factura, visite la pagina
                                web:<br>
                                <b>www.contago.com.mx</b>
                            </p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">¡Gracias por su Compra!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DIALOG DE CONFIRMACION -->
        <Dialog header="Cerrar Sesión" :closable="false" :visible.sync="dialogConfirmacion"
            :containerStyle="{ width: '350px' }" :modal="true">
            <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">

                <div class="field col-12">
                    <Button label="Cerrar Turno" class="p-button-sm  p-button-primary"
                        @click="dialogCerrarSesion = true" />
                </div>
                <div class="field col-12">
                    <Button label="Dejar turno abierto y salir" class="p-button-sm  p-button-primary"
                        @click="dejarTurnoAbierto()" />
                </div>

                <div class="field col-12">
                    <Button label="Cancelar" class="p-button-sm  p-button-danger" @click="dialogConfirmacion = false" />
                </div>
            </div>

        </Dialog>

        <!-- NUEVO CLIENTE -->
        <Dialog :visible.sync="dialogoNuevoCliente" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <Clientes @closeCliente="closeDialogCliente()"></Clientes>
        </Dialog>

        <!-- DIALOGO PARA LOGIN DE PUNTO DE VENTA -->
        <Dialog :closeOnEscape="false" :visible.sync="dialogLoginVenta" :closable="false"
            :containerStyle="{ width: '500px' }" header="Comenzar Turno" :modal="true">
            <LoginPuntoVenta @cerrarLogin="dialogLoginVenta = false"></LoginPuntoVenta>
        </Dialog>

        <!-- DIALOGO PARA CERRAR SESION -->
        <Dialog :visible.sync="dialogCerrarSesion" :closable="false" :containerStyle="{ width: '1000px' }"
            header="Cerrar Sesión" :modal="true">
            <CerrarSesion @closeCerrarSesion="dialogCerrarSesion = false"></CerrarSesion>
        </Dialog>

        <!-- DIALOGO PARA BUSCAR PRODUCTOS -->
        <Dialog :visible.sync="dialogConceptos" position="top" :closable="true" header=" " :modal="true"
            :containerStyle="{ width: '90vw' }">
            <Conceptos></Conceptos>
        </Dialog>

        <!-- DIALOGO PARA VERIFICAR PRECIOS -->
        <Dialog :visible.sync="dialogPrecios" :closable="false" :containerStyle="{ width: '500px' }"
            header="Verificar Precios" :modal="true">
            <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">
                <div class="field col-12 mb-1 pb-0">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoVerificar" :dropdown="false"
                            :autoHighlight="true" :should-auto-focus="true" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" field="concepto" @item-select="asignaConceptoVerificar">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
                <div class="field col-12 pt-5 pb-5 mt-3" style="background-color: #e9eef3;">
                    <H3 class="pb-3" style=" color: #000095;text-align:center;">{{ descripcionPrecio }}</H3>
                    <H1 style=" color: #000095;text-align:center;">{{ formatCurrency(precio) }}</H1>
                </div>
                <div class="field col-6" style="text-align: right;">
                    <Button label="ESC - Cancelar" class="p-button-sm  p-button-danger"
                        @click="dialogPrecios = false" />
                </div>
                <div class="field col-6" style="text-align: right;">
                    <Button label="Agregar a Venta" icon="pi pi-plus" class="p-button-sm  p-button-primary"
                        @click="pushConceptoTotal()" />
                </div>
            </div>
        </Dialog>

        <!-- DIALOGO PARA AGREGAR MAS DE UN PRODUCTO -->
        <Dialog :visible.sync="dialogCantidadProducto" :closable="false" :containerStyle="{ width: '500px' }"
            header="Agregar cantidad" :modal="true">
            <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">
                <div class="field col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoVerificar" :dropdown="false"
                            scrollHeight="0px" :autoHighlight="true" :should-auto-focus="true"
                            :suggestions="filteredConcepto" @complete="searchConcepto($event)" field="concepto"
                            @item-select="asignaConceptoVerificar">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
                <div class="field col-6">
                </div>
                <div class="field col-6">
                    <span class="p-float-label">
                        <InputNumber id="stacked" ref="_cantidad" v-model="concepto.cantidad" showButtons mode="decimal"
                            :minFractionDigits="3" currency="USD" />
                        <label>Cantidad</label>
                    </span>
                </div>
                <div class="field col-6" style="text-align: right;">
                    <Button label="ESC - Cancelar" class="p-button-sm  p-button-danger"
                        @click="dialogCantidadProducto = false" />
                </div>
                <div class="field col-6" style="text-align: right;">
                    <Button label="Agregar a Venta" icon="pi pi-plus" class="p-button-sm  p-button-primary"
                        @click="pushConceptoTotal()" />
                </div>
            </div>
        </Dialog>

        <!-- AGREGAR UN PRODUCTO NO REGISTRADO -->
        <Dialog :visible.sync="dialogAgregarProducto" :closable="false" :containerStyle="{ width: '700px' }"
            header="Agregar Producto" :modal="true">
            <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">
                <div class="field col-12">
                    <span class="p-float-label">
                        <InputText type="text" v-model="conceptoNoRegistrado.descripcion" ref="_descripcion" />
                        <label>Descripción</label>
                    </span>
                </div>
                <!-- CANTIDAD -->
                <div class="justify-right field col-12 md:col-6 sm:col-12">
                    <span class="p-float-label">
                        <InputNumber v-model="conceptoNoRegistrado.cantidad" mode="decimal" locale="en-US"
                            :minFractionDigits="3" ref="_cantidad" />
                        <label>Cantidad</label>
                    </span>
                </div>

                <div class="justify-right field col-12 md:col-6 sm:col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber v-model="conceptoNoRegistrado.precio" mode="decimal" locale="en-US"
                                :minFractionDigits="2" />
                            <label>Precio</label>
                        </span>
                    </div>
                </div>
                <div class="field col-6" style="text-align: right;">
                </div>
                <div class="field col-3" style="text-align: right;">
                    <Button label="ESC - Cancelar" class="p-button-sm  p-button-danger"
                        @click="dialogAgregarProducto = false" />
                </div>
                <div class="field col-3" style="text-align: right;">
                    <Button label="Agregar a Venta" icon="pi pi-plus" class="p-button-sm  p-button-primary"
                        @click="asignaConceptoNoRegistrado()" />
                </div>
            </div>
        </Dialog>

        <!-- DIALOGO PARA AGREGAR NOTAS -->
        <Dialog :visible.sync="dialogAgregarNotas" :closable="false" :containerStyle="{ width: '500px' }"
            header="Agregar Notas" :modal="true">
            <div class="pt-0 text-left p-fluid grid formgrid">
                <div class="field col-12">
                    <span class="p-float-label">
                        <InputText autofocus type="text" v-model="venta.notas" />
                        <label>Notas</label>
                    </span>
                </div>
                <div class="field col-6">
                    <Button label="ESC - Cancelar" class="p-button-sm ml-2 p-button-danger" @click="cancelarNotas()" />
                </div>
                <div class="field col-6">
                    <Button label="ENTER - Agregar" class="p-button-sm ml-2 p-button-primary" @click="agregarNotas()" />
                </div>
            </div>
        </Dialog>

        <!-- DIALOGO PARA ASIGNAR CLIENTE -->
        <Dialog :visible.sync="dialogAsignarCliente" :closable="true" :containerStyle="{ width: '800px' }"
            header="Asignar Cliente" :modal="true">
            <div class="pt-0 text-left p-fluid grid formgrid">
                <div class="field col-12">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" class="auto-complete" v-model="venta.cliente"
                                :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                            </AutoComplete>
                            <label>Cliente</label>
                        </span>
                    </div>
                </div>
                <div class="field col-6">
                    <Button label="ESC - Cancelar" class="p-button-sm ml-2 p-button-danger"
                        @click="cancelarCliente()" />
                </div>
                <div class="field col-6">
                    <Button label="F4 - Asignar" class="p-button-sm ml-2 p-button-primary" @click="asignarCliente()" />
                </div>
            </div>
        </Dialog>

        <!-- DIALOGO PARA REALIZAR EL COBRO -->
        <Dialog :visible.sync="dialogCobro" :closable="false" :containerStyle="{ width: '90vw' }" :modal="true">
            <template v-if="guardandoVenta == 1">
                <ProgressSpinner />
            </template>
            <div v-if="guardandoVenta == 0" class="pt-0 pb-2 text-left p-d-flex   p-fluid grid formgrid">
                <div class="field col-6 pt-2" style="background-color: #e9eef3;">
                    <H4 style=" color: #000095;text-align:center;">Total</H4>
                    <H1 style=" color: #000095;text-align:center;">{{ formatCurrency(venta.total) }}</H1>
                </div>
                <div class="field col-6 pt-2" style="background-color: #e9eef3;">
                    <H4 style=" color: #000095;text-align:center;">{{ mensajeCobro }}</H4>
                    <H1 style=" color: #000095;text-align:center;">{{ formatCurrency(computedSumaCobros) }}</H1>
                </div>
                <!-- FORMA DE PAGO -->
                <div class="field col-12 md:col-4 pt-2">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="objFormaPago.formaPago" :options="itemsFormaPago"
                            optionLabel="formaPago" />
                        <label>Forma de Pago</label>
                    </span>
                </div>
                <!-- REFERENCIA -->
                <div class="field col-12 md:col-4 pt-2">
                    <span class="p-float-label">
                        <InputText autofocus type="text" v-model="objFormaPago.referencia" />
                        <label>Referencia</label>
                    </span>
                </div>
                <div class="field col-12 md:col-4 pt-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber mode="decimal" v-model="objFormaPago.monto" locale="en-US"
                                :minFractionDigits="2" />
                            <label>Monto</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushCobro()"
                            v-tooltip.bottom="'Click o Enter para agregar forma de pago.'" />
                    </div>
                </div>
                <div class="field col-12 pt-2">
                    <!-- <H2 style=" color: #000095;text-align:center;"> Cambio de: {{ formatCurrency(totalCambio) }}</H2> -->
                    <DataTable :value="venta.formasPago" class="p-datatable-sm" responsiveLayout="scroll">
                        <Column field="formaPago.formaPago" header="Forma de Pago"></Column>
                        <Column header="Referencia" field="referencia"></Column>
                        <Column header="Monto" field="monto">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.monto) }}</span>
                            </template>
                        </Column>
                        <Column header="Eliminar" field="eliminar">
                            <template #body="slotProps">
                                <Button icon="pi pi-ban" class="p-button-rounded p-button-danger"
                                    v-tooltip.bottom="'Eliminar'" @click="eliminarCobro(slotProps.data)"></Button>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="field col-3 pt-2">
                    <Button class="ml-2" label="SPACE - Cobrar e Imprimir" @click="imprimirTicket()" />
                </div>
                <div class="field col-3 pt-2">
                    <Button class="ml-2" label="F2 - Cobrar sin Imprimir" @click="cobrarSinImprimir()" />
                </div>
                <div class="field col-3 pt-2">
                    <Button class="ml-2" icon="pi pi-plus" label="Ingresar Notas" @click="abrirNotas()" />
                </div>
                <div class="field col-3 pt-2">
                    <Button class="ml-2 p-button-danger" label="ESC - Cancelar Cobro" @click="dialogCobro = false" />
                </div>
                <div class="field col-12 pt-2 text-right ">
                    <div class="field-checkbox text-right">
                        <label for="city1">Enviar Ticket por Correo: </label>
                        <InputSwitch class="ml-2" id="city1" v-model="enviarCorreo" />
                    </div>
                </div>
                <template v-if="enviarCorreo == true">
                    <div class="justify-right field col-6">
                        <span class="p-float-label">
                            <InputText v-model="nombreCorreo" style="text-align: left" type="text" />
                            <label>Nombre</label>
                        </span>
                    </div>
                    <!-- CORREO -->
                    <div class="justify-right field col-6">
                        <div class="p-inputgroup">
                            <InputText v-model="correo" placeholder="Correo" @keydown.enter="pushCorreo()" />
                            <Button icon="pi pi-plus" @click="pushCorreo()" class="p-button-warning" />
                        </div>
                    </div>
                    <!-- TABLA DE CORREOS -->
                    <div class="justify-right field col-12">
                        <DataTable :value="venta.cliente.correos" responsiveLayout="scroll">
                            <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" :exportable="false"
                                :styles="{ 'min-width': '1rem' }">
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" style="width:35px; height:35px"
                                        class="p-button-rounded p-button-danger"
                                        @click="eliminarCorreo(slotProps.data)" />
                                </template>
                            </Column>
                            <Column field="nombre" header="Nombre"></Column>
                            <Column field="correo" header="Correo"></Column>
                        </DataTable>
                    </div>
                </template>
            </div>
        </Dialog>

        <Card class="mt-0 pt-0" style="height: 100%;">
            <!-- TITULO CARD -->
            <template #content>
                <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="field col-10">
                        <Button label="Historial" icon="pi pi-list" class="p-button-sm p-button-outlined"
                            @click="verHistorial()" style="width: 200px;" />
                        <Button label="Nuevo Cliente" icon="pi pi-user-plus" class="ml-2 p-button-sm p-button-outlined"
                            style="width: 200px;" @click="nuevoCliente()" />
                        <!-- <Button label="Corte" class="ml-2 p-button-sm p-button-outlined" style="width: 200px;" /> -->
                        <Button label="Reportes" icon="pi pi-chart-bar" class="ml-2 p-button-sm p-button-outlined"
                            style="width: 200px;" />
                        <Button label="Turnos" class="ml-2 p-button-sm p-button-outlined" style="width: 200px;"
                            @click="verTurnos()" />
                    </div>
                    <div class="field col-2" style="background-color: #e9eef3; text-align: right;">
                        <h3 class="mr-3 mb-3" style="color: gray; display:inline;">{{ usuarioVentas.usuario }}</h3>
                        <Button icon="pi pi-sign-out" class="p-button-danger" @click="dialogConfirmacion = true" />
                    </div>
                </div>
                <div class="pt-0 text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="field col-12 text-center mt-12">
                        <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                            ARTICULOS
                        </h2>
                    </div>
                    <span hidden> {{ usuarioVentas }}</span>

                    <div class="col-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <InputNumber :disabled="validaCantidad" v-model="cantidad" />
                                <label>Cantidad</label>
                            </span>
                            <span class="p-inputgroup-addon">
                                <Checkbox v-model="validaCantidad" :binary="true" />
                            </span>
                        </div>
                    </div>

                    <div class="field col-10 mb-1 pb-0">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <AutoComplete style="text-align: left" v-model="conceptoSelect" scrollHeight="0px"
                                    :autoHighlight="true" :suggestions="filteredConcepto"
                                    @complete="searchConcepto($event)" field="concepto" @item-select="asignaConcepto">
                                </AutoComplete>
                                <label>Buscar por clave o Descripción</label>
                            </span>
                        </div>
                    </div>

                    <div class="field col-12 mt-1 pt-0">
                        <!-- <Button label="Ctrl + B - Buscar Producto" class="p-button-sm  p-button-primary"
                                style="width: 210px;" icon="pi pi-search" @click="dialogConceptos = true" /> -->
                        <Button label="Ctrl + B - Checador" class="p-button-sm ml-2 p-button-primary"
                            style="width: 200px;" @click="dialogPrecios = true" />
                        <!-- <Button label="F2 - Varios" class="p-button-sm ml-2 p-button-primary" style="width: 200px;" @click="dialogCantidadProducto = true" /> -->
                        <!-- <Button label="F4 - Otro Producto" class="p-button-sm ml-2 p-button-primary" style="width: 200px;" @click="dialogAgregarProducto = true" /> -->
                        <Button label="Eliminar Ultimo" icon="pi pi-minus" class="p-button-sm ml-2 p-button-primary"
                            style="width: 200px;" @click="EliminarUltimo()" />
                        <Button label="Agregar Ultimo" icon="pi pi-plus" class="p-button-sm ml-2 p-button-primary"
                            style="width: 200px;" @click="AgregarUltimo()" />
                    </div>
                </div>
                <span hidden>{{ venta }}</span>
                <DataTable class="mt-1 p-datatable-sm" :value="venta.conceptos" :scrollable="true" scrollHeight="400px">
                    <Column field="noIdentificacion" header="Código de Barras"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%', 'background-color': '#eaf4ff', 'justify-content': 'center' }">
                    </Column>
                    <Column field="descripcion" header="Descripción del Producto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%', 'justify-content': 'left' }">
                    </Column>
                    <Column field="cantidad" header="Cantidad"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%', 'justify-content': 'center' }">
                    </Column>
                    <Column field="precio" header="Precio" bodyClass="text-center"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%', 'text-content': 'right' }"
                        style="background-color: red;">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.precio) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%', 'background-color': '#effaf1', 'justify-content': 'center' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column bodyClass="text-center"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem', 'justify-content': 'center' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-ban" class="p-button-rounded p-button-danger"
                                v-tooltip.bottom="'Eliminar'" @click="eliminarArticulo(slotProps.data)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>

        <!-- <div class="footerVenta"> -->
        <div class="pt-0 pb-2 text-left p-d-flex footerVenta  p-fluid grid formgrid">
            <div class="field col-9">
                <div class="pt-1 pb-1" style="background-color: #e9eef3;">
                    <H2 class="ml-3 mr-3" style="color: #000095; display:inline;">{{ cantidadProducto }} </H2>
                    <h3 style="color: gray; display:inline;">Productos en la
                        Venta.</h3>
                </div>
                <Button class="ml-2 p-button-danger p-button-sm" label="SUPR - Cancelar Venta" style="width: 220px;"
                    @click="cancelarVenta()" />
                <Button class="mr-2 ml-2 p-button-sm" label="Agregar Notas" style="width: 200px;"
                    @click="abrirNotas()" />
                <Button class="mr-2 ml-2 p-button-sm" label="Asignar Cliente" style="width: 200px;"
                    @click="abrirAsignarCliente()" />
                <Button class="mr-2 ml-2 p-button-sm" label="Imprimir ultimo Ticket" style="width: 200px;"
                    @click="imprimirUltimoTicket()" />
            </div>
            <div class="field col-1">
                <Button label="SPACE - Cobrar" class="p-button-outlined  p-button-lg" style="width: 100%; height: 100%;"
                    @click="realizarCobro()" />
            </div>
            <div class="field col-2 pt-4" style="background-color: #e9eef3;">
                <H1 style=" color: #000095;text-align:center;">{{ formatCurrency(venta.total) }}</H1>
                <!-- <H3 style=" color: #000095;text-align:center;">{{ formatCurrency(venta.subTotal) }}</H3> -->
            </div>

        </div>
        <!-- </div> -->
    </div>
</template>
<script>
import LoginPuntoVenta from "./LoginPuntoVenta.vue";
import CerrarSesion from "./CerrarSesion.vue";
import Conceptos from "../Conceptos/ListaConceptos.vue"
import axios from 'axios'
import Clientes from "../Clientes/Clientes.vue";
import moment from 'moment'
import print from 'print-js'
import html2pdf from "html2pdf.js";

export default {
    components: { LoginPuntoVenta, Conceptos, Clientes, CerrarSesion },
    data() {
        return {
            dialogLoginVenta: false,
            filteredCliente: null,
            editedIndex: -1,

            conceptoSelect: null,
            filteredConcepto: null,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
            concepto: {
                descripcion: '',
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },


            dialogConceptos: false,
            dialogPrecios: false,
            conceptoVerificar: null,

            // VARIABLES PARA EL DIALOG DE VERIFICAR PRECIOS
            precio: 0,
            descripcionPrecio: '',

            // DIALOGO PRA AGREGAR MAS DE UNA CANTIDAD
            dialogCantidadProducto: false,

            // DIALOG PARA REGISTRA PRODUCTOS NO REGISTRADOS
            dialogAgregarProducto: false,
            conceptoNoRegistrado: {
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: {
                    clave: '01',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },

            // DIALOGO DE COBRO
            dialogCobro: false,
            guardandoVenta: 0,
            formasPago: [],
            objFormaPago: {
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                monto: 0.00
            },
            mensajeCobro: '',

            // TICKET
            dialogTicket: false,

            // DIALOGO PARA NOTAS 
            dialogAgregarNotas: false,

            // DIALOGO PARA ASIGNAR CLIENTE 
            dialogAsignarCliente: false,

            // DIALOGO PARA CREAR NUEVOS CLIENTES
            dialogoNuevoCliente: false,

            //DIALOGO PARA CERRAR SESION
            dialogCerrarSesion: false,
            dialogConfirmacion: false,

            validaCantidad: true,
            cantidad: 1,

            enviarCorreo: false,
            nombreCorreo: '',
            correo: ''
        }
    },
    computed: {
        venta() {

            return this.$store.state.ventaStore
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
        listaConceptos() {
            return this.$store.state.listaConceptosStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
        precioBuscado() {
            return this.conceptoVerificar.precio
        },
        totalCambio() {
            return this.venta.pagoCon - this.venta.total
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        cantidadProducto() {
            let cantidad = 0
            cantidad = this.venta.conceptos.length
            return cantidad
        },
        usuarioVentas() {
            return this.$store.state.usuarioVentasStore;
        },
        turno() {
            return this.$store.state.turnoStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        importeLetra() {
            const func = require('../../plugins/numeroALetras.js')
            let a = func.numeroALetras(this.$store.state.ventaStore.total, this.$store.state.ventaStore.moneda.clave);
            return a;
        },
        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.ventaStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.ventaStore.impuestos.traslados) {
                console.log(x)
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                if (x.tipoFactor === 'Exento') {
                    nombre = nombre + ' Exento'
                } else {
                    nombre = nombre + '(' + tasa + ')%'
                }
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.ventaStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.ventaStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
        computedSumaCobros() {
            var total_ = 0;
            for (var i = 0; i < this.venta.formasPago.length; i++) {
                this.mensajeCobro = 'Por Cobrar:'
                total_ = total_ + Number(this.venta.formasPago[i].monto);
            }
            total_ = this.venta.total - total_
            if (total_ < 0) {
                this.mensajeCobro = 'Cambio:'
                total_ = total_ * -1
            }
            return total_
        }
    },
    created() {
        if (this.usuarioVentas.usuario == '') {
            this.loginVentas()
        }
        this.getClientes();
        this.getEmpresa();
        this.GetFormaPago();
    },
    mounted() {
        window.addEventListener('keyup', this.handleKeyUp)
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleKeyUp)
    },
    methods: {
        async GetFormaPago() {
            if (this.$store.state.formaPagoStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetFormaPago/');
                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },
        async cobrarSinImprimir() {
            console.log(this.venta)

            try {
                // OBTENEMOS EL ULTIMO FOLIO REGISTRADO
                console.log(this.venta)
                let folio = this.usuarioVentas.folio + 1

                this.guardandoVenta = 1
                let comprobante = { ...this.venta }
                comprobante.idComprobante = 0
                comprobante.folio = folio.toString();
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.venta.cliente.rfc
                comprobante.nombre = this.venta.cliente.nombre
                comprobante.fecha = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                comprobante.idTurno = this.turno.idTurno
                comprobante.usuarioVentas = this.usuarioVentas.usuario
                comprobante.serie = this.usuarioVentas.serie
                comprobante.usuarioCreador = this.token.nombre
                comprobante.pagoCon = this.sum(this.venta.formasPago, 'monto')
                comprobante.cambioTotal = comprobante.pagoCon - this.venta.total
                comprobante.formaPago = this.venta.formasPago[0].formaPago
                console.log(comprobante)

                let response = await axios.post('Ventas/PostPuntoVenta/' + this.token.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                comprobante.idComprobante = response.data.idComprobante;
                comprobante.folio = response.data.folio;
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre
                comprobante.folioFiscal = response.data;

                this.$store.state.listVentasStore.push(comprobante);
                let array = this.$store.state.listVentasStore;
                this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))

                //INCREMENTAMOS EL FOLIO DEL USUARIO
                this.usuarioVentas.folio = this.usuarioVentas.folio + 1

                this.$toast.add({ severity: 'success', detail: 'Venta realizada.', life: 5000 });
                // this.cierraVentana();
                console.log(response.data)
                // this.loading = false;
                this.guardandoVenta = 0

                if (this.enviarCorreo == true) {
                    await this.mandarCorreo();
                }

                this.cancelarVenta();
                this.dialogCobro = false
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar', life: 5000 });
                console.log(error)
                this.guardandoVenta = 0
                // this.loading = false;
            }

        },
        asignarCliente() {
            this.dialogAsignarCliente = false
        },
        cancelarCliente() {
            let clienteDefault = {
                rfc: 'XAXX010101000',
                nombre: 'PUBLICO EN GENERAL',
                cliente: 'XAXX010101000 | PUBLICO EN GENERAL',
                domicilioFiscal: '',
                usoCfdi: {
                    id: 22,
                    clave: "S01",
                    moral: "Sí",
                    fisica: "Sí",
                    usoCfdi: "S01 | Sin efectos fiscales.",
                    descripcion: "Sin efectos fiscales."
                },
                regimenFiscal: {
                    id: 11,
                    clave: "616",
                    moral: "Sí",
                    fisica: "No",
                    descripcion: "Sin obligaciones fiscales",
                    regimenFiscal: "616 | Sin obligaciones fiscales"
                },
                correos: []
            }

            this.venta.cliente = { ...clienteDefault }
            this.dialogAsignarCliente = false
        },
        abrirAsignarCliente() {
            this.dialogAsignarCliente = true
        },
        abrirNotas() {
            this.dialogAgregarNotas = true
        },
        cancelarNotas() {
            this.venta.notas = ''
            this.dialogAgregarNotas = false
        },
        agregarNotas() {
            this.dialogAgregarNotas = false
        },
        SumaCobros() {
            var total_ = 0;
            for (var i = 0; i < this.venta.formasPago.length; i++) {
                this.mensajeCobro = 'Por Cobrar:'
                total_ = total_ + Number(this.venta.formasPago[i].monto);
            }
            total_ = this.venta.total - total_
            if (total_ < 0) {
                this.mensajeCobro = 'Cambio:'
                total_ = total_ * -1
            }
            return total_
        },
        realizarCobro() {
            this.SumaCobros()
            if (this.venta.conceptos.length == 0) {
                this.$toast.add({ severity: 'error', detail: 'Agrege al menos un producto.', life: 5000 });
                return;
            }
            this.objFormaPago.monto = this.venta.total
            this.mensajeCobro = 'Por Cobrar:'
            this.dialogCobro = true
        },
        pushCobro() {
            if (this.objFormaPago.monto == 0) {
                this.$toast.add({ severity: 'error', detail: 'Agrege un monto mayor a 0.00', life: 5000 });
                return;
            }
            let cobroDefault = {
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                monto: 0.00,
                referencia: ''
            }
            this.venta.formasPago.push(this.objFormaPago)
            this.objFormaPago = Object.assign({}, cobroDefault);
        },
        eliminarCobro(item) {
            let indice = this.venta.formasPago.indexOf(item);
            this.venta.formasPago.splice(indice, 1);
        },
        asignaConceptoNoRegistrado() {
            this.concepto = { ...this.conceptoNoRegistrado }
            this.impuestos = { ...this.conceptoNoRegistrado.impuestos }
            this.pushConceptoTotal();
            this.conceptoNoRegistrado.descripcion = ''
            this.conceptoNoRegistrado.cantidad = 1
            this.conceptoNoRegistrado.precio = 0.00
        },

        pushConceptoTotal() {
            let precio = this.concepto.precio;
            let sumT = this.sum(this.impuestos.traslados, 'tasaOCuota')
            let sumR = this.sum(this.impuestos.retenciones, 'tasaOCuota')
            console.log('sumT', sumT)
            console.log('sumR', sumR)
            let sumaImpuestos = ((sumT - sumR)) + 1
            console.log('sumaImpuestos', sumaImpuestos)
            let importeC = precio / sumaImpuestos;
            console.log('importeC', importeC)

            importeC = Math.trunc(importeC * 1000000) / 1000000;
            console.log(importeC)
            this.concepto.valorUnitario = importeC
            this.concepto.cantidad = this.cantidad
            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }
            console.log('cantidad', objConcepto.cantidad)
            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;
            objConcepto.total = precio * objConcepto.cantidad
            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                // objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    console.log('aqui1')
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    console.log('aqui2')
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            // objConcepto.impuestos = Object.assign({}, objImpuestosChido)
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            this.venta.conceptos.push(objConcepto);

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.conceptoVerificar = null
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);

            this.descripcionPrecio = ''
            this.precio = 0
            this.cantidad = 1
            console.log('fin')
        },

        calcularImpuestos() {
            this.$store.state.ventaStore.impuestos.traslados = []
            this.$store.state.ventaStore.impuestos.retenciones = []
            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.venta.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {
                //VERIFICAMOS SI LLEVA IMPUESTOS
                if (a.objImp.clave === '02') {
                    for (let t of a.impuestos.traslados) {
                        // t.base_ = a.importe
                        // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                        sumaT = sumaT + t.importe
                        sumaT = Math.round(sumaT * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        let im = { ...t }
                        listTrasladados.push(im);
                        im = {}
                    }

                    for (let r of a.impuestos.retenciones) {
                        // r.base_ = a.impo  rte
                        // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                        let im = { ...r }
                        listRetenidos.push(im);
                        im = {}
                    }
                }


                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.ventaStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.ventaStore.impuestos.traslados.push(tra)
                }
                // console.log(this.$store.state.ventaStore.impuestos.traslados)

                this.$store.state.ventaStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.ventaStore.impuestos.retenciones.push(ret)
                }
            }
        },

        sumatoria() {
            let indice = 1;
            for (let x of this.venta.conceptos) {
                x.item = indice;
                indice++;
            }

            this.venta.subTotal = Math.round(this.sum(this.venta.conceptos, 'importe') * 100) / 100;
            let tras = Math.round(this.sum(this.$store.state.ventaStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.ventaStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.venta.total = this.venta.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.idImpuesto };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },

        loginVentas() {
            let usuario = this.$store.state.usuarioVentasStore.idUsuario
            if (usuario == -1) {
                let obj = {
                    idUsuario: 0,
                }
                this.$store.state.usuarioVentasStore = { ...obj }
            }
            if (this.$store.state.turnoStore.idTurno == 0) {
                this.dialogLoginVenta = true
            }
        },
        async getClientes() {
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.empresa.empresaBase);
                // console.log(result.data);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },

        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        asignaConcepto() {
            this.concepto = { ...this.conceptoSelect }
            this.impuestos = { ...this.conceptoSelect.impuestos }

            if (this.concepto.descripcion == '') {
                this.$toast.add({ severity: 'error', detail: 'Articulo no existe.', life: 5000 });
                return;
            }
            if (this.cantidad == 0) {
                this.$toast.add({ severity: 'error', detail: 'La cantidad debe ser mayor a 0.', life: 5000 });
                return;
            }
            this.pushConceptoTotal();
        },
        // ASIGNA CONCEPTO DE VERIFICAR PRECIO
        asignaConceptoVerificar() {
            console.log(this.conceptoVerificar)

            this.concepto = { ...this.conceptoVerificar }
            this.impuestos = { ...this.conceptoVerificar.impuestos }
            console.log(this.impuestos)
            this.descripcionPrecio = this.concepto.descripcion
            this.precio = this.concepto.precio

            if (this.dialogCantidadProducto != true) {
                this.conceptoVerificar = null
            }
        },

        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.concepto.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        eliminarArticulo(item) {
            console.log(item)
            let indice = this.$store.state.ventaStore.conceptos.indexOf(item);
            this.$store.state.ventaStore.conceptos.splice(indice, 1);
            this.calcularImpuestos();
            this.sumatoria();
        },

        verHistorial() {
            this.$router.push({ name: "ListaVentas" });

        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        // ATAJOS
        AgregarUltimo() {
            if (this.$store.state.ventaStore.conceptos.length > 0) {
                var indice = this.$store.state.ventaStore.conceptos.length
                console.log(indice);
                var ultimo = this.$store.state.ventaStore.conceptos[indice - 1]
                this.$store.state.ventaStore.conceptos.push(ultimo)
                this.calcularImpuestos();
                this.sumatoria();
            }
        },
        EliminarUltimo() {
            var item = this.$store.state.ventaStore.conceptos.length
            var indice = this.$store.state.ventaStore.conceptos.indexOf(item)
            this.$store.state.ventaStore.conceptos.splice(indice, 1);
            this.calcularImpuestos();
            this.sumatoria();
        },
        cancelarVenta() {

            let ventaDefaul = {
                idComprobante: 0,
                folioFiscal: '',
                serie: '',
                folio: '',
                fecha: null,
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                condicionesPago: '',
                subTotal: 0,
                descuento: 0,
                moneda: {
                    id: 100,
                    clave: 'MXN',
                    descripcion: 'Peso Mexicano',
                    moneda: 'MXN | Peso Mexicano'
                },
                tipoCambio: 0,
                total: 0,
                tipoComprobante: 'I',
                tipoComprobanteInterno: 'PuntoVenta',
                metodoPago: { id: 1, clave: "PUE", metodoPago: "PUE | Pago en una sola exhibición", descripcion: "Pago en una sola exhibición" },
                empresa: {},
                cliente: {
                    rfc: 'XAXX010101000',
                    nombre: 'PUBLICO EN GENERAL',
                    cliente: 'XAXX010101000 | PUBLICO EN GENERAL',
                    domicilioFiscal: '',
                    usoCfdi: {
                        id: 22,
                        clave: "S01",
                        moral: "Sí",
                        fisica: "Sí",
                        usoCfdi: "S01 | Sin efectos fiscales.",
                        descripcion: "Sin efectos fiscales."
                    },
                    regimenFiscal: {
                        id: 11,
                        clave: "616",
                        moral: "Sí",
                        fisica: "No",
                        descripcion: "Sin obligaciones fiscales",
                        regimenFiscal: "616 | Sin obligaciones fiscales"
                    },
                    correos: []
                },
                rfc: '',
                nombre: '',
                atn: '',
                notas: '',
                estatus: 'Sin Timbrar',
                usuarioCreador: '',
                usuarioModificador: '',
                usuarioAutoriza: '',
                conceptos: [],
                impuestos: {
                    traslados: [],
                    retenciones: [],

                },
                idTurno: 0,
                usuarioVentas: '',
                pagoCon: 0.00,
                cambioTotal: 0.00,
                formasPago: [],
            }
            this.$store.state.ventaStore = { ...ventaDefaul }
        },
        handleKeyUp() {
            // console.log(event.keyCode)
            if (event.ctrlKey) {
                switch (event.keyCode) {
                    case 66:
                        if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                            && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                            && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                            this.dialogPrecios = true
                        }
                        break;
                }
            }
            switch (event.keyCode) {
                case 107:
                    // +
                    if (this.dialogCobro == true) {
                        this.dialogAgregarNotas = true
                    }
                    else if (this.dialogAgregarNotas == true) {
                        this.dialogAgregarNotas = false
                    }
                    else if (this.dialogPrecios == true) {
                        this.pushConceptoTotal();
                    }
                    else if (this.dialogAgregarProducto == true) {
                        this.asignaConceptoNoRegistrado();
                    }
                    else if (this.dialogCantidadProducto == true) {

                        console.log(this.concepto)
                        this.pushConceptoTotal();
                        this.conceptoVerificar = null
                    } else if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        if (this.venta.conceptos.length > 0) {
                            var indice = this.venta.conceptos.length
                            console.log(indice);
                            var ultimo = this.venta.conceptos[indice - 1]
                            this.venta.conceptos.push(ultimo)
                            this.calcularImpuestos();
                            this.sumatoria();
                            this.conceptoSelect = null
                        }
                    }

                    break
                case 109:
                    // -
                    if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        var item = this.venta.conceptos.length
                        var indice = this.venta.conceptos.indexOf(item)
                        this.venta.conceptos.splice(indice, 1);
                        this.calcularImpuestos();
                        this.sumatoria();
                        this.conceptoSelect = null
                    }
                    break
                case 17:
                    //CTRL
                    // if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                    //     && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                    //     && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                    //     this.dialogConceptos = true
                    // }
                    break
                case 18:
                    // ALT
                    if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        //this.dialogPrecios = true
                    }
                    break

                case 113:
                    // F2
                    if (this.dialogAgregarNotas == true) {
                        this.dialogAgregarNotas = false
                    } else if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        // this.dialogCantidadProducto = true
                    } else if (this.dialogCobro == true) {
                        this.cobrarSinImprimir()
                    }

                    break
                case 115:
                    //F4
                    if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        this.dialogAgregarProducto = true
                    } else if (this.dialogAsignarCliente == true) {
                        this.asignarCliente();
                    }
                    break
                case 46:
                    if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        this.cancelarVenta()
                    }
                    break
                case 32:
                    //SPACE
                    if (this.dialogAgregarNotas == false && this.dialogAgregarProducto == false && this.dialogAsignarCliente == false
                        && this.dialogCantidadProducto == false && this.dialogCobro == false && this.dialogConceptos == false && this.dialogLoginVenta == false
                        && this.dialogPrecios == false && this.dialogTicket == false && this.dialogoNuevoCliente == false) {
                        this.realizarCobro()
                    } else if (this.dialogCobro == true) {
                        let sumaVenta = this.sum(this.venta.formasPago, 'monto')
                        if (this.venta.total > sumaVenta) {
                            this.$toast.add({ severity: 'error', detail: 'Se debe cubrir el monto total de la venta.', life: 5000 });
                            return;
                        } else {

                            console.log('aqui', this.venta)
                            this.imprimirTicket();
                        }
                    }
                    break
                case 13:
                    // ENTER
                    if (this.dialogCobro == true && this.dialogAgregarNotas == false) {
                        this.pushCobro();
                    } else if (this.dialogAgregarNotas == true) {
                        this.agregarNotas();
                    }
                    break
                case 27:
                    //ESC
                    if (this.dialogPrecios == true) {
                        this.dialogPrecios = false
                    } else if (this.dialogCantidadProducto == true) {
                        this.dialogCantidadProducto = false
                    } else if (this.dialogAgregarProducto == true) {
                        this.dialogAgregarProducto = false
                    } else if (this.dialogAgregarNotas == true) {
                        this.cancelarNotas();
                    } else if (this.dialogAsignarCliente == true) {
                        this.cancelarCliente();
                    } else if (this.dialogCobro == true) {
                        this.dialogCobro = false
                    }

                    break
            }
        },
        async getEmpresa() {
            try {
                this.loadingMensaje = 'Consultando datos...'
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresa.empresaBase);
                this.$store.state.empresaStore = response.data
            } catch (error) {
                console.log(error)
                this.dialogLoading = false;
            }
        },
        closeDialogCliente() {
            this.dialogoNuevoCliente = false
        },
        nuevoCliente() {
            let nuevoCliente = {
                idCliente: 0,
                rfc: '',
                nombre: '',
                domicilioFiscal: '',
                regimenFiscal: {
                    regimenFiscal: '',
                },
                usoCfdi: {
                    usoCfdi: '',
                },
                direccion: '',
                usuarioCreador: '',
                residenciaFiscal: {
                    residenciaFiscal: '',
                },
                correos: [],
                numRegIdTrib: '',
            }
            if (this.$store.state.clienteStore.idCliente == -1) {
                this.$store.state.clienteStore = { ...nuevoCliente }
            }
            this.dialogoNuevoCliente = true;
        },
        async imprimirTicket() {
            console.log(this.venta)
            let folio = this.usuarioVentas.folio + 1

            this.venta.empresa = this.datosEmpresa
            this.venta.rfc = this.venta.cliente.rfc
            this.venta.nombre = this.venta.cliente.nombre
            this.venta.fecha = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
            this.venta.serie = this.usuarioVentas.serie
            this.venta.pagoCon = this.sum(this.venta.formasPago, 'monto')
            this.venta.cambioTotal = this.venta.pagoCon - this.venta.total
            this.venta.folio = folio.toString()
            this.venta.formaPago = this.venta.formasPago[0].formaPago

            var a = await this.convertBase64();
            // var printWindow = window.open(a); 
            // printWindow.print();
            print({
                printable: a,
                type: 'pdf',
                base64: true,
            })
            this.cobrarSinImprimir();
        },
        async convertBase64() {
            var opt = {
                margin: .1,
                filename: 'Ticket' + this.venta.serie + "_" + this.venta.folio + ".pdf",
                // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },

                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('ticket')).set(opt).toPdf().get('pdf').then((pdf) => {
            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return file
        },
        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy hh:mm:ss a");
            return fechaR;
        },
        verTurnos() {
            let rol = this.usuarioVentas.permisos.find(element => element.permiso === 'Administrador');
            console.log(rol)
            if (rol == null) {
                this.$toast.add({ severity: 'error', detail: 'Se requiere tener permisos de Administrador.', life: 5000 });
                return;
            } else {
                this.$router.push({ name: "ListaTurnos" });
            }
        },
        async getVentas() {
            this.$store.state.listVentasStore = []
            try {
                let fI = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('Ventas/GetPuntoVenta/' + this.token.empresaBase + '/PuntoVenta/' + fI + '/' + fF + '/' + this.empresa.rfc);
                console.log(response.data)

                let rol = this.usuarioVentas.permisos.find(element => element.permiso === 'Administrador');
                console.log(rol)

                if (rol == null) {
                    let array = response.data.filter(x => x.idTurno == this.turno.idTurno);
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))


                } else {
                    let array = response.data
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))

                }
                this.loadingVentas = false;
            } catch (error) {
                console.log(error);
                this.loadingVentas = false;
            }
        },
        async imprimirUltimoTicket() {
            if (this.$store.state.listVentasStore.length == 0) {
                this.$toast.add({ severity: 'error', detail: 'Aun no hay ventas el dia de hoy.', life: 5000 });
                return
            }
            let ultimo = this.$store.state.listVentasStore[0]
            this.$store.state.ventaStore = { ...ultimo }
            var a = await this.convertBase64();
            // var printWindow = window.open(a); 
            // printWindow.print();
            print({
                printable: a,
                type: 'pdf',
                base64: true,
            })
            this.cancelarVenta();
        },
        dejarTurnoAbierto() {
            let usuarioVentas = {
                idUsuario: -1,
                usuario: ''
            }

            let turno = {
                idTurno: 0,
                idUsuario: 0,
                efectivoInicial: 0.00,
                caja: { nombreCajas: 'Caja 01', caja: 1 },
                efectivoFinal: 0.00
            }

            this.$store.state.turnoStore = { ...turno }
            this.$store.state.usuarioVentasStore = { ...usuarioVentas }
            this.$router.push({ name: "Home" });
        },
        async mandarCorreo() {
            let correos = {
                asunto: 'Gracias por su Compra.',
                mensaje: '',
                emisor: {
                    nombre: '',
                    correo: '',
                    contra: '',
                    hostt: '',
                    puerto: 0,
                },
                receptores: [],
                archivos: [],
            }

            var pdf_ = {
                base64: await this.convertBase64(),
                nombre: 'Ticket_' + this.venta.serie + '_' + this.venta.folio + '.pdf'
            }

            console.log('1', this.venta.cliente.correos)
            for (let c of this.venta.cliente.correos) {
                let correo = {
                    nombre: c.nombre,
                    correo: c.correo
                }
                correos.receptores.push(correo)
            }
            console.log('2', correos.receptores)

            //  correos.receptores = this.venta.cliente.correo;

            correos.archivos.push(pdf_);

            console.log(correos)
            try {
                let response = await axios.post('Correos/PostCorreos/' + this.empresa.empresaBase + '/' + this.empresa.idusuariosApp, correos)
                console.log(response.data)
                //this.dialogCorreo = false;
                //this.loading = false;
                //1this.$toast.add({ severity: 'success', detail: 'Correo enviado con éxito', life: 5000 });

            } catch (error) {
                //this.$toast.add({ severity: 'error', detail: 'Error al enviar:' + error.response.data, life: 5000 });
                console.log(error)
                //this.loading = false;
            }
        },
        pushCorreo() {
            let objeto = {
                nombre: this.nombreCorreo,
                correo: this.correo
            }
            this.venta.cliente.correos.push(objeto)

            this.nombreCorreo = ''
            this.correo = ''
        },
        eliminarCorreo(data) {
            let editedIndexCorreo = this.venta.cliente.correos.indexOf(data)
            this.venta.cliente.correos.splice(editedIndexCorreo, 1)
        },
    }
}
</script>
<style>
.reloj {
    color: #858585;
    font-size: 15px;
    font-family: Arial;
    letter-spacing: 5px;
    text-align: right;
    /* background-color: #f7d06a; */
    /* border: 10px solid #091321; */
    border-radius: 20px;
}

.ticket {
    width: 90mm;
    max-width: 90mm;
    max-height: 100%;
}

.footerVenta {
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 100px;
    width: 100%;
}

@media print {

    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
}
</style>
