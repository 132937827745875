<template>
    <div>
        <!-- TIKCET A IMPRIMIR -->
        <div hidden>
            <div class="ticket" id="cierreTurno">
                <!-- CABECERA DEL COMPROBANTE -->
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <div class="col-12" style="text-align: center;">
                        <a style="text-align: center;" href="https://contago.com.mx/" target="_blank">
                            <img :src="datosEmpresa.logo.base64" width="150">
                        </a>
                    </div>
                    <div class="col-12 text-center">
                        <font size="3">
                            <div><strong>Cierre de Turno</strong></div>
                        </font>
                        <!-- <div>{{ formatDate(new Date) }}</div> -->
                    </div>
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>{{ datosEmpresa.rfc }}</div>
                        </font>
                        <font size="2">
                            <div>{{ datosEmpresa.nombre }}</div>
                        </font>
                    </div>
                    <!-- DATOS DEL CLIENTE -->
                    <div class="col-12 text-center mt-3">
                        <font size="2">
                            <div>Datos del Usuario:</div>
                        </font>
                        <font size="3">
                            <div><strong>Turno: </strong>{{ turno.idTurno }}</div>
                        </font>
                        <font size="3">
                            <div><strong>Caja: </strong>{{ turno.caja.nombreCajas }}</div>
                        </font>
                        <font size="2">
                            <div><strong>Nombre: </strong>{{ usuarioVentas.nombre }}</div>
                        </font>
                        <font size="2">
                            <div><strong>RFC: </strong>{{ usuarioVentas.rfc }}</div>
                        </font>
                        <font size="2">
                            <div><strong>Usuario: </strong>{{ usuarioVentas.usuario }}</div>
                        </font>
                    </div>
                    <DataTable style="width:100%" class="mt-1 p-datatable-sm" :value="listaCorte" responsiveLayout="scroll">
                        <Column field="formaPago.formaPago" header="Forma de Pago" :styles="{ 'font-size': '70%' }">
                        </Column>

                        <Column field="monto" header="Importe">
                            <template #body="{ data }" :styles="{ 'font-size': '70%', 'text-align': 'right' }"
                                :bodyStyle="{ 'text-align': 'right', overflow: 'visible' }">
                                <span>{{ formatCurrency(data.monto) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                    <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12 mt-1 pr-0">
                        <div class="col-12 mr-0 pr-0 text-center">
                            <font size="3">
                                <div><strong>Efectivo en Caja</strong></div>
                            </font>
                        </div>
                        <div class="col-12 mr-0 pr-0 text-center">
                            <font size="3">
                                <div>{{ formatCurrency(turno.efectivoFinal) }}</div>
                            </font>
                        </div>
                        <div class="col-12 mt-3 pr-0 text-center">
                            <font size="3">
                                <div>{{ formatDate(new Date()) }}</div>
                            </font>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-d-flex  p-fluid grid formgrid col-12">
            <template v-if="loadingCorte == 1">
                <ProgressSpinner />
            </template>
        </div>
        <div v-if="loadingCorte == 0" class="p-d-flex  p-fluid grid formgrid col-12">
            <div class="field col-12" style="background-color: #e9eef3;">
                <H2 style=" color: #000095;text-align:center;">Cierre de Turno</H2>
                <!-- <Button icon="pi pi-print" class="p-button-rounded p-button-info" @click="imprimirCierreTurno" /> -->
            </div>
            <div class="field col-12">
                <!-- <span>{{ usuarioVentas }}</span> -->
                <!-- <span>{{ turno }}</span> -->
                <DataTable :value="listaCorte" class="p-datatable-sm" responsiveLayout="scroll">
                    <Column field="formaPago.formaPago" header="Forma de Pago">
                    </Column>
                    <Column header="Monto" field="monto" :styles="{ 'background-color': '#effaf1', }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.monto) }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 pt-2 pb-2" style="background-color: #e9eef3;">
                <h3 style=" color: #000095;text-align:center;"> Efectivo en caja:</h3>
            </div>
            <div class="field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber mode="decimal" v-model="turno.efectivoFinal" locale="en-US" :minFractionDigits="2" />
                        <label>¿Efectivo en caja.?</label>
                    </span>
                </div>
            </div>
            <div class="field col-6">
                <Button label="Cancelar" @click="cierraVentana()" />
            </div>
            <div class="field col-6">
                <Button label="Cerrar Turno" @click="cerrarTurno()" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import print from 'print-js'
import html2pdf from "html2pdf.js";
export default {
    data() {
        return {
            efectivoEsperado: 0,
            loadingCorte: 0,
            listaCorte: []
        }
    },
    computed: {
        usuarioVentas() {
            return this.$store.state.usuarioVentasStore;
        },
        turno() {
            return this.$store.state.turnoStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
        diferencia() {
            return this.turno.efectivoFinal - this.efectivoEsperado
        }
    },
    created() {
        this.getEfectivoEsperado();
    },
    mounted() {
    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        async getEfectivoEsperado() {
            this.loadingCorte = 1
            this.listaCorte = []
            try {
                let response = await axios.get('Ventas/GetPuntoVentaPorTurno/' + this.empresa.empresaBase + '/PuntoVenta/' + this.empresa.rfc + '/' + this.turno.idTurno);
                console.log(response.data)
                this.listaCorte = response.data.filter(x => x.formaPago.id != 1)
                this.loadingCorte = 0
                //this.efectivoEsperado = response.data
            } catch (error) {
                console.log(error);
            }
        },
        async cerrarTurno() {
            console.log(this.turno)
            try {
                let response = await axios.post("LoginPuntoVenta/cerrarTurno/" + this.empresa.empresaBase, this.turno);
                console.log(response.data)

                let usuarioVentas = {
                    idUsuario: -1,
                    usuario: ''
                }

                let turno = {
                    idTurno: 0,
                    idUsuario: 0,
                    efectivoInicial: 0.00,
                    caja: { nombreCajas: 'Caja 01', caja: 1 },
                    efectivoFinal: 0.00
                }

                this.$store.state.turnoStore = { ...turno }
                this.$store.state.usuarioVentasStore = { ...usuarioVentas }
                this.imprimirCierreTurno();
                this.$router.push({ name: "Home" });
            } catch (error) {
                console.log(error);
            }
        },
        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MM-yyyy hh:mm:ss a");
            return fechaR;
        },
        cierraVentana() {
            this.$emit('closeCerrarSesion')
        },
        async imprimirCierreTurno() {

            var a = await this.convertBase64();
            // var printWindow = window.open(a); 
            // printWindow.print();
            print({
                printable: a,
                type: 'pdf',
                base64: true,
            })
        },
        async convertBase64() {
            var opt = {
                margin: .1,
                filename: 'CierreTurno_' + this.turno.idTurno + "_" + this.usuarioVentas.usuario + ".pdf",
                // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('cierreTurno')).set(opt).toPdf().get('pdf').then((pdf) => {
            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return file
        },
    }
}
</script>
<style>
.ticket {
    width: 90mm;
    max-width: 90mm;
    max-height: 100%;
}
</style>