<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 700px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>
            <!-- DIALOG DEL PDF -->
            <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
                <!-- <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf> -->
                <ComprobantePdfG @cierraDialogPdf="closeDialogPdf"></ComprobantePdfG>
            </Dialog>
            <Card class="shadow-none">
                <!-- TITULO CARD -->
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cierraVentana()" />
                        </div>
                        <div class="text-center field col-4">
                            <div @click="activaPrecio()">Factura</div>
                        </div>
                        <div class="text-right field col-4">
                            <template v-if="factura.idFactura === 0">
                                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                    @click="postFactura" v-tooltip.bottom="'Timbrar'" />
                                <template v-if="factura.remisiones.length == 0 && factura.protocolos.length == 0">
                                    <Button icon="pi pi-save"
                                        class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                                        @click="postGuardaFactura" v-tooltip.bottom="'Guardar'" />
                                </template>
                            </template>

                            <template v-if="factura.idFactura != 0">
                                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                    @click="putTimbrado" v-tooltip.bottom="'Timbrar Guardado'" />
                                <Button icon="pi pi-save"
                                    class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                                    @click="putFactura" v-tooltip.bottom="'Guardar Editado'" />
                            </template>

                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="nuevoPdf" v-tooltip.bottom="'Vista Previa'" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <!-- CONTENIDO DE LA FACTURA -->
                <template #content>
                    <TabView>
                        <TabPanel header="Datos generales">
                            <General></General>
                        </TabPanel>
                        <TabPanel header="Conceptos">
                            <Concepto></Concepto>
                        </TabPanel>
                        <TabPanel header="CFDI'S Relacionados">
                            <Relacionados></Relacionados>
                        </TabPanel>
                    </TabView>
                </template>
                <template #footer>
                    <div style="justify-content:right" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber v-model="$store.state.facturaStore.subTotal" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>SubTotal</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber class="color" v-model="$store.state.facturaStore.total" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>Total</label>
                            </span>
                        </div>
                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import General from './FacturaGeneral.vue'
import Concepto from './FacturaConcepto.vue'
import Relacionados from './FacturaCfdiRelacionado.vue'
import moment from 'moment'
// import ComprobantePdf from "../../Pdf/ComprobantePdf.vue";
import { comprobanteBase64 } from '../../../plugins/comprobanteBase64.js'
import ComprobantePdfG from "../../Pdf/ComprobantePDFG.vue";

export default {
    components: {
        General,
        Concepto,
        Relacionados,
        ComprobantePdfG,
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogPdf: false,
            timbres: 0,
        }
    },
    computed: {
        factura() {
            return this.$store.state.facturaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        datosEmpresa() {
            return this.$store.state.empresaStore;
        },
    },
    created() {
        this.Iniciales();
    },
    methods: {
        //CARGA DE CATALOGOS INICIALES
        async Iniciales() {
            this.loading = true;
            await this.GetSeries();
            await this.GetClientes();
            await this.GetUsoCfdi();
            await this.GetMetododPago();
            await this.GetFormaPago();
            await this.GetMoneda();
            await this.GetConceptos();
            await this.GetClaveProdServ();
            await this.GetClaveUnidad();
            await this.GetObjImp();
            await this.GetTraslados();
            await this.GetRetenciones();
            await this.GetTipoRelacion();
            this.loading = false;
        },

        async GetSeries() {
            this.loadingMensaje = 'Consultando Series...'
            try {
                let response = await axios.get('Comprobantes/GetSerieComprobantes/' + this.token.empresaBase + '/Factura/' + this.datosEmpresa.rfc);
                this.$store.state.listFacturaSerieStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClientes() {
            this.loadingMensaje = 'Consultando Clientes...'
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.token.empresaBase);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },

        async GetUsoCfdi() {
            this.loadingMensaje = 'Consultando Uso de CFDI...'
            if (this.$store.state.usoCfdiStore.length != 0) {
                return;
            }
            try {
                let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                this.$store.state.usoCfdiStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async GetMetododPago() {
            this.loadingMensaje = 'Consultando Método de Pago...'
            if (this.$store.state.metodoPagoStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMetodoPago/');
                this.$store.state.metodoPagoStore = result.data;
            } catch (error) {

            }
        },

        async GetFormaPago() {
            this.loadingMensaje = 'Consultando Forma de Pago...'
            if (this.$store.state.formaPagoStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetFormaPago/');
                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },

        async GetMoneda() {
            this.loadingMensaje = 'Consultando Moneda...'
            if (this.$store.state.monedaStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },

        async GetConceptos() {
            this.loadingMensaje = 'Consultando Conceptos...'
            try {
                let response = await axios.get('Conceptos/GetConceptos/' + this.token.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveProdServ() {
            this.loadingMensaje = 'Consultando Clave de Productos y Servicios...'
            if (this.$store.state.claveProdServStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            this.loadingMensaje = 'Consultando Clave de Unidad...'
            if (this.$store.state.claveUnidadStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            this.loadingMensaje = 'Consultando Objeto de Impuestos...'
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTraslados() {
            this.loadingMensaje = 'Consultando Trasladados...'
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            this.loadingMensaje = 'Consultando Retenciones...'
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTipoRelacion() {
            this.loadingMensaje = 'Consultando Tipo de Relación...'
            if (this.$store.state.listTipoRelacion.length == 0) {
                try {
                    let response = await axios.get('CatalogosSat/GetTipoRelacion');
                    this.$store.state.listTipoRelacion = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },

        //FUNCIONES
        async postFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.factura }

                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.factura.cliente.rfc
                comprobante.nombre = this.factura.cliente.nombre
                comprobante.fecha = moment(this.factura.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraComprobante/' + this.token.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                comprobante.timbreFiscalDigital = { ...response.data };
                comprobante.folio = response.data.folio
                comprobante.folioFiscal = response.data.folioFiscal
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre
                comprobante.estatus = 'Vigente'

                //ASIGNAMOS VALORES PARA EL PDF
                this.$store.state.facturaStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.facturaStore.folio = response.data.folio
                this.$store.state.facturaStore.folioFiscal = response.data.folioFiscal
                this.$store.state.facturaStore.rfc = comprobante.cliente.rfc
                this.$store.state.facturaStore.nombre = comprobante.cliente.nombre
                this.$store.state.facturaStore.estatus = 'Vigente'

                this.$store.state.listFacturaStore.push(comprobante);
                this.$store.state.facturaStore.idFactura = -1;
                let array = this.$store.state.listFacturaStore;
                this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                await this.nuevoPdf();
                this.dialogPdf = true;

                if (comprobante.remisiones.length != 0) {
                    for (var r of comprobante.remisiones) {
                        let fac = {
                            idVenta: r.id,
                            serie: comprobante.serie,
                            folio: response.data.folio,
                            fecha: comprobante.fecha,
                            rfc: comprobante.cliente.rfc,
                            nombre: comprobante.cliente.nombre,
                            moneda: comprobante.moneda,
                            subTotal: comprobante.subTotal,
                            total: comprobante.total,
                            folioFiscal: response.data.folioFiscal,
                            documento: 'Remisión',
                            tipoInterno: 'Factura',
                        }
                        let indice = this.$store.state.listVentaRemisionStore.findIndex(x => x.idVenta === r.id);
                        this.$store.state.listVentaRemisionStore[indice].estatus = 'Facturado';
                        this.$store.state.listVentaRemisionStore[indice].facturas++;
                        this.$store.state.listVentaRemisionStore[indice].totalFacturado += comprobante.total;
                        this.$store.state.listVentaRemisionStore[indice].comprobantes.push(fac);
                        fac = {};
                    }
                }
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async postGuardaFactura() {
            // console.log(this.factura)
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'
                let comprobante = { ...this.factura }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.factura.cliente.rfc
                comprobante.nombre = this.factura.cliente.nombre
                comprobante.fecha = moment(this.factura.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostGuardaComprobante/' + this.token.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                comprobante.idComprobante = response.data;
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre
                comprobante.folioFiscal = response.data;

                this.$store.state.listFacturaStore.push(comprobante);
                this.$store.state.facturaStore.idFactura = -1;
                let array = this.$store.state.listFacturaStore;
                this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.cierraVentana();
                console.log(response.data)
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putFactura() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando comprobante ...'

                let comprobante = { ...this.factura }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.factura.cliente.rfc
                comprobante.nombre = this.factura.cliente.nombre
                comprobante.fecha = moment(this.factura.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.put('Comprobantes/PutComprobante/' + this.token.empresaBase, comprobante)
                //ASIGNAMOS VALORES
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listFacturaStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listFacturaStore[indice] = comprobante

                this.$store.state.facturaStore.idFactura = -1;
                let array = this.$store.state.listFacturaStore;
                this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante guardado con éxito', life: 5000 });
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al guardar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        async putTimbrado() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Timbrando comprobante ...'
                let comprobante = { ...this.factura }
                comprobante.empresa = this.datosEmpresa
                comprobante.rfc = this.factura.cliente.rfc
                comprobante.nombre = this.factura.cliente.nombre
                comprobante.fecha = moment(this.factura.fecha).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.post('Comprobantes/PostTimbraEditaComprobante/' + this.token.empresaBase, comprobante)

                //ASIGNAMOS VALORES
                comprobante.timbreFiscalDigital = { ...response.data };
                comprobante.folio = response.data.folio
                comprobante.folioFiscal = response.data.folioFiscal
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre
                comprobante.estatus = 'Vigente'

                //ASIGNAMOS VALORES PARA EL PDF
                this.$store.state.facturaStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.facturaStore.folio = response.data.folio
                this.$store.state.facturaStore.folioFiscal = response.data.folioFiscal
                this.$store.state.facturaStore.rfc = comprobante.cliente.rfc
                this.$store.state.facturaStore.nombre = comprobante.cliente.nombre
                this.$store.state.facturaStore.estatus = 'Vigente'

                //VAMOS A ACTUALIZAR DE MANERA LOCAL
                let indice = this.$store.state.listFacturaStore.findIndex(x => x.folioFiscal === comprobante.folioFiscal)
                this.$store.state.listFacturaStore[indice] = comprobante

                let array = this.$store.state.listFacturaStore;
                this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))

                this.$store.state.facturaStore.idFactura = -1;
                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.loading = false;
                await this.nuevoPdf();
                this.dialogPdf = true;
            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                console.log(error.response.data)
                this.loading = false;
            }
        },

        vistaPrevia() {
            this.dialogPdf = true;
        },

        cierraVentana() {
            this.$store.state.facturaStore.idFactura = -1;
            this.$emit('closeFactura')
        },

        closeDialogPdf() {
            this.dialogPdf = false
            if (this.factura.timbreFiscalDigital.folioFiscal != '') {
                this.$emit('closeFactura')
            }
        },

        //PARA ACTIVAR EL MODO PRECIO
        activaPrecio() {
            this.$store.state.modoImporteStore = false;
        },

        async nuevoPdf() {
            try {
                let item = { ...this.$store.state.facturaStore }
                item.empresa = this.datosEmpresa;
                let logo = this.datosEmpresa.logo.base64;
                let estatus = item.estatus
                let colores = this.datosEmpresa.colores.color;
                console.log(colores);

                let base64 = await comprobanteBase64(logo, 'Factura', item, colores, estatus);
                let nombreDoc = '';
                if (item.serie === '') {
                    nombreDoc = item.folio + '_' + item.cliente.rfc + '_' + item.timbreFiscalDigital.folioFiscal
                } else {
                    nombreDoc = item.serie + '_' + item.folio + '_' + item.cliente.rfc + '_' + item.timbreFiscalDigital.folioFiscal
                }
                let timbrado = 'SI';
                if (item.timbreFiscalDigital.folioFiscal === '') {
                    timbrado = 'NO'
                }
                let idCliente = item.cliente.idCliente;

                let ObjComprobante = {
                    nombre: nombreDoc,
                    base64: base64.split(',')[1],
                    archivoXml: item.timbreFiscalDigital.archivoXml,
                    timbrado: timbrado,
                    idCliente: idCliente,
                }

                this.$store.state.comprobanteStore = { ...ObjComprobante };
                this.dialogPdf = true;
                // console.log(this.$store.state.comprobanteStore);
            } catch (error) {
                console.error(error);
            }
        },


    },
}
</script>
<style>
.color {
    background: #cfd3e9 !important;
}
</style>