<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '400px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" v-if="!ventasI" />
            <ProgressBar :value="progresVentas" v-if="ventasI" style="height: 1em">{{ progresVentas }}%</ProgressBar>
            <span>{{ loadingMensaje }}</span>
            <div v-if="banderaFinaliza">
                <Button icon="pi pi-times" class="p-button-rounded p-button-outlined mr-4" @click="CierraInicializa()"
                    v-tooltip.bottom="'Cerrar'" style="color:#FB4130" />
                <Button icon="pi pi-download" class="p-button-rounded p-button-outlined" @click="exportarReporte()"
                    v-tooltip.bottom="'Descargar Reporte'" style="color:#38BD4B" />
            </div>
            <div v-if="!timbrando">Seleccione una opción o presione la tecla ESC</div>
        </Dialog>

        <div class="p-d-flex p-fluid grid formgrid">
            <div class="text-left field col-4">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="CierraVentana()" />
            </div>
            <div class="text-center field col-4">
                <h2>Factura</h2>
            </div>
            <div class="text-right field col-4">
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined" @click="PostFactura"
                    v-tooltip.bottom="'Timbrar'" />
                <Button icon="pi pi-check-square" class="p-button-rounded p-button-success p-button-outlined"
                    @click="PostFacturaMasivo" v-tooltip.bottom="'Timbrar Masivo'" />
            </div>
        </div>
        <hr size=7 color="#ED6421">
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- SERIE -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Dropdown v-model="comprobante.serie" :options="itemsSerie" :editable="true" />
                    <label>Serie</label>
                </span>
            </div>
            <!-- FECHA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Calendar v-model="comprobante.fecha" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                    <label>Fecha</label>
                </span>
            </div>
            <!-- TOTAL -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputNumber v-model="sumTotal" mode="decimal" :minFractionDigits="2" locale="en-US" />
                    <label>Total</label>
                </span>
            </div>
            <!-- CLIENTE -->
            <div class=" field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" class="auto-complete" v-model="comprobante.cliente"
                            :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                        </AutoComplete>
                        <label>Cliente</label>
                    </span>
                </div>
            </div>
            <!-- USO DE CFDI -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="comprobante.cliente.usoCfdi" :options="itemsUsoCfdi"
                        optionLabel="usoCfdi" />
                    <label>Uso de CFDI</label>
                </span>
            </div>
            <!-- METODO DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="comprobante.metodoPago" :options="itemsMetodoPago"
                        optionLabel="metodoPago" />
                    <label>Método de Pago</label>
                </span>
            </div>
            <!-- FORMA DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="comprobante.formaPago" :options="itemsFormaPago"
                        optionLabel="formaPago" />
                    <label>Forma de Pago</label>
                </span>
            </div>
        </div>
        <div>
            <DataTable class="mt-1 p-datatable-sm editable-cells-table" :value="itemsVentas" :scrollable="true"
                scrollHeight="800px" scrollDirection="both" :loading="loading"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Ventas." :paginator="true"
                :rows="10" editMode="cell" @cell-edit-complete="onCellEditComplete">

                <Column field="serie" header="Serie"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                </Column>
                <Column field="folio" header="Folio"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                </Column>
                <Column field="fecha" header="Fecha"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    <template #body="slotProps">
                        <span>{{ formatDate(slotProps.data.fecha) }}</span>
                    </template>
                </Column>
                <Column field="producto" header="Producto"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                </Column>
                <Column field="cantidad" header="Litros"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    <template #body="slotProps">
                        <span>{{ formatCantidad(slotProps.data.cantidad) }}</span>
                    </template>
                </Column>
                <Column field="subtotal" header="SubTotal"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    <template #body="slotProps">
                        <span>{{ formatCurrency(slotProps.data.subtotal) }}</span>
                    </template>
                </Column>
                <Column field="descuento" header="Descuento"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    <template #body="slotProps">
                        <span>{{ formatCurrency(slotProps.data.descuento) }}</span>
                    </template>
                </Column>
                <Column field="total" header="Total"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    <template #body="slotProps">
                        <span>{{ formatCurrency(slotProps.data.total) }}</span>
                    </template>
                </Column>

                <Column field="folioFiscalRelacionado" header="Folio Fiscal Relacionado"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    <template #editor="slotProps">
                        <InputText v-model="slotProps.data.folioFiscalRelacionado" autofocus />
                    </template>
                </Column>

            </DataTable>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from 'moment'
export default {
    components: {

    },
    data() {
        return {
            dialogLoading: false,
            loadingMensaje: '',
            loading: false,

            comprobante: {
                serie: '',
                fecha: new Date(),
                cliente: {
                    rfc: '',
                    nombre: '',
                    usoCfdi: {},
                    cliente: '',
                },
                metodoPago: '',
                formaPago: '',
            },

            filteredCliente: null,
            ventasI: false,
            progresVentas: 0,
            banderaFinaliza: false,
            respuestaTimbrado: [],
            timbrando: true,

            foliotimbrado: '',
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        itemsSerie() {
            return this.$store.state.listFacturaSerieStore;
        },
        itemsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
        itemsVentas() {
            return this.$store.state.listVentasStore;
        },
        sumTotal() {
            let suma = this.itemsVentas.reduce((acumulador, objeto) => acumulador + objeto.total, 0);
            return suma;
        },
    },
    created() {
        this.inicializa();
    },
    methods: {
        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            data[field] = newValue;
            console.log(field)
        },

        async inicializa() {
            this.dialogLoading = true;
            await this.getEmpresa();
            await this.GetSeries();
            await this.GetClientes();
            await this.GetUsoCfdi();
            await this.GetMetododPago();
            await this.GetFormaPago();
            this.dialogLoading = false;
        },

        async getEmpresa() {
            try {
                this.loadingMensaje = 'Consultando datos...'
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.$store.state.empresaStore = response.data
            } catch (error) {
                console.log(error)
                this.dialogLoading = false;
            }
        },

        async GetSeries() {
            if (this.$store.state.listFacturaSerieStore.length != 0) {
                return;
            }

            this.loadingMensaje = 'Consultando Series...'
            try {
                let response = await axios.get('Comprobantes/GetSerieComprobantes/' + this.token.empresaBase + '/Factura/' + this.empresa.rfc);
                this.$store.state.listFacturaSerieStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClientes() {
            this.loadingMensaje = 'Consultando Clientes...'
            try {
                let result = await axios.get('Clientes/GetClientes/' + this.token.empresaBase);
                this.$store.state.listClienteStore = result.data;
            } catch (error) {

            }
        },

        async GetUsoCfdi() {
            this.loadingMensaje = 'Consultando Uso de CFDI...'
            if (this.$store.state.usoCfdiStore.length != 0) {
                return;
            }
            try {
                let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                this.$store.state.usoCfdiStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async GetMetododPago() {
            this.loadingMensaje = 'Consultando Método de Pago...'
            if (this.$store.state.metodoPagoStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMetodoPago/');
                this.$store.state.metodoPagoStore = result.data;
            } catch (error) {

            }
        },

        async GetFormaPago() {
            this.loadingMensaje = 'Consultando Forma de Pago...'
            if (this.$store.state.formaPagoStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetFormaPago/');
                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },

        async GetFolio() {
            this.loadingMensaje = 'Consultando folio...'
            try {
                let response = await axios.get('Comprobantes/GetFolioComprobantes/' + this.token.empresaBase + '/Factura/' + this.comprobante.serie);
                return response.data;
            } catch (error) {

            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },

        formatCantidad(value) {
            let numeroFormateado = value.toFixed(3);
            return numeroFormateado;
        },

        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        CierraVentana() {
            this.$emit('cerrarVentanaFacturaGas')
        },

        async PostFactura() {
            this.respuestaTimbrado = [];
            this.banderaFinaliza = false;
            this.ventasI = true;
            let cuenta = this.itemsVentas.length;
            let valor = 100 / cuenta;
            this.progresVentas = 0;
            this.dialogLoading = true;
            let folioV = await this.GetFolio();
            // let folioV = this.foliotimbrado;
            console.log(folioV)
            this.loadingMensaje = 'Timbrando...'
            // this.dialogLoading = false;

            for (let v of this.itemsVentas) {
                let fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss');
                let ObjFactura = {
                    serie: this.comprobante.serie,
                    folio: folioV,
                    fecha: fecha,
                    cliente: this.comprobante.cliente,
                    metodoPago: this.comprobante.metodoPago,
                    formaPago: this.comprobante.formaPago,
                    usuario: 'Admin',
                    lista: [],
                    folioFiscalRelacionado: v.folioFiscalRelacionado,
                }
                ObjFactura.lista.push(v.id);
                try {
                    let response = await axios.post('Ventas/PostVentasReplicadorFactura/' + this.token.empresaBase, ObjFactura);
                    let respuestas = response.data;
                    for (var a of respuestas) {
                        if (a.error === '') {
                            this.$store.state.listVentasGasStore
                            let indice = this.$store.state.listVentasGasStore.findIndex(x => x.folio === a.folio);
                            this.$store.state.listVentasGasStore[indice].rfc = a.rfc;
                            this.$store.state.listVentasGasStore[indice].nombre = a.nombre;
                            this.$store.state.listVentasGasStore[indice].folioFiscal = a.folioFiscal;
                            this.$store.state.listVentasGasStore[indice].estatus = a.estatus;
                            this.$store.state.listVentasGasStore[indice].fechaF = a.fechaTimbrado;
                            this.$store.state.listVentasGasStore[indice].folioF = a.folioTimbrado;
                            this.$store.state.listVentasGasStore[indice].serieF = a.serieTimbrado;

                            let ObjRespuesta = {
                                noVenta: a.folio,
                                respuesta: 'Timbrado con éxito',
                                folioFiscal: a.folioFiscal
                            }
                            this.respuestaTimbrado.push(ObjRespuesta)
                            console.log(a.folio, 'Timbrado')
                        } else {
                            let ObjRespuesta = {
                                noVenta: a.folio,
                                respuesta: a.error,
                                folioFiscal: '',
                            }
                            if (a.folioFiscal === 'NA') {
                                let indice = this.$store.state.listVentasGasStore.findIndex(x => x.id == a.folio);
                                let Nv = this.$store.state.listVentasGasStore[indice].folio
                                ObjRespuesta.noVenta = Nv;
                                console.log(Nv, a.error)
                            } else {
                                console.log(a.folio, a.error)
                            }
                            this.respuestaTimbrado.push(ObjRespuesta)
                        }
                    }
                    this.progresVentas += valor;
                    folioV++;
                } catch (error) {
                    console.log(error)
                    this.progresVentas += valor;
                }
            }
            this.loadingMensaje = 'Proceso terminado'
            this.banderaFinaliza = true;
            this.timbrando = false;
        },

        async PostFacturaMasivo() {
            this.ventasI = false;
            let fecha = moment(this.comprobante.fecha).format('YYYY-MM-DD HH:mm:ss');
            let listIds = this.itemsVentas.map(x => x.id)
            let ObjFactura = {
                serie: this.comprobante.serie,
                folio: '',
                fecha: fecha,
                cliente: this.comprobante.cliente,
                metodoPago: this.comprobante.metodoPago,
                formaPago: this.comprobante.formaPago,
                usuario: 'Admin',
                lista: listIds,
            }
            // console.log(ObjFactura);

            try {
                this.dialogLoading = true;
                this.loadingMensaje = 'Timbrando...'
                let response = await axios.post('Ventas/PostVentasReplicadorFacturaMasivo/' + this.token.empresaBase, ObjFactura);
                console.log(response.data)
                let respuestas = response.data;
                for (var a of respuestas) {
                    if (a.error === '') {
                        this.$store.state.listVentasGasStore
                        let indice = this.$store.state.listVentasGasStore.findIndex(x => x.folio === a.folio);
                        console.log(indice)
                        this.$store.state.listVentasGasStore[indice].rfc = a.rfc;
                        this.$store.state.listVentasGasStore[indice].nombre = a.nombre;
                        this.$store.state.listVentasGasStore[indice].folioFiscal = a.folioFiscal;
                        this.$store.state.listVentasGasStore[indice].estatus = a.estatus;
                        this.$store.state.listVentasGasStore[indice].fechaF = a.fechaTimbrado;
                        this.$store.state.listVentasGasStore[indice].folioF = a.folioTimbrado;
                        this.$store.state.listVentasGasStore[indice].serieF = a.serieTimbrado;
                        this.$emit('CierraInicializa')
                    }
                }
            } catch (error) {
                console.log(error)
            }
            this.dialogLoading = false;
        },

        CierraInicializa() {
            this.$emit('CierraInicializa')
        },

        exportarReporte() {
            let fecha = new Date();
            fecha = moment(fecha).format('DD/MM/yyyy')
            let lista = [...this.respuestaTimbrado]
            let nombreArchivo = 'ReporteTimbrado' + fecha + '.txt'
            const contenido = lista.map(objeto => JSON.stringify(objeto)).join('\n');
            const enlaceDescarga = document.createElement('a');
            enlaceDescarga.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(contenido);
            enlaceDescarga.download = nombreArchivo;
            enlaceDescarga.style.display = 'none';
            document.body.appendChild(enlaceDescarga);
            enlaceDescarga.click();
            document.body.removeChild(enlaceDescarga);
        },

    },
}

</script>