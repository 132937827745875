<template>
    <div>


        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>

        <!-- DIALOG DEL CORREO -->
        <Dialog :visible.sync="dialogCorreo" :style="{ width: '1000px' }" :closable="false" :modal="true">
            <Card>
                <template #header>
                    <div class="flex justify-content-between flex-wrap pb-2">
                        <Button @click="closeDialogCorreo"> Cerrar</Button>
                        Enviar comprobante
                        <Button @click="enviaCorreo"> Enviar</Button>
                    </div>
                </template>
                <template #content>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <InputText v-model="correo.asunto" type="text" autofocus tabindex="1" />
                                <label>Asunto</label>
                            </span>
                        </div>
                    </div>
                    <InlineMessage severity="info">Para agregar un DESTINATARIO primero agregue un NOMBRE y un
                        CORREO</InlineMessage>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-6">
                            <span class="p-float-label">
                                <InputText v-model="nuevoDestinatario.nombre" type="text" ref="nombreF" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <div class="field col-6">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText v-model="nuevoDestinatario.correo" type="text"
                                        @keydown.enter="pushCorreo()" />
                                    <label>Correo</label>
                                    <Button icon="pi pi-plus" class="p-button-success" @click="pushCorreo()" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <Chips v-model="destinatarios" :allowDuplicate="false">
                                    <template #chip="slotProps">
                                        <div>
                                            <span style="font-size: 12px">{{ slotProps.value.nombre }} - {{
                                                slotProps.value.correo
                                            }}</span>
                                        </div>
                                    </template>
                                </Chips>
                                <label>Destinatarios</label>
                            </span>
                        </div>
                    </div>
                    <Editor v-model="correo.mensaje" editorStyle="height: 200px" />
                </template>
            </Card>
        </Dialog>

        <Card class="shadow-none mt-3">
            <template #header>
                <Button class="mr-1" @click="closeDialogPdf"> Cerrar</Button>
                <Button class="mr-1" @click="descargaComprobante"> Descargar PDF</Button>
                <Button class="mr-1" @click="openDialogCorreo"> Enviar</Button>
                <Button class="mr-1" @click="imprimirComprobante"> Imprimir</Button>
            </template>
            <template #content>
                <ScrollTop />
                <ScrollPanel style="height: 600px">
                    <div id="invoice">
                        <!-- CABECERA DEL COMPROBANTE -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                            <div class="md:col-6">
                                <a href="https://contago.com.mx/" target="_blank">
                                    <img :src="datosEmpresa.logo.base64" width="250">
                                </a>
                            </div>
                            <div class="md:col-6 text-right">
                                <font size="4">
                                    <div><strong>Cotización</strong></div>
                                </font>
                                <font size="3">
                                    <div>{{ datosEmpresa.rfc }}</div>
                                </font>
                                <font size="3">
                                    <div>{{ datosEmpresa.nombre }}</div>
                                </font>
                            </div>
                        </div>
                        <!-- DATOS DEL CLIENTE -->
                        <div class="grid">
                            <div class="col text-left">
                                <div><b>Cliente: </b> {{ item.cliente.nombre }}</div>
                                <div><b>Atn:</b> {{ item.atn }}</div>
                                <div><b>Email:</b> {{ item.email }}</div>
                                <div><b>Proyecto:</b> {{ item.proyecto.descripcion }}</div>
                            </div>
                            <div class="col text-right">
                                <div><b>Cotización No:</b> <b style="color:#FF0000">{{ item.serie }} {{ item.folio }}</b>
                                </div>
                                <div><b>Fecha: </b> {{ formatDate(item.fecha) }}</div>
                                <div><b>Vigencia: </b> {{ formatDate(item.vigencia) }}</div>
                                <div><b>Moneda: </b> {{ item.moneda.clave }}</div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col text-left">
                                <p class="font-bold" style="font-size: 12px">Tiempo de entrega</p>
                                <p style="font-size: 12px">{{ item.tiempoEntrega }}</p>
                            </div>
                            <div v-if="item.notas != ''">
                                <div class="col text-left">
                                    <p class="font-bold" style="font-size: 12px">Observaciones</p>
                                    <p style="font-size: 12px">{{ item.notas }}</p>
                                </div>
                            </div>
                            <div class="col text-left">
                                <p class="font-bold" style="font-size: 12px">Condiciones de Pago</p>
                                <p style="font-size: 12px">{{ item.condicionesPago }}</p>
                            </div>
                            <div class="col text-left">
                                <p class="font-bold" style="font-size: 12px">Condiciones de Entrega</p>
                                <p style="font-size: 12px">{{ item.condicionesEntrega }}</p>
                            </div>
                        </div>

                        <!-- CONCEPTOS -->
                        <DataTable class="mt-1 p-datatable-sm" :value="primeraParte" responsiveLayout="scroll">
                            <Column field="item" header="Item" :bodyStyle="{ 'max-width': '50px', 'min-width': '50px', }"
                                :styles="{ 'max-width': '50px', 'min-width': '50px', 'font-size': '90%' }">
                            </Column>
                            <Column field="descripcion" header="Descripción" class="flex"
                                :styles="{ 'max-width': '300px', 'min-width': '300px', 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                                <template #body="{ data }">
                                    <!-- <h4 style="overflow-wrap: break-word;">{{ data.descripcion }}</h4> -->
                                    <Editor readonly :value="separar(data.descripcion)" class="ed editor">
                                        <template hidden #toolbar>
                                            <span hidden class="ql-formats">

                                            </span>

                                        </template>
                                    </Editor>
                                    <div v-if="data.material != ''">
                                        <p style="font-size: 12px">Material: {{ data.material }}</p>
                                    </div>
                                    <div v-if="data.imagen.base64 != ''">
                                        <img width="300px" :src="data.imagen.base64" class=" img-item" />
                                    </div>
                                </template>
                            </Column>
                            <!-- <Column header="Imagen"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                                <template #body="slotProps">
                                    <img v-if="slotProps.data.imagen.base64 != ''" :src="slotProps.data.imagen.base64"
                                        class="product-image img-item" />
                                </template>
                            </Column> -->
                            <Column field="cantidad" header="Cantidad"
                                :styles="{ 'min-width': '80px', 'max-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                <template #body="{ data }">
                                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                                </template>
                            </Column>
                            <Column field="unidad" header="Unidad"
                                :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                            </Column>
                            <Column field="valorUnitario" header="Valor Unitario"
                                :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                </template>
                            </Column>
                            <Column field="importe" header="Importe"
                                :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.importe) }}</span>
                                </template>
                            </Column>
                        </DataTable>
                        <template v-if="segundaParte.length != 0">
                            <DataTable class="mt-3 p-datatable-sm ocult" :value="segundaParte" responsiveLayout="scroll">
                                <Column field="item" header="Item" style="max-width: 50px; min-width: 50px;"
                                    :styles="{ 'max-width': '50px', 'min-width': '50px', 'font-size': '90%' }"
                                    :bodyStyle="{ 'max-width': '50px', 'min-width': '50px', }">
                                </Column>
                                <Column field="descripcion" header="Descripción" class="flex"
                                    :styles="{ 'max-width': '300px', 'min-width': '300px', 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                                    <template #body="{ data }">
                                        <Editor readonly :value="separar(data.descripcion)" class="ed editor">
                                            <template hidden #toolbar>
                                                <span hidden class="ql-formats">

                                                </span>

                                            </template>
                                        </Editor>
                                        <!-- <h4 style="overflow-wrap: break-word;">{{ data.descripcion }}</h4> -->
                                        <div v-if="data.material != ''">
                                            <p style="font-size: 12px">Material: {{ data.material }}</p>
                                        </div>
                                        <div v-if="data.imagen.base64 != ''">
                                            <img width="300px" :src="data.imagen.base64" class="img-item" />
                                        </div>
                                    </template>
                                </Column>
                                <!-- <Column header="Imagen"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                                    <template #body="slotProps">
                                        <img v-if="slotProps.data.imagen.base64 != ''" :src="slotProps.data.imagen.base64"
                                            class="product-image img-item" />
                                    </template>
                                </Column> -->
                                <Column field="cantidad" header="Cantidad"
                                    :styles="{ 'min-width': '80px', 'max-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                    <template #body="{ data }">
                                        <span>{{ formatoCantidad(data.cantidad) }}</span>
                                    </template>
                                </Column>
                                <Column field="unidad" header="Unidad"
                                    :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                </Column>
                                <Column field="valorUnitario" header="Valor Unitario"
                                    :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                    </template>
                                </Column>
                                <Column field="importe" header="Importe"
                                    :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.importe) }}</span>
                                    </template>
                                </Column>
                            </DataTable>
                        </template>

                        <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                        <div class="grid">
                            <div class="col-8 text-left mt-2">
                                <!-- IMAGEN GENERAL -->
                                <template v-if="item.imagenGeneral.base64 != ''">
                                    <p style="font-size: 12px"><strong>Imagen General de Cotización: </strong> </p>
                                    <img style="min-width: 200px; max-width: 350px;" :src="item.imagenGeneral.base64"
                                        class="img-item" />
                                </template>
                            </div>
                            <div class="col-4">
                                <DataTable :value="itemsImpuestos" class="ocult" responsiveLayout="scroll">
                                    <Column field="impuesto" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                    </Column>
                                    <Column field="importe" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                        <template #body="{ data }">
                                            <span>{{ formatCurrency(data.importe) }}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                            <div class="col-12 text-center">
                                <p style="font-size: 12px"><strong>Importe con letra: </strong> {{ importeLetra }}</p>
                                <br>
                            </div>
                        </div>
                        <div class="grid text-left">
                            <div class="col-8">
                                <Editor class="editor" v-model="datosEmpresa.notasVentasA" :style="{ 'font-size': '50%' }">
                                    <template hidden #toolbar>
                                        <span hidden class="ql-formats">
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                            <div class="col-4 text-right">
                                <Editor class="editor" v-model="datosEmpresa.notasVentasB" :style="{ 'font-size': '50%' }">
                                    <template hidden #toolbar>
                                        <span hidden class="ql-formats">
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </template>
        </Card>
        <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
    </div>
</template>

<script>
import moment from "moment";
import html2pdf from "html2pdf.js";
import axios from 'axios'
import { numeroALetras } from '../../plugins/numeroALetras.js'
import print from 'print-js'

export default {
    components: {

    },
    data() {
        return {
            dialogCorreo: false,
            loadingMensaje: '',
            loading: false,
            destinatarios: [],
            nuevoDestinatario: {
                nombre: '',
                correo: ''
            },
            primeraParte: [],
            segundaParte: [],
        };
    },
    computed: {
        item() {
            return this.$store.state.ventaCotizacionStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },

        importeLetra() {
            const func = require('../../plugins/numeroALetras.js')
            let a = func.numeroALetras(this.$store.state.ventaCotizacionStore.total, this.$store.state.ventaCotizacionStore.moneda.clave);
            return a;
        },

        datosEmpresa() {
            return this.$store.state.empresaStore
        },

        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.ventaCotizacionStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.ventaCotizacionStore.impuestos.traslados) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.ventaCotizacionStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.ventaCotizacionStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
        correo() {
            return this.$store.state.correoStore
        },
    },
    created() {
        this.getCorreo();
        this.divirConceptos();
    },
    watch: {

    },
    mounted() {

    },
    methods: {
        separar(a) {
            // let b = a.replace(/~/g, '<br/>');

            return '<h5>' + a + '</h5>'
        },
        divirConceptos() {
            if (this.item.conceptos.length > 8) {
                this.primeraParte = this.item.conceptos.slice(0, 7);
                this.segundaParte = this.item.conceptos.slice(7);
            }
            else {
                this.primeraParte = this.item.conceptos
            }

        },
        async getCorreo() {
            this.correo.asunto = 'Se envía documento'
            this.loadingMensaje = 'Espere...'
            this.loading = true
            let idCliente = this.item.cliente.idCliente
            try {
                //AGREGAMOS EL CORREO QUE VIENE EN LA COTIZACIÓN
                let correoD = {
                    nombre: this.item.atn,
                    correo: this.item.email
                };
                this.destinatarios.push(correoD)
                //AGREGAMOS LOS CORREOS POR DEFAULT
                let response = await axios.get('Correos/GetCorreoContacto/' + this.empresa.empresaBase + '/' + idCliente);
                console.log(response)
                let correos = response.data;
                for (let c of correos) {
                    this.destinatarios.push(c)
                }
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },

        pushCorreo() {
            if (this.nuevoDestinatario.correo === '') {
                this.$toast.add({ severity: 'error', detail: 'Error al agregar destinatario, indique un correo', life: 5000 });
                return
            }
            let nuevo = { ...this.nuevoDestinatario }
            this.destinatarios.push(nuevo)
            let inicializa = {
                nombre: '',
                correo: ''
            }
            this.nuevoDestinatario = { ...inicializa }
            this.$refs.nombreF.$el.focus();
        },

        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy");
            return fechaR;
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        async exportToPDF() {
            var opt = {
                margin: .5,
                filename: 'COTIZACION_' + this.item.serie + "_" + this.item.folio + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //EXPORTA EL PDF
            html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                // console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');

                    // you can add the line separator as an image, consult the docs below to properly set the place of the image
                    // pdf.addImage(img, 'png', 0, 0, 52, 23)
                }

            }).save();
        },

        async convertBase64() {
            var opt = {
                margin: .5,
                filename: 'COTIZACION_' + this.item.serie + "_" + this.item.folio + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                // console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');
                }

            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return 'data:application/pdf;base64,' + file
        },

        async descargaComprobante() {
            await this.exportToPDF()
        },

        openDialogCorreo() {
            this.correo.archivos = []

            this.correo.archivos.push()
            this.dialogCorreo = true;
        },

        closeDialogPdf() {
            this.$emit('cierraDialogPdf')
        },

        closeDialogCorreo() {
            this.dialogCorreo = false;
        },

        async enviaCorreo() {
            this.loading = true;
            this.loadingMensaje = 'Enviando correo...'

            let correos = {
                asunto: this.correo.asunto,
                mensaje: this.correo.mensaje,
                emisor: {
                    nombre: '',
                    correo: '',
                    contra: '',
                    hostt: '',
                    puerto: 0,
                },
                receptores: [],
                archivos: [],
            }

            var pdf_ = {
                base64: await this.convertBase64(),
                nombre: this.item.serie + '_' + this.item.folio + '.pdf'
            }

            // for (let d of this.destinatarios) {
            //     var des = {
            //         nombre: '',
            //         correo: d,
            //     }
            correos.receptores = this.destinatarios;
            // des = {}
            // }

            correos.archivos.push(pdf_);

            console.log(correos)


            try {
                let response = await axios.post('Correos/PostCorreos/' + this.empresa.empresaBase + '/' + this.empresa.idusuariosApp, correos)
                console.log(response.data)
                this.dialogCorreo = false;
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Correo enviado con éxito', life: 5000 });

            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al enviar:' + error.response.data, life: 5000 });
                console.log(error)
                this.loading = false;
            }
        },

        async imprimirComprobante() {
            this.loadingMensaje = ''
            this.loading = true

            let documento = await (await this.convertBase64()).split(',')[1];
            //console.log(documento)
            print({
                printable: documento,
                type: 'pdf',
                base64: true,
            })
            this.loading = false

        }
    }
};
</script>
<style>
.col {
    background-color: white;
}

.grid {
    background-color: white;
}

.ocult thead {
    display: none;
}

.ocult table {
    border: none
}

.editor .p-editor-container .p-editor-toolbar.ql-snow {
    border: none !important;
}

.editor .ql-toolbar.ql-snow {
    border: 0px solid #ffffff !important;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 0px;
}

.editor .ql-container {
    font-size: 10px;
}

.p-editor-container .p-editor-content.ql-snow {
    border: 0px solid #ffffff !important;
}

.editor {
    padding: 0px 0px;
    font-size: 14px;
}

/* .img-item {
    /* height: 100%; */
/* width: 160px; 
    object-fit: cover;
    object-position: bottom;
} */
</style>