<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Dirección
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">
                    <!-- PAIS -->
                    <div class="justify-right field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <!-- <Dropdown v-model="item.pais" :options="itemsPaises" optionLabel="pais">
                                </Dropdown> -->
                                <AutoComplete v-model="item.pais" :suggestions="filtroPaises" @complete="searchPais($event)"
                                    field="pais">
                                </AutoComplete>
                                <Button @click="listarEstados()" icon="pi pi-check" class="p-button-warning" />
                                <label>País</label>
                            </span>
                            <!-- <span>{{ item.pais }}</span> -->
                        </div>
                    </div>
                    <!-- ESTADO -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.estado" :options="itemsEstado" optionLabel="estado">
                            </Dropdown>
                            <label>Estado</label>
                        </span>
                    </div>
                    <!-- CODIGO POSTAL -->
                    <div class="justify-right field col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <InputText v-model="item.codigoPostal" />
                                <Button @click="validaCodigoPostal()" icon="pi pi-check" class="p-button-warning" />
                                <label>Código Postal</label>
                            </span>
                        </div>
                    </div>
                    <!-- MUNICIPIO -->
                    <template v-if="item.pais.pais == 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="item.municipio" :options="itemsMunicipio" optionLabel="municipio">
                                </Dropdown>
                                <label>Municipio</label>
                            </span>
                        </div>
                    </template>
                    <!-- MUNICIPIO PARA OTROS PAISES QUE NO SEA MEXICO -->
                    <template v-if="item.pais.pais != 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText v-model="municipio.clave" />
                                <label>Municipio</label>
                            </span>
                        </div>
                    </template>

                    <!-- LOCALIDAD -->
                    <template v-if="item.pais.pais == 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="item.localidad" :options="itemsLocalidad" optionLabel="localidad">
                                </Dropdown>
                                <label>Localidad</label>
                            </span>
                        </div>
                    </template>
                    <!-- LOCALIDAD PARA OTROS PAISES QUE NO SEA MEXICO -->
                    <template v-if="item.pais.pais != 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText v-model="localidad.clave" />
                                <label>Localidad</label>
                            </span>
                        </div>
                    </template>

                    <!-- COLONIA -->
                    <template v-if="item.pais.pais == 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="item.colonia" :options="itemsColonia" optionLabel="colonia">
                                </Dropdown>
                                <label>Colonia</label>
                            </span>
                        </div>
                    </template>
                    <!-- COLONIA PARA OTROS PAISES QUE NO SEA MEXICO -->
                    <template v-if="item.pais.pais != 'MEX | México'">
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText v-model="colonia.clave" />
                                <label>Colonia</label>
                            </span>
                        </div>
                    </template>

                    <!-- CALLE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="item.calle" />
                            <label>Calle</label>
                        </span>
                    </div>
                    <!-- REFERENCIA -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText v-model="item.referencia" />
                            <label>Referencia</label>
                        </span>
                    </div>
                    <!-- NUMERO EXTERIOR -->
                    <div class="justify-right field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText v-model="item.numeroExterior" />
                            <label>Número Exterior</label>
                        </span>
                    </div>
                    <!-- NUMERO INTERIOR -->
                    <div class="justify-right field col-12 md:col-6">
                        <span class="p-float-label">
                            <InputText v-model="item.numeroInterior" />
                            <label>Número Interior</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',


            editedIndex: -1,
            itemsPais: [],
            itemsEstado: [],
            itemsColonia: [],
            itemsLocalidad: [],
            itemsMunicipio: [],
            filtroPaises: null,

            municipio: {
                clave: '',
                c_Estado: '',
                municipio: '',
                descripcion: ''
            },
            localidad: {
                clave: '',
                c_Estado: '',
                localidad: '',
                descripcion: ''
            },
            colonia: {
                clave: '',
                colonia: '',
                c_CodigoPostal: '',
                descripcion: ''
            }

        }
    },
    computed: {
        item() {
            return this.$store.state.direccionStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        itemsPaises() {
            return this.$store.state.listaPaisStore;
        },
    },
    created() {
        this.listarPaises()
        this.validarEstadosEditar()
    },
    methods: {
        //VALIDAR ESTADOS AL EDITAR
        async validarEstadosEditar() {
            if (this.item.id != 0) {
                this.localidad = this.item.localidad
                this.municipio = this.item.municipio
                this.colonia = this.item.colonia
                await this.listarEstados()
                await this.validaCodigoPostal()
            }
        },

        guardarOactualizar() {
            if (this.$store.state.direccionStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },
        searchPais(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroPaises = [...this.itemsPaises];
                } else {
                    this.filtroPaises = this.itemsPaises.filter(
                        (country) => {
                            return country.pais
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },
        async postItem() {
            if (this.item.pais.pais != 'MEX | México') {
                this.municipio.c_Estado = this.item.estado.clave
                this.municipio.municipio = this.municipio.clave + ' | ' + this.municipio.clave
                this.municipio.descripcion = this.municipio.clave

                this.localidad.c_Estado = this.item.estado.clave
                this.localidad.localidad = this.localidad.clave + ' | ' + this.localidad.clave
                this.localidad.descripcion = this.localidad.clave

                this.colonia.colonia = this.colonia.clave + ' | ' + this.colonia.clave
                this.colonia.descripcion = this.colonia.clave

                this.item.municipio = { ...this.municipio }
                this.item.localidad = { ...this.localidad }
                this.item.colonia = { ...this.colonia }
            }

            this.loading = true;
            this.loadingMensaje = 'Guardando Dirección, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostDirecciones/' + this.empresa.empresaBase, this.item)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Dirección guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listDireccionStore.push(this.item)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            if (this.item.pais.pais != 'MEX | México') {
                this.municipio.c_Estado = this.item.estado.clave
                this.municipio.municipio = this.municipio.clave + ' | ' + this.municipio.clave
                this.municipio.descripcion = this.municipio.clave

                this.localidad.c_Estado = this.item.estado.clave
                this.localidad.localidad = this.localidad.clave + ' | ' + this.localidad.clave
                this.localidad.descripcion = this.localidad.clave

                this.colonia.colonia = this.colonia.clave + ' | ' + this.colonia.clave
                this.colonia.descripcion = this.colonia.clave

                this.item.municipio = { ...this.municipio }
                this.item.localidad = { ...this.localidad }
                this.item.colonia = { ...this.colonia }
            }
            this.loading = true;
            this.loadingMensaje = 'Actualizando Dirección, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateDirecciones/' + this.empresa.empresaBase, this.item)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listDireccionStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listDireccionStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Origen actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        async listarPaises() {
            if (this.itemsPaises.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetPaises');
                this.$store.state.listaPaisStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async listarEstados() {
            this.loading = true;
            this.loadingMensaje = 'Validando país, espere...'
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetEstado/' + this.item.pais.clave);
                this.itemsEstado = response.data
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        async validaCodigoPostal() {
            if (this.item.codigoPostal == '' || !this.item.codigoPostal) {
                this.$toast.add({ severity: 'info', detail: 'Indique un Código Postal.', life: 3000 });
                return
            }

            if (!this.item.estado) {
                this.$toast.add({ severity: 'info', detail: 'Indique un Estado.', life: 3000 });
                return
            }


            if (this.item.pais.clave === 'MEX') {
                try {
                    let response = await axios.get('CatalogosSatCartaPorte/GetCodigoPostal/' + this.item.codigoPostal + '/' + this.item.estado.clave);
                    console.log(response)
                    this.listarMunicipio(this.item.estado.clave, response.data.c_Municipio);
                    this.listarLocalidad(this.item.estado.clave, response.data.c_Localidad);
                    this.listarColonia(this.item.codigoPostal);
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async listarMunicipio(estado, municipio) {
            console.log(estado, municipio)
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetMunicipio/' + estado + '/' + municipio);
                this.itemsMunicipio = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async listarLocalidad(estado, localidad) {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetLocalidad/' + estado + '/' + localidad);
                this.itemsLocalidad = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async listarColonia(codigoPostal) {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetColonia/' + codigoPostal);
                this.itemsColonia = response.data
            } catch (error) {
                console.log(error)
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>