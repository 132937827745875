<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO PROTOCOLO -->
        <Dialog :modal="false" header="Remisiones" position="top" :visible.sync="dialogoNuevo" :closeOnEscape="true"
            :closable="true" :style="{ width: '95vw' }">
            <Protocolo @cierraVentana="dialogoNuevo = false"></Protocolo>
        </Dialog>

        <!-- PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <ProtocoloPdf @cierraDialogPdf="closeDialogPdf"></ProtocoloPdf>
        </Dialog>

        <!-- NUEVA FACTURA -->
        <Dialog :showHeader="false" position="top" :visible.sync="dialogFactura" :closeOnEscape="true" :closable="false"
            :modal="false" :style="{ width: '95vw' }">
            <factura @closeFactura="dialogFactura = false"></factura>
        </Dialog>

        <!-- COMERCIO EXTERIOR -->
        <Dialog :visible.sync="dialogComercioExterior" :style="{ width: '1200px' }" :closable="false" :modal="true"
            position="top">
            <comercio-exterior @closeComprobante="dialogComercioExterior = false"></comercio-exterior>
        </Dialog>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar el protocolo de la remisión <b>{{
                    objetoEditado.serie + ' | ' + objetoEditado.folio
                }}</b>.?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarProtocolo()" />
            </template>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
                        <Button icon="mdi mdi-arrow-left-bold"
                            class="p-button-rounded p-button-secondary p-button-outlined -mb-1" @click="irRemision()" />
                        Protocolos
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                            <label>Fecha Inicial</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
                        <span class="p-float-label">
                            <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                            <label>Fecha Final</label>
                        </span>
                    </div>
                    <div
                        class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
                        <Button icon="pi pi-plus" @click="nuevoProtocolo()" class="mx-2 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getProtocolos()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="mt-1 p-datatable-sm" :value="itemsProtocolos" :scrollable="true" scrollHeight="500px"
                    scrollDirection="both" :loading="loadingItemsProtocolos" :rowsPerPageOptions="[10, 25, 50]"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :paginator="true" :rows="10" contextMenu @row-contextmenu="onRowContextMenu"
                    :selection.sync="selectedProtocolo" selectionMode="multiple" :metaKeySelection="false">
                    <template #header>
                        <div style="justify-content:right" class="flex">
                            <Button label="Borrar selección" class="p-button-outlined p-button-help"
                                @click="borrarSeleccion" />
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '8rem', }">
                        <template #body="slotProps">
                            <Button @click="confirmDeleteProduct(slotProps.data)" icon="pi pi-trash"
                                class="p-button-rounded p-button-danger" v-tooltip.bottom="'Cancelar'" />
                            <Button @click="openDialogPdf(slotProps.data)" icon="pi pi-file-pdf"
                                class="p-button-rounded p-button-warning" v-tooltip.bottom="'PDF'" />
                            <!-- <Button @click="ver(slotProps.data)" icon="pi pi-file-pdf" -->
                            <!-- class="p-button-rounded p-button-warning" v-tooltip.bottom="'PDF'" /> -->
                        </template>
                    </Column>
                    <Column field="idVentaProtocolo" header="Protocolo" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable>
                    </Column>
                    <Column field="serie" header="Serie de Remisión" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }"
                        sortable>
                    </Column>
                    <Column field="folio" header="Folio de Remisión"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }" sortable>
                        <template #body="slotProps">
                            <span :class="'customer-badge status-' + slotProps.data.estatus">{{
                                slotProps.data.folio
                            }}</span>
                        </template>
                    </Column>
                    <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
                    </Column>
                    <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
                    </Column>
                    <Column field="proyecto.descripcion" header="Proyecto"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
                    </Column>
                    <Column field="fechaProtocolo" header="Fecha de Protocolo" dataType="date"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
                        <template #body="{ data }">
                            {{ formatDate(data.fechaProtocolo) }}
                        </template>
                    </Column>
                    <!-- <Column field="estatusFactura" header="Estatus de Factura"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
                        <template #body="slotProps">
                            <span :class="'product-badge-protocolo protocolo-' + slotProps.data.estatusFactura">{{
                                slotProps.data.estatusFactura
                            }}</span>
                        </template>
                    </Column> -->
                </DataTable>
                <ContextMenu :model="menuModel" ref="cm" />
            </template>
        </Card>
    </div>
</template>
<script>
import axios from "axios";
import Protocolo from "./ProtocoloDetalles.vue";
import ProtocoloPdf from "../Pdf/VentaProtocoloPdf";
import moment from "moment";
import factura from '../Comprobantes/Factura/Factura.vue';
import comercioExterior from '../Comprobantes/ComercioExterior/ComercioExterior.vue';

export default {
    components: { Protocolo, ProtocoloPdf, factura, comercioExterior },
    data() {
        return {
            dialogLoading: false,
            loadingMensaje: '',
            fechaI: new Date(),
            fechaF: new Date(),
            loadingItemsProtocolos: false,
            dialogoNuevo: false,
            dialogPdf: false,

            deleteProductDialog: false,
            editedIndex: -1,
            objetoEditado: {},
            dialogFactura: false,

            selectedProtocolo: null,
            menuModel: [
                { label: 'Factura', icon: 'pi pi-fw pi-file', command: () => this.facturarProtocolo(this.selectedProtocolo) },
                { label: 'Comercio Exterior', icon: 'pi pi-fw pi-file', command: () => this.facturarProtocoloComercioExterior(this.selectedProtocolo) },
            ],
            listaConceptosFactura: [],
            listaConceptosFacturaFinal: [],
            impu: {
                tras: [],
                ret: []
            },
            total: 0,
            subTotal: 0,
            dialogComercioExterior: false,

            remision: {},
            moeneda: {},
            cliente: {},
            protocolos: [],
            conceptoDefault2: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            conceptoDefault: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            OtraLista: []
        };
    },
    computed: {
        itemsProtocolos() {
            return this.$store.state.listProtocolosStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getEmpresa()
    },
    methods: {
        irRemision() {
            this.$router.push({ name: "ListaVentasRemision" });
        },
        ver(data) {
            console.log(data)
        },
        facturarProtocolo(data) {
            const listaProtocolos = data
            const listaConceptos = []
            this.listaConceptosFacturaFinal = []
            this.remision = listaProtocolos[0].idVenta
            this.cliente = listaProtocolos[0].cliente
            this.moneda = listaProtocolos[0].moneda
            this.listaConceptosFactura = []
            this.protocolos = []
            this.impu = {
                tras: [],
                ret: []
            }

            //RECORREMOS CADA UNO DE LOS PROTCOLOS
            for (const p of listaProtocolos) {
                for (var c of p.conceptos) {
                    // const Obj = {}
                    const Obj = Object.assign({}, c)
                    listaConceptos.push(Obj)
                    // Obj = {}
                }
                const protocolo = {
                    id: p.idVentaProtocolo,
                    idVenta: p.idVenta,
                    folioFiscal: '',
                }
                this.protocolos.push(protocolo);
                // protocolo = {};
            }
            for (let i = 0; i < listaProtocolos[0].conceptos.length; i++) {
                // for (var conc of listaProtocolos[0].conceptos) {
                this.OtraLista = []
                // const listaChida = []
                // const conceptoChido = {}

                const listaChida = listaConceptos.filter(a => a.item === listaProtocolos[0].conceptos[i].item);
                const conceptoChido = listaConceptos.find(c => c.item === listaProtocolos[0].conceptos[i].item)

                this.conceptoDefault = { ...this.conceptoDefault2 };

                this.conceptoDefault.almacenable = conceptoChido.almacenable
                this.conceptoDefault.cantidad = this.sum(listaChida, 'cantidadEntregar');
                this.conceptoDefault.claveProdServ = conceptoChido.claveProdServ,
                    this.conceptoDefault.claveUnidad = conceptoChido.claveUnidad,
                    this.conceptoDefault.concepto = Object.freeze(conceptoChido.concepto),
                    this.conceptoDefault.descripcion = conceptoChido.descripcion,
                    this.conceptoDefault.descuento = conceptoChido.descuento,
                    this.conceptoDefault.estatus = conceptoChido.estatus,
                    this.conceptoDefault.fechaCreacion = conceptoChido.fechaCreacion,
                    this.conceptoDefault.fechaModificacion = conceptoChido.fechaModificacion,
                    this.conceptoDefault.folioFiscal = conceptoChido.folioFiscal,
                    this.conceptoDefault.idConcepto = conceptoChido.idConcepto,
                    this.conceptoDefault.idDocumento = conceptoChido.idDocumento,
                    this.conceptoDefault.iepsGas = conceptoChido.iepsGas,
                    this.conceptoDefault.imagen = conceptoChido.imagen,
                    this.conceptoDefault.importe = conceptoChido.importe,
                    this.conceptoDefault.impuestos = conceptoChido.impuestos,
                    this.conceptoDefault.item = conceptoChido.item,
                    this.conceptoDefault.linea = conceptoChido.linea,
                    this.conceptoDefault.marca = conceptoChido.marca,
                    this.conceptoDefault.material = conceptoChido.material,
                    this.conceptoDefault.noIdentificacion = conceptoChido.noIdentificacion,
                    this.conceptoDefault.numeroCuentaPredial = conceptoChido.numeroCuentaPredial,
                    this.conceptoDefault.objImp = conceptoChido.objImp,
                    this.conceptoDefault.precio = conceptoChido.precio,
                    this.conceptoDefault.submarca = conceptoChido.submarca,
                    this.conceptoDefault.total = conceptoChido.total,
                    this.conceptoDefault.unidad = conceptoChido.unidad,
                    this.conceptoDefault.usuarioCreador = conceptoChido.usuarioCreador,
                    this.conceptoDefault.usuarioModificador = conceptoChido.usuarioModificador,
                    this.conceptoDefault.valorUnitario = conceptoChido.valorUnitario

                this.listaConceptosFactura.push(this.conceptoDefault)
                this.OtraLista = [...this.listaConceptosFactura, this.conceptoDefault]
            }
            this.pushConceptos();

        },
        componeFactura() {
            const nuevo = {
                idFactura: 0,
                folioFiscal: '',
                serie: '',
                folio: '',
                fecha: new Date(),
                formaPago: {},
                condicionesPago: '',
                subTotal: this.subTotal,
                descuento: 0,
                moneda: this.moneda,
                tipoCambio: 0,
                total: this.total,
                tipoComprobante: 'I',
                tipoComprobanteInterno: 'Factura',
                metodoPago: {},
                empresa: {},
                cliente: this.cliente,
                rfc: this.cliente.rfc,
                nombre: this.cliente.nombre,
                atn: '',
                notas: '',
                estatus: 'Sin Timbrar',
                estatusCancelacion: '',
                usuarioCreador: '',
                usuarioModificador: '',
                usuarioAutoriza: '',
                conceptos: this.listaConceptosFacturaFinal.filter(a => a.cantidad != 0),
                impuestos: this.impu,
                cfdiRelacionados: [
                    {
                        tipoRelacion: {},
                        cfdiRelacionados: [],
                    }
                ],
                timbreFiscalDigital: {
                    folioFiscal: '',
                    fechaTimbrado: new Date(),
                    selloCFDI: { base64: '' },
                    noCertificado: '',
                    noCertificadoSAT: '',
                    selloSAT: { base64: '' },
                    cadenaOriginal: '',
                    serie: '',
                    folio: '',
                    Error: '',
                    archivoXml: '',
                },
                protocolos: this.protocolos,
                remisiones: []
            }
            this.$store.state.facturaStore = Object.assign({}, nuevo)
            this.dialogFactura = true;
        },
        facturarProtocoloComercioExterior(data) {
            console.log(data)
            this.listaConceptosFactura = []
            this.total = 0,
                this.subTotal = 0
            this.impuestos.traslados = []
            this.impuestos.retenciones = []
            try {
                let remision = this.selectedProtocolo[0].idVenta
                let cliente = this.selectedProtocolo[0].cliente
                let moneda = this.selectedProtocolo[0].moneda
                let conceptos = []
                let conceptosFactura = []
                let protocolos = []

                //RECORREMOS CADA UNO DE LOS PROTCOLOS
                for (var p of this.selectedProtocolo) {
                    // VALIDAMOS QUE SEAN DE LA MISMA REMISION 
                    if (p.idVenta != remision) {
                        this.$toast.add({ severity: 'error', detail: 'Todas los protocolos deben ser de la misma Remisión.', life: 3000 });
                        return
                    }

                    for (var concepto of p.conceptos) {
                        let Obj = {}
                        Obj = Object.assign({}, concepto)
                        conceptos.push(Obj)
                        Obj = {}
                    }
                    let protocolo = {
                        id: p.idVentaProtocolo,
                        idVenta: p.idVenta,
                        folioFiscal: '',
                    }
                    protocolos.push(protocolo);
                    protocolo = {};
                }

                console.log('el primero', this.selectedProtocolo[0].conceptos)

                for (var conc of this.selectedProtocolo[0].conceptos) {
                    let conceptoChido = {}
                    let listaChida = conceptos.filter(a => a.item === conc.item);
                    conceptoChido = conceptos.find(c => c.item === conc.item)
                    let conceptoDefault = {}

                    conceptoDefault.almacenable = conceptoChido.almacenable
                    conceptoDefault.cantidad = this.sum(listaChida, 'cantidadEntregar');
                    conceptoDefault.claveProdServ = conceptoChido.claveProdServ,
                        conceptoDefault.claveUnidad = conceptoChido.claveUnidad,
                        conceptoDefault.concepto = conceptoChido.concepto,
                        conceptoDefault.descripcion = conceptoChido.descripcion,
                        conceptoDefault.descuento = conceptoChido.descuento,
                        conceptoDefault.estatus = conceptoChido.estatus,
                        conceptoDefault.fechaCreacion = conceptoChido.fechaCreacion,
                        conceptoDefault.fechaModificacion = conceptoChido.fechaModificacion,
                        conceptoDefault.folioFiscal = conceptoChido.folioFiscal,
                        conceptoDefault.idConcepto = conceptoChido.idConcepto,
                        conceptoDefault.idDocumento = conceptoChido.idDocumento,
                        conceptoDefault.iepsGas = conceptoChido.iepsGas,
                        conceptoDefault.imagen = conceptoChido.imagen,
                        conceptoDefault.importe = conceptoChido.importe,
                        conceptoDefault.impuestos = conceptoChido.impuestos,
                        conceptoDefault.item = conceptoChido.item,
                        conceptoDefault.linea = conceptoChido.linea,
                        conceptoDefault.marca = conceptoChido.marca,
                        conceptoDefault.material = conceptoChido.material,
                        conceptoDefault.noIdentificacion = conceptoChido.noIdentificacion,
                        conceptoDefault.numeroCuentaPredial = conceptoChido.numeroCuentaPredial,
                        conceptoDefault.objImp = conceptoChido.objImp,
                        conceptoDefault.precio = conceptoChido.precio,
                        conceptoDefault.submarca = conceptoChido.submarca,
                        conceptoDefault.total = conceptoChido.total,
                        conceptoDefault.unidad = conceptoChido.unidad,
                        conceptoDefault.usuarioCreador = conceptoChido.usuarioCreador,
                        conceptoDefault.usuarioModificador = conceptoChido.usuarioModificador,
                        conceptoDefault.valorUnitario = conceptoChido.valorUnitario

                    conceptosFactura.push(conceptoDefault)
                }
                console.log('conceptosFactura', conceptosFactura)
                this.listaConceptosFactura = []
                this.pushConceptos(conceptosFactura);

                let nuevo = {
                    idComprobante: 0,
                    folioFiscal: '',
                    serie: '',
                    folio: '',
                    fecha: new Date(),
                    formaPago: {},
                    condicionesPago: '',
                    subTotal: this.subTotal,
                    descuento: 0,
                    moneda: moneda,
                    tipoCambio: 0,
                    total: this.total,
                    tipoComprobante: 'I',
                    tipoComprobanteInterno: 'CE',
                    metodoPago: {},
                    empresa: {},
                    cliente: cliente,
                    rfc: cliente.rfc,
                    nombre: cliente.nombre,
                    atn: '',
                    notas: '',
                    estatus: 'Sin Timbrar',
                    estatusCancelacion: '',
                    usuarioCreador: '',
                    usuarioModificador: '',
                    usuarioAutoriza: '',
                    conceptos: this.listaConceptosFactura.filter(a => a.cantidad != 0),
                    impuestos: this.impuestos,
                    cfdiRelacionados: [
                        {
                            tipoRelacion: {},
                            cfdiRelacionados: [],
                        }
                    ],
                    timbreFiscalDigital: {
                        folioFiscal: '',
                        fechaTimbrado: new Date(),
                        selloCFDI: { base64: '' },
                        noCertificado: '',
                        noCertificadoSAT: '',
                        selloSAT: { base64: '' },
                        cadenaOriginal: '',
                        serie: '',
                        folio: '',
                        Error: '',
                        archivoXml: '',
                    },
                    comercio: {
                        version: '1.1',
                        motivoTraslado: {
                            clave: '',
                        },
                        tipoOperacion: {},
                        claveDePedimento: {},
                        certificadoOrigen: {},
                        numCertificadoOrigen: '',
                        numeroExportadorConfiable: '',
                        incoterm: {},
                        subdivision: '0',
                        observaciones: '',
                        tipoCambioUSD: 0,
                        totalUSD: 0,
                        emisor: {},
                        propietarios: [],
                        receptor: {},
                        destinatarios: [],
                        mercancias: [],
                    },
                    protocolos: protocolos,
                    remisiones: []
                }

                this.$store.state.comercioExteriorStore = Object.assign({}, nuevo)
                this.dialogComercioExterior = true;
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Seleccione al menos un protocolo', life: 3000 });
            }

        },
        pushConceptos() {
            for (let i = 0; i < this.OtraLista.length; i++) {
                // for (var C of this.listaConceptosFactura) {
                const conceptoBueno = Object.assign({}, this.OtraLista[i])
                const impuestosBueno = Object.assign({}, this.OtraLista[i].impuestos)

                var impuestosDefault = {
                    traslados: [],
                    retenciones: [],
                }

                // const objConcepto = {}
                // const objImpuestos = {}
                const objConcepto = Object.assign({}, conceptoBueno);
                const objImpuestos = Object.assign({}, impuestosBueno);

                const objImpuestosChido = {
                    traslados: [],
                    retenciones: [],
                }

                const importe = objConcepto.valorUnitario * objConcepto.cantidad;
                objConcepto.importe = Math.round(importe * 1000000) / 1000000;

                // CALCULAMOS LOS IMPUESTOS
                if (objConcepto.objImp.clave === '02') {
                    objConcepto.impuestos.traslados = []
                    for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                        var x = objImpuestos.traslados[i];
                        x.base_ = importe;
                        x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                        // console.log(x)
                        const y = Object.assign({}, x)
                        objImpuestosChido.traslados.push(y);
                    }
                    for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                        var x = objImpuestos.retenciones[i];
                        x.base_ = importe;
                        x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                        // console.log(x)
                        const y = Object.assign({}, x)
                        objImpuestosChido.retenciones.push(y);
                    }
                }

                //GUARDAMOS
                objConcepto.impuestos = Object.freeze(Object.assign({}, objImpuestosChido))
                this.listaConceptosFacturaFinal.push(objConcepto);

                // objConcepto = {};
                // objImpuestos = {};
                // objImpuestosChido = {}
                this.calcularImpuestos();
                this.sumatoria();
                this.componeFactura();
                //impuestosBueno = {};
                //  impuestosBueno = Object.assign({}, impuestosDefault);
                //conceptoBueno = {};
                //conceptoBueno = Object.assign({}, this.conceptoDefault2);
            }
        },
        calcularImpuestos() {
            this.impu.tras = []
            this.impu.ret = []
            const listTrasladados = [];
            const listRetenidos = [];
            // const listaImpuestos = []
            const listaImpuestos = this.listaConceptosFacturaFinal
            // const sumaT = 0.00;
            // const sumaR = 0.00;

            for (const a of listaImpuestos) {
                //VERIFICAMOS SI LLEVA IMPUESTOS
                if (a.objImp.clave === '02') {
                    for (let t of a.impuestos.traslados) {
                        // t.base_ = a.importe
                        // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                        let sumaT = sumaT + t.importe
                        sumaT = Math.round(sumaT * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        let im = Object.assign({}, t)
                        listTrasladados.push(im);
                        // im = {}
                    }

                    for (let r of a.impuestos.retenciones) {
                        // r.base_ = a.impo  rte
                        // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                        const im = Object.assign({}, r)
                        listRetenidos.push(im);
                        // im = {}
                    }
                }

                //AGRUPAMOS LOS IMPUESTOS
                this.impu.tras = [];
                const obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.impu.tras.push(tra)
                }
                // console.log(this.$store.state.facturaStore.impuestos.traslados)

                this.impu.ret = [];
                const objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.impu.ret.push(ret)
                }
            }
        },
        sumatoria() {
            let indice = 1;
            for (let x of this.listaConceptosFacturaFinal) {
                x.item = indice;
                indice++;
            }

            this.subTotal = Math.round(this.sum(this.listaConceptosFacturaFinal, 'importe') * 100) / 100;
            const tras = Math.round(this.sum(this.impu.tras, 'importe') * 100) / 100;
            const ret = Math.round(this.sum(this.impu.ret, 'importe') * 100) / 100;
            this.total = this.subTotal + tras - ret;
        },

        async getEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresa.empresaBase);
                this.$store.state.empresaStore = response.data
                console.log(this.$store.state.empresaStore)
            } catch (error) {
                console.log(error)
            }
        },
        nuevoProtocolo() {
            this.dialogoNuevo = true
        },

        async getProtocolos() {
            try {
                this.loadingItemsProtocolos = true;
                let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
                let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
                let response = await axios.get("Ventas/GetProtocolos/" + this.empresa.empresaBase + "/" + fechaInicial + "/" + fechaFinal);
                let array = response.data;
                this.$store.state.listProtocolosStore = array.sort(((a, b) => b.idVentaProtocolo - a.idVentaProtocolo))

                this.loadingItemsProtocolos = false;
            } catch (error) {
                console.log(error);
                this.loadingItemsProtocolos = false;
            }
        },

        async eliminarProtocolo() {
            this.loading = true;
            this.loadingMensaje = 'Procesando soliciud, espere...'

            try {
                this.objetoEditado.usuarioModificador = this.empresa.nombre
                this.objetoEditado.estatus = 'Cancelado'

                let response = await axios.put('Ventas/DeleteProtocolo/' + this.empresa.empresaBase, this.objetoEditado)
                this.itemsProtocolos.splice(this.editedIndex, 1)

                this.deleteProductDialog = false;
                this.loading = false;

                this.$toast.add({ severity: 'success', detail: 'Protocolo cancelado con éxito.', life: 3000 });

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al cancelar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },

        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsProtocolos.indexOf(data)
            this.objetoEditado = { ...data };
            this.deleteProductDialog = true;
        },

        openDialogPdf(data) {
            console.log(data)
            this.dialogPdf = true;
            this.$store.state.protocoloStore = { ...data }
        },
        closeDialogPdf() {
            this.dialogPdf = false;

        },
        formatCurrency(value) {
            return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
        onRowContextMenu(event) {
            this.$refs.cm.show(event.originalEvent);
        },
        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.idImpuesto };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },
        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
        borrarSeleccion() {
            this.selectedProtocolo = null;
        },
    }
}
</script>
<style>
.product-badge-protocolo.protocolo-SinFacturar {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge-protocolo.protocolo-Facturado {
    background: #c8e6c9;
    color: #256029;
}

.product-badge-protocolo {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .3px;
}
</style>