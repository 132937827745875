<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Permiso SCT
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid ">
                    <!-- NUMERO PERMISO SCT -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="permiso.numPermisoSCT" />
                            <label>Número de Permiso SCT</label>
                        </span>
                        <small>Este campo sirve para registrar el número de permiso otorgado a la unidad que realiza el
                            traslado de bienes y/o mercancías en territorio nacional.</small>

                    </div>
                    <!-- PERMISO SCT -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="permiso.permSCT" :options="itemsPermSCT" optionLabel="permSCT" />
                            <label>Permiso SCT</label>
                        </span>
                        <small>Este campo sirve para registrar la clave del tipo de permiso otorgado para realizar el
                            servicio de traslado de los bienes y/o mercancías.</small>
                    </div>
                    <InlineMessage severity="info">Nota:Cuando no se cuente con un permiso emitido por parte de la
                        Secretaría de Infraestructura, Comunicaciones y Transportes debido a que no es requerido o se
                        cuenta con un permiso de ámbito local o estatal se deberá registrar la clave TPXX00 en el campo
                        “Permiso SCT”, registrando el número de permiso local o estatal en el campo “Número de Permiso
                        SCT”, en caso de no requerir permiso la descripción “Permiso no contemplado en el catálogo” en
                        dicho campo.</InlineMessage>

                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

            itemsPermSCT: [],
        }
    },
    computed: {
        permiso() {
            return this.$store.state.permisoSCTStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.searchPermSCT();
    },
    methods: {
        async searchPermSCT() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetTipoPermiso/01')
                this.itemsPermSCT = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        guardarOactualizar() {
            if (this.$store.state.permisoSCTStore.id == 0) {
                this.postPermiso();
            } else {
                this.updatePermiso();
            }
        },

        async postPermiso() {
            this.loading = true;
            this.loadingMensaje = 'Guardando permiso, espere...'
            this.permiso.usuarioCreador = this.empresa.nombre
            try {
                let response = await axios.post('CatalogosCartaPorte/PostPermisos/' + this.empresa.empresaBase, this.permiso)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Permiso guardado con exito.', life: 3000 });

                this.permiso.id = response.data
                this.$store.state.listPermisoSCTStore.push(this.permiso)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updatePermiso() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando permiso, espere...'
            this.permiso.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdatePermisos/' + this.empresa.empresaBase, this.permiso)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listPermisoSCTStore.findIndex(x => x.id === this.permiso.id);
                Object.assign(this.$store.state.listPermisoSCTStore[indice], this.permiso)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Permiso actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>