<template>
  <div>
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <div class="
            scalein
            animation-duration-2000 animation-iteration-infinite
            flex
            align-items-center
            justify-content-center
            font-bold
            border-round
            m-2
            px-5
            py-3
          ">
        {{ loadingMensaje }}
      </div>
    </Dialog>

    <!-- DIALOGO CONFIRMAR ELIMINACION -->
    <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Estas seguro de eliminar <b>{{ objetoEditado.placaVM }}</b>.?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogDelete = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminar()" />
      </template>
    </Dialog>

    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
            Vehículo
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
            <Button icon="pi pi-plus" class="mx-2 p-button-rounded" @click="nuevoVehiculo()" />
            <Button icon="pi pi-undo" class="p-button-rounded" />
          </div>
        </div>
      </template>

      <template #content>
        <DataTable class="p-datatable-sm" :filters.sync="filtrosVehiculos" removableSort showGridlines ref="clientes"
          :value="itemsVehiculo" responsiveLayout="scroll" :paginator="true" :rows="10"
          :globalFilterFields="['id', 'placa',]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Remolques."
          :loading="loadingItemsVehiculo">
          <template #header>
            <div class="flex justify-content-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filtrosVehiculos['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column sortable field="id" header="ID" :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
            <template #body="{ data }">
              {{ data.id }}
            </template>
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
            </template>
          </Column>
          <Column sortable field="placaVM" header="Placa" :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
            <template #body="{ data }">
              {{ data.placaVM }}
            </template>
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
            </template>
          </Column>
          <Column sortable field="anioModelo" header="Año modelo"
            :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
            <template #body="{ data }">
              {{ data.anioModelo }}
            </template>
            <!-- <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
            </template> -->
          </Column>
          <Column sortable field="configAutotransporte.configVehicular" header="Vehículo"
            :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
            <template #body="{ data }">
              {{ data.configAutotransporte.configAutotransporte }}
            </template>
            <!-- <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template> -->
          </Column>
          <!-- 
          <Column field="configVehicular" header="Configuracion Vehicular" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            
          </Column> -->
          <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Editar/Eliminar">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editar(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                @click="confirmDeleteProduct(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <!-- NUEVA VENTA COTIZACION -->
    <Dialog :visible.sync="dialogNuevoVehiculo" :closeOnEscape="true" :closable="false" :style="{ width: '70vw' }">
      <Vehiculo @closeVehiculo="dialogNuevoVehiculo = false"></Vehiculo>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from "moment";

import Vehiculo from "./Vehiculo.vue";

export default {
  components: { Vehiculo },
  data() {
    return {
      loading: false,
      loadingMensaje: "",
      fechaI: new Date(),
      fechaF: new Date(),
      loadingItemsVehiculos: false,
      dialogNuevoVehiculo: false,
      loadingItemsVehiculo: false,
      filtrosVehiculos: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        placaVM: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        'configAutotransporte.configAutotransporte': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
      },
      objetoEditado: {},
      dialogDelete: false,
      editedIndex: -1,
    };
  },
  computed: {
    empresa() {
      return this.$store.state.usuario;
    },
    itemsVehiculo() {
      return this.$store.state.listVehiculoStore;
    },
  },
  created() {
    this.getVehiculos();
  },
  methods: {
    //VEHICULO
    nuevoVehiculo() {
      this.dialogNuevoVehiculo = true;

      if (this.$store.state.vehiculoStore != 0) {
        let obj = {
          id: 0,
          placaVM: "",
          anioModelo: 0,
          configAutotransporte: {},
          usuarioCreador: "",
          usuarioModificador: "",
        };
        this.$store.state.vehiculoStore = { ...obj };
      }
    },
    editar(data) {
      // console.log(data)
      this.$store.state.vehiculoStore = { ...data };
      this.dialogNuevoVehiculo = true;
    },
    async getVehiculos() {
      this.loadingItemsVehiculo = true;
      try {
        let response = await axios.get(
          "CatalogosCartaPorte/GetVehiculo/" + this.empresa.empresaBase
        );
        // console.log(response.data);
        this.$store.state.listVehiculoStore = response.data;
        this.loadingItemsVehiculo = false;
      } catch (error) {
        console.log(error);
        this.loadingItemsVehiculo = false;
      }
    },

    confirmDeleteProduct(data) {
      this.editedIndex = this.itemsVehiculo.indexOf(data);
      this.objetoEditado = { ...data };
      this.dialogDelete = true;
    },

    async eliminar() {
      this.loading = true;
      this.loadingMensaje = 'Procesando soliciud, espere...'
      console.log(this.objetoEditado)
      try {
        this.objetoEditado.usuarioModificador = this.empresa.nombre

        let response = await axios.put('CatalogosCartaPorte/PutVehiculoEstatus/' + this.empresa.empresaBase, this.objetoEditado)
        this.itemsVehiculo.splice(this.editedIndex, 1)

        this.dialogDelete = false;
        this.loading = false;

        this.$toast.add({ severity: 'success', detail: 'Vehículo eliminado con éxito.', life: 3000 });

      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
        this.loading = false;
      }
    },

  },
};
</script>