<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" style="height:50px; width:50px;" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text" />
                    Mercancía
                    <Button @click="guardarOactualizar()" icon="pi pi-save" style="height:50px; width:50px;"
                        class="p-button-rounded p-button-danger p-button-text" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid ">
                    <!-- MATERIAL PELIGROSO-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <Dropdown v-model="item.materialPeligroso" :options="itemsMaterialPeligroso">
                            </Dropdown>
                            <label>Material Peligroso</label>
                        </span>

                    </div>
                    <!--CLAVE MATERIAL PELIGROSO-->
                    <div v-if="item.materialPeligroso == 'SI'" class="justify-right field col-12">
                        <span class="p-float-label">
                            <AutoComplete v-model="item.cveMaterialPeligroso" :suggestions="filtroMaterialPeligroso"
                                @complete="buscarMaterialPeligroso($event)" :dropdown="true" field="materialPeligroso">
                            </AutoComplete>
                            <label>Clave Material Peligroso</label>
                        </span>
                    </div>
                    <!--BIENES TRANSPORTADOS-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <AutoComplete v-model="item.bienesTransp" :suggestions="filtroBienesTransp"
                                @complete="buscarBienesTrans($event)" :dropdown="true" field="claveProdServCP">
                            </AutoComplete>
                            <label>Bienes Transportados</label>
                        </span>
                    </div>
                    <!-- DESCRIPCION -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="item.descripcion" />
                            <label>Descripción</label>
                        </span>
                    </div>
                    <!--CLAVE UNIDAD-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <AutoComplete v-model="item.claveUnidad" :suggestions="filtroUnidad"
                                @complete="buscarUnidad($event)" :dropdown="true" field="claveUnidad">
                            </AutoComplete>
                            <label>Clave Unidad</label>
                        </span>
                    </div>
                    <!-- UNIDAD -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="item.unidad" />
                            <label>Unidad</label>
                        </span>
                    </div>
                    <!--TIPO DE EMBALAJE-->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <AutoComplete v-model="item.embalaje" :suggestions="filtroEmbalaje"
                                @complete="buscarEmbalaje($event)" :dropdown="true" field="tipoEmbalaje">
                            </AutoComplete>
                            <label>Tipo de Embalaje</label>
                        </span>
                    </div>
                    <!-- DESCRIPCION DEL EMBALAJE -->
                    <div class="justify-right field col-12">
                        <span class="p-float-label">
                            <InputText maxlength="50" v-model="item.descripEmbalaje" />
                            <label>Descripción del Embalaje</label>
                        </span>
                    </div>
                    <!-- MONEDA -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="item.moneda" :options="itemsMoneda"
                                optionLabel="moneda" />
                            <label>Moneda</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',

            filtroMaterialPeligroso: null,
            filtroBienesTransp: null,
            filtroUnidad: null,
            filtroEmbalaje: null,

            editedIndex: -1,
            itemsMaterialPeligroso: ['SI', 'NO'],
            itemsCveMaterialPeligroso: [],
            itemsBienesTransp: [],
            itemsTipoEmbalaje: [],

        }
    },
    computed: {
        item() {
            return this.$store.state.mercanciaStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        itemsClaveUnidad() {
            return this.$store.state.claveUnidadStore
        },
    },
    watch: {

    },
    created() {
        this.claveMP(),
            this.claveProduc(),
            this.GetClaveUnidad(),
            this.searchTipoEmbalaje()
        this.getMoneda();

    },
    methods: {
        buscarMaterialPeligroso(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroMaterialPeligroso = [...this.itemsCveMaterialPeligroso];
                }
                else {
                    this.filtroMaterialPeligroso = this.itemsCveMaterialPeligroso.filter((country) => {
                        return country.materialPeligroso.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        buscarBienesTrans(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroBienesTransp = [...this.itemsBienesTransp];
                }
                else {
                    this.filtroBienesTransp = this.itemsBienesTransp.filter((country) => {
                        return country.claveProdServCP.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        buscarUnidad(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroUnidad = [...this.itemsClaveUnidad];
                }
                else {
                    this.filtroUnidad = this.itemsClaveUnidad.filter((country) => {
                        return country.claveUnidad.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        buscarEmbalaje(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroEmbalaje = [...this.itemsTipoEmbalaje];
                }
                else {
                    this.filtroEmbalaje = this.itemsTipoEmbalaje.filter((country) => {
                        return country.tipoEmbalaje.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        async claveMP() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetMaterialPeligroso')
                this.itemsCveMaterialPeligroso = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async claveProduc() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetClaveProdServCP/')
                this.itemsBienesTransp = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async searchTipoEmbalaje() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetTipoEmbalaje')
                this.itemsTipoEmbalaje = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getMoneda() {
            if (this.itemsMoneda.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },
        async GetClaveUnidad() {
            if (this.itemsClaveUnidad.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },


        guardarOactualizar() {
            if (this.$store.state.mercanciaStore.id == 0) {
                this.postItem();
            } else {
                this.updateItem();
            }
        },

        async postItem() {
            this.loading = true;
            this.loadingMensaje = 'Guardando mercancía, espere...'
            this.item.usuarioCreador = this.empresa.nombre
            console.log(this.item)
            try {
                let response = await axios.post('CatalogosCartaPorte/PostMercancias/' + this.empresa.empresaBase, this.item)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Mercancía guardado con exito.', life: 3000 });

                this.item.id = response.data
                this.$store.state.listMercanciaStore.push(this.item)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async updateItem() {
            this.loading = true;
            this.loadingMensaje = 'Actualizando mercancía, espere...'
            this.item.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('CatalogosCartaPorte/UpdateMercancias/' + this.empresa.empresaBase, this.item)
                console.log(response.data)
                this.loading = false;

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listMercanciaStore.findIndex(x => x.id === this.item.id);
                Object.assign(this.$store.state.listMercanciaStore[indice], this.item)

                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Mercancía actualizado con exito.', life: 3000 });
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    },
}
</script>