<template>
    <div>
        <div v-if="loadingInicio == 0" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <ProgressSpinner style="vertical-align:middle;" />
        </div>
        <div v-if="loadingInicio != 0 && usuarioVentas.idUsuario == 0">
            <div class="p-d-flex  p-fluid grid formgrid col-12">
                <div class="field col-12">
                    <div class="p-inputgroup justify-center">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" v-model="usuario" :suggestions="filteredUsuario"
                                @complete="searchUsuario($event)" field="usuario">
                            </AutoComplete>
                            <label>Usuario</label>
                        </span>
                    </div>
                </div>
            </div>
            <div style="justify-content:center" class="p-d-flex justify-center p-fluid grid formgrid col-12">

                <div class="field col-12">
                    <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-"
                        :num-inputs="4" :should-auto-focus="true" v-model="password" :is-input-num="true"
                        input-type="password" @on-change="handleOnChange" @on-complete="handleOnComplete" type="password" />
                </div>
            </div>
            <div style="justify-content: right;" class="p-d-flex  p-fluid grid formgrid col-12">
                <div class="field col-4">
                    <Button label="Cancelar" @click="cerrarLogin()" />
                </div>
            </div>
        </div>

        <div v-if="usuarioVentas.idUsuario != 0">
            <div class="p-d-flex  p-fluid grid formgrid col-12">
                <div class="field col-12">
                    <InputNumber v-model="turno.efectivoInicial" mode="currency" currency="USD" locale="en-US" />
                </div>
                <div class="field col-6">
                </div>
                <div class="field col-6">
                    <Dropdown v-model="turno.caja" :options="cajas" optionLabel="nombreCajas" placeholder="Caja" />
                </div>
                <div class="field col-6">
                    <Button label="Cancelar" @click="cancelarRegistro()" />
                </div>
                <div class="field col-6">
                    <Button label="Registrar" @click="registrarCaja()" />
                </div>
                <span hidden>{{ turno }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from 'moment'

export default {
    data() {
        return {
            filteredUsuario: null,
            listaUsuarios: [],
            usuario: '',
            password: '',
            loadingInicio: null,
            messages: [],
            value18: 0.00,
            cajas: [
                { nombreCajas: 'Caja 01', caja: 1 },
                { nombreCajas: 'Caja 02', caja: 2 },
                { nombreCajas: 'Caja 03', caja: 3 },
                { nombreCajas: 'Caja 04', caja: 4 },
                { nombreCajas: 'Caja 05', caja: 5 },
                { nombreCajas: 'Caja 06', caja: 6 },
                { nombreCajas: 'Caja 07', caja: 7 },
                { nombreCajas: 'Caja 08', caja: 8 },
                { nombreCajas: 'Caja 09', caja: 9 },
                { nombreCajas: 'Caja 10', caja: 10 }
            ],
            caja: { nombreCajas: 'Caja 01', caja: 1 }

        }
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        usuarioVentas() {
            return this.$store.state.usuarioVentasStore;
        },
        turno() {
            return this.$store.state.turnoStore;
        }
    },
    created() {
        this.getUsuarios()
    },
    methods: {
        searchUsuario(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUsuario = [...this.listaUsuarios];
                }
                else {
                    this.filteredUsuario = this.listaUsuarios.filter((country) => {
                        return country.usuario.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        async getUsuarios() {
            console.log('aqui')

            try {
                let response = await axios.get('Usuarios/GetUsuariosVentas/' + this.empresa.empresaBase)
                this.listaUsuarios = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        async loginVentasAsync() {
            try {
                let response = await axios.post("LoginPuntoVenta/validarTurno/" + this.empresa.empresaBase + '/' + this.usuario.usuario + '/' + this.password + '/' + this.usuario.serie);
                console.log('login', response.data)
                // EN CASO DE QUE EL TURNO ESTE ABIERTO
                if (response.data.estatus == 'Abierta') {
                    this.turno.idTurno = response.data.intUsuario
                    this.cierraVentana()
                }

                // EN CASO DE QUE EL TURNO SEA NUEVO
                this.loadingInicio = response.data.login

                this.$store.state.usuarioVentasStore = { ...this.usuario }
                this.$store.state.usuarioVentasStore.folio = response.data.folioInt
                this.getVentas();

            } catch (error) {
                console.log(error);
                this.handleClearInput()
                this.usuario = ''
            }
        },

        async registrarCaja() {
            this.turno.idTurno = this.loadingInicio
            this.turno.idUsuario = this.usuario.idUsuario
            console.log(this.turno)
            try {
                let response = await axios.post("LoginPuntoVenta/actualizaTurno/" + this.empresa.empresaBase, this.turno);
                console.log(response.data)
                this.cierraVentana()
            } catch (error) {
                console.log(error);
            }
        },

        cerrarLogin() {
            let usuarioVentas = {
                idUsuario: 0,
                usuario: ''
            }


            let turnoStore = {
                idTurno: 0,
                idUsuario: 0,
                efectivoInicial: 0.00,
                caja: { nombreCajas: 'Caja 01', caja: 1 },
                efectivoFinal: 0.00
            }
            this.$store.state.usuarioVentasStore = usuarioVentas
            this.$store.state.turnoStore = turnoStore
            this.$router.push({ name: "Home" }), (this.visibleLeft = false);
        },
        async cancelarRegistro() {

            this.turno.idTurno = this.loadingInicio
            this.turno.idUsuario = this.usuario.idUsuario
            console.log(this.turno)
            try {
                let response = await axios.post("LoginPuntoVenta/cancelaTurno/" + this.empresa.empresaBase, this.turno);
                console.log(response.data)

                let usuarioVentas = {
                    idUsuario: 0,
                    usuario: ''
                }


                let turnoStore = {
                    idTurno: 0,
                    idUsuario: 0,
                    efectivoInicial: 0.00,
                    caja: { nombreCajas: 'Caja 01', caja: 1 },
                    efectivoFinal: 0.00
                }
                this.$store.state.usuarioVentasStore = usuarioVentas
                this.$store.state.turnoStore = turnoStore

            } catch (error) {
                console.log(error);
            }



        },

        handleOnComplete(value) {
            console.log("OTP completado: ", value);
            this.password = value;
            this.loginVentasAsync();
            this.loadingInicio = 0
        },

        handleOnChange(value) {
            console.log("OTP cambio: ", value);
        },

        handleClearInput() {
            this.password = 0
            this.loadingInicio = null
            // this.$refs.otpInput.clearInput();
        },

        cierraVentana() {
            this.$emit('cerrarLogin')
        },
        async getVentas() {
            this.$store.state.listVentasStore = []
            try {
                let fI = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('Ventas/GetPuntoVenta/' + this.empresa.empresaBase + '/PuntoVenta/' + fI + '/' + fF + '/' + this.empresa.rfc);
                console.log(response.data)

                let rol = this.usuarioVentas.permisos.find(element => element.permiso === 'Administrador');
                console.log(rol)

                if (rol == null) {
                    let array = response.data.filter(x => x.idTurno == this.turno.idTurno);
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))


                } else {
                    let array = response.data
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))

                }
                this.loadingVentas = false;
            } catch (error) {
                console.log(error);
                this.loadingVentas = false;
            }
        },
    }
}
</script>