<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOGO CONFIRMAR ELIMINACION -->
        <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Estas seguro de eliminar <b>{{ objetoEditado.rfc + " | " +
                    objetoEditado.nombre }}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
                <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarProveedor()" />
            </template>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- NUEVO PROVEEDOR -->
        <Dialog :visible.sync="dialogoNuevoProveedor" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <Proveedor @closeProveedor="closeDialogProveedor()"></Proveedor>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- TITULO -->
                    <div class="col-6 text-left pa-0 ma-0 pl-3">
                        <span>Proveedores</span>
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoProveedor()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getProveedores()" class="p-button-rounded" />
                    </div>
                </div>
            </template>

            <template #content>
                <DataTable :filters.sync="filtrosProveedor" class="mt-1 p-datatable-sm" :value="itemsProveedores"
                    :scrollable="true" scrollHeight="800px" scrollDirection="both" :loading="loadingProveedor"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Proveedores." :paginator="true"
                    :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosProveedor['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="editarProveedor(slotProps.data)" icon="pi pi-pencil"
                                class="p-button-rounded p-button-success mr-2" v-tooltip.bottom="'Editar Proveedor'" />
                            <Button @click="confirmDeleteProduct(slotProps.data)" icon="pi pi-trash"
                                class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar Proveedor'" />
                        </template>
                    </Column>
                    <Column field="idProveedor" header="ID Proveedor"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfc" header="RFC" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'text-align': 'center' }">
                    </Column>
                    <Column field="nombre" header="Nombre" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                    </Column>
                    <Column field="nombreComercial" header="Nombre Comercial"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                    </Column>
                    <Column field="correo" header="Correo" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="telefono" header="Teléfono"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="direccion" header="Dirección"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="contacto" header="Contacto"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                </DataTable>
            </template>

        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from 'moment'
import axios from "axios";
import Proveedor from "./Proveedor.vue";

export default {
    components: { Proveedor },

    data() {
        return {
            loading: null,
            loadingMensaje: '',
            fechaI: new Date(),
            fechaF: new Date(),
            loadingProveedor: null,
            filtrosProveedor: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            dialogoNuevoProveedor: false,
            deleteProductDialog: false,
            editedIndex: -1,
            objetoEditado: {}
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        itemsProveedores() {
            return this.$store.state.listaProveedorStore;
        },
        proveedor() {
            return this.$store.state.proveedorStore;
        }
    },
    created() {
        this.getProveedores();
    },
    methods: {
        async getProveedores() {
            this.loadingProveedor = true
            try {
                let response = await axios.get('Proveedores/GetProveedores/' + this.token.empresaBase);
                console.log(response.data)
                this.$store.state.listaProveedorStore = response.data
                this.loadingProveedor = false
            } catch (error) {
                console.log(error);
                this.loadingProveedor = false
            }
        },
        nuevoProveedor() {
            let nuevoProveedor = {
                idProveedor: 0,
                rfc: '',
                nombre: '',
                nombreComercial: '',
                correo: '',
                telefono: '',
                contacto: '',
                direccion: '',
                proveedor: ''
            }
            if (this.$store.state.proveedorStore.idProveedor != 0) {
                this.$store.state.proveedorStore = { ...nuevoProveedor }
            }
            this.dialogoNuevoProveedor = true;
        },
        confirmDeleteProduct(data) {
            this.editedIndex = this.itemsProveedores.indexOf(data)
            this.objetoEditado = data;
            this.deleteProductDialog = true;
        },
        async eliminarProveedor() {
            this.loading = true;
            this.loadingMensaje = 'Eliminando Proveedor, espere...'
            this.objetoEditado.usuarioModificador = this.token.nombre
            try {
                let response = await axios.put('Proveedores/PutProveedorEstatus/' + this.token.empresaBase, this.objetoEditado)
                console.log(response.data)
                this.itemsProveedores.splice(this.editedIndex, 1)

                this.loading = false;
                this.deleteProductDialog = false;

                this.$toast.add({ severity: 'success', detail: 'Proveedor eliminado con éxito.', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
                this.loading = false;
            }
        },
        editarProveedor(data) {
            this.$store.state.proveedorStore = { ...data };
            this.dialogoNuevoProveedor = true
        },
        closeDialogProveedor() {
            this.dialogoNuevoProveedor = false;
        },
    },
}
</script>