<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- NUEVO SOLICITANTE -->
        <Dialog :visible.sync="dialogoNuevoSolicitante" :closeOnEscape="true" :closable="false"
            :containerStyle="{ width: '1100px' }" position="top" :modal="true">
            <SolicitanteVw @closeSolicitante="closeDialogSolicitante()"></SolicitanteVw>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <!-- DIALOG PARA ELIMINAR SOLICITANTE -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDelete = false" />
                Eliminar
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                    @click="confirmDelete()" />
            </div>
            <hr size="7" color="#ED6421" />
            <br />
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="editedSolicitante">Esta seguro de borrar al solicitante
                    <b>{{ editedSolicitante.nombre }} | </b>
                    <b>{{ editedSolicitante.correo }}</b>?</span>
            </div>
        </Dialog>
        <Card>
            <template #title>
                <div id="pdfprueba" class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Solicitantes
                    </div>
                    <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-6 md:col-6">
                        <Button icon="pi pi-plus" @click="nuevoSolicitante()" class="mr-3 p-button-rounded" />
                        <Button icon="pi pi-undo" @click="getSolicitante()" class="p-button-rounded" />
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable class="p-datatable-sm" :filters.sync="filtrosSolicitante" removableSort showGridlines
                    ref="clientes" :value="itemsSolicitante" responsiveLayout="scroll" :paginator="true" :rows="10"
                    :globalFilterFields="[
                        'nombre',
                        'correoContacto',
                    ]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} datos." :loading="loadingItems">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosSolicitante['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column sortable field="nombre" header="Nombre del solicitante"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.nombre }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>
                    <Column sortable field="correo" header="Correo Solicitante"
                        :bodyStyle="{ 'text-align': 'left', overflow: 'visible' }">
                        <template #body="{ data }">
                            {{ data.correo }}
                        </template>
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" />
                        </template>
                    </Column>

                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editSolicitante(slotProps.data)" v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="openDialogDelete(slotProps.data)" v-tooltip.bottom="'Eliminar'" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
</div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import SolicitanteVw from "./SolicitanteVw.vue";
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            loadingMensaje: "",
            loading: false,
            // itemsProveedores: [],
            filtrosSolicitante: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                correoContacto: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                nombre: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            },
            dialogoNuevoSolicitante: false,
            loadingItems: false,
            dialogDelete: false,
            editedIndex: -1,
            editedSolicitante: {},
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },
        itemsSolicitante() {
            return this.$store.state.listSolicitanteVwStore;
        },
    },
    created() {
        this.getSolicitante();
    },
    methods: {
        async getSolicitante() {
            this.loading = true;
            this.loadingMensaje = 'Consultando Solicitantes';
            try {
                let response = await axios.get("Addendas/GetSolicitanteVwPsv/" + this.empresa.empresaBase);
                console.log(response.data);
                this.$store.state.listSolicitanteVwStore = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },

        nuevoSolicitante() {
            let nuevoSolicitante = {
                idSolicitante: 0,
                correoContacto: '',
                nombre: '',
            }
            if (this.$store.state.solicitanteVwStore.idSolicitante == -1) {
                this.$store.state.solicitanteVwStore = { ...nuevoSolicitante }
            }
            this.dialogoNuevoSolicitante = true;
        },

        editSolicitante(item) {
            // console.log(item)
            this.$store.state.solicitanteVwStore = { ...item };
            this.dialogoNuevoSolicitante = true;
        },

        openDialogDelete(item) {
            this.editedIndex = this.itemsSolicitante.indexOf(item);
            this.editedSolicitante = { ...item };
            this.dialogDelete = true;
        },

        async confirmDelete() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            try {
                this.editedSolicitante.usuarioModificador = this.empresa.nombre;
                let response = await axios.put(
                    "Addendas/PutSolicitanteVwEstatus/" + this.empresa.empresaBase,
                    this.editedSolicitante
                );
                this.itemsSolicitante.splice(this.editedIndex, 1);
                this.dialogDelete = false;
                this.loading = false;
                this.$toast.add({
                    severity: "success",
                    detail: "Solicitante eliminado con éxito.",
                    life: 3000,
                });
            } catch (error) {
                // console.log(error)
                this.$toast.add({
                    severity: "error",
                    detail: "Error al eliminar, intente nuevamente.",
                    life: 3000,
                });
                this.loading = false;
            }
        },

        closeDialogSolicitante() {
            this.dialogoNuevoSolicitante = false;
        },
    },
    components: { SolicitanteVw },
};
</script>
