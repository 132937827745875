<template>
    <div>
        <ScrollTop />
        <ScrollPanel style="height: 800px">
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <div
                    class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                    {{ loadingMensaje }}</div>
            </Dialog>

            <!-- DIALOG DEL PDF -->
            <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
                <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
            </Dialog>

            <!-- CONTENIDO -->
            <Card>
                <!-- TITULO CARD -->
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cierraVentana()" />
                        </div>
                        <div class="text-center field col-4">
                            Cotización
                        </div>
                        <div class="text-right field col-4">
                            <template v-if="ventaCotizacion.idVenta === 0">
                                <Button icon="pi pi-save" @click="postVentaCotizacion()"
                                    class="p-button-rounded p-button-success p-button-outlined" />
                            </template>
                            <template v-if="ventaCotizacion.idVenta != 0">
                                <Button icon="pi pi-save" @click="editVentaCotizacion()"
                                    class="p-button-rounded p-button-warning p-button-outlined" />
                            </template>
                            <Button icon="pi pi-file-pdf"
                                class="ml-3 mr-1 p-button-rounded p-button-danger p-button-outlined" @click="vistaPrevia" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <TabView>
                        <TabPanel header="Datos generales">
                            <General></General>
                        </TabPanel>
                        <TabPanel header="Conceptos">
                            <Conceptos></Conceptos>
                        </TabPanel>
                    </TabView>
                </template>
                <template #footer>
                    <div style="justify-content:right" class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber v-model="$store.state.ventaCotizacionStore.subTotal" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>SubTotal</label>
                            </span>
                        </div>
                        <div class="field col-12 md:col-3">
                            <span class="p-float-label">
                                <InputNumber class="color" v-model="$store.state.ventaCotizacionStore.total" mode="decimal"
                                    :minFractionDigits="2" locale="en-US" />
                                <label>Total</label>
                            </span>
                        </div>
                    </div>
                </template>
            </Card>
            <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </ScrollPanel>
    </div>
</template>
<script>
import axios from 'axios'
import General from './VentaCotizacionGeneral';
import Conceptos from './VentaCotizacionConcepto'
import ComprobantePdf from "../Pdf/VentaCotizacionPdf";

export default {
    components: {
        General,
        Conceptos,
        ComprobantePdf
    },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogPdf: false,
            datosEmpresa: null,
        }
    },
    computed: {
        ventaCotizacion() {
            return this.$store.state.ventaCotizacionStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.Inicializa();
    },
    methods: {
        //INICIALES
        async Inicializa() {
            this.loading = true;
            await this.GetEmpresa();
            await this.GetSerie();
            await this.GetClientes();
            await this.GetMoneda();
            await this.GetProyectos();
            await this.GetConceptos();
            await this.GetObjImpuestos();
            await this.GetTraslados();
            await this.GetRetenciones();
            this.loading = false;
        },

        async GetEmpresa() {
            this.loadingMensaje = 'Consultando datos de la empresa'
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.datosEmpresa = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetSerie() {
            this.loadingMensaje = 'Consultando serie'
            try {
                let response = await axios.get("Ventas/GetVentasCotizacionSeries/" + this.token.empresaBase);
                this.$store.state.listVentaCotizacionSerieStore = response.data;
                if (!this.ventaCotizacion.serie) {
                    console.log('Vamos por la serie')
                }
            } catch (error) {
                console.log(error)
            }
        },
        async GetClientes() {
            this.loadingMensaje = 'Consultanod Clientes';
            try {
                let response = await axios.get("Clientes/GetClientes/" + this.token.empresaBase);
                this.$store.state.listClienteStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async GetMoneda() {
            this.loadingMensaje = 'Consultando Monedas';
            if (this.$store.state.monedaStore.length != 0) {
                return
            }
            try {
                let result = await axios.get('CatalogosSat/GetMoneda/');
                this.$store.state.monedaStore = result.data;
            } catch (error) {

            }
        },
        async GetProyectos() {
            this.loadingMensaje = 'Consultando Proyectos';
            try {
                let response = await axios.get('Proyectos/GetProyectos/' + this.token.empresaBase)
                this.$store.state.listProyectosStore = response.data
            } catch (error) {
                this.loadingItemsProyectos = false;
            }
        },
        async GetConceptos() {
            try {
                this.loadingMensaje = 'Consultando Conceptos';
                let response = await axios.get('Conceptos/GetConceptos/' + this.token.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetObjImpuestos() {
            this.loadingMensaje = 'Consultando Objeto de impuestos';
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetTraslados() {
            this.loadingMensaje = 'Consultando Impuestos Trasladados';
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async GetRetenciones() {
            this.loadingMensaje = 'Consultando Impuestos Retenidos';
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async postVentaCotizacion() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'

                let objCotizacion = { ...this.ventaCotizacion }
                objCotizacion.empresa = this.datosEmpresa
                objCotizacion.rfc = objCotizacion.cliente.rfc
                objCotizacion.nombre = objCotizacion.cliente.nombre

                objCotizacion.usuarioCreador = this.token.nombre

                let response = await axios.post('Ventas/PostVentaCotizacion/' + this.token.empresaBase, objCotizacion)
                console.log(response.data)

                objCotizacion.idVenta = response.data.id;
                objCotizacion.folio = response.data.folio;
                this.$store.state.listVentaCotizacionStore.push(objCotizacion)

                let array = this.$store.state.listVentaCotizacionStore;
                this.$store.state.listVentaCotizacionStore = array.sort(((a, b) => b.folio - a.folio))
                this.$store.state.ventaCotizacionStore.idVenta = -1;

                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Cotización guardado con éxito', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },

        async editVentaCotizacion() {
            try {
                this.loading = true;
                this.loadingMensaje = 'Guardando espere...'
                let objCotizacion = { ...this.ventaCotizacion }
                objCotizacion.empresa = this.datosEmpresa
                objCotizacion.rfc = objCotizacion.cliente.rfc
                objCotizacion.nombre = objCotizacion.cliente.nombre

                objCotizacion.usuarioModificador = this.token.nombre

                let response = await axios.post('Ventas/UpdateVentasCotizacion/' + this.token.empresaBase, objCotizacion)
                console.log(response.data)

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listVentaCotizacionStore.findIndex(x => x.idVenta === objCotizacion.idVenta);
                Object.assign(this.$store.state.listVentaCotizacionStore[indice], objCotizacion)
                this.$store.state.ventaCotizacionStore.idVenta = -1;
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Cotización actualizada con éxito', life: 3000 });
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al actualizar, intente nuevamente', life: 3000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeVentaCotizacion')
            this.$store.state.ventaCotizacionStore.idVenta = -1;
        },

        vistaPrevia() {
            this.dialogPdf = true;
            console.log(this.$store.state.ventaCotizacionStore)
        },

        closeDialogPdf() {
            this.dialogPdf = false

            if (this.ventaCotizacion.folio != '') {
                this.$emit('closeVentaCotizacion')
            }
        },
    },
}
</script>