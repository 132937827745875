<template>
    <div>
        <!-- TIKCET A IMPRIMIR -->
        <div hidden>
            <div class="ticket" id="ticket">
                <!-- CABECERA DEL COMPROBANTE -->
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <div class="col-12" style="text-align: center;">
                        <a style="text-align: center;" href="https://contago.com.mx/" target="_blank">
                            <img :src="empresa.logo.base64" width="150">
                        </a>
                    </div>
                    <div class="col-12 text-center">
                        <font size="3">
                            <div><strong>Ticket</strong></div>
                        </font>
                        <h4>{{ venta.serie }} {{ venta.folio }}</h4>
                        <div>{{ formatDate(venta.fecha) }}</div>
                        <div><b>Lugar de Expedición: </b>{{ empresa.domicilioFiscal }}</div>
                    </div>
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>{{ empresa.rfc }}</div>
                        </font>
                        <font size="2">
                            <div>{{ empresa.nombre }}</div>
                        </font>
                    </div>
                    <!-- DATOS DEL CLIENTE -->
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>Datos del cliente:</div>
                        </font>
                        <font size="2">
                            <div><strong>{{ venta.cliente.cliente }}</strong></div>
                        </font>
                    </div>
                    <!-- CONCEPTOS -->
                    <DataTable style="width:100%" class="mt-1 p-datatable-sm" :value="venta.conceptos"
                        responsiveLayout="scroll">
                        <Column field="descripcion" header="Descripción" :styles="{ 'font-size': '70%' }">
                            <template #body="{ data }">
                                <div>{{ data.noIdentificacion }}</div>
                                <h4>{{ data.descripcion }}</h4>
                            </template>
                        </Column>
                        <Column field="cantidad" header="Cantidad" :styles="{ 'font-size': '70%' }">
                        </Column>
                        <Column field="precio" header="Precio">
                            <template #body="{ data }" :styles="{ 'font-size': '70%' }">
                                <span>{{ formatCurrency(data.precio) }}</span>
                            </template>
                        </Column>
                    </DataTable>
                    <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12 mr-0 pr-0">
                        <div class="col-6 pt-3">
                            <font size="2">
                                <div>Pago con: {{ formatCurrency(venta.pagoCon) }}</div>
                            </font>
                            <font size="2">
                                <div>Cambio: {{ formatCurrency(venta.cambioTotal) }}</div>
                            </font>
                        </div>
                        <div class="col-6 mr-0 pr-0">
                            <DataTable :value="itemsImpuestos" class="ocult p-datatable-sm" responsiveLayout="scroll">
                                <Column field="impuesto" header=""
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '70%' }">
                                </Column>
                                <Column field="importe" header=""
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '70%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.importe) }}</span>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <div class="col-12 text-left">
                            <p style="font-size: 14px; text-align: center;"> {{
                                importeLetra }}
                            </p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">{{ venta.notas }}</p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 10px; text-align: center;">Para emitir su factura, visite la pagina
                                web:<br>
                                <b href="https://contago.com.mx/" target="_blank">www.contago.com.mx</b>
                            </p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">¡Gracias por su Compra!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DIALOGO PARA VER LAS FORMAS DE PAGO DE LA VENTA -->
        <Dialog :header="numVenta" :visible.sync="dialogoFormasPago" :style="{ width: '75vw' }" :maximizable="true"
            :modal="true" :contentStyle="{ height: '300px' }">
            <DataTable :value="listaFormasPago" :scrollable="true" scrollHeight="flex">
                <Column field="formaPago.formaPago" header="Forma de Pago" :styles="{ 'min-width': '200px' }"></Column>
                <Column field="referencia" header="Referencia" :styles="{ 'min-width': '200px' }"></Column>
                <Column field="monto" header="Monto" :styles="{ 'min-width': '200px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.monto) }}</span>
                    </template>
                </Column>
            </DataTable>
        </Dialog>
        <!-- PDF -->
        <Dialog :visible.sync="dialogTicket" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <ticket-pdf @cierraDialogPdf="cerrarPdf()"></ticket-pdf>
        </Dialog>

        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- TITULO -->
                    <div class="col-7 text-left pa-0 ma-0 pl-3">
                        <span>Historial de Ventas </span>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-5 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getVentas()" />
                                    <Button icon="pi pi-shopping-cart" @click="irPuntoVenta()"
                                        class="p-button-primary pl-4 pr-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- BOTONES -->
                    <!-- <div class="col-1 text-right">
                            <Button icon="pi pi-shopping-cart" @click="irPuntoVenta()" class="mx-2 p-button-rounded" />
                            <Button icon="pi pi-search" @click="getVentas()" class="p-button-rounded" />
                        </div> -->
                </div>
            </template>

            <template #content>
                <DataTable :filters.sync="filtrosVentas" class="mt-1 p-datatable-sm" :value="itemsVentas" :scrollable="true"
                    scrollHeight="800px" scrollDirection="both" :loading="loadingVentas"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Ventas." :paginator="true"
                    :rows="10">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosVentas['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <!-- <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100"
                                v-tooltip.bottom="'Ver Venta'" @click="verVenta(slotProps.data)" /> -->
                            <Button icon="pi pi-print" class="p-button-rounded p-button-primary"
                                v-tooltip.bottom="'Imprimir Ticket'" @click="imprimirTicket(slotProps.data)" />
                        </template>
                    </Column>

                    <Column field="serie" header="Serie"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="folio" header="Folio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
                        <template #body="slotProps">
                            <span :class="'customer-badge status-' + slotProps.data.estatus">{{
                                slotProps.data.folio
                            }}</span>
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfc" header="RFC" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombre" header="Nombre" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'background-color': '#effaf1', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column field="pagoCon" header="Pago Con"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'background-color': '#effaf1', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.pagoCon) }}</span>
                        </template>
                    </Column>
                    <Column field="cambioTotal" header="Total de Cambio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'background-color': '#effaf1', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.cambioTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="formaPago.formaPago" header="Forma de Pago"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ data.formaPago.formaPago }}</span>
                        </template>
                    </Column>
                    <!-- <Column header="Formas de Pago" bodyClass="text-center"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-dollar" class="p-button-rounded" style="background:green"
                                v-tooltip.bottom="'Ver Formas de Pago'" @click="verFormasPago(slotProps.data)" />
                        </template>
                    </Column> -->
                    <Column field="idTurno" header="No. Turno"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '60px', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                    </Column>
                    <Column field="usuarioVentas" header="Vendedor"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import TicketPdf from "../Pdf/TicketPDF.vue";

import moment from 'moment'
import axios from "axios";
import print from 'print-js'
import html2pdf from "html2pdf.js";
export default {
    components: { TicketPdf },

    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            loadingVentas: null,
            filtrosVentas: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                serie: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                folio: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                rfc: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                nombre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            dialogTicket: false,
            listaFormasPago: [],
            numVenta: '',
            dialogoFormasPago: false
        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        itemsVentas() {
            return this.$store.state.listVentasStore;
        },
        usuarioVentas() {
            return this.$store.state.usuarioVentasStore;
        },
        turno() {
            return this.$store.state.turnoStore;
        },
        venta() {
            return this.$store.state.ventaStore
        },
        importeLetra() {
            const func = require('../../plugins/numeroALetras.js')
            let a = func.numeroALetras(this.$store.state.ventaStore.total, this.$store.state.ventaStore.moneda.clave);
            return a;
        },
        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.ventaStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.ventaStore.impuestos.traslados) {
                console.log(x)
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                if (x.tipoFactor === 'Exento') {
                    nombre = nombre + ' Exento'
                } else {
                    nombre = nombre + '(' + tasa + ')%'
                }
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.ventaStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.ventaStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
    },
    created() {

    },
    methods: {
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        verVenta(data) {
            console.log(data)
            this.dialogTicket = true;
            this.$store.state.ventaStore = { ...data }
        },
        async imprimirTicket(data) {
            this.$store.state.ventaStore = { ...data }
            var a = await this.convertBase64();
            // var printWindow = window.open(a); 
            // printWindow.print();
            print({
                printable: a,
                type: 'pdf',
                base64: true,
            })
            console.log(data)
        },
        irPuntoVenta() {
            this.cancelarVenta()
            this.$router.push({ name: "PuntoVenta" });
        },
        async getVentas() {
            console.log(this.usuarioVentas)
            this.loadingVentas = true;
            this.$store.state.listVentasStore = []
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(this.fechaF).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('Ventas/GetPuntoVenta/' + this.token.empresaBase + '/PuntoVenta/' + fI + '/' + fF + '/' + this.empresa.rfc);
                console.log(response.data)

                let rol = this.usuarioVentas.permisos.find(element => element.permiso === 'Administrador');
                console.log(rol)

                if (rol == null) {
                    let array = response.data.filter(x => x.idTurno == this.turno.idTurno);
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))
                } else {
                    let array = response.data
                    this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))
                }
                this.loadingVentas = false;
            } catch (error) {
                console.log(error);
                this.loadingVentas = false;
            }
        },
        async convertBase64() {
            var opt = {
                margin: .1,
                filename: 'Ticket' + this.venta.serie + "_" + this.venta.folio + ".pdf",
                // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },

                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('ticket')).set(opt).toPdf().get('pdf').then((pdf) => {
            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return file
        },

        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy hh:mm:ss a");
            return fechaR;
        },
        cerrarPdf() {
            this.dialogTicket = false
            this.cancelarVenta();
        },
        cancelarVenta() {
            let ventaDefaul = {
                idComprobante: 0,
                folioFiscal: '',
                serie: '',
                folio: '',
                fecha: null,
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                condicionesPago: '',
                subTotal: 0,
                descuento: 0,
                moneda: {
                    id: 100,
                    clave: 'MXN',
                    descripcion: 'Peso Mexicano',
                    moneda: 'MXN | Peso Mexicano'
                },
                tipoCambio: 0,
                total: 0,
                tipoComprobante: 'I',
                tipoComprobanteInterno: 'PuntoVenta',
                metodoPago: { id: 1, clave: "PUE", metodoPago: "PUE | Pago en una sola exhibición", descripcion: "Pago en una sola exhibición" },
                empresa: {},
                cliente: {
                    rfc: 'XAXX010101000',
                    nombre: 'PUBLICO EN GENERAL',
                    cliente: 'XAXX010101000 | PUBLICO EN GENERAL',
                    domicilioFiscal: '',
                    usoCfdi: {
                        id: 22,
                        clave: "S01",
                        moral: "Sí",
                        fisica: "Sí",
                        usoCfdi: "S01 | Sin efectos fiscales.",
                        descripcion: "Sin efectos fiscales."
                    },
                    regimenFiscal: {
                        id: 11,
                        clave: "616",
                        moral: "Sí",
                        fisica: "No",
                        descripcion: "Sin obligaciones fiscales",
                        regimenFiscal: "616 | Sin obligaciones fiscales"
                    },
                    correos: []
                },
                rfc: '',
                nombre: '',
                atn: '',
                notas: '',
                estatus: 'Sin Timbrar',
                usuarioCreador: '',
                usuarioModificador: '',
                usuarioAutoriza: '',
                conceptos: [],
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                idTurno: 0,
                usuarioVentas: '',
                pagoCon: 0.00,
                cambioTotal: 0.00,
                formasPago: [],
            }
            this.$store.state.ventaStore = { ...ventaDefaul }
        },
        verFormasPago(data) {
            this.listaFormasPago = []
            this.listaFormasPago = data.formasPago
            this.numVenta = 'Venta' + data.serie + ' ' + data.folio
            this.dialogoFormasPago = true
        }
    }
}
</script>
<style>
.status-Vigente {
    background-color: #4CAF50;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-VigenteSinRespuesta {
    background-color: #4CAF50;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-VigenteEnProceso {
    background-color: #FF6F00;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-Sin {
    background-color: #2196F3;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-Cancelado {
    background-color: #B71C1C;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}

.status-CanceladoCanceladoSinAceptacion {
    background-color: #B71C1C;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}
</style>