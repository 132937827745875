<template>
    <div>
        <!-- DIALOGO PARA ERRORES
        <div class="p-d-flex">
            <Toast />
        </div> -->
        <!-- NUEVO USUARIO -->
        <Dialog :visible.sync="dialogoWebCam" header=" " :containerStyle="{ width: '1800px' }" :closeOnEscape="true"
            :closable="true">
            <WebCam @closeWebCam="closeDialogWebCam()"></WebCam>
        </Dialog>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button @click="cierraVentana()" icon="pi pi-times"
                        style="height:45px; width:45px; background:#F46722; color: white;"
                        class="p-button-rounded p-button-text" />
                    Usuario
                    <Button @click="guardarOactualizar()" icon="pi pi-save"
                        style="height:45px; width:45px; background:#F46722; color: white;" class="p-button-rounded" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-d-flex p-fluid grid formgrid">
                    <div class="p-fluid grid field col-6">
                        <div class="field col-12">
                            <h2>Foto de Perfil</h2>
                            <ImagePreview :src="usuario.foto.base64" width="200" preview />
                            <div class="text-right">
                                <Button icon="pi pi-camera" @click="abrirCamara()"
                                    style="height:45px; width:45px;  background:#F46722; color: white;"
                                    class="p-button-rounded mr-2" />
                            </div>
                        </div>
                        <div class="field col-12">
                            <h3>Seleccione la imagen</h3>
                            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadImage" accept="image/*"
                                :maxFileSize="5242880">
                                <template #empty>
                                    <p>Arrastre y suelte para cargar</p>
                                </template>
                            </FileUpload>
                        </div>
                    </div>

                    <div class="p-fluid grid field col-6">
                        <!-- RFC-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.rfc" maxlength="13" style="text-align: left" type="text" />
                                <label>RFC</label>
                            </span>
                        </div>
                        <!-- CURP-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.curp" maxlength="18" style="text-align: left" type="text" />
                                <label>CURP</label>
                            </span>
                        </div>
                        <!-- NOMBRE-->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <InputText v-model="usuario.nombre" style="text-align: left" type="text" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <!--USUARIO-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.usuario" style="text-align: left" type="text" />
                                <label>Usuario</label>
                            </span>
                        </div>
                        <!-- PIN-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <!-- <InputText v-model="usuario.pin" style="text-align: left" type="text" /> -->
                                <InputMask mask="9999" v-model="usuario.pin" />
                                <label>PIN</label>
                            </span>
                        </div>
                        <!-- PUESTO-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.puesto" style="text-align: left" type="text" />
                                <label>Puesto</label>
                            </span>
                        </div>
                        <!-- DEPARTAMENTO-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.departamento" style="text-align: left" type="text" />
                                <label>Departamento</label>
                            </span>
                        </div>
                        <!-- PERMISOS -->
                        <div class="justify-right field col-12">
                            <span class="p-float-label">
                                <MultiSelect v-model="usuario.permisos" :options="itemsPermisos" optionLabel="permiso"
                                    display="chip" />
                                <label>Permisos</label>
                            </span>
                        </div>
                        <!-- ALMACEN -->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <Dropdown v-model="usuario.almacen" :options="$store.state.listaAlmacenesStore"
                                    optionLabel="descripcion" />
                                <label>Almacén</label>
                            </span>
                        </div>
                        <!-- SERIE-->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="usuario.serie" style="text-align: left" type="text" />
                                <label>Serie</label>
                            </span>
                        </div>
                    </div>
                    <!-- LISTA DE CORREOS -->
                    <div class="p-fluid grid field col-6">
                        <div class="justify-right field col-12">
                            <h3>Correos</h3>
                            <hr size=7 color="#ED6421">
                        </div>
                        <!-- NOMBRE DEL CORREO -->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="nombreCorreo" style="text-align: left" type="text" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <!-- CORREO -->
                        <div class="justify-right field col-6">
                            <div class="p-inputgroup">
                                <InputText v-model="correo" placeholder="Correo" />
                                <Button icon="pi pi-plus" @click="pushCorreo()" class="p-button-warning" />
                            </div>
                        </div>
                        <!-- TABLA DE CORREOS -->
                        <div class="justify-right field col-12">
                            <DataTable :value="usuario.correos" responsiveLayout="scroll">
                                <Column field="nombre" header="Nombre"></Column>
                                <Column field="correo" header="Correo"></Column>
                                <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" :exportable="false"
                                    :styles="{ 'min-width': '1rem' }">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" style="width:35px; height:35px"
                                            class="p-button-rounded p-button-danger"
                                            @click="eliminarCorreo(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <!-- LISTA DE TELEFONOS -->
                    <div class="p-fluid grid field col-6">
                        <div class="justify-right field col-12">
                            <h3>Teléfonos</h3>
                            <hr size=7 color="#ED6421">
                        </div>
                        <!-- REGION -->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="region" style="text-align: left" type="text" />
                                <label>Región</label>
                            </span>
                        </div>
                        <!-- TELEFONO -->
                        <div class="justify-right field col-6">
                            <div class="p-inputgroup">
                                <InputText v-model="telefono" placeholder="Teléfono" />
                                <Button @click="pushTelefono()" icon="pi pi-plus" class="p-button-warning" />
                            </div>
                        </div>
                        <!-- TABLA DE TELEFONOS -->
                        <div class="justify-right field col-12">
                            <DataTable :value="usuario.telefonos" responsiveLayout="scroll">
                                <Column field="region" header="Region"></Column>
                                <Column field="numero" header="Teléfono"></Column>
                                <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" :exportable="false"
                                    :styles="{ 'min-width': '1rem' }">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" style="width:35px; height:35px"
                                            class="p-button-rounded p-button-danger"
                                            @click="eliminarTelefono(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import WebCam from './WebCam.vue';
export default {
    components: {
        WebCam
    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',

            dialogoWebCam: false,

            correo: '',
            nombreCorreo: '',

            region: '',
            telefono: '',

            itemsPermisos: [{ permiso: 'Administrador' }, { permiso: 'Compras' }, { permiso: 'Ventas' }, { permiso: 'Todos' }, { permiso: 'Autorizar' }],

            editedIndexCorreo: -1,
            editedIndexTelefono: -1,
        }
    },
    computed: {
        usuario() {
            return this.$store.state.usuarioInternosStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    watch: {
    },
    created() {
        this.getAlmacenes();
    },
    methods: {
        guardarOactualizar() {
            console.log(this.usuario)
            if (this.usuario.idUsuario == 0) {
                this.guardarUsuario();
            } else {
                this.putUsuario();
            }
        },
        async guardarUsuario() {
            console.log(this.usuario)
            this.loading = true;
            this.loadingMensaje = 'Guardando Usuario, espere...'
            this.usuario.usuarioCreador = this.usuario.nombre
            try {
                let response = await axios.post('Usuarios/GuardarUsuario/' + this.empresa.empresaBase, this.usuario)
                console.log(response.data)
                this.loading = false;

                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Configuración guardada.', life: 3000 });
                this.usuario.idUsuario = response.data
                this.$store.state.listaUsuariosInternosStore.push(this.usuario)
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },
        async putUsuario() {
            this.loading = true;
            this.loadingMensaje = 'Guardando Usuario, espere...'

            this.usuario.usuarioModificador = this.empresa.nombre
            try {
                let response = await axios.put('Usuarios/UpdateUsuario/' + this.empresa.empresaBase, this.usuario)
                this.loading = false;

                //BUSCAMOS EL INDICE
                let indice = this.$store.state.listaUsuariosInternosStore.findIndex(x => x.idUsuario === this.usuario.idUsuario);
                Object.assign(this.$store.state.listaUsuariosInternosStore[indice], this.usuario)

                this.$toast.add({ severity: 'success', detail: 'Usuario actualizado con éxito.', life: 3000 });

                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al guardar, verifique su informacion.', life: 3000 });
                this.loading = false;
            }
        },
        closeDialogWebCam() {
            this.dialogoWebCam = false
        },
        abrirCamara() {
            this.dialogoWebCam = true
        },
        pushCorreo() {
            let objeto = {
                nombre: this.nombreCorreo,
                correo: this.correo
            }
            this.usuario.correos.push(objeto)
        },
        pushTelefono() {
            let objeto = {
                region: this.region,
                numero: this.telefono
            }
            this.usuario.telefonos.push(objeto)
            console.log(this.usuario.telefonos)
        },
        eliminarCorreo(data) {
            this.editedIndexCorreo = this.usuario.correos.indexOf(data)
            this.usuario.correos.splice(this.editedIndexCorreo, 1)
        },
        eliminarTelefono(data) {
            this.editedIndexTelefono = this.usuario.telefonos.indexOf(data)
            this.usuario.telefonos.splice(this.editedIndexTelefonos, 1)
        },
        async getAlmacenes() {
            try {
                let response = await axios.get('Almacenes/GetAlmacenes/' + this.empresa.empresaBase)
                this.$store.state.listaAlmacenesStore = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
        },

        async uploadImage(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.usuarioInternosStore.foto.base64 = result;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
        cierraVentana() {
            this.$emit('closeUsuario')
        },
    },
}
</script>
