<template>
    <div>
        <DataTable hidden ref="dt" :value="plantillaItems" responsiveLayout="scroll">
        </DataTable>
        <!-- CARGAR EXCEL -->
        <Dialog :visible.sync="dialogExcel" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraExcel()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR EXCEL</h3>
                </div>
            </div>
            <hr size=7 color="#ED6421">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarExcel" accept=".xlsx,.csv"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
            <p style="cursor: pointer; border-bottom: 2px solid #88b2ff;" @click="exportCSV($event)">Descargar Plantilla</p>
        </Dialog>

        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- MEDIDA -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <AutoComplete v-model="concepto.descripcion" :suggestions="filteredConcepto"
                        @complete="searchConcepto($event)" />
                    <label>Descripción</label>
                </span>
            </div>
            <div class="justify-right field col-6">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.medida" />
                    <label>Medida</label>
                </span>
            </div>
            <div class="justify-right field col-6">
                <span class="p-float-label">
                    <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- DETALLE -->
            <div class="justify-right field col-12 mb-0">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputText style="text-align: left" type="text" v-model="concepto.detalle" />
                        <label>Detalle</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                </div>
            </div>
        </div>
        <div class="field col-12 text-center mb-0 pb-0">
            <h1 class="mb-0 pb-0" style="background: #FF9100;color: white;font-size: 14px;font-family: arial;">
                CONCEPTOS
            </h1>
        </div>
        <DataTable class="mt-0" :filters.sync="filtrosConceptos" :value="pedido.conceptos" :scrollable="true"
            scrollHeight="300px" scrollDirection="both">
            <template #header>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtrosConceptos['global'].value" placeholder="Buscar" />
                    </span>
                    <Button label="Importar Excel" icon="pi pi-upload" class="p-button-help" @click="abrirExcel()" />
                </div>
            </template>
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '10px' }">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="editConcepto(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="deleteConcepto(slotProps.data)" />
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '20px' }">
            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
            </Column>
            <Column field="medida" header="Medida" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                <template #body="{ data }">
                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                </template>
            </Column>
            <Column field="detalle" header="Detalle" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import readXlsFile from 'read-excel-file'
import * as XLSX from 'xlsx/xlsx.js'

export default {
    components: {
    },

    data() {
        return {
            filteredConcepto: null,
            editedIndex: -1,
            concepto: {
                item: 0,
                descripcion: '',
                medida: '',
                cantidad: 0,
                valorUnitario: 0,
                precio: 0,
                importe: 0,
                detalle: '',
                objImp: {
                    objImp: ''
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                total: 0
            },
            conceptoDefault: {
                item: 0,
                descripcion: '',
                medida: '',
                cantidad: 0,
                valorUnitario: 0,
                precio: 0,
                importe: 0,
                detalle: '',
                objImp: {
                    objImp: ''
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                total: 0
            },
            filtrosConceptos: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            dialogExcel: false,
            plantillaItems: [{ Descripcion: '', UnidadMedida: '', Cantidad: 0, Detalle: '' }]
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        listaConceptos() {
            let listaDescripcion = []
            for (let i of this.$store.state.listaConceptosStore) {
                listaDescripcion.push(i.descripcion)
            }
            return listaDescripcion
        },
        pedido() {
            return this.$store.state.comprasPedidoStore
        }
    },
    created() {
    },
    methods: {
        pushConcepto() {
            console.log(this.concepto)

            if (this.editedIndex === -1) {
                this.pedido.conceptos.push(this.concepto);
            } else {
                Object.assign(this.pedido.conceptos[this.editedIndex], this.concepto)
            }
            this.sumatoria();

            this.editedIndex = -1;
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);

        },
        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        sumatoria() {
            let indice = 1;
            for (let x of this.pedido.conceptos) {
                x.item = indice;
                indice++;
            }
        },
        editConcepto(item) {
            this.editedIndex = this.pedido.conceptos.indexOf(item);
            this.concepto = { ...item }
        },
        deleteConcepto(item) {
            let indice = this.pedido.conceptos.indexOf(item);
            this.pedido.conceptos.splice(indice, 1);
            this.sumatoria();
        },
        abrirExcel() {
            this.dialogExcel = true;
        },

        cerraExcel() {
            this.dialogExcel = false;
        },
        cargarExcel(event) {
            this.pedido.conceptos = [];
            let itemsExcel = []
            const file = event.files[0];
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                for (var cont = 1; cont < num; cont++) {
                    try {
                        this.concepto.descripcion = rows[cont]['0']
                        this.concepto.medida = rows[cont]['1']
                        this.concepto.cantidad = rows[cont]['2']
                        this.concepto.detalle = rows[cont]['3']
                        this.concepto.objImp = {
                            objImp: ''
                        }
                        this.pushConcepto();
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },
        exportCSV() {
            console.log(this.$refs.dt)
            const rows = this.$refs.dt.value; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, 'PlantillaPedidosConceptos.xlsx');
        }
    },
}
</script>