<template>
    <div>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>

        <!-- DIALOG DEL CORREO -->
        <Dialog :visible.sync="dialogCorreo" :style="{ width: '800px' }" :closable="false">
            <Card>
                <template #header>
                    <div class="flex justify-content-between flex-wrap pb-2">
                        <Button @click="closeDialogCorreo"> Cerrar</Button>
                        Enviar comprobante
                        <Button @click="enviaCorreo"> Enviar</Button>
                    </div>
                </template>
                <template #content>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <InputText v-model="asunto" type="text" />
                                <label>Asunto</label>
                            </span>
                        </div>
                    </div>

                    <Editor v-model="mensaje" editorStyle="height: 300px" />
                </template>
            </Card>
        </Dialog>

        <Card class="shadow-none mt-3">
            <template #header>
                <Button class="mr-2" @click="closeDialogPdf"> Cerrar</Button>
                <Button class="mr-2" @click="descargaComprobante"> Descargar PDF</Button>
                <Button class="mr-2" @click="dialogCorreo = true"> Enviar</Button>
            </template>
            <template #content>
                <ScrollTop />
                <ScrollPanel style="height: 600px">
                    <div id="invoice">
                        <!-- CABECERA DEL COMPROBANTE -->
                        <div class="p-d-flex justify-center p-fluid grid formgrid col-12 mr-0 pr-0 ml-0 pl-0">
                            <div class="md:col-6 mr-0 pr-0 ml-0 pl-0 text-left">
                                <a href="https://contago.com.mx/" target="_blank">
                                    <img :src="datosEmpresa.logo.base64" width="250">
                                </a>
                            </div>
                            <div class="md:col-6 text-right mr-0 pr-0 ml-0 pl-0">
                                <font size="4">
                                    <div><strong>Orden de Compra</strong></div>
                                </font>

                                <font size="3">
                                    <div>{{ datosEmpresa.rfc }}</div>
                                </font>
                                <font size="3">
                                    <div>{{ datosEmpresa.nombre }}</div>
                                </font>
                                <font size="2">
                                    <div>{{ datosEmpresa.regimenFiscal.regimenFiscal }}</div>
                                </font>
                            </div>
                        </div>
                        <!-- DATOS DEL CLIENTE -->
                        <div class="grid">
                            <div class="col text-left">
                                <font size="3">
                                    <div>Datos del Proveedor:</div>
                                </font>
                                <font size="3">
                                    <div><strong>{{ item.proveedor.rfc + " | " + item.proveedor.nombre }}</strong></div>
                                </font>
                                <font size="2">
                                    <div><b>N. Comercial: </b>{{ item.proveedor.nombreComercial }}</div>
                                </font>
                                <font size="2">
                                    <div>{{ item.proveedor.direccion }}</div>
                                </font>
                            </div>
                            <div class="col text-right">
                                <h3> {{ item.serie + " | " + item.folio }}</h3>
                                <div><b>Fecha: </b> {{ formatDate(item.fecha) }}</div>
                                <div><b>Proyecto: </b>{{ item.proyecto.descripcion }}</div>
                                <div><b>Solicitante: </b>{{ item.solicitante }}</div>
                            </div>
                        </div>
                        <!-- CONCEPTOS -->
                        <DataTable class="mt-1" :value="primeraParte" responsiveLayout="scroll">
                            <Column field="item" header="Item"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '800px', 'font-size': '90%' }">
                            </Column>
                            <Column field="descripcion" header="Descripción" class="flex"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '200px', 'font-size': '90%' }">
                                <template #body="{ data }">
                                    <h4 style="overflow-wrap: break-word;">{{ data.descripcion }}</h4>
                                    <p style="font-size: 12px">{{ data.detalle }}</p>
                                </template>
                            </Column>
                            <Column field="cantidad" header="Cantidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '800px', 'font-size': '90%' }">
                                <template #body="{ data }">
                                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                                </template>
                            </Column>
                            <Column field="medida" header="Unidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                            </Column>
                            <Column field="valorUnitario" header="Valor Unitario"
                                :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                </template>
                            </Column>
                            <Column field="importe" header="Importe"
                                :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.importe) }}</span>
                                </template>
                            </Column>

                        </DataTable>
                        <template v-if="segundaParte.length != 0">
                            <DataTable class="mt-1 ocult" :value="segundaParte" responsiveLayout="scroll">
                                <Column field="item" header="Item"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '800px', 'font-size': '90%' }">
                                </Column>
                                <Column field="descripcion" header="Descripción" class="flex"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '200px', 'font-size': '90%' }">
                                    <template #body="{ data }">
                                        <h4 style="overflow-wrap: break-word;">{{ data.descripcion }}</h4>
                                        <p style="font-size: 12px">{{ data.detalle }}</p>
                                    </template>
                                </Column>
                                <Column field="cantidad" header="Cantidad"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '800px', 'font-size': '90%' }">
                                    <template #body="{ data }">
                                        <span>{{ formatoCantidad(data.cantidad) }}</span>
                                    </template>
                                </Column>
                                <Column field="medida" header="Unidad"
                                    :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                                </Column>

                                <Column field="valorUnitario" header="Valor Unitario"
                                    :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                    </template>
                                </Column>
                                <Column field="importe" header="Importe"
                                    :styles="{ 'max-width': '80px', 'min-width': '80px', 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                    <template #body="{ data }">
                                        <span>{{ formatCurrency(data.importe) }}</span>
                                    </template>
                                </Column>

                            </DataTable>
                        </template>
                        <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                        <div class="grid">
                            <div class="col-8 text-left mt-2">
                            </div>
                            <div class="col-4">

                                <DataTable :value="itemsImpuestos" class="ocult" responsiveLayout="scroll">
                                    <Column field="impuesto" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%' }">
                                    </Column>
                                    <Column field="importe" header=""
                                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '80%', 'text-align': 'right' }">
                                        <template #body="{ data }">
                                            <span>{{ formatCurrency(data.importe) }}</span>
                                        </template>
                                    </Column>
                                </DataTable>

                            </div>
                            <div class="col-12 text-center">

                                <p style="font-size: 12px"><strong>Importe con letra: </strong> {{ importeLetra }}</p>
                                <br>

                            </div>
                        </div>
                        <div class="grid">
                            <div class="col text-center">
                                <p style="font-size: 12px">{{ item.notas }}</p>
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </template>
        </Card>
        <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
    </div>
</template>

<script>
import moment from "moment";
import html2pdf from "html2pdf.js";
import axios from 'axios'
import { numeroALetras } from '../../plugins/numeroALetras.js'

export default {
    components: {
        // numeroALetras,
    },
    data() {
        return {
            dialogCorreo: false,
            loadingMensaje: '',
            loading: false,
            destinatarios: [],
            nuevoDestinatario: {
                nombre: '',
                correo: ''
            },
            primeraParte: [],
            segundaParte: [],
            asunto: 'Envio Orden de Compra.',
            mensaje: ''
        };
    },
    computed: {
        item() {
            return this.$store.state.comprasOrdenCompraStore;
        },
        empresa() {
            return this.$store.state.usuario;
        },

        datosEmpresa() {
            return this.$store.state.empresaStore
        },

        correo() {
            return this.$store.state.correoStore
        },
        importeLetra() {
            const func = require('../../plugins/numeroALetras.js')
            let a = func.numeroALetras(this.$store.state.comprasOrdenCompraStore.total, 'MXN');
            return a;
        },
        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.comprasOrdenCompraStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.comprasOrdenCompraStore.impuestos.traslados) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.comprasOrdenCompraStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.comprasOrdenCompraStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
    },
    watch: {

    },
    mounted() {

    },
    created() {
        // this.getCorreo();
        this.divirConceptos();
    },
    methods: {
        divirConceptos() {
            if (this.item.conceptos.length > 8) {
                this.primeraParte = this.item.conceptos.slice(0, 8);
                this.segundaParte = this.item.conceptos.slice(8);
            }
            else {
                this.primeraParte = this.item.conceptos
            }

        },

        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy");
            return fechaR;
        },

        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        async exportToPDF() {
            var opt = {
                margin: .5,
                filename: 'ORDEN-COMPRA' + this.item.serie + "_" + this.item.folio + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //EXPORTA EL PDF
            html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                // console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');

                    // you can add the line separator as an image, consult the docs below to properly set the place of the image
                    // pdf.addImage(img, 'png', 0, 0, 52, 23)
                }

            }).save();
        },

        async convertBase64() {
            var opt = {
                margin: .5,
                filename: 'PEDIDO_' + this.item.folio + ".pdf",
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy']
                },
                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('invoice')).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                // console.log(totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    pdf.text(pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + i + " / " + totalPages);
                    pdf.text(pdf.internal.pageSize.getWidth() - 5,
                        pdf.internal.pageSize.getHeight() - 0.1, 'Documento generado por contago');
                }

            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return 'data:application/pdf;base64,' + file
        },

        async descargaComprobante() {
            await this.exportToPDF()
        },

        openDialogCorreo() {
            this.correo.archivos = []

            this.correo.archivos.push()
            this.dialogCorreo = true;
        },

        closeDialogPdf() {
            this.$emit('cierraDialogPdf')
        },

        closeDialogCorreo() {
            this.dialogCorreo = false;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async enviaCorreo() {
            if (this.item.proveedor.correo == '') {
                this.$toast.add({ severity: 'error', summary: 'Información Incompleta.', detail: 'El proveedor no cuenta con un correo registrado...', life: 5000 });
                return;
            }
            this.loading = true;
            this.loadingMensaje = 'Enviando correo...'

            let correos = {
                asunto: this.asunto,
                mensaje: this.mensaje,
                emisor: {
                    nombre: '',
                    correo: '',
                    contra: '',
                    hostt: '',
                    puerto: 0,
                },
                receptores: [],
                archivos: [],
            }

            var pdf_ = {
                base64: await this.convertBase64(),
                nombre: 'Pedido_' + this.item.folio + '.pdf'
            }

            var des = {
                nombre: this.item.proveedor.nombre,
                correo: this.item.proveedor.correo,
            }
            correos.receptores.push(des)
            des = {}

            correos.archivos.push(pdf_);
            console.log(correos)
            try {
                let response = await axios.post('Correos/PostCorreos/' + this.empresa.empresaBase + '/' + this.empresa.idusuariosApp, correos)
                console.log(response.data)
                this.dialogCorreo = false;
                this.loading = false;
                this.$toast.add({ severity: 'success', detail: 'Correo enviado con éxito', life: 5000 });

            } catch (error) {
                this.$toast.add({ severity: 'error', detail: 'Error al enviar:' + error.response.data, life: 5000 });
                console.log(error)
                this.loading = false;
            }
        },
    }
};
</script>
<style>
.col {
    background-color: white;
}

.grid {
    background-color: white;
}

.ocult thead {
    display: none;
}

.ocult table {
    border: none
}
</style>