<template>
  <div>
    <!-- DIALOGO PARA AUTORIZAR -->
    <Dialog :visible.sync="autorizarProducto" :closable="false" :containerStyle="{ width: '390px' }"
      header="¿Quien Autoriza?" :modal="true">
      <Card>
        <template #content>
          <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <div class=" field col-12">
              <div class="p-inputgroup">
                <span class="p-float-label">
                  <AutoComplete style="text-align: left" v-model="usuarioAutoriza" :suggestions="filteredUsuario"
                    @complete="searchUsuario($event)" field="usuario">
                  </AutoComplete>
                  <label>Usuario</label>
                </span>
              </div>
            </div>
          </div>
          <v-otp-input class="justify-center" ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4"
            :should-auto-focus="true" v-model="password" :is-input-num="true" @on-change="handleOnChange"
            @on-complete="handleOnComplete" type="password" />
        </template>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="autorizarProducto = false" />
          <Button label="Autorizar" icon="pi pi-check" class="p-button-text" @click="autorizarProductoAsync()" />
        </template>
      </Card>
    </Dialog>

    <!-- DIALOGO CONFIRMAR ELIMINACION -->
    <Dialog :visible.sync="deleteProductDialog" :styles="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Estas seguro de eliminar la remisión <b>{{ objetoEditado.serie + objetoEditado.folio }}</b>.?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="eliminarRemision()" />
      </template>
    </Dialog>

    <!-- DIALOGO DE LOADING -->
    <Dialog :baseZIndex="2" :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>

    <!-- NUEVA VENTA REMISION -->
    <Dialog :showHeader="false" position="top" :visible.sync="dialogVentaRemision" :closeOnEscape="true" :closable="false"
      :modal="false" :style="{ width: '95vw' }">
      <VentaRemision @closeVentaRemision="dialogVentaRemision = false"></VentaRemision>
    </Dialog>

    <!-- PDF -->
    <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <RemisionPDF @cierraDialogPdf="closeDialogPdf"></RemisionPDF>
    </Dialog>

    <!-- DIALOG DEL PDF DE LA FACTURA -->
    <Dialog :visible.sync="dialogPdfFactura" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
      <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
    </Dialog>

    <!-- FACTURA -->
    <Dialog :visible.sync="dialogFactura" :style="{ width: '1200px' }" :closable="false" :modal="true" position="top">
      <factura @closeFactura="closeDialogFactura"></factura>
    </Dialog>

    <!-- DILOAG DEL PDF DE COMERCIO EXTERIOR -->
    <Dialog :visible.sync="dialogPdfComercioExterior" :style="{ width: '850px' }" :closable="false" :modal="true"
      position="top">
      <comprobante-pdf-ce @cierraDialogPdf="closeDialogPdf"></comprobante-pdf-ce>
    </Dialog>

    <!-- COMERCIO EXTERIOR -->
    <Dialog :visible.sync="dialogComercioExterior" :style="{ width: '1200px' }" :closable="false" :modal="true"
      position="top">
      <comercio-exterior @closeComprobante="closeDialogComercioExterior"></comercio-exterior>
    </Dialog>

    <!-- LISTA DE COMPROBANTES ASOCIADOS -->
    <Dialog :visible.sync="dialogComprobantes" :style="{ width: '1200px' }" :closable="false" :modal="true"
      position="top">
      <Card>
        <template #title>
          <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
            <div class="col-1 text-left">
              <Button icon="pi pi-times" @click="closeDialogComprobantes()"
                class="p-button-rounded p-button-danger p-button-outlined" />
            </div>
            <div class="col-10 text-center">
              Comprobantes
            </div>
            <div class="col-1 text-right">
            </div>
          </div>
        </template>
        <template #content>
          <div>
            <b>Remisión: </b> {{ selectedRemisionComp.serie }} {{ selectedRemisionComp.folio }}
          </div>
          <div>
            {{ selectedRemisionComp.cliente.cliente }}
          </div>
          <DataTable class="mt-1 p-datatable-sm" :value="itemsComprobantes" :scrollable="true" scrollHeight="500px"
            scrollDirection="both">
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }" frozen>
              <template #body="slotProps">
                <Button @click="verPdfComprobante(slotProps.data)" icon="pi pi-file-pdf" class="p-button-rounded"
                  style="background:#E65100" v-tooltip.bottom="'PDF'" />
              </template>
            </Column>
            <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            </Column>
            <Column field="folio" header="Folio" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            </Column>
            <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
              sortable>
              <template #body="{ data }">
                {{ formatDate(data.fecha) }}
              </template>
            </Column>
            <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
            </Column>
            <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sortable>
            </Column>
            <Column field="moneda.moneda" header="Moneda" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sortable>
            </Column>
            <Column field="subTotal" header="SubTotal"
              :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.subTotal) }}
              </template>
            </Column>
            <Column field="total" header="Total"
              :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.total) }}
              </template>
            </Column>
            <Column field="folioFiscal" header="Folio Fiscal" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }"
              sortable>
            </Column>
            <Column field="documento" header="Documento" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }" sortable>
            </Column>
            <Column field="tipoInterno" header="Tipo Comprobante" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }"
              sortable>
            </Column>
          </DataTable>
        </template>
      </Card>
    </Dialog>

    <!-- DIALOGO PARA ERRORES -->
    <div class="p-d-flex">
      <Toast />
    </div>

    <Card>
      <template #title>
        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-3 pr-0 pb-0 pa-0 justify-right field col-6 md:col-7">
            <Button icon="mdi mdi-arrow-left-bold "
              class="p-button-rounded p-button-secondary p-button-outlined mr-2 -mb-1" @click="irPedidos()" />
            <Button icon="mdi mdi-arrow-right-bold" class="p-button-rounded p-button-secondary p-button-outlined -mb-1"
              @click="irProtocolo()" />
            Remisiones
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
              <label>Fecha Inicial</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
              <label>Fecha Final</label>
            </span>
          </div>
          <div class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-0 pr-5 pb-0 pa-0 text-right justify-right field col-12 md:col-1">
            <Button icon="pi pi-plus" @click="nuevaRemision()" class="mx-2 p-button-rounded" />
            <Button icon="pi pi-undo" @click="getRemisiones()" class="p-button-rounded" />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable :filters.sync="filtrosRemision" class="mt-1 p-datatable-sm" :value="itemsRemisiones" :scrollable="true"
          scrollHeight="500px" scrollDirection="both" :loading="loadingItemsRemisiones" :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :paginator="true" :rows="10" contextMenu @row-contextmenu="onRowContextMenu" :selection.sync="selectedRemision"
          selectionMode="multiple" :metaKeySelection="false" style="margin-top: 3em">
          <template #header>
            <div class="flex justify-content-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filtrosRemision['global'].value" placeholder="Buscar" />
              </span>
              <Button label="Borrar selección" class="p-button-outlined p-button-help" @click="borrarSeleccion" />
            </div>
          </template>
          <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'min-width': '15rem', }" frozen>
            <template #body="slotProps">
              <template v-if="slotProps.data.estatus === 'Nuevo'">
                <Button @click="confirmDeleteProduct(slotProps.data)" icon="pi pi-trash"
                  class="p-button-rounded p-button-danger" v-tooltip.bottom="'Eliminar'" />
                <Button @click="editConcepto(slotProps.data)" icon="pi pi-pencil" class="p-button-rounded p-button-info"
                  v-tooltip.bottom="'Editar'" />
                <Button @click="abrirAutorizarProducto(slotProps.data)" icon="pi pi-check"
                  class="p-button-rounded p-button-success" v-tooltip.bottom="'Autorizar'" />
              </template>
              <template v-if="slotProps.data.facturas != 0">
                <Button @click="verFacturas(slotProps.data)" icon="pi pi-inbox" class="p-button-rounded p-button-success"
                  v-tooltip.bottom="'Ver Facturas'" />
              </template>
              <Button icon="pi pi-replay" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Similar'"
                @click="remisionSimilar(slotProps.data)" />
              <Button @click="openDialogPdf(slotProps.data)" icon="pi pi-file-pdf" class="p-button-rounded"
                style="background:#E65100" v-tooltip.bottom="'PDF'" />
            </template>
          </Column>
          <Column field="facturas" header="Facturas" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
          </Column>
          <Column field="serie" header="Serie" :styles="{ 'flex-grow': '1', 'flex-basis': '80px' }" sortable>
          </Column>
          <Column field="folio" header="Folio"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'text-align': 'center' }">
            <template #body="slotProps">
              <span :class="'customer-badge status-' + slotProps.data.estatus">{{ slotProps.data.folio }}</span>
            </template>
          </Column>
          <Column field="fecha" header="Fecha" dataType="date" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }"
            sortable>
            <template #body="{ data }">
              {{ formatDate(data.fecha) }}
            </template>
          </Column>
          <Column field="rfc" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
          </Column>
          <Column field="nombre" header="Nombre" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }" sortable>
          </Column>
          <Column field="ordenCompra" header="OC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }" sortable>
          </Column>
          <Column field="proyecto.descripcion" header="Proyecto" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }"
            sortable>
          </Column>
          <Column field="subTotal" header="SubTotal"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.subTotal) }}
            </template>
          </Column>
          <Column field="total" header="Total"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.total) }}
            </template>
          </Column>
          <Column field="moneda.clave" header="Moneda" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }" sortable>
          </Column>
          <Column field="totalFacturado" header="Total Facturado"
            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'justify-content': 'right', }" sortable>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalFacturado) }}
            </template>
          </Column>
        </DataTable>
        <ContextMenu :model="menuModel" ref="cm" />
      </template>
      <template #footer>
        <div class="flex align-items-center">
          <Chip label="Nuevo" class="mr-2 nuevo-chip " />
          <Chip label="Autorizado" class="mr-2 autorizado-chip" />
          <Chip label="Facturado" class="mr-2 facturado-chip" />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import moment from "moment";
import VentaRemision from "./VentaRemision";
import RemisionPDF from "../Pdf/VentaRemisionPdf";
//FACTURA
import factura from '../Comprobantes/Factura/Factura.vue';
import ComprobantePdf from "../Pdf/ComprobantePdf.vue";
//COMERCIO EXTERIOR
import comercioExterior from '../Comprobantes/ComercioExterior/ComercioExterior.vue';
import ComprobantePdfCe from "../Pdf/ComplementoComercioExteriorPdf.vue";

export default {
  components: { VentaRemision, RemisionPDF, factura, ComprobantePdf, comercioExterior, ComprobantePdfCe },
  data() {
    return {
      listaUsuariosAutorizantes: [],
      filteredUsuario: null,
      usuarioAutoriza: { usuario: '' },
      autorizarProducto: false,
      password: "",

      dialogPdf: false,
      dialogPdfFactura: false,
      dialogPdfComercioExterior: false,

      loadingMensaje: "",
      loading: false,

      fechaI: new Date(),
      fechaF: new Date(),

      loadingItemsRemisiones: false,
      dialogVentaRemision: false,

      deleteProductDialog: false,
      editedIndex: -1,
      objetoEditado: {},

      //PARA LAS OPCIONES DE FACTURACION
      dialogFactura: false,
      dialogComercioExterior: false,
      selectedRemision: null,
      menuModel: [
        { label: 'Factura', icon: 'pi pi-fw pi-book', command: () => this.factura(this.selectedRemision) },
        { label: 'Comercio Exterior', icon: 'pi pi-fw pi-book', command: () => this.comercioExteriorN(this.selectedRemision) },
        { label: 'Protocolo', icon: 'pi pi-fw pi-book', command: () => this.generaProtocolo(this.selectedRemision) },
      ],
      // FILTROS
      filtrosRemision: {
        "global": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "folio": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        "rfc": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        "nombre": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        "proyecto.descripcion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      },

      //COMPROBANTES
      dialogComprobantes: false,
      itemsComprobantes: [],
      selectedRemisionComp: {
        serie: '',
        folio: '',
        cliente: {},
      },

      idRemision: 0,

    };
  },
  computed: {
    itemsRemisiones() {
      return this.$store.state.listVentaRemisionStore;
    },
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    irPedidos() {
      this.$router.push({ name: "ListaVentasPedidos" });

    },
    irProtocolo() {
      this.$router.push({ name: "ListaProtocolos" });

    },
    async getEmpresa() {
      try {
        let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
      }
    },

    async getRemisiones() {
      try {
        this.loadingItemsRemisiones = true;
        let fechaInicial = moment(this.fechaI).format("yyyy-MM-DD");
        let fechaFinal = moment(this.fechaF).format("yyyy-MM-DD");
        let response = await axios.get("Ventas/GetVentasRemisiones/" + this.token.empresaBase + "/" + fechaInicial + "/" + fechaFinal);
        let array = response.data;
        this.$store.state.listVentaRemisionStore = array.sort(((a, b) => b.folio - a.folio))
        console.log(array)
        this.loadingItemsRemisiones = false;
      } catch (error) {
        console.log(error);
        this.loadingItemsRemisiones = false;
      }
    },

    async eliminarRemision() {
      this.loading = true;
      this.loadingMensaje = 'Procesando soliciud, espere...'

      try {
        this.objetoEditado.usuarioModificador = this.token.nombre
        this.objetoEditado.estatus = 'Eliminado'

        let response = await axios.put('Ventas/DeleteVenta/' + this.token.empresaBase, this.objetoEditado)
        this.itemsRemisiones.splice(this.editedIndex, 1)

        this.deleteProductDialog = false;
        this.loading = false;

        this.$toast.add({ severity: 'success', detail: 'Remisión eliminado con éxito.', life: 3000 });

      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Error al eliminar, intente nuevamente.', life: 3000 });
        this.loading = false;
      }
    },

    async abrirAutorizarProducto(data) {
      let listaUsuariosAutorizantes = [];
      this.idRemision = data.idVenta
      await this.getUsuarios();

      //BUSCAR EL USUARIO EN LA LISTA
      this.loadingMensaje = 'Validando usuario...'
      let indice = this.listaUsuariosAutorizantes.findIndex(x => x.usuario.toUpperCase() === this.token.nombre.toUpperCase())
      if (indice != -1) {
        let ObjUsuario = {}
        ObjUsuario = { ...this.listaUsuariosAutorizantes[indice] }
        let indicePermiso = ObjUsuario.permisos.findIndex(x => x.permiso === 'Autorizar')
        if (indicePermiso != -1) {
          console.log('Autorzacion directa')
          this.usuarioAutoriza.usuario = ObjUsuario.usuario
          this.password = ObjUsuario.pin
          await this.autorizarProductoAsync();
          this.loading = false;
        }
        else {
          console.log('mostrar dialog')
          this.password = "";
          this.usuarioAutoriza = "";
          this.autorizarProducto = true;
          this.loading = false;
        }
      }
      else {
        console.log('mostrar dialog')
        this.password = "";
        this.usuarioAutoriza = "";
        this.autorizarProducto = true;
        this.loading = false;
      }
    },

    async autorizarProductoAsync() {
      this.loading = true;
      this.loadingMensaje = "Procesando soliciud, espere...";

      try {
        let response = await axios.post("Ventas/autorizarProducto/" + this.token.empresaBase + "/" + this.idRemision + "/" + this.usuarioAutoriza.usuario + "/" + this.password);
        this.$toast.add({
          severity: "success",
          detail: "Cotización Autorizado con éxito.",
          life: 3000,
        });

        let indice = this.$store.state.listVentaRemisionStore.findIndex(
          (x) => x.idVenta === this.idRemision
        );
        this.$store.state.listVentaRemisionStore[indice].estatus = "Autorizado"
        this.loading = false;
        this.autorizarProducto = false;
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: "error",
          detail: "Error al autorizar, intente nuevamente.",
          life: 3000,
        });
        this.loading = false;
        this.handleClearInput();
        this.usuarioAutoriza = "";
      }
    },

    searchUsuario(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredUsuario = [...this.listaUsuariosAutorizantes];
        }
        else {
          this.filteredUsuario = this.listaUsuariosAutorizantes.filter((country) => {
            return country.usuario.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    async getUsuarios() {
      this.loadingItemsUsuarios = true;
      try {
        let response = await axios.get('Usuarios/GetUsuariosAutorizantes/' + this.token.empresaBase)
        this.listaUsuariosAutorizantes = response.data
        console.log(response)
        this.loadingItemsUsuarios = false;

      } catch (error) {
        console.log(error)
        this.loadingItemsUsuarios = false;

      }
    },

    openDialogPdf(data) {
      console.log(data)
      this.dialogPdf = true;
      this.$store.state.ventaRemisionStore = { ...data }
    },

    closeDialogPdf() {
      this.dialogPdf = false;
      this.dialogPdfFactura = false;
      this.dialogPdfComercioExterior = false;
    },

    closeDialogFactura() {
      this.dialogFactura = false;
    },

    closeDialogComercioExterior() {
      this.dialogComercioExterior = false;
    },

    editConcepto(data) {
      this.$store.state.ventaRemisionStore = { ...data }
      this.dialogVentaRemision = true
    },

    confirmDeleteProduct(data) {
      this.editedIndex = this.itemsRemisiones.indexOf(data)
      this.objetoEditado = { ...data };
      this.deleteProductDialog = true;
    },

    nuevaRemision() {
      let remision = this.$store.state.ventaRemisionStore.idVenta
      if (remision == -1) {
        let obj = {
          idVenta: 0,
          serie: '',
          folio: '',
          fecha: new Date(),
          vigencia: new Date(),
          formaPago: {},
          condicionesPago: '',
          subTotal: 0,
          descuento: 0,
          moneda: {
            id: 100,
            clave: 'MXN',
            descripcion: 'Peso Mexicano',
            moneda: 'MXN | Peso Mexicano'
          },
          tipoCambio: 0,
          total: 0,
          tipoComprobante: 'Remision',
          metodoPago: {},
          empresa: {},
          cliente: {
            rfc: '',
            nombre: '',
            cliente: '',
            usoCfdi: {},
          },
          rfc: '',
          nombre: '',
          atn: '',
          notas: '',
          estatus: 'Nuevo',
          usuarioCreador: '',
          usuarioModificador: '',
          usuarioAutoriza: '',
          conceptos: [],
          impuestos: {
            traslados: [],
            retenciones: [],
          },
          proyecto: {
            descripcion: '',
            presupuesto: 0
          },
          ordenTrabajo: '',
          ordenCompra: '',
          idRelacion: 0,
          facturas: 0,
          totalFacturado: 0,
        }
        this.$store.state.ventaRemisionStore = { ...obj }
      }
      this.dialogVentaRemision = true;

    },

    remisionSimilar(item) {
      console.log(item)
      let similar = { ...item }
      similar.idVenta = 0
      similar.folio = ''
      similar.estatus = 'Nuevo'
      similar.fecha = new Date()

      this.$store.state.ventaRemisionStore = { ...similar }
      this.dialogVentaRemision = true;
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    formatDate(date) {
      moment.locale('es-mx')
      return moment(date).format("DD-MMMM-yyyy");
    },

    handleOnComplete(value) {
      console.log("OTP completado: ", value);
      this.password = value;
    },

    handleOnChange(value) {
      console.log("OTP cambio: ", value);
      this.itemsEmpresa = [];
    },

    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    //PARA FACTURAR
    factura() {
      try {
        let proyecto = this.selectedRemision[0].proyecto.idProyecto;
        let cliente = this.selectedRemision[0].cliente;
        let moneda = this.selectedRemision[0].moneda;
        let formaPago = this.selectedRemision[0].formaPago;
        let condicionesPago = this.selectedRemision[0].condicionesPago;
        let metodoPago = this.selectedRemision[0].metodoPago;
        let ordenCompra = this.selectedRemision[0].ordenCompra;

        let subTotal = 0
        let total = 0;
        let conceptos = []
        let remisiones = []
        let listTrasladados = [];
        let listRetenidos = [];
        let impuestos =
        {
          traslados: [],
          retenciones: [],
        }

        for (var c of this.selectedRemision) {
          //VALIDAMOS QUE ESTE AUTORIZADO
          if (c.estatus === 'Nuevo') {
            this.$toast.add({ severity: 'error', detail: 'Todas las remisiones deben de estar autorizadas', life: 3000 });
            return
          }
          //VALIDAMOS QUE SEAN DEL MISMO PROYECTO
          if (c.proyecto.idProyecto != proyecto) {
            this.$toast.add({ severity: 'error', detail: 'Todas las remisiones deben tener el mismo proyecto', life: 3000 });
            return
          }
          //RECORREMOS LOS CONCEPTOS PARA AGREGARLOS A LA FACTURA
          for (var concepto of c.conceptos) {
            let Obj = {}
            Obj = { ...concepto }
            conceptos.push(Obj)
            Obj = {}
          }
          let remision = {
            id: c.idVenta,
            folioFiscal: '',
          }
          remisiones.push(remision);
          remision = {};
          //SUMAMOS EL TOTAL Y EK SUBTOTAL
          total += c.total;
          subTotal += c.subTotal
          //RECORREMOS LOS IMPUESTOS, PARA AGRUPARLOS
          for (var t of c.impuestos.traslados) {
            listTrasladados.push(t)
          }
          for (var r of c.impuestos.retenciones) {
            listRetenidos.push(r)
          }
        }

        //AGRUPAMOS LOS IMPUESTOS
        var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
        for (const prop in obj) {
          const tra = Object.assign({}, obj[prop])
          impuestos.traslados.push(tra)
        }

        var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
        for (const prop in objR) {
          const ret = Object.assign({}, objR[prop])
          impuestos.retenciones.push(ret)
        }
        //ARMAMOS LA NUEVA FACTURA Y LO MOSTRAMOS EN PANTALLA
        let nuevo = {
          idFactura: 0,
          folioFiscal: '',
          serie: '',
          folio: '',
          fecha: new Date(),
          formaPago: formaPago,
          condicionesPago: condicionesPago,
          subTotal: subTotal,
          descuento: 0,
          moneda: moneda,
          tipoCambio: 0,
          total: total,
          tipoComprobante: 'I',
          tipoComprobanteInterno: 'Factura',
          metodoPago: metodoPago,
          empresa: {},
          cliente: cliente,
          rfc: cliente.rfc,
          nombre: cliente.nombre,
          atn: '',
          notas: '',
          estatus: 'Sin Timbrar',
          estatusCancelacion: '',
          usuarioCreador: '',
          usuarioModificador: '',
          usuarioAutoriza: '',
          conceptos: conceptos,
          impuestos: impuestos,
          cfdiRelacionados: [
            {
              tipoRelacion: {},
              cfdiRelacionados: [],
            }
          ],
          timbreFiscalDigital: {
            folioFiscal: '',
            fechaTimbrado: new Date(),
            selloCFDI: { base64: '' },
            noCertificado: '',
            noCertificadoSAT: '',
            selloSAT: { base64: '' },
            cadenaOriginal: '',
            serie: '',
            folio: '',
            Error: '',
            archivoXml: '',
          },
          remisiones: remisiones,
          ordenCompra: ordenCompra,
          ordenTrabajo: '',
        }

        this.$store.state.facturaStore = { ...nuevo }
        this.dialogFactura = true;
      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Seleccione al menos una remisión', life: 3000 });
      }
    },

    comercioExteriorN() {
      try {
        let proyecto = this.selectedRemision[0].proyecto.idProyecto;
        let cliente = this.selectedRemision[0].cliente;
        let moneda = this.selectedRemision[0].moneda;
        let formaPago = this.selectedRemision[0].formaPago;
        let condicionesPago = this.selectedRemision[0].condicionesPago;
        let metodoPago = this.selectedRemision[0].metodoPago;
        let ordenCompra = this.selectedRemision[0].ordenCompra;

        let subTotal = 0
        let total = 0;
        let conceptos = []
        let remisiones = []
        let listTrasladados = [];
        let listRetenidos = [];
        let impuestos =
        {
          traslados: [],
          retenciones: [],
        }

        for (var c of this.selectedRemision) {
          //VALIDAMOS QUE ESTE AUTORIZADO
          if (c.estatus === 'Nuevo') {
            this.$toast.add({ severity: 'error', detail: 'Todas las remisiones deben de estar autorizadas', life: 3000 });
            return
          }
          //VALIDAMOS QUE SEAN DEL MISMO PROYECTO
          if (c.proyecto.idProyecto != proyecto) {
            this.$toast.add({ severity: 'error', detail: 'Todas las remisiones deben tener el mismo proyecto', life: 3000 });
            return
          }
          //RECORREMOS LOS CONCEPTOS PARA AGREGARLOS A LA FACTURA
          for (var concepto of c.conceptos) {
            let Obj = {}
            Obj = { ...concepto }
            conceptos.push(Obj)
            Obj = {}
          }
          let remision = {
            id: c.idVenta,
            folioFiscal: '',
          }
          remisiones.push(remision);
          remision = {};
          //SUMAMOS EL TOTAL Y EK SUBTOTAL
          total += c.total;
          subTotal += c.subTotal
          //RECORREMOS LOS IMPUESTOS, PARA AGRUPARLOS
          for (var t of c.impuestos.traslados) {
            listTrasladados.push(t)
          }
          for (var r of c.impuestos.retenciones) {
            listRetenidos.push(r)
          }
        }

        //AGRUPAMOS LOS IMPUESTOS
        var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
        for (const prop in obj) {
          const tra = Object.assign({}, obj[prop])
          impuestos.traslados.push(tra)
        }

        var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
        for (const prop in objR) {
          const ret = Object.assign({}, objR[prop])
          impuestos.retenciones.push(ret)
        }
        //ARMAMOS LA NUEVA FACTURA Y LO MOSTRAMOS EN PANTALLA
        let nuevo = {
          idComprobante: 0,
          folioFiscal: '',
          serie: '',
          folio: '',
          fecha: new Date(),
          formaPago: formaPago,
          condicionesPago: condicionesPago,
          subTotal: subTotal,
          descuento: 0,
          moneda: moneda,
          tipoCambio: 0,
          total: total,
          tipoComprobante: 'I',
          tipoComprobanteInterno: 'CE',
          metodoPago: metodoPago,
          empresa: {},
          cliente: cliente,
          rfc: cliente.rfc,
          nombre: cliente.nombre,
          atn: '',
          notas: '',
          estatus: 'Sin Timbrar',
          estatusCancelacion: '',
          usuarioCreador: '',
          usuarioModificador: '',
          usuarioAutoriza: '',
          conceptos: conceptos,
          impuestos: impuestos,
          cfdiRelacionados: [
            {
              tipoRelacion: {},
              cfdiRelacionados: [],
            }
          ],
          timbreFiscalDigital: {
            folioFiscal: '',
            fechaTimbrado: new Date(),
            selloCFDI: { base64: '' },
            noCertificado: '',
            noCertificadoSAT: '',
            selloSAT: { base64: '' },
            cadenaOriginal: '',
            serie: '',
            folio: '',
            Error: '',
            archivoXml: '',
          },
          comercio: {
            version: '1.1',
            motivoTraslado: {
              clave: '',
            },
            tipoOperacion: {},
            claveDePedimento: {},
            certificadoOrigen: {},
            numCertificadoOrigen: '',
            numeroExportadorConfiable: '',
            incoterm: {},
            subdivision: '0',
            observaciones: '',
            tipoCambioUSD: 0,
            totalUSD: 0,
            emisor: {},
            propietarios: [],
            receptor: {},
            destinatarios: [],
            mercancias: [],
          },
          remisiones: remisiones,
          ordenCompra: ordenCompra,
          ordenTrabajo: '',
        }

        this.$store.state.comercioExteriorStore = { ...nuevo }
        this.dialogComercioExterior = true;
      } catch (error) {
        console.log(error)
        this.$toast.add({ severity: 'error', detail: 'Seleccione al menos una remisión', life: 3000 });
      }
    },

    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },

    borrarSeleccion() {
      this.selectedRemision = null;
    },

    agrupaImpuestos(miarray, prop) {
      return miarray.reduce(function (groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.idImpuesto };
        groups[val].base_ += item.base_;
        groups[val].importe += item.importe;
        groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
        return groups;
      }, {});
    },

    verFacturas(item) {
      console.log(item)
      this.selectedRemisionComp = { ...item }
      this.itemsComprobantes = item.comprobantes;
      this.dialogComprobantes = true;
    },

    closeDialogComprobantes() {
      this.dialogComprobantes = false;
    },

    async verPdfComprobante(item) {
      console.log(item.folioFiscal)
      this.loading = true;
      try {
        this.loadingMensaje = 'Consultando comprobante...'
        this.loading = false

        if (item.tipoInterno === 'Factura') {
          let response = await axios.get('Comprobantes/GetComprobanteFolioFiscal/' + this.token.empresaBase + '/' + item.folioFiscal);
          let _comprobante = response.data[0]
          console.log(_comprobante)
          this.$store.state.facturaStore = { ..._comprobante }
          this.dialogPdfFactura = true;
        }
        if (item.tipoInterno === 'CE') {
          let response = await axios.get('ComercioExterior/GetComprobanteFolioFiscal/' + this.token.empresaBase + '/' + item.folioFiscal);
          let _comprobante = response.data[0]
          console.log(_comprobante)
          this.$store.state.comercioExteriorStore = { ..._comprobante }
          this.dialogPdfComercioExterior = true;
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },

    //PARA PROTOCOLOS
    generaProtocolo(item) {
      let protocolo = { ...this.selectedRemision[0] };
      console.log(protocolo.conceptos);

    },

  },
};
</script>
<style >
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #EFF6FF;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1.25rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-contextmenu .p-menuitem-link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.status-Nuevo {
  background-color: #ED6421;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Modificada {
  background-color: #ED6421;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Autorizado {
  background-color: #2BA2DE;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.status-Facturado {
  background-color: #3EBF4F;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 50px;
  height: 45px;
  font-size: 18px bold;
  color: white;
  font-weight: bolder;
}

.p-chip.nuevo-chip {
  background: #ED6421;
  ;
  color: var(--primary-color-text);
}

.p-chip.autorizado-chip {
  background: #2BA2DE;
  color: var(--primary-color-text);
}

.p-chip.facturado-chip {
  background: #3EBF4F;
  color: var(--primary-color-text);
}
</style>