<template>
    <div>
        <!-- <ScrollTop /> -->
        <!-- <ScrollPanel style="height: 500px"> -->
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- CLAVE PROD SERV -->
            <template v-if="editedIndex === -1">
                <div class=" field col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoSelect" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" field="concepto" @item-select="asignaConcepto">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
            </template>
            <!-- CLAVE PROD SERV -->
            <div class=" field col-12">
                <span class="p-float-label">
                    <AutoComplete style="text-align: left" v-model="concepto.claveProdServ"
                        :suggestions="filteredClaveProdServ" @complete="searchClaveProdServ($event)" field="claveProdServ">
                    </AutoComplete>
                    <label>Clave(SAT)</label>
                </span>
            </div>
            <!-- NO DE INDENTIFICACION -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                    <label>No. Identificación</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.descripcion" style="text-transform: uppercase" />
                    <label>Descripción</label>
                </span>
            </div>
            <!-- CANTIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- UNIDAD SAT -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <AutoComplete v-model="concepto.claveUnidad" :suggestions="filteredClaveUnidad"
                        @complete="searchClaveUnidad($event)" field="claveUnidad"></AutoComplete>
                    <label>Unidad(SAT)</label>
                </span>
            </div>
            <!-- UNIDAD -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.unidad" />
                    <label>Unidad</label>
                </span>
            </div>
            <!-- VALOR UNITARIO -->
            <div class="justify-right field col-12 md:col-3 sm:col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber :value="0.00" mode="decimal" locale="en-US" :minFractionDigits="6" disabled />
                        <label>Valor unitario</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                </div>
            </div>
            <!-- OBJETO DE IMPUESTOS -->
            <div class="justify-left field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp" optionLabel="objImp" />
                    <label>Objeto de impuestos(SAT)</label>
                </span>
            </div>
        </div>
        <!-- TABLA DE CONCEPTOS -->
        <DataTable :value="trasladoCP.conceptos" :scrollable="true" scrollHeight="500px" scrollDirection="both"
            class="mt-3">
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }" frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="editConcepto(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        @click="deleteConcepto(slotProps.data)" />
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="noIdentificacion" header="No. Indentificación"
                :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }">
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="valorUnitario" header="Valor Unitario" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="importe" header="Importe" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="material" header="Material" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
        </DataTable>
        <!-- <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" /> -->
        <!-- </ScrollPanel> -->
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            conceptoDefault: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {},
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            filteredConcepto: null,
            concepto: {
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: { clave: '' },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },
            conceptoSelect: null,
            editedIndex: -1,

            filteredClaveProdServ: null,
            filteredClaveUnidad: null,
            filteredObjImp: null,
            objImp: [
                {
                    id: 3,
                    clave: "01",
                    descripcion: "No objeto de impuesto.",
                    objImp: "01 | No objeto de impuesto."
                }
            ]
        }
    },
    computed: {
        listaConceptos() {
            return this.$store.state.listaConceptosStore
        },
        claveProdServ() {
            return this.$store.state.claveProdServStore
        },
        claveUnidad() {
            return this.$store.state.claveUnidadStore
        },
        // objImp() {
        //     return this.$store.state.objImpStore
        // },
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        trasladoCP() {
            return this.$store.state.trasladoCartaPorteStore;
        },

    },
    created() {
        this.GetClaveProdServ();
        this.GetClaveUnidad();
        // this.GetObjImp();
        this.GetTraslados();
        this.GetRetenciones();
        this.GetConceptos();
    },
    methods: {
        async GetClaveProdServ() {
            if (this.claveProdServ.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            if (this.claveUnidad.length != 0) {
                return
            }
            try {
                let response = await axios.get('CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        // async GetObjImp() {
        //     console.log(this.objImp)
        //     if (this.objImp.length != 0) {
        //         return
        //     }
        //     try {
        //         let response = await axios.get('CatalogosSat/GetObjImp/');
        //         this.$store.state.objImpStore = response.data
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        async GetTraslados() {
            if (this.traslados.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            if (this.retenciones.length != 0) {
                return
            }
            try {
                let response = await axios.get('Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async uploadImage(event) {
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.$store.state.conceptoStore.imagen.base64 = result;
            } catch (error) {
                console.error(error);
                return;
            }
        },

        async GetConceptos() {
            try {
                this.loadingItemsConceptos = true;
                let response = await axios.get('Conceptos/GetConceptos/' + this.empresa.empresaBase)
                this.$store.state.listaConceptosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.descripcion.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchClaveProdServ(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveProdServ = [...this.claveProdServ];
                }
                else {
                    this.filteredClaveProdServ = this.claveProdServ.filter((country) => {
                        return country.claveProdServ.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchClaveUnidad(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClaveUnidad = [...this.claveUnidad];
                }
                else {
                    this.filteredClaveUnidad = this.claveUnidad.filter((country) => {
                        return country.claveUnidad.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchObjImp(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredObjImp = [...this.objImp];
                }
                else {
                    this.filteredObjImp = this.objImp.filter((country) => {
                        return country.objImp.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        asignaConcepto() {
            this.conceptoSelect.impuestos = {
                traslados: [],
                retenciones: [],
            },
                this.conceptoSelect.objImp = {
                    id: 3,
                    clave: "01",
                    descripcion: "No objeto de impuesto.",
                    objImp: "01 | No objeto de impuesto."
                }
            console.log(this.conceptoSelect)

            this.concepto = { ...this.conceptoSelect }
        },

        pushConcepto() {
            //HACEMOS UNOS CÁLCULOS
            this.concepto.valorUnitario = 0
            let importe = this.concepto.valorUnitario * this.concepto.cantidad;
            this.concepto.importe = Math.round(importe * 1000000) / 1000000;


            if (this.editedIndex === -1) {
                this.trasladoCP.conceptos.push(this.concepto);
            } else {
                Object.assign(this.trasladoCP.conceptos[this.editedIndex], this.concepto)
            }
            this.conceptoSelect = null;
            this.concepto = { ...this.conceptoDefault };
            this.editedIndex = -1;
            this.sumatoria();
            this.calcularImpuestos();
        },

        deleteConcepto(item) {
            let indice = this.trasladoCP.conceptos.indexOf(item);
            this.trasladoCP.conceptos.splice(indice, 1);
            this.sumatoria();
            this.calcularImpuestos();
        },

        editConcepto(item) {
            this.conceptoSelect = null;
            this.editedIndex = this.trasladoCP.conceptos.indexOf(item);
            this.concepto = { ...item }

        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        calcularImpuestos() {
            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.trasladoCP.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {
                // console.log(a)
                //VERIFICAMOS SI LLEVA IMPUESTOS
                if (a.objImp.clave === '02') {
                    for (let t of a.impuestos.traslados) {
                        t.base_ = a.importe
                        t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                        sumaT = sumaT + t.importe
                        sumaT = Math.round(sumaT * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        listTrasladados.push(t);
                    }

                    for (let r of a.impuestos.retenciones) {
                        r.base_ = a.importe
                        r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                        listRetenidos.push(r);
                    }
                }

                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.trasladoCartaPorteStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'impuesto', 'tasaOCuota')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.trasladoCartaPorteStore.impuestos.traslados.push(tra)
                }
                this.$store.state.trasladoCartaPorteStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'impuesto', 'tasaOCuota')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.trasladoCartaPorteStore.impuestos.retenciones.push(ret)
                }
            }
        },

        sumatoria() {
            let indice = 1;
            for (let x of this.trasladoCP.conceptos) {
                x.item = indice;
                indice++;
            }
            this.trasladoCP.subTotal = Math.round(this.sum(this.trasladoCP.conceptos, 'importe') * 100) / 100;
            let tras = Math.round(this.sum(this.$store.state.trasladoCartaPorteStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.trasladoCartaPorteStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.factura.total = this.factura.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        agrupaImpuestos(miarray, prop, propa) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop, propa];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.tipo };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },
    },
}
</script>