<template>
    <div style="text-align:left">
        <!-- <ScrollTop /> -->
        <!-- <ScrollPanel style="height: 600px"> -->
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- COMPLEMENTO CARTA PORTE -->
            <div class="field col-12 text-center">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    COMPLEMENTO CARTA PORTE
                </h1>
            </div>
            <!-- VERSION -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText aria-describedby="version" v-model="complemento.version" type="text"
                        v-tooltip.bottom="'Versión del complemento Carta Porte.'" />
                    <label>Versión</label>
                </span>
            </div>
            <!-- TRANSPORTE INTERNACIONAL -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="complemento.transpInternac" :options="itemsTranspInternac"
                        v-tooltip.bottom="'En este campo se deberá registrar si el traslado de bienes y/o mercancías es de carácter internacional.'" />
                    <label>Transporte Internacional</label>
                </span>
            </div>
            <!-- KILOMETROS RECORRIDOS -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputNumber readonly v-model="complemento.totalDistRec" mode="decimal" suffix=" km" locale="en-US"
                        v-tooltip.bottom="'En este campo se deberá registrar la suma de las distancias recorridas en kilómetros, registradas en la sección Ubicación de destino.'" />
                    <label>Total Distancia a Recorrer</label>
                </span>
            </div>
            <template v-if="complemento.transpInternac == 'SI'">
                <!-- COMPLEMENTO CARTA PORTE -->
                <div class="field col-12 text-center">
                    <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                        TRANSPORTE INTERNACIONAL
                    </h1>
                </div>
                <!-- ENTRADA O SALIDA DE MERCANCIAS -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="complemento.entradaSalidaMerc"
                            :options="itemsEntradaSalidaMerc"
                            v-tooltip.bottom="'Este campo se deberá registrar cuando los bienes y/o mercancías ingresen o salgan del territorio nacional.'" />
                        <label>Entrada o Salida de Mercancías</label>
                    </span>
                </div>
                <!-- VÍA DE ENTRADA O SALIDA -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="complemento.viaEntradaSalida"
                            :options="itemsViaEntradaSalida" optionLabel="transporte"
                            v-tooltip.bottom="'En este campo se deberá registrar la clave del transporte a través del cual las mercancías ingresano salen del territorio nacional.'" />
                        <label>Vía de entrada o salida</label>
                    </span>
                </div>
                <!-- VÍA DE ENTRADA O SALIDA -->
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="complemento.paisOrigenDestino" :options="itemsPais"
                            optionLabel="pais"
                            v-tooltip.bottom="'En este campo se deberá registrar la clave del transporte a través del cual las mercancías ingresano salen del territorio nacional.'" />
                        <label>País Origen/Destino</label>
                    </span>
                </div>
            </template>

            <!-- UBICACIÓN ORIGEN -->
            <div class="field col-12 text-center mt-4">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    UBICACIÓN ORIGEN
                </h1>
            </div>
            <!-- ORIGEN -->
            <!-- <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="complemento.ubicacionOrigen"
                            v-tooltip.bottom="'En este campo, se deberá registrar el punto de salida.'"
                            :options="itemsOrigenes" optionLabel="ubicacion" />
                        <label>Ubicación de Origen</label>
                    </span>
                </div> -->
            <div class="field col-12 md:col-8">
                <div class="p-inputgroup">
                    <Button icon="pi pi-refresh" @click="getOrigenes()" />
                    <span class="p-float-label">
                        <AutoComplete v-model="complemento.ubicacionOrigen" :suggestions="filteredUbicacionOrigen"
                            v-tooltip.bottom="'En este campo, se deberá registrar el punto de salida.'"
                            @complete="searchUbicacionOrigen($event)" field="ubicacion">
                        </AutoComplete>
                        <label>Ubicación Origen</label>
                    </span>
                </div>
            </div>
            <!-- FECHA Y HORA DE SALIDA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Calendar v-model="complemento.ubicacionOrigen.fecha" dateFormat="dd-MM-yy" :showTime="true"
                        hourFormat="12" />
                    <label>Fecha y Hora de Salida</label>
                </span>
            </div>
            <!-- UBICACIÓN DE DESTINO -->
            <div class="field col-12 text-center mt-4">
                <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                    UBICACIÓN DESTINO
                </h1>
            </div>
            <!-- DESTINO -->
            <div class="field col-12 md:col-4">
                <div class="p-inputgroup">
                    <Button icon="pi pi-refresh" @click="getDestinos()" />
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="destino" :options="itemsDestinos"
                            optionLabel="ubicacion" />
                        <label>Ubicación de Destino</label>
                    </span>
                </div>
            </div>
            <!-- DISTANCIA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputNumber v-model="destino.distanciaRecorrida" mode="decimal" suffix=" km" locale="en-US" />
                    <label>Total Distancia a Recorrer</label>
                </span>
            </div>
            <!-- FECHA Y HORA DE SALIDA -->
            <div class="field col-12 md:col-4">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <Calendar v-model="destino.fecha" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                        <label>Fecha</label>
                    </span>
                    <Button @click="pushDestino()" icon="pi pi-plus" class="p-button-success" />
                </div>
            </div>
            <!-- TABLA DE DESTINOS -->
            <div class="field col-12">
                <DataTable dataKey="iDUbicacion" :value="complemento.ubicacionesDestino" class="mt-3">
                    <Column field="iDUbicacion" header="ID Ubicación" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="nombreRemitenteDestinatario" header="Destinatario"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                    </Column>
                    <Column field="fecha" header="Fecha de llegada" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            {{ formatDate(data.fecha) }}
                        </template>
                    </Column>
                    <Column field="distanciaRecorrida" header="Distancia recorrida"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            {{ data.distanciaRecorrida + ' km' }}
                        </template>
                    </Column>
                    <Column header="Eliminar Destino"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem', }" frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deleteDestino(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- MERCANCIAS -->
            <div class="field col-12 text-center mt-4">
                <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                    MERCANCÍAS
                </h2>
            </div>
            <!-- PESO BRUTO TOTAL -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputNumber readonly v-model="complemento.mercancia.pesoBrutoTotal" mode="decimal"
                        :minFractionDigits="2" locale="en-US"
                        v-tooltip.bottom="'En este campo se deberá registrar la suma del peso bruto de los bienes y/o mercancías que se trasladan.'" />
                    <label>Peso Bruto Total</label>
                </span>
            </div>
            <!-- UNIDAD DE PESO -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <AutoComplete readonly v-model="complemento.mercancia.unidadPeso" :suggestions="filteredUnidadPeso"
                        @complete="searchUnidadPeso($event)" field="claveUnidadPeso"
                        v-tooltip.bottom="'En este campo se deberá registrar la clave de la unidad de peso que corresponda al valor registrado en el campo “PesoBrutoTotal”.'">
                    </AutoComplete>
                    <label>Unidad de Peso</label>
                </span>
            </div>
            <!-- PESO BRUTO TOTAL -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputNumber readonly v-model="complemento.mercancia.numTotalMercancias" mode="decimal" locale="en-US"
                        v-tooltip.bottom="'En este campo se deberá registrar el número total de los bienes y/o mercancías que se trasladan, registrados en la sección “Mercancías a transportar”.'" />
                    <label>Número total de mercancías</label>
                </span>
            </div>
            <!-- MERCANCIAS A TRANSPORTAR -->
            <div class="field col-12 text-center mt-4">
                <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                    MERCANCÍAS A TRANSPORTAR
                </h2>
            </div>
            <!-- MERCANCIA  -->
            <div class="field col-12 md:col-12">
                <div class="p-inputgroup">
                    <Button icon="pi pi-refresh" @click="getMercancias()" />
                    <span class="p-float-label">
                        <AutoComplete v-model="selectMercancia" :suggestions="filteredMercancia"
                            @complete="searchMercancia($event)" field="mercancia"
                            v-tooltip.bottom="'En este campo se deberá registrar el bienes y/o mercancías que se trasladan vía Autotransporte, dado de alta previamente.'">
                        </AutoComplete>
                        <label>Mercancía</label>
                    </span>
                </div>
            </div>
            <!-- FRACCION ARANCELARIA SOLO EN CASO DE QUE SEA TRANSPORTE INTERNACIONAL -->
            <template v-if="complemento.transpInternac == 'SI'">
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <AutoComplete v-model="selectMercancia.fraccionArancelaria" :suggestions="filteredFraccion"
                            @complete="searchFraccion($event)" field="fraccion"
                            v-tooltip.bottom="'En este campo se deberá registrar la clave de la fracción arancelaria que corresponda con la descripción de los bienes y/o mercancías que se trasladan.'">
                        </AutoComplete>
                        <label>Fracción Arancelaria</label>
                    </span>
                </div>
            </template>
            <!-- UUID COMERCIO EXTERIOR EN CASO DE QUE SEA SALIDA DE MERCANCIAS -->
            <template v-if="complemento.transpInternac == 'SI' && complemento.entradaSalidaMerc == 'Salida'">
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <InputText v-model="selectMercancia.UUIDComercioExt"
                            v-tooltip.bottom="'Atributo opcional para expresar el folio fiscal (UUID) del comprobante de comercio exterior que se relaciona.'" />
                        <label>UUID Comercio Exterior</label>
                    </span>
                </div>
            </template>

            <!-- NUMERO DE PEDIMENTO PARA LA IMPORTACION DE MERCANCIA -->
            <template v-if="complemento.transpInternac == 'SI' && complemento.entradaSalidaMerc == 'Entrada'">
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <InputText v-model="selectMercancia.pedimento"
                            v-tooltip.bottom="'En este campo se deberá registrar el número de pedimento correspondiente a la importación del bien o mercancía.'" />
                        <label>Número de Pedimento</label>
                    </span>
                </div>
            </template>

            <!-- CANTIDAD -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="selectMercancia.cantidad" :minFractionDigits="3" mode="decimal" locale="en-US"
                        v-tooltip.bottom="'En este campo se deberá registrar la cantidad de bienes y/o mercancías que se trasladan'" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- DIMENSIONES -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputText v-model="selectMercancia.dimensiones"
                        v-tooltip.bottom="'En este campo se podrán registrar las medidas del empaque de los bienes y/o mercancías que se trasladan. Se debe registrar la longitud, la altura y la anchura en centímetros o en pulgadas separando dichos valores por una diagonal. Ejemplo:largo/alto/ancho(plg/cm)'" />
                    <label>Dimensiones</label>
                </span>
            </div>
            <!-- PESO EN KILOGRAMOS -->
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <InputNumber v-model="selectMercancia.pesoEnKg" :minFractionDigits="2" mode="decimal" locale="en-US"
                        v-tooltip.bottom="'En este campo se deberá registrar en kilogramos el peso estimado de los bienes y/o mercancías que se trasladan.'" />
                    <label>Peso en Kilogramos</label>
                </span>
            </div>
            <!-- VALOR DE LA MERCANCIA -->
            <div class="field col-12 md:col-3">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber v-model="selectMercancia.valorMercancia" :minFractionDigits="2" mode="decimal"
                            locale="en-US"
                            v-tooltip.bottom="'En este campo se podrá registrar el valor estimado de los bienes y/o mercancías que se trasladan.'" />
                        <label>Valor de la Mercancía</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushMercancia()" />
                </div>
            </div>
            <!-- TABLA DE MERCANCIAS -->
            <div class="field col-12">
                <DataTable :value="complemento.mercancias" class="mt-3">
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem', }"
                        frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editMercancia(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deleteMercancia(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="item" header="Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="mercancia" header="Mercancía" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="pesoEnKg" header="Peso en KG" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            {{ data.pesoEnKg + ' KG' }}
                        </template>
                    </Column>
                    <Column field="valorMercancia" header="Valor en Mercancía"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        <template #body="{ data }">
                            {{ formatCurrency(data.valorMercancia) }}
                        </template>
                    </Column>
                    <Column field="moneda.clave" header="Moneda" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column v-if="complemento.transpInternac == 'SI'" field="fraccionArancelaria.fraccion"
                        header="Fracción Arancelaria" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <template v-if="complemento.transpInternac == 'SI' && complemento.entradaSalidaMerc == 'Salida'">
                        <Column field="UUIDComercioExt" header="UUID Comercio Exterior"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                    </template>
                    <template v-if="complemento.transpInternac == 'SI' && complemento.entradaSalidaMerc == 'Entrada'">
                        <Column field="pedimento" header="Pedimento" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                        </Column>
                    </template>


                </DataTable>
            </div>
            <!-- AUTOTRANSPORTE -->
            <div class="field col-12 text-center mt-4">
                <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                    AUTOTRANSPORTE
                </h2>
            </div>
            <!-- AUTOTRANSPORTE -->
            <!-- <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="complemento.autotransporte"
                            :options="itemsAutotransporte" optionLabel="autotransporte" />
                        <label>Autotransporte</label>
                    </span>
                    <span> {{ complemento.autotransporte.autotransporte }}</span>
                </div> -->
            <div class="field col-12 md:col-12">
                <div class="p-inputgroup">
                    <Button icon="pi pi-refresh" @click="getAutotransporte()" />
                    <span class="p-float-label">
                        <AutoComplete v-model="complemento.autotransporte" :suggestions="filteredAutotransporte"
                            @complete="searchAutotransporte($event)" field="autotransporte">
                        </AutoComplete>
                        <label>Autotransporte</label>
                    </span>
                </div>
            </div>
            <!-- FIGURA DEL TRANSPORTE -->
            <div class="field col-12 text-center mt-4">
                <h2 style="background: #FF9100; color: white; font-size: 16px; font-family: arial;">
                    FIGURAS DEL TRASNPORTE
                </h2>
            </div>
            <!-- TIPO DE FIGURA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="selectTipoFigura" :options="itemsClaveTipoFiguraTransporte"
                        optionLabel="figuraTransporte" />
                    <label>Tipo de Figura</label>
                </span>
            </div>
            <!-- FIGURA DE TRANSPORTE -->
            <div class="field col-12 md:col-6">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="tipoFigura" :options="itemsTipoFiguraTransporte"
                            optionLabel="figuraTransporte" />
                        <label>Figura de Transporte</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushFigurasTransporte()" />
                </div>
            </div>
            <!-- TABLA DE FIGURAS DE TRANSPORTE -->
            <div class="field col-12">
                <DataTable :value="complemento.figuraDelTransporte" class="mt-3">
                    <Column field="rfcFigura" header="RFC" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column field="figuraTransporte" header="Figura" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                    </Column>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem', }"
                        frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="deleteFigura(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <!-- <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" /> -->
        <!-- </ScrollPanel> -->
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
    components: {},
    data() {
        return {
            itemsTranspInternac: ["SI", "NO"],
            itemsEntradaSalidaMerc: ["Entrada", "Salida"],
            destino: {
                ubicacion: '',
                distanciaRecorrida: 0,
                fecha: new Date(),
            },

            filteredUnidadPeso: null,
            itemsClaveUnidadDePeso: [],
            itemsViaEntradaSalida: [],
            itemsPais: [],

            filteredMercancia: null,
            selectMercancia: { mercancia: '', cantidad: 0.00, dimensiones: '', pesoEnKg: 0.00, valorMercancia: 0.00, pedimento: '', fraccionArancelaria: { fraccion: '' } },

            filteredFraccion: null,
            itemsFraccionArancelaria: [],

            selectTipoFigura: {},
            tipoFigura: {},
            itemsClaveTipoFiguraTransporte: [
                { clave: '01', descripcion: 'Operador', figuraTransporte: '01 | Operador' },
                { clave: '02', descripcion: 'Propietario', figuraTransporte: '02 | Propietario' },
                { clave: '03', descripcion: 'Arrendador', figuraTransporte: '03 | Arrendador' },
                { clave: '04', descripcion: 'Notificado', figuraTransporte: '04 | Notificado' },
            ],
            indexDestino: -1,
            filteredAutotransporte: null,
            filteredUbicacionOrigen: null,
            loadingOrigenes: true
        };
    },

    computed: {
        lastYearTotal() {
            let total = 0;
            for (let sale of this.sales) {
                total += sale.lastYearProfit;
            }

            return this.formatCurrency(total);
        },
        complemento() {
            // CALCULA LA DISTANCIA RECORRIDA TOTAL DE LOS DESTINOS
            let total = 0;
            for (let i of this.$store.state.ingresoCartaPorteStore.cartaPorte.ubicacionesDestino) {
                total += i.distanciaRecorrida;
            }
            this.$store.state.ingresoCartaPorteStore.cartaPorte.totalDistRec = total

            // CALCULA EL TOTAL DE PESO EN KILOGRAMOS DE LAS MERCANCIAS 
            let kg = 0;
            for (let i of this.$store.state.ingresoCartaPorteStore.cartaPorte.mercancias) {
                kg += i.pesoEnKg;
            }
            this.$store.state.ingresoCartaPorteStore.cartaPorte.mercancia.pesoBrutoTotal = kg

            // CUENTA EL NUMERO DE MERCANCIAS A TRANSPORTAR
            this.$store.state.ingresoCartaPorteStore.cartaPorte.mercancia.numTotalMercancias = this.$store.state.ingresoCartaPorteStore.cartaPorte.mercancias.length
            // this.$store.state.ingresoCartaPorteStore.cartaPorte.ubicacionOrigen.fecha = new Date()

            return this.$store.state.ingresoCartaPorteStore.cartaPorte;
        },
        itemsOrigenes() {
            return this.$store.state.listOrigenStore;
        },
        itemsDestinos() {
            return this.$store.state.listDestinoStore;
        },
        itemsMercancias() {
            return this.$store.state.listMercanciaStore
        },
        itemsAutotransporte() {
            return this.$store.state.listAutotransporteStore
        },
        itemsTipoFiguraTransporte() {
            return this.$store.state.listFigurasTransporteStore.filter(tipo => tipo.tipoFigura.clave === this.selectTipoFigura.clave);
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getOrigenes();
        this.getDestinos();
        this.getUnidadPeso();
        this.getMercancias();
        this.getAutotransporte();
        this.getFiguras();
        this.getTransporte();
        this.listarPaises()
        this.getFraccionArancelaria()
    },
    methods: {
        pushDestino() {
            console.log(this.destino)
            if (this.destino.iDUbicacion == '' || !this.destino.iDUbicacion) {
                this.$toast.add({ severity: 'info', detail: 'Seleccione un destino.', life: 3000 });
                return
            }
            this.destino.fecha = moment(this.destino.fecha).format('YYYY-MM-DD HH:mm:ss')

            const destino = { ...this.destino }

            this.complemento.ubicacionesDestino.push(destino)

            this.destino = {
                ubicacion: '',
                distanciaRecorrida: 0,
                fecha: new Date(),
            }
        },

        deleteDestino(data) {
            const index = this.complemento.ubicacionesDestino.indexOf(data)
            this.complemento.ubicacionesDestino.splice(index, 1)
        },

        pushMercancia() {
            const cantidad = Number(this.selectMercancia.cantidad);
            const pesoEnKg = Number(this.selectMercancia.pesoEnKg);
            const valorMercancia = Number(this.selectMercancia.valorMercancia);

            if (this.selectMercancia.mercancia == '' || !this.selectMercancia.mercancia) {
                this.$toast.add({ severity: 'info', detail: 'Indique una Mercancía.', life: 3000 });
                return
            }

            if (cantidad <= 0) {
                this.$toast.add({ severity: 'info', detail: 'La cantidad debe ser mayor a 0.', life: 3000 });
                return
            }

            if (pesoEnKg <= 0) {
                this.$toast.add({ severity: 'info', detail: 'El peso en KG debe ser mayor a 0,', life: 3000 });
                return
            }

            if (valorMercancia <= 0) {
                this.$toast.add({ severity: 'info', detail: 'El valor de la mercancía debe ser mayor a 0.', life: 3000 });
                return
            }

            const mercancia = Object.assign({}, this.selectMercancia)

            if (this.indexMercancia > -1) {
                Object.assign(this.complemento.mercancias[this.indexMercancia], mercancia)
            } else {
                this.complemento.mercancias.push(mercancia)
            }

            //INICIALIZAMOS EL OBJETO
            this.selectMercancia = Object.assign({})

            //ASIGNAMOS EL NUMERO DE ITEM
            let cont = 1
            for (let i of this.complemento.mercancias) {
                i.item = cont;
                cont++;
            }

            this.indexMercancia = -1
            this.selectMercancia = { mercancia: '', cantidad: 0.00, dimensiones: '', pesoEnKg: 0.00, valorMercancia: 0.00 },

                console.log(this.selectMercancia)
        },

        editMercancia(item) {
            this.indexMercancia = this.complemento.mercancias.indexOf(item)
            this.selectMercancia = Object.assign({}, item)
            this.dialogMercancias = true
        },

        deleteMercancia(item) {
            const index = this.complemento.mercancias.indexOf(item)
            this.complemento.mercancias.splice(index, 1)
            //ORDENAMOS LOS DETINOS
            let cont = 1
            for (let i of this.complemento.mercancias) {
                i.item = cont;
                cont++;
            }
        },

        pushFigurasTransporte() {
            console.log(this.tipoFigura)
            this.complemento.figuraDelTransporte.push(this.tipoFigura)

            //METODO PARA EVITAR QUE SE AGREGUEN DUPLICADOS
            var arrTwo = this.complemento.figuraDelTransporte
            const filteredArray = arrTwo.filter(function (ele, pos) {
                return arrTwo.indexOf(ele) == pos;
            })
            this.complemento.figuraDelTransporte = filteredArray
        },

        deleteFigura(item) {
            let inidce = this.complemento.figuraDelTransporte.indexOf(item)
            this.complemento.figuraDelTransporte.splice(inidce, 1)
        },

        async getOrigenes() {
            try {
                let response = await axios.get(
                    "CatalogosCartaPorte/GetUbicaciones/" +
                    this.empresa.empresaBase +
                    "/" +
                    "OR"
                );
                this.$store.state.listOrigenStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getDestinos() {
            try {
                let response = await axios.get(
                    "CatalogosCartaPorte/GetUbicaciones/" +
                    this.empresa.empresaBase +
                    "/" +
                    "DE"
                );
                this.$store.state.listDestinoStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async getMercancias() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetMercancias/' + this.empresa.empresaBase)
                this.$store.state.listMercanciaStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async getAutotransporte() {

            try {
                let response = await axios.get('CatalogosCartaPorte/GetAutotransporte/' + this.empresa.empresaBase)
                this.$store.state.listAutotransporteStore = response.data
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        },
        async getFiguras() {
            try {
                let response = await axios.get('CatalogosCartaPorte/GetFiguras/' + this.empresa.empresaBase)
                this.$store.state.listFigurasTransporteStore = response.data

            } catch (error) {
                console.log(error)
            }
        },

        async getUnidadPeso() {
            try {
                let response = await axios.get(
                    "CatalogosSatCartaPorte/GetClaveUnidadPeso/"
                );
                this.itemsClaveUnidadDePeso = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async listarPaises() {
            try {
                let response = await axios.get('CatalogosSatCartaPorte/GetPaises');
                this.itemsPais = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async getTransporte() {
            try {
                let response = await axios.get(
                    "CatalogosSatCartaPorte/GetTransporte/"
                );
                this.itemsViaEntradaSalida = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async getFraccionArancelaria() {
            try {
                let response = await axios.get(
                    "CatalogosSatCartaPorte/GetFraccion/"
                );
                this.itemsFraccionArancelaria = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        searchUnidadPeso(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUnidadPeso = [...this.itemsClaveUnidadDePeso];
                } else {
                    this.filteredUnidadPeso = this.itemsClaveUnidadDePeso.filter(
                        (country) => {
                            return country.claveUnidadPeso
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        searchMercancia(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredMercancia = [...this.itemsMercancias];
                } else {
                    this.filteredMercancia = this.itemsMercancias.filter(
                        (country) => {
                            return country.mercancia
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        searchFraccion(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredFraccion = [...this.itemsFraccionArancelaria];
                } else {
                    this.filteredFraccion = this.itemsFraccionArancelaria.filter(
                        (country) => {
                            return country.fraccion
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        searchAutotransporte(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredAutotransporte = [...this.itemsAutotransporte];
                } else {
                    this.filteredAutotransporte = this.itemsAutotransporte.filter(
                        (country) => {
                            return country.autotransporte
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        searchUbicacionOrigen(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredUbicacionOrigen = [...this.itemsOrigenes];
                } else {
                    this.filteredUbicacionOrigen = this.itemsOrigenes.filter(
                        (country) => {
                            return country.ubicacion
                                .toLowerCase()
                                .includes(event.query.toLowerCase());
                        }
                    );
                }
            }, 250);
        },

        formatCurrency(value) {
            return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD HH:mm");
        },
        validaObjeto(obj) {
            return Object.keys(obj).length === 0;
        },

    },
};
</script>