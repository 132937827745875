<template>
    <div>
        <ContextMenu ref="menu" :model="itemsTablaConceptos" />

        <!-- EXCEL -->
        <Dialog :visible.sync="dialogExcel" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <Card>
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="cerraExcel()" />
                        </div>
                        <div class="text-center field col-4">
                            Cargar Excel
                        </div>
                        <div class="text-right field col-4">
                            <Button icon="pi pi-file-pdf"
                                class="ml-3 mr-1 p-button-rounded p-button-success p-button-outlined"
                                @click="cargarExcel" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <FileUpload name="demo[]" :customUpload="true" @uploader="cargarExcel" accept=".xlsx"
                        :maxFileSize="5242880">
                        <template #empty>
                            <p>Arrastre y suelte para cargar</p>
                        </template>
                    </FileUpload>
                </template>
            </Card>
        </Dialog>

        <Dialog :visible.sync="dialogImagen" :closeOnEscape="true" :closable="true" :containerStyle="{ width: '600px' }"
            :modal="true">
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <div class="field col-12 md:col-12" height="400px">
                    <ImagePreview :src="imagenAinsertar" alt="Image" width="400px" preview />
                </div>
                <div class="fiel justify-right col-12 md:col-12">
                    <div class="justify-right text-right">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            @click="eliminarImagenInsert()"></Button>
                        <Button icon="pi pi-check" class="p-button-rounded p-button-succes"
                            @click="cargarImagenInsert()"></Button>
                    </div>
                </div>
                <div class="field col-12 md:col-12">
                    <FileUpload name="demo[]" :customUpload="true" @uploader="cargarImage" accept="image/*"
                        :maxFileSize="5242880">
                        <template #empty>
                            <p>Arrastre y suelte para cargar</p>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </Dialog>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- CLAVE PROD SERV -->
            <template v-if="editedIndex === -1">
                <div class=" field col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" v-model="conceptoSelect" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" field="concepto" @item-select="asignaConcepto">
                        </AutoComplete>
                        <label>Buscar por clave o Descripción</label>
                    </span>
                </div>
            </template>
            <!-- NO DE INDENTIFICACION -->
            <div class="justify-right field col-12">
                <span class="p-float-label">
                    <InputText style="text-align: left" type="text" v-model="concepto.noIdentificacion" />
                    <label>No. Identificación</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <div class="field col-12">
                <!-- <span class="p-float-label">
                    <InputText type="text" v-model="concepto.descripcion" />
                    <label>Descripción</label>
                </span> -->
                <span>Descripción</span>
                <Editor class="editor editor1" style="border:  1px solid black; font-size: 15px;"
                    v-model="concepto.descripcion">
                    <template hidden #toolbar>
                        <span style="font-size: 15px;" hidden class="ql-formats">
                        </span>
                    </template>
                </Editor>
            </div>
            <!-- MATERIAL -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.material" />
                    <label>Material</label>
                </span>
            </div>
            <!-- CANTIDAD -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <span class="p-float-label">
                    <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                    <label>Cantidad</label>
                </span>
            </div>
            <!-- UNIDAD -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <span class="p-float-label">
                    <InputText type="text" v-model="concepto.unidad" />
                    <label>Unidad</label>
                </span>
            </div>
            <!-- VALOR UNITARIO -->
            <div class="justify-right field col-12 md:col-4 sm:col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <InputNumber v-model="concepto.valorUnitario" mode="decimal" locale="en-US"
                            :minFractionDigits="6" />
                        <label>Valor unitario</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                </div>
            </div>
            <!-- IVA 16% DEFAULT -->
            <div class="field col-12 md:col-2">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <Checkbox v-model="iva16" :binary="true" />
                    </span>
                    <InputText value="IVA 16%" readonly />
                </div>
            </div>
            <template v-if="iva16 == false">

                <!-- OBJETO DE IMPUESTOS -->
                <div class="justify-left field col-10">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp"
                            optionLabel="objImp" />
                        <label>Objeto de impuestos(SAT)</label>
                    </span>
                </div>
                <template v-if="concepto.objImp.clave === '02'">
                    <!-- TRASLADADOS -->
                    <div class="field md:col-6 sm:col-12">
                        <h1>Traslados</h1>
                        <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                            :selection.sync="impuestos.traslados">
                            <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                            <Column field="traslado" header="Impuesto"></Column>
                            <Column field="tipoFactor" header="Tipo Factor"></Column>
                            <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                        </DataTable>
                    </div>
                    <!-- RETENIDOS -->
                    <div class="field md:col-6 sm:col-12">
                        <h1>Retenciones</h1>
                        <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                            :selection.sync="impuestos.retenciones">
                            <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                            <Column field="retencion" header="Impuesto"></Column>
                            <Column field="tipoFactor" header="Tipo Factor"></Column>
                            <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                        </DataTable>
                    </div>
                </template>
            </template>

            <div class="field md:col-12 col-12 sm:col-12">
                <div class="field-checkbox text-right">
                    <InputSwitch class="mr-2" id="city1" v-model="validarImagenItem" />
                    <h1 style=";  font-size: 15px; font-family: arial;display:inline;">
                        CARGAR IMAGEN
                    </h1>
                </div>
            </div>
            <template v-if="validarImagenItem == true">
                <div class="field col-6 md:col-6">
                    <FileUpload name="demo[]" :customUpload="true" @uploader="uploadImage" accept="image/*"
                        :maxFileSize="5242880">
                        <template #empty>
                            <p>Arrastre y suelte para cargar</p>
                        </template>
                    </FileUpload>
                </div>
                <div class="field col-6 md:col-6">
                    <ImagePreview :src="concepto.imagen.base64" alt="Image" height="200" preview />
                </div>
                <div class="fiel justify-right col-12 md:col-12">
                    <div class="justify-right text-right">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            @click="eliminarImagen()"></Button>
                    </div>
                </div>
            </template>
        </div>

        <!-- TABLA DE CONCEPTOS -->
        <!-- <div class="p-d-flex justify-center p-fluid grid formgrid col-12"> -->
        <DataTable :value="ventaCotizacion.conceptos" :scrollable="true" scrollHeight="500px" scrollDirection="both"
            editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table" :contextMenu="true"
            :contextMenuSelection.sync="selectConcepto" @row-contextmenu="onRowContextMenu" responsiveLayout="scroll">
            <template #header>
                <div class="table-header">
                    Conceptos
                    <Button icon="pi pi-file-excel"
                        class="ml-3 mr-1 p-button-rounded p-button-success  p-button-outlined" @click="abrirExcel"
                        v-tooltip.bottom="'Cargar Excel'" />
                </div>
            </template>
            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }"
                frozen>
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="editConcepto(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                        @click="deleteConcepto(slotProps.data)" />
                    <!-- <Button v-if="slotProps.data.imagen.base64 != ''" icon="pi pi-image"
                        class="p-button-rounded p-button-info" @click="verImagen(slotProps.data)"></Button> -->
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="noIdentificacion" header="No. Indentificación"
                :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.noIdentificacion" autofocus />
                </template>

            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '300px' }">
                <!-- <template #editor="slotProps">
                    <InputText v-model="slotProps.data.descripcion" autofocus />
                </template> -->
                <template #body="{ data }">
                    <Editor readonly :value="data.descripcion" class="editor" style="font-size: 15px;">
                        <template hidden #toolbar>
                            <span hidden class="ql-formats">
                            </span>
                        </template>
                    </Editor>
                </template>
            </Column>
            <Column header="Imagen" :styles="{ 'flex-grow': '1', 'flex-basis': '150px' }">
                <template #body="slotProps">
                    <ImagePreview v-if="slotProps.data.imagen.base64 != ''" :src="slotProps.data.imagen.base64" w
                        alt="Image" width="100px" heigth="100px" preview />
                </template>
            </Column>
            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                </template>
            </Column>
            <Column field="unidad" header="Unidad" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
            </Column>
            <Column field="valorUnitario" header="Valor Unitario" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                </template>
            </Column>
            <Column field="importe" header="Importe" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
            <Column field="material" header="Material" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data.material" autofocus />
                </template>
            </Column>
            <Column field="objImp" header="Obj Impuestos" :styles="{ 'flex-grow': '1', 'flex-basis': '100px' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.objImp.objImp) }}</span>
                </template>
            </Column>
        </DataTable>
        <!-- </div> -->
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';
import PrimeVue from 'primevue/config';
import { mapState } from 'vuex'
import readXlsFile from 'read-excel-file'

export default {
    components: {

    },
    data() {
        return {
            imagenAinsertar: '',
            selectConcepto: null,
            itemsTablaConceptos: [
                { label: 'Asignar Imagen', icon: 'pi pi-fw pi-image', command: () => this.AbrirInsertarImagen(this.selectConcepto) },
            ],
            conceptoDefault: {
                idDocumento: 0,
                idConcepto: 0,
                claveProdServ: {
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                precio: 0,
                total: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                numeroCuentaPredial: '',
                material: '',
                imagen: {
                    base64: '',
                },
                linea: '',
                marca: '',
                submarca: '',
                iepsGas: 0,
                almacenable: 'NO',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            },
            filteredConcepto: null,
            concepto: {
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                valorUnitario: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' },
                numeroCuentaPredial: '',
            },
            conceptoSelect: null,
            filtrosConceptos: {
                "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
                "claveProdServ.clave": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "noIdentificacion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "claveUnidad.clave": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "unidad": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                "descripcion": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            editedIndex: -1,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
            dialogExcel: false,
            validarImagenItem: false,
            imagenItem: '',
            dialogImagen: false,

            iva16: true
        }
    },
    computed: {
        listaConceptos() {
            return this.$store.state.listaConceptosStore
        },
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        empresa() {
            return this.$store.state.usuario;
        },
        ventaCotizacion() {
            return this.$store.state.ventaCotizacionStore;
        },
        objImp() {
            return this.$store.state.objImpStore
        },

    },
    created() {
        this.validarImagen();
    },
    methods: {
        validarImagen() {
            if (this.concepto.imagen.base64 == '') {
                this.validarImagenItem = false
            } else {
                this.validarImagenItem = true
            }
        },

        async uploadImage(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.concepto.imagen.base64 = result;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            data[field] = newValue;
            console.log(field)
        },

        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.concepto.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        asignaConcepto() {
            this.concepto = { ...this.conceptoSelect }
            this.impuestos = { ...this.conceptoSelect.impuestos }
            this.validarImagen();
        },

        pushConcepto() {
            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }

            var objetoImpuesto02 = {
                id: 4,
                clave: "02",
                descripcion: "Sí objeto de impuesto.",
                objImp: "02 | Sí objeto de impuesto."
            }

            var impuestos16Default = {
                retenciones: [],
                traslados: [
                    {
                        idImpuesto: 9,
                        folioFiscal: null,
                        base_: 0,
                        impuesto: "002",
                        tipoFactor: "Tasa",
                        tasaOCuota: 0.16,
                        importe: 0,
                        traslado: "IVA(16.000000%)"
                    }
                ],
            }


            if (this.iva16 == true) {
                this.concepto.objImp = objetoImpuesto02
                this.impuestos = impuestos16Default
            }


            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)
            if (this.editedIndex === -1) {
                this.ventaCotizacion.conceptos.push(objConcepto);

            } else {
                Object.assign(this.ventaCotizacion.conceptos[this.editedIndex], objConcepto)
            }

            // if (objConcepto.idConcepto != 0) {
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            // }
            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.editedIndex = -1;
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
        },

        deleteConcepto(item) {
            let indice = this.ventaCotizacion.conceptos.indexOf(item);
            this.ventaCotizacion.conceptos.splice(indice, 1);
            this.calcularImpuestos();
            this.sumatoria();
        },

        editConcepto(item) {
            this.conceptoSelect = null;
            this.impuestos = { ...item.impuestos }

            this.editedIndex = this.ventaCotizacion.conceptos.indexOf(item);
            this.concepto = { ...item }
            this.validarImagen();
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        calcularImpuestos() {
            this.$store.state.ventaCotizacionStore.impuestos.traslados = [];
            this.$store.state.ventaCotizacionStore.impuestos.retenciones = [];

            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.ventaCotizacion.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {

                for (let t of a.impuestos.traslados) {
                    // t.base_ = a.importe
                    // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                    sumaT = sumaT + t.importe
                    sumaT = Math.round(sumaT * 100) / 100
                    //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                    let im = { ...t }
                    listTrasladados.push(im);
                    im = {}
                }

                for (let r of a.impuestos.retenciones) {
                    // r.base_ = a.impo  rte
                    // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                    sumaR = sumaR + r.importe
                    sumaR = Math.round(sumaR * 100) / 100
                    //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                    let im = { ...r }
                    listRetenidos.push(im);
                    im = {}
                }


                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.ventaCotizacionStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.ventaCotizacionStore.impuestos.traslados.push(tra)
                }
                this.$store.state.ventaCotizacionStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.ventaCotizacionStore.impuestos.retenciones.push(ret)
                }
            }


        },

        sumatoria() {
            let indice = 1;
            for (let x of this.ventaCotizacion.conceptos) {
                x.item = indice;
                indice++;
            }
            let tras = Math.round(this.sum(this.$store.state.ventaCotizacionStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.ventaCotizacionStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.ventaCotizacion.subTotal = Math.round(this.sum(this.ventaCotizacion.conceptos, 'importe') * 100) / 100;
            this.ventaCotizacion.total = this.ventaCotizacion.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.tipo };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },

        //CARGA DESDE EXCEL
        abrirExcel() {
            this.dialogExcel = true;
        },

        cerraExcel() {
            this.dialogExcel = false;
        },

        cargarExcel(event) {
            this.ventaCotizacion.conceptos = [];
            let itemsExcel = []
            const file = event.files[0];
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                for (var cont = 1; cont < num; cont++) {
                    try {
                        this.concepto.noIdentificacion = rows[cont]['0']
                        this.concepto.descripcion = rows[cont]['1']
                        this.concepto.cantidad = rows[cont]['2']
                        this.concepto.unidad = rows[cont]['3']
                        this.concepto.valorUnitario = rows[cont]['4']
                        this.concepto.material = rows[cont]['6']

                        //CASO PARA LOS IMPUESTOS
                        let conImpuestos = { "id": 4, "clave": "02", "descripcion": "Sí objeto de impuesto.", "objImp": "02 | Sí objeto de impuesto." };
                        let sinImpuestos = { "id": 3, "clave": "01", "descripcion": "No objeto de impuesto.", "objImp": "01 | No objeto de impuesto." };
                        let impuestoIva = { "traslados": [{ "base_": 0, "importe": 0, "impuesto": "002", "traslado": "IVA(16.000000%)", "idImpuesto": 9, "tasaOCuota": 0.16, "tipoFactor": "Tasa", "folioFiscal": null }], "retenciones": [] };

                        let llevaImpuestos = rows[cont]['7']
                        if (llevaImpuestos === 'SI') {
                            this.concepto.objImp = { ...conImpuestos }
                            this.impuestos = { ...impuestoIva }
                        } else {
                            this.concepto.objImp = { ...sinImpuestos }
                        }
                        this.pushConcepto();
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },

        verImagen(item) {
            console.log(item)
            this.imagenItem = item.imagen.base64
            this.dialogImagen = true
        },

        eliminarImagen() {
            this.concepto.imagen.base64 = ''
        },

        onRowContextMenu(event) {
            this.$refs.menu.show(event.originalEvent);
        },

        AbrirInsertarImagen(item) {
            console.log(item)
            this.editedIndex = this.ventaCotizacion.conceptos.indexOf(item);
            this.dialogImagen = true
            this.imagenAinsertar = item.imagen.base64
        },

        eliminarImagenInsert() {
            this.imagenAinsertar = ''
            this.$store.state.ventaCotizacionStore.conceptos[this.editedIndex].imagen.base64 = this.imagenAinsertar

        },

        cargarImagenInsert() {
            this.$store.state.ventaCotizacionStore.conceptos[this.editedIndex].imagen.base64 = this.imagenAinsertar
            this.dialogImagen = false
        },

        async cargarImage(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.imagenAinsertar = result;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
    },
}
</script>
<style>
.editor1 .ql-editor {
    font-size: 15px;
}
</style>