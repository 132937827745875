var numeroALetrasIngles = (function () {
    // Código basado en el comentario de @sapienman
    // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'ONE';
            case 2:
                return 'TWO';
            case 3:
                return 'TRHEE';
            case 4:
                return 'FOUR';
            case 5:
                return 'FIVE';
            case 6:
                return 'SIX';
            case 7:
                return 'SEVEN';
            case 8:
                return 'EIGHT';
            case 9:
                return 'NINE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'TEN';
                    case 1:
                        return 'ELEVEN';
                    case 2:
                        return 'TWELVE';
                    case 3:
                        return 'THIRTEEN';
                    case 4:
                        return 'FOURTEEN';
                    case 5:
                        return 'FIFTEEN';
                    default:
                        return Unidades(unidad) + 'TEEN';
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'TWENTY';
                    default:
                        return 'TWENTY' + Unidades(unidad);
                }
            case 3:
                return DecenasY('THIRTY', unidad);
            case 4:
                return DecenasY('FORTY', unidad);
            case 5:
                return DecenasY('FIFTY', unidad);
            case 6:
                return DecenasY('SIXTY', unidad);
            case 7:
                return DecenasY('SEVENTY', unidad);
            case 8:
                return DecenasY('EIGHTY', unidad);
            case 9:
                return DecenasY('NINETY', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'ONE HUNDRED ' + Decenas(decenas);
                return 'HUNDRED';
            case 2:
                return 'TWO HUNDRED ' + Decenas(decenas);
            case 3:
                return 'THREE HUNDRED ' + Decenas(decenas);
            case 4:
                return 'FOUR HUNDRED ' + Decenas(decenas);
            case 5:
                return 'FIVE HUNDRED ' + Decenas(decenas);
            case 6:
                return 'SIX HUNDRED ' + Decenas(decenas);
            case 7:
                return 'SEVEN HUNDRED ' + Decenas(decenas);
            case 8:
                return 'EIGHT HUNDRED ' + Decenas(decenas);
            case 9:
                return 'NINE HUNDRED ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = Seccion(num, divisor, 'THOUSAND', 'THOUSAND');
        let strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = Seccion(num, divisor, 'A MILLION OF', 'MILLIONS OF');
        let strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    } //Millones()

    return function NumeroALetras(num, currency) {
        currency = currency || {};
        let data = {};

        switch (currency) {
            case 'MXN':
                let pesos = {
                    numero: num,
                    enteros: Math.floor(num),
                    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
                    letrasCentavos: '',
                    letrasMonedaPlural: currency.plural || 'PESOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
                    letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
                    letrasMonedaCentavoPlural: currency.centPlural || 'Centavos',
                    letrasMonedaCentavoSingular: currency.centSingular || 'Centavos',
                    monedaS: currency.moneda || 'M.N.'
                };
                data = { ...pesos }
                break;
            case 'USD':
                let dolares = {
                    numero: num,
                    enteros: Math.floor(num),
                    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
                    letrasCentavos: '',
                    letrasMonedaPlural: currency.plural || 'DOLLARS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
                    letrasMonedaSingular: currency.singular || 'DOLLAR', //'PESO', 'Dólar', 'Bolivar', 'etc'
                    letrasMonedaCentavoPlural: currency.centPlural || 'Cents',
                    letrasMonedaCentavoSingular: currency.centSingular || 'Penny',
                    monedaS: currency.moneda || 'USD'
                };
                data = { ...dolares }
                break;
            case 'EUR':
                let euros = {
                    numero: num,
                    enteros: Math.floor(num),
                    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
                    letrasCentavos: '',
                    letrasMonedaPlural: currency.plural || 'EUROS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
                    letrasMonedaSingular: currency.singular || 'EURO', //'PESO', 'Dólar', 'Bolivar', 'etc'
                    letrasMonedaCentavoPlural: currency.centPlural || 'Centavos',
                    letrasMonedaCentavoSingular: currency.centSingular || 'Centavos',
                    monedaS: currency.moneda || 'EUR'
                };
                data = { ...euros }
                break;
        }

        if (data.centavos >= 0) {
            data.letrasCentavos = 'WITH ' + (function () {
                if (data.centavos == 0)
                    return '00/100 ' + data.monedaS;
                else
                    return data.centavos + '/100 ' + data.monedaS;
            })();
        };

        if (data.enteros == 0)
            return 'ZERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        if (data.enteros == 1)
            return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
        else
            return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    };

})();
module.exports = {
    numeroALetrasIngles,
}