<template>
    <div>
        <!-- PLANTILLA CONCEPTOS -->
        <DataTable hidden ref="dt" :value="plantillaItems" responsiveLayout="scroll">
        </DataTable>

        <!-- PANTILLA PRECIOS -->
        <DataTable hidden ref="precios" :value="plantillaPreciosItems" responsiveLayout="scroll">
        </DataTable>

        <!-- ASIGNA CLAVE SAT -->
        <Dialog :visible.sync="dialogAsignaImpuestos" :closable="false" :containerStyle="{ width: '1200px' }" :modal="true">
            <Card>
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="dialogAsignaImpuestos = false" v-tooltip.bottom="'Cerrar'" />
                        </div>
                        <div class="text-center field col-4">
                            <div>Asignar Impuestos</div>
                        </div>
                        <div class="text-right field col-4">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                                @click="confirmaImpuestos()" v-tooltip.bottom="'Asignar'" />
                        </div>
                    </div>
                    <hr size=7 color="#ED6421">
                </template>
                <template #content>
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <!-- OBJETO DE IMPUESTOS -->
                        <div class="justify-left field col-12">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="objetoImpuestos" :options="objImp"
                                    optionLabel="objImp" />
                                <label>Objeto de impuestos(SAT)</label>
                            </span>
                        </div>
                        <template v-if="objetoImpuestos.clave === '02'">
                            <!-- TRASLADADOS -->
                            <div class="field md:col-6 sm:col-12">
                                <h1>Traslados</h1>
                                <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                                    :selection.sync="impuestos.traslados">
                                    <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                    <Column field="traslado" header="Impuesto"></Column>
                                    <Column field="tipoFactor" header="Tipo Factor"></Column>
                                    <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                                </DataTable>
                            </div>
                            <!-- RETENIDOS -->
                            <div class="field md:col-6 sm:col-12">
                                <h1>Retenciones</h1>
                                <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                                    :selection.sync="impuestos.retenciones">
                                    <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                                    <Column field="retencion" header="Impuesto"></Column>
                                    <Column field="tipoFactor" header="Tipo Factor"></Column>
                                    <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                                </DataTable>
                            </div>
                        </template>
                    </div>
                </template>
            </Card>
        </Dialog>

        <!-- CARGAR EXCEL -->
        <Dialog :visible.sync="dialogExcel" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraExcel()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR EXCEL</h3>
                </div>
            </div>
            <hr size=7 color="#ED6421">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarExcel" accept=".xlsx" :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
            <p style="cursor: pointer; border-bottom: 2px solid #88b2ff;" @click="exportCSV($event)">Descargar Plantilla</p>
        </Dialog>

        <!-- CARGAR EXCEL PRECIOS -->
        <Dialog :visible.sync="dialogExcelPrecios" :closable="false" :containerStyle="{ width: '600px' }" :modal="true">
            <div class="p-d-flex p-fluid grid formgrid">
                <div class="text-left field col-4">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cerraExcelPrecios()" />
                </div>
                <div class="text-center field col-4">
                    <h3>CARGAR PRECIOS</h3>
                </div>
            </div>
            <hr size=7 color="#ED6421">
            <FileUpload name="demo[]" :customUpload="true" @uploader="cargarExcelPrecios" accept=".xlsx"
                :maxFileSize="5242880">
                <template #empty>
                    <p>Arrastre y suelte para cargar</p>
                </template>
            </FileUpload>
            <p style="cursor: pointer; border-bottom: 2px solid #88b2ff;" @click="exportCSVPlantillaPrecios($event)">
                Descargar Plantilla</p>
        </Dialog>

        <template v-if="cotizacion.idRelacion == 0">
            <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                <!-- MEDIDA -->
                <div class="justify-right field col-12">
                    <span class="p-float-label">
                        <AutoComplete v-model="concepto.descripcion" :suggestions="filteredConcepto"
                            @complete="searchConcepto($event)" />
                        <label>Descripción</label>
                    </span>
                </div>
                <div class="justify-right field col-6">
                    <span class="p-float-label">
                        <InputText style="text-align: left" type="text" v-model="concepto.medida" />
                        <label>Medida</label>
                    </span>
                </div>
                <div class="justify-right field col-6">
                    <span class="p-float-label">
                        <InputNumber v-model="concepto.cantidad" mode="decimal" locale="en-US" :minFractionDigits="3" />
                        <label>Cantidad</label>
                    </span>
                </div>
                <!-- DETALLE -->
                <div class="justify-right field col-12 ">
                    <span class="p-float-label">
                        <InputText style="text-align: left" type="text" v-model="concepto.detalle" />
                        <label>Detalle</label>
                    </span>
                </div>
                <!-- OBJETO DE IMPUESTOS -->
                <div class="justify-left field col-8 mb-0">
                    <span class="p-float-label">
                        <Dropdown style="text-align: left" v-model="concepto.objImp" :options="objImp"
                            optionLabel="objImp" />
                        <label>Objeto de impuestos(SAT)</label>
                    </span>
                </div>
                <!-- VALOR UNITARIO -->
                <div class="justify-right field col-12 md:col-4 sm:col-12 mb-0">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber v-model="concepto.precio" mode="decimal" locale="en-US" :minFractionDigits="6" />
                            <label>Precio</label>
                        </span>
                        <Button icon="pi pi-plus" class="p-button-success" @click="pushConcepto" />
                    </div>
                </div>
                <template v-if="concepto.objImp.clave === '02'">
                    <!-- TRASLADADOS -->
                    <div class="field md:col-6 sm:col-12">
                        <h1>Traslados</h1>
                        <DataTable :value="traslados" responsiveLayout="scroll" dataKey="idImpuesto"
                            :selection.sync="impuestos.traslados">
                            <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                            <Column field="traslado" header="Impuesto"></Column>
                            <Column field="tipoFactor" header="Tipo Factor"></Column>
                            <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                        </DataTable>
                    </div>
                    <!-- RETENIDOS -->
                    <div class="field md:col-6 sm:col-12">
                        <h1>Retenciones</h1>
                        <DataTable :value="retenciones" responsiveLayout="scroll" dataKey="idImpuesto"
                            :selection.sync="impuestos.retenciones">
                            <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
                            <Column field="retencion" header="Impuesto"></Column>
                            <Column field="tipoFactor" header="Tipo Factor"></Column>
                            <Column field="tasaOCuota" header="Tasa O Cuota"></Column>
                        </DataTable>
                    </div>
                </template>
            </div>
            <div class="field col-12 text-center mb-0 pb-0">
                <h1 class="mb-0 pb-0" style="background: #FF9100;color: white;font-size: 14px;font-family: arial;">
                    CONCEPTOS
                </h1>
            </div>
        </template>
        <span hidden> {{ cotizacion.estatus }}</span>

        <DataTable editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table mt-0"
            :filters.sync="filtrosConceptos" :value="cotizacion.conceptos" :scrollable="true" scrollHeight="300px"
            scrollDirection="both" :selection.sync="selectConceptosEdit" @row-contextmenu="onRowContextMenu" dataKey="item">
            <template #header>
                <div class="flex justify-content-between">
                    <div class="justify-left">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filtrosConceptos['global'].value" placeholder="Buscar" />
                        </span>
                    </div>
                    <div class="justify-left">
                        <template v-if="cotizacion.idRelacion == 0">
                            <Button label="Importar Excel" icon="pi pi-upload" class="p-button-info"
                                @click="abrirExcel()" />
                        </template>
                        <template v-if="cotizacion.idRelacion != 0 && cotizacion.estatus == 'Cotizada'">
                            <Button label="Cargar Precios" icon="pi pi-upload" class="p-button-success"
                                @click="abrirExcelPrecios()" />
                        </template>
                    </div>
                </div>

            </template>
            <template v-if="cotizacion.estatus == 'Cotizada'">

                <Column selectionMode="multiple" :headerStyle="{ 'width': '3em' }"></Column>
            </template>

            <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '10px' }">
                <template #body="slotProps">
                    <template v-if="cotizacion.idRelacion == 0">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                            @click="editConcepto(slotProps.data)" />
                    </template>
                    <Button icon="pi pi-minus" class="p-button-rounded p-button-danger"
                        @click="deleteConcepto(slotProps.data)" />
                </template>
            </Column>
            <Column field="item" header="No. Item" :styles="{ 'flex-grow': '1', 'flex-basis': '20px' }">
            </Column>
            <Column field="descripcion" header="Descripción" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
            </Column>
            <Column field="medida" header="Medida" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
            </Column>

            <template v-if="cotizacion.estatus != 'Nuevo'">
                <Column field="valorUnitario" header="Valor Unitario" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.valorUnitario) }}</span>
                    </template>
                    <template #editor="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.field]" mode="decimal" locale="en-US"
                            :minFractionDigits="3" autofocus />
                    </template>
                </Column>
                <Column field="precio" header="Precio" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.precio) }}</span>
                    </template>
                    <template #editor="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.field]" mode="decimal" locale="en-US"
                            :minFractionDigits="3" autofocus />
                    </template>
                </Column>
            </template>

            <Column field="cantidad" header="Cantidad" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                <template #body="{ data }">
                    <span>{{ formatoCantidad(data.cantidad) }}</span>
                </template>
                <template #editor="slotProps">
                    <InputNumber v-model="slotProps.data[slotProps.column.field]" mode="decimal" locale="en-US"
                        :minFractionDigits="3" autofocus />
                </template>
            </Column>

            <template v-if="cotizacion.estatus != 'Nuevo'">
                <Column field="detalle" header="Detalle" :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
                </Column>

                <Column field="importe" header="Importe" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.importe) }}</span>
                    </template>
                </Column>
                <Column field="total" header="Precio Total" :styles="{ 'flex-grow': '1', 'flex-basis': '50px' }">
                    <template #body="{ data }">
                        <span>{{ formatCurrency(data.total) }}</span>
                    </template>
                </Column>
                <Column field="objImp.objImp" header="Objeto de Impuestos"
                    :styles="{ 'flex-grow': '1', 'flex-basis': '200px' }">
                </Column>
            </template>
        </DataTable>
        <!-- MENU PARA ASIGNAR CLAVES DE MANERA MASIVA -->
        <ContextMenu :model="menuModel" ref="cm" />
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import readXlsFile from 'read-excel-file'
import * as XLSX from 'xlsx/xlsx.js'


export default {
    components: {
    },

    data() {
        return {
            filteredConcepto: null,
            editedIndex: -1,
            concepto: {
                item: 0,
                descripcion: '',
                medida: '',
                cantidad: 0,
                detalle: '',
                objImp: {
                    objImp: ''
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                importe: 0,
                total: 0
            },
            conceptoDefault: {
                item: 0,
                descripcion: '',
                medida: '',
                cantidad: 0,
                detalle: '',
                objImp: {
                    objImp: ''
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                importe: 0,
                total: 0

            },
            filtrosConceptos: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            dialogExcel: false,
            selectConceptosEdit: null,
            menuModel: [
                { label: 'Asignar Impuestos', icon: 'pi pi-fw pi-bookmark', command: () => this.asignarImpuestos(this.selectConceptosEdit) },
            ],
            objetoImpuestos: {},
            dialogAsignaImpuestos: false,
            impuestos: {
                traslados: [],
                retenciones: [],
            },

            dialogExcelPrecios: false,

            plantillaItems: [{ Descripcion: '', UnidadMedida: '', Cantidad: 0, Detalle: '', ObjetoImpuestos: 'SI/NO', Precio: 0 }],
            plantillaPreciosItems: [{ Item: 0, Precio: 0, ValorUnitario: 0 }]
        }
    },
    computed: {
        traslados() {
            return this.$store.state.trasladosStore
        },
        retenciones() {
            return this.$store.state.retencionesStore
        },
        token() {
            return this.$store.state.usuario;
        },
        listaConceptos() {
            let listaDescripcion = []
            for (let i of this.$store.state.listaConceptosStore) {
                listaDescripcion.push(i.descripcion)
            }
            return listaDescripcion
        },
        cotizacion() {
            return this.$store.state.comprasCotizacionStore
        },
        objImp() {
            return this.$store.state.objImpStore
        },
    },
    created() {
    },
    methods: {
        confirmaImpuestos() {
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }

            let objI = { ...this.objetoImpuestos }
            let impuestos = { ...this.impuestos }

            if (objI) {
                for (var c of this.selectConceptosEdit) {
                    c.objImp = { ...objI }
                    c.impuestos = { ...impuestos }
                }
                this.dialogAsignaImpuestos = false;
            }
            this.calcularTablaPrecioValorUnitario();
            // this.calcularTablaCompleta();
            // this.calculaTablaValorUnitario()
            this.impuestos = Object.assign({}, impuestosDefault);

        },

        calcularTablaPrecioValorUnitario() {
            for (let obj of this.selectConceptosEdit) {
                if (obj.precio == 0) {
                    this.calculaTablaValorUnitario(obj);
                } else {
                    this.calcularTablaCompleta(obj);
                }
            }
        },
        asignarImpuestos() {
            console.log(this.selectConceptosEdit)
            if (this.selectConceptosEdit != null) {
                this.dialogAsignaImpuestos = true
            } else {
                this.$toast.add({ severity: "error", detail: "Error, selecione al menos un concepto.", life: 3000, });

            }

        },
        onRowContextMenu(event) {
            this.$refs.cm.show(event.originalEvent);
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;
            // data[field] = newValue;
            switch (field) {
                case 'precio':
                    data[field] = newValue;
                    data['valorUnitario'] = 0;
                    // this.calcularTablaCompleta()
                    break;
                case 'objImp':
                    data[field] = newValue;
                    break;
                case 'cantidad':
                    data[field] = newValue;
                    break;
                case 'valorUnitario':
                    data[field] = newValue;
                    data['precio'] = 0;
                    // this.calculaTablaValorUnitario()
                    break;
            }
        },
        // CALCULA TODA LA TABLA SI INGRESAN EL VALOR UNITARIO
        calculaTablaValorUnitario(item) {
            // for (let item of this.selectConceptosEdit) {
            this.editedIndex = this.cotizacion.conceptos.indexOf(item);
            this.concepto = { ...item }

            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;
            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                // objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }
            objConcepto.precio = (objConcepto.importe + this.sum(objImpuestosChido.traslados, 'importe') - this.sum(objImpuestosChido.retenciones, 'importe')) / objConcepto.cantidad;
            objConcepto.total = objConcepto.precio * objConcepto.cantidad

            console.log('obj', objImpuestosChido)
            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            if (this.editedIndex === -1) {
                this.cotizacion.conceptos.push(objConcepto);

            } else {
                Object.assign(this.cotizacion.conceptos[this.editedIndex], objConcepto)
            }

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.conceptoSelect = null;
            this.editedIndex = -1;
            // this.impuestos = {};
            // this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
            // }
        },
        // CALCULA TODA LA TABLA SI INGRESAN EL PRECIO
        calcularTablaCompleta(item) {
            // for (let item of this.selectConceptosEdit) {
            this.editedIndex = this.cotizacion.conceptos.indexOf(item);
            this.concepto = { ...item }

            let precio = this.concepto.precio;
            let sumT = this.sum(this.impuestos.traslados, 'tasaOCuota')
            let sumR = this.sum(this.impuestos.retenciones, 'tasaOCuota')
            let sumaImpuestos = ((sumT - sumR)) + 1
            let importeC = precio / sumaImpuestos;
            importeC = Math.trunc(importeC * 1000000) / 1000000;
            console.log(importeC)
            this.concepto.valorUnitario = importeC

            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;
            objConcepto.total = objConcepto.precio * objConcepto.cantidad

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                // objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }
            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            console.log(this.concepto)
            if (this.editedIndex === -1) {
                this.cotizacion.conceptos.push(objConcepto);
            } else {
                Object.assign(this.cotizacion.conceptos[this.editedIndex], objConcepto)
            }
            // this.editedIndex = -1;
            // this.concepto = {};
            // this.concepto = Object.assign({}, this.conceptoDefault);

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.editedIndex = -1;
            // this.impuestos = {};
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
            // }
        },
        // CALCULA LOS IMPUESTOS POR PRECIO
        pushConcepto() {
            let precio = this.concepto.precio;
            let sumT = this.sum(this.impuestos.traslados, 'tasaOCuota')
            let sumR = this.sum(this.impuestos.retenciones, 'tasaOCuota')
            let sumaImpuestos = ((sumT - sumR)) + 1
            let importeC = precio / sumaImpuestos;
            importeC = Math.trunc(importeC * 1000000) / 1000000;
            console.log(importeC)
            this.concepto.valorUnitario = importeC

            //HACEMOS UNOS CÁLCULOS
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            var objConcepto = Object.assign({}, this.concepto);
            var objImpuestos = Object.assign({}, this.impuestos);
            var objImpuestosChido = {
                traslados: [],
                retenciones: [],
            }

            let importe = objConcepto.valorUnitario * objConcepto.cantidad;
            objConcepto.importe = Math.round(importe * 1000000) / 1000000;
            objConcepto.total = objConcepto.precio * objConcepto.cantidad

            // CALCULAMOS LOS IMPUESTOS
            if (objConcepto.objImp.clave === '02') {
                objConcepto.impuestos.traslados = []
                for (let i = 0; i <= objImpuestos.traslados.length - 1; i++) {
                    var x = objImpuestos.traslados[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.traslados.push(y);
                }
                for (let i = 0; i <= objImpuestos.retenciones.length - 1; i++) {
                    var x = objImpuestos.retenciones[i];
                    x.base_ = importe;
                    x.importe = Math.round((x.base_ * x.tasaOCuota) * 1000000) / 1000000;
                    // console.log(x)
                    const y = Object.assign({}, x)
                    objImpuestosChido.retenciones.push(y);
                }
            }

            //GUARDAMOS O EDITAMOS
            objConcepto.impuestos = Object.assign({}, objImpuestosChido)

            console.log(this.concepto)
            if (this.editedIndex === -1) {
                this.cotizacion.conceptos.push(objConcepto);
            } else {
                Object.assign(this.cotizacion.conceptos[this.editedIndex], objConcepto)
            }

            // this.editedIndex = -1;
            // this.concepto = {};
            // this.concepto = Object.assign({}, this.conceptoDefault);

            objConcepto = {};
            objImpuestos = {};
            objImpuestosChido = {}
            this.calcularImpuestos();
            this.sumatoria();
            this.editedIndex = -1;
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};
            this.concepto = Object.assign({}, this.conceptoDefault);
        },
        calcularImpuestos() {
            this.$store.state.comprasCotizacionStore.impuestos.traslados = []
            this.$store.state.comprasCotizacionStore.impuestos.retenciones = []
            let listTrasladados = [];
            let listRetenidos = [];
            let listaImpuestos = this.cotizacion.conceptos
            // console.log(listaImpuestos)
            let sumaT = 0.00;
            let sumaR = 0.00;

            for (let a of listaImpuestos) {
                //VERIFICAMOS SI LLEVA IMPUESTOS
                if (a.objImp.clave === '02') {
                    for (let t of a.impuestos.traslados) {
                        // t.base_ = a.importe
                        // t.importe = Math.round((t.base_ * t.tasaOCuota) * 1000000) / 1000000;
                        sumaT = sumaT + t.importe
                        sumaT = Math.round(sumaT * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS TRASLADADOS
                        let im = { ...t }
                        listTrasladados.push(im);
                        im = {}
                    }

                    for (let r of a.impuestos.retenciones) {
                        // r.base_ = a.impo  rte
                        // r.importe = Math.round((r.base_ * r.tasaOCuota) * 1000000) / 1000000;
                        sumaR = sumaR + r.importe
                        sumaR = Math.round(sumaR * 100) / 100
                        //AGREGAMOS A LA LISTA GENERAL DE IMPUESTOS RETENIDOS
                        let im = { ...r }
                        listRetenidos.push(im);
                        im = {}
                    }
                }


                //AGRUPAMOS LOS IMPUESTOS
                this.$store.state.comprasCotizacionStore.impuestos.traslados = [];
                var obj = this.agrupaImpuestos(listTrasladados, 'idImpuesto')
                for (const prop in obj) {
                    const tra = Object.assign({}, obj[prop])
                    this.$store.state.comprasCotizacionStore.impuestos.traslados.push(tra)
                }
                // console.log(this.$store.state.comprasCotizacionStore.impuestos.traslados)

                this.$store.state.comprasCotizacionStore.impuestos.retenciones = [];
                var objR = this.agrupaImpuestos(listRetenidos, 'idImpuesto')
                for (const prop in objR) {
                    const ret = Object.assign({}, objR[prop])
                    this.$store.state.comprasCotizacionStore.impuestos.retenciones.push(ret)
                }
            }
        },
        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },
        sumatoria() {
            let indice = 1;
            for (let x of this.cotizacion.conceptos) {
                x.item = indice;
                indice++;
            }

            this.$store.state.comprasCotizacionStore.subTotal = Math.round(this.sum(this.cotizacion.conceptos, 'importe') * 100) / 100;
            let tras = Math.round(this.sum(this.$store.state.comprasCotizacionStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.comprasCotizacionStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.$store.state.comprasCotizacionStore.total = this.$store.state.comprasCotizacionStore.subTotal + tras - ret;
        },
        agrupaImpuestos(miarray, prop) {
            return miarray.reduce(function (groups, item) {
                var val = item[prop];
                groups[val] = groups[val] || { nombre: item.nombre, base_: 0, impuesto: item.impuesto, tipoFactor: item.tipoFactor, tasaOCuota: item.tasaOCuota, importe: 0, tipo: item.idImpuesto };
                groups[val].base_ += item.base_;
                groups[val].importe += item.importe;
                groups[val].importe = Math.round(groups[val].importe * 1000000) / 1000000;
                return groups;
            }, {});
        },
        searchConcepto(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredConcepto = [...this.listaConceptos];
                }
                else {
                    this.filteredConcepto = this.listaConceptos.filter((x) => {
                        return x.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        formatoCantidad(value) {
            let val = (value / 1).toFixed(3)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatCurrency(value) {
            return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },

        editConcepto(item) {
            this.editedIndex = this.cotizacion.conceptos.indexOf(item);
            this.impuestos = { ...item.impuestos }
            this.concepto = { ...item }
        },
        deleteConcepto(item) {
            let indice = this.cotizacion.conceptos.indexOf(item);
            this.cotizacion.conceptos.splice(indice, 1);
            this.sumatoria();
        },
        abrirExcel() {
            this.dialogExcel = true;
        },

        cerraExcel() {
            this.dialogExcel = false;
        },

        abrirExcelPrecios() {
            this.dialogExcelPrecios = true;
        },
        cerraExcelPrecios() {
            this.dialogExcelPrecios = false;
        },
        cargarExcelPrecios(event) {
            const file = event.files[0];
            console.log(file)
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                for (var cont = 1; cont < num; cont++) {
                    try {
                        let item = rows[cont]['0']
                        let precio = rows[cont]['1']
                        let valorUnitario = rows[cont]['2']

                        for (let c of this.cotizacion.conceptos) {
                            if (c.item == item) {
                                if (precio == 0) {
                                    c.valorUnitario = valorUnitario
                                } else {
                                    c.precio = precio
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },
        cargarExcel(event) {
            this.cotizacion.conceptos = [];
            let itemsExcel = []
            const file = event.files[0];
            readXlsFile(file).then((rows) => {
                let num = rows.length;
                for (var cont = 1; cont < num; cont++) {
                    try {
                        this.concepto.descripcion = rows[cont]['0']
                        this.concepto.medida = rows[cont]['1']
                        this.concepto.cantidad = rows[cont]['2']
                        this.concepto.detalle = rows[cont]['3']

                        //CASO PARA LOS IMPUESTOS
                        let conImpuestos = { id: 4, clave: "02", descripcion: "Sí objeto de impuesto.", objImp: "02 | Sí objeto de impuesto." };
                        let sinImpuestos = { id: 3, clave: "01", descripcion: "No objeto de impuesto.", objImp: "01 | No objeto de impuesto." };
                        let impuestoIva = { "traslados": [{ "base_": 0, "importe": 0, "impuesto": "002", "traslado": "IVA(16.000000%)", "idImpuesto": 9, "tasaOCuota": 0.16, "tipoFactor": "Tasa", "folioFiscal": null }], "retenciones": [] };

                        let llevaImpuestos = rows[cont]['4']
                        if (llevaImpuestos === 'SI') {
                            this.concepto.objImp = { ...conImpuestos }
                            this.impuestos = { ...impuestoIva }

                        } else {
                            this.concepto.objImp = { ...sinImpuestos }
                        }

                        this.concepto.precio = rows[cont]['5']
                        // this.concepto.importe = this.concepto.cantidad * this.concepto.precio
                        this.pushConcepto();
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },
        exportCSV() {
            // this.$refs.dt.exportCSV();
            console.log(this.$refs.dt)
            const rows = this.$refs.dt.value; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, 'PlantillaCootizacionConceptos.xlsx');
        },

        exportCSVPlantillaPrecios() {
            // this.$refs.dt.exportCSV();
            console.log(this.$refs.precios)
            const rows = this.$refs.precios.value; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, 'PlantillaCargarPrecios.xlsx');
        }
    },
}
</script>
<style>
.p-dialog .p-dialog-content {
    overflow-y: visible !important;
}

.p-dialog-mask {
    position: absolute;
    pointer-events: auto;
    /* (optional, doesn't cover whole area when scroll presents) */
}
</style>