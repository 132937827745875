<template>
    <div>
        <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
            <!-- SERIE -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Dropdown v-model="factura.serie" :options="itemsSerie" :editable="true" />
                    <label>Serie</label>
                </span>
            </div>
            <!-- FOLIO -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText v-model="factura.folio" type="text" />
                    <label>Folio</label>
                </span>
            </div>
            <!-- FECHA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Calendar v-model="factura.fecha" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                    <label>Fecha</label>
                </span>
            </div>
            <!-- CLIENTE -->
            <div class=" field col-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" class="auto-complete" v-model="factura.cliente"
                            :suggestions="filteredCliente" @complete="searchCliente($event)" field="cliente">
                        </AutoComplete>
                        <label>Cliente</label>
                    </span>
                    <Button icon="pi pi-plus" class="p-button-success" @click="nuevoCliente" />
                </div>
            </div>
            <!-- CASO PARA PUBLICO EN GENERAL -->
            
            <!-- USO DE CFDI -->
            <div class="field col-12">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="factura.cliente.usoCfdi" :options="itemsUsoCfdi"
                        optionLabel="usoCfdi" />
                    <label>Uso de CFDI</label>
                </span>
            </div>
            <!-- METODO DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="factura.metodoPago" :options="itemsMetodoPago"
                        optionLabel="metodoPago" />
                    <label>Método de Pago</label>
                </span>
            </div>
            <!-- FORMA DE PAGO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="factura.formaPago" :options="itemsFormaPago"
                        optionLabel="formaPago" />
                    <label>Forma de Pago</label>
                </span>
            </div>
            <!-- CONDICIONES DE PAGO -->
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText v-model="factura.condicionesPago" type="text" />
                    <label>Condiciones de Pago</label>
                </span>
            </div>
            <!-- MONEDA -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <Dropdown style="text-align: left" v-model="factura.moneda" :options="itemsMoneda"
                        optionLabel="moneda" />
                    <label>Moneda</label>
                </span>
            </div>
            <!-- TIPO DE CAMBIO -->
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <InputNumber v-model="factura.tipoCambio" mode="decimal" locale="en-US" :minFractionDigits="6" />
                    <label>Tipo de Cambio</label>
                </span>
            </div>
            <!-- NOTAS -->
            <div class="field col-12 md:col-8">
                <span class="p-float-label">
                    <InputText v-model="factura.notas" type="text" />
                    <label>Notas</label>
                </span>
            </div>
            <!-- ORDEN DE COMPRA -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText v-model="factura.ordenCompra" type="text" />
                    <label>OC</label>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            filteredCliente: null,
            dialogoNuevoCliente: false,
        }
    },
    computed: {
        factura() {
            return this.$store.state.facturaStore;
        },
        itemsSerie() {
            return this.$store.state.listFacturaSerieStore;
        },
        itemsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore;
        },
        itemsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },
        itemsMoneda() {
            return this.$store.state.monedaStore
        },
        clientes() {
            return this.$store.state.listClienteStore;
        },
    },
    created() {

    },
    methods: {
        searchCliente(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCliente = [...this.clientes];
                }
                else {
                    this.filteredCliente = this.clientes.filter((country) => {
                        return country.cliente.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        nuevoCliente() {
            this.dialogoNuevoCliente = true;
        },
    },
}
</script>
<style>

</style>