<template>
    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
        <!-- VERSION -->
        <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <InputText v-model="addenda.version" type="text" />
                <label>Versión</label>
            </span>
        </div>
        <!-- DIVISION -->
        <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown v-model="addenda.divisionVwPsv" :options="divisionVwPsv" optionLabel="clave" />
                <label>División</label>
            </span>
        </div>
        <!-- TIPO DOCUMENTO VW -->
        <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown v-model="addenda.tipoDocumentoVWMVwPsv" :options="tipoDocumentoVWMVwPsV" optionLabel="clave" />
                <label>Tipo Documento VW</label>
            </span>
        </div>
        <!-- TIPO DOCUMENTO FISCAL -->
        <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown v-model="addenda.tipoDocumentoFiscalVwPsv" :options="tipoDocumentoFiscalVwPsv"
                    optionLabel="tipoDocumentoFiscal" />
                <label>Tipo Documento VW</label>
            </span>
        </div>
        <div class="field col-12 text-center">
            <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                MONEDA
            </h1>
        </div>
        <!-- CODIGO IMPUESTO -->
        <div class="field col-12 md:col-5">
            <span class="p-float-label">
                <Dropdown v-model="addenda.moneda.codigoImpuesto" :options="codigoImpuestoVwPsv"
                    optionLabel="codigoImpuesto" />
                <label>Código Impuesto</label>
            </span>
        </div>
        <!-- TIPO MONEDA -->
        <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <Dropdown v-model="addenda.moneda.tipoMonedaVwPsv" :options="tipoMonedaVwPsv" optionLabel="tipoMoneda" />
                <label>Moneda</label>
            </span>
        </div>
        <!-- TIPO DE CAMBIO -->
        <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <InputNumber v-model="addenda.moneda.tipoCambio" mode="decimal" locale="en-US" :minFractionDigits="2" />
                <label>Tipo de Cambio</label>
            </span>
        </div>
        <div class="field col-12 text-center">
            <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                PROVEEDOR
            </h1>
        </div>
        <!-- PROVEEDOR -->
        <div class="field col-12">
            <span class="p-float-label">
                <Dropdown v-model="addenda.proveedorVwPsv" :options="proveedorVwPsv" optionLabel="nombre" />
                <label>Proveedor</label>
            </span>
        </div>
        <!-- <div class=" field col-12 text-center">
            <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                ORIGEN
            </h1>
        </div> -->
        <!-- ORIGEN -->
        <!-- <div class="field col-12">
            <span class="p-float-label">
                <Dropdown v-model="addenda.origenVwPsv" :options="origenVwPsv" optionLabel="origen" />
                <label>Origen</label>
            </span>
        </div> -->
        <div class="field col-12 text-center">
            <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                REFERENCIAS
            </h1>
        </div>
        <!-- NUMERO ASN -->
        <div class="field col-12 md:col-6">
            <span class="p-float-label">
                <InputText v-model="addenda.referencia.numeroASN" type="text" maxlength="20" />
                <label>Número ASN</label>
            </span>
        </div>
        <!-- REFERENCIA PROVEEDOR -->
        <div class="field col-12 md:col-6">
            <span class="p-float-label">
                <InputText v-model="addenda.referencia.referenciaProveedor" type="text" maxlength="16" />
                <label>Referencia Proveedor</label>
            </span>
        </div>
        <!-- REMISION -->
        <div class="field col-12 md:col-6">
            <span class="p-float-label">
                <InputText v-model="addenda.referencia.remision" type="text" maxlength="16" />
                <label>Remisión</label>
            </span>
        </div>
        <!-- UNIDAD DE NEGOCIOS -->
        <div class="field col-12 md:col-6">
            <span class="p-float-label">
                <InputText v-model="addenda.referencia.unidadNegocios" type="text" maxlength="80" />
                <label>Unidad de Negocios</label>
            </span>
        </div>
        <div class="field col-12 text-center">
            <h1 style="background: #FF9100;color: white;font-size: 16px;font-family: arial;">
                SOLICITANTE
            </h1>
        </div>
        <!-- SOLICITANTE -->
        <div class="field col-12">
            <span class="p-float-label">
                <Dropdown v-model="addenda.solicitanteVwPsv" :options="solicitanteVwPsv" optionLabel="nombre" />
                <label>Solicitante</label>
            </span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        addenda() {
            return this.$store.state.addendaVwPsvStore;
        },
        divisionVwPsv() {
            return this.$store.state.divisionVwPsvStore;
        },
        tipoDocumentoVWMVwPsV() {
            return this.$store.state.tipoDocumentoVWMVwPsvStore;
        },
        tipoDocumentoFiscalVwPsv() {
            return this.$store.state.tipoDocumentoFiscalVwPsvStore;
        },
        codigoImpuestoVwPsv() {
            return this.$store.state.codigoImpuestoVwPsvStore;
        },
        tipoMonedaVwPsv() {
            return this.$store.state.tipoMonedaVwPsvStore;
        },
        origenVwPsv() {
            return this.$store.state.origenVwPsvStore;
        },
        proveedorVwPsv() {
            return this.$store.state.proveedorVwPsvStore;
        },
        solicitanteVwPsv() {
            return this.$store.state.solicitanteVwPsvStore;
        },
    },
    created() {

    },
    methods: {

    },
}
</script>
<style></style>