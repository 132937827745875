<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <div
                class="scalein animation-duration-2000 animation-iteration-infinite flex align-items-center justify-content-center font-bold border-round m-2 px-5 py-3">
                {{ loadingMensaje }}</div>
        </Dialog>
        <Dialog :visible.sync="dialogRelacionados" :closable="false" :containerStyle="{ width: '1200px' }"
            :modal="true">
            <Card>
                <template #title>
                    <div class="p-d-flex p-fluid grid formgrid">
                        <div class="text-left field col-4">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                                @click="dialogRelacionados = false" />
                        </div>
                        <div class="text-center field col-4">
                            Buscar CFDI´S
                        </div>
                    </div>
                </template>
                <template #content>
                    <!-- CAMPOS PARA BUSCAR -->
                    <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.folio"
                                        @keydown.enter="getComprobantesR(1)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantesR(1)" />
                                    <label>Folio</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.rfc"
                                        @keydown.enter="getComprobantesR(2)" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantesR(2)" />
                                    <label>RFC</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.nombre" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantesR(3)" />
                                    <label>Nombre</label>
                                </span>
                            </div>
                        </div>
                        <div class="justify-right field col-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <InputText style="text-align: left" type="text" v-model="busqueda.folioFiscal" />
                                    <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                        class="p-button-warning" @click="getComprobantesR(4)" />
                                    <label>Folio Fiscal</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <DataTable :value="itemsBuscados" :scrollable="true" scrollHeight="500px" scrollDirection="both"
                        class="mt-3">
                        <Column header="Acciones"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'min-width': '10rem' }" frozen>
                            <template #body="slotProps">
                                <Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2"
                                    @click="pushRelacionado(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="serie" header="Serie"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80', 'font-size': '90%' }">
                        </Column>
                        <Column field="folio" header="Folio"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%' }">
                        </Column>
                        <Column field="fecha" header="Fecha"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                        <Column field="rfc" header="RFC"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        </Column>
                        <Column field="nombre" header="Nombre"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                        <Column field="subTotal" header="SubTotal"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.subTotal) }}</span>
                            </template>
                        </Column>
                        <Column field="total" header="Total"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                            <template #body="{ data }">
                                <span>{{ formatCurrency(data.total) }}</span>
                            </template>
                        </Column>
                        <Column field="uuid" header="FolioFiscal"
                            :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        </Column>
                    </DataTable>
                </template>
            </Card>
        </Dialog>
        <Card class="shadow-none">
            <template #header>
                <div class="p-d-flex justify-center p-fluid grid formgrid col-12">
                    <!-- TIPO DE RELACION -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" :options="itemsTipoRelacion" optionLabel="tipoRelacion"
                                v-model="comprobante.cfdiRelacionados[0].tipoRelacion" />
                            <label>Tipo de Relación</label>
                        </span>
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :value="comprobante.cfdiRelacionados[0].cfdiRelacionados" :scrollable="true"
                    scrollHeight="500px" scrollDirection="both" class="mt-3">
                    <template #header>
                        <div class="table-header">
                            CFDI'S Relacionados
                            <Button style="color:white; background-color: #FF9100;" icon="pi pi-search"
                                @click="dialogRelacionados = true" />
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'min-width': '10rem' }"
                        frozen>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                                @click="deleteRelacionado(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="serie" header="Serie"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="folio" header="Folio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="fecha" header="Fecha"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfc" header="RFC"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombre" header="Nombre"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column field="uuid" header="FolioFiscal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            loading: false,
            loadingMensaje: '',
            dialogRelacionados: false,
            itemsBuscados: [],
            busqueda: {
                folio: '',
                rfc: '',
                nombre: '',
                folioFiscal: '',
            },
        }
    },
    computed: {
        comprobante() {
            console.log(this.$store.state.trasladoCartaPorteStore)
            return this.$store.state.trasladoCartaPorteStore;
        },
        itemsTipoRelacion() {
            return this.$store.state.listTipoRelacion;
        },
        empresa() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.GetTipoRelacion();
    },
    methods: {
        async GetTipoRelacion() {
            if (this.$store.state.listTipoRelacion.length == 0) {
                try {
                    let response = await axios.get('CatalogosSat/GetTipoRelacion');
                    this.$store.state.listTipoRelacion = response.data;
                } catch (error) {
                    console.log(error)
                }
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async getComprobantesR(tabla) {
            console.log(tabla)
            this.loading = true;
            this.loadingMensaje = 'Buscando comprobantes...'
            let ta = '';
            let dato = '';
            if (tabla == 1) {
                ta = 'folio'
                dato = this.busqueda.folio;
            }
            else if (tabla == 2) {
                ta = 'rfc'
                dato = this.busqueda.rfc;
            }
            else if (tabla == 3) {
                ta = 'nombre'
                dato = this.busqueda.nombre;
            }
            else if (tabla == 4) {
                ta = 'folioFiscal'
                dato = this.busqueda.folioFiscal
            }

            try {
                let response = await axios.get("Comprobantes/GetComprobantesR/" + this.empresa.empresaBase + '/' + ta + '/' + dato);
                console.log(response);
                let pre = response.data;

                //ELIMINAMOS LOS QUE YA TENGAN UN PAGO REGISTRADO
                for (let a of this.comprobante.cfdiRelacionados[0].cfdiRelacionados) {
                    let indice = pre.findIndex(x => x.uuid === a.uuid)
                    pre.splice(indice, 1)
                }

                this.itemsBuscados = pre;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log(error)
            }
        },

        pushRelacionado(item) {
            console.log(item)
            this.comprobante.cfdiRelacionados[0].cfdiRelacionados.push(item);
            let indice = this.itemsBuscados.findIndex(x => x.uuid === item.uuid)
            this.itemsBuscados.splice(indice, 1)
        },

        deleteRelacionado(item) {
            let indice = this.comprobante.cfdiRelacionados[0].cfdiRelacionados.indexOf(item)
            this.comprobante.cfdiRelacionados[0].cfdiRelacionados.splice(indice, 1)
        },
    },
}
</script>
<style>
.col {
    background-color: white;
}

.grid {
    background-color: white;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
