<template>
    <div>
        <!-- DIALOGO PARA ERRORES -->
        <div class="p-d-flex">
            <Toast />
        </div>

        <!-- DIALOG DEL PDF DE LA FACTURA -->
        <Dialog :visible.sync="dialogPdfFactura" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="dialogLoading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- DIALOG PARA CANCELAR CFDI -->
        <Dialog :visible.sync="dialogCancelacion" :style="{ width: '95vw' }" :closable="false">
            <Cancelacion @cerrarVentanaCancelacion="dialogCancelacion = false"></Cancelacion>
        </Dialog>

        <!-- DIALOG PARA FACTURAR -->
        <Dialog :visible.sync="dialogFacturaGas" :style="{ width: '95vw' }" :closable="false">
            <factura-gas @cerrarVentanaFacturaGas="dialogFacturaGas = false"
                @CierraInicializa="CloseVentanaInicializa"></factura-gas>
        </Dialog>

        <!-- DATOS DEL COMPROBANTE -->
        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <!-- TITULO -->
                    <div class="col-5 text-left pa-0 ma-0 pl-3">
                        <span style="cursor:pointer" @click="getTimbres()">Factura </span>
                        <ProgressSpinner v-if="datosGenerales == 1" style="width:36px;height:36px;  vertical-align: middle;"
                            strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        <Badge v-if="datosGenerales == 0" :value="timbresDisponibles" @click="getTimbres()" class="mr-2"
                            size="large" severity="success">
                        </Badge>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-6 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-6 text-left">
                                <span class="p-float-label">
                                    <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                    <label>Fecha Inicial</label>
                                </span>
                            </div>
                            <div class="col-12 md:col-6 text-left">
                                <span class="p-float-label">
                                    <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                    <label>Fecha Final</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- BOTONES -->
                    <div class="col-1 text-right">
                        <!-- <Button icon="pi pi-plus" @click="nuevaFactura()" class="mx-2 p-button-rounded" /> -->
                        <Button icon="pi pi-undo" @click="GetVentas()" class="p-button-rounded" />
                        <Button icon="pi pi-inbox" @click="SeleclAll()" class="p-button-rounded p-button-danger" />
                        <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#00A35C"
                            v-tooltip.bottom="'EXCEL'" @click="ExportExcel()" />
                        <Button icon="pi pi-undo" @click="SeleccionaLista()" class="p-button-rounded p-button-danger" />

                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosFact" class="mt-1 p-datatable-sm" :value="listaVentasGas"
                    :scrollable="true" scrollHeight="800px" scrollDirection="both" :loading="loading"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Ventas." :paginator="true"
                    :rows="10" :selection.sync="selectedVentas" selectionMode="multiple" dataKey="id"
                    :metaKeySelection="false">
                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filtrosFact['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'min-width': '10rem' }"
                        frozen>
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estatus === 'Vigente'">
                                <Button v-if="slotProps.data.estatusCancelacion != 'EnProceso'" icon="pi pi-ban"
                                    class="p-button-rounded p-button-danger" v-tooltip.bottom="'Cancelar'"
                                    @click="openCancelacion(slotProps.data)"></Button>
                                <Button icon="pi pi-check-circle" style="background:green" class="p-button-rounded "
                                    v-tooltip.bottom="'Actualizar Estatus SAT'"
                                    @click="actualizarEstatus(slotProps.data)"></Button>
                                <Button icon="pi pi-file-pdf" class="p-button-rounded" style="background:#E65100"
                                    v-tooltip.bottom="'PDF'" @click="openDialogPdf(slotProps.data)" />
                            </div>
                            <div v-if="slotProps.data.estatus === 'Sin Timbrar'">
                                <Button icon="pi pi-check" class="p-button-rounded p-button-success"
                                    v-tooltip.bottom="'Timbrar'" @click="OpenFactura(slotProps.data)"></Button>
                            </div>
                        </template>
                    </Column>
                    <Column field="serie" header="Serie"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="folio" header="Folio"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'text-align': 'center' }">
                        <template #body="slotProps">
                            <span :class="'customer-badge status-' + slotProps.data.estatus">{{
                                slotProps.data.folio
                            }}</span>
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatDate(slotProps.data.fecha) }}</span>
                        </template>
                    </Column>
                    <Column field="rfc" header="RFC"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombre" header="Nombre"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '200px', 'font-size': '90%' }">
                    </Column>
                    <Column field="subtotal" header="SubTotal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatCurrency(slotProps.data.subtotal) }}</span>
                        </template>
                    </Column>
                    <Column field="descuento" header="Descuento"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatCurrency(slotProps.data.descuento) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatCurrency(slotProps.data.total) }}</span>
                        </template>
                    </Column>
                    <Column field="formaPago" header="Forma de Pago"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="serieF" header="Serie Factura"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '90%' }">
                    </Column>
                    <Column field="folioF" header="Folio Factura"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="fechaF" header="Fecha Factura"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                        <template #body="slotProps">
                            <span>{{ formatDate(slotProps.data.fechaF) }}</span>
                        </template>
                    </Column>
                    <Column field="folioFiscal" header="Folio Fiscal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '250px', 'font-size': '90%' }">
                    </Column>
                    <Column field="estatus" header="Estatus"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import axios from "axios";
import moment from 'moment'
import ComprobantePdf from "../../Pdf/ComprobantePdf.vue";
import Cancelacion from "../../Cancelacion/Cancelacion.vue";
import FacturaGas from "./FacturaGas.vue"
import * as xlsx from 'xlsx';


export default {
    components: { ComprobantePdf, Cancelacion, FacturaGas },
    data() {
        return {
            dialogLoading: false,
            loadingMensaje: '',
            loading: false,

            dialogCancelacion: false,
            datosGenerales: 0,

            dialogFacturaGas: false,
            dialogPdfFactura: false,

            //FECHAS
            fechaI: new Date(),
            fechaF: new Date(),

            selectedVentas: [],

            filtrosFact: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                serie: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                folio: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                rfc: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                nombre: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                total: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                folioFiscal: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
                estatus: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                }
                ,
            },

        }
    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        listaVentasGas() {
            return this.$store.state.listVentasGasStore;
        },
        timbresDisponibles() {
            return this.$store.state.timbresDisponiblesStore;
        },
    },
    created() {
        this.inicializa();
    },
    methods: {
        async inicializa() {
            this.dialogLoading = true;
            this.datosGenerales = 1
            await this.getEmpresa();
            await this.getTimbres();
            this.datosGenerales = 0
            this.dialogLoading = false;
        },

        async getEmpresa() {
            try {
                this.loadingMensaje = 'Consultando datos...'
                let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
                this.$store.state.empresaStore = response.data
            } catch (error) {
                console.log(error)
                this.dialogLoading = false;
            }
        },

        async getTimbres() {
            this.datosGenerales = 1
            this.loadingMensaje = 'Consultando timbres...'
            try {
                let response = await axios.get('Comprobantes/GetTimbres/' + this.empresa.rfc);
                this.$store.state.timbresDisponiblesStore = response.data
                this.datosGenerales = 0
            } catch (error) {
                console.log(error)
                this.datosGenerales = 0
            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },

        async GetVentas() {
            this.dialogLoading = true;
            this.loadingMensaje = 'Consultando Ventas'
            try {
                let fechaI = moment(this.fechaI).format('YYYY-MM-DD');
                let fechaF = moment(this.fechaF).format('YYYY-MM-DD');;

                let response = await axios.get('Ventas/GetReporteVentaGasCompleto/' + this.token.empresaBase + '/' + fechaI + '/' + fechaF);
                let respuesta = response.data;
                console.log(respuesta)
                this.$store.state.listVentasGasStore = [...respuesta]
            } catch (error) {
                console.log(error)
            }
            this.dialogLoading = false;
        },

        OpenFactura(item) {
            let ventas = this.selectedVentas.filter(x => x.estatus === 'Sin Timbrar')
            // let ventas = this.selectedVentas
            this.$store.state.listVentasStore = ventas
            this.dialogFacturaGas = true;
        },

        CloseVentanaInicializa() {
            this.selectedVentas = []
            this.dialogFacturaGas = false
        },

        async openDialogPdf(item) {
            console.log(item.folioFiscal)
            try {
                let response = await axios.get('Comprobantes/GetComprobanteFolioFiscal/' + this.token.empresaBase + '/' + item.folioFiscal);
                let _comprobante = response.data[0]
                this.$store.state.facturaStore = { ..._comprobante }
                this.dialogPdfFactura = true;
            } catch (error) {
                console.log(error)
            }

        },

        closeDialogPdf() {
            this.dialogPdfFactura = false;
        },

        openCancelacion(data) {
            console.log(data)
            let ObjComprobante =
            {
                serie: data.serieF,
                folio: data.folioF,
                folioFiscal: data.folioFiscal,
                total: data.totalF,
                cliente: {
                    rfc: data.rfc,
                    nombre: data.nombre,
                },
                tipoComprobanteInterno: 'FacturaGas',
            }
            this.dialogCancelacion = true
            this.$store.state.cancelacionStore = { ...ObjComprobante }
        },

        SeleclAll() {
            let ventas = this.listaVentasGas.filter(x => x.estatus === 'Sin Timbrar')
            if (this.selectedVentas.length == 0) {
                this.selectedVentas = [...ventas]
            } else {
                this.selectedVentas = []
            }
        },

        ExportExcel() {
            const workbook = xlsx.utils.book_new();

            const sheetOtros = xlsx.utils.json_to_sheet(this.$store.state.listVentasGasStore);
            xlsx.utils.book_append_sheet(workbook, sheetOtros, 'VENTAS');

            xlsx.writeFile(workbook, 'VENTAS.xlsx');
        },

        SeleccionaLista() {
            this.selectedVentas = [];
            let cont = 0;
            let lista = [
                '00000001124882',
                '00000001124924',
                '00000001125009',
                '00000001125090',
                '00000001125163',
                '00000001125399',
                '00000001125499',
                '00000001125507',
                '00000001125781',
                '00000001125824',
                '00000001125936',
                '00000001126012',
                '00000001126286',
                '00000001126434',
                '00000001126474',
                '00000001126719',
                '00000001126781',
                '00000001126812',
                '00000001127257',
                '00000001127410',
                '00000001127556',
                '00000001127593',
                '00000001127724',
                '00000001127889',
                '00000001127891',
                '00000001127980',
                '00000001128152',
                '00000001128193',
                '00000001128204',
                '00000001128352',
                '00000001128422',
                '00000001128453',
                '00000001128584',
                '00000001128664',
                '00000001128768',
                '00000001128930',
                '00000001129180',
                '00000001129345',
                '00000001129450',
                '00000001129525',
                '00000001130153',
                '00000001130217',
                '00000001130294',
                '00000001130453',
                '00000001130472',
                '00000001130520',
                '00000001130840',
                '00000001130903',
                '00000001131106',
                '00000001131260',
                '00000001131486',
                '00000001131763',
            ]
            for (let a of lista) {
                let obj = this.$store.state.listVentasGasStore.find(f => f.folio === a);
                console.log(obj);
                this.selectedVentas.push(obj)
                obj = {}
                cont++;
            }
        },
    },
}

</script>
<style>
.status-Facturado {
    background-color: #4CAF50;
    display: inline-block;
    padding: 15px 15px 15px 15px;
    border-radius: 50px;
    height: 45px;
    font-size: 18px bold;
    color: white;
    font-weight: bolder;
}
</style>