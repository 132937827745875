<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                        @click="cierraVentana()" />
                    Nuevo Banco
                    <template v-if="banco.idCatalogoBancos === 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-success p-button-outlined"
                            @click="postBanco()" />
                    </template>
                    <template v-if="banco.idCatalogoBancos != 0">
                        <Button icon="pi pi-save" class="ml-1 mr-1 p-button-rounded p-button-warning p-button-outlined"
                            @click="putBanco()" />
                    </template>
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <div class="p-fluid grid">

                    <!-- CAJA O BANCO -->
                    <div style="text-align: center;" class="field col-12 md:col-4">
                        <h5>¿CAJA O BANCO?</h5>
                        <div class="formgroup-inline justify-center">
                            <div class="ml-6 mr-6 field-radiobutton">
                                <RadioButton id="caja" name="cajabanco" value="caja" v-model="radioB" />
                                <label for="caja">Caja</label>
                            </div>
                            <div class="ml-6 field-radiobutton">
                                <RadioButton id="banc" name="cajabanco" value="banco" v-model="radioB" />
                                <label for="banc">Banco</label>
                            </div>
                        </div>
                    </div>
                    <!-- CUENTA -->
                    <div class="field col-8">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="cuenta" type="text" v-model="banco.cuenta" />
                                <label for="cuenta">Cuenta</label>
                            </span>
                        </span>
                    </div>

                    <!-- CAJA -->
                    <!-- <div v-if="radioB == 'caja'" class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="caja" type="text" v-model="banco.caja" />
                                <label for="caja">Caja</label>
                            </span>
                        </span>
                    </div> -->
                    <!-- BANCO -->
                    <div v-if="radioB == 'banco'" class="justify-left field col-12">
                        <span class="p-float-label">
                            <Dropdown style="text-align: left" v-model="banco.jsonBancosSat" :options="listaBancosSat"
                                optionLabel="banco" />
                            <label>Banco</label>
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios';
import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
    data() {
        return {
            loading: false,
            loadingMensaje: "",
            correo: '',
            listaBancosSat: [],
            radioB: 'caja',
        };
    },
    computed: {
        empresa() {
            return this.$store.state.usuario;
        },

        banco() {
            return this.$store.state.bancosEmpresaStore;
        },

    },

    created() {
        this.Inicializa();
    },
    mounted() {

    },
    methods: {
        //INICILIALIZA
        async Inicializa() {
            this.loading = true;
            this.loadingMensaje = 'Consultando lista de Bancos...'
            await this.GetBancosSat();
            this.loading = false;
        },

        async GetBancosSat() {
            try {
                let response = await axios.get("Nomina/GetBanco");
                this.listaBancosSat = response.data;
                // console.log(this.itemsBancosEmpresa);
            } catch (error) {
                console.log(error);
            }
        },

        //GUARDA O EDITA
        async postBanco() {

            if (this.radioB === 'caja') {
                this.banco.jsonBancosSat = {
                    banco: 'Caja | ' + this.banco.cuenta,
                    clave: 'Caja',
                    descripcion: this.banco.cuenta,
                }

            }
            // else {
            //     this.banco.caja = ''
            // }

            let ObjBanco = { ...this.banco }

            this.loading = true;
            this.loadingMensaje = 'Guardando datos, espere...'
            ObjBanco.usuarioCreador = this.empresa.nombre

            try {
                let response = await axios.post('Bancos/PostBancoEmpresa/' + this.empresa.empresaBase, ObjBanco)
                ObjBanco.idCatalogoBancos = response.data
                this.loading = false;

                this.$store.state.listBancosEmpresa.push(ObjBanco);
                this.$store.state.bancosEmpresaStore.idCatalogoBancos = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }

        },

        async putBanco() {

            if (this.radioB === 'caja') {
                this.banco.jsonBancosSat = {
                    banco: 'Caja | ' + this.banco.cuenta,
                    clave: 'Caja',
                    descripcion: this.banco.cuenta,
                }

            }
            // else {
            //     this.banco.caja = ''
            // }

            let ObjBanco = { ...this.banco }

            this.loading = true;
            this.loadingMensaje = 'Actualizando datos, espere...'
            ObjBanco.usuarioModificador = this.empresa.nombre

            try {
                let response = await axios.put('Bancos/PutBancoEmpresa/' + this.empresa.empresaBase, ObjBanco)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listBancosEmpresa.findIndex(x => x.idCatalogoBancos === ObjBanco.idCatalogoBancos);
                Object.assign(this.$store.state.listBancosEmpresa[indice], ObjBanco)
                this.$store.state.bancosEmpresaStore.idCatalogoBancos = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },

        cierraVentana() {
            this.$emit('closeBanco')
        },
    },
};
</script>